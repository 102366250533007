<template>
  <el-dialog v-can="'order.read'" :title="$i18n.t('common.label.showOrderDetail')" :visible.sync="dialogVisible" :before-close="handleClose" :close-on-click-modal="false" width="80%">
    <b-row>
      <b-col cols="8" class="pr-5" style="border-right: 1px solid #ebeef5">
        <el-table
          v-loading="isLoading"
          :data="productTableData"
          :header-cell-style="discountHeaderStyle"
          :height="500"
          style="width: 100%"
          class="mb-5"
          default-expand-all
          row-key="id"
          :span-method="arraySpanMethod"
        >
          <el-table-column :label="this.$i18n.t('src.modules.order.detail.name')" width="350">
            <template slot-scope="scope">
              <span v-if="scope.row.productName && Object.keys(scope.row).length > 6">
                <el-tooltip effect="dark" :content="scope.row.productName" placeholder="bottom" v-if="scope.row.productName && scope.row.productName.length > 35">
                  <a target="_blank" :href="EI_ENDPOINT + '/' + scope.row.productSlug + '-pid' + scope.row.productId">{{ scope.row.productName | truncate(35) }}</a>
                </el-tooltip>
                <span v-else>
                  <a target="_blank" :href="EI_ENDPOINT + '/' + scope.row.productSlug + '-pid' + scope.row.productId">{{ scope.row.productName }}</a>
                </span>
              </span>
              <div v-else class="d-inline-block">
                <div class="d-flex">
                  <el-tooltip effect="dark" :content="scope.row.supInfo.name" placeholder="bottom" v-if="scope.row.supInfo.name && scope.row.supInfo.name.length > 10">
                    <span class="mr-4 font-weight-bold" style="cursor: pointer" onMouseOver="this.style.textDecoration ='underline'" onMouseOut="this.style.textDecoration='none'">
                      {{ scope.row.supInfo.name | truncate(10) }}
                    </span>
                  </el-tooltip>
                  <span v-else class="mr-4 font-weight-bold" style="cursor: pointer" onMouseOver="this.style.textDecoration ='underline'" onMouseOut="this.style.textDecoration='none'">
                    {{ scope.row.supInfo.name }}
                  </span>
                  <el-select
                    size="medium"
                    :value="scope.row.supStatus"
                    :placeholder="$i18n.t('src.modules.order.detail.select.status.placeholder')"
                    class="supplier-status-select col-md-5 mr-2 border-none bg-light"
                    :disabled="true"
                    v-if="scope.row.orderSupId"
                  >
                    <template slot="prefix">
                      <el-tag
                        :disable-transitions="true"
                        effect="dark"
                        class="supplier-status-text"
                        :class="allOrderSupplierStatus[scope.row.supStatus].color"
                        style="width: 100%"
                        v-if="scope.row.supStatus"
                      >
                        {{ allOrderSupplierStatus[scope.row.supStatus].label }}
                      </el-tag>
                    </template>
                    <el-option
                      v-for="item in Object.keys(allOrderSupplierStatus)"
                      :key="allOrderSupplierStatus[item].value"
                      :label="allOrderSupplierStatus[item].value"
                      :value="allOrderSupplierStatus[item].value"
                      :disabled="parseInt(scope.row.supStatus) >= parseInt(allOrderSupplierStatus[item].value)"
                    >
                      <span>
                        <el-tag :disable-transitions="true" :key="allOrderSupplierStatus[item].label" effect="dark" style="border-color: white" :class="allOrderSupplierStatus[item].color">
                          {{ allOrderSupplierStatus[item].label }}
                        </el-tag>
                      </span>
                      <!-- {{  allOrderSupplierStatus[item].label }} -->
                    </el-option>
                  </el-select>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column :label="this.$i18n.t('src.modules.order.detail.image')" width="100">
            <template slot-scope="scope">
              <el-image style="width: 50px; height: 50px" :src="S3_STORE_ENDPOINT + '/' + getProductImage(scope.row.productImg)" v-if="scope.row.productImg && Object.keys(scope.row).length > 6">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column :label="this.$i18n.t('src.modules.order.detail.unit')" width="150">
            <template slot-scope="scope">
              <span
                v-if="
                  Object.keys(scope.row).length > 6 &&
                  scope.row.products.unitCode != null &&
                  unitObj[scope.row.products.unitCode] !== null &&
                  typeof unitObj[scope.row.products.unitCode] !== 'undefined'
                "
              >
                {{ unitObj[scope.row.products.unitCode] }}
              </span>
            </template>
          </el-table-column>
          <!-- <el-table-column :label="$i18n.t('src.modules.order.detail.productVariants')" width="250" style="text-align: center">
            <template slot-scope="scope" v-if="scope.row.variants">
              <span v-for="(item, index) in scope.row.variants" :key="index" class="mr-2"> {{ item.groupName || item.name }} </span>
            </template>
          </el-table-column> -->
          <el-table-column prop="quanity" :label="this.$i18n.t('src.modules.order.detail.quantity')" width="150" style="text-align: center">
            <template slot-scope="scope">
              <span>{{ scope.row.quantity }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="this.$i18n.t('src.modules.order.detail.salePrice')" width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.price || scope.row.price !== 0">
                {{
                  new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND'
                  }).format(scope.row.price)
                }}
              </span>
              <span v-else-if="!scope.row.price || scope.row.price === 0"> Liên hệ </span>
            </template>
          </el-table-column>
          <el-table-column :label="this.$i18n.t('src.modules.order.detail.supplierPrice')" width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.supplierPrice || scope.row.supplierPrice !== 0">
                {{
                  new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND'
                  }).format(scope.row.supplierPrice)
                }}
              </span>
              <span v-else-if="!scope.row.supplierPrice || scope.row.supplierPrice === 0"> Liên hệ </span>
            </template>
          </el-table-column>
          <el-table-column prop="totalPrice" :label="$i18n.t('src.modules.order.detail.totalPrice')" width="120">
            <template slot-scope="scope">
              {{
                new Intl.NumberFormat('vi-VN', {
                  style: 'currency',
                  currency: 'VND'
                }).format(scope.row.totalPrice)
              }}
            </template>
          </el-table-column>
        </el-table>
      </b-col>
      <b-col cols="4" class="pr-5">
        <!-- Order Information (S) -->
        <Widget>
          <h5 style="font-weight: 600; text-align: center; color: #909399; border-bottom: 1px solid rgba(0, 0, 0, 0.05)" class="mb-3 pb-3">
            {{ this.$i18n.t('src.modules.order.index.deliveryInfo') }}
          </h5>
          <div class="mb-1" style="color: #adb5bd; font-size: 12px; letter-spacing: 1px; font-weight: 600">
            <span class="text-uppercase">{{ this.$i18n.t('src.modules.order.index.receiver') }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <div class="col-md-10 text-order-info">
              <span>{{ orderData.name }}</span>
            </div>
          </div>
          <div class="mb-1" style="color: #adb5bd; font-size: 12px; letter-spacing: 1px; font-weight: 600">
            <span class="text-uppercase">{{ this.$i18n.t('src.modules.order.index.phone_number') }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <div class="col-md-10 text-order-info">
              <span>{{ orderData.phone }}</span>
            </div>
          </div>
          <div class="mb-1" style="color: #adb5bd; font-size: 12px; letter-spacing: 1px; font-weight: 600">
            <span class="text-uppercase">{{ this.$i18n.t('src.modules.order.index.delivery_address') }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <div class="col-md-10 text-order-info">
              <span>{{ fullAddress }}</span>
            </div>
          </div>
        </Widget>
        <!-- Order Information (E) -->
        <!-- Order Information (S) -->
        <Widget>
          <h5 class="font-weight-bold pb-3 mb-2" style="border-bottom: 1px solid rgba(0, 0, 0, 0.05)">
            {{ this.$i18n.t('src.modules.order.detail.orderSummary') }}
          </h5>
          <div class="d-flex justify-content-between mb-3">
            <div style="color: #696969">
              <span>
                {{ this.$i18n.t('src.modules.order.detail.subTotal') }}
              </span>
            </div>
            <div class="font-weight-bold">
              <span
                >{{
                  new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND'
                  }).format(this.totalPrice)
                }}
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-between mb-3">
            <div style="color: #696969">
              <span> VAT (10%) </span>
            </div>
            <div class="font-weight-bold">
              <span
                >+
                {{
                  new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND'
                  }).format(this.vat)
                }}
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-between mb-3" v-if="this.orderData.eiCash > 0">
            <div style="color: #696969">
              <span> EI Cash </span>
            </div>
            <div class="font-weight-bold">
              <span
                >-
                {{
                  new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND'
                  }).format(this.orderData.eiCash)
                }}
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center pt-3 mt-3 mb-3" style="border-top: 1px solid rgba(0, 0, 0, 0.05)" v-if="this.totalPrice > 0 && this.orderData.voucherId">
            <div style="color: #696969">
              <span>{{ this.$i18n.t('src.modules.order.detail.discount') }}</span>
              <strong style="display: block; font-size: 12px">{{ getDiscountCode() }}</strong>
            </div>
            <div class="font-weight-bold">
              <span
                >-
                {{
                  new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND'
                  }).format(this.orderData.discountAmount)
                }}</span
              >
            </div>
          </div>
          <div class="d-flex justify-content-between mb-3">
            <div style="color: #696969">
              <!-- <span></span> -->
              <el-button type="warning" size="mini" class="btn-orange-md">
                {{ $i18n.t('src.modules.order.detail.shippingFee') }}
              </el-button>
            </div>
            <div class="font-weight-bold">
              <div class="d-flex justify-content-between">
                <span class="mr-2"
                  >+
                  {{
                    new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND'
                    }).format(this.shippingFee)
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center pt-3 mt-3" style="border-top: 3px solid black; font-size: 16px; font-weight: bold">
            <div style="color: #696969">
              <span>{{ this.$i18n.t('src.modules.order.detail.total') }}</span>
            </div>
            <div class="font-weight-bold">
              <span>
                {{
                  new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND'
                  }).format(totalSalePrice)
                }}
              </span>
            </div>
          </div>
        </Widget>
        <!-- Order Information (E) -->
      </b-col>
    </b-row>
    <span slot="footer" class="dialog-footer">
      <el-row :gutter="24">
        <el-col :span="4">
          <el-button class="float-left" @click="handleClose">{{ $i18n.t('src.components.modal.selectProduct.tableProduct.close') }}</el-button>
        </el-col>
        <el-col :span="20">
          <el-button v-can="'order.edit'" class="float-right btn-blue-sm" type="primary" size="medium" @click="$router.push(`/app/order/edit/${orderData.id}`)">{{
            $i18n.t('common.label.editOrder')
          }}</el-button>
        </el-col>
      </el-row>
    </span>
  </el-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { filterByKey } from 'core/helpers';
// import cloneDeep from 'lodash/cloneDeep';
// import isEqual from 'lodash/isEqual';
import { SUPPLIER_TYPE_CODE } from '../../../core/constants';
import isEmpty from 'lodash/isEmpty';
import { ORDER_SUPPLIER_COLOR, CHECKOUT_COLOR, VCHR_DSCNT_TP } from 'core/constants';
const ORDER_STATUS_CODE = 'ORDR_STT';
const ORDER_SUP_STATUS_CODE = 'ORDR_SUP_STT';
import vnLocal from 'vn-local';

export default {
  components: {},

  props: {
    orderId: {
      type: Number,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      S3_STORE_ENDPOINT: process.env.VUE_APP_S3_STORE_ENDPOINT,
      EI_ENDPOINT: process.env.VUE_APP_EI_ENDPOINT,
      isLoading: false,
      editorConfig: {
        language: 'en'
      },
      supplierInfoArr: [],
      productTableData: []
    };
  },
  computed: {
    ...mapState('orderDetail', ['orderData', 'orderDetails', 'orderStatus', 'orderSuppliers', 'orderDetailSuppliers', 'vat', 'shippingFee', 'totalPrice', 'totalSalePrice']),
    ...mapState('supplier', ['suppliers', 'suppliersInPage']),
    ...mapState('category', ['categories', 'categoriesMap']),
    ...mapState('unit', ['units']),
    ...mapState('common', ['listCommons', 'listCommon2nd']),
    ...mapState('voucher', ['vouchers', 'listVoucher']),

    // get Full Address
    fullAddress() {
      const { address, district, ward } = this.orderData;
      if (address == '' || district == '' || ward == '') return '';
      else {
        const districtList = vnLocal.getWardsByDistrictCode(district);
        const provinceName = districtList?.[0]?.provinceName || '';
        const districtName = districtList?.[0]?.districtName || '';
        const wardName = (ward && districtList?.find((d) => d.code === ward)?.name) || '';
        return address + ', ' + wardName + ', ' + districtName + ', ' + provinceName;
      }
    },

    //list id supplier in order
    allSupplierId() {
      const list = [...this.orderDetails];
      const idsArray = list.map((item) => item.supId);
      return [...new Set(idsArray)];
    },

    // group product by supplier
    groupProductBySupplier() {
      // group supplier by supId
      const group = this.orderDetails.reduce((objectsByKeyValue, obj) => {
        const value = obj['supId'];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});

      const groupOrderDetailSup = this.orderSuppliers.reduce((objectsByKeyValue, obj) => {
        const value = obj['supplierId'];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});
      // get data for table by above group variable
      const t = Object.keys(group).map((item) => {
        const orderSupplierInfo = this.orderSuppliers.find((ordrSup) => parseInt(ordrSup.supId) === parseInt(item)) || {};
        if (!isEmpty(orderSupplierInfo) && this.supplierObj[item]) {
          if (!!this.supplierInfoArr.find((o) => o.id === item) === false) {
            this.supplierInfoArr.push({
              id: item,
              name: this.supplierObj[item]
            });
          }
          return {
            id: item,
            orderSupId: orderSupplierInfo.id,
            supInfo: {
              name: this.supplierObj[item]
            },
            supStatus: orderSupplierInfo.statusCode,
            orderDetailSuppliers: groupOrderDetailSup[orderSupplierInfo.supId],
            children: group[orderSupplierInfo.supId]
          };
        } else if (isEmpty(orderSupplierInfo) && this.supplierObj[item]) {
          return {
            id: item,
            supInfo: {
              name: this.supplierObj[item].name,
              listAddress: [],
              supBanks: []
            },
            children: group[item]
          };
        }
        return {};
      });

      //delete all empty object in array
      return t.filter((value) => Object.keys(value).length !== 0);
    },

    // list status order with coSlor status
    allStatusCheckout() {
      const data = this.listCommons.map((item) => {
        return {
          value: item.code,
          label: item.name,
          color: CHECKOUT_COLOR[item.code]
        };
      });
      return data;
    },

    allOrderSupplierStatus() {
      const data = this.listCommon2nd.reduce((objKey, obj) => {
        if (parseInt(obj.code) !== 1) {
          objKey[obj.code] = {
            value: obj.code,
            label: obj.name,
            color: ORDER_SUPPLIER_COLOR[parseInt(obj.code)]
          };
        }
        return objKey;
      }, {});
      return data;
    },

    unitOptions() {
      return this.units.map((item) => {
        return {
          value: item.code,
          label: item.vi_VN
        };
      });
    },

    dialogVisible() {
      return this.visible;
    },

    getCategory() {
      return filterByKey(this.categories, 'name', this.searchCate).map((item) => ({
        label: item.name,
        value: item.id
      }));
    },

    getSupplier() {
      return filterByKey(this.suppliers, 'name', this.searchSupplier).map((item) => {
        return {
          label: item.name,
          value: item.id,
          isSourcing: item.typeCode == SUPPLIER_TYPE_CODE.SOURCING ? true : false
        };
      });
    },
    supplierObj() {
      let obj = this.suppliersInPage.reduce(function (o, val) {
        o[val.id] = val.name;
        return o;
      }, {});
      return obj;
    },
    // create list unit object
    unitObj() {
      const lang = localStorage.getItem('user-language');
      let obj = this.units.reduce((acc, ele) => {
        acc[ele.code] = lang === 'vi-VN' ? ele.vi_VN : ele.en_US;
        return acc;
      }, {});
      return obj;
    }
  },

  watch: {
    orderId: async function () {
      const listPromise = [this.getOrderById({ id: this.orderId }), this.getByOrderId({ id: this.orderId })];
      this.isLoading = true;
      await Promise.all(listPromise).then(async () => {
        await this.getAllSupplier({ supplierIdArray: this.allSupplierId });
        this.productTableData = this.groupProductBySupplier;
      });
      this.isLoading = false;
    }
  },

  async created() {
    await this.getAllCategories({
      current_page: 0,
      itemsPerPage: 0
    });
    this.getAllCommons({ name: '', comCode: ORDER_STATUS_CODE });
    this.getAllCommons({ name: '', comCode: ORDER_SUP_STATUS_CODE, numberList: 2 });
    await this.getAllVouchers({ name: '', code: '', date: '' });
    await this.getAllUnits({ name: '' });
  },
  methods: {
    ...mapActions('supplier', ['getAllSupplier', 'addSupplierFromOrderDetail']),

    ...mapActions('product', ['getAllProducts', 'setEmptyProductList', 'saveAddProductFromOrderDetail']),

    ...mapActions('global', ['setGlobalReady']),

    ...mapActions('category', ['getAllCategories']),

    ...mapActions('unit', ['getAllUnits']),

    ...mapActions('orderDetail', ['getOrderById', 'getByOrderId']),

    ...mapActions('common', ['getAllCommons']),

    ...mapActions('voucher', ['getAllVouchers']),

    // discount method
    getDiscountCode() {
      const voucher = this.vouchers.find((item) => item.id == this.orderData.voucherId);
      const { discountTypeCode = '' } = voucher;
      if (discountTypeCode == VCHR_DSCNT_TP.PRCNT) {
        return `${voucher?.code} (- ${voucher?.value}%)`
      }
      if (discountTypeCode == VCHR_DSCNT_TP.CASH) {
        return `${voucher?.code} (- ${new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND'
        }).format(voucher?.value)})`;
      }
        
    },

    discountHeaderStyle({ rowIndex }) {
      if (rowIndex === 1) {
        //Hide the other two head cells
        return { display: 'none' };
      }
    },

    arraySpanMethod({ row, columnIndex }) {
      if (Object.keys(row).length <= 6) {
        if (columnIndex === 0) {
          return [1, 6];
        } else if (columnIndex === 1) {
          return [0, 0];
        }
      }
    },

    getProductImage(listImage) {
      const images = listImage ? listImage.split('|') : [];
      return images[0];
    },
    handleClose() {
      this.$emit('onCloseShowOrderDetail');
    }
  }
};
</script>
<style>
.list-product-row .el-table__fixed-right-patch {
  background-color: #333 !important;
  height: 56px !important;
}
.supplier-status-select {
  background-color: white !important;
}
.supplier-status-select input {
  width: 100%;
  border: none;
  background-color: white !important;
}
.text-order-info {
  padding-bottom: 5px !important;
}
</style>
