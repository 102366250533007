import Service from 'core/service';
import * as Type from 'modules/role/store/types';

const services = new Service()

const getAllRoles = async ({ commit }, value) => {
    const { name = '', isBelongToSupplier, isRoleAdmin } = value;

    const params = { }

    if (name) {
        params.name = name;
    };

    if(isBelongToSupplier){
        params.isBelongToSupplier = isBelongToSupplier
    };

    if(isRoleAdmin){
        params.isRoleAdmin = isRoleAdmin
    };
   
    const data = await services.rest('role/getListDetail', params)
      
    if (data) {
        commit(Type.GET_ALL, data);
    } else {
        console.log('error');
    } 
};

const saveRole= async ({ commit }, value) => {
    const allRole= await getListRole();
    const checkRoleExist = await allRole.find(element => element.name === value.role.name);

    if(!checkRoleExist) {
        const data = {
            name: value.role.name,
            description: value.role.description,
            arrPermissions: value.permissions,
            isBelongToSupplier: value.isBelongToSupplier
        }
        return await services.rest('role/setList', { 
            items: {
                $add: [data]
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltAdd} = items
            commit(Type.SAVE, $rltAdd);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
        
    } else {
        return {
            type: false, 
            message: 'Item already exist'
        }
    }
};

const editRole = async ({ commit }, value) => {
    if (value.oldNameEdit.trim() !== value.role.name.trim()) {
        let allRole= await getListRole();
        const checkRoleExist = await allRole.find(element => element.name == value.role.name)
        if(checkRoleExist) {
            return {
                type: false, 
                message: 'Item already exist'
            }
        }
    } 

    if (value.id) {
        const data = {
            id: value.id,
            name: value.role.name,
            description: value.role.description,
            arrPermissions: value.permissions,
        }
        return await services.rest('role/setList', { 
            items: {
                $update: [data]
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltUpdate} = items
            // Commit to news state
            commit(Type.EDIT, $rltUpdate[0]);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const deleteRole= async ({ commit }, value) => {
    if (value.row.id) { 
        return await services.rest('role/setList', { 
            items: {
                $delete: [value.row]
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltDelete} = items
            // Commit to news state
            commit(Type.DELETE, $rltDelete);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const deleteRoles = async ({ commit }, value) => {
    if (value.length > 0) { 
        return await services.rest('role/setList', { 
            items: {
                $delete: value
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltDelete} = items
            // Commit to news state
            commit(Type.DELETE, $rltDelete);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const getListRole = async () => {    
    const data = await services.rest('role/getListPaging', {})
    .then(res=> {
        return res.rows
    }).catch(err=> {
        console.log(err);
    })
    return data
};


export {
    editRole,
    getAllRoles,
    saveRole,
    deleteRole,
    deleteRoles
};