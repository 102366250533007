import Service from 'core/service';
import * as Type from 'modules/orderStatus/store/types'
const service = new Service();

const getAllStatus = async ({ commit }) => {
    return service.rest('/orderStatus/getAll').then(res => {
        commit(Type.SET_ALL_STATUS, res);
        return true
    }).catch(err => {
        console.log(err);
        return false
    });
}


export {
    getAllStatus
};