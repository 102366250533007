<template>
  <div class="tables-basic" v-can="'quotation.read'">
    <b-row>
      <b-col>
        <!-- Search (S) -->
        <Widget style="">
          <el-row :gutter="24">
            <el-col :span="4">
              <div class="grid-content">
                <el-select
                  size="medium"
                  clearable
                  v-model="formSearch.customerId"
                  placeholder="Select Customer"
                  width="100%"
                  filterable
                  :remote="true"
                  :remote-method="data => customersFilter(data)"
                  :loading="customersDataLoading"
                  @visible-change="onBlurSelectCustomer"
                >
                  <el-option
                    v-for="item in customersList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <!-- <el-col :span="4">
              <div class="grid-content">
                <el-select
                  size="medium"
                  clearable
                  v-model="formSearch.salesInChargeId"
                  placeholder="Select Sales"
                  width="100%"
                  filterable
                >
                  <el-option
                    v-for="item in salesList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col> -->
            <el-col :span="4">
              <div class="grid-content">
                <el-select
                  size="medium"
                  clearable
                  v-model="formSearch.quotationCode"
                  :value="keywordQuotationCode"
                  placeholder="Select Quotation Code"
                  width="100%"
                  filterable
                  :remote="true"
                  :remote-method="data => quotationCodeFilter(data)"
                  @visible-change="onBlurSelectCustomer"
                  popper-class="infinite-load"
                  v-loadmore="loadMore"
                >
                  <el-option
                    v-for="item in quotationByCodeList"
                    :key="item.key"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                  <el-option v-if="hasMore" disabled label="Load More..." value="-1"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content">
                <el-select
                  size="medium"
                  clearable
                  v-model="formSearch.bdInChargeId"
                  placeholder="Select BD"
                  width="100%"
                  filterable
                >
                  <el-option
                    v-for="item in BDList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="isSupplier ? 6 : 4">
              <div class="grid-content">
                <el-select
                  size="medium"
                  clearable
                  v-model="formSearch.quotationStatusId"
                  placeholder="Select Status"
                  width="100%"
                  filterable
                >
                  <template slot="prefix">
                    <el-tag
                      :disable-transitions="true"
                      :type="getStatusColor(formSearch.quotationStatusId)"
                      effect="dark"
                      v-if="getStatus(formSearch.quotationStatusId)"
                    >
                      {{ getStatus(formSearch.quotationStatusId) }}
                    </el-tag>
                  </template>
                  <el-option
                    v-for="item in quotationStatusList"
                    :key="item.value"
                    :value="item.value"
                  >
                    <span>
                      <el-tag
                        :key="item.label"
                        :type="item.color"
                        :disable-transitions="true"
                        effect="dark"
                      >
                        {{ item.label }}
                      </el-tag>
                    </span>
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="isSupplier ? 6 : 4">
              <div class="grid-content">
                <el-date-picker
                  v-model="formSearch.createdAt"
                  type="daterange"
                  align="right"
                  :start-placeholder="$i18n.t('src.modules.quotation.index.startDate')"
                  :end-placeholder="$i18n.t('src.modules.quotation.index.endDate')"
                  :default-time="['00:00:00', '23:59:59']"
                  format="dd-MM-yyyy"
                >
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="12" v-if="isSupplier">
              <div class="grid-content">
                <el-row type="flex" justify="end">
                  <el-button class="btn-black-lg" size="medium" type="info" @click="onClickSearchQuotation()">
                    <i class="el-icon-search"></i>&nbsp;
                    {{ $t('src.modules.news.index.btnSearch') }}</el-button
                  >
                </el-row>
              </div>
            </el-col>
            
          </el-row>
          <!-- Search quotation code (S)  -->
          <!-- <el-row :gutter="24">
            
          </el-row> -->
          <!-- Search quotation code (E)  -->
        </Widget>
        <!-- Search (E)  -->
        <Widget style="margin-bottom: 0px">
          <!-- Button Action (S) -->
          <el-row type="flex" justify="start">
            <el-col>
              <div class="grid-content">
                <el-row type="flex" justify="end">
                  <div class="main-actions">
                    <el-button
                      v-can="'quotation.read'"
                      size="medium"
                      type="success"
                      class="btn-green-lg"
                      @click="onClickExportExcelByFilter()"
                      :loading="isLoadingDownloadExcel"
                    >
                      <i class="el-icon-download" style="color: white"></i>
                      {{ $i18n.t('src.modules.supplier.index.btnDownloadExcel') }}
                    </el-button>
                  </div>
                  <div class="main-actions">
                    <el-button
                      v-can="'quotation.add'"
                      size="medium"
                      type="primary"
                      class="btn-blue-lg"
                      @click="onClickShowAddQuotationPopup()"
                    >
                      <i class="el-icon-circle-plus-outline"></i>
                      {{ $t('src.modules.quotation.index.btnAdd') }}
                    </el-button>
                  </div>
                  <div class="main-actions">
                    <el-button
                      v-can="'quotation.delete'"
                      size="medium"
                      type="danger"
                      class="btn-red-lg"
                      @click="onClickDeleteData()"
                      :disabled="isDisabledButtonDelete"
                    >
                      <i class="el-icon-delete"></i>
                      {{ $t('src.modules.news.index.btnDelete') }}
                    </el-button>
                  </div>
                  <div class="main-actions">
                    <el-button
                      size="medium"
                      type="info"
                      class="btn-black-lg"
                      @click="onClickSearchQuotation()"
                    >
                      <i class="el-icon-search"></i>&nbsp;
                      {{ $t('src.modules.news.index.btnSearch') }}</el-button
                    >
                  </div>
                </el-row>
              </div>
            </el-col>
          </el-row>
          <!-- Button Action (E) -->

          <!-- Table Quotations Data (S) -->
          <el-row>
            <el-table
              v-loading="isLoading"
              :data="pagedTableData"
              height="600"
              :default-sort="{ prop: 'updatedAt', order: 'descending' }"
              style="width: 100%"
              @selection-change="handleSelectionChange"
              :cell-style="{height: '95px'}"
            >
              <!-- Check box (S) -->
              <el-table-column
                type="selection"
                width="50"
              ></el-table-column>
              <!-- Check box (E) -->

              <!-- Id (S) -->
              <!-- <el-table-column
                fixed="left"
                prop="id"
                label="#"
                width="50"
              ></el-table-column> -->
              <!-- Id (E) -->

              <!-- Quotation Code (S) -->
              <el-table-column
                prop="code"
                :label="$i18n.t('src.modules.quotation.index.quotationCode')"
                width="200"
                sortable
                fixed="left"
              >
              </el-table-column>
              <!-- Quotation Code (E) -->

              <!-- customerName (S) -->
              <el-table-column
                prop="customerName"
                :label="$i18n.t('src.modules.quotation.index.customerName')"
                width="200"
                sortable
                fixed="left"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="scope.row.customerName"
                    placement="top"
                    v-if="scope.row.customerName && scope.row.customerName.length > 50"
                  >
                    <span>{{ scope.row.customerName.slice(0, 50) + '...' }}</span>
                  </el-tooltip>
                  <span v-else>
                    {{ scope.row.customerName }}
                  </span>
                </template>
              </el-table-column>
              <!-- customerName (E)-->

              <!-- Customer Address (S) -->
              <el-table-column
                prop="deliveryAddress"
                :label="$i18n.t('src.modules.quotation.index.customerAddress')"
                width="220"
                sortable
              >
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="scope.row.deliveryAddress"
                    placement="top"
                    v-if="
                      scope.row.deliveryAddress && scope.row.deliveryAddress.length > 50
                    "
                  >
                    <span>{{ scope.row.deliveryAddress.slice(0, 50) + '...' }}</span>
                  </el-tooltip>
                  <span v-else>
                    {{ scope.row.deliveryAddress }}
                  </span>
                </template>
              </el-table-column>
              <!-- Customer Address (E)-->

              <!-- Sales (S) -->
              <!-- <el-table-column
                prop="salesName"
                :label="$i18n.t('src.modules.quotation.index.sales')"
                width="180"
                sortable
              >
                <template slot-scope="scope">
                  <el-tag
                    style="margin-right: 3px"
                    v-for="item in salesNameArr(scope.row.salesInChargeIdList)"
                    :key="item.value" 
                    size="mini"
                  >
                    <el-tooltip 
                      effect="dark" 
                      :content="item.label" 
                      placeholder="bottom" 
                      v-if="item.label && item.label.length > 20"
                    >
                      <span>{{ item.label | truncate(20) }}</span>
                    </el-tooltip>
                    <span v-else>
                      {{ item.label }}
                    </span>
                  </el-tag>
                </template>
              </el-table-column> -->
              <!-- Sales (E)-->

              <!-- BD (S) -->
              <el-table-column
                prop="BDName"
                :label="$i18n.t('src.modules.quotation.index.BD')"
                width="180"
                sortable
              >
                <template slot-scope="scope">
                  <el-tag
                    style="margin-right: 3px"
                    v-for="item in BDNameArr(scope.row.bdInChargeIdList)"
                    :key="item.value" 
                    size="mini"
                  >
                    <el-tooltip 
                      effect="dark" 
                      :content="item.label" 
                      placeholder="bottom" 
                      v-if="item.label && item.label.length > 20"
                    >
                      <span>{{ item.label | truncate(20) }}</span>
                    </el-tooltip>
                    <span v-else>
                      {{ item.label }}
                    </span>
                  </el-tag>
                </template>
              </el-table-column>
              <!-- BD (E)-->

              <!-- Created At (S) -->
              <el-table-column
                prop="createdAt"
                :label="$i18n.t('src.modules.quotation.createdAt')"
                width="130"
                sortable
              >
                <template slot-scope="scope">
                  {{ scope.row.createdAt | formattedDate }}
                </template>
              </el-table-column>
              <!-- Created At (E) -->

              <!-- User Created (S) -->
              <el-table-column
                prop="userCreatedName"
                :label="$i18n.t('src.modules.quotation.index.createdBy')"
                width="180"
                sortable
              >
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="scope.row.userCreatedName"
                    placement="top"
                    v-if="
                      scope.row.userCreatedName && scope.row.userCreatedName.length > 50
                    "
                  >
                    <span>{{ scope.row.userCreatedName.slice(0, 50) + '...' }}</span>
                  </el-tooltip>
                  <span v-else>
                    {{ scope.row.userCreatedName }}
                  </span>
                </template>
              </el-table-column>
              <!-- User Created (E)-->

              <!-- Total (S) -->
              <el-table-column
                fixed="right"
                sortable
                :label="$i18n.t('src.modules.quotation.index.total')"
                width="150"
              >
                <template slot-scope="scope">
                  {{
                    new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND'
                    }).format(scope.row.total)
                  }}
                </template>
              </el-table-column>
              <!-- Total (E) -->

              <!-- Quotation Status (S) -->
              <el-table-column
                fixed="right"
                prop="status"
                :label="$i18n.t('src.modules.quotation.index.quotationStatus')"
                width="200"
              >
                <template slot-scope="scope">
                  <el-select
                    size="medium"
                    :value="scope.row.quotationStatusId"
                    filterable
                    class="select_quotation_status"
                    @change="handleChangeQuotationStatus(scope.row.id, $event)"
                    :disabled="isSupplier"
                    style="width:75%; cursor: pointer"
                  >
                    <template slot="prefix">
                      <el-tag
                        :disable-transitions="true"
                        :key="scope.row.status"
                        :type="scope.row.statusColor"
                        effect="dark"
                        style="padding-right: 10px"
                      >
                        {{ scope.row.status }}
                      </el-tag>
                    </template>
                    <el-option
                      v-can="'quotation.edit'"
                      v-for="item in quotationStatusList"
                      :key="item.value"
                      :value="item.value"
                    >
                      <span>
                        <el-tag
                          :disable-transitions="true"
                          :key="item.label"
                          :type="item.color"
                          effect="dark"
                        >
                          {{ item.label }}
                        </el-tag>
                      </span>
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <!-- Quotation Status (E)  -->

              <!-- Quotation Cancel Reason Code (S) -->
              <el-table-column
                prop="quotationCancelReasonCode"
                :label="$i18n.t('src.modules.quotation.index.quotationCancelReasonCode')"
                width="180"
                sortable
              >
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="scope.row.cancelReason"
                    placement="top"
                    v-if="isHaveCancelReason(scope.row)"
                  >
                    <span>{{ getQuoCancelNameByCode(scope.row.quotationCancelReasonCode) || 'No Data' }}</span>
                  </el-tooltip>
                  <span v-else>
                    {{ getQuoCancelNameByCode(scope.row.quotationCancelReasonCode) }}
                  </span>
                </template>
              </el-table-column>
              <!-- Quotation Cancel Reason Code (E) -->

              <!-- Actions (S) -->
              <el-table-column
                fixed="right"
                :label="$i18n.t('src.modules.customer.index.table.col.action')"
                width="120"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Show Quotation Detail"
                    placement="top-start"
                    v-if="isSupplier"
                  >
                    <el-button
                      v-can="'quotation.edit'"
                      type="primary"
                      icon="el-icon-document"
                      circle
                      size="mini"
                      class="btn-blue-md"
                      @click="$router.push(`/quotation/${scope.row.id}`)"
                    >
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Show Quotation Detail"
                    placement="top-start"
                  >
                    <el-button
                      v-can="'quotation.edit'"
                      type="primary"
                      icon="el-icon-edit"
                      circle
                      size="mini"
                      class="btn-blue-sm"
                      @click="$router.push(`/quotation/${scope.row.id}`)"
                    >
                      {{ $i18n.t('src.modules.quotation.index.button.edit') }}
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Remove Row"
                    placement="top-start"
                  >
                    <el-button
                      v-can="'quotation.delete'"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      size="mini"
                      class="btn-red-sm"
                      @click="onClickRemove(scope)"
                    >
                      {{ $i18n.t('src.modules.quotation.index.button.delete') }}
                    </el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
              <!-- Actions (E) -->
            </el-table>
          </el-row>
          <!-- Table Customers Data (E) -->

          <!-- Paging (S) -->
          <el-row type="flex" justify="center">
            <el-col>
              <div class="grid-content bg-purple">
                <el-pagination
                  class="pagination"
                  @current-change="changePage"
                  :page-size="pageSize"
                  :current-page="currentPage"
                  layout="prev, pager, next"
                  :total="totalQuotationCnt"
                >
                </el-pagination>
              </div>
            </el-col>
          </el-row>
          <!-- Paging (E) -->
        </Widget>

        <!-- Popup Add Quotation (S) -->
        <el-dialog
          :title="$i18n.t('src.modules.quotation.index.AddQuotationFormTitle')"
          :visible.sync="addQuotationDialogVisible"
          :before-close="handleCloseAddForm"
          width="45%"
          :close-on-click-modal="false"
        >
          <el-form
            ref="formQuotation"
            :rules="rulesAddQuotationForm"
            :model="formQuotation"
            label-width="160px"
          >
            <!-- isImportant (S) -->
            <el-form-item prop="isImportant">
              <el-switch
                v-model="formQuotation.isImportant"
                active-text="Important"
                inactive-text="Normal"
              >
              </el-switch>
            </el-form-item>
            <!-- isImportant (E) -->

            <!-- Customer (S) -->
            <div style="display:flex;">
              <div style="flex: 1 1 auto">
                <el-form-item
                  :label="$i18n.t('src.modules.customer.index.customerName')"
                  prop="customerId"
                >
                  <el-select
                    size="medium"
                    clearable
                    v-model="formQuotation.customerId"
                    placeholder="Select Customer"
                    width="100%"
                    filterable
                    :remote="true"
                    :remote-method="data => customersFilterByMultiField(data)"
                    :loading="customersDataLoading"
                    @change="onChangeSelectCustomer($event)"
                    @visible-change="onBlurSelectCustomer"
                  >
                    <el-option
                      v-for="item in customersList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                
              </div>
              <!-- Customer Filter Method (S) -->
              <div style="margin-left:8px; padding-top: 2px; width: 28%">
                <el-select
                  size="medium"
                  v-model="customerFilterMethodArr"
                  collapse-tags
                  multiple
                  placeholder="Filter Method"
                >
                  <el-option
                    v-for="item in filterMethodList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- Customer Filter Method (E) -->
            </div>
            
            <!-- Customer (E) -->

            <!-- Sales (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.quotation.index.sales')"
              prop="salesInChargeId"
            >
              <el-select
                size="medium"
                clearable
                multiple
                collapse-tags
                v-model="formQuotation.salesInChargeIdList"
                placeholder="Select Sales Incharge"
                width="100%"
                filterable
              >
                <el-option
                  v-for="item in salesList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- Sales (E) -->

            <!-- BD (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.quotation.index.BD')"
              prop="bdInChargeId"
            >
              <el-select
                size="medium"
                clearable
                multiple
                collapse-tags
                v-model="formQuotation.bdInChargeIdList"
                placeholder="Select BD Incharge"
                width="100%"
                filterable
              >
                <el-option
                  v-for="item in BDList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- BD (E) -->

            <!-- Note (S) -->
            <el-form-item :label="$i18n.t('src.modules.quotation.note')" prop="note">
              <el-input
                size="medium"
                type="textarea"
                :rows="2"
                placeholder="Note"
                v-model="formQuotation.note"
              />
            </el-form-item>
            <!-- Note (E) -->

            <!-- Person Incharge (S) -->
            <div style="display:flex;">
              <div style="flex: 1 1 auto">
                <el-form-item
                  :label="$i18n.t('src.modules.quotation.index.personIncharge')"
                  prop="personInChargeId"
                >
                  <el-select
                    size="medium"
                    clearable
                    v-model="formQuotation.personInChargeId"
                    placeholder="Select Person Incharge"
                    width="100%"
                    filterable
                    :disabled="!formQuotation.customerId"
                    :loading="selectLoading"
                  >
                    <el-option
                      v-for="item in personInChargeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div style="margin-left:8px; padding-top: 2px">
                <!-- Button add new person Incharge (S) -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Add new"
                  placement="top-start"
                >
                  <el-button
                    size="medium"
                    type="primary"
                    icon="el-icon-edit"
                    @click="onClickShowAddPersonInchargePopup()"
                    :disabled="!formQuotation.customerId"
                  >
                  </el-button>
                </el-tooltip>

                <!-- Button add new person Incharge (E) -->
              </div>
            </div>

            <!-- Person Incharge (E) -->

            <!-- Customer Address (S) -->
            <div style="display:flex;">
              <div style="flex: 1 1 auto">
                <el-form-item
                  :label="$i18n.t('src.modules.quotation.index.customerAddress')"
                  prop="customerAddressId"
                >
                  <el-select
                    size="medium"
                    clearable
                    v-model="formQuotation.customerAddressId"
                    placeholder="Select Customer Address"
                    width="100%"
                    filterable
                    :disabled="!formQuotation.customerId"
                    :loading="selectLoading"
                  >
                    <el-option
                      v-for="item in customerAddressList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div style="margin-left:8px; padding-top: 2px">
                <!-- Button add new Customer Address (S) -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Add new"
                  placement="top-start"
                >
                  <el-button
                    size="medium"
                    type="primary"
                    icon="el-icon-edit"
                    @click="onClickShowAddCustomerAddressPopup()"
                    :disabled="!formQuotation.customerId"
                  >
                  </el-button>
                </el-tooltip>
                <!-- Button add new person Incharge (E) -->
              </div>
            </div>
            <!-- Customer Address (E) -->

            <!-- Customer Representative (S) -->
            <div style="display:flex;">
              <div style="flex: 1 1 auto">
                <el-form-item
                  :label="$i18n.t('src.modules.quotation.index.customerRepresentative')"
                  prop="representativeId"
                >
                  <el-select
                    size="medium"
                    clearable
                    v-model="formQuotation.representativeId"
                    placeholder="Select Customer Representative"
                    width="100%"
                    filterable
                    :disabled="!this.formQuotation.customerId"
                  >
                    <el-option
                      v-for="item in customerRepresentativeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div style="margin-left:8px; padding-top: 2px">
                <!-- Button add new Customer Representative (S) -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Add new"
                  placement="top-start"
                >
                  <el-button
                    size="medium"
                    type="primary"
                    icon="el-icon-edit"
                    @click="onClickShowAddCustomerRepresentativePopup()"
                    :disabled="!formQuotation.customerId"
                  >
                  </el-button>
                </el-tooltip>

                <!-- Button add new Customer Representative (E) -->
              </div>
            </div>
            <!-- Customer Representative (E) -->
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-row :gutter="24">
              <el-col :span="4">
                <el-button size="medium" @click="handleCloseAddForm">{{
                  $i18n.t('src.modules.news.index.close')
                }}</el-button>
              </el-col>
              <el-col l :span="20">
                <el-button size="medium" type="primary" @click="onClickAddQuotation()">{{
                  $i18n.t('src.modules.tag.index.btnAdd')
                }}</el-button>
              </el-col>
            </el-row>
          </span>
        </el-dialog>
        <!-- Popup Add Quotation (E) -->

        <!-- Popup Add New Person Incharge (S) -->
        <el-dialog
          :title="$i18n.t('src.modules.quotation.index.AddPersonInchargeFormTitle')"
          :visible.sync="addPersonInchargeDialogVisible"
          :before-close="handleCloseAddPersonInchargeForm"
          width="45%"
          :close-on-click-modal="false"
        >
          <el-form
            ref="formAddPersonIncharge"
            :rules="rulesPersonInchargeForm"
            :model="formAddPersonIncharge"
            label-width="200px"
          >
            <!-- isDefault (S) -->
            <el-form-item prop="isDefault">
              <el-switch
                v-model="formAddPersonIncharge.isDefault"
                active-text="Default"
                inactive-text="Normal"
                active-value="true"
                inactive-value="false"
              >
              </el-switch>
            </el-form-item>
            <!-- isDefault (E) -->

            <!-- Name (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.quotation.personInchargeForm.name')"
              prop="name"
            >
              <CustomInput
                size="medium"
                placeholder="Name"
                v-model="formAddPersonIncharge.name"
              >
              </CustomInput>
            </el-form-item>
            <!-- Name (E) -->

            <!-- Email (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.quotation.personInchargeForm.email')"
              prop="email"
            >
              <el-input
                size="medium"
                type="email"
                placeholder="Email"
                v-model="formAddPersonIncharge.email"
              />
            </el-form-item>
            <!-- Email (E) -->

            <!-- Phone (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.quotation.personInchargeForm.phone')"
              prop="phone"
            >
              <el-input
                size="medium"
                type="text"
                placeholder="Phone Number"
                v-model="formAddPersonIncharge.phone"
              />
            </el-form-item>
            <!-- Phone (E) -->

            <!-- Address (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.quotation.personInchargeForm.address')"
              prop="address"
            >
              <el-input
                size="medium"
                type="text"
                placeholder="Address"
                v-model="formAddPersonIncharge.address"
              />
            </el-form-item>
            <!-- Address (E) -->

            <!-- Note (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.quotation.personInchargeForm.note')"
              prop="note"
            >
              <el-input
                size="medium"
                type="textarea"
                :rows="2"
                placeholder="Note"
                v-model="formAddPersonIncharge.note"
              />
            </el-form-item>
            <!-- Note (E) -->
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-row :gutter="24">
              <el-col :span="4">
                <el-button size="medium" @click="handleCloseAddPersonInchargeForm">{{
                  $i18n.t('src.modules.news.index.close')
                }}</el-button>
              </el-col>
              <el-col l :span="20">
                <el-button
                  size="medium"
                  type="primary"
                  @click="onClickAddRowPersonIncharge()"
                  >{{ $i18n.t('src.modules.tag.index.btnAdd') }}</el-button
                >
              </el-col>
            </el-row>
          </span>
        </el-dialog>
        <!-- Popup Add New Person Incharge (E) -->

        <!-- Popup Add New Customer Address (S) -->
        <el-dialog
          :title="$i18n.t('src.modules.quotation.index.AddCustomerAddressFormTitle')"
          :visible.sync="addCustomerAddressDialogVisible"
          :before-close="handleCloseAddCustomerAddressForm"
          width="45%"
          :close-on-click-modal="false"
        >
          <el-form
            ref="formAddCustomerAddress"
            :rules="rulesCustomerAddressForm"
            :model="formAddCustomerAddress"
            label-width="200px"
          >
            <!-- isDefault (S) -->
            <el-form-item prop="isDefault">
              <el-switch
                v-model="formAddCustomerAddress.isDefault"
                active-text="Default"
                inactive-text="Normal"
                active-value="true"
                inactive-value="false"
              >
              </el-switch>
            </el-form-item>
            <!-- isDefault (E) -->

            <!-- companyName (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.customerAddress.index.companyName')"
              prop="companyName"
            >
              <CustomInput
                size="medium"
                type="text"
                class="input_add"
                v-model="formAddCustomerAddress.companyName"
              >
              </CustomInput>
            </el-form-item>
            <!-- companyName (E) -->

            <!-- receiverName (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.customerAddress.index.receiverName')"
              prop="receiverName"
            >
              <CustomInput
                size="medium"
                type="text"
                class="input_add"
                v-model="formAddCustomerAddress.receiverName"
              >
              </CustomInput>
            </el-form-item>
            <!-- receiverName (E) -->

            <!-- email (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.customer.index.email')"
              prop="email"
            >
              <el-input
                size="medium"
                type="email"
                class="input_add"
                v-model="formAddCustomerAddress.email"
              />
            </el-form-item>
            <!-- email (E) -->

            <!-- phone (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.customer.index.phone')"
              prop="phone"
            >
              <el-input
                size="medium"
                type="text"
                class="input_add"
                v-model="formAddCustomerAddress.phone"
              />
            </el-form-item>
            <!-- phone (E) -->

            <!-- address (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.customerAddress.index.address')"
              prop="address"
            >
              <el-input
                size="medium"
                class="input_add"
                v-model="formAddCustomerAddress.address"
              />
            </el-form-item>
            <!-- address (E) -->

            <!-- province (S)  -->
            <el-form-item
              :label="$i18n.t('src.modules.customerAddress.index.province')"
              prop="province"
            >
              <el-select
                size="medium"
                clearable
                placeholder="Select Province"
                v-model="formAddCustomerAddress.province"
                width="100%"
                filterable
                @change="resetLocationForm('province')"
              >
                <el-option
                  v-for="item in provinceList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- province (E)  -->

            <!-- district (S)  -->
            <el-form-item
              :label="$i18n.t('src.modules.customerAddress.index.district')"
              prop="district"
            >
              <el-select
                size="medium"
                clearable
                placeholder="Select District"
                v-model="formAddCustomerAddress.district"
                width="100%"
                filterable
                @change="resetLocationForm('district')"
              >
                <el-option
                  v-for="item in districtListInForm"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- district (E)  -->

            <!-- ward (S)  -->
            <el-form-item
              :label="$i18n.t('src.modules.customerAddress.index.ward')"
              prop="ward"
            >
              <el-select
                size="medium"
                clearable
                placeholder="Select Ward"
                v-model="formAddCustomerAddress.ward"
                width="100%"
                filterable
              >
                <el-option
                  v-for="item in wardListInForm"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- ward (E)  -->
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-row :gutter="24">
              <el-col :span="4">
                <el-button size="medium" @click="handleCloseAddCustomerAddressForm">{{
                  $i18n.t('src.modules.news.index.close')
                }}</el-button>
              </el-col>
              <el-col l :span="20">
                <el-button
                  size="medium"
                  type="primary"
                  @click="onClickAddRowCustomerAddress()"
                  >{{ $i18n.t('src.modules.tag.index.btnAdd') }}</el-button
                >
              </el-col>
            </el-row>
          </span>
        </el-dialog>
        <!-- Popup Add New Customer Address (E) -->

        <!-- Popup Add New Customer Representative(S) -->
        <el-dialog
          :title="
            $i18n.t('src.modules.quotation.index.AddCustomerRepresentativeFormTitle')
          "
          :visible.sync="addCustomerRepresentativeDialogVisible"
          :before-close="handleCloseAddCustomerRepresentativeForm"
          width="45%"
          :close-on-click-modal="false"
        >
          <el-form
            ref="formAddCustomerRepresentative"
            :rules="rulesCustomerRepresentativeForm"
            :model="formAddCustomerRepresentative"
            label-width="200px"
          >
            <!-- isDefault (S) -->
            <el-form-item prop="isDefault">
              <el-switch
                v-model="formAddCustomerRepresentative.isDefault"
                active-text="Default"
                inactive-text="Normal"
                active-value="true"
                inactive-value="false"
              >
              </el-switch>
            </el-form-item>
            <!-- isDefault (E) -->

            <!-- Name (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.quotation.customerRepresentativeForm.name')"
              prop="name"
            >
              <CustomInput
                size="medium"
                type="text"
                class="input_add"
                v-model="formAddCustomerRepresentative.name"
              >
              </CustomInput>
            </el-form-item>
            <!-- Name (E) -->

            <!-- Title (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.quotation.customerRepresentativeForm.title')"
              prop="title"
            >
              <el-input
                size="medium"
                type="text"
                class="input_add"
                v-model="formAddCustomerRepresentative.title"
              />
            </el-form-item>
            <!-- Title (E) -->

            <!-- email (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.customer.index.email')"
              prop="email"
            >
              <el-input
                size="medium"
                type="email"
                class="input_add"
                v-model="formAddCustomerRepresentative.email"
              />
            </el-form-item>
            <!-- email (E) -->

            <!-- phone (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.customer.index.phone')"
              prop="phone"
            >
              <el-input
                size="medium"
                type="text"
                class="input_add"
                v-model="formAddCustomerRepresentative.phone"
              />
            </el-form-item>
            <!-- phone (E) -->

            <!-- address (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.customerAddress.index.address')"
              prop="address"
            >
              <el-input
                size="medium"
                class="input_add"
                v-model="formAddCustomerRepresentative.address"
              />
            </el-form-item>
            <!-- address (E) -->

            <!-- Note (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.quotation.personInchargeForm.note')"
              prop="note"
            >
              <el-input
                size="medium"
                type="textarea"
                :rows="2"
                placeholder="Note"
                v-model="formAddCustomerRepresentative.note"
              />
            </el-form-item>
            <!-- Note (E) -->
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-row :gutter="24">
              <el-col :span="4">
                <el-button
                  size="medium"
                  @click="handleCloseAddCustomerRepresentativeForm"
                  >{{ $i18n.t('src.modules.news.index.close') }}</el-button
                >
              </el-col>
              <el-col l :span="20">
                <el-button
                  size="medium"
                  type="primary"
                  @click="onClickAddRowCustomerRepresentative()"
                  >{{ $i18n.t('src.modules.tag.index.btnAdd') }}</el-button
                >
              </el-col>
            </el-row>
          </span>
        </el-dialog>
        <!-- Popup Add New Customer Representative (E) -->

        <!-- Popup Confirm Cancel Quotation (S) -->
        <el-dialog
          v-loading="isLoading"
          :title="$i18n.t('src.modules.quotation.index.dialog.title.dialogConfirmCancelQuotation')"
          :visible.sync="dialogConfirmCancelQuotationVisible"
          width="50%"
          :before-close="emitCloseConfirmCancelQuotationPopup"
          :append-to-body="true"
        >
          <el-row>
            <el-select
              size="medium"
              clearable
              placeholder="Select Reason"
              v-model="formQuotation.quotationCancelReasonCode"
              width="100%"
              filterable
              @change="onchangeQuotationCancelStatus"
            >
              <el-option
                v-for="item in getListQuotationCancelStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-row>
          <el-row v-if="isShowCancelReasonText">
            <span>{{$i18n.t('src.modules.quotation.index.cancelStatus.title')}}</span>
            <el-input
              size="medium"
              type="textarea"
              :rows="3"
              placeholder="Note"
              v-model="formQuotation.cancelReason"
            />
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button
              size="medium"
              type="success"
              @click="onClickSaveCancelQuotationStatus()"
            >
              OK
            </el-button>
            <el-button @click="emitCloseConfirmCancelQuotationPopup">{{
              $i18n.t('src.components.modal.selectProduct.tableProduct.close')
            }}
            </el-button>
          </span>
        </el-dialog>
        <!-- Popup Confirm Cancel Quotation (E) -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Widget from 'components/Widget/Widget';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import vnLocal from 'vn-local';
import { validatePhone } from 'core/helpers';
import { QUOTATION_STATUS, QUOTATION_STATUS_COLOR, CUSTOMER_FILTER_METHOD, QUOTATION_DA_HUY, QUOTATION_CANCEL_REASON_CODE } from 'core/constants';
import CustomInput from 'components/CustomInput';
import LoadMoreSelect from '../../core/directives/loadMoreSelect';
import XLSX from 'xlsx';

export default {
  components: { Widget, CustomInput },
  directives: {
    LoadMoreSelect,
  },
  data() {
    return {
      isLoadingDownloadExcel: false,
      isLoading: true,
      selectLoading: true,
      isSupplier: false,
      customersDataLoading: true,
      quotationCodeDataLoading: true,
      customerIdListInPage: [],
      customerFilterMethodArr: [CUSTOMER_FILTER_METHOD.CUSTOMER_NAME.value],
      dialogConfirmCancelQuotationVisible: false,
      valueCancelQuotationReasonId: '',
      quotationIdEditing: null,
      // Paging
      currentPage: 1,
      pageSize: 10,
      lang: 'vi_VN',
      isShowCancelReasonText: false,

      page: 1,
      limit: 20,
      hasMore: false,
      keywordQuotationCode: '',
      canLoadMore: true,

      formSearch: {
        customerId: '',
        bdInChargeId: '',
        createdAt: '',
        quotationStatusId: '',
        quotationCode: ''
      },

      quotationSelectedList: [],

      // form add new quotation
      addQuotationDialogVisible: false,
      formQuotation: {
        isImportant: false,
        customerId: null,
        salesInChargeIdList: [],
        bdInChargeIdList: [],
        note: null,
        personInChargeId: null,
        customerAddressId: null,
        representativeId: null,
        quotationCancelReasonCode: null,
        cancelReason: null
      },
      formQuotationDefault: {
        isImportant: false,
        customerId: null,
        salesInChargeIdList: [],
        bdInChargeIdList: [],
        note: null,
        personInChargeId: null,
        customerAddressId: null,
        representativeId: null,
        quotationCancelReasonCode: null,
        cancelReason: null
      },

      // form add new person incharge
      addPersonInchargeDialogVisible: false,
      formAddPersonIncharge: {
        customerId: null,
        name: null,
        email: null,
        phone: null,
        address: null,
        note: null,
        isDefault: null
      },
      formAddPersonInchargeDefault: {
        customerId: null,
        name: null,
        email: null,
        phone: null,
        address: null,
        note: null,
        isDefault: null
      },

      // form add new customer address
      addCustomerAddressDialogVisible: false,
      formAddCustomerAddress: {
        customerId: null,
        companyName: null,
        receiverName: null,
        province: null,
        district: null,
        ward: null,
        email: null,
        phone: null,
        address: null,
        isDefault: null
      },
      formAddCustomerAddressDefault: {
        customerId: null,
        companyName: null,
        receiverName: null,
        province: null,
        district: null,
        ward: null,
        email: null,
        phone: null,
        address: null,
        isDefault: null
      },

      // form add Customer Representative
      addCustomerRepresentativeDialogVisible: false,
      formAddCustomerRepresentative: {
        customerId: null,
        name: null,
        title: null,
        email: null,
        phone: null,
        address: null,
        note: null,
        isDefault: null
      },
      formAddCustomerRepresentativeDefault: {
        customerId: null,
        name: null,
        title: null,
        email: null,
        phone: null,
        address: null,
        note: null,
        isDefault: null
      },

      rulesPersonInchargeForm: {
        name: [
          {
            required: true,
            message: 'Please enter name',
            trigger: 'change'
          }
        ],
        email: [
          {
            type: 'email',
            message: 'Please enter the correct email',
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            message: 'Please enter the correct phone format',
            trigger: 'change'
          }
        ]
      },
      rulesAddQuotationForm: {
        customerId: [
          {
            required: true,
            message: 'Customer is required',
            trigger: 'blur'
          }
        ]
      },
      rulesCustomerAddressForm: {
        companyName: [
          {
            required: true,
            message: 'Please enter company name',
            trigger: 'blur',
          }
        ],
        receiverName: [
          {
            required: true,
            message: 'Please enter name',
            trigger: 'blur',
          }
        ],
        email: [
          {
            required: true,
            type: 'email',
            message: 'Please enter the correct email',
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            message: 'Please enter the correct phone format',
            trigger: 'change'
          }
        ],
        address: [{ required: true, message: 'Please enter address', trigger: 'blur' }],
        province: [{ required: true, message: 'Please enter province', trigger: 'blur' }],
        district: [{ required: true, message: 'Please enter district', trigger: 'blur' }],
        ward: [{ required: true, message: 'Please enter ward', trigger: 'blur' }]
      },
      rulesCustomerRepresentativeForm: {
        name: [{ required: true, message: 'Please enter name', trigger: 'blur' }],
        email: [
          { type: 'email', message: 'Please enter the correct email', trigger: 'change' }
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            message: 'Please enter the correct phone format',
            trigger: 'change'
          }
        ]
      }
    };
  },
  
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('customer', ['customers', 'customersAddLocal', 'customerMap']),
    ...mapState('quotation', ['quotations', 'quotationsByQuotationCode', 'quotationDataExportExcel']),
    ...mapState('user', ['users', 'usersMap']),
    ...mapState('quotationStatus', ['quotationStatusArray', 'quotationStatusMap']),
    ...mapState('customerAddress', ['customerAddress', 'customerAddressMapWithCustomer']),
    ...mapState('personInCharge', ['personInChargeArray']),
    ...mapState('customerRepresentative', ['customerRepresentativeArray']),

    ...mapState('common', ['listCommons', 'quoCancelReasonMap']),

    

    getListQuotationCancelStatus(){
      return this.listCommons.map(item => {
        const { name, code } = item;
        const nameParse = JSON.parse(name);
        const label = this.lang === 'vi-VN' && nameParse ? nameParse.vi_VN : nameParse.en_US || 'No Data';

        return {
          value: code,
          label,
          key: code
        };
      });
    },

    filterMethodList() {
      const object = CUSTOMER_FILTER_METHOD || {};
      const methodFilterArr = Object.keys(object).map(key => {
        this.$i18n.te()
        const value = object[key].value;
        const label = object[key].label;
        const labelTranslate = 
        this.$i18n.te(`src.modules.quotation.index.filterMethod.${label}`) 
        ? this.$i18n.t(`src.modules.quotation.index.filterMethod.${label}`)
        : label;

        return {
          label: labelTranslate,
          value: value
        };
      });
      return methodFilterArr;
    },

    isDisabledButtonDelete() {
      return !this.quotationSelectedList.length > 0;
    },

    quotationByCodeList(){
      return this.quotationsByQuotationCode.map(item => {
        return {
          value: item.code,
          label: item.code,
          key: item.id
        };
      });
    },

    customerRepresentativeList() {
      return this.customerRepresentativeArray.map(item => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },

    personInChargeList() {
      return this.personInChargeArray.map(item => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },

    customerAddressList() {
      return this.customerAddress.map(item => {
        let address = item?.address || '';
        if (item?.district) {
          const district = vnLocal.getWardsByDistrictCode(item.district);

          let districtName = district?.[0]?.districtName || '';
          let provinceName = district?.[0]?.provinceName || '';
          let wardName =
            (item.ward && district?.find(x => x.code === item.ward)?.name) || '';
          address = `${address ? address : ''}${wardName ? ', ' + wardName : ''}${
            districtName ? ', ' + districtName : ''
          }${provinceName ? ', ' + provinceName : ''}`;
        }
        item.deliveryAddress = address || 'No Data';

        return {
          value: item.id,
          label: item.deliveryAddress
        };
      });
    },

    quotationStatusList() {
      return this.quotationStatusArray.map(item => {
        return {
          value: item.id,
          label: QUOTATION_STATUS[item.id],
          color: QUOTATION_STATUS_COLOR[item.id]
        };
      });
    },

    customersList() {
      return this.customers.map(item => {
        return {
          value: item.id,
          label: item.customerName
        };
      });
    },

    salesList() {
      return this.users.map(item => {
        return {
          value: item.id,
          label: item.username
        };
      });
    },

    BDList() {
      return this.users.map(item => {
        return {
          value: item.id,
          label: item.username
        };
      });
    },

    provinceList() {
      return vnLocal.getProvinces()?.map(item => {
        return {
          value: item.code,
          label: item.name
        };
      });
    },

    districtListInForm() {
      return this.formAddCustomerAddress.province
        ? vnLocal
            .getDistrictsByProvinceCode(this.formAddCustomerAddress.province)
            ?.map(item => {
              return {
                value: item.code,
                label: item.name
              };
            })
        : [];
    },

    wardListInForm() {
      return this.formAddCustomerAddress.district
        ? vnLocal
            .getWardsByDistrictCode(this.formAddCustomerAddress.district)
            ?.map(item => {
              return {
                value: item.code,
                label: item.name
              };
            })
        : [];
    },

    allQuotations() {
      return cloneDeep(this.quotations);
    },

    // Paging
    pagedTableData() {
      const customerAddressMap = this.customerAddressMapWithCustomer || {};
      this.resetCustomerIdListInPage();
      return this.allQuotations
        .slice(
          this.pageSize * this.currentPage - this.pageSize,
          this.pageSize * this.currentPage
        )
        .map(item => {
          const { customerId, customerAddressId, cancelReason, quotationCancelReasonCode } = item
          if(this.customerIdListInPage.includes(customerId) === false){
            this.customerIdListInPage.push(customerId);
          }
          //generate address
          if(customerAddressMap[customerId]){
            const customerAddressArr = customerAddressMap[customerId];

            customerAddressArr.forEach(element => {

              const { district, ward, address, id } = element;

              if(customerAddressId === id && district){
                
                const districtInfo = vnLocal.getWardsByDistrictCode(district);
                const districtName = districtInfo?.[0]?.districtName || '';
                const provinceName = districtInfo?.[0]?.provinceName || '';
                const wardName =
                (ward && districtInfo?.find(x => x.code === ward)?.name) || '';

                item.deliveryAddress = `${address ? address + ', ' : ''}${
                  wardName ? wardName + ', ' : ''
                }${districtName ? districtName + ', ' : ''}${
                  provinceName ? provinceName : ''
                }`
              }else{
                item.deliveryAddress = 'No Data';
              };
            });

          }else{
            item.deliveryAddress = 'No Data'
          }

          // get user created name
          item.userCreatedName = item?.userCreated?.username || 'No Data';
          if (!item.userCreated && item.createdBy) {
            const id = item.createdBy;
            item.userCreatedName =
              (this.usersMap[id] && this.usersMap[id].username) || 'No Data';
          }

          // get customer name
          item.customerName = item?.customer?.customerName || 'No Data';
          //get customer name after add new address
          if (item.customerId && !item?.customer) {
            const id = item.customerId;
            item.customerName =
              (this.customerMap[id] && this.customerMap[id].customerName) || 'No Data';
          }

          // quotation status
          item.cancelReason = cancelReason; 
          item.quotationCancelReasonCode = quotationCancelReasonCode;
          item.status = QUOTATION_STATUS[item.quotationStatusId];
          item.statusColor = QUOTATION_STATUS_COLOR[item.quotationStatusId];

          return item;
        });
    },

    totalQuotationCnt() {
      return this.allQuotations.length || 0;
    }
  },
  
  watch: {
    personInChargeList() {
      this.formQuotation.personInChargeId = undefined;
      this.personInChargeArray.map(item => {
        // set default person incharge value
        if (item.isDefault === true) {
          this.formQuotation.personInChargeId = item.id;
        }
      });
    },

    customerAddressList() {
      this.formQuotation.customerAddressId = undefined;
      return this.customerAddress.map(item => {
        // set default value
        if (item.isDefault === true) {
          this.formQuotation.customerAddressId = item.id;
        }
      });
    },

    customerRepresentativeList() {
      this.formQuotation.representativeId = undefined;
      return this.customerRepresentativeArray.map(item => {
        //set representative value default
        if (item.isDefault === true) {
          this.formQuotation.representativeId = item.id;
        }
      });
    }
  },
  mounted(){

    // Detect when scrolled to bottom.
    // const listElm = document.querySelector('.infinite-load .el-select-dropdown__wrap');
    // console.log('listElm: ', listElm);
    // listElm.addEventListener('scroll', () => {
    //   console.log('scroll');
    //   if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        
    //     // this.loadMore();
    //     console.log('load more...........');
    //   }
    // });

    // Initially load some items.
    // this.loadMore();
    
  },
  async created() {
    if (Object.keys(this.user).length === 0) {
      await this.getMe();
    }
    if (this.user && this.user.supplierId > 0) {
      this.isSupplier = true;
      this.formSearch.supplierId = this.user.supplierId;
    }
    await Promise.all([
      this.getAllQuotations({ ...this.formSearch, salesInChargeId: this.user && this.user.id || '' }),
      this.getAllCustomers({}),
      this.getAllUsers(),
      this.getAllQuotationStatus(),
      this.getAllCommons({ comCode : 'QTTN_CC_STT_RS' })
    ]).then( async () => {
      await this.getAllCustomerAddressInPageCustomer({ customerIdArr: this.customerIdListInPage });
      this.isLoading = false;
      this.customersDataLoading = false;
      this.lang = localStorage.getItem('user-language');
    });
  },
  methods: {
    ...mapActions('customer', ['getAllCustomers']),
    ...mapActions('quotation', [
      'getAllQuotations',
      'saveEditQuotation',
      'addNewQuotation',
      'deleteQuotationList',
      'changeQuotationStatus',
      'getAllQuotationsByCode',
      'getAllQuotationDataByFilterForExportExcel'
    ]),
    ...mapActions('user', ['getAllUsers']),
    ...mapActions('auth', ['getMe']),
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('quotationStatus', ['getAllQuotationStatus']),
    ...mapActions('personInCharge', ['getAllPersonInCharge', 'saveAddPersonInCharge']),
    ...mapActions('customerRepresentative', [
      'getAllCustomerRepresentative',
      'saveAddCustomerRepresentative'
    ]),
    ...mapActions('customerAddress', [
      'getAllCustomerAddress',
      'saveAddCustomerAddress',
      'saveEditCustomerAddress',
      'deleteCustomerAddressList',
      'getAllCustomerAddressInPageCustomer'
    ]),
    ...mapActions('common', ['getAllCommons']),
    ...mapMutations('quotation',['SET_RESET_QUOTATION_CODE_FILTER']),

    async onClickExportExcelByFilter(){
      this.isLoadingDownloadExcel = true;
      const result = await this.getAllQuotationDataByFilterForExportExcel(this.formSearch);
      const data = this.quotationDataExportExcel;

      if(!result){
        this.$notify({
          title: 'Error',
          message: 'Something wrong',
          type: 'error'
        });
      };

      if(result && (result.length <= 0 || data.length <= 0)){
        this.$notify({
          title: 'Warning',
          message: 'Data is empty',
          type: 'Warning'
        });
        this.isLoadingDownloadExcel = false;
        return false
      };
      
      try {
        //generate sheet
        const ws = XLSX.utils.json_to_sheet(data);
        const setWidth = [
          {width: 20},
          {width: 20},
          {width: 25},
          {width: 20},
          {width: 30},
          {width: 20},
          {width: 20},
          {width: 20},
          {width: 20}
        ];

        ws['!cols'] = setWidth;

        //generate new book
        const wb = XLSX.utils.book_new();
        const fileName = `quotation_${Date.now()}.xlsx`;
        XLSX.utils.book_append_sheet(wb, ws, 'Report Quotation');

        //generate excel file
        XLSX.writeFile(wb, fileName, { bookType: 'xlsx', type: 'base64', bookSST: true });

        this.isLoadingDownloadExcel = false;
        return true;

      } catch (error) {
        console.log('error generate excel file: ', error);
        this.$notify({
          title: 'Error',
          message: error.message,
          type: 'error'
        });
        this.isLoadingDownloadExcel = false;
        return false;
      }
      
    },

    isHaveCancelReason(scope){
      const { quotationCancelReasonCode, cancelReason } = scope;
      return  quotationCancelReasonCode == QUOTATION_CANCEL_REASON_CODE.OTHER_REASON &&
              cancelReason && 
              cancelReason.length > 0
    },

    getQuoCancelNameByCode(code){
      if(code){
        const cancelReasonMapData = this.quoCancelReasonMap[code] || {};
        const dataParse = JSON.parse(cancelReasonMapData?.name || {});
        const nameReturn =  this.lang === 'vi-VN' && dataParse ? dataParse.vi_VN : dataParse.en_US || 'No Data';

        return nameReturn;
      };
      
      return 'No Data';
    },

    onchangeQuotationCancelStatus(code){
      //handle when change quotation status to cancel
      if(QUOTATION_CANCEL_REASON_CODE.OTHER_REASON == code){
        this.isShowCancelReasonText = true;
        return
      };

      this.isShowCancelReasonText = false;
      return

    },

    async onClickSaveCancelQuotationStatus(){
      const quotationCancelCode = this.formQuotation.quotationCancelReasonCode;
      const quotationCancelReasonText = this.formQuotation.cancelReason && this.formQuotation.cancelReason.trim() || '';

      //warning when not add cancel reason when save
      if(
        QUOTATION_CANCEL_REASON_CODE.OTHER_REASON == quotationCancelCode &&
        ( !quotationCancelReasonText || quotationCancelReasonText.length <= 0 )
      ){
        this.$notify({
          title: 'Warning',
          message: this.$i18n.t('src.modules.quotation.index.notAddCancelReasonText'),
          type: 'warning'
        });
        return
      };

      const result = await this.changeQuotationStatus({
        quotationStatusId: QUOTATION_DA_HUY,
        id: this.quotationIdEditing,
        cancelReason: quotationCancelReasonText,
        quotationCancelReasonCode: quotationCancelCode
      });
      const { status } = result;
      if (status) {
        this.$notify({
          title: 'Success',
          message: 'Change Quotation Status Success',
          type: 'success'
        });

        this.isShowCancelReasonText = false;
        this.dialogConfirmCancelQuotationVisible = false;

      } else {
        const { messageCode, messageContent } = result;

        const messageTranslate = 
          this.$i18n.te(`src.modules.quotation.index.changeStatus.error.${messageCode}`) ? 
          this.$i18n.t(`src.modules.quotation.index.changeStatus.error.${messageCode}`) :
          messageContent;
          
        this.$notify({
          title: 'Error',
          message: messageTranslate,
          type: 'error'
        });
      };

    },

    async loadMore(){
      if(this.canLoadMore === false){
        return
      };
      this.hasMore = true;
      this.page += 1;
      this.canLoadMore = false;
      const that = this;
      setTimeout( async function() {
        const result = await that.getAllQuotationsByCode({
          quotationCode: that.keywordQuotationCode,
          limit: that.limit,
          page: that.page
        });

        that.canLoadMore = true;
        that.hasMore = false;

        if(!result){
          that.canLoadMore = false;
        };
      }, 400);
    },

    salesNameArr(salesIdsArr){
      const arrReturn = [];
      const arrIds = salesIdsArr && salesIdsArr.split('|');

      if( Array.isArray(arrIds) && arrIds.length > 0){
        arrIds.map(async element => {
          this.users.map(item => {
            if (element == item.id) {
              const data = {
                value: item.id,
                label: item.username
              };
              arrReturn.push(data);
            }
          });
        });
      }

      return arrReturn;
    },

    BDNameArr(BDIdsArr){
      const arrReturn = [];
      const arrIds = BDIdsArr && BDIdsArr.split('|');

      if( Array.isArray(arrIds) && arrIds.length > 0){
        arrIds.map(async element => {
          this.users.map(item => {
            if (element == item.id) {
              const data = {
                value: item.id,
                label: item.username
              };
              arrReturn.push(data);
            }
          });
        });
      }

      return arrReturn;
    },

    resetCustomerIdListInPage(){
      this.customerIdListInPage = []
    },

    async quotationCodeFilter(string){
      if(string){
        this.SET_RESET_QUOTATION_CODE_FILTER();
        this.page = 1;
        this.keywordQuotationCode = string;
        this.canLoadMore = true;
          
        this.hasMore = true;
        await this.getAllQuotationsByCode({
          quotationCode: string,
          limit: this.limit,
          page: this.page
        });
        this.hasMore = false;
      };
    },

    async customersFilter(string) {
      if (string) {
        this.customersDataLoading = true;
        await this.getAllCustomers({ customerName: string });
        this.customersDataLoading = false;
      }
    },

    async customersFilterByMultiField(string){
      if(string){
        this.customersDataLoading = true;
        await this.getAllCustomers({ customerName: string, customerFilterMethodArr: this.customerFilterMethodArr });
        this.customersDataLoading = false;
      }
    },

    async onBlurSelectCustomer(status) {
      if (status === false) {
        //when select dropdown is disapear
        this.customersDataLoading = true;
        await this.getAllCustomers({});
        this.customersDataLoading = false;
      }
    },

    getStatusColor(id) {
      return QUOTATION_STATUS_COLOR[id] || '';
    },

    getStatus(id) {
      return QUOTATION_STATUS[id] || '';
    },

    onClickDeleteData() {
      this.$confirm('Do you want to remove quotations ?')
        .then(async () => {
          if (this.quotationSelectedList.length > 0) {
            const QuotationIdArr = this.quotationSelectedList.map(item => item.id);
            const result = await this.deleteQuotationList(QuotationIdArr);
            if (result) {
              this.$notify({
                title: 'Success',
                message: 'Delete quotations Success',
                type: 'success'
              });
              this.addressSelectedList = [];
            } else {
              this.$notify({
                title: 'Error',
                message: 'Can not remove quotations',
                type: 'error'
              });
            }
          } else {
            this.$notify({
              title: 'Error',
              message: 'Can not remove quotations',
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },

    async onClickSearchQuotation() {
      this.setGlobalReady(false);
      await this.getAllQuotations(this.formSearch);
      this.setGlobalReady(true);
    },

    // form add new quotation
    clearFormQuotation() {
      this.formQuotation = cloneDeep(this.formQuotationDefault);
    },

    async onChangeSelectCustomer(customerId) {
      this.clearFormQuotation();

      if (customerId > 0) {
        this.formQuotation.customerId = customerId;
        await Promise.all([
          this.getAllCustomerAddress({ customerId: this.formQuotation.customerId || '' }),
          this.getAllPersonInCharge({ customerId: this.formQuotation.customerId || '' }),
          this.getAllCustomerRepresentative({
            customerId: this.formQuotation.customerId || ''
          })
        ]).then(() => {
          this.selectLoading = false;
        });
      }
    },

    async onClickAddQuotation() {
      this.$refs['formQuotation'].validate(async valid => {
        if (valid) {
          try {
            await this.addNewQuotation(this.formQuotation);
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('common.notify.message.add.success', {
                item: 'row'
              }),
              type: 'success'
            });
            this.clearFormQuotation();
            this.addQuotationDialogVisible = false;
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.add.failr', { name: 'row' }),
              type: 'error'
            });
          }
        } else {
          return false;
        }
      });
    },

    emitCloseConfirmCancelQuotationPopup(){
      this.dialogConfirmCancelQuotationVisible = false;
      this.formQuotation.quotationCancelReasonCode = null;
      this.formQuotation.cancelReason = '';
      this.isShowCancelReasonText = false;
    },

    handleChangeQuotationStatus(quotationId, $event) {

      //handle when change quotation status to cancel
      if($event === QUOTATION_DA_HUY){
        this.dialogConfirmCancelQuotationVisible = true;
        this.quotationIdEditing = quotationId;

        return;
      };  

      this.$confirm('Do you want change ?')
        .then(async () => {
          try {
            const result = await this.changeQuotationStatus({
              quotationStatusId: $event,
              id: quotationId
            });
            const { status } = result;
            if (status) {
              this.$notify({
                title: 'Success',
                message: 'Change Quotation Status Success',
                type: 'success'
              });
            } else {
              const { messageCode, messageContent } = result;

              const messageTranslate = 
                this.$i18n.te(`src.modules.quotation.index.changeStatus.error.${messageCode}`) ? 
                this.$i18n.t(`src.modules.quotation.index.changeStatus.error.${messageCode}`) :
                messageContent;
                
              this.$notify({
                title: 'Error',
                message: messageTranslate,
                type: 'error'
              });
            }
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.edit.fail', {
                name: 'row'
              }),
              type: 'error'
            });
          }
        })
        .catch(error => {
          console.log('error: ', error);
        });
    },

    handleSelectionChange(val) {
      this.quotationSelectedList = val;
    },

    // Paging
    async changePage(val) {
      this.currentPage = val;
      await this.getAllCustomerAddressInPageCustomer({ customerIdArr: this.customerIdListInPage });
    },

    onClickShowAddQuotationPopup() {
      this.addQuotationDialogVisible = true;
      this.clearFormQuotation();
    },

    handleCloseAddForm(done) {
      let message = null;
      if (!isEqual(this.formQuotation, this.formQuotationDefault)) {
        message = this.$i18n.t('common.confirm.close.form.out');
        this.$confirm(message)
          .then(() => {
            this.clearFormQuotation();
            this.addQuotationDialogVisible = false;
            done();
          })
          .catch(() => {});
      } else {
        this.addQuotationDialogVisible = false;
      }
    },

    // form add new Person Incharge form
    clearFormPersonIncharge() {
      this.formAddPersonIncharge = cloneDeep(this.formAddPersonInchargeDefault);
    },

    onClickShowAddPersonInchargePopup() {
      this.addPersonInchargeDialogVisible = true;
      this.clearFormPersonIncharge();
      this.formAddPersonIncharge.customerId = this.formQuotation.customerId;
    },

    handleCloseAddPersonInchargeForm(done) {
      let message = null;
      if (!isEqual(this.formAddPersonIncharge, this.formAddPersonInchargeDefault)) {
        message = this.$i18n.t('common.confirm.close.form.out');
        this.$confirm(message)
          .then(() => {
            this.clearFormPersonIncharge();
            this.addPersonInchargeDialogVisible = false;
            done();
          })
          .catch(() => {});
      } else {
        this.addPersonInchargeDialogVisible = false;
      }
    },

    onClickAddRowPersonIncharge() {
      this.$refs['formAddPersonIncharge'].validate(async valid => {
        if (valid) {
          try {
            await this.saveAddPersonInCharge(this.formAddPersonIncharge);
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('common.notify.message.add.success', {
                item: 'row'
              }),
              type: 'success'
            });
            this.clearFormPersonIncharge();
            this.addPersonInchargeDialogVisible = false;
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.add.failr', { name: 'row' }),
              type: 'error'
            });
          }
        } else {
          return false;
        }
      });
    },

    // form add new customer address
    clearFormCustomerAddress() {
      this.formAddCustomerAddress = cloneDeep(this.formAddCustomerAddressDefault);
    },

    onClickShowAddCustomerAddressPopup() {
      this.addCustomerAddressDialogVisible = true;
      this.clearFormCustomerAddress();
      this.formAddCustomerAddress.customerId = this.formQuotation.customerId;
    },

    handleCloseAddCustomerAddressForm(done) {
      let message = null;
      if (!isEqual(this.formAddCustomerAddress, this.formAddCustomerAddressDefault)) {
        message = this.$i18n.t('common.confirm.close.form.out');
        this.$confirm(message)
          .then(() => {
            this.clearFormCustomerAddress();
            this.addCustomerAddressDialogVisible = false;
            done();
          })
          .catch(() => {});
      } else {
        this.addCustomerAddressDialogVisible = false;
      }
    },

    onClickAddRowCustomerAddress() {
      this.$refs['formAddCustomerAddress'].validate(async valid => {
        if (valid) {
          try {
            await this.saveAddCustomerAddress(this.formAddCustomerAddress);
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('common.notify.message.add.success', {
                item: 'row'
              }),
              type: 'success'
            });
            this.clearFormCustomerAddress();
            this.addCustomerAddressDialogVisible = false;
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.add.failr', { name: 'row' }),
              type: 'error'
            });
          }
        } else {
          return false;
        }
      });
    },

    // form add new Customer Representative
    clearFormCustomerRepresentative() {
      this.formAddCustomerRepresentative = cloneDeep(
        this.formAddCustomerRepresentativeDefault
      );
    },

    onClickShowAddCustomerRepresentativePopup() {
      this.addCustomerRepresentativeDialogVisible = true;
      this.clearFormCustomerRepresentative();
      this.formAddCustomerRepresentative.customerId = this.formQuotation.customerId;
    },

    handleCloseAddCustomerRepresentativeForm(done) {
      let message = null;
      if (
        !isEqual(
          this.formAddCustomerRepresentative,
          this.formAddCustomerRepresentativeDefault
        )
      ) {
        message = this.$i18n.t('common.confirm.close.form.out');
        this.$confirm(message)
          .then(() => {
            this.clearFormCustomerRepresentative();
            this.addCustomerRepresentativeDialogVisible = false;
            done();
          })
          .catch(() => {});
      } else {
        this.addCustomerRepresentativeDialogVisible = false;
      }
    },

    onClickAddRowCustomerRepresentative() {
      this.$refs['formAddCustomerRepresentative'].validate(async valid => {
        if (valid) {
          try {
            await this.saveAddCustomerRepresentative(this.formAddCustomerRepresentative);
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('common.notify.message.add.success', {
                item: 'row'
              }),
              type: 'success'
            });
            this.clearFormCustomerRepresentative();
            this.addCustomerRepresentativeDialogVisible = false;
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.add.failr', { name: 'row' }),
              type: 'error'
            });
          }
        } else {
          return false;
        }
      });
    },

    async onClickRemove(index) {
      this.$confirm(`Do you want to remove ${index.row.code} ?`)
        .then(async () => {
          const result = await this.deleteQuotationList([index.row.id]);
          if (result) {
            this.$notify({
              title: 'Success',
              message: 'Delete Success',
              type: 'success'
            });
          } else {
            this.$notify({
              title: 'Error',
              message: 'Cannot remove quotation',
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },

    resetLocationForm(name) {
      if (name === 'province') {
        this.formAddCustomerAddress.district = '';
        this.formAddCustomerAddress.ward = '';
      }
      if (name === 'district') {
        this.formAddCustomerAddress.ward = '';
      }
    }
  }
};
</script>

<style>
.select_quotation_status input {
  border: none;
}
</style>
