<template>
  <div class="tables-basic">
    <div class="d-flex justify-content-end">
      <span>Place On {{ cloneOrderData.createdAt | formattedDate }}</span>
    </div>
    <b-row>
      <b-col>
        <Widget>
          <el-row :gutter="24">
            <el-col :span="4"
              ><div class="grid-content">
                <el-select
                  size="medium"
                  v-model="defaultStatus"
                  :placeholder="this.$i18n.t('src.modules.order.index.search.status.placeholder')"
                  width="120%"
                  :popper-append-to-body="false"
                  @change="handleChangeStatus"
                  :disabled="cloneOrderData.statusCode == STATUS_CODE_DA_HUY ? true : false"
                >
                  <el-option v-for="item in allStatusCheckout" :key="item.value" :label="item.label" :value="item.value">
                    <span style="float: left">
                      <div :class="item.color" style="width: 10px; height: 10px; display: inline-block; border-radius: 50%; margin-right: 10px"></div>
                      {{ item.label }}</span
                    >
                  </el-option>
                </el-select>
              </div></el-col
            >
            <el-col :span="8" v-show="defaultStatus == STATUS_CODE_DA_HUY">
              <div class="grid-content">
                <el-form ref="formOrderDetail" :model="formOrderDetail" label-width="180px">
                  <el-form-item :label="$i18n.t('src.modules.order.detail.reasonForCancellation')" prop="note">
                    <el-input
                      size="medium"
                      type="textarea"
                      :rows="3"
                      :placeholder="$i18n.t('src.modules.order.detail.reasonForCancellation.placeholder')"
                      v-model="formOrderDetail.reasonForCancellation"
                    />
                  </el-form-item>
                </el-form>
              </div>
            </el-col>
            <el-col :span="defaultStatus == STATUS_CODE_DA_HUY ? 12 : 20">
              <div class="grid-content">
                <el-row type="flex" justify="end">
                  <el-button  type="info" @click="printDownload()" class="btn-black-lg">
                    <i class="el-icon-download mr-2" style="color: white"></i>
                    {{ this.$i18n.t('src.modules.order.detail.sendMail.exportPO') }}
                  </el-button>
                  <el-button  type="info" @click="printDownloadDeliveryRecord()" class="btn-black-lg">
                    <i class="el-icon-download mr-2" style="color: white"></i>
                    {{ this.$i18n.t('src.modules.order.detail.sendMail.exportDeliveryRecord') }}
                  </el-button>
                  <el-button  type="warning" @click="onShowSendMailDialog()" class="btn-blue-lg">
                    <i class="el-icon-s-promotion mr-2"></i>
                    {{ this.$i18n.t('src.modules.order.detail.sendMail.btnSendMail') }}
                  </el-button>
                  <el-button  type="success" @click="onSaveOrder()" class="btn-green-lg">
                    <i class="el-icon-check mr-2"></i>
                    {{ this.$i18n.t('src.modules.order.index.btnSave') }}
                  </el-button>
                </el-row>
              </div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-select v-model="orderTags" multiple filterable allow-create default-first-option placeholder="Chọn nhãn dán" @change="changeOrderTags" size="mini">
                <el-option v-for="item in tagOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-col>
          </el-row>
        </Widget>
        <Widget>
          <el-row :gutter="24">
            <el-form ref="formOrderDetail" :model="formOrderDetail" label-width="180px">
              <el-col :span="7"
                ><div class="grid-content">
                  <!-- customerName (S) -->
                  <el-form-item :label="$i18n.t('src.modules.order.detail.customerName')" prop="customerName">
                    <el-input size="medium" disabled v-model="formOrderDetail.customerName" width="80%" />
                  </el-form-item>

                  <!-- customerName (E) -->
                  <!-- saleId (S) -->
                  <!-- <el-form-item :label="$i18n.t('src.modules.order.detail.sales')" prop="saleId">
                    <el-select 
                       size="medium" 
                       v-model="formOrderDetail.saleId" 
                       :placeholder="$i18n.t('src.modules.order.detail.sales.placeholder')" 
                       filterable 
                       clearable
                       collapse-tags 
                       width="80%"
                     >
                       <el-option 
                         v-for="item in userOptions" 
                         :key="item.value" 
                         :label="item.label" 
                         :value="item.value"
                       > 
                       </el-option>
                     </el-select>
                  </el-form-item> -->
                  <!-- saleId (E) -->
                </div></el-col
              >
              <el-col :span="1">
                <el-button type="primary" icon="el-icon-user" circle @click="isShowCustomerInfoPopup = true"></el-button>
              </el-col>
              <el-col :span="8"
                ><div class="grid-content">
                  <!-- saleId (S) -->
                  <!-- <el-form-item :label="$i18n.t('src.modules.order.detail.bd')" prop="bdId">
                    <el-select 
                      size="medium" 
                      v-model="formOrderDetail.bdId" 
                      :placeholder="$i18n.t('src.modules.order.detail.bd.placeholder')" 
                      filterable clearable multiple collapse-tags width="80%"
                    >
                      <el-option v-for="item in userOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                  </el-form-item> -->
                  <!-- saleId (E) -->
                  <!-- CustomerAddress (S) -->
                  <el-form-item :label="$i18n.t('src.modules.order.detail.customerAddress')" prop="customerAddressId">
                    <el-select
                      size="medium"
                      v-model="formOrderDetail.customerAddressId"
                      :placeholder="$i18n.t('src.modules.order.detail.customerAddress.placeholder')"
                      filterable
                      clearable
                      width="80%"
                    >
                      <el-option v-for="item in customerAddressOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                  </el-form-item>
                  <!-- CustomerAddress (E) -->
                </div></el-col
              >
              <el-col :span="8"
                ><div class="grid-content">
                  <!-- PersonInCharges (S) -->
                  <el-form-item :label="$i18n.t('src.modules.order.detail.personInCharge')" prop="personInChargesId">
                    <el-select size="medium" v-model="formOrderDetail.personInChargesId" :placeholder="$i18n.t('src.modules.order.detail.personInCharge.placeholder')" filterable clearable width="80%">
                      <el-option v-for="item in personInChargesOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                  </el-form-item>
                  <!-- PersonInCharges (E) -->
                  <!-- note (S) -->
                  <!-- <el-form-item
                    :label="$i18n.t('src.modules.order.detail.note')"
                    prop="note"
                  >
                    <textarea
                      size="medium"
                      type="text"
                      v-model="formOrderDetail.note"
                      width="80%"
                      rows="2"
                      cols="52"
                    ></textarea>
                  </el-form-item> -->
                  <el-form-item :label="$i18n.t('src.modules.order.detail.note')" prop="note">
                    <el-input size="medium" type="textarea" :rows="2" :placeholder="$i18n.t('src.modules.order.detail.note.placeholder')" v-model="formOrderDetail.note" />
                  </el-form-item>
                  <!-- note (E) -->
                </div></el-col
              >
            </el-form>
          </el-row>
        </Widget>
        <b-row>
          <b-col cols="8">
            <Widget>
              <SelectProduct
                :visible="selectProductVisible"
                :selected-items="multipleSelectProduct"
                :customer-id="orderData.customer && orderData.customer.id"
                @onChangeSelect="handleSelectionChange"
                @onCloseAddProduct="handleCloseAddProduct"
                @onClickAddMultiRow="handleAddMultiRow"
                @onClickAddSingleRow="onClickAddRow"
                @onSaveAddOrderDetail="handleSaveOrderDetail"
              />

              <!-- Lalamove (S) -->
              <LalaMove
                :order_id="+this.$route.params.id"
                :visible="lalamoveVisible"
                :form_info="this.formInfo"
                :supplier_info_arr="this.supplierInfoArr"
                @onCloseLalamoveDialog="handleCloseLalamoveDialog"
              />
              <!-- Lalamove (E) -->

              <el-button  type="primary" size="small" class="float-right mb-3 btn-blue-lg mr-2" @click="selectProductVisible = true"
                ><i class="el-icon-plus"></i>
                {{ this.$i18n.t('src.modules.order.detail.btnAddProduct') }}
              </el-button>

              <el-table
                v-loading="isLoading"
                :data="groupProductBySupplier"
                :header-cell-style="discountHeaderStyle"
                :row-style="rowStyle"
                :height="400"
                style="width: 100%"
                class="mb-5"
                default-expand-all
                row-key="id"
                :span-method="arraySpanMethod"
                :cell-style="{ height: '95px' }"
              >
                <el-table-column label="#" width="150" fixed="left">
                  <template slot-scope="scope">
                    <span v-if="scope.row.id && Object.keys(scope.row).length > 6">{{ scope.row.id }}</span>
                    <!-- <el-tag v-else type="danger" size="small" effect="dark">{{
                      $i18n.t('src.modules.order.index.label.new')
                    }}</el-tag> -->
                    <div v-else class="d-inline-block">
                      <div class="d-flex">
                        <el-tooltip effect="dark" :content="scope.row.supInfo.name" placeholder="bottom" v-if="scope.row.supInfo.name && scope.row.supInfo.name.length > 10">
                          <!-- <a href="#" class="mr-4 font-weight-bold" 
                                onMouseOver="this.style.textDecoration='underline'" 
                                onMouseOut="this.style.textDecoration='none'"
                                style="color: #000000">
                                {{ scope.row.supInfo.name | truncate(15) }}
                          </a> -->
                          <span
                            class="mr-4 font-weight-bold"
                            style="cursor: pointer"
                            onMouseOver="this.style.textDecoration ='underline'"
                            onMouseOut="this.style.textDecoration='none'"
                            @click="onShowSupplierAddressDialog(scope)"
                          >
                            {{ scope.row.supInfo.name | truncate(10) }}
                          </span>
                        </el-tooltip>
                        <span
                          v-else
                          class="mr-4 font-weight-bold"
                          style="cursor: pointer"
                          onMouseOver="this.style.textDecoration ='underline'"
                          onMouseOut="this.style.textDecoration='none'"
                          @click="onShowSupplierAddressDialog(scope)"
                        >
                          {{ scope.row.supInfo.name }}
                        </span>
                        <!-- <span v-else class="mr-4 font-weight-bold">{{scope.row.supInfo.name}}</span> -->
                        <!-- <a v-else href="#" class="mr-4 font-weight-bold" 
                                onMouseOver="this.style.textDecoration='underline'" 
                                onMouseOut="this.style.textDecoration='none'"
                                style="color: #000000">
                                {{ scope.row.supInfo.name | truncate(15) }}
                          </a> -->
                        <el-select
                          size="medium"
                          :value="scope.row.supStatus"
                          :placeholder="$i18n.t('src.modules.order.detail.select.status.placeholder')"
                          class="supplier-status-select col-md-5 mr-2 border-none bg-light"
                          @change="handleChangeSupplierStatus(scope, $event)"
                          :disabled="!scope.row.supStatus"
                          v-if="scope.row.orderSupId"
                        >
                          <template slot="prefix">
                            <el-tag
                              :disable-transitions="true"
                              :key="allOrderSupplierStatus[scope.row.supStatus].label"
                              effect="dark"
                              class="supplier-status-text"
                              :class="allOrderSupplierStatus[scope.row.supStatus].color"
                              style="width: 100%"
                              v-if="scope.row.supStatus"
                            >
                              {{ allOrderSupplierStatus[scope.row.supStatus].label }}
                            </el-tag>
                          </template>
                          <!-- <template slot="prefix">
                            <el-tag
                              :disable-transitions="true"
                              :key="scope.row.supStatus"
                              type="warning"
                              effect="dark"
                            >
                              {{ scope.row.supStatus }}
                            </el-tag>
                          </template> -->
                          <!-- <template slot="prefix">
                            <el-tag
                              :disable-transitions="true"
                              :key="scope.row.supStatus"
                              type="primary"
                              effect="dark"
                            >
                              {{ scope.row.supStatusN }}
                            </el-tag>
                          </template> -->
                          <el-option
                            v-for="item in Object.keys(allOrderSupplierStatus)"
                            :key="allOrderSupplierStatus[item].value"
                            :label="allOrderSupplierStatus[item].value"
                            :value="allOrderSupplierStatus[item].value"
                            :disabled="parseInt(scope.row.supStatus) >= parseInt(allOrderSupplierStatus[item].value)"
                          >
                            <span>
                              <el-tag :disable-transitions="true" :key="allOrderSupplierStatus[item].label" effect="dark" style="border-color: white" :class="allOrderSupplierStatus[item].color">
                                {{ allOrderSupplierStatus[item].label }}
                              </el-tag>
                            </span>
                            <!-- {{  allOrderSupplierStatus[item].label }} -->
                          </el-option>
                        </el-select>
                        <div class="d-inline-block" v-if="scope.row.orderSupId">
                          <el-button type="warning" size="small" v-if="!scope.row.supStatus" class="btn-orange-lg" @click="onShowWaitingConfirmDialog(scope)"
                            ><i class="el-icon-right mr-2"></i>
                            {{ $i18n.t('src.modules.order.detail.btnWaitingConfirm') }}
                          </el-button>
                          <div v-else>
                            <el-dropdown @command="handleCommand" class="ml-2">
                              <el-button type="success" size="small" class="btn-green-lg">
                                {{ $i18n.t('src.modules.order.detail.btnAction') }} <i class="el-icon-arrow-down el-icon--right"></i>
                              </el-button>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="item in listSupplierAction" :key="item.id" :icon="item.icon" :command="{ action: item.id, data: scope }">
                                  {{ $i18n.t(item.title) }}
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>

                            <!-- <el-button type="info" size="small" @click="previewMailSupplier(scope)"><i class="el-icon-document" style="color: white"></i></el-button>
                            <el-button type="warning" size="small" @click="handleSendMailToSupplier(scope)"><i class="el-icon-s-promotion"></i></el-button>
                            <el-button type="info" size="small" @click="downloadSupplierPO(scope)"><i class="el-icon-download" style="color: white"></i></el-button> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column :label="this.$i18n.t('src.modules.order.detail.name')" width="350" fixed="left">
                  <template slot-scope="scope">
                    <el-tooltip effect="dark" :content="scope.row.productName" placeholder="bottom" v-if="scope.row.productName && scope.row.productName.length > 35">
                      <a target="_blank" :href="EI_ENDPOINT + '/' + scope.row.productSlug + '-pid' + scope.row.productId">{{ scope.row.productName | truncate(35) }}</a>
                    </el-tooltip>
                    <span v-else>
                      <a target="_blank" :href="EI_ENDPOINT + '/' + scope.row.productSlug + '-pid' + scope.row.productId">{{ scope.row.productName }}</a>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :label="this.$i18n.t('src.modules.order.detail.image')" width="100">
                  <template slot-scope="scope">
                    <el-image style="width: 50px; height: 50px" :src="S3_STORE_ENDPOINT + '/' + getProductImage(scope.row.productImg)" v-if="scope.row.productImg && Object.keys(scope.row).length > 6">
                    </el-image>
                  </template>
                </el-table-column>
                <el-table-column :label="this.$i18n.t('src.modules.order.detail.unit')" width="150">
                  <template slot-scope="scope">
                    <span
                      v-if="
                        Object.keys(scope.row).length > 6 &&
                          scope.row.products.unitCode != null &&
                          unitObj[scope.row.products.unitCode] !== null &&
                          typeof unitObj[scope.row.products.unitCode] !== 'undefined'
                      "
                    >
                      {{ unitObj[scope.row.products.unitCode] }}
                    </span>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  :label="this.$i18n.t('src.modules.order.detail.supplierAddress')"
                  width="300"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      effect="dark"
                      :content="scope.row.supAddress"
                      placeholder="bottom"
                      v-if="scope.row.supAddress.length > 40"
                    >
                      <span>{{ scope.row.supAddress | truncate(40) }}</span>
                    </el-tooltip>
                    <span v-else>
                      {{ scope.row.supAddress }}
                    </span>
                  </template>
                </el-table-column> -->
                <el-table-column :label="$i18n.t('src.modules.order.detail.productVariants')" width="250" style="text-align: center">
                  <template slot-scope="scope" v-if="scope.row.variants">
                    <span v-for="(item, index) in scope.row.variants" :key="index" class="mr-2"> {{ item.groupName }} : {{ item.name }} </span>
                  </template>
                </el-table-column>
                <el-table-column prop="quanity" :label="this.$i18n.t('src.modules.order.detail.quantity')" width="150" style="text-align: center">
                  <template slot-scope="scope">
                    <span v-if="editRow != scope.$index">{{ scope.row.quantity }}</span>
                    <el-input-number v-else v-model="selectedOrderDetail.quantity" size="mini" :min="1" :max="1000" style="width: 80%"></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column :label="this.$i18n.t('src.modules.order.detail.salePrice')" width="150">
                  <template slot-scope="scope">
                    <span v-if="editRow != scope.$index && (scope.row.price || scope.row.price !== 0)">
                      {{
                        new Intl.NumberFormat('vi-VN', {
                          style: 'currency',
                          currency: 'VND'
                        }).format(scope.row.price)
                      }}
                    </span>
                    <span v-else-if="editRow != scope.$index && (!scope.row.price || scope.row.price === 0)"> Liên hệ </span>
                    <el-input v-else placeholder="Please type price" size="small" v-model="priceWithComma"></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="this.$i18n.t('src.modules.order.detail.supplierPrice')" width="150">
                  <template slot-scope="scope">
                    <span v-if="editRow != scope.$index && (scope.row.supplierPrice || scope.row.supplierPrice !== 0)">
                      {{
                        new Intl.NumberFormat('vi-VN', {
                          style: 'currency',
                          currency: 'VND'
                        }).format(scope.row.supplierPrice)
                      }}
                    </span>
                    <span v-else-if="editRow != scope.$index && (!scope.row.supplierPrice || scope.row.supplierPrice === 0)"> Liên hệ </span>
                    <el-input v-else placeholder="Please type price" size="small" v-model="supplierPriceWithComma"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="totalPrice" :label="$i18n.t('src.modules.order.detail.totalPrice')" width="120">
                  <template slot-scope="scope">
                    {{
                      new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND'
                      }).format(scope.row.totalPrice)
                    }}
                  </template>
                </el-table-column>
                <el-table-column :label="$i18n.t('src.modules.order.detail.taxRate')" width="150" style="text-align: center">
                  <template slot-scope="scope">
                    <span v-if="editRow != scope.$index">{{ scope.row.taxRate }} %</span>
                    <el-input-number v-else v-model="selectedOrderDetail.taxRate" size="mini" :min="1" :max="100" style="width: 80%"></el-input-number>
                  </template>
                </el-table-column>
                <el-table-column :label="$i18n.t('src.modules.order.detail.taxAmount')" width="120" style="text-align: center">
                  <template slot-scope="scope">
                    {{
                      new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND'
                      }).format(Math.round((scope.row.totalPrice / 100) * scope.row.taxRate))
                    }}
                  </template>
                </el-table-column>
                <el-table-column width="150" fixed="right" :label="$i18n.t('src.modules.order.detail.action')">
                  <template slot-scope="scope" v-if="Object.keys(scope.row).length > 6">
                    <el-button class="btn-blue-sm" type="primary" size="mini"  @click="enableEditing(scope)" v-if="editRow != scope.$index">
                      <i class="el-icon-edit mr-1" /> {{ $i18n.t('common.label.edit') }}
                    </el-button>
                    <el-button class="btn-green-sm" type="success" size="mini"  @click="onSaveEditOrderDetail(scope)" v-else>
                      <i class="el-icon-check mr-1" /> {{ $i18n.t('common.label.save') }}
                    </el-button>
                    <el-button class="btn-red-sm" type="danger" size="mini" v-can="'order-detail.delete'" @click="onClickRemove(scope)" v-if="editRow != scope.$index">
                      <i class="el-icon-delete mr-1" /> {{ $i18n.t('common.label.delete') }}
                    </el-button>
                    <el-button class="btn-red-sm" type="danger" size="mini"  @click="onCloseEditing(scope.$index)" v-else>
                      <i class="el-icon-close mr-1" /> {{ $i18n.t('common.label.close') }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <b-row>
                <b-col cols="7">
                  <b-row class="mb-4">
                    <b-col cols="4" class="text-right">
                      {{ this.$i18n.t('src.modules.order.detail.estimatedDeliveryDate') }}
                    </b-col>
                    <b-col>
                      <el-date-picker
                        v-model="cloneOrderData.estimatedDeliveryDate"
                        type="date"
                        size="small"
                        :placeholder="$i18n.t('src.modules.order.detail.estimatedDeliveryDate.placeholder')"
                        value-format="yyyy-MM-dd"
                        style="width: 80%"
                      >
                      </el-date-picker>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4" class="text-right">
                      {{ this.$i18n.t('src.modules.order.detail.discount') }}
                    </b-col>
                    <b-col>
                      <el-radio-group v-model="selectedDiscount" @change="handleChangeDiscount" class="mb-3">
                        <el-radio v-model="radioDiscount" :label="0">
                          {{ this.$i18n.t('src.modules.order.detail.voucher') }}
                        </el-radio>
                        <!-- <el-radio v-model="radioDiscount" :label="1">
                          {{ this.$i18n.t('src.modules.order.detail.coupon') }}
                        </el-radio> -->
                      </el-radio-group>
                      <el-tooltip class="item" effect="dark" placement="bottom" v-if="selectedDiscount === 0">
                        <div slot="content">
                          <div v-if="this.discountInfo">
                            <p>Start Date: {{ discountInfo.startDate | formattedDate }}</p>
                            <p>End Date: {{ discountInfo.endDate | formattedDate }}</p>
                            <p>Description: {{ discountInfo.description }}</p>
                          </div>
                          <div v-else>Khong co thong tin</div>
                        </div>

                        <el-select
                          v-model="cloneOrderData.voucherId"
                          :placeholder="this.$i18n.t('src.modules.order.detail.selectVoucher')"
                          size="mini"
                          style="width: 80%"
                          class="mb-3"
                          clearable
                          @change="handleChangeVoucher"
                        >
                          <el-option v-for="item in allVouchers" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" placement="bottom" v-if="selectedDiscount === 1">
                        <div slot="content">
                          <div v-if="this.discountInfo">
                            <p>Start Date: {{ discountInfo.startDate | formattedDate }}</p>
                            <p>End Date: {{ discountInfo.endDate | formattedDate }}</p>
                            <p>Description: {{ discountInfo.description }}</p>
                          </div>
                          <div v-else>Khong co thong tin</div>
                        </div>

                        <el-select
                          v-model="cloneOrderData.couponId"
                          :placeholder="this.$i18n.t('src.modules.order.detail.selectCoupon')"
                          size="mini"
                          style="width: 80%"
                          class="mb-3"
                          clearable
                          @change="handleChangeCoupon"
                        >
                          <el-option v-for="item in allCoupons" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                      </el-tooltip>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4" class="text-right">
                      Sử dụng EI Cash
                    </b-col>
                    <b-col>
                      <el-switch
                        v-model="selectedEiCash"
                        :disabled="(this.totalSalePrice <= 2000000 && !this.selectedEiCash) || (!this.selectedEiCash && cloneOrderData.customer && customerEiCash <= 0)"
                        @change="handleChangeEICashStatus"
                      >
                      </el-switch>
                      <span class="ml-3 text-right" style="color: gray; font-size: 16px;" v-if="customerEiCash >= 0">
                        <strong v-if="this.selectedEiCash">
                          {{
                            this.totalSalePrice >= customerEiCash
                              ? new Intl.NumberFormat('vi-VN', {
                                  style: 'currency',
                                  currency: 'VND'
                                }).format(0)
                              : new Intl.NumberFormat('vi-VN', {
                                  style: 'currency',
                                  currency: 'VND'
                                }).format(Math.abs(customerEiCash - totalSalePrice))
                          }}
                        </strong>
                        <strong v-else>
                          {{
                            new Intl.NumberFormat('vi-VN', {
                              style: 'currency',
                              currency: 'VND'
                            }).format(Math.abs(customerEiCash + cloneOrderData.eiCash))
                          }}
                        </strong>
                      </span>
                      <p style="color: indianred; font-size: 12px; margin-top: 10px;">* Chỉ sử dụng khi giá trị đơn hàng trên 2.000.000 đ</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-4" v-if="this.cloneOrderData.evidence">
                    <b-col cols="4" class="text-right">
                      {{ this.$i18n.t('src.modules.order.detail.evidence') }}
                    </b-col>
                    <b-col cols="8">
                      <span style="color: rgb(173, 181, 189)">{{ this.cloneOrderData.vidence.substring(this.cloneOrderData.evidence.lastIndexOf('/') + 1, this.cloneOrderData.evidence.length) }}</span>
                      <el-tooltip class="item" effect="dark" :content="$i18n.t('src.modules.order.detail.btnDownload')" placement="top">
                        <el-button class="btn-download pt-0" @click="downloadFile(this.cloneOrderData.evidence)">
                          <i class="el-icon-download"></i>
                        </el-button>
                      </el-tooltip>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex justify-content-between mb-3 border-top pt-3">
                    <div style="color: #696969">
                      <span class="font-weight-normal">
                        {{ this.$i18n.t('src.modules.order.detail.subTotal') }}
                      </span>
                    </div>
                    <div class="font-weight-bold">
                      <span
                        >{{
                          new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND'
                          }).format(this.setPrice.totalPrice)
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between mb-3 border-top pt-3">
                    <div style="color: #696969">
                      <span> {{ this.$i18n.t('src.modules.order.detail.vat') }} </span>
                    </div>
                    <div class="font-weight-bold">
                      <span
                        >+
                        <!-- {{
                          new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND'
                          }).format(getVATPrice)
                        }} -->
                        {{
                          new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND'
                          }).format(this.setPrice.vat)
                        }}
                      </span>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between mb-3 border-top pt-3">
                    <div style="color: #696969">
                      <!-- <span></span> -->
                      <el-button @click="changeVisibleLalaMove" type="warning" icon="el-icon-edit" size="mini" class="btn-orange-md">
                        {{ $i18n.t('src.modules.order.detail.shippingFee') }}
                      </el-button>
                    </div>
                    <div class="font-weight-bold">
                      <div class="d-flex justify-content-between" v-if="!isEnableEditShippingFee">
                        <span class="mr-2"
                          >+
                          {{
                            new Intl.NumberFormat('vi-VN', {
                              style: 'currency',
                              currency: 'VND'
                            }).format(this.setPrice.shippingFee)
                          }}
                        </span>
                        <button  class="btn-edit" @click="enableEditShippingFee()">
                          <i class="el-icon-edit" />
                        </button>
                      </div>
                      <div class="d-flex justify-content-between" v-else>
                        <el-input placeholder="Please input" v-model="shippingFeeWithComma" @keyup.enter.native="handleEnterShippingFeeKeyUp" size="small"> </el-input>
                        <button  class="btn-save" @click.prevent="closeEditShippingFee()" ref="btnSaveShippingFee">
                          <i class="el-icon-document-checked" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    class="d-flex justify-content-between align-items-center pt-3 mt-3 mb-3"
                    style="border-top: 1px solid rgba(0, 0, 0, 0.05)"
                    v-if="
                      (this.setPrice.totalPrice > 0 && this.selectedDiscount === 0 && this.cloneOrderData.voucherId) ||
                        (this.setPrice.totalPrice && this.selectedDiscount === 1 && this.cloneOrderData.couponId)
                    "
                  >
                    <div style="color: #696969">
                      <span>{{ this.$i18n.t('src.modules.order.detail.discount') }}</span>
                      <strong style="display: block; font-size: 12px">{{ getDiscountCode(this.selectedDiscount) }}</strong>
                    </div>
                    <div class="font-weight-bold">
                      <span
                        >-
                        {{
                          new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND'
                          }).format(this.cloneOrderData.discountAmount)
                        }}</span
                      >
                    </div>
                  </div>

                  <div class="d-flex justify-content-between mb-3 pt-3 mt-3" style="border-top: 1px solid rgba(0, 0, 0, 0.05); " v-if="this.selectedEiCash">
                    <div :style="cloneOrderData.eiCash === 0 ? 'color: indianred' : 'color: dodgerblue'">
                      <span><strong> EI Cash </strong></span>
                    </div>
                    <div class="font-weight-bold" style="color: indianred" v-if="cloneOrderData.eiCash === 0">
                      <span>
                        -
                        <!-- {{
                          new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND'
                          }).format(getVATPrice)
                        }} -->
                        {{
                          totalSalePrice - customerEiCash > 0
                            ? new Intl.NumberFormat('vi-VN', {
                                style: 'currency',
                                currency: 'VND'
                              }).format(customerEiCash)
                            : new Intl.NumberFormat('vi-VN', {
                                style: 'currency',
                                currency: 'VND'
                              }).format(totalSalePrice)
                        }}
                      </span>
                    </div>
                    <div class="font-weight-bold" style="color: dodgerblue" v-else>
                      <span>
                        -
                        <!-- {{
                          new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND'
                          }).format(getVATPrice)
                        }} -->
                        {{
                          new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND'
                          }).format(cloneOrderData.eiCash)
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center pt-3 mt-3" style="border-top: 3px solid black; font-size: 16px; font-weight: bold">
                    <div style="color: #696969; font-size: 18px">
                      <span>{{ this.$i18n.t('src.modules.order.detail.total') }}</span>
                    </div>
                    <div class="font-weight-bold">
                      <span style="font-size: 18px">
                        <!-- {{
                          this.orderData.voucher_id !== null
                            ? new Intl.NumberFormat('vi-VN', {
                                style: 'currency',
                                currency: 'VND'
                              }).format(getSalePrice + getShippingFee)
                            : new Intl.NumberFormat('vi-VN', {
                                style: 'currency',
                                currency: 'VND'
                              }).format(totalPrice + getVATPrice + getShippingFee)
                        }} -->
                        {{
                          new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND'
                          }).format(totalSalePrice)
                        }}
                      </span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </Widget>
            <Widget style="padding: 25px 35px; height: 400px">
              <b-row>
                <b-col cols="12">
                  <h4 style="padding-bottom: 20px; border-bottom: 1px solid rgba(0, 0, 0, 0.05)">
                    {{ this.$i18n.t('src.modules.order.detail.documents') }}
                  </h4>
                </b-col>
              </b-row>
              <b-row :gutter="20" class="mt-3">
                <b-col cols="4">
                  <!-- <el-select
                    v-model="selectedDocumentTypes"
                    placeholder="Select"
                    size="medium"
                  >
                    <el-option
                      v-for="item in listDocumentTypes"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select> -->
                  <el-select v-model="selectedDocumentTypes" placeholder="Select">
                    <el-option v-for="item in listDocumentTypes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-select>
                </b-col>
                <b-col cols="2">
                  <el-upload ref="uploadFiles" action="" multiple :auto-upload="false" :show-file-list="false" :on-change="handleFileUpload">
                    <el-button size="medium" type="primary" :loading="loadingUploadLocal" :disabled="loadingUploadLocal" class="btn-blue-lg"
                      ><i class="el-icon-upload2 mr-2"></i>{{ this.$i18n.t('src.modules.order.detail.btnUpload') }}
                    </el-button>
                  </el-upload>
                </b-col>
                <b-col cols="6">
                  <el-collapse style="border-top: none; border-bottom: none">
                    <el-collapse-item v-for="(doc, index) in listDocuments" :key="index" style="margin-bottom: 10px">
                      <template slot="title">
                        <span style="font-weight: 600; font-size: 14px; margin-right: 20px">{{ doc.name }}</span>
                        <span style="font-weight: 600; color: rgb(173, 181, 189)">
                          {{ doc.items.length }}
                          {{ $i18n.t('src.modules.order.detail.file') }}
                        </span>
                      </template>
                      <ul v-for="(item, idx2) in doc.items" :key="idx2" class="col-md-12 mb-1">
                        <li class="d-flex justify-content-between align-items-center p-2">
                          <a href="#">{{ item.fileName }}</a>
                          <div class="d-flex justify-content-between align-items-center">
                            <el-tooltip class="item" effect="dark" :content="$i18n.t('src.modules.order.detail.btnDownload')" placement="top">
                              <button class="btn-download" @click="downloadFile(S3_STORE_ENDPOINT + '/' + item.filePath)">
                                <i class="el-icon-download"></i>
                              </button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" :content="$i18n.t('src.modules.order.detail.btnDelete')" placement="top">
                              <button class="btn-remove" @click="handleRemoveFile(item)">
                                <i class="el-icon-close"></i>
                              </button>
                            </el-tooltip>
                          </div>
                        </li>
                      </ul>
                    </el-collapse-item>
                  </el-collapse>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="mt-3"> </b-col>
              </b-row>
            </Widget>
          </b-col>
          <b-col>
            <!-- Order Information (S) -->
            <Widget>
              <h5 style="font-weight: 600; text-align: center; color: #909399; border-bottom: 1px solid rgba(0, 0, 0, 0.05)" class="mb-3 pb-3">
                {{ this.$i18n.t('src.modules.order.index.deliveryInfo') }}
              </h5>
              <el-form :model="formInfo" :rules="ruleFormInfo" ref="formInfo" label-width="120px" size="small" label-position="top">
                <el-form-item>
                  <div class="mb-1" style="color: #adb5bd; font-size: 12px; letter-spacing: 1px; font-weight: 600">
                    <span class="text-uppercase">{{ this.$i18n.t('src.modules.order.index.receiver') }}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="col-md-10">
                      <span v-if="editInfo !== 'name'">{{ formInfo.name }}</span>
                      <el-form-item prop="name" v-else>
                        <el-input
                          size="medium"
                          :placeholder="
                            this.$i18n.t('src.modules.order.index.input.placeholder', {
                              name: 'name'
                            })
                          "
                          v-model="formInfo.name"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-2">
                      <el-button class="btn-blue-sm" type="primary" size="mini"  @click.prevent="enableEditOrder('name')" v-if="editInfo !== 'name'">
                        <i class="el-icon-edit mr-1" /> {{ $i18n.t('common.label.edit') }}
                      </el-button>

                      <el-button class="btn-green-sm" type="success" size="mini"  @click.prevent="closeEditOrder()" v-else>
                        <i class="el-icon-check mr-1" /> {{ $i18n.t('common.label.save') }}
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="mb-1" style="color: #adb5bd; font-size: 12px; letter-spacing: 1px; font-weight: 600">
                    <span class="text-uppercase">{{ this.$i18n.t('src.modules.order.index.phone_number') }}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="col-md-10">
                      <span v-if="editInfo !== 'phone'">{{ formInfo.phone }}</span>
                      <el-form-item prop="phone" v-else>
                        <el-input
                          size="medium"
                          :placeholder="
                            this.$i18n.t('src.modules.order.index.input.placeholder', {
                              name: 'phone number'
                            })
                          "
                          v-model="formInfo.phone"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-2">
                      <el-button class="btn-blue-sm" type="primary" size="mini"  @click.prevent="enableEditOrder('phone')" v-if="editInfo !== 'phone'">
                        <i class="el-icon-edit mr-1" /> {{ $i18n.t('common.label.edit') }}
                      </el-button>
                      <el-button  class="btn-save ml-2" @click.prevent="closeEditOrder()" v-else>
                        <i class="el-icon-document-checked mr-1" /> {{ $i18n.t('common.label.save') }}
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item v-if="editInfo !== 'address'">
                  <div class="mb-1" style="color: #adb5bd; font-size: 12px; letter-spacing: 1px; font-weight: 600">
                    <span class="text-uppercase">{{ this.$i18n.t('src.modules.order.index.delivery_address') }}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="col-md-10">
                      <span>{{ fullAddress }}</span>
                    </div>
                    <div class="col-md-2">
                      <el-button class="btn-blue-sm" type="primary" size="mini"  @click.prevent="enableEditOrder('address')">
                        <i class="el-icon-edit mr-1" /> {{ $i18n.t('common.label.edit') }}
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item v-else>
                  <div class="mb-2" style="color: #adb5bd; font-size: 12px; letter-spacing: 1px; font-weight: 600">
                    <span class="text-uppercase">{{ this.$i18n.t('src.modules.order.index.delivery_address') }}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="col-md-10">
                      <el-form-item label="Địa chỉ đã lưu">
                        <el-select
                          size="medium"
                          :placeholder="$i18n.t('src.modules.order.detail.customerAddress.placeholder')"
                          v-model="selectedAvailableAddress"
                          filterable
                          clearable
                          width="80%"
                          @change="handleCustomerAddressChange"
                        >
                          <el-option v-for="item in customerAddressOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$i18n.t('src.modules.order.index.form.province.title')" prop="province">
                        <el-select size="small" clearable v-model="formInfo.province" width="100%" filterable @change="resetLocation('province')">
                          <el-option :label="$i18n.t('src.modules.order.index.form.province.placeholder')" value=""></el-option>
                          <el-option v-for="item in provinceList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$i18n.t('src.modules.order.index.form.district.title')" prop="district">
                        <el-select size="small" clearable v-model="formInfo.district" width="100%" filterable @change="resetLocation('district')">
                          <el-option :label="$i18n.t('src.modules.order.index.form.district.placeholder')" value=""></el-option>
                          <el-option v-for="item in districtList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$i18n.t('src.modules.order.index.form.ward.title')" prop="ward">
                        <el-select size="small" clearable v-model="formInfo.ward" width="100%" filterable>
                          <el-option :label="$i18n.t('src.modules.order.index.form.ward.placeholder')" value=""></el-option>
                          <el-option v-for="item in wardList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$i18n.t('src.modules.order.index.form.address.title')" prop="address">
                        <el-input v-model="formInfo.address" clearable :placeholder="$i18n.t('src.modules.order.index.form.address.placeholder')"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-2">
                      <el-button class="btn-green-sm" type="success" size="mini"  @click.prevent="closeEditOrder()">
                        <i class="el-icon-check mr-1" /> {{ $i18n.t('common.label.save') }}
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </Widget>
            <!-- Order Information (E) -->

            <!-- Invoice Information (S) -->
            <Widget v-if="invoiceInfo">
              <h5 style="font-weight: 600; text-align: center; color: #909399; border-bottom: 1px solid rgba(0, 0, 0, 0.05)" class="mb-3 pb-3">
                {{ this.$i18n.t('src.modules.order.index.invoiceInfo') }}
              </h5>
              <div class="mt-4">
                <div class="mb-1" style="color: #adb5bd; font-size: 12px; letter-spacing: 1px; font-weight: 600">
                  <span class="text-uppercase">{{ this.$i18n.t('src.modules.order.index.invoice.companyName') }}</span>
                </div>
                <div class="col-md-12 pt-3 pb-3">
                  <span>{{ invoiceInfo.companyName }}</span>
                </div>
              </div>
              <div class="mt-4">
                <div class="mb-1" style="color: #adb5bd; font-size: 12px; letter-spacing: 1px; font-weight: 600">
                  <span class="text-uppercase">{{ this.$i18n.t('src.modules.order.index.invoice.email') }}</span>
                </div>
                <div class="col-md-12 pt-3 pb-3">
                  <span>{{ invoiceInfo.email }}</span>
                </div>
              </div>
              <div class="mt-4">
                <div class="mb-1" style="color: #adb5bd; font-size: 12px; letter-spacing: 1px; font-weight: 600">
                  <span class="text-uppercase">{{ this.$i18n.t('src.modules.order.index.invoice.phone') }}</span>
                </div>
                <div class="col-md-12 pt-3 pb-3">
                  <span>{{ invoiceInfo.phone }}</span>
                </div>
              </div>
              <div class="mt-4">
                <div class="mb-1" style="color: #adb5bd; font-size: 12px; letter-spacing: 1px; font-weight: 600">
                  <span class="text-uppercase">{{ this.$i18n.t('src.modules.order.index.invoice.taxCode') }}</span>
                </div>
                <div class="col-md-12 pt-3 pb-3">
                  <span>{{ invoiceInfo.taxCode }}</span>
                </div>
              </div>
              <div class="mt-4">
                <div class="mb-1" style="color: #adb5bd; font-size: 12px; letter-spacing: 1px; font-weight: 600">
                  <span class="text-uppercase">{{ this.$i18n.t('src.modules.order.index.invoice.address') }}</span>
                </div>
                <div class="col-md-12 pt-3 pb-3">
                  <span>{{ this.getFullAddress(invoiceInfo.address, invoiceInfo.district, invoiceInfo.ward) }}</span>
                </div>
              </div>
            </Widget>
            <!-- Invoice Information (E) -->
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Send Mail Popup -->
    <el-dialog
      :title="$i18n.t('src.modules.order.detail.sendMail.title')"
      :visible.sync="isShowSendMailDialog"
      width="60%"
      :before-close="handleCloseSendMailDialog"
      custom-class="select-address-dialog"
    >
      <b-row v-loading="isLoadingSendMail">
        <b-col cols="5" style="border-right: 1px solid #f0f0f0">
          <div class="sub-title mb-3">
            {{ this.$i18n.t('src.modules.order.detail.sendMail.select.receiveMail.title') }}
          </div>
          <el-cascader
            :options="roleUserObj"
            :props="props"
            v-model="listSelectUserMail"
            :show-all-levels="false"
            :placeholder="$i18n.t('src.modules.order.detail.sendMail.select.receiveMailUser.placeholder')"
            filterable
            clearable
            style="width: 99%"
            class="mb-3"
            @change="handleChangeListUserMail"
          ></el-cascader>
          <!-- <el-cascader
            :options="roleSupplierUserObj"
            :props="props"
            v-model="listSelectSupplierUserMail"
            :show-all-levels="false"
            :placeholder="$i18n.t('src.modules.order.detail.sendMail.select.receiveMailSupplier.placeholder')"
            filterable
            clearable
            style="width: 99%"
            class="mb-3"
            @change="handleChangeListSupplierUserMail"
          ></el-cascader> -->
          <el-checkbox v-model="checkedCustomerMail" class="mb-3"
            >{{ this.$i18n.t('src.modules.order.detail.sendMail.checkbox.title') }}: {{ this.orderData.customer && this.orderData.customer.email }}</el-checkbox
          >
          <div class="sub-title mb-3">
            {{ this.$i18n.t('src.modules.order.detail.sendMail.inputAdd.title') }}
          </div>
          <b-row class="d-flex justify-content-between mb-3">
            <b-col cols="8">
              <el-input
                :placeholder="$i18n.t('src.modules.order.detail.sendMail.inputAdd.placeholder')"
                v-model="addedMail"
                size="medium"
                @keyup.enter.native="handleEnterKeyUp"
                clearable
                filterable
              ></el-input>
            </b-col>
            <b-col cols="4">
              <el-button type="primary" icon="el-icon-plus" size="medium" @click="handleAddMail" ref="addMail">
                {{ this.$i18n.t('src.modules.order.detail.sendMail.btnAdd') }}
              </el-button>
            </b-col>
          </b-row>
          <el-tag v-for="mail in listAddedMail" :key="mail" closable type="info" class="mr-2 mb-3" @close="handleRemoveMail(mail)">
            {{ mail }}
          </el-tag>
        </b-col>
        <b-col cols="7">
          <el-form ref="formMail" :rules="ruleFormMail" :model="formMail" label-width="150px">
            <el-form-item :label="$i18n.t('src.modules.order.detail.sendMail.select.template.label')" prop="template">
              <el-select v-model="formMail.template" :placeholder="$i18n.t('src.modules.order.detail.sendMail.select.template.placeholder')" width="100%" clearable filterable>
                <el-option v-for="mt in listMailTemplates" :key="mt.value" :label="mt.label" :value="mt.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$i18n.t('src.modules.order.detail.sendMail.input.title.title')" width="100%" prop="note">
              <el-input type="text" v-model="formMail.title" :placeholder="$i18n.t('src.modules.order.detail.sendMail.input.title.placeholder')"> </el-input>
            </el-form-item>
            <el-form-item :label="$i18n.t('src.modules.order.detail.sendMail.textarea.note.title')" width="100%" prop="note">
              <el-input type="textarea" v-model="formMail.note" :rows="4" :placeholder="$i18n.t('src.modules.order.detail.sendMail.textarea.note.placeholder')"></el-input>
            </el-form-item>
          </el-form>
        </b-col>
      </b-row>
      <span slot="footer" class="dialog-footer">
        <el-button class="float-left" @click="isShowSendMailDialog = false"> {{ this.$i18n.t('common.label.close') }} </el-button>
        <el-button type="warning" @click="handlePreviewMail">
          {{ this.$i18n.t('src.modules.order.detail.sendMail.btnPreviewMail') }}
        </el-button>
        <el-button type="warning" @click="handleSendMail">
          {{ this.$i18n.t('src.modules.order.detail.sendMail.btnSendMail') }}
        </el-button>
      </span>
    </el-dialog>
    <!-- Send Mail Popup -->
    <!-- Print PO HTML (S) -->

    <VueHtml2pdf :manual-pagination="true" :enable-download="true" ref="DownloadComp" pdf-format="a4" :pdf-quality="2" :html-to-pdf-options="{ margin: [10, 0, 10, 0], filename: orderData.code }">
      <section slot="pdf-content">
        <span v-html="pdfPOContent"></span>
      </section>
    </VueHtml2pdf>
    <!-- Print PO HTML (E) -->

    <!-- Print Delivery Record HTML (S) -->
    <VueHtml2pdf
      :manual-pagination="true"
      :enable-download="true"
      ref="DownloadDeliveryRecord"
      pdf-format="a4"
      :pdf-quality="2"
      :html-to-pdf-options="{ margin: [10, 0, 10, 0], filename: 'DeliveryRecord-' + orderData.code }"
    >
      <section slot="pdf-content">
        <span v-html="pdfDeliveryRecordContent"></span>
      </section>
    </VueHtml2pdf>
    <!-- Print Delivery Record HTML (E) -->

    <!-- Print PO HTML (S) -->
    <VueHtml2pdf
      :enable-download="true"
      ref="DownloadSupplierPO"
      :paginate-elements-by-height="1100"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :manual-pagination="false"
      :html-to-pdf-options="{ margin: [10, 0, 10, 0], filename: selectedSupplierCode + '-' + orderData.code }"
    >
      <section slot="pdf-content">
        <span v-html="pdfSupplierPOContent"></span>
      </section>
    </VueHtml2pdf>
    <!-- Print PO HTML (E) -->

    <!--  Show Mail Content (S) -->
    <el-dialog title="Mail Preview" :visible.sync="mailPreviewShow" width="50%">
      <span v-html="this.mailPreviewContent"></span>
      <span v-if="isMailSupplier" slot="footer" class="dialog-footer">
        <el-button @click="mailPreviewShow = false"> Cancel </el-button>
        <el-button type="warning" @click="handleSendMailToSupplier(mailSupplierContent)">
          {{ this.$i18n.t('src.modules.order.detail.sendMail.btnSendMail') }}
        </el-button>
      </span>
    </el-dialog>
    <!--  Show Mail Content (E) -->

    <!-- Show Supplier Address (S) -->
    <el-dialog :visible.sync="isShowSupplierAddressPopup" width="50%" custom-class="supplier-address-dialog">
      <el-row>
        <el-col :span="6">
          <div class="p-3 text-center">
            <el-image class="mb-2" :src="selectedSupplier && S3_STORE_ENDPOINT + '/' + selectedSupplier.avatar" style="max-width: 70%; object-fit: cover"> </el-image>
          </div>
        </el-col>
        <el-col :span="18">
          <div>
            <h4 class="mb-2" style="letter-spacing: 1px">
              {{ selectedSupplier && selectedSupplier.name }}
            </h4>
            <h6 class="mb-4" style="letter-spacing: 1px; color: #0062cc">
              {{ selectedSupplier && selectedSupplier.typeName }}
            </h6>
            <el-tabs v-model="supplierInfoTab">
              <el-tab-pane :label="$i18n.t('src.modules.order.detail.supplierAddress.dialog.tab.infomation.title')" name="info">
                <div class="p-3">
                  <el-row>
                    <el-col :span="8">
                      <strong style="letter-spacing: 1px">
                        {{ $i18n.t('src.modules.order.detail.supplierAddress.dialog.email.title') }}
                      </strong>
                    </el-col>
                    <el-col :span="16">
                      <span style="letter-spacing: 1px">
                        {{ selectedSupplier && selectedSupplier.email ? selectedSupplier.email : $i18n.t('common.label.noData') }}
                      </span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <strong style="letter-spacing: 1px">
                        {{ $i18n.t('src.modules.order.detail.supplierAddress.dialog.phone.title') }}
                      </strong>
                    </el-col>
                    <el-col :span="16">
                      <span style="letter-spacing: 1px">
                        {{ selectedSupplier && selectedSupplier.phone ? selectedSupplier.phone : $i18n.t('common.label.noData') }}
                      </span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <strong style="letter-spacing: 1px">
                        {{ $i18n.t('src.modules.order.detail.supplierAddress.dialog.taxCode.title') }}
                      </strong>
                    </el-col>
                    <el-col :span="16">
                      <span style="letter-spacing: 1px">
                        {{ selectedSupplier && selectedSupplier.taxCode ? selectedSupplier.taxCode : $i18n.t('common.label.noData') }}
                      </span>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="$i18n.t('src.modules.order.detail.supplierAddress.dialog.tab.address.title')" name="address">
                <div v-if="selectedSupplier && selectedSupplier.listAddress.length > 0">
                  <div v-for="(sup, index) in selectedSupplier.listAddress" :key="index" class="p-3">
                    <p>
                      <strong class="mr-1" style="letter-spacing: 1px"
                        >{{ $i18n.t('src.modules.order.detail.supplierAddress.dialog.address.title') }}
                        :
                      </strong>
                      <span style="letter-spacing: 1px">{{ getFullAddress(sup.address, sup.district, sup.ward) }}</span>
                    </p>
                    <p>
                      <strong class="mr-1" style="letter-spacing: 1px"
                        >{{ $i18n.t('src.modules.order.detail.supplierAddress.dialog.receiverName.title') }}
                        :
                      </strong>
                      <span style="letter-spacing: 1px">{{ sup.receiverName }}</span>
                    </p>
                    <p>
                      <strong class="mr-1" style="letter-spacing: 1px"
                        >{{ $i18n.t('src.modules.order.detail.supplierAddress.dialog.phone.title') }}
                        :
                      </strong>
                      <span style="letter-spacing: 1px">{{ sup.phone }}</span>
                    </p>
                    <p>
                      <strong class="mr-1" style="letter-spacing: 1px"
                        >{{ $i18n.t('src.modules.order.detail.supplierAddress.dialog.email.title') }}
                        :
                      </strong>
                      <span style="letter-spacing: 1px">{{ sup.email }}</span>
                    </p>
                  </div>
                </div>
                <div class="text-center" v-else>
                  <p>
                    {{ this.$i18n.t('src.modules.order.detail.supplierAddress.dialog.noData') }}
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="$i18n.t('src.modules.order.detail.supplierAddress.dialog.tab.bank.title')" name="payment">
                <div v-if="selectedSupplier && selectedSupplier.supBanks.length > 0">
                  <div v-for="(sup, index) in selectedSupplier.supBanks" :key="index" class="p-3" style="border-bottom: 1px solid #dedede">
                    <el-row>
                      <el-col :span="8">
                        <strong style="letter-spacing: 1px">
                          {{ $i18n.t('src.modules.order.detail.supplierAddress.dialog.bankName.title') }}
                        </strong></el-col
                      >
                      <el-col :span="16">
                        <span style="letter-spacing: 1px">
                          {{ sup.bankName }}
                        </span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8">
                        <strong style="letter-spacing: 1px">
                          {{ $i18n.t('src.modules.order.detail.supplierAddress.dialog.bankBranch.title') }}
                        </strong>
                      </el-col>
                      <el-col :span="16">
                        <span style="letter-spacing: 1px">
                          {{ sup.bankBranch }}
                        </span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8">
                        <strong style="letter-spacing: 1px">
                          {{ $i18n.t('src.modules.order.detail.supplierAddress.dialog.accountName.title') }}
                        </strong>
                      </el-col>
                      <el-col :span="16">
                        <span style="letter-spacing: 1px">
                          {{ sup.accountName }}
                        </span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8">
                        <strong style="letter-spacing: 1px">
                          {{ $i18n.t('src.modules.order.detail.supplierAddress.dialog.accountNumber.title') }}
                        </strong>
                      </el-col>
                      <el-col :span="16">
                        <span style="letter-spacing: 1px">
                          {{ sup.accountNumber }}
                        </span>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="text-center" v-else>
                  <p>
                    {{ this.$i18n.t('src.modules.order.detail.supplierAddress.dialog.noData') }}
                  </p>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <!-- <div class="text-center" v-else>
            <p>
              {{ this.$i18n.t('src.modules.order.detail.supplierAddress.dialog.noData') }}
            </p>
          </div> -->
        </el-col>
      </el-row>
    </el-dialog>
    <!-- Show Supplier Address (E) -->

    <!-- Show Info Order Supplier Dialog -->
    <el-dialog :title="$i18n.t('src.modules.order.detail.orderInformation.dialog.title')" :visible.sync="isShowInfoDialog" width="40%" :close-on-click-modal="false">
      <el-descriptions title="" direction="vertical" :column="3" border>
        <el-descriptions-item :label="$i18n.t('src.modules.order.detail.packageLength')">
          {{ (this.selectedOrderSupplierInfo && this.selectedOrderSupplierInfo.length) || $i18n.t('common.label.noData') }}
        </el-descriptions-item>
        <el-descriptions-item :label="$i18n.t('src.modules.order.detail.packageWidth')">
          {{ (this.selectedOrderSupplierInfo && this.selectedOrderSupplierInfo.width) || $i18n.t('common.label.noData') }}
        </el-descriptions-item>
        <el-descriptions-item :label="$i18n.t('src.modules.order.detail.packageHeight')">
          {{ (this.selectedOrderSupplierInfo && this.selectedOrderSupplierInfo.height) || $i18n.t('common.label.noData') }}
        </el-descriptions-item>
        <el-descriptions-item :label="$i18n.t('src.modules.order.detail.packageWeight')">
          {{ (this.selectedOrderSupplierInfo && this.selectedOrderSupplierInfo.weight) || $i18n.t('common.label.noData') }}
        </el-descriptions-item>
        <el-descriptions-item :label="$i18n.t('src.modules.order.detail.orderInformation.dialog.totalQuantity.title')">
          {{ this.selectedOrderSupplierInfo && this.selectedOrderSupplierInfo.totalQty }}
        </el-descriptions-item>
        <el-descriptions-item :label="$i18n.t('src.modules.order.detail.orderInformation.dialog.totalPrice.title')">
          {{
            this.selectedOrderSupplierInfo &&
              new Intl.NumberFormat('vi-VN', {
                style: 'currency',
                currency: 'VND'
              }).format(this.selectedOrderSupplierInfo.totalPrice)
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$i18n.t('src.modules.order.detail.estimatedDeliveryDate')" :span="2">
          {{ this.selectedOrderSupplierInfo && this.selectedOrderSupplierInfo.estimatedDeliveryDate | formattedDateTime }}
        </el-descriptions-item>
        <el-descriptions-item :label="$i18n.t('src.modules.order.detail.deliveryForCustomer')" :span="2">
          {{ this.selectedOrderSupplierInfo && this.selectedOrderSupplierInfo.deliveryDateCustomer | formattedDateTime }}
        </el-descriptions-item>
        <el-descriptions-item :label="$i18n.t('src.modules.order.detail.warehouseAddress')" :span="4">
          {{
            this.selectedOrderSupplierInfo && this.selectedOrderSupplierInfo.supplierAddress
              ? this.getFullAddress(
                  this.selectedOrderSupplierInfo.supplierAddress.address,
                  this.selectedOrderSupplierInfo.supplierAddress.district,
                  this.selectedOrderSupplierInfo.supplierAddress.ward
                )
              : $i18n.t('common.label.noData')
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$i18n.t('src.modules.order.detail.note')" :span="4">
          {{ (this.selectedOrderSupplierInfo && this.selectedOrderSupplierInfo.note) || $i18n.t('common.label.noData') }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <!-- Show Info Order Supplier Dialog -->

    <!-- WWaiting confirm  Popup -->
    <el-dialog :title="$i18n.t('src.modules.order.detail.orderInfo.dialog.title')" :visible.sync="isShowWaitingConfirmDialog" width="30%">
      <el-row>
        <el-col :span="8"> {{ $i18n.t('src.modules.order.detail.estimatedDeliveryDate') }} </el-col>
        <el-col :span="16">
          <el-date-picker
            v-model="formWaitingConfirm.deliveryDate"
            type="date"
            size="small"
            :placeholder="$i18n.t('src.modules.order.detail.estimatedDeliveryDate.placeholder')"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8"> {{ $i18n.t('src.modules.order.detail.titleNote') }} </el-col>
        <el-col :span="16">
          <el-input v-model="formWaitingConfirm.title" :placeholder="$i18n.t('src.modules.order.detail.titleNote.placeholder')"> </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8"> {{ $i18n.t('src.modules.order.detail.note') }} </el-col>
        <el-col :span="16">
          <el-input type="textarea" :rows="2" v-model="formWaitingConfirm.note" :placeholder="$i18n.t('src.modules.order.detail.note.placeholder')"> </el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowWaitingConfirmDialog = false">
          {{ $i18n.t('common.label.cancel') }}
        </el-button>
        <el-button type="warning" @click="handleUpdateOrderStatus">
          {{ $i18n.t('common.label.confirm') }}
        </el-button>
      </span>
    </el-dialog>
    <!-- WWaiting confirm  Popup -->

    <!-- Order Supplier Note Popup (S) -->
    <el-dialog width="30%" :title="$i18n.t('src.modules.order.detail.noteAdmin.dialog.title')" :visible.sync="isShowOrderSupplierNote" :close-on-click-modal="false">
      <el-form label-position="right" label-width="100px" :model="formNoteAdmin">
        <el-form-item :label="$i18n.t('src.modules.order.detail.noteAdmin.dialog.field.title.title')">
          <el-input v-model="formNoteAdmin.title" :placeholder="$i18n.t('src.modules.order.detail.noteAdmin.dialog.field.title.placeholder')"></el-input>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.modules.order.detail.noteAdmin.dialog.field.title.note')">
          <el-input type="textarea" :rows="3" :placeholder="$i18n.t('src.modules.order.detail.noteAdmin.dialog.field.note.placeholder')" v-model="formNoteAdmin.note"> </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseOrderSupplierNote">{{ $i18n.t('common.label.cancel') }}</el-button>
        <el-button type="primary" @click="handleUpdateOrderStatus">{{ $i18n.t('common.label.send') }}</el-button>
      </span>
    </el-dialog>
    <!-- Order Supplier Note Popup (E) -->

    <!-- Order Supplier Status History Popup -->
    <el-dialog :title="$i18n.t('src.modules.order.detail.orderSupplierStatus.dialog.title')" :visible.sync="isShowOrderSupStatusHistory" width="30%" :close-on-click-modal="false">
      <div v-show="activities.length === 0">
        <span class="centerMessage"> {{ this.$i18n.t('src.modules.order.detail.noActivities') }}</span>
      </div>
      <el-timeline style="margin-top: 25px" v-loading="isLoadingTimeline">
        <el-timeline-item
          v-for="(activity, index) in activities"
          :key="index"
          :icon="activity.icon"
          :type="activity.type"
          :color="activity.color"
          :size="activity.size"
          :timestamp="activity.timestamp"
          placement="top"
          style="margin-top: 15px"
        >
          {{ activity.content }}
          <div v-if="activity.noteAdmin && !isShowNoteAdmin[Number(activity.statusCode)]" style="font-weight: lighter; font-size: small; color: gray; margin-top: -10px">
            <br />
            <!-- <b-badge pill class="status-blue"> <i class="el-icon-notebook-2"></i> </b-badge> -->
            <b-badge pill class="status-orange"> EI </b-badge>
            <strong> {{ activity.titleNoteAdmin && activity.titleNoteAdmin.length > 0 ? activity.titleNoteAdmin : $i18n.t('src.modules.order.detail.ordeerSupplierStatus.noteFromEI') }} </strong>
            <br />
            {{ activity.noteAdmin | truncate(20) }}
            <a style="font-weight: bold; text-decoration-line: underline" v-show="activity.noteAdmin && activity.noteAdmin.length > 20" @click="isShowNoteAdmin[Number(activity.statusCode)] = true">
              Xem thêm
            </a>
          </div>
          <div v-if="activity.noteAdmin && isShowNoteAdmin[Number(activity.statusCode)]" style="font-weight: lighter; font-size: small; color: gray; margin-top: -10px">
            <br />
            <!-- <b-badge pill class="status-blue"> <i class="el-icon-notebook-2"></i> </b-badge> -->
            <b-badge pill class="status-orange"> EI </b-badge>
            <strong> {{ activity.titleNoteAdmin && activity.titleNoteAdmin.length > 0 ? activity.titleNoteAdmin : $i18n.t('src.modules.order.detail.ordeerSupplierStatus.noteFromEI') }} </strong>
            <br />
            {{ activity.noteAdmin }}
          </div>
          <div v-if="activity.noteSupplier && !isShowNoteSupplier[Number(activity.statusCode)]" style="font-weight: lighter; font-size: small; color: gray; margin-top: -10px">
            <br />
            <!-- <b-badge pill class="status-blue"> <i class="el-icon-notebook-2"></i> </b-badge> -->
            <b-badge pill class="status-green"> {{ $i18n.t('common.label.seller') }} </b-badge>
            <strong>
              {{ activity.titleNoteSupplier && activity.titleNoteSupplier.length > 0 ? activity.titleNoteSupplier : $i18n.t('src.modules.order.detail.ordeerSupplierStatus.noteFromSeller') }}
            </strong>
            <br />
            <div>{{ activity.noteSupplier | truncate(20) }}</div>
            <a
              style="font-weight: bold; text-decoration-line: underline"
              v-show="activity.noteSupplier && activity.noteSupplier.length > 20"
              @click="isShowNoteSupplier[Number(activity.statusCode)] = true"
            >
              {{ $i18n.t('common.label.seeMore') }}
            </a>
          </div>
          <div v-if="activity.noteSupplier && isShowNoteSupplier[Number(activity.statusCode)]" style="font-weight: lighter; font-size: small; color: gray; margin-top: -10px">
            <br />
            <!-- <b-badge pill class="status-blue"> <i class="el-icon-notebook-2"></i> </b-badge> -->
            <b-badge pill class="status-green"> Seller </b-badge>
            <strong>
              {{ activity.titleNoteSupplier && activity.titleNoteSupplier.length > 0 ? activity.titleNoteSupplier : $i18n.t('src.modules.order.detail.ordeerSupplierStatus.noteFromSeller') }}
            </strong>
            <br />
            {{ activity.noteSupplier }}
          </div>
        </el-timeline-item>
      </el-timeline>
    </el-dialog>
    <!-- Order Supplier Status History Popup -->

    <!-- Show customer info Popup -->
    <el-dialog :title="$i18n.t('src.modules.order.detail.customerInfo.dialog.title')" :visible.sync="isShowCustomerInfoPopup" width="35%" :close-on-click-modal="false">
      <div class="d-flex justify-content-center mb-5">
        <el-avatar :size="100" :src="'https://ei-dev-store.s3.amazonaws.com/' + cloneOrderData.customer.avatar" v-if="cloneOrderData.customer && cloneOrderData.customer.avatar"></el-avatar>
        <el-avatar :size="100" icon="el-icon-user" v-else></el-avatar>
      </div>
      <el-descriptions class="margin-top" :column="2" border direction="vertical">
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-warning mr-2"></i>
            {{ $i18n.t('src.modules.order.detail.customerInfo.dialog.field.customerCode') }}
          </template>
          {{ this.cloneOrderData.customer && this.cloneOrderData.customer.code }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user mr-2"></i>
            {{ $i18n.t('src.modules.order.detail.customerInfo.dialog.field.customerName') }}
          </template>
          {{ this.cloneOrderData.customer && this.cloneOrderData.customer.customerName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-message mr-2"></i>
            Email
          </template>
          {{ this.cloneOrderData.customer && this.cloneOrderData.customer.email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone mr-2"></i>
            {{ $i18n.t('src.modules.order.detail.customerInfo.dialog.field.phoneNumber') }}
          </template>
          {{ this.cloneOrderData.customer && this.cloneOrderData.customer.phone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            {{ $i18n.t('src.modules.order.detail.customerInfo.dialog.field.taxCode') }}
          </template>
          {{ this.cloneOrderData.customer && this.cloneOrderData.customer.taxCode }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <!-- Show customer info Popup -->
    <EIChatBox :data="this.supplierInfo" :is-show="isShowChatBox" @onCloseChatBox="handleCloseChatBox" v-if="this.supplierInfo" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SelectProduct from 'components/Modal/SelectProduct';
import EIChatBox from 'components/ChatBox';
import LalaMove from 'components/Shipping/lalaMove';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {
  CHECKOUT_COLOR,
  orderSupplierActions,
  SHOW_INFO,
  SEND_MAIL,
  DOWNLOAD_PO,
  SHOW_TIMELINE,
  SEND_MESSAGE,
  SUPPLIER_MAIL_TEMPLATE_TYPE,
  SUPPLIER_MAIL_TEMPLATE_CODE,
  ORDER_SUPPLIER_COLOR,
  ORDER_SUPPLIER_STATUS,
  PO_CHO_XAC_NHAN,
  PO_DA_XAC_NHAN,
  PO_DANG_GIAO_HANG,
  PO_DA_NHAN_HANG,
  PO_DA_HUY,
  DA_HUY,
  VCHR_DSCNT_TP,
  VCHR_TP,
  TYPE_TEMPLATE_MAIL_CODE
} from 'core/constants';
import vnLocal from 'vn-local';
import axios from 'axios';
import VueHtml2pdf from 'vue-html2pdf';
const ORDER_STATUS_CODE = 'ORDR_STT';
const ORDER_SUP_STATUS_CODE = 'ORDR_SUP_STT';

const tagOptions = [
  {
    value: 'AMISExported',
    label: 'Đã đẩy AMIS'
  },
  {
    value: 'InvoiceExported',
    label: 'Đã xuất hóa đơn'
  }
];

export default {
  components: { SelectProduct, VueHtml2pdf, LalaMove, EIChatBox },
  data() {
    return {
      props: { multiple: true },
      STATUS_CODE_DA_HUY: DA_HUY,
      S3_STORE_ENDPOINT: process.env.VUE_APP_S3_STORE_ENDPOINT,
      EI_ENDPOINT: process.env.VUE_APP_EI_ENDPOINT,
      isLoading: true,
      isLoadingSendMail: false,
      price: '',
      supplierPrice: '',
      editRow: null,
      editInfo: null,
      oldOrderData: {},
      multipleSelectProduct: [],
      radioDiscount: 0,
      selectedDiscount: 0,
      formOrderDetailOld: {},
      selectProductVisible: false,
      lalamoveVisible: false,
      selectedDocumentTypes: 1,
      loadingUploadLocal: false,
      selectedListUser: [],
      // selectedListSupplierUser: [],
      checkedCustomerMail: false,
      addedMail: '',
      listAddedMail: [],
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      // Send Mail Dialog
      isShowSendMailDialog: false,

      // Search Product Data
      searchSupplier: {
        keyword: '' // search input
      },

      userOptions: [],
      personInChargesOptions: [],
      customerAddressOptions: [],
      isMailSupplier: false,

      formOrderDetail: {
        customerName: '',
        saleId: '',
        bdId: [],
        customerAddressId: '',
        personInChargesId: '',
        note: '',
        reasonForCancellation: ''
      },

      orderTags: [],

      formSearchAddress: {
        province: '',
        district: '',
        ward: '',
        checkoutAddress: ''
      },

      formSearchVoucher: {
        name: '',
        code: '',
        date: ''
      },

      formSearchCoupon: {
        name: '',
        code: '',
        date: ''
      },

      formInfo: {
        name: '',
        phone: '',
        address: '',
        province: '',
        district: '',
        ward: ''
      },

      formInfoDefault: {
        name: '',
        phone: '',
        address: '',
        province: '',
        district: '',
        ward: ''
      },

      formMail: {
        template: '',
        note: '',
        title: ''
      },

      formMailDefault: {
        template: '',
        note: '',
        title: ''
      },

      supplierInfoArr: [],

      editorConfig: {
        language: 'en'
      },

      // Rule Form Info
      ruleFormInfo: {
        name: [
          {
            required: true,
            message: this.$i18n.t('src.modules.order.index.rule.name'),
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: true,
            message: this.$i18n.t('src.modules.order.index.rule.phone'),
            trigger: 'blur'
          },
          { validator: this.validatePhone, trigger: 'blur' },
          {
            min: 8,
            max: 11,
            message: this.$i18n.t('common.notify.message.error.phone.length', {
              from: 8,
              to: 11
            }),
            trigger: 'blur'
          }
        ],
        province: [
          {
            required: true,
            message: this.$i18n.t('src.modules.order.index.rule.province'),
            trigger: 'change'
          }
        ],
        district: [
          {
            required: true,
            message: this.$i18n.t('src.modules.order.index.rule.district'),
            trigger: 'change'
          }
        ],
        ward: [
          {
            required: true,
            message: this.$i18n.t('src.modules.order.index.rule.ward'),
            trigger: 'change'
          }
        ],
        address: [
          {
            required: true,
            message: this.$i18n.t('src.modules.order.index.rule.address'),
            trigger: 'change'
          }
        ]
      },

      ruleFormMail: {
        template: [
          {
            required: true,
            message: this.$i18n.t('src.modules.order.index.rule.template'),
            trigger: 'change'
          }
        ]
      },
      pdfPOContent: '',
      pdfDeliveryRecordContent: '',
      pdfSupplierPOContent: '',
      pdfPOName: 'OrderPOFile',
      mailPreviewContent: '',
      mailSupplierContent: null,
      mailPreviewShow: false,
      isEnableEditShippingFee: false,
      isShowSupplierAddressPopup: false,
      selectedSupplier: null,
      listStatusSupplier: {},
      setOrderData: null,
      cloneOrderData: {},
      defaultStatus: '',
      setPrice: {
        totalPrice: 0,
        vat: 0,
        shippingFee: 0,
        totalSalePrice: 0
      },
      isShowInfoDialog: false,
      selectedOrderSupplierInfo: null,
      selectedOrderSupplierData: null,
      formWaitingConfirm: {
        deliveryDate: '',
        note: '',
        title: ''
      },
      isShowWaitingConfirmDialog: false,
      formWaitingConfirmDefault: {
        deliveryDate: '',
        note: '',
        title: ''
      },
      supplierInfoTab: 'info',
      setShippingFee: '',
      setPromotionId: '',
      selectedOrderDetail: '',
      discountInfo: null,
      ds: '',
      tagOptions,
      noteAdmin: '',
      isShowOrderSupplierNote: false,
      isShowOrderSupStatusHistory: false,
      formNoteAdmin: {
        title: '',
        note: ''
      },
      formNoteAdminDefault: {
        title: '',
        note: ''
      },
      isShowNoteAdmin: {
        [PO_CHO_XAC_NHAN]: false,
        [PO_DA_XAC_NHAN]: false,
        [PO_DANG_GIAO_HANG]: false,
        [PO_DA_NHAN_HANG]: false,
        [PO_DA_HUY]: false
      },
      isShowNoteSupplier: {
        [PO_CHO_XAC_NHAN]: false,
        [PO_DA_XAC_NHAN]: false,
        [PO_DANG_GIAO_HANG]: false,
        [PO_DA_NHAN_HANG]: false,
        [PO_DA_HUY]: false
      },
      isLoadingTimeline: false,
      listSelectUserMail: [],
      // listSelectSupplierUserMail: [],
      supplierInfo: null,
      isShowChatBox: false,
      selectedEiCash: false,
      oldSelectedEiCash: true,
      isShowCustomerInfoPopup: false,
      selectedSupplierCode: ''
    };
  },

  computed: {
    ...mapState('orderDetail', [
      'orderData',
      'orderDetails',
      'orderDetailAddLocal',
      'orderDocuments',
      'orderDocumentsAddLocal',
      'orderStatus',
      'orderSuppliers',
      'vat',
      'shippingFee',
      'totalPrice',
      'totalSalePrice',
      'orderDetailSuppliers'
    ]),
    ...mapState('supplierAddress', ['supplierAddress']),
    ...mapState('tag', ['listTag']),
    ...mapState('supplier', ['suppliers', 'suppliersInPage']),
    ...mapState('product', ['products']),
    ...mapState('category', ['categories', 'categoriesMap']),
    ...mapState('voucher', ['vouchers', 'listVoucher']),
    ...mapState('coupon', ['coupons', 'listCoupon']),
    ...mapState('orderStatus', ['listStatus']),
    ...mapState('documentType', ['documentTypes']),
    ...mapState('auth', ['user']),
    ...mapState('role', ['roles', 'rolesMap']),
    ...mapState('user', ['users']),
    ...mapState('unit', ['units']),
    ...mapState('mailTemplate', ['mailTemplates']),
    ...mapState('common', ['listCommons', 'listCommon2nd']),
    ...mapState('orderSupplierStatusHistory', ['orderSupplierStatusHistories']),
    ...mapState('supplierUser', ['supplierUsers']),
    ...mapState('basicInfo', ['basicInfo']),

    // supplier actrion

    listSupplierAction() {
      return Object.keys(orderSupplierActions).map(item => ({
        id: item,
        icon: orderSupplierActions[item].icon,
        title: orderSupplierActions[item].title
      }));
    },
    // suppier title
    titleSupplierDialog() {
      return `Supplier: ${this.selectedSupplier.name}`;
    },

    //get list province
    provinceList() {
      return vnLocal.getProvinces()?.map(item => {
        return {
          value: item.code,
          label: item.name
        };
      });
    },

    districtList() {
      return this.formInfo.province
        ? vnLocal.getDistrictsByProvinceCode(this.formInfo.province)?.map(item => {
            return {
              value: item.code,
              label: item.name
            };
          })
        : [];
    },

    wardList() {
      return this.formInfo.district
        ? vnLocal.getWardsByDistrictCode(this.formInfo.district)?.map(item => {
            return {
              value: item.code,
              label: item.name
            };
          })
        : [];
    },

    // get Full Address
    fullAddress() {
      const { address, district, ward } = this.formInfo;
      if (address == '' || district == '' || ward == '') return '';
      else {
        const districtList = vnLocal.getWardsByDistrictCode(district);
        const provinceName = districtList?.[0]?.provinceName || '';
        const districtName = districtList?.[0]?.districtName || '';
        const wardName = (ward && districtList?.find(d => d.code === ward)?.name) || '';
        return address + ', ' + wardName + ', ' + districtName + ', ' + provinceName;
      }
    },

    customerInvoiceInfo() {
      return this.orderData.invoice;
    },

    // list order detail
    allOrderDetails() {
      return this.orderDetails;
    },

    //list id supplier in order
    allSupplierId() {
      const list = [...this.orderDetails];
      const idsArray = list.map(item => item.supId);
      return [...new Set(idsArray)];
    },

    // list order detail supplier
    allOrderDetailSuppliers() {
      return this.orderDetailSuppliers;
    },

    listDocumentTypes() {
      return this.documentTypes.map(dt => ({
        value: dt.id,
        label: dt.name
      }));
    },

    listDocuments() {
      const totalDocuments = [...this.orderDocumentsAddLocal, ...this.orderDocuments];
      return totalDocuments.filter(od => od.items && od.items.length > 0);
    },
    // add comma when edit price
    priceWithComma: {
      get: function() {
        return this.selectedOrderDetail.price
          .toString()
          .replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      set: function(newValue) {
        this.selectedOrderDetail.price = newValue !== '' ? newValue.replace(/,/g, '') : '';
      }
    },

    supplierPriceWithComma: {
      get: function() {
        return this.selectedOrderDetail.supplierPrice
          .toString()
          .replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      set: function(newValue) {
        this.selectedOrderDetail.supplierPrice = newValue !== '' ? newValue.replace(/,/g, '') : '';
      }
    },

    shippingFeeWithComma: {
      get: function() {
        return this.setPrice.shippingFee
          .toString()
          .replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      set: function(newValue) {
        this.setPrice.shippingFee = newValue !== '' ? newValue.replace(/,/g, '') : '';
      }
    },

    // voucher list
    allVouchers() {
      const filterUnexpiredVoucher = this.vouchers.filter(voucher => {
        const currentDate = new Date();
        const startDate = new Date(voucher.startDate);
        const endDate = new Date(voucher.endDate);
        return currentDate >= startDate && currentDate <= endDate;
      });
      return filterUnexpiredVoucher.map(vou => {
        const { discountTypeCode = '' } = vou;
        if (discountTypeCode == VCHR_DSCNT_TP.PRCNT)
          return {
            label: `${vou.name} (- ${vou.value}%)`,
            value: vou.id
          };
        if (discountTypeCode == VCHR_DSCNT_TP.CASH)
          return {
            label: `${vou.name} (- ${new Intl.NumberFormat('vi-VN', {
              style: 'currency',
              currency: 'VND'
            }).format(vou.value)})`,
            value: vou.id
          };
        return null;
      });
    },

    voucherObj() {
      let obj = this.vouchers.reduce(function(o, val) {
        o[val.id] = val;
        return o;
      }, {});
      return obj;
    },

    //  coupon list
    allCoupons() {
      const filterUnexpiredCoupon = this.coupons.filter(voucher => {
        const currentDate = new Date();
        const startDate = new Date(voucher.startDate);
        const endDate = new Date(voucher.endDate);
        return currentDate >= startDate && currentDate <= endDate;
      });
      return filterUnexpiredCoupon.map(coupon => {
        return {
          label: `${coupon.name} (- ${coupon.discount}%)`,
          value: coupon.id
        };
      });
    },
    couponObj() {
      let obj = this.coupons.reduce(function(o, val) {
        o[val.id] = val;
        return o;
      }, {});
      return obj;
    },

    // create list supplier object
    getSupplierJSON() {
      let obj = this.suppliers.reduce(function(o, val) {
        o[val.id] = val.name;
        return o;
      }, {});
      return obj;
    },

    // create list unit object
    unitObj() {
      const lang = localStorage.getItem('user-language');
      let obj = this.units.reduce((acc, ele) => {
        acc[ele.code] = lang === 'vi-VN' ? ele.vi_VN : ele.en_US;
        return acc;
      }, {});
      return obj;
    },

    // create list supplier address
    supplierAddressObj() {
      let obj = this.suppliers.reduce(function(o, val) {
        o[val.id] = val.address;
        return o;
      }, {});
      return obj;
    },

    supplierObj() {
      let obj = this.suppliersInPage.reduce(function(o, val) {
        o[val.id] = val;
        return o;
      }, {});
      return obj;
    },

    // group user by roleId
    groupUserByRoleId() {
      return this.users.reduce((objectsByKeyValue, obj) => {
        const value = obj['roleId'];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat({
          value: obj.email,
          label: obj.email,
          disabled: this.listAddedMail.some(m => m === obj.email)
        });
        return objectsByKeyValue;
      }, {});
    },

    // group user by roleId
    groupSupplierUserByRoleId() {
      return this.supplierUsers.reduce((objectsByKeyValue, obj) => {
        const value = obj['roleId'];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat({
          value: obj.email,
          label: obj.email,
          disabled: this.listAddedMail.some(m => m === obj.email)
        });
        return objectsByKeyValue;
      }, {});
    },

    roleUserObj() {
      return this.roles.reduce((acc, ele) => {
        if (typeof this.groupUserByRoleId[ele.id] !== 'undefined') {
          acc.push({
            value: ele.name,
            label: ele.name,
            children: this.groupUserByRoleId[ele.id]
          });
        }
        return acc;
      }, []);
    },

    // roleSupplierUserObj() {
    //   return this.roles.reduce((acc, ele) => {
    //     if (typeof this.groupSupplierUserByRoleId[ele.id] !== 'undefined') {
    //       acc.push({
    //         value: ele.name,
    //         label: ele.name,
    //         children: this.groupSupplierUserByRoleId[ele.id]
    //       });
    //     }
    //     return acc;
    //   }, []);
    // },

    // list supplier
    allSuppliers() {
      return this.suppliers.map(item => {
        return {
          label: item.name,
          value: item.id
        };
      });
    },

    // list status order with coSlor status
    allStatusCheckout() {
      const data = this.listCommons.map(item => {
        return {
          value: item.code,
          label: item.name,
          color: CHECKOUT_COLOR[item.code]
        };
      });
      return data;
    },

    allOrderSupplierStatus() {
      const data = this.listCommon2nd.reduce((objKey, obj) => {
        if (parseInt(obj.code) !== 1) {
          objKey[obj.code] = {
            value: obj.code,
            label: obj.name,
            color: ORDER_SUPPLIER_COLOR[parseInt(obj.code)]
          };
        }
        return objKey;
      }, {});
      return data;
    },

    // list order status
    listOrderStatus() {
      return this.listCommons.map(item => ({
        value: item.code,
        label: item.name
      }));
    },

    // list mail template
    listMailTemplates() {
      return this.mailTemplates.map(mt => ({
        label: mt.name,
        value: mt.code
      }));
    },

    // all mail selected
    allMailSelect() {
      const allMails = [...this.selectedListUser, ...this.listAddedMail];
      if (this.checkedCustomerMail && this.orderData.customer) allMails.push(this.orderData.customer.email);
      return allMails;
    },

    // invoice information
    invoiceInfo() {
      return this.orderData.dataInvoice;
    },

    // checkout address list
    checkoutAddressList() {
      return this.orderData.customer.dataAddress.map(item => {
        const district = item.district ? vnLocal.getWardsByDistrictCode(item.district) : '';
        const provinceName = district?.[0]?.provinceName || '';
        const districtName = district?.[0]?.districtName || '';
        const wardName = (item.ward && district?.find(x => x.code === item.ward)?.name) || '';
        return item.address + ', ' + wardName + ', ' + districtName + ', ' + provinceName;
      });
    },

    // group product by supplier
    groupProductBySupplier() {
      const orderDetailSupplier = this.orderSuppliers;
      // group supplier by supId
      const group = this.allOrderDetails.reduce((objectsByKeyValue, obj) => {
        const value = obj['supId'];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});

      const groupOrderDetailSup = this.orderDetailSuppliers.reduce((objectsByKeyValue, obj) => {
        const value = obj['supplierId'];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});
      // get data for table by above group variable
      const t = Object.keys(group).map(item => {
        const orderSupplierInfo = orderDetailSupplier.find(ordrSup => parseInt(ordrSup.supId) === parseInt(item)) || {};
        if (!isEmpty(orderSupplierInfo) && this.supplierObj[item]) {
          if (!!this.supplierInfoArr.find(o => o.id === item) === false) {
            this.supplierInfoArr.push({
              id: item,
              name: this.supplierObj[item].name
            });
          }
          return {
            id: item,
            orderSupId: orderSupplierInfo.id,
            supInfo: {
              name: this.supplierObj[item].name,
              code: this.supplierObj[item].code,
              companyName: this.supplierObj[item].companyName,
              address: this.supplierObj[item].address,
              taxCode: this.supplierObj[item].taxCode,
              email: this.supplierObj[item].email,
              listUser: orderSupplierInfo.supplierInfo.listSupplierUsers && orderSupplierInfo.supplierInfo.listSupplierUsers.length > 0 ? orderSupplierInfo.supplierInfo.listSupplierUsers : [],
              avatar: this.supplierObj[item].icon,
              phone: this.supplierObj[item].phone,
              note: orderSupplierInfo.note,
              estimatedDeliveryDate: orderSupplierInfo.estimatedDeliveryDate,
              deliveryDateCustomer: orderSupplierInfo.estimatedDeliveryDateForCustomer,
              length: orderSupplierInfo.length,
              width: orderSupplierInfo.width,
              weight: orderSupplierInfo.weight,
              height: orderSupplierInfo.height,
              supplierAddress: orderSupplierInfo.supplierAddress,
              listAddress: orderSupplierInfo.supplierInfo.supplierAddress,
              typeName: this.supplierObj[item].typeName,
              supBanks: orderSupplierInfo.supplierInfo.supplierBanks,
              totalPrice: orderSupplierInfo.totalPrice,
              totalQty: orderSupplierInfo.totalQty
            },
            supStatus: orderSupplierInfo.statusCode,
            // supStatusName: this.allOrderSupplierStatus[orderSupplierInfo.statusCode],
            orderDetailSuppliers: groupOrderDetailSup[orderSupplierInfo.supId],
            children: group[orderSupplierInfo.supId]
          };
        } else if (isEmpty(orderSupplierInfo) && this.supplierObj[item]) {
          return {
            id: item,
            supInfo: {
              name: this.supplierObj[item].name,
              listAddress: [],
              supBanks: []
            },
            children: group[item]
          };
        }
        return {};
      });

      //delete all empty object in array
      return t.filter(value => Object.keys(value).length !== 0);
    },

    // activities
    activities() {
      const orderSupplierHistories = this.orderSupplierStatusHistories || [];
      const listActivities =
        orderSupplierHistories.map((item, index) => {
          return {
            content: this.getNameStatus(item.statusCode),
            statusCode: item.statusCode,
            timestamp: this.formatDateTime(item.createdAt),
            noteAdmin: item.noteAdmin,
            titleNoteAdmin: item.titleNoteAdmin,
            noteSupplier: item.noteSupplier,
            titleNoteSupplier: item.titleNoteSupplier,
            color: index === orderSupplierHistories.length - 1 ? '#0bbd87' : '',
            size: index === orderSupplierHistories.length - 1 ? 'large' : 'normal'
          };
        }) || [];
      return listActivities.sort((a, b) => (parseInt(a.statusCode) < parseInt(b.statusCode) ? 1 : parseInt(b.statusCode) < parseInt(a.statusCode) ? -1 : 0)) || [];
    },

    customerEiCash() {
      return this.orderData.customer?.eiCash;
    }
  },

  async created() {
    await this.getOrderById({ id: this.$route.params.id }).then(async () => {
      this.cloneOrderData = cloneDeep(this.orderData);

      this.formInfo = {
        name: this.orderData.name,
        phone: this.orderData.phone,
        address: this.orderData.address,
        province: this.orderData.province,
        district: this.orderData.district,
        ward: this.orderData.ward,
        saleId: this.orderData.saleId ? this.orderData.saleId.toString() : '',
        bdId: this.orderData.bdId ? this.orderData.bdId.toString() : '',
        customerAddressId: this.orderData.customerAddressId,
        personInChargesId: this.orderData.personInChargesId,
        note: this.orderData.note
      };
      this.formInfoDefault = {
        name: this.orderData.name,
        phone: this.orderData.phone,
        address: this.orderData.address,
        province: this.orderData.province,
        district: this.orderData.district,
        ward: this.orderData.ward,
        saleId: this.orderData.saleId ? this.orderData.saleId.toString() : '',
        bdId: this.orderData.bdId ? this.orderData.bdId.toString() : '',
        customerAddressId: this.orderData.customerAddressId,
        personInChargesId: this.orderData.personInChargesId,
        note: this.orderData.note
      };

      const bdInChargeIdList = this.cloneOrderData.bdId;
      //const salesInChargeIdList = this.cloneOrderData.saleId;

      const convertBDListToArray =
        (bdInChargeIdList &&
          bdInChargeIdList
            .split('|')
            .map(Number)
            .filter(id => id > 0)) ||
        [];

      // const convertSalesListToArray =
      // salesInChargeIdList && salesInChargeIdList.split('|').map(Number).filter(id => id > 0) || [];

      this.formOrderDetail = {
        customerName: this.orderData.customer?.customerName,
        saleId: this.orderData.saleId ? Number(this.orderData.saleId) : '',
        bdId: convertBDListToArray,
        customerAddressId: this.orderData.customerAddressId,
        personInChargesId: this.orderData.personInChargesId,
        note: this.orderData.note,
        reasonForCancellation: this.orderData.reasonForCancellation
      };

      this.orderTags = this.orderData.tags ? JSON.parse(this.orderData.tags) : [];

      this.oldOrderData = this.orderData;
      this.selectedDiscount = this.orderData.couponId ? 1 : 0;
      this.userOptions = this.orderData.listUser.map(item => {
        return {
          value: item.id,
          label: item.username
        };
      });
      this.personInChargesOptions = this.orderData.listPersonInCharges.map(item => {
        return {
          value: item.id,
          label: item.name
        };
      });

      this.customerAddressOptions =
        this.orderData.customer && this.orderData.customer.dataAddress
          ? this.orderData.customer.dataAddress.map(item => {
              return {
                value: item.id,
                label: this.getFullAddress(item.address, item.district, item.ward)
              };
            })
          : [];

      this.defaultStatus = this.orderStatus;
      this.setPrice = {
        totalPrice: this.totalPrice,
        vat: this.vat,
        shippingFee: this.shippingFee,
        totalSalePrice: this.totalSalePrice
      };
      this.formWaitingConfirm.deliveryDate = this.orderData.estimatedDeliveryDate;
      if (this.cloneOrderData.voucherId || this.cloneOrderData.couponId) {
        if (this.cloneOrderData.voucherId) {
          this.discountInfo = this.allVouchers[this.cloneOrderData.voucherId];
        } else {
          this.discountInfo = this.allCoupons[this.cloneOrderData.couponId];
        }
      }
      this.cloneOrderData.discountAmount = this.getDiscountPrice(this.orderData.voucherId);
      this.selectedEiCash = this.orderData.eiCash > 0;
      this.oldSelectedEiCash = this.selectedEiCash;
      this.isLoading = true;
      const listPromise = [
        this.getByOrderId({ id: this.$route.params.id }),
        this.getOrderDetailSupplierByOrderId({ id: this.$route.params.id }),
        this.getAllStatus(),
        this.getAllCommons({ name: '', comCode: ORDER_STATUS_CODE }),
        this.getAllCommons({ name: '', comCode: ORDER_SUP_STATUS_CODE, numberList: 2 }),
        this.getAllVouchers(this.formSearchVoucher),
        this.getAllCoupons(this.formSearchCoupon),
        this.getAllDocumentTypes(),
        this.getAllRoles({}),
        this.getAllUsers({}),
        this.getAllSupplierUsers({}),
        this.getAllUnits({ name: '' }),
        this.getAllMailTemplates({ comCode: TYPE_TEMPLATE_MAIL_CODE.ORDR_TMPLT_ML }),
        this.getAllBasicInfos()
      ];
      await Promise.all(listPromise).then(async () => {
        this.supplierInfoArr = [];

        if (this.allSupplierId && this.allSupplierId.length > 0) {
          await this.getAllSupplier({ supplierIdArray: this.allSupplierId });
        }

        this.isLoading = false;
      });
    });
  },

  methods: {
    ...mapActions('orderDetail', [
      'getOrderById',
      'getByOrderId',
      'deleteOrderDetail',
      'changeStatus',
      'saveEditOrderDetail',
      'addOrderDetailLocal',
      'saveAddOrderDetails',
      'uploadFile',
      'deleteFile',
      'sendMail',
      'previewMail',
      'exportPO',
      'exportDeliveryRecord',
      'exportSupplierPO',
      'saveEditOrders',
      'updateOrderStatusSupplier',
      'getOrderDetailSupplierByOrderId',
      'sendMailToSupplier',
      'sendMailConfirmToSupplier',
      'updateOrderTags'
    ]),

    ...mapActions('tag', ['getAllTagsSelect']),

    ...mapActions('order', ['getAllOrders']),

    ...mapActions('supplier', ['getAllSupplier']),

    ...mapActions('product', ['getAllProducts']),

    ...mapActions('global', ['setGlobalReady']),

    ...mapActions('category', ['getAllCategories']),

    ...mapActions('voucher', ['getAllVouchers']),

    ...mapActions('coupon', ['getAllCoupons']),

    ...mapActions('orderStatus', ['getAllStatus']),

    ...mapActions('documentType', ['getAllDocumentTypes']),

    ...mapActions('unit', ['getAllUnits']),

    ...mapActions('role', ['getAllRoles']),

    ...mapActions('user', ['getAllUsers']),

    ...mapActions('supplierUser', ['getAllSupplierUsers']),

    ...mapActions('mailTemplate', ['getAllMailTemplates']),

    ...mapActions('common', ['getAllCommons']),

    ...mapActions('supplierAddress', ['getAllSupplierAddress']),

    ...mapActions('orderSupplierStatusHistory', ['getBySupplierId']),

    ...mapActions('basicInfo', ['getAllBasicInfos']),

    getListUserAllowSendMail() {
      this.users.forEach(obj => {
        const value = obj['roleId'];
        const setting = JSON.parse(obj['setting']) || {};
        if (setting.receiveEmail && setting.receiveEmail.length > 0) {
          const findStatusCode = setting.receiveEmail.find(element => element.actionCode == this.orderStatus && Number(element.isUse) == 1);
          if (findStatusCode) {
            this.listSelectUserMail.push([this.getRoleName(value), obj.email]);
          }
        }
      });
    },

    // getListSupplierUserAllowSendMail() {
    //   this.supplierUsers.forEach((obj) => {
    //     const value = obj['roleId'];
    //     const supplierId = obj['supplierId']
    //     const setting = JSON.parse(obj['setting']) || {};
    //     const checkSupplierId = this.checkSupplierId(supplierId);
    //     if (checkSupplierId && setting.receiveEmail && setting.receiveEmail.length > 0) {
    //       const findStatusCode = setting.receiveEmail.find((element) => element.actionCode == this.orderStatus && Number(element.isUse) == 1);
    //       if (findStatusCode) {
    //         this.listSelectSupplierUserMail.push([this.getRoleName(value), obj.email])
    //       }
    //     }
    //   });
    // },

    checkSupplierId(id) {
      const check = this.allSupplierId.find(item => item == id);
      if (check) return true;
      return false;
    },

    getRoleName(id) {
      return (this.rolesMap[id] && this.rolesMap[id].name) || '';
    },

    changeOrderTags(value) {
      this.updateOrderTags({ tags: value, orderId: this.$route.params.id });
    },

    // getFullAddress
    getFullAddress(address, districtId, wardId) {
      // const { address, district, ward } = this.formInfo;
      if (address == '' || districtId == '' || wardId == '') return '';
      else {
        const districtList = vnLocal.getWardsByDistrictCode(districtId);
        const provinceName = districtList?.[0]?.provinceName || '';
        const districtName = districtList?.[0]?.districtName || '';
        const wardName = (wardId && districtList?.find(d => d.code === wardId)?.name) || '';
        return address + ', ' + wardName + ', ' + districtName + ', ' + provinceName;
      }
    },

    //format datetime
    formatDateTime(strDate) {
      const date = new Date(strDate);
      const day = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate();
      const month = date.getMonth().toString().length === 1 ? '0' + parseInt(date.getMonth() + 1) : date.getMonth();
      const minute = date.getMinutes().toString().length === 1 ? '0' + date.getMinutes() : date.getMinutes();
      const second = date.getSeconds().toString().length === 1 ? '0' + date.getSeconds() : date.getSeconds();
      return day + '/' + month + '/' + date.getFullYear() + ' ' + date.getHours() + ':' + minute + ':' + second;
    },

    getNameStatus(id) {
      this.statusColor = ORDER_SUPPLIER_COLOR[parseInt(id)];
      return ORDER_SUPPLIER_STATUS[Number(id)];
    },

    // validate phone method
    validatePhone(rule, value, callback) {
      var validate = /(([\\+84|84|0]+(2|3|5|7|8|9|1[2|6|8|9]))+([0-9]{8,11})\b)/g;

      if (validate.test(value) == false) {
        callback(this.$i18n.t('common.notify.message.error.phone.incorrect'));
      } else {
        callback();
      }
    },

    forceFileDownload(response, url1) {
      const headers = response.headers;
      const fileName = url1.substring(url1.lastIndexOf('/') + 1);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data], { type: headers['content-type'] }));
      link.download = fileName;
      link.click();
    },

    downloadFile(url) {
      axios({
        method: 'get',
        url,
        responseType: 'blob',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, Authorization, X- Request-With'
        }
      })
        .then(response => {
          this.forceFileDownload(response, url);
        })
        .catch(() => console.log('error occured'));
    },

    getProductImage(listImage) {
      const images = listImage ? listImage.split('|') : [];
      return images[0];
    },

    // format datetime
    formatDate(strDate) {
      const date = new Date(strDate);
      return date.getDate() + ' - ' + date.getMonth() + ' - ' + date.getFullYear();
    },

    // formatDateTime(strDate) {
    //   const date = new Date(strDate);
    //   return date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    // },

    // truncate string
    truncateString(str, num) {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + '...';
    },

    // header style
    headerRow({ rowIndex }) {
      if (rowIndex === 0) {
        return 'headerRow';
      }
    },
    discountHeaderStyle({ rowIndex }) {
      if (rowIndex === 1) {
        //Hide the other two head cells
        return { display: 'none' };
      }
    },
    rowStyle({ rowIndex }) {
      if (this.editRow === rowIndex) {
        return { background: '#ECF5FF' };
      }
    },

    // discount method
    getDiscountCode(type) {
      if (type === 0) {
        const voucher = this.vouchers.find(item => item.id == this.cloneOrderData.voucherId) || {};
        const { discountTypeCode = '' } = voucher;
        if (discountTypeCode == VCHR_DSCNT_TP.PRCNT) return `${voucher?.code} (- ${voucher?.value}%)`;
        if (discountTypeCode == VCHR_DSCNT_TP.CASH)
          return `${voucher?.code} (- ${new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND'
          }).format(voucher?.value)})`;
      } else {
        const coupon = this.coupons.find(item => item.id === this.cloneOrderData.couponId);
        return `${coupon?.code} (- ${coupon?.discount}%)`;
      }
    },

    getDiscountPrice(id, type = true) {
      if (!id) return 0;
      if (type) {
        const voucher = this.vouchers.find(item => item.id === id) || {};
        const { discountTypeCode = '', typeCode = '' } = voucher;
        if (discountTypeCode == VCHR_DSCNT_TP.PRCNT) {
          if (typeCode == VCHR_TP.DSCNT) return (Number(voucher.value) / 100) * Number(this.setPrice.totalPrice);
          if (typeCode == VCHR_TP.SHP_CD) return (Number(voucher.value) / 100) * Number(this.setPrice.shippingFee);
        }
        if (discountTypeCode == VCHR_DSCNT_TP.CASH) {
          if (typeCode == VCHR_TP.DSCNT) return voucher.value > this.setPrice.totalPrice ? this.setPrice.totalPrice : voucher.value;
          if (typeCode == VCHR_TP.SHP_CD) return voucher.value > this.setPrice.shippingFee ? this.setPrice.shippingFee : voucher.value;
        }
        return 'No Data';
      } else {
        const coupon = this.coupons.find(item => item.id === id);
        const price = coupon !== 0 && coupon.discount ? (coupon.discount * this.totalPrice) / 100 : 0;
        return price;
      }
    },

    getDiscountAmount(id) {
      if (this.selectedDiscount === 0) {
        const voucher = this.vouchers.find(item => item.id == id);
        return voucher.discount;
      } else {
        const coupon = this.coupons.find(item => item.id == id);
        return coupon.discount;
      }
    },

    // edit order detail
    enableEditing(scope) {
      const id = scope.$index;
      this.editRow = this.editRow !== id ? id : null;
      this.formOrderDetailOld = cloneDeep(scope.row);
      this.selectedOrderDetail = cloneDeep(scope.row);
      this.price = scope.row.price;
      this.supplierPrice = scope.row.supplierPrice;
    },

    onCloseEditing(index) {
      this.allOrderDetails[index] = cloneDeep(this.formOrderDetailOld);
      this.editRow = null;
      this.price = '';
      this.supplierPrice = '';
    },

    // edit order info (name, phone, address,...)
    enableEditOrder(value) {
      this.$refs['formInfo'].validate(valid => {
        if (valid) {
          if (!this.isEditting) {
            this.editInfo = value;
            this.isEditting = true;
          } else {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('src.modules.order.index.message.save.editting'),
              type: 'error'
            });
          }
        }
      });
    },

    closeEditOrder() {
      this.$refs['formInfo'].validate(valid => {
        if (valid) {
          this.editInfo = null;
          this.isEditting = false;
          this.$notify({
            title: this.$i18n.t('common.notify.title.success'),
            message: this.$i18n.t('common.notify.message.success.edit', {
              name: 'info'
            }),
            type: 'success'
          });
        }
      });
    },

    // change status order
    async handleChangeStatus(value) {
      await this.changeStatus(value).then(() => (this.defaultStatus = this.orderStatus));
    },

    onShowSendMailDialog() {
      this.isShowSendMailDialog = true;
      this.getListUserAllowSendMail();
      // this.getListSupplierUserAllowSendMail();
    },

    async printDownload() {
      this.orderData.discountAmount = this.cloneOrderData.discountAmount;
      this.orderData.fullAddress = this.fullAddress;
      this.orderData.customerName = this.orderData.customer?.customerName;
      const listOrderDetails = this.orderDetails.map(orderDetail => ({
        ...orderDetail,
        productUnit:
          orderDetail.products && orderDetail.products.unitCode && this.unitObj[orderDetail.products.unitCode] && typeof this.unitObj[orderDetail.products.unitCode] !== 'undefined'
            ? this.unitObj[orderDetail.products.unitCode]
            : 'Cái'
      }));
      await this.exportPO({
        orderDetails: listOrderDetails,
        orderData: this.orderData
      }).then(res => {
        this.pdfPOContent = res;
        this.$refs.DownloadComp.generatePdf();
      });
    },

    async printDownloadDeliveryRecord() {
      this.orderData.discountAmount = this.cloneOrderData.discountAmount;
      this.orderData.fullAddress = this.fullAddress;
      this.orderData.customerName = this.orderData.customer?.customerName;
      const listOrderDetails = this.orderDetails.map(orderDetail => ({
        ...orderDetail,
        productUnit:
          orderDetail.products && orderDetail.products.unitCode && this.unitObj[orderDetail.products.unitCode] && typeof this.unitObj[orderDetail.products.unitCode] !== 'undefined'
            ? this.unitObj[orderDetail.products.unitCode]
            : 'Cái'
      }));
      let saleName = '';
      if (this.orderData.saleId) {
        const saleIndex = this.orderData.listUser.findIndex(user => user.id === parseInt(this.orderData.saleId));
        saleName =
          this.orderData.listUser[saleIndex].firstName && this.orderData.listUser[saleIndex].lastName
            ? this.orderData.listUser[saleIndex].firstName + ' ' + this.orderData.listUser[saleIndex].lastName
            : this.orderData.listUser[saleIndex].username;
      }

      this.orderData.saleName = saleName;
      await this.exportDeliveryRecord({
        orderDetails: listOrderDetails,
        orderData: this.orderData,
        basicInfo: this.basicInfo
      }).then(res => {
        this.pdfDeliveryRecordContent = res;
        this.$refs.DownloadDeliveryRecord.generatePdf();
      });
    },

    async downloadSupplierPO(data) {
      console.log('supplier po :', this.orderData);
      this.selectedSupplierCode = data.row.supInfo.code;
      this.orderData.fullAddress = this.fullAddress;
      let supplierData = data.row;
      supplierData.orderDetailSuppliers = data.row.orderDetailSuppliers.map(orderDetail => ({
        ...orderDetail,
        productUnit:
          orderDetail.products && orderDetail.products.unitCode && this.unitObj[orderDetail.products.unitCode] && typeof this.unitObj[orderDetail.products.unitCode] !== 'undefined'
            ? this.unitObj[orderDetail.products.unitCode]
            : 'Cái'
      }));
      await this.exportSupplierPO({
        supplierInfo: supplierData,
        orderData: this.orderData
      }).then(res => {
        this.selectedSupplierCode = '';
        this.pdfSupplierPOContent = res;
        this.$refs.DownloadSupplierPO.generatePdf();
      });
    },

    async onSaveOrder() {
      if (this.defaultStatus == this.STATUS_CODE_DA_HUY && (!this.formOrderDetail.reasonForCancellation || this.formOrderDetail.reasonForCancellation == '')) {
        this.$notify({
          title: this.$i18n.t('common.notify.title.error'),
          message: this.$i18n.t('src.modules.order.index.rule.reasonForCancellation'),
          type: 'error'
        });
        return null;
      }
      if (this.cloneOrderData.statusCode == this.STATUS_CODE_DA_HUY) {
        this.$notify({
          title: this.$i18n.t('common.notify.title.error'),
          message: this.$i18n.t('src.modules.order.index.rule.isOrderCancel'),
          type: 'error'
        });
        return null;
      }
      this.$confirm(
        this.$i18n.t('src.modules.order.index.update.comfirm', {
          id: this.$route.params.id
        })
      )
        .then(async () => {
          const newData = {
            ...this.cloneOrderData,
            ...this.formOrderDetail,
            name: this.formInfo.name,
            phone: this.formInfo.phone,
            province: this.formInfo.province,
            district: this.formInfo.district,
            ward: this.formInfo.ward,
            address: this.formInfo.address,
            discountType: this.selectedDiscount,
            vat: this.getVATPrice,
            //discountAmount: salePrice,
            shippingFee: this.setPrice.shippingFee,
            status: this.defaultStatus,
            eiCash: 0
          };
          if (this.selectedDiscount === 0) {
            newData.couponId = null;
            if (this.cloneOrderData.voucherId !== '') {
              newData.voucherId = this.cloneOrderData.voucherId;
              newData.discountAmount = this.getDiscountPrice(this.cloneOrderData.voucherId);
            } else {
              newData.voucherId = null;
              newData.discountAmount = 0;
            }
          }
          if (this.selectedDiscount === 1) {
            newData.voucherId = null;
            if (this.cloneOrderData.couponId !== '') {
              newData.couponId = this.cloneOrderData.couponId;
              newData.discountAmount = this.getDiscountPrice(this.cloneOrderData.couponId, false);
            } else {
              newData.couponId = null;
              newData.discountAmount = 0;
            }
          }
          if (this.selectedEiCash && this.selectedEiCash !== this.oldSelectedEiCash) {
            newData.eiCash = this.totalSalePrice >= this.cloneOrderData.customer.eiCash ? this.cloneOrderData.customer.eiCash : this.totalSalePrice;
          }
          if (!this.selectedEiCash && this.selectedEiCash !== this.oldSelectedEiCash) {
            newData.eiCash = 0 - this.cloneOrderData.eiCash;
          }

          const result = await this.saveEditOrders({
            oldData: this.oldOrderData,
            newData: newData
          });

          if (result) {
            this.formNewsOld = {};
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('common.notify.message.success.edit', {
                name: 'order'
              }),
              type: 'success'
            });
            this.cloneOrderData = cloneDeep(this.orderData);
            this.cloneOrderData.statusCode = this.defaultStatus;
            if (this.selectedEiCash !== this.oldSelectedEiCash) this.oldSelectedEiCash = this.selectedEiCash;
            this.getOrderById({ id: this.$route.params.id });
          } else {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.error.edit', {
                name: 'order',
                message: result.message
              }),
              type: 'error'
            });
          }
          this.newsDialogVisible = false;
        })
        .catch(() => {
          if (this.selectedEiCash !== this.oldSelectedEiCash) this.selectedEiCash = !this.selectedEiCash;
        });
    },

    async onSaveEditOrderDetail(scope) {
      this.$confirm(this.$i18n.t('common.notify.edit.comfirm', { name: 'order detail' })).then(async () => {
        const data = this.selectedOrderDetail;
        const { quantity, price } = data;
        if (quantity === 0) {
          this.$notify({
            title: this.$i18n.t('common.notify.title.error'),
            message: this.$i18n.t('common.notify.message.error.quantity"'),
            type: 'error'
          });
        } else if (typeof quantity != 'number') {
          this.$notify({
            title: this.$i18n.t('common.notify.title.error'),
            message: this.$i18n.t('common.notify.message.error.quantity.number'),
            type: 'error'
          });
        } else {
          data.price = parseFloat(price);
          data.totalPrice = quantity * data.price;
          let postData = {
            checkoutId: this.$route.params.id,
            customerId: this.cloneOrderData.customerId,
            oldData: scope.row,
            newData: data
          };
          if (this.selectedDiscount === 0 && this.cloneOrderData.voucherId) {
            postData.discountAmount = this.getDiscountPrice(this.cloneOrderData.voucherId);
          }
          if (this.selectedDiscount === 1 && this.cloneOrderData.couponId) {
            postData.discountAmount = this.getDiscountPrice(this.cloneOrderData.couponId, false);
          }
          if (this.selectedEiCash && this.cloneOrderData.eiCash > 0) {
            postData.eiCash = this.cloneOrderData.eiCash;
          }
          const result = await this.saveEditOrderDetail(postData);
          if (result) {
            this.formOrderDetailOld = {};
            this.editRow = null;
            await this.getAllSupplier({ supplierIdArray: this.allSupplierId }).then(() => {
              this.$notify({
                title: this.$i18n.t('common.notify.title.success'),
                message: this.$i18n.t('common.notify.message.success.edit', {
                  name: 'order detail'
                }),
                type: 'success'
              });
              this.setPrice = {
                totalPrice: this.totalPrice,
                vat: this.vat,
                shippingFee: this.shippingFee,
                totalSalePrice: this.totalSalePrice
              };
              console.log('order detail update :', this.orderData);
              this.cloneOrderData = cloneDeep(this.orderData);
              //if (this.totalSalePrice <= 2000000) this.selectedEiCash = false;
            });
          } else {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.error.edit', {
                name: 'order detail',
                message: result.message
              }),
              type: 'error'
            });
          }
        }
      });
    },

    // Add One Product to Order Detail
    async onClickAddRow(index) {
      const addData = index.row;
      if (this.orderDetailAddLocal.some(item => item.productId === addData.id) || this.orderDetails.some(item => item.productId === addData.id)) {
        this.$notify({
          title: this.$i18n.t('common.notify.title.error'),
          message: this.$i18n.t('common.notify.message.error.add', {
            message: `Product ${addData.id} already exists`
          }),
          type: 'error'
        });
      } else {
        const newOrderDetail = {
          quantity: 1,
          price: addData.price,
          totalPrice: addData.price,
          supId: addData.supplierId,
          status: this.orderData.status,
          dateDelivery: this.orderData.deliveryDate,
          checkoutId: this.orderData.id,
          productName: addData.name,
          productModel: addData.mng,
          productImg: addData.images,
          productId: addData.id,
          productSlug: addData.slug,
          products: {
            unitCode: addData.unitCode,
            eic: addData.eic
          },
          suppliers: {
            id: addData.supplier.id,
            name: addData.supplier.name
          },
          variantValue: null,
          variants: []
        };
        await this.addOrderDetailLocal([newOrderDetail]);
        this.$notify({
          title: this.$i18n.t('common.notify.title.success'),
          message: this.$i18n.t('common.notify.message.success.add', {
            item: `product ${addData.id}`
          }),
          type: 'success'
        });
      }
    },

    // Add Product Selected To Order Detail
    async handleAddMultiRow() {
      let existProduct = [];
      let newOrderDetails = [];
      this.multipleSelectProduct.map(addData => {
        if (this.orderDetailAddLocal.some(item => item.productId === addData.id) || this.orderDetails.some(item => item.productId === addData.id)) {
          existProduct.push(addData.id);
        } else
          newOrderDetails.push({
            quantity: 1,
            price: addData.price,
            totalPrice: addData.price,
            supId: addData.supplierId,
            status: this.orderData.status,
            dateDelivery: this.orderData.deliveryDate,
            checkoutId: this.orderData.id,
            productName: addData.name,
            productModel: addData.mng,
            productImg: addData.images,
            productId: addData.id,
            productSlug: addData.slug,
            taxRate: addData.taxRate,
            products: {
              unitId: addData.unitId
            }
          });
      });
      if (existProduct.length > 0) {
        this.$notify({
          title: this.$i18n.t('common.notify.title.error'),
          message: this.$i18n.t('common.notify.message.error.add', {
            message: `Product have id ${existProduct.join(',')} already exists`
          }),
          type: 'error'
        });
      }
      if (newOrderDetails.length > 0) {
        await this.addOrderDetailLocal(newOrderDetails);
        this.$notify({
          title: this.$i18n.t('common.notify.title.success'),
          message: this.$i18n.t('common.notify.message.success.add', {
            item: 'products'
          }),
          type: 'success'
        });
      }
    },

    async handleSaveOrderDetail() {
      if (this.multipleSelectProduct.length > 0) {
        let existProduct = [];
        let newOrderDetails = [];
        this.multipleSelectProduct.map(addData => {
          if (this.orderDetails.some(item => item.productId === addData.id)) {
            existProduct.push(addData.id);
          } else
            newOrderDetails.push({
              quantity: 1,
              price: addData.salePrice || 0,
              totalPrice: addData.price || 0,
              supplierPrice: addData.supplierPrice || 0,
              supId: addData.supplierId,
              checkoutId: this.$route.params.id,
              productName: addData.name,
              productModel: addData.eic,
              productImg: addData.thumbnail,
              productId: addData.id,
              productSlug: addData.slug,
              isSourcing: addData.isSourcing,
              taxRate: addData.taxRate
            });
        });

        if (existProduct.length > 0) {
          this.$notify({
            title: this.$i18n.t('common.notify.title.error'),
            message: this.$i18n.t('common.notify.message.error.add', {
              message: `Product have id ${existProduct.join(',')} already exists`
            }),
            type: 'error'
          });
        }
        if (newOrderDetails.length > 0) {
          const postData = {
            newOrderDetails: newOrderDetails,
            checkoutId: this.$route.params.id,
            customerId: this.cloneOrderData.customerId
          };
          if (this.selectedDiscount === 0 && this.cloneOrderData.voucherId) {
            postData.discountAmount = this.getDiscountPrice(this.cloneOrderData.voucherId);
          }
          if (this.selectedDiscount === 1 && this.cloneOrderData.couponId) {
            postData.discountAmount = this.getDiscountPrice(this.cloneOrderData.couponId, false);
          }

          if (this.selectedEiCash && this.cloneOrderData.customer.eiCash > 0) {
            postData.eiCash = this.cloneOrderData.customer.eiCash;
          }
          const data = await this.saveAddOrderDetails(postData);
          if (data == true) {
            await this.getAllSupplier({ supplierIdArray: this.allSupplierId });
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('common.notify.message.success.add', {
                item: 'Order Details'
              }),
              type: 'success'
            });
            this.multipleSelectProduct = [];
            this.setPrice = {
              totalPrice: this.totalPrice,
              vat: this.vat,
              shippingFee: this.shippingFee,
              totalSalePrice: this.totalSalePrice
            };
            this.cloneOrderData = cloneDeep(this.orderData);
          } else {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.save.fail', {
                name: 'Order Detail'
              }),
              type: 'error'
            });
          }
        }
      }
    },

    // Remove product from order detail list
    async onClickRemove(index) {
      this.$confirm(
        this.$i18n.t('src.modules.order.detail.remove.comfirm', {
          id: index.row.id ? index.row.id : ''
        })
      )
        .then(async () => {
          const postData = {
            deleteItem: index.row,
            checkoutId: this.$route.params.id,
            customerId: this.orderData.customerId
          };
          if (this.selectedDiscount === 0 && this.cloneOrderData.voucherId) {
            postData.discountAmount = this.getDiscountPrice(this.cloneOrderData.voucherId);
          }
          if (this.selectedDiscount === 1 && this.cloneOrderData.couponId) {
            postData.discountAmount = this.getDiscountPrice(this.cloneOrderData.couponId, false);
          }
          if (this.selectedEiCash && this.cloneOrderData.eiCash > 0) {
            postData.eiCash = this.cloneOrderData.eiCash;
          }
          const result = await this.deleteOrderDetail(postData);
          if (result) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('src.modules.order.detail.remove.success', {
                id: index.row.id ? index.row.id : ''
              }),
              type: 'success'
            });
            this.setPrice = {
              totalPrice: this.totalPrice,
              vat: this.vat,
              shippingFee: this.shippingFee,
              totalSalePrice: this.totalSalePrice
            };
            this.cloneOrderData = cloneDeep(this.orderData);
            //if (this.totalSalePrice <= 2000000) this.selectedEiCash = false;
          } else {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('src.modules.order.detail.remove.error', {
                id: index.row.id ? index.row.id : '',
                message: result.message
              }),
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },

    handleSelectionChange(val) {
      this.multipleSelectProduct = val;
    },

    handleChangeDiscount(value) {
      // this.cloneOrderData.couponId = null;
      // this.cloneOrderData.voucherId = null;
      // this.cloneOrderData.discountAmount = 0;
      if (value === 1) {
        this.discountInfo = this.couponObj[this.cloneOrderData.couponId];
        this.cloneOrderData.discountAmount = this.getDiscountPrice(this.cloneOrderData.couponId, false);
      } else {
        this.discountInfo = this.voucherObj[this.cloneOrderData.voucherId];
        this.cloneOrderData.discountAmount = this.getDiscountPrice(this.cloneOrderData.voucherId);
      }
    },

    handleCloseAddProduct() {
      this.selectProductVisible = false;
    },

    handleCloseLalamoveDialog() {
      this.lalamoveVisible = false;
    },

    resetLocation(name) {
      if (name === 'province') {
        this.formInfo.district = '';
        this.formInfo.ward = '';
        this.$refs['formInfo'].clearValidate(['district', 'ward']);
      }
      if (name === 'district') {
        this.formInfo.ward = '';
      }
    },

    async handleFileUpload(file) {
      const { companyId } = this.user;
      this.loadingUploadLocal = true;
      await this.uploadFile({
        companyId,
        prefix: 'Order',
        orderId: this.$route.params.id,
        typeId: this.selectedDocumentTypes,
        file
      })
        .then(result => {
          if (result.status) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('src.modules.order.detail.upload.success'),
              type: 'success'
            });
          } else {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('src.modules.order.detail.upload.error', {
                message: result.message
              }),
              type: 'error'
            });
          }
          this.loadingUploadLocal = false;
        })
        .catch(error => {
          this.$notify({
            title: this.$i18n.t('common.notify.title.success'),
            message: this.$i18n.t('src.modules.order.detail.upload.error', {
              message: error.message
            }),
            type: 'success'
          });
          this.loadingUploadLocal = false;
        });
    },
    // Remove product from order detail list
    async handleRemoveFile(data) {
      this.$confirm(this.$i18n.t('src.modules.order.detail.upload.remove.comfirm'))
        .then(async () => {
          const result = await this.deleteFile(data);
          if (result) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('src.modules.order.detail.sendMail.succes'),
              type: 'success'
            });
          } else {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('src.modules.order.detail.upload.remove.error', {
                message: result.message
              }),
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },

    clearFormMail() {
      this.formMail = cloneDeep(this.formMailDefault);
      this.$refs['formMail'].resetFields(); //Remove the verification result and reset the field value
      this.$refs['formMail'].clearValidate(['template']);
      this.selectedListUser = [];
      // this.selectedListSupplierUser = [];
      this.listAddedMail = [];
      this.addedMail = [];
    },

    handleCloseSendMailDialog(done) {
      var message = null;
      if (!isEqual(this.formMail, this.formMailDefault) || this.selectedListUser.length > 0 || this.listAddedMail.length > 0) {
        message = this.$i18n.t('common.confirm.close.form.out', { name: 'orders' });
        this.$confirm(message)
          .then(() => {
            this.clearFormMail();
            //this.isShowSendMailDialog = false;
            done();
          })
          .catch(() => {});
      } else {
        this.isShowSendMailDialog = false;
      }
    },
    handleChangeListUserMail(value) {
      this.selectedListUser = value.map(v => v[1]);
    },

    // handleChangeListSupplierUserMail(value) {
    //   this.selectedListSupplierUser = value.map((v) => v[1]);
    // },

    async handleSendMail() {
      this.$refs['formMail'].validate(async valid => {
        if (valid) {
          const listEmail = this.allMailSelect;
          const listOrderDetails = this.orderDetails.map(orderDetail => ({
            ...orderDetail,
            productUnit:
              orderDetail.products && orderDetail.products.unitCode && this.unitObj[orderDetail.products.unitCode] && typeof this.unitObj[orderDetail.products.unitCode] !== 'undefined'
                ? this.unitObj[orderDetail.products.unitCode]
                : 'Cái'
          }));
          this.orderData.orderDetails = listOrderDetails;
          this.orderData.fullAddress = this.fullAddress;
          this.isLoadingSendMail = true;
          await this.sendMail({
            listEmail,
            orderData: this.orderData,
            mailTemplateCode: this.formMail.template,
            note: this.formMail.note,
            title: this.formMail.title
          }).then(result => {
            this.isLoadingSendMail = false;
            if (result) {
              this.$notify({
                title: this.$i18n.t('common.notify.title.success'),
                message: this.$i18n.t('src.modules.order.detail.sendMail.success'),
                type: 'success'
              });
            } else {
              this.$notify({
                title: this.$i18n.t('common.notify.title.error'),
                message: this.$i18n.t('src.modules.order.detail.sendMail.error', {
                  message: result.message
                }),
                type: 'error'
              });
            }
          });
        }
      });
    },

    async handleSendMailDebtRequest(data) {
      try {
        const orderDataSendMail = cloneDeep(this.orderData);
        const mailTemplateCode = 'SupplierDebtRequest';
        const listEmail = [];
        this.users.forEach(obj => {
          const setting = JSON.parse(obj['setting']) || {};
          if (setting.receiveEmail && setting.receiveEmail.length > 0) {
            const findStatusCode = setting.receiveEmail.find(element => Number(element.actionCode) == 8 && Number(element.isUse) == 1);
            if (findStatusCode) {
              listEmail.push(obj.email);
            }
          }
        });
        orderDataSendMail.orderDetails = data.orderDetailSuppliers;
        orderDataSendMail.supplierInfo = data.supInfo;

        this.orderData.fullAddress = this.fullAddress;

        await this.sendMailConfirmToSupplier({
          listEmail: listEmail,
          orderData: orderDataSendMail,
          supplierInfo: data,
          mailTemplateCode,
          title: `Nhà bán ${orderDataSendMail.supplierInfo.name} đã yêu cầu công nợ đơn hàng #${orderDataSendMail.code}`
        });
      } catch (error) {
        console.log('🚀 ~ file: supplier.orderDetail.vue ~ line 1499 ~ handleSendMailConfirmToAdmin ~ error', error);
      }
    },

    getTemplateCode(supCode) {
      let mailTemplateCode = '';
      switch (supCode) {
        case SUPPLIER_MAIL_TEMPLATE_TYPE.SUPPLIER_WAITING_CONFIRM:
          mailTemplateCode = SUPPLIER_MAIL_TEMPLATE_CODE.SUPPLIER_WAITING_CONFIRM;
          break;
        case SUPPLIER_MAIL_TEMPLATE_TYPE.SUPPLIER_CONFIRMED:
          mailTemplateCode = SUPPLIER_MAIL_TEMPLATE_CODE.SUPPLIER_CONFIRMED;
          break;
        case SUPPLIER_MAIL_TEMPLATE_TYPE.SUPPLIER_DELIEVERING:
          mailTemplateCode = SUPPLIER_MAIL_TEMPLATE_CODE.SUPPLIER_DELIEVERING;
          break;
        case SUPPLIER_MAIL_TEMPLATE_TYPE.SUPPLIER_RECEIVED:
          mailTemplateCode = SUPPLIER_MAIL_TEMPLATE_CODE.SUPPLIER_RECEIVED;
          break;
        case SUPPLIER_MAIL_TEMPLATE_TYPE.SUPPLIER_CANCEL_ORDER:
          mailTemplateCode = SUPPLIER_MAIL_TEMPLATE_CODE.SUPPLIER_CANCEL_ORDER;
          break;
      }
      return mailTemplateCode;
    },

    async handleSendMailToSupplier(data) {
      const supStatus = data.row.supStatus;
      let listSupplierUser = data.row.supInfo.listUser.map(user => user.email);
      listSupplierUser.push(data.row.supInfo.email);
      // console.log(listSupplierUser);
      // /const listSupplierUser = ['cobeautizon@gmail.com'];
      // const listSupplierUser = ['hieuquang2212@gmail.com'];
      const listOrderDetails = data.row.orderDetailSuppliers.map(orderDetail => ({
        ...orderDetail,
        productUnit:
          orderDetail.products && orderDetail.products.unitCode && this.unitObj[orderDetail.products.unitCode] && typeof this.unitObj[orderDetail.products.unitCode] !== 'undefined'
            ? this.unitObj[orderDetail.products.unitCode]
            : 'Cái'
      }));
      this.orderData.orderDetails = listOrderDetails;
      console.log('order data :', this.orderData.orderDetails);
      this.orderData.supplierInfo = data.row.supInfo;
      this.orderData.fullAddress = this.fullAddress;
      const supplierInfo = data.row;
      supplierInfo.orderDetailSuppliers = listOrderDetails;
      // await this.exportSupplierPO({
      //   supplierInfo: data.row,
      //   orderData: this.orderData
      let mailTemplateCode = '';
      if (!supStatus || typeof supStatus === 'undefined') mailTemplateCode = 'SupplierWaitingConfirm';
      else mailTemplateCode = this.getTemplateCode(supStatus);
      await this.sendMailConfirmToSupplier({
        listEmail: listSupplierUser,
        orderData: this.orderData,
        supplierInfo,
        mailTemplateCode,
        title: `EI INDUSTRIAL - Thông báo về đơn hàng #${this.orderData.code}`,
        note: ''
      }).then(result => {
        if (result) {
          this.$notify({
            title: this.$i18n.t('common.notify.title.success'),
            message: this.$i18n.t('src.modules.order.detail.sendMail.success'),
            type: 'success'
          });
        } else {
          this.$notify({
            title: this.$i18n.t('common.notify.title.error'),
            message: this.$i18n.t('src.modules.order.detail.sendMail.error', {
              message: result.message
            }),
            type: 'error'
          });
        }
      });
    },

    onShowSendMailConfirmDialog(data) {
      this.$confirm('Are you want to preview mail before send to supplier ?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      })
        .then(() => {
          this.isMailSupplier = true;
          this.mailSupplierContent = data;
          this.handlePreviewSupplierMail(data);
        })
        .catch(() => {
          this.handleSendMailToSupplier(data);
        });
    },

    async handlePreviewMail() {
      this.$refs['formMail'].validate(async valid => {
        if (valid) {
          const listEmail = this.allMailSelect;
          this.orderData.orderDetails = this.orderDetails;
          this.orderData.fullAddress = this.fullAddress;
          const { message } = await this.previewMail({
            listEmail,
            orderData: this.orderData,
            mailTemplateCode: this.formMail.template,
            note: this.formMail.note,
            title: this.formMail.title
          });

          this.mailPreviewContent = message;
          this.mailPreviewShow = true;
        }
      });
    },

    async handlePreviewSupplierMail(data) {
      let mailData = this.orderData;
      const listOrderDetails = data.row.orderDetailSuppliers.map(orderDetail => ({
        ...orderDetail,
        productUnit:
          orderDetail.products && orderDetail.products.unitCode && this.unitObj[orderDetail.products.unitCode] && typeof this.unitObj[orderDetail.products.unitCode] !== 'undefined'
            ? this.unitObj[orderDetail.products.unitCode]
            : 'Cái'
      }));
      mailData.orderDetails = listOrderDetails;
      mailData.fullAddress = this.fullAddress;
      mailData.supplierInfo = data.row.supInfo;
      // this.orderData.orderDetails = data.row.orderDetailSuppliers;
      // this.orderData.fullAddress = this.fullAddress;
      // this.orderData.supplierInfo = data.row.supInfo;
      const supStatus = data.row.supStatus;
      const { message } = await this.previewMail({
        listEmail: ['hieuquang2212@gmail.com'],
        orderData: mailData,
        mailTemplateCode: this.getTemplateCode(supStatus),
        note: '',
        title: ''
      });
      this.mailPreviewContent = message;
      this.mailPreviewShow = true;
    },

    handleAddMail() {
      const regex = /^(([^<>()[\]\\.,;:\s@|"]+(\.[^<>()[\]\\.,;:\s@|"]+)*)|(|".+|"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      // check email is empty or not
      if (this.addedMail === '') {
        this.$message({
          showClose: true,
          message: this.$i18n.t('src.modules.order.detail.sendMail.validate.mail.error.empty'),
          type: 'error'
        });

        // check mail input is correct format or not
      } else if (!regex.test(this.addedMail)) {
        this.$message({
          showClose: true,
          message: this.$i18n.t('src.modules.order.detail.sendMail.validate.mail.error.format'),
          type: 'error'
        });
        this.addedMail = '';

        // check email already exists or not
      } else if (this.allMailSelect.some(obj => (Array.isArray(obj) ? obj[1] === this.addedMail : obj === this.addedMail))) {
        this.$message({
          showClose: true,
          message: this.$i18n.t('src.modules.order.detail.sendMail.validate.mail.error.exist'),
          type: 'error'
        });
        this.addedMail = '';
      } else {
        this.listAddedMail.push(this.addedMail);
        this.$message({
          message: this.$i18n.t('src.modules.order.detail.sendMail.addMail.success'),
          type: 'success'
        });
        this.addedMail = '';
      }
    },

    // enter to add text input
    handleEnterKeyUp() {
      this.$refs.addMail.$el.click();
    },

    handleRemoveMail(value) {
      this.listAddedMail.splice(this.listAddedMail.indexOf(value), 1);
    },

    enableEditShippingFee() {
      this.isEnableEditShippingFee = true;
    },

    closeEditShippingFee() {
      this.isEnableEditShippingFee = false;
    },

    handleEnterShippingFeeKeyUp() {
      this.$refs.btnSaveShippingFee.click();
    },

    onShowSupplierAddressDialog(index) {
      this.isShowSupplierAddressPopup = true;
      this.selectedSupplier = index.row.supInfo;
    },
    arraySpanMethod({ row, columnIndex }) {
      if (Object.keys(row).length <= 6) {
        if (columnIndex === 0) {
          return [1, 8];
        } else if (columnIndex === 1) {
          return [0, 0];
        }
      }
    },
    async handleChangeSupplierStatus(data, $event) {
      this.$confirm('Are you sure to update this order detail ? ')
        .then(async () => {
          this.selectedOrderSupplierData = data.row;
          this.selectedOrderSupplierData.supStatus = $event;
          this.isShowOrderSupplierNote = true;
          // const result = await this.updateOrderStatusSupplier({
          //   id: data.row.orderSupId,
          //   supStatus: $event,
          //   orderStatus: data.row.supStatus,
          //   orderId: this.$route.params.id
          // });
          // if (result) {
          //   this.$notify({
          //     title: this.$i18n.t('common.notify.title.success'),
          //     message: 'Update status successfully',
          //     type: 'success'
          //   });
          // } else {
          //   this.$notify({
          //     title: this.$i18n.t('common.notify.title.error'),
          //     message: this.$i18n.t('src.modules.order.detail.sendMail.error', {
          //       message: result.message
          //     }),
          //     type: 'error'
          //   });
          // }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async handleUpdateOrderStatus() {
      const data = this.selectedOrderSupplierData;
      console.log('🚀 ~ file: admin.orderDetail.vue ~ line 3439 ~ handleUpdateOrderStatus ~ data', data);
      if (data.children.some(orderDetail => orderDetail.price === 0 || orderDetail.supplierPrice === 0)) {
        this.$notify({
          title: this.$i18n.t('common.notify.title.error'),
          message: 'Cannot change waiting confirm status. Please edit price before change status',
          type: 'error'
        });
      } else {
        let postData = {
          id: data.orderSupId,
          orderId: this.$route.params.id
        };
        if (!data.supStatus) {
          postData.supStatus = '2';
          postData.estimatedDeliveryDate = this.formWaitingConfirm.deliveryDate;
          if (this.formWaitingConfirm.note !== '') postData.note = this.formWaitingConfirm.note;
          if (this.formWaitingConfirm.title !== '') postData.titleNote = this.formWaitingConfirm.title;
          this.formWaitingConfirm = cloneDeep(this.formWaitingConfirmDefault);
          this.handleSendMailToSupplier(this.mailSupplierContent);
        } else {
          postData.supStatus = data.supStatus;
          if (this.formNoteAdmin.note !== '') postData.note = this.formNoteAdmin.note;
          if (this.formNoteAdmin.title !== '') postData.titleNote = this.formNoteAdmin.title;
          this.formNoteAdmin = cloneDeep(this.formNoteAdminDefault);
        }
        // handle send mail debt request for EI user
        if (Number(data.supStatus) == PO_DA_NHAN_HANG) {
          this.handleSendMailDebtRequest(data);
        }

        const result = await this.updateOrderStatusSupplier(postData);
        if (result) {
          this.$notify({
            title: this.$i18n.t('common.notify.title.success'),
            message: 'Update status successfully',
            type: 'success'
          });
          this.isShowWaitingConfirmDialog = false;
          this.isShowOrderSupplierNote = false;
          this.noteAdmin = '';
        } else {
          this.$notify({
            title: this.$i18n.t('common.notify.title.error'),
            message: this.$i18n.t('src.modules.order.detail.sendMail.error', {
              message: result.message
            }),
            type: 'error'
          });
        }
      }
    },

    onShowWaitingConfirmDialog(data) {
      this.isShowWaitingConfirmDialog = true;
      this.selectedOrderSupplierData = data.row;
      this.mailSupplierContent = data;
    },

    onShowInfoDialog(data) {
      this.isShowInfoDialog = true;
      this.selectedOrderSupplierInfo = data.row.supInfo;
    },

    async showOrderSupStatusTimeline(data) {
      this.isShowOrderSupStatusHistory = true;
      if (data.row.orderSupId && data.row.id) {
        this.isLoadingTimeline = true;
        await this.getBySupplierId({
          orderSupplierId: parseInt(data.row.orderSupId),
          supplierId: parseInt(data.row.id)
        })
          .then(() => (this.isLoadingTimeline = false))
          .catch(error => console.log('show order sup status timeline error ', error));
      }
    },
    sendMessage(data) {
      this.supplierInfo = data.row;
      this.isShowChatBox = true;
    },
    handleCommand(value) {
      const action = parseInt(value.action);
      console.log('value data :', value.data);
      switch (action) {
        case SHOW_INFO:
          this.onShowInfoDialog(value.data);
          break;
        case SEND_MAIL:
          this.onShowSendMailConfirmDialog(value.data);
          break;
        case DOWNLOAD_PO:
          this.downloadSupplierPO(value.data);
          break;
        case SHOW_TIMELINE:
          this.showOrderSupStatusTimeline(value.data);
          break;
        case SEND_MESSAGE:
          this.sendMessage(value.data);
      }
    },
    handleChangeVoucher(value) {
      this.cloneOrderData.discountAmount = this.getDiscountPrice(value);
      this.discountInfo = this.voucherObj[this.cloneOrderData.voucherId];
    },
    handleChangeCoupon(value) {
      this.cloneOrderData.discountAmount = this.getDiscountPrice(value, false);
      this.discountInfo = this.couponObj[this.cloneOrderData.couponId];
    },
    handleCustomerAddressChange(value) {
      const findAddress = this.orderData.customer.dataAddress.find(cusAddress => cusAddress.id === value);
      if (findAddress) {
        this.formInfo = {
          ...this.formInfo,
          address: findAddress.address || '',
          province: findAddress.province || '',
          district: findAddress.district || '',
          ward: findAddress.ward
        };
      }
    },
    handleCloseOrderSupplierNote() {
      this.isShowOrderSupplierNote = false;
      this.isShowConfirmChangeSupStatus = false;
    },
    handleCloseChatBox() {
      this.supplierInfo = null;
    },
    handleChangeEICashStatus(value) {
      let message = 'Bạn có chắc chắn muốn xoá EI Cash không ? ';
      if (value) message = 'Bạn có chắc chắn muốn thêm EI Cash không ? ';
      this.$confirm(message)
        .then(() => {
          this.onSaveOrder();
        })
        .catch(() => {
          this.selectedEiCash = !this.selectedEiCash;
        });
    },
    changeVisibleLalaMove() {
      this.lalamoveVisible = true;
    }
  }
};
</script>
<style>
.el-table__fixed-right-patch {
  background-color: #333 !important;
  height: 50px !important;
}
.el-form-item__error {
  margin-top: 5px !important;
  margin-left: 5px;
  letter-spacing: 1px;
}
.el-form-item__label {
  margin-bottom: 0px !important;
}
.el-icon-download {
  color: #67c23a;
}
.el-collapse-item__wrap li {
  font-size: 14px;
}
.el-collapse-item__wrap li:hover {
  background-color: #dedede;
}
.el-collapse-item__header {
  background-color: #ebebeb !important;
  padding: 0px 15px;
}
.el-collapse-item__content {
  background-color: #fafafa !important;
  max-height: 200px;
  overflow-y: scroll;
}
.el-collapse-item__wrap {
  border-bottom: none !important;
}
.select-address-dialog .el-dialog__body {
  padding: 15px 20px;
}
.select-address-dialog .el-dialog__header {
  padding-bottom: 20px;
}
.pac-container {
  z-index: 9999999 !important;
  top: 43px !important;
  left: 0 !important;
  width: 320px !important;
}
.pac-item {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}

.pac-item:hover {
  background-color: #ececec;
}

.supplier-address-dialog .el-dialog__body {
  padding: 10px 20px 50px 20px;
  height: auto;
  max-height: 640px;
  overflow: auto;
}

.supplier-status-select {
  background-color: white !important;
}
.supplier-status-select input {
  width: 100%;
  border: none;
  background-color: white !important;
}
</style>
