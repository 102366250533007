import Vue from 'vue';
import Router from 'vue-router';
import store from 'store';
import {
  i18n,
  loadLanguageAsync
} from 'locales';
import NatAcl from 'vue-module-acl';
import {
  DEFAULT_TITLE,
  AUTH_LANGUAGE,
  LOGIN_MODE
} from 'core/constants';
import {
  convertPermissions
} from 'core/helpers';
import Cookies from 'js-cookie';
import {
  ACCESS_TOKEN
} from 'core/constants';

import Global from 'modules/global';
import Dashboard from 'modules/dashboard';
import Order from 'modules/order';
import OrderDetail from 'modules/orderDetail';
import Login from 'modules/global/components/Login';
import Register from 'modules/global/components/Register/Register';
import ErrorPage from 'modules/global/components/Error/Error';
import Customer from 'modules/customer';
import OrderStatus from 'modules/orderStatus';
import Profile from 'modules/profile';
import ChangePassword from 'modules/global/components/changePassword';
import ForgetPassword from 'modules/global/components/forgotPassword';
import User from 'modules/user';
import CustomerNotification from 'modules/customerNotification';
import CustomerDetail from 'modules/customerDetail';
import Quotation from 'modules/quotation';
import QuotationDetail from 'modules/quotationDetail';
import Collaborator from 'modules/collaborator';

Vue.use(Router);

// catch type login
const host = window.location.host;
const parts = host.split('.');
let metaTitle;
if (parts[0] === LOGIN_MODE.ADMIN) metaTitle = i18n.t('src.core.router.ei_admin');

const router = new Router({
  mode: 'history',
  saveScrollPosition: true,
  linkActiveClass: 'open active',
  routes: [{
      path: '/',
      redirect: '/app/dashboard'
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: metaTitle,
        requiresAuth: false
      }
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: {
        title: metaTitle,
        requiresAuth: false
      }
    },
    {
      path: '/error',
      name: 'Error',
      component: ErrorPage,
      meta: {
        title: metaTitle,
        requiresAuth: true
      }
    },
    {
      path: '/change-password',
      name: 'ChangePassword',
      component: ChangePassword,
      meta: {
        title: metaTitle,
        requiresAuth: true
      }
    },
    {
      path: '/forgot-password',
      name: 'ForgetPassword',
      component: ForgetPassword,
      meta: {
        title: metaTitle,
        requiresAuth: true
      }
    },
    {
      path: '/app',
      name: 'Global',
      component: Global,
      children: [{
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            title: metaTitle,
            requiresAuth: true
          }
        },
        {
          path: 'my-account',
          name: 'MyAccount',
          component: Profile,
          meta: {
            title: metaTitle,
            requiresAuth: true
          }
        },
        {
          path: 'order',
          name: 'Order',
          component: Order,
          meta: {
            title: metaTitle,
            requiresAuth: true,
            breadcrumb: [{
                name: i18n.t('src.components.AppLeftMenu.index.order')
              },
              {
                name: i18n.t('src.components.AppLeftMenu.index.order_management')
              }
            ]
          }
        },
        {
          path: 'order/edit/:id',
          name: 'OrderDetail',
          component: OrderDetail,
          meta: {
            title: metaTitle,
            requiresAuth: true,
            breadcrumb: [{
                name: i18n.t('src.components.AppLeftMenu.index.order'),
                link: 'order'
              },
              {
                name: i18n.t('src.components.AppLeftMenu.index.order') + ' #' + '${id}',
                routeParams: ['id']
              }
            ]
          }
        },
        {
          path: 'order/setting/order-status',
          name: 'OrderStatus',
          component: OrderStatus,
          meta: {
            title: metaTitle,
            requiresAuth: true,
            breadcrumb: [{
                name: 'order'
              },
              {
                name: 'setting'
              },
              {
                name: 'order status',
                link: 'order/setting/order-status'
              }
            ]
          }
        },
        {
          path: 'customer/management',
          name: 'Customer',
          component: Customer,
          meta: {
            title: metaTitle,
            requiresAuth: true,
            breadcrumb: [{
                name: i18n.t('src.components.AppLeftMenu.index.customer'),
                link: 'customer/management'
              },
              {
                name: i18n.t('src.components.AppLeftMenu.index.customer_management')
              }
            ]
          }
        },

        {
          path: 'customer/notification',
          name: 'CustomerNotification',
          component: CustomerNotification,
          meta: {
            title: metaTitle,
            requiresAuth: true,
            breadcrumb: [{
                name: i18n.t('src.components.AppLeftMenu.index.customer'),
                link: 'customer/notification'
              },
              {
                name: i18n.t('src.components.AppLeftMenu.index.customer_notification')
              }
            ]
          }
        },
        {
          path: 'customer/detail/:id',
          name: 'CustomerDetail',
          component: CustomerDetail,
          meta: {
            title: metaTitle,
            requiresAuth: true,
            breadcrumb: [{
                name: i18n.t('src.components.AppLeftMenu.index.customer'),
                link: 'customer/management'
              },
              {
                name: i18n.t('src.components.AppLeftMenu.index.customer') +
                  ' #' +
                  '${id}',
                routeParams: ['id']
              }
            ]
          }
        },
        {
          path: 'staff/user',
          name: 'User',
          component: User,
          meta: {
            title: metaTitle,
            requiresAuth: true,
            breadcrumb: [{
                name: i18n.t('src.components.AppLeftMenu.index.staff')
              },
              {
                name: i18n.t('src.components.AppLeftMenu.index.user')
              }
            ]
          }
        },
        {
          path: 'quotation/management',
          name: 'Quotation',
          component: Quotation,
          meta: {
            title: metaTitle,
            requiresAuth: true,
            breadcrumb: [{
                name: i18n.t('src.components.AppLeftMenu.index.quotation')
              },
              {
                name: i18n.t('src.components.AppLeftMenu.index.quotation_management')
              }
            ]
          }
        },
        {
          path: '/quotation/:id',
          name: 'Quotation Detail',
          component: QuotationDetail,
          meta: {
            title: metaTitle,
            requiresAuth: true,
            breadcrumb: [{
                name: i18n.t('src.components.AppLeftMenu.index.quotation'),
                link: 'quotation/management'
              },
              {
                name: i18n.t('src.components.AppLeftMenu.index.quotation_detail') +
                  ' #' +
                  '${id}',
                routeParams: ['id']
              }
            ]
          }
        },
        {
          path: 'collaborator/management',
          name: 'Collaborator',
          component: Collaborator,
          meta: {
            title: metaTitle,
            requiresAuth: true,
            breadcrumb: [{
                name: i18n.t('src.components.AppLeftMenu.index.collaborator')
              },
              {
                name: i18n.t('src.components.AppLeftMenu.index.collaborator_management')
              }
            ]
          }
        }
      ],
      meta: {
        title: metaTitle,
        requiresAuth: true
      }
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  try {
    Vue.nextTick(async () => {
      document.title = to.meta.title || DEFAULT_TITLE;
      /**
       * Check Login
       */

      const isLoginPage = to.matched.some(p => p.path.indexOf('/login') === 0);
      const isForgotPage = to.matched.some(p => p.path.indexOf('/forgot-password') === 0);
      const isAuthenticated = store.getters['auth/isAuthenticated'];

      const requiresAuth = to.meta.requiresAuth;
      if (isAuthenticated && isLoginPage) {
        const authUser = store.getters['auth/user'];
        let permission = [];
        if (!authUser.userPermissions) {
          const user = await store.dispatch('auth/getMe');

          permission = user.userPermissions;
        } else {
          permission = authUser.userPermissions;
        }

        const listPermissions = convertPermissions(permission);

        Vue.use(NatAcl, {
          router: router,
          init: listPermissions,
          fail: '/error'
        });
        return next('/');
      }
      if (requiresAuth && !isAuthenticated && !isForgotPage) {
        window.location.href = '/login';
      }

      try {
        const authUser = store.getters['auth/user'];
        let permission = [];
        if (!authUser.userPermissions) {
          const user = await store.dispatch('auth/getMe');
          permission = user.userPermissions;
        } else {
          permission = authUser.userPermissions;
        }

        const listPermissions = convertPermissions(permission);

        Vue.use(NatAcl, {
          router: router,
          init: listPermissions,
          fail: '/error'
        });
      } catch (err) {
        if (Cookies.get(ACCESS_TOKEN)) {
          store.dispatch('auth/logoutUser');
        }
      }

      /**
       * Check language
       */
      let lang = '';           
      if (to.params.lang) {
        lang = to.params.lang;
      } else if (localStorage.getItem(AUTH_LANGUAGE)) {
        lang = localStorage.getItem(AUTH_LANGUAGE);
      }

      if (lang === '') {
        const navlang = navigator.language || navigator.userLanguage;
        const browserLanguagesMapping = [{
            key: 'en-us',
            value: 'en-US'
          },
          {
            key: 'vi-vn',
            value: 'vi-VN'
          }
        ];
        const navLanguage = browserLanguagesMapping.filter(item => {
          return item.key.toLowerCase() == navlang.toLowerCase();
        });
        if (navLanguage.length > 0) lang = navLanguage[0].value;
        else lang = 'vi-VN';
      }
      localStorage.setItem(AUTH_LANGUAGE, lang);
      loadLanguageAsync(lang).then(() => next());
    });

    await store.dispatch('global/setLoginMode', LOGIN_MODE.ADMIN);

    return next();
  } catch (error) {
    next(error);
  }
});

export default router;