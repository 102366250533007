import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  commons: [],
  commonMap: {},
  listCommons: [],
  listCommon2nd: [],
  quoCancelReasonMap: {}
};

const mutations = {
  async [Type.SET_COM_CODE_MAP](state, { code, value }) {
    const commonMap = Object.assign(state.commonMap, { [code]: value });
    state.commonMap = {...commonMap}
  },

  async [Type.GET_ALL](state, value) {
    state.commons = value.rows;
    state.listCommons = value.rows;

  },

  async [Type.SET_QUO_CANCEL_REASON_MAP](state, value){
    const { rows } = value;
    state.quoCancelReasonMap = rows.reduce(
      (cMap, c) => ({
        ...cMap,
        [c.code]: c
      }), {}
    );
  },

  async [Type.GET_ALL_2ND](state, value) {
    state.listCommon2nd = value.rows;
  },

  async [Type.SAVE](state, value) {
    state.commons = [...value, ...state.commons];
    Promise.all(
      value.map(item => {
        state.listCommons.push(item);
      })
    );
  },

  async [Type.EDIT](state, value) {
    const oldCommons = state.commons;
    const index = state.commons.findIndex(element => element.id == value.id);
    oldCommons[index] = value;
    state.commons = oldCommons.slice(0);

    // update listCommonsActions[]
    const indexInListCommons = state.listCommons.findIndex(element => element.id == value.id);
    state.listCommons[indexInListCommons].name = value.name;
  },

  async [Type.DELETE](state, value) {
    await Promise.all(
      value.map(item => {
        const index = state.commons.findIndex(element => element.id == item.id);
        state.commons.splice(index, 1);
        const indexInListCommons = state.listCommons.findIndex(element => element.id == item.id);
        state.listCommons.splice(indexInListCommons, 1);
      })
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
