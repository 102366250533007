import Service from 'core/service';
import * as Type from 'modules/category/store/types';
import {
    cloneDeep
} from 'lodash';

const service = new Service()

const _convertData = (row) => {
    const newData = cloneDeep(row)
    return newData
}

const getAllCategories = async ({
    commit
}, value) => {
    const {
        name = '',
        parentId = '',
        supplierIds = [],
        supplierId = '',
    } = value || {};
    const params = {}

    if (name) {
        params.name = name;
    };
    if (supplierId && supplierId > 0) {
        params.supplierId = Number(supplierId);
    };
    if (parentId || parentId === 0) {
        params.parentId = Number(parentId);
    };
    if (supplierIds.length > 0) {
        params.supplierIds = supplierIds.join('|');
    };

    return service.rest('/category/getListPaging', params).then(res => {
        commit(Type.SET_ALL_CATEGORY, res.rows);
        return true
    }).catch(err => {
        console.log(err);
        return false
    })
};

const getFullCategories = async ({
    commit
}) => {
    return service.rest('/category/getListPaging', {}).then(res => {
        commit(Type.SET_FULL_CATEGORY, res.rows);
        return true
    }).catch(err => {
        console.log(err);
        return false
    })
};

const addCategoryLocal = async ({
    commit
}, newRow) => {
    // Convert data
    const data = _convertData(newRow)
    // Commit to news state
    commit(Type.SET_CATEGORY_LOCAL, data);
    return true
};

const addCategory = async ({
    commit
}, arrAddData) => {
    // Convert data
    const arrAddDataConvert = arrAddData.map(item => {
        return _convertData(item)
    })
    return service.rest('category/setList', {
        items: {
            $add: arrAddDataConvert
        }
    }).then(rlt => {
        const {
            items
        } = rlt;
        const {
            $rltAdd
        } = items
        // Commit to Category state
        if ($rltAdd && $rltAdd.length > 0) {
            // Commit to product state
            commit(Type.SET_CATEGORY_ADD, $rltAdd[0]);
            return true;
        } else {
            throw 'exit';
        }
    }).catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
        return false

    })
};

const editCategory = async ({
    commit
}, editData) => {
    const {
        oldData,
        newData
    } = editData
    const {
        id
    } = newData;
    const data = _convertData(newData);
    if (id) {
        // Convert data
        return service.rest('category/setList', {
            items: {
                $update: [data]
            }
        }).then(rlt => {
            const {
                items
            } = rlt;
            const {
                $rltUpdate
            } = items
            if ($rltUpdate && $rltUpdate.length > 0) {
                // Commit to news state
                commit(Type.SET_CATEGORY_UPDATE, $rltUpdate[0]);
                return true;
            } else {
                throw 'exit';
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return false
        })
    } else {
        // Commit to news state
        commit(Type.SET_CATEGORY_UPDATE_LOCAL, {
            oldData,
            newData: data
        });
        return true
    }
};

const deleteCategory = async ({
    commit
}, value) => {
    const {
        id
    } = value;
    if (id) {
        return service.rest('category/setList', {
            items: {
                $delete: [value]
            }
        }).then(rlt => {
            const {
                items
            } = rlt;
            const {
                $rltDelete
            } = items
            // Commit to news state
            commit(Type.SET_DELETE_CATEGORY, $rltDelete);
            return value
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return false

        })
    } else {
        commit(Type.SET_DELETE_CATEGORY_LOCAL, [value]);
        return true
    }
};

const changeStatusCategory = async ({
    commit
}, value) => {
    return service.rest('category/changeStatus', value).then(res => {
        commit(Type.SET_CATEGORY_UPDATE, res);
        return true
    }).catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
        return false
    })
};


const publishMenuList = async () => {
    try {
        const result = await service.rest('category/ei/publishMenuList').catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                status: false,
                message: 'false'
            };
        })
        return result;
    } catch (error) {
        console.log('🚀 ~ file: actions.js ~ line 178 ~ publishMenuList ~ error', error)
        return {
            status: false,
            message: 'false'
        };
    }
};

const publishSupMenuList = async () => {
    try {
        const result = await service.rest('category/ei/publishSupMenuList').catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                status: false,
                message: 'false'
            };
        })
        return result;
    } catch (error) {
        console.log('🚀 ~ file: actions.js ~ line 178 ~ publishMenuList ~ error', error)
        return {
            status: false,
            message: 'false'
        };
    }
};

const deleteCategoryList = async ({
    commit
}, value) => {
    const lstLocal = [];
    const lstDB = [];
    // Filter
    value.map(item => {
        const {
            id
        } = item
        if (id) {
            lstDB.push(item)
        } else {
            lstLocal.push(item)
        }
    })

    if (lstLocal.length > 0) {
        commit(Type.SET_DELETE_CATEGORY_LOCAL, lstLocal);
    }

    if (lstDB.length > 0) {
        return service.rest('category/setList', {
            items: {
                $delete: lstDB
            }
        }).then(rlt => {
            const {
                items
            } = rlt;
            const {
                $rltDelete
            } = items
            // Commit to news state
            commit(Type.SET_DELETE_CATEGORY, $rltDelete);
            return true
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return false
        })
    }
};

export {
    editCategory,
    getAllCategories,
    addCategory,
    deleteCategory,
    deleteCategoryList,
    addCategoryLocal,
    publishMenuList,
    publishSupMenuList,
    getFullCategories,
    changeStatusCategory
};