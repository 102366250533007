import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';

const state = {
  industries: [],
  industriesMap: {}
};

const mutations = {
  [Type.SET_ALL_INDUSTRIES](state, value) {
    state.industries = value;
    state.industriesMap = state.industries.reduce(
      (cMap, c) => ({
        ...cMap,
        [c.id]: c
      }),
      {}
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
