import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
    units: [],
    listUnit: [] 
};

const mutations = {
    async [Type.GET_ALL](state, value) {
      state.units = value.rows;
    },
    async [Type.GET_ALL_UNIT_SELECT](state, value) {
      state.listUnit = value.rows;
    },
    async [Type.SAVE](state, value) {
      state.units = [ ...value, ...state.units]
      Promise.all(value.map( item => {
        state.listUnit.push(item);
      }));
    },
    async [Type.EDIT](state, value) {
      const oldUnits = state.units;
      const { id } = value;
      const index = state.units.findIndex((element) => element.id == id);
      oldUnits[index] = value;
      state.units = oldUnits.slice(0);
    },
    async [Type.DELETE](state, value) {
        await Promise.all(value.map( item => {
          const index = state.units.findIndex((element) => element.id == item.id);
          state.units.splice(index, 1)
          const indexInListUnit = state.listUnit.findIndex((element) => element.id == item.id);
          state.listUnit.splice(indexInListUnit, 1)
        }));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};