import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
import cloneDeep from 'lodash/cloneDeep';

const state = {
  quotations: [],
  quotationsPaging: [],
  totalRowsQuotation: 0,
  quotationsByQuotationCode:[],
  quotationDataExportExcel: []
};

const mutations = {
  [Type.SET_ALL_QUOTATION](state, value) {
    state.quotations = value;
  },

  [Type.SET_PAGING_QUOTATION](state, value) {
    state.quotationsPaging = value.items;
    state.totalRowsQuotation = value.totalCnt;
  },

  [Type.SET_QUOTATION_ADD](state, value){
    const oldCustomerAddress = state.quotations;
    //add new data
    state.quotations = [...cloneDeep(value), ...oldCustomerAddress];
  },

  [Type.SET_QUOTATION_UPDATE](state, value) {
    const oldQuotation = state.quotations;
    const { id } = value;
    const objIndex = state.quotations.findIndex(obj => obj.id === id);
    oldQuotation[objIndex] = value;
    state.quotations = oldQuotation.slice(0);
  },

  [Type.SET_DELETE_QUOTATION](state, value){
    const oldData = cloneDeep(state.quotations);
    value.map(item => {
      const { id } = item;
      const objIndex = oldData.findIndex(obj => obj.id === id);
      oldData.splice(objIndex, 1);
    });
    state.quotations = oldData.slice(0);
  },

  [Type.SET_ALL_QUOTATION_BY_QUOTATION_CODE](state, value){
    const oldValue = state.quotationsByQuotationCode;
    state.quotationsByQuotationCode = [...cloneDeep(value), ...oldValue];
  },

  [Type.SET_RESET_QUOTATION_CODE_FILTER](state){
    state.quotationsByQuotationCode = [];
  },

  [Type.GET_QUOTATION_FOR_EXPORT_EXCEL](state, value){
    state.quotationDataExportExcel = value;
  },
  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};