<template>
  <el-dialog :title="$i18n.t('src.components.modal.selectProduct.title')" :visible.sync="dialogVisible" :before-close="handleClose" :close-on-click-modal="false" width="80%">
    <b-row>
      <b-col cols="3" class="pr-5" style="border-right: 1px solid #ebeef5">
        <el-form ref="form" :model="form" label-width="120px" size="medium" label-position="top">
          <el-form-item :label="$i18n.t('src.components.modal.selectProduct.title.category')">
            <el-select
              clearable
              v-model="formSearchProduct.categoryId"
              :placeholder="$i18n.t('src.components.modal.selectProduct.placaholder.category')"
              filterable
              :popper-append-to-body="false"
              :filter-method="(value) => (searchCate = value)"
              @clear="handleClearSearchCate"
            >
              <el-option v-for="item in getCategory" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$i18n.t('src.components.modal.selectProduct.title.name')">
            <el-input v-model="formSearchProduct.name" :placeholder="$i18n.t('src.components.modal.selectProduct.placaholder.name')"></el-input>
          </el-form-item>
          <el-form-item :label="$i18n.t('src.components.modal.selectProduct.title.model')">
            <el-input v-model="formSearchProduct.model" :placeholder="$i18n.t('src.components.modal.selectProduct.placaholder.model')"></el-input>
          </el-form-item>
          <el-form-item :label="$i18n.t('src.components.modal.selectProduct.title.supplier')">
            <el-select
              size="medium"
              v-model="formSearchProduct.supplierId"
              :placeholder="$i18n.t('src.components.modal.selectProduct.placaholder.supplier')"
              clearable
              filterable
              :filter-method="(value) => (searchSupplier = value)"
              @clear="handleClearSearchSupplier"
            >
              <el-option v-for="item in getSupplier" :key="item.value" :label="item.label" :value="item.value">
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.isSourcing ? 'Sourcing' : '' }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="danger" size="medium" @click="handleResetField" class="btn-red-lg float-left">
              <i class="el-icon-delete mr-2"></i> {{ $i18n.t('src.components.modal.selectProduct.btnResetField') }}
            </el-button>
            <el-button type="info" size="medium" @click="handleSearchProduct" class="btn-black-lg float-right">
              <i class="el-icon-search mr-2"></i> {{ $i18n.t('src.components.modal.selectProduct.btnSearch') }}
            </el-button>
          </el-form-item>
        </el-form>
      </b-col>
      <b-col cols="9" class="pl-5">
        <b-row>
          <b-col cols="5">
            <!-- <p>Có <strong>{{ this.productCount }}/{{ this.productTotalCnt }}</strong> sản phẩm được tìm thấy</p> -->
            <h5 class="mb-2" v-if="this.productTotalCnt > 0">{{ $i18n.t('src.components.modal.selectProduct.searchResult', { count: this.productCount, total: this.productTotalCnt }) }}</h5>
            <h5 class="mb-2" v-else>
              {{ $i18n.t('src.components.modal.selectProduct.searchNoResult', { count: this.productCount, total: this.productTotalCnt }) }}
            </h5>
          </b-col>
          <b-col cols="7">
            <el-button type="success" size="small" class="mb-2 float-right btn-green-md mr-2" :disabled="selectedProducts.length === 0" @click="emitSaveAddOrderDetail"
              ><i class="el-icon-plus mr-2"></i>&nbsp;
              {{ $i18n.t('src.components.modal.selectProduct.addSelectedProduct', { count: this.selectedProducts.length > 0 ? this.selectedProducts.length : '' }) }}
            </el-button>
            <el-button type="primary" size="small" class="mb-2 float-right btn-blue-md mr-2" @click="addNewProductDialogVisible = true"
              ><i class="el-icon-plus mr-2"></i>&nbsp;
              {{ $i18n.t('src.components.modal.addNewProduct.title') }}
            </el-button>
          </b-col>
        </b-row>
        <el-table
          v-loading="isLoadingProduct"
          :data="listProduct"
          height="450"
          ref="multipleSelection"
          style="width: 100%"
          @selection-change="emitChangeSelect"
          header-row-class-name="list-product-row"
          stripe
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="eic" :label="$i18n.t('src.components.modal.selectProduct.tableProduct.model')" width="150"> </el-table-column>
          <el-table-column :label="$i18n.t('src.components.modal.selectProduct.tableProduct.image')" width="100">
            <template slot-scope="scope">
              <el-image style="width: 50px; height: 50px" :src="S3_STORE_ENDPOINT + '/' + getProductImage(scope.row.thumbnail)"> </el-image>
            </template>
          </el-table-column>
          <el-table-column width="250" :label="$i18n.t('src.components.modal.selectProduct.tableProduct.name')">
            <template slot-scope="scope">
              <el-tooltip effect="dark" :content="scope.row.name" placeholder="top" v-if="scope.row.name.length > 25">
                <a target="_blank" :href="EI_ENDPOINT + '/' + scope.row.slug + '-pid' + scope.row.id">{{ scope.row.name | truncate(25) }}</a>
              </el-tooltip>
              <span v-else>
                <a target="_blank" :href="EI_ENDPOINT + '/' + scope.row.slug + '-pid' + scope.row.id">{{ scope.row.name }}</a>
              </span>
            </template>
          </el-table-column>
          <el-table-column width="250" :label="$i18n.t('src.components.modal.selectProduct.tableProduct.supplier')">
            <template slot-scope="scope">
              <el-tooltip
                effect="dark"
                :content="supplierObj[scope.row.supplierId]"
                placeholder="top"
                v-if="scope.row.supplierId && typeof supplierObj[scope.row.supplierId] !== 'undefined' && supplierObj[scope.row.supplierId].length > 25"
              >
                <span>{{ supplierObj[scope.row.supplierId] | truncate(25) }}</span>
              </el-tooltip>
              <span v-else-if="scope.row.supplierId && typeof supplierObj[scope.row.supplierId] !== 'undefined' && supplierObj[scope.row.supplierId].length <= 25">
                {{ supplierObj[scope.row.supplierId] }}
              </span>
              <span v-else>No data</span>
            </template>
          </el-table-column>
          <el-table-column :label="$i18n.t('src.components.modal.selectProduct.tableProduct.salePrice')" width="200" style="font-weight: bold">
            <template slot-scope="scope">
              {{
                scope.row.salePrice
                  ? new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND'
                    }).format(scope.row.salePrice)
                  : 'Liên hệ'
              }}
            </template>
          </el-table-column>
          <el-table-column :label="$i18n.t('src.components.modal.selectProduct.tableProduct.supplierPrice')" width="200" style="font-weight: bold">
            <template slot-scope="scope">
              {{
                scope.row.supplierPrice
                  ? new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND'
                    }).format(scope.row.supplierPrice)
                  : 'Liên hệ'
              }}
            </template>
          </el-table-column>
          <el-table-column :label="$i18n.t('src.components.modal.selectProduct.tableProduct.taxRate')" width="200" style="font-weight: bold">
            <template slot-scope="scope">
              {{ scope.row.taxRate }} %
            </template>
          </el-table-column>
          <infinite-loading spinner="waveDots" slot="append" @infinite="infiniteHandler" force-use-infinite-wrapper=".el-table__body-wrapper" :identifier="infiniteId">
            <span slot="no-more"></span>
            <span slot="no-results"></span>
            <div slot="error" slot-scope="{ trigger }">
              Opps, something went wrong :( <br />
              Click <a href="javascript:;" @click="trigger">here</a> to retry
            </div>
          </infinite-loading>
        </el-table>
      </b-col>
    </b-row>
    <span slot="footer" class="dialog-footer">
      <el-row :gutter="24">
        <el-col :span="14" style="margin-left: 25%">
          <el-button class="float-left" v-show="customerId > 0" type="success" size="small" @click="handleGetProductRepurchase()"
            >{{ $i18n.t('src.components.modal.selectProduct.tableProduct.getProductRepurchase') }}
          </el-button>
        </el-col>
        <el-col :span="customerId > 0 ? 4 : 24">
          <el-button class="float-right" @click="emitCloseSelectProduct">{{ $i18n.t('src.components.modal.selectProduct.tableProduct.close') }}</el-button>
        </el-col>
      </el-row>
    </span>
    <el-dialog
      width="40%"
      :title="$i18n.t('src.components.modal.addNewProduct.title')"
      :visible.sync="addNewProductDialogVisible"
      :before-close="handleCloseAddProductForm"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form :model="formNewProduct" ref="formNewProduct" :rules="ruleFormNewProduct" label-width="150px" class="demo-ruleForm">
        <el-form-item :label="$i18n.t('src.components.modal.selectProduct.form.newProduct.name.title')" prop="name">
          <el-input v-model="formNewProduct.name" :placeholder="$i18n.t('src.components.modal.selectProduct.form.newProduct.name.placeholder')"></el-input>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.components.modal.selectProduct.form.newProduct.supplierName.title')" prop="supplierName">
          <el-select
            v-model="selectedSupplier"
            :placeholder="$i18n.t('src.components.modal.selectProduct.placaholder.supplier')"
            clearable
            filterable
            :disabled="isCreateNew"
            @change="handleChangeSupplier"
          >
            <el-option v-for="item in getSupplier" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-checkbox v-model="isCreateNew" class="mb-0" @change="handleChangeCreateNewOotion">
            {{ $i18n.t('src.components.modal.selectProduct.form.newProduct.newSupplier.label') }}
          </el-checkbox>
          <el-input v-model="formNewProduct.supplierName" :placeholder="$i18n.t('src.components.modal.selectProduct.form.newProduct.supplierName.placeholder')" v-if="isCreateNew"> </el-input>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.components.modal.selectProduct.form.newProduct.price.title')" prop="price">
          <el-input v-model.number="formNewProduct.price" :placeholder="$i18n.t('src.components.modal.selectProduct.form.newProduct.price.placeholder')"></el-input>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.components.modal.selectProduct.form.newProduct.supplierPrice.title')" prop="supplierPrice">
          <el-input v-model.number="formNewProduct.supplierPrice" :placeholder="$i18n.t('src.components.modal.selectProduct.form.newProduct.price.placeholder')"></el-input>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.modules.product.index.unit')" prop="unitCode">
          <el-select size="medium" v-model="formNewProduct.unitCode" placeholder="Select Unit" width="100%" filterable>
            <el-option v-for="item in unitOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.modules.product.index.description')" prop="description">
          <ckeditor v-model="formNewProduct.description" class="nat-cke-content" :config="editorConfig"></ckeditor>
        </el-form-item>
        <el-form-item>
          <el-button class="float-right mr-2" type="primary" @click="handleSaveAddroduct">{{ $i18n.t('common.label.add') }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import { filterByKey } from 'core/helpers';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { SUPPLIER_TYPE_CODE } from '../../../core/constants';

export default {
  components: { InfiniteLoading },

  props: {
    getting: {
      type: String,
      default: 'Hello'
    },
    customerId: {
      type: Number,
      default: null
    },
    haveIsDemoAndIsSourcing: {
      type: Boolean,
      default: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    selectedItems: {
      type: Array,
      default: () => []
    }
  },

  data: function () {
    return {
      S3_STORE_ENDPOINT: process.env.VUE_APP_S3_STORE_ENDPOINT,
      EI_ENDPOINT: process.env.VUE_APP_EI_ENDPOINT,
      isLoading: false,
      isLoadingProduct: false,
      isShowProductRepurchase: false,
      editorConfig: {
        language: 'en'
      },
      // Search Form
      formSearchProduct: {
        categoryId: '',
        supplierId: '',
        tags: [],
        name: '',
        model: ''
      },
      formSearchProductDefault: {
        categoryId: '',
        supplierId: '',
        tags: [],
        name: '',
        model: ''
      },
      form: {
        date1: ''
      },
      innerVisible: false,
      addNewProductDialogVisible: false,
      multipleSelection: [],
      confirmSelection: [],
      page: 1,
      listProduct: [],
      isTriggerFirstLoad: false,
      infiniteId: +new Date(),
      productCount: 0,
      searchCate: '',
      searchSupplier: '',
      productTotalCnt: 0,
      isCreateNew: false,
      selectedSupplier: '',
      formNewProduct: {
        name: '',
        supplierName: '',
        price: '',
        supplierPrice: '',
        unitCode: '',
        description: ''
      },
      formNewProductDefault: {
        name: '',
        supplierName: '',
        price: '',
        supplierPrice: '',
        unitCode: '',
        description: ''
      },
      ruleFormNewProduct: {
        name: [{ required: true, message: this.$i18n.t('src.components.modal.selectProduct.form.newProduct.name.placeholder'), trigger: 'blur' }]
      }
    };
  },
  computed: {
    ...mapState('supplier', ['suppliers']),
    ...mapState('product', ['products', 'totalRows']),
    ...mapState('category', ['categories', 'categoriesMap']),
    ...mapState('unit', ['units']),
    ...mapState('productRepurchase', ['productRepurchases', 'totalRowsProductRepurchases']),

    unitOptions() {
      return this.units.map((item) => {
        return {
          value: item.code,
          label: item.vi_VN
        };
      });
    },

    dialogVisible() {
      return this.visible;
    },

    getCategory() {
      return filterByKey(this.categories, 'name', this.searchCate).map((item) => ({
        label: item.name,
        value: item.id
      }));
    },

    getSupplier() {
      return filterByKey(this.suppliers, 'name', this.searchSupplier).map((item) => {
        return {
          label: item.name,
          value: item.id,
          isSourcing: item.typeCode == SUPPLIER_TYPE_CODE.SOURCING ? true : false
        };
      });
    },
    supplierObj() {
      let obj = this.suppliers.reduce(function (o, val) {
        o[val.id] = val.name;
        return o;
      }, {});
      return obj;
    },
    // listProduct() {
    //   let queryString = []
    //   if (this.formSearchProduct.name) {
    //     queryString.push(data =>
    //       data.name
    //         .toLowerCase()
    //         .includes(this.formSearchProduct.name.trim().toLowerCase()),
    //     )
    //   }
    //   if (this.formSearchProduct.model) {
    //     queryString.push(data =>
    //       data.eic
    //         .toLowerCase()
    //         .includes(this.formSearchProduct.model.trim().toLowerCase()),
    //     )
    //   }
    //   if (this.formSearchProduct.supplierId) {
    //     queryString.push(
    //       data => data.supplierId === this.formSearchProduct.supplierId,
    //     )
    //   }
    //   let result = this.products.filter(data =>
    //     queryString.reduce(
    //       (accumulator, queryFilter) => accumulator && queryFilter(data),
    //       true,
    //     ),
    //   )
    //   return result
    // },
    selectedProducts() {
      return this.selectedItems.map((item) => {
        return {
          ...item,
          quantity: 1
        };
      });
    }
  },

  async created() {
    await this.getAllCategories({
      current_page: 0,
      itemsPerPage: 0
    });
    await this.getAllSupplier({ keyword: '' });
    await this.getAllUnits({ name: '' });
  },
  methods: {
    ...mapActions('supplier', ['getAllSupplier', 'addSupplierFromOrderDetail']),

    ...mapActions('product', ['getAllProducts', 'getAllProductsForManagement', 'setEmptyProductList', 'saveAddProductFromOrderDetail']),

    ...mapActions('global', ['setGlobalReady']),

    ...mapActions('category', ['getAllCategories']),

    ...mapActions('unit', ['getAllUnits']),

    ...mapActions('productRepurchase', ['getAllProductRepurchases']),

    async handleGetProductRepurchase() {
      if (!this.isShowProductRepurchase) this.isShowProductRepurchase = true;
      this.isLoadingProduct = true;
      await this.getAllProductRepurchases({ customerId: this.customerId });
      this.listProduct = this.productRepurchases.map((product) => ({
        ...product.product,
        salePrice: product.salePrice,
        supplierPrice: product.supplierPrice
      }));
      this.productTotalCnt = this.totalRowsProductRepurchases;
      this.productCount = this.productRepurchases.length;
      this.isLoadingProduct = false;
    },

    async onChangeSearchModel() {
      this.setGlobalReady(false);
      if (!this.haveIsDemoAndIsSourcing) {
        this.formSearchProduct.isDemo = 0;
        this.formSearchProduct.isSourcing = 0;
        await this.getAllProductsForManagement(this.formSearchProduct);
      } else {
        await this.getAllProducts(this.formSearchProduct);
      }
      this.setGlobalReady(true);
    },

    getProductImage(listImage) {
      const images = listImage ? listImage.split('|') : [];
      return images[0];
    },

    async onSearchProduct() {
      this.setGlobalReady(false);
      if (!this.haveIsDemoAndIsSourcing) {
        this.formSearchProduct.isDemo = 0;
        this.formSearchProduct.isSourcing = 0;
        await this.getAllProductsForManagement(this.formSearchProduct);
      } else {
        await this.getAllProducts(this.formSearchProduct);
      }
      this.setGlobalReady(true);
    },

    // async handleChangeCategory(value) {
    //  this.setGlobalReady(false)
    //   if (value !== '') {
    //     await this.getAllProducts(this.formSearchProduct).then(
    //       () => this.setGlobalReady(true)
    //     ).catch(()=>  this.setGlobalReady(true))
    //   }
    //     this.setGlobalReady(true)
    // },
    emitCloseSelectProduct() {
      this.$emit('onCloseAddProduct');
    },
    emitAddMultiProduct() {
      this.$emit('onClickAddMultiProduct');
    },
    emitChangeSelect(value) {
      this.$emit('onChangeSelect', value);
    },
    emitAddMultipleProduct() {
      this.$emit('onClickAddMultiRow');
      this.$refs.multipleSelection.clearSelection();
    },
    emitAddSingleProduct(scope) {
      this.$emit('onClickAddSingleRow', scope);
    },
    emitSaveAddOrderDetail() {
      this.$emit('onSaveAddOrderDetail');
      this.$refs.multipleSelection.clearSelection();
    },
    handleClose() {
      if (this.selectedItems.length > 0) {
        const message = this.$i18n.t('src.components.modal.selectProduct.notification.close');
        this.$confirm(message, {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        })
          .then(() => {
            this.$emit('onSaveAddOrderDetail');
            this.$emit('onCloseAddProduct');
          })
          .catch(() => {
            this.$emit('onCloseAddProduct');
          });
      } else {
        this.$emit('onCloseAddProduct');
      }
    },
    async infiniteHandler($state) {
      if (this.page === 1) this.isLoadingProduct = true;
      if (this.page === 1 && this.isShowProductRepurchase && this.listProduct < 5) $state.complete();
      if (this.customerId > 0 && this.isShowProductRepurchase) {
        this.isLoadingProduct = true;
        await this.getAllProductRepurchases({ customerId: this.customerId, currentPage: this.page });
        this.productTotalCnt = this.totalRowsProductRepurchases;
        this.productCount += this.productRepurchases.length;
        if (this.productRepurchases > 0) {
          this.page += 1;
          const newListProduct = this.productRepurchases.map((product) => ({
            ...product.product,
            salePrice: product.salePrice,
            supplierPrice: product.supplierPrice
          }));
          this.listProduct.push(...newListProduct);
          $state.loaded();
        } else {
          $state.complete();
        }
        this.isLoadingProduct = false;
      } else {
        if (!this.haveIsDemoAndIsSourcing) {
          this.formSearchProduct.isDemo = 0;
          this.formSearchProduct.isSourcing = 0;
          this.getAllProductsForManagement({ ...this.formSearchProduct, currentPage: this.page }).then(() => {
            this.productTotalCnt = this.totalRows;
            this.isLoadingProduct = false;
            if (this.products.length > 0) {
              this.page += 1;
              this.productCount += this.products.length;
              this.listProduct.push(...this.products);
              $state.loaded();
            } else {
              $state.complete();
            }
          });
        } else {
          this.getAllProducts({ ...this.formSearchProduct, currentPage: this.page }).then(() => {
            this.productTotalCnt = this.totalRows;
            this.isLoadingProduct = false;
            if (this.products.length > 0) {
              this.page += 1;
              this.productCount += this.products.length;
              this.listProduct.push(...this.products);
              $state.loaded();
            } else {
              $state.complete();
            }
          });
        }
      }
    },

    async handleSearchProduct() {
      this.page = 1;
      this.listProduct = [];
      this.productCount = 0;
      this.infiniteId += 1;
      this.isShowProductRepurchase = false;
      // this.isLoading = true;
      // this.page = 0;
      // await this.getAllProducts(this.formSearchProduct)
      // .then(() => {
      //   console.log(this.products);
      //   this.listProduct = this.products
      //   this.isLoading = false
      // })
      // .catch(()=>  this.isLoading = false)
    },
    handleResetField() {
      this.formSearchProduct = cloneDeep(this.formSearchProductDefault);
      this.page = 1;
      this.listProduct = [];
      this.productCount = 0;
      this.infiniteId += 1;
    },
    handleClearSearchCate() {
      this.searchCate = '';
    },
    handleClearSearchSupplier() {
      this.searchSupplier = '';
    },
    clearFormNewProduct() {
      this.formNewProduct = cloneDeep(this.formNewProductDefault);
      this.$refs['formNewProduct'].clearValidate();
    },
    async handleSaveAddroduct() {
      this.$refs['formNewProduct'].validate(async (valid) => {
        if (valid) {
          let checkSuccess = true;
          let supplierId = this.formNewProduct.supplierName;
          if (this.isCreateNew) {
            const postSupData = { name: this.formNewProduct.supplierName };
            await this.addSupplierFromOrderDetail(postSupData).then(async (result) => {
              if (result.status) {
                supplierId = this.suppliers[0].id;
                this.$notify({
                  title: this.$i18n.t('common.notify.title.success'),
                  message: this.$i18n.t('common.notify.message.success.add', {
                    item: localStorage.getItem('user-language') === 'vi-VN' ? 'Nhà Cung Cấp' : 'Supplier'
                  }),
                  type: 'success'
                });
              } else {
                checkSuccess = false;
                this.$notify({
                  title: this.$i18n.t('common.notify.title.error'),
                  message: this.$i18n.t('common.notify.message.error.add', {
                    message: result.message
                  }),
                  type: 'error'
                });
              }
            });
          }

          if (checkSuccess) {
            const postProductData = {
              ...this.formNewProduct,
              supplierId
            };

            await this.saveAddProductFromOrderDetail(postProductData).then((result) => {
              if (result.status) {
                this.$notify({
                  title: this.$i18n.t('common.notify.title.success'),
                  message: this.$i18n.t('common.notify.message.success.add', {
                    item: localStorage.getItem('user-language') === 'vi-VN' ? 'Sản Phẩm' : 'Product'
                  }),
                  type: 'success'
                });
                this.clearFormNewProduct();
                this.handleResetField();
              } else {
                this.$notify({
                  title: this.$i18n.t('common.notify.title.error'),
                  message: this.$i18n.t('common.notify.message.error.add', {
                    message: result.message
                  }),
                  type: 'error'
                });
                this.selectedSupplier = '';
              }
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    handleCloseAddProductForm(done) {
      if (!isEqual(this.formNewProduct, this.formNewProductDefault)) {
        this.$confirm(this.$i18n.t('src.components.modal.selectProduct.notification.closeFormAddProduct'))
          .then(() => {
            done();
            this.clearFormNewProduct();
          })
          .catch(() => {});
      } else {
        this.addNewProductDialogVisible = false;
      }
    },
    handleChangeSupplier(value) {
      this.formNewProduct.supplierName = value;
    },
    handleChangeCreateNewOotion(value) {
      if (value) {
        this.selectedSupplier = '';
        this.formNewProduct.supplierName = '';
      }
    }
  }
};
</script>
<style>
.list-product-row .el-table__fixed-right-patch {
  background-color: #333 !important;
  height: 56px !important;
}
</style>
