import Service from 'core/service';
// import { cloneDeep } from 'lodash';
import * as Type from 'modules/orderMessage/store/types';
import {
  cloneDeep
} from 'lodash';

const service = new Service();

const _convertData = (row) => {
  const newData = cloneDeep(row)
  return newData
}


const getListMessage = async ({ commit }, value) => {
  const {
      orderId = '',
      type = '',
      senderId = '',
      reciId = ''
  } = value || {};
  const params = {};
  if (orderId != '') {
    params.orderId = orderId;
  };
  if (type != '') {
    params.type = type;
  };
  if (senderId != '') {
    params.senderId = senderId;
  };
  if (reciId != '') {
    params.reciId = reciId;
  };
  // Get All Message
  return service
    .rest('orderMessage/getListMessage', params)
    .then(res => {
      console.log('🚀 ~ file: actions.js ~ line 13 ~ getListMessage ~ res', res)
      commit(Type.SET_ALL_MESSAGE, res.items);
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
};

const addMessage = async ({
  commit
}, arrAddData) => {
  // Convert data
  const arrAddDataConvert = arrAddData.map(item => {
      return _convertData(item)
  })
  return service.rest('orderMessage/setList', {
      items: {
          $add: arrAddDataConvert
      }
  }).then(rlt => {
      console.log('🚀 ~ file: actions.js ~ line 67 ~ rlt', rlt)
      const {
          items
      } = rlt;
      const {
          $rltAdd
      } = items
      // Commit to Category state
      if ($rltAdd && $rltAdd.length > 0) {
          // Commit to product state
          commit(Type.SET_MESSAGE_ADD, $rltAdd[0]);
          return true;
      } else {
          throw 'exit';
      }
  }).catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
      return false

  })
};

export { getListMessage, addMessage };
