import Service from 'core/service';
// import { cloneDeep } from 'lodash';
import * as Type from 'modules/customerInvoiceAddress/store/types';

const service = new Service();

const getAllCustomerIncvoiceAddress = async ({ commit }, value) => {
  const {
    customerId
  } = value;

  const params = {};

  if (customerId) {
    params.customerId = customerId;
  }
  // Get All News
  return service
    .rest('customerInvoiceAddress/getList', params)
    .then(res => {
      const { status, data } = res;
      console.log('res: ', res);
      if(!status){
        return false;
      }
      const { items } = data;
      commit(Type.SET_ALL_CUSTOMERS_INVOICE_ADDRESS, items);
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
};

const saveAddCustomerInvoiceAddress = async ({ commit }, arrAddData) => {
  console.log('arrAddData: ', arrAddData);
  Object.keys(arrAddData).forEach(
    k => !arrAddData[k] && arrAddData[k] !== undefined && delete arrAddData[k]
  );

  return service
    .rest('customerInvoiceAddress/add', arrAddData)
    .then(result => {
      const { data, status } = result;
      if (status !== true) {
        return false;
      }
      const { items } = data;
      // Commit to state
      commit(Type.SET_ADD_CUSTOMERS_INVOICE_ADDRESS, [items]);
      return true;
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 66 ~ addNewQuotation ~ e', e);
      return false;
    });
};

const deleteListCustomerInvoiceAddress = async ({ commit }, value) => {
  if (value.length <= 0) {
    return false;
  }

  return service
    .rest('customerInvoiceAddress/deleteList', value)
    .then(result => {
      const { data, status } = result;
      console.log('result: ', result);
      if (status !== true) {
        return false;
      }
      const { items } = data;
      // Commit to news state
      commit(Type.SET_DELETE_CUSTOMERS_INVOICE_ADDRESS, items);
      return true;
    })
    .catch(e => {
      console.log(
        '🚀 ~ file: actions.js ~ line 120 ~ deleteCustomerInvoiceAddressList.rest ~ e',
        e
      );
      return false;
    });
};

const saveEditCustomerInvoiceAddress = async ({ commit }, newData) => {
  const { addressId, customerId } = newData;

  if (!addressId || !customerId) {
    return false;
  }
  // Convert data
  return service
    .rest('customerInvoiceAddress/update', newData)
    .then(result => {
      const { data, status } = result;
      if (status !== true) {
        return false;
      }
      const { items } = data;
      // Commit to news state
      commit(Type.SET_CUSTOMERS_INVOICE_ADDRESS_UPDATE, items);
      return true;
    })
    .catch(e => {
      console.log(
        '🚀 ~ file: actions.js ~ line 66 ~ saveEditCustomerAddress.rest ~ e',
        e
      );
      return false;
    });
};


export {
  getAllCustomerIncvoiceAddress,
  saveAddCustomerInvoiceAddress,
  deleteListCustomerInvoiceAddress,
  saveEditCustomerInvoiceAddress
};
