import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';

const state = {
    customerDetail: {}
};

const mutations = {
    [Type.GET_BY_ID](state, value) {
      state.customerDetail = value;
    },
    [Type.EDIT](state, value) {
      state.customerDetail = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};