import Service from 'core/service';
import * as Type from 'modules/dashboard/store/types';
import store from 'store';

const services = new Service()



const getCustomerSignUpForDashboard = async ({ commit }, value) => {
    const {
        startDate = '',
        endDate = ''
    } = value;

    const params  = {};
    if (startDate && endDate) {
        params.startDate = startDate,
        params.endDate = endDate;
      }
    return await services.rest('customer/getCustomerSignUpForDashboard', params)
    .then(result => {
        commit(Type.GET_CUSTOMERS_SIGN_UP, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

const getTopActiveCustomerMonthly = async ({ commit }, value) => {
    const {
        startDate = '',
    } = value;

    const params  = {};
    if (startDate) {
        params.startDate = startDate;
    }
    return await services.rest('order/getTopActiveCustomers', params)
    .then(result => {
        commit(Type.GET_ACTIVE_CUSTOMER_MONTHLY, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};


const getSuppliersSignUpForDashboard = async ({ commit }, value) => {
    const {
        startDate = '',
        endDate = ''
    } = value;

    const params  = {};
    if (startDate && endDate) {
        params.startDate = startDate,
        params.endDate = endDate;
      }
    return await services.rest('supplier/getSuppliersSignUpForDashboard', params)
    .then(result => {
        commit(Type.GET_SUPPLIERS_SIGN_UP, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

const getOrdersForDashboard = async ({ commit }, value) => {
    const {
        startDate = '',
        endDate = ''
    } = value;

    const params  = {};
    if (startDate && endDate) {
        params.startDate = startDate,
        params.endDate = endDate;
      }
    return await services.rest('order/getOrdersForDashboard', params)
    .then(result => {
        commit(Type.GET_ORDERS, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

const getActiveCustomersForDashboard = async ({ commit }, value) => {
    const {
        startDate = '',
        endDate = ''
    } = value;

    const params  = {};
    if (startDate && endDate) {
        params.startDate = startDate,
        params.endDate = endDate;
      }
    return await services.rest('customerLoginHistory/getActiveCustomersForDashboard', params)
    .then(result => {
        commit(Type.GET_ACTIVE_CUSTOMERS, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

const getOrdersChartDashboard = async ({ commit }, value) => {
    const {
        startDate = '',
        endDate = ''
    } = value;

    const params  = {};
    if (startDate && endDate) {
        params.startDate = startDate,
        params.endDate = endDate;
      }
    return await services.rest('order/getOrdersChartDashboard', params)
    .then(result => {
        commit(Type.GET_ORDERS_CHART, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

const getTopCustomers = async ({ commit }, value) => {
    const {
        startDate = '',
        endDate = ''
    } = value;

    const params  = {};
    if (startDate && endDate) {
        params.startDate = startDate,
        params.endDate = endDate;
      }
    return await services.rest('order/getTopCustomers', params)
    .then(result => {
        commit(Type.GET_TOP_CUSTOMERS, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

const getTopSuppliers = async ({ commit }, value) => {
    const {
        startDate = '',
        endDate = ''
    } = value;

    const params  = {};
    if (startDate && endDate) {
        params.startDate = startDate,
        params.endDate = endDate;
      }
    return await services.rest('order-detail/getTopSuppliers', params)
    .then(result => {
        commit(Type.GET_TOP_SUPPLIERS, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

const getRevenueOfCategory = async ({ commit }, value) => {
    const {
        startDate = '',
        endDate = ''
    } = value;

    const params  = {};
    if (startDate && endDate) {
        params.startDate = startDate,
        params.endDate = endDate;
      }
    return await services.rest('order-detail/getRevenueOfCategory', params)
    .then(result => {
        commit(Type.GET_REVENUE_OF_CATEGORY, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

const getProfit = async ({ commit }, value) => {
    const {
        startDate = '',
        endDate = ''
    } = value;

    const params  = {};
    if (startDate && endDate) {
        params.startDate = startDate,
        params.endDate = endDate;
      }
    return await services.rest('order-detail/getProfit', params)
    .then(result => {
        commit(Type.GET_PROFIT, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

/* ******* Supplier Dashboard ********* */
const getOrdersForSupplierDashboard = async ({ commit }, value) => {
    const { supplierId } = await store.dispatch('auth/getMe');
    const {
        startDate = '',
        endDate = '',
        valueSupplierId = ''
    } = value;
    const params  = {
        supId: valueSupplierId != '' ? valueSupplierId : supplierId
    };
    if (startDate && endDate) {
        params.startDate = startDate,
        params.endDate = endDate;
      }
    return await services.rest('orderDetailSupplier/getOrdersForSupplierDashboard', params)
    .then(result => {
        commit(Type.GET_SUPPLIER_ORDERS, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

const getOrdersChartSupplierDashboard = async ({ commit }, value) => {
    const { supplierId } = await store.dispatch('auth/getMe');
    const {
        startDate = '',
        endDate = ''
    } = value;

    const params  = {
        supId: supplierId
    };
    if (startDate && endDate) {
        params.startDate = startDate,
        params.endDate = endDate;
      }
    return await services.rest('orderDetailSupplier/getOrdersChartSupplierDashboard', params)
    .then(result => {
        commit(Type.GET_SUPPLIER_ORDERS_CHART, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

const getProductsSalesForSupplierDashboard = async ({ commit }, value) => {
    const { supplierId } = await store.dispatch('auth/getMe');
    const {
        startDate = '',
        endDate = ''
    } = value;

    const params  = {
        supId: supplierId
    };
    if (startDate && endDate) {
        params.startDate = startDate,
        params.endDate = endDate;
      }
    return await services.rest('orderDetailSupplier/getProductsSalesForSupplierDashboard', params)
    .then(result => {
        commit(Type.GET_SUPPLIER_PRODUCTS_SALES, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

export {
    getCustomerSignUpForDashboard,
    getSuppliersSignUpForDashboard,
    getOrdersForDashboard,
    getActiveCustomersForDashboard,
    getOrdersChartDashboard,
    getTopCustomers,
    getTopSuppliers,
    getRevenueOfCategory,
    getProfit,
    getOrdersForSupplierDashboard,
    getOrdersChartSupplierDashboard,
    getProductsSalesForSupplierDashboard,
    getTopActiveCustomerMonthly
};