import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
import moment from 'moment';

const state = {
  totalCustomers: 0,
  customerDataChart: [],
  customersSignUpTotal: 0,
  customersSignUp: [],
  totalSuppliersSignUp: 0,
  suppliersSignUp: [],
  totalOrders: 0,
  orders: [],
  totalActiveCustomers: 0,
  activeCustomers: [],
  totalOrdersChart: 0,
  orderDataChart: [],
  topCustomers: [],
  topSuppliers: [],
  revenueOfCategoryDataChart: [],
  totalProfit: 0,
  profitDataChart: [],
  totalSalesDataChart: [],

  /* ******** Supplier Dashboard *********** */
  totalOrdersSupplier: 0,
  supplierOrders: [],
  totalSalesDataChartSupplier: [],
  totalOrdersChartSupplier: 0,
  supplierOrderDataChart: [],
  totalOrdersSupplierStatusIs_2: 0,
  totalOrdersStatusIs_2_DataChartSupplier: [],
  totalProductsSales: 0,
  totalProductsSalesDataChart: [],
  
  /* ********** Customer Dashboard ************* */
  activeCustomerMonthlyDataChart: []
};

const mutations = {
  async [Type.GET_CUSTOMER_CHART](state, value) {
    state.totalCustomers = value.totalCustomer;
    state.customerDataChart = value.data;
  },
  async [Type.GET_CUSTOMERS_SIGN_UP](state, value) {
    state.customersSignUpTotal = value.totalRows;
    state.customersSignUp = value.rows;
  },
  async [Type.GET_SUPPLIERS_SIGN_UP](state, value) {
    state.totalSuppliersSignUp = value.totalRows;
    state.suppliersSignUp = value.rows;
  },
  async [Type.GET_ORDERS](state, value) {
    state.totalOrders = value.totalRows;
    state.orders = value.rows;
    // Total Sales Chart
    const yearDefault = moment().year();
    state.totalSalesDataChart = [];
    for (let i = 1; i <= 12; i++ ) {
        let total = 0;
        value.rows.map(item => {
            let month = 1 + moment(item.createdAt).month();
            let year = moment(item.createdAt).year();
            (parseInt(month) === i && year === yearDefault) 
            ? total += item.totalSalePrice : total
        });
        state.totalSalesDataChart.push(total)
    }
  },
  async [Type.GET_ACTIVE_CUSTOMERS](state, value) {
    state.totalActiveCustomers = value.totalRows;
    state.activeCustomers = value.rows;
  },
  async [Type.GET_ORDERS_CHART](state, value) {
    state.totalOrdersChart = value.totalOrder;
    state.orderDataChart = value.result;
  },
  async [Type.GET_TOP_CUSTOMERS](state, value) {
    state.topCustomers = value.rows;
  },
  async [Type.GET_TOP_SUPPLIERS](state, value) {
    state.topSuppliers = value.rows;
  },
  async [Type.GET_REVENUE_OF_CATEGORY](state, value) {
    state.revenueOfCategoryDataChart = value.rows;
  },
  async [Type.GET_PROFIT](state, value) {
    state.totalProfit = value.totalProfit;
    state.profitDataChart = value.rows;
  },

  /* ******** Supplier Dashboard *********** */
  async [Type.GET_SUPPLIER_ORDERS](state, value) {
    state.totalOrdersSupplier = value.totalRows;
    state.supplierOrders = value.rows;
    // Total Sales Chart
    const yearDefault = moment().year();
    state.totalSalesDataChartSupplier = [];
    state.totalOrdersStatusIs_2_DataChartSupplier = [];
    state.totalProductsSalesDataChart = [];
    for (let i = 1; i <= 12; i++ ) {
        let total = 0;
        let ordersTotalStatusIs_2 = 0;
        let totalProductsSales = 0;
        value.rows.map(item => {
            let month = 1 + moment(item.createdAt).month();
            let year = moment(item.createdAt).year();

            if (parseInt(month) === i && year === yearDefault) {
              total += item.totalPrice;
              if (Number(item.statusCode) == 2) {
                ordersTotalStatusIs_2 += 1;
              };
              totalProductsSales += item.quantity;
            }
        });
        state.totalSalesDataChartSupplier.push(total);
        state.totalOrdersStatusIs_2_DataChartSupplier.push(ordersTotalStatusIs_2);
        state.totalProductsSalesDataChart.push(totalProductsSales);
    }
  },
  async [Type.GET_SUPPLIER_ORDERS_CHART](state, value) {
    state.totalOrdersChartSupplier = value.totalOrder;
    state.supplierOrderDataChart = value.result;
    const ordersTotalStatusIs_2 = value.result.filter(item => Number(item.statusCode) == 2);
    state.totalOrdersSupplierStatusIs_2 = ordersTotalStatusIs_2[0].total;
  },
  async [Type.GET_SUPPLIER_PRODUCTS_SALES](state, value) {
    state.totalProductsSales = value.rows.totalProductsSales;
  },
  async [Type.GET_ACTIVE_CUSTOMER_MONTHLY](state, value) {
    console.log(value);
    state.activeCustomerMonthlyDataChart = value.data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
