export const SET_ALL_ORDER = 'SET_ALL_ORDER';
export const SET_ALL_ORDER_SUPPLIER = 'SET_ALL_ORDER_SUPPLIER';
export const SET_ALL_CUSTOMER_ORDER = 'SET_ALL_CUSTOMER_ORDER';
export const SET_ORDER_LOCAL = 'SET_ORDER_LOCAL';
export const SET_ORDER_ADD = 'SET_ORDER_ADD';
export const SET_ORDER_UPDATE = 'SET_ORDER_UPDATE';
export const SET_PARTNER_UPDATE_LOCAL = 'SET_PARTNER_UPDATE_LOCAL';
export const SET_DELETE_ORDER = 'SET_ORDER_ORDER';
export const SET_DELETE_ORDER_LOCAL = 'SET_DELETE_ORDER_LOCAL';
export const SET_ALL_ORDER_DATA_EXPORT_EXCEL = 'SET_ALL_ORDER_DATA_EXPORT_EXCEL';
