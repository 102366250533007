import Service from 'core/service';
import * as Type from 'modules/common/store/types';

const services = new Service();

const actGetCommonByCode = async ({ commit, state }, comCd) => {
  if (!comCd) {
    return [];
  }
  if (state.commonMap && state.commonMap.hasOwnProperty(comCd)) {
    return state.commonMap[comCd];
  }

  try {
    const {data} = await services.rest('common/getCommonsByCode', { comCode:comCd });
    if (!data ) {
      return [];
    }
    commit(Type.SET_COM_CODE_MAP, { code: comCd, value: data.items });
    return data.items;
  } catch (error) {
    return [];
  }
};

const getAllCommons = async ({ commit }, value) => {
  const { name = '', comCode = '', numberList = 1 } = value;

  const params = {};

  if (name) {
    params.name = name;
  }
  if (comCode) {
    params.comCode = comCode;
  }

  const data = await services.rest('common/getListPaging', params);

  if (data) {
    commit(Type.SET_QUO_CANCEL_REASON_MAP, data);
    if (numberList == 1) commit(Type.GET_ALL, data);
    if (numberList == 2) commit(Type.GET_ALL_2ND, data);
  } else {
    console.log('error');
  }
};

const saveCommon = async ({ commit }, value) => {
  const allCommon = await getListCommons();
  const checkExist = await allCommon.find(element => element.code === value.code && element.comCode === value.comCode);

  if (!checkExist) {
    const data = {
      name: value.name,
      code: value.code,
      description: value.description,
      comCode: value.comCode,
      content: value.content
    };
    return await services
      .rest('common/setList', {
        items: {
          $add: [data]
        }
      })
      .then(rlt => {
        const { items } = rlt;
        const { $rltAdd } = items;
        commit(Type.SAVE, $rltAdd);
        return {
          type: true,
          message: 'success'
        };
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
        return {
          type: false,
          message: 'error'
        };
      });
  } else {
    return {
      type: false,
      message: 'Item already exist'
    };
  }
};

const editCommon = async ({ commit }, value) => {
  if (value.oldNameEdit.trim() !== value.data.code.trim()) {
    let allCommon = await getListCommons();
    const checkExist = await allCommon.find(element => element.code == value.data.code && element.comCode === value.data.comCode);
    if (checkExist) {
      return {
        type: false,
        message: 'Item already exist'
      };
    }
  }

  if (value.id) {
    const data = {
      id: value.id,
      name: value.data.name,
      code: value.data.code,
      description: value.data.description,
      comCode: value.data.comCode,
      content: value.data.content
    };
    return await services
      .rest('common/setList', {
        items: {
          $update: [data]
        }
      })
      .then(rlt => {
        const { items } = rlt;
        const { $rltUpdate } = items;
        // Commit to news state
        commit(Type.EDIT, $rltUpdate[0]);
        return {
          type: true,
          message: 'success'
        };
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
        return {
          type: false,
          message: 'error'
        };
      });
  }
};

const deleteCommon = async ({ commit }, value) => {
  if (value.row.id) {
    return await services
      .rest('common/setList', {
        items: {
          $delete: [value.row]
        }
      })
      .then(rlt => {
        const { items } = rlt;
        const { $rltDelete } = items;
        // Commit to news state
        commit(Type.DELETE, $rltDelete);
        return {
          type: true,
          message: 'success'
        };
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
        return {
          type: false,
          message: 'error'
        };
      });
  }
};

const deleteCommons = async ({ commit }, value) => {
  if (value.length > 0) {
    return await services
      .rest('common/setList', {
        items: {
          $delete: value
        }
      })
      .then(rlt => {
        const { items } = rlt;
        const { $rltDelete } = items;
        // Commit to news state
        commit(Type.DELETE, $rltDelete);
        return {
          type: true,
          message: 'success'
        };
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
        return {
          type: false,
          message: 'error'
        };
      });
  }
};

const getListCommons = async () => {
  const data = await services
    .rest('common/getListPaging', {})
    .then(res => {
      return res.rows;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
};

export { actGetCommonByCode, editCommon, getAllCommons, saveCommon, deleteCommon, deleteCommons };
