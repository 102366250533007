<template>
  <div class="tables-basic" v-can="'collaborator.read'">
    <Widget>
      <!-- Button Action (S) -->
      <el-row :gutter="24">
        <el-col :span="8">
          <div class="grid-content">
            <el-input clearable size="medium" class="input" v-model="formSearch.name" placeholder="Input Name" /></div
        ></el-col>
        <el-col :span="4"
          ><div class="grid-content">
            <el-row>
              <el-button size="medium" type="info" @click="onClickSearch()"> <i class="el-icon-search"></i>&nbsp; {{ $t('src.modules.collaborator.index.btnSearch') }}</el-button>
            </el-row>
          </div></el-col
        >
        <el-col :span="12"
          ><div class="grid-content">
            <el-row type="flex" justify="end">
              <div class="main-actions">
                <el-button v-can="'collaborator.add'" size="medium" type="primary" @click="onClickShowAddPopup()"
                  ><i class="el-icon-circle-plus-outline"></i>&nbsp;{{ $i18n.t('src.modules.collaborator.index.btnAdd') }}</el-button
                >
              </div>
              <div class="main-actions">
                <el-button v-can="'collaborator.delete'" size="medium" type="danger" :disabled="collaboratorSelectedList.length > 0 ? false : true" @click.native="onClickDeleteData()"
                  ><i class="el-icon-delete"></i>&nbsp;{{ $i18n.t('src.modules.collaborator.index.btnDelete') }}</el-button
                >
              </div>
            </el-row>
          </div></el-col
        >
      </el-row>

      <el-row>
        <el-table
          v-loading="isLoadingTable"
          ref="collaboratorTable"
          :data="pagedTableData"
          height="600"
          :default-sort="{ prop: 'updatedAt', order: 'descending' }"
          style="width: 100%; min-height: 500px"
        >
          <!-- Check box (S) -->
          <el-table-column prop="id" fixed="left" width="100" :render-header="renderHeader">
            <template slot-scope="scope">
              <el-checkbox v-model="selectedIds[scope.row.id]" @change="handleCheckChange(scope.row.id, selectedIds[scope.row.id], scope.row)" />
            </template>
          </el-table-column>
          <!-- Check box (E) -->
          <el-table-column fixed="left" prop="avatar" :label="$i18n.t('src.modules.collaborator.index.avatar')" width="200">
            <template slot-scope="scope">
              <el-image style="width: auto; height: 75px" :src="getAvatar(scope.row.avatar)" fit="scale-down"></el-image>
            </template>
          </el-table-column>
          <el-table-column fixed="left" prop="name" :label="$i18n.t('src.modules.collaborator.index.name')" width="300" sortable>
            <template slot-scope="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>

          <el-table-column prop="refCode" :label="$i18n.t('src.modules.collaborator.index.refCode')" width="300" sortable>
            <template slot-scope="scope">
              {{ scope.row.refCode }}
            </template>
          </el-table-column>

          <el-table-column prop="phone" :label="$i18n.t('src.modules.collaborator.index.phone')" width="300" sortable>
            <template slot-scope="scope">
              {{ scope.row.phone }}
            </template>
          </el-table-column>

          <el-table-column prop="address" :label="$i18n.t('src.modules.collaborator.index.address')" width="300" sortable>
            <template slot-scope="scope">
              {{ scope.row.address }}
            </template>
          </el-table-column>

          <el-table-column prop="email" :label="$i18n.t('src.modules.collaborator.index.email')" width="300" sortable>
            <template slot-scope="scope">
              {{ scope.row.email }}
            </template>
          </el-table-column>

          <el-table-column prop="isActive" :label="$i18n.t('src.modules.collaborator.index.isActive')" width="300" sortable>
            <template slot-scope="scope">
              <el-button plain size="mini" disabled v-if="scope.row.isActive === 1" type="primary">ACTIVE</el-button>
              <el-button plain size="mini" disabled v-if="scope.row.isActive === 0" type="info">INACTIVE</el-button>
            </template>
          </el-table-column>

          <el-table-column prop="createdAt" :label="$i18n.t('src.modules.collaborator.index.createdAt')" width="150" sortable>
            <template slot-scope="scope">
              <el-tag type="info" effect="light" style="font-weight: bold"> {{ scope.row.createdAt | formattedDate }} </el-tag>
            </template>
          </el-table-column>

          <el-table-column fixed="right" :label="$i18n.t('src.modules.collaborator.index.action')" width="200">
            <template slot-scope="scope">
              <el-row>
                <el-col :span="24">
                  <el-button v-can="'collaborator.edit'" type="primary" icon="el-icon-edit" circle size="mini" @click="handelEditCollaborator(scope)" class="item btn-blue-sm"
                    >{{ $i18n.t('common.label.edit') }}
                  </el-button>
                </el-col>
                <el-col :span="24" v-show="scope.row.orderId && scope.row.orderId > 0">
                  <el-button v-can="'collaborator.edit'" class="item btn-black-sm" type="info" size="mini" @click="$router.push(`/app/order/edit/${scope.row.orderId}`)">
                    <i class="el-icon-info" /> {{ $i18n.t('common.label.showOrderDetail') }}
                  </el-button>
                </el-col>
                <el-col :span="24">
                  <el-button v-can="'collaborator.delete'" type="danger" icon="el-icon-delete" circle size="mini" @click="onClickRemove(scope)" class="item btn-red-sm"
                    >{{ $i18n.t('common.label.delete') }}
                  </el-button>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col
          ><div class="grid-content">
            <!--  Pagination (S) -->
            <vue-paging-crazy
              v-model="currentPageChange"
              :page-count="pageCount"
              :page-range="itemsPerPageChange"
              :data-count="totalRowsCollaborators"
              :label-options="{
                showing: $i18n.t('common.paging.label.showing'),
                to: $i18n.t('common.paging.label.to'),
                of: $i18n.t('common.paging.label.of'),
                entries: $i18n.t('common.paging.label.entries'),
                prevText: $i18n.t('common.paging.label.prevText'),
                nextText: $i18n.t('common.paging.label.nextText')
              }"
              class="pagination"
            />
            <!--  Pagination (E) -->
          </div></el-col
        >
      </el-row>
    </Widget>
    <UploadFile
      :show="showUploadFileAvatar"
      @onCloseUploadFile="handleOnCloseUploadFile()"
      @onSelectedFiles="handleOnSelectedFiles"
      :category-options="categoryList"
      :module-options="moduleOptions"
      :is-multiple="false"
      :accept="'image/*'"
    />
    <!-- dialog add -->
    <el-dialog width="60%" :title="titlePopup" :visible.sync="collaboratorDialogVisible" :before-close="handleClose" :close-on-click-modal="false" append-to-body>
      <el-form :model="formCollaborators" ref="formCollaborators" :rules="rulesForm" label-width="200px" class="demo-ruleForm">
        <el-form-item :label="$i18n.t('src.components.modal.collaborator.form.name')" prop="name">
          <el-input v-model="formCollaborators.name" type="text" :placeholder="$i18n.t('src.components.modal.collaborator.placeholder.name')"></el-input>
        </el-form-item>
        <!-- avatar (S) -->
        <el-form-item :label="$i18n.t('src.components.modal.collaborator.form.avatar')" prop="avatar">
          <el-row>
            <el-col>
              <el-button type="primary" icon="el-icon-circle-plus-outline" size="mini" @click="handleOpenPopupUploadFile()"></el-button>
              <el-button v-if="showAvatar" type="danger" icon="el-icon-delete" size="mini" @click="handleDeleteIcon"></el-button>
            </el-col>
          </el-row>
          <el-row v-if="showAvatar">
            <el-col>
              <vue-select-image :h="'200px'" :w="'200px'" ref="iconListAddForm" :data-images="dataAvatar" @onselectimage="onSelectAvatar"> </vue-select-image>
            </el-col>
          </el-row>
        </el-form-item>
        <!-- avatar (E) -->
        <el-form-item v-show="this.formMode == 'edit'" :label="$i18n.t('src.components.modal.collaborator.form.refCode')" prop="refCode">
          <el-input v-model="formCollaborators.refCode" type="text" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.components.modal.collaborator.form.phone')" prop="phone">
          <el-input v-model="formCollaborators.phone" type="text" :placeholder="$i18n.t('src.components.modal.collaborator.placeholder.phone')"></el-input>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.components.modal.collaborator.form.address')" prop="address">
          <el-input v-model="formCollaborators.address" type="text" :placeholder="$i18n.t('src.components.modal.collaborator.placeholder.address')"></el-input>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.components.modal.collaborator.form.email')" prop="email">
          <el-input v-model="formCollaborators.email" type="text" :placeholder="$i18n.t('src.components.modal.collaborator.placeholder.email')"></el-input>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.components.modal.collaborator.form.isActive')" prop="isActive">
          <el-switch v-model="formCollaborators.isActive" :active-value="Number(1)" :inactive-value="Number(0)"> </el-switch>
        </el-form-item>
        <el-form-item>
          <el-button class="float-right mr-2" type="primary" @click="onClickPopupSaveCollaborator">{{ btnCollaboratorDialog }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import reduce from 'lodash/reduce';
import { preventSpace } from 'core/helpers';
import { TEXT_NOT_FOUND } from 'core/constants';
import { UPLOAD_FILE_MODULE } from 'core/constants';
import without from 'lodash/without';
import UploadFile from 'components/Upload';
import { validateEmail, validatePhone } from 'core/helpers';

export default {
  name: 'Collaborator',
  components: { UploadFile },
  data() {
    return {
      editorConfig: {
        language: 'en'
      },
      props: { multiple: true },
      S3_STORE_ENDPOINT: process.env.VUE_APP_S3_STORE_ENDPOINT,
      isLoadingTable: true,
      isFormChange: false,
      collaboratorDialogVisible: false,
      collaboratorSelectedList: [],
      formMode: 'add',
      formCollaboratorsDefault: {
        name: '',
        phone: '',
        avatar: '',
        address: '',
        email: '',
        isActive: 1
      },
      formCollaborators: {
        name: '',
        phone: '',
        avatar: '',
        address: '',
        email: '',
        isActive: 1
      },
      formCollaboratorsOld: {},
      dialogVisible: false,
      currentPageChange: 1,
      itemsPerPageChange: 5,
      showUploadFile: false,
      showUploadThumbnail: false,
      selectedSupplier: '',
      // Search Form
      formSearch: {
        name: '',
        currentPage: 1
      },

      rulesForm: {
        name: [{ required: true, message: this.$i18n.t('src.components.modal.collaborator.placeholder.name'), trigger: 'blur' }],
        phone: [{ required: true, message: this.$i18n.t('src.components.modal.collaborator.placeholder.phone'), trigger: 'blur' }, { validator: validatePhone, trigger: 'blur' },
          {
            min: 10,
            max: 11,
            message: 'Phone Number Length should be 10 to 11',
            trigger: 'blur'
          }],
        address: [{ required: true, message: this.$i18n.t('src.components.modal.collaborator.placeholder.address'), trigger: 'blur' }],
        email: [{ required: true, message: this.$i18n.t('src.components.modal.collaborator.placeholder.email'), trigger: 'blur' }, { validator: validateEmail, trigger: 'blur' }]
      },

      // childCategories
      endPoint: process.env.NEXT_APP_EI_ENDPOINT,
      getRowKeys(row) {
        return row.id;
      },
      checkAll: false,
      isIndeterminate: false,
      selectedIds: {},
      showUploadFileAvatar: false,
      categoryList: [{
        value: 1,
        label: 'Collaborator'
      }],
      moduleOptions: [
        {
          value: UPLOAD_FILE_MODULE.COLLABORATOR,
          label: UPLOAD_FILE_MODULE.COLLABORATOR
        }
      ],
      fileListSelectedAvatar: []
    };
  },
  computed: {
    ...mapState('collaborator', ['collaborators', 'totalRowsCollaborators']),
    ...mapState('auth', ['user']),

    dataAvatar() {
      if (this.collaboratorDialogVisible) {
        const avatar = cloneDeep(this.formCollaborators.avatar) || [];
        return avatar.map(item => {
          item.src = this.S3_STORE_ENDPOINT + '/' + item.filePath;
          return item;
        });
      } else {
        return [];
      }
    },

    showAvatar() {
      if (this.dataAvatar) {
        if ((this.dataAvatar && this.dataAvatar.length === 1 && this.dataAvatar[0] === '') || this.dataAvatar.length === 0) return false;
        return true;
      } else {
        return false;
      }
    },

    getRefCode() {
      return this.user.refCode || '';
    },

    getSaleId() {
      return this.user.id || '';
    },

    // group user by roleId
    groupUserByRoleId() {
      return this.users.reduce((objectsByKeyValue, obj) => {
        const value = obj['roleId'];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat({
          value: obj.id,
          label: obj.fistName ? obj.fistName + ' ' : '' + (obj.lastName || '')
        });
        return objectsByKeyValue;
      }, {});
    },

    roleUserObj() {
      return this.roles.reduce((acc, ele) => {
        if (typeof this.groupUserByRoleId[ele.id] !== 'undefined') {
          acc.push({
            value: ele.name,
            label: ele.name,
            children: this.groupUserByRoleId[ele.id]
          });
        }
        return acc;
      }, []);
    },

    textNotFound() {
      return localStorage.getItem('user-language') === 'vi-VN' ? TEXT_NOT_FOUND.vi_VN : TEXT_NOT_FOUND.en_US;
    },

    pageCount() {
      return Math.ceil(this.totalRowsCollaborators / this.itemsPerPageChange);
    },

    titlePopup() {
      switch (this.formMode) {
        case 'add':
          return this.$i18n.t('src.modules.collaborator.index.form_add');
        case 'edit':
          return this.$i18n.t('src.modules.collaborator.index.form_edit');
        default:
          return this.$i18n.t('src.modules.collaborator.index.form_add');
      }
    },

    btnCollaboratorDialog() {
      switch (this.formMode) {
        case 'add':
          return this.$i18n.t('common.label.add');
        case 'edit':
          return this.$i18n.t('common.label.edit');
        default:
          return this.$i18n.t('common.label.add');
      }
    },

    // Paging
    pagedTableData() {
      return this.collaborators;
    },

    collaboratorImages() {
      if (this.collaboratorDialogVisible) {
        const images = cloneDeep(this.formCollaborators.images) || [];
        return images.map((item) => {
          item.src = this.S3_STORE_ENDPOINT + '/' + item.filePath;
          return item;
        });
      } else {
        return [];
      }
    }
  },

  watch: {
    currentPageChange: async function () {
      this.isLoadingTable = true;
      this.formSearch.currentPage = this.currentPageChange;
      await this.getAllCollaborators(this.formSearch);
      this.isLoadingTable = false;
    }
  },

  async created() {
    this.isLoadingTable = true;
    await this.getAllCollaborators({ refCode: this.getRefCode, ...this.formSearch });
    this.isLoadingTable = false;
  },

  mounted() {},

  methods: {
    ...mapActions('collaborator', ['getAllCollaborators', 'editCollaborator', 'addCollaborator', 'deleteCollaborator']),
    // ...mapActions('user', ['getAllUsers']),

    getAvatar(url) {
      let images = 'Global/1/1/no-image.jpg';
      if (url) {
        images = url;
      }
      return this.S3_STORE_ENDPOINT + '/' + images;
    },

    onSelectAvatar(value) {
      this.fileListSelectedAvatar = value;
    },

    handleDeleteIcon() {
      this.formCollaborators.avatar = '';
      this.$refs.iconListAddForm.removeFromMultipleSelected();
    },

    handleOnCloseUploadFile() {
      this.showUploadFileAvatar = false;
    },

    handleOnSelectedFiles(arrObj) {
      this.formCollaborators.avatar = [arrObj];
      this.showUploadFileAvatar = false;
    },

    // avatar
    handleOpenPopupUploadFile() {
      this.showUploadFileAvatar = true;
    },

    getImages(link) {
      return link ? this.S3_STORE_ENDPOINT + '/' + link : this.S3_STORE_ENDPOINT + '/Global/1/1/no-image.jpg';
    },

    getSupplierName(id) {
      return (this.suppliersMap[id] && this.suppliersMap[id].name) || this.textNotFound;
    },

    renderHeader(h) {
      return h('span', [
        h('el-checkbox', {
          on: {
            change: this.handleCheckAllChange
          },
          props: {
            value: this.checkAll,
            indeterminate: this.isIndeterminate
          }
        })
      ]);
    },

    handleCheckAllChange(val) {
      this.checkAll = val;
      this.isIndeterminate = false;
      if (val) {
        this.selectedIds = reduce(this.collaborators, (acc, { id }) => ({ ...acc, [id]: true }), {});
        this.collaboratorSelectedList = this.collaborators;
      } else {
        const that = this;
        Object.keys(this.selectedIds).forEach(function (key) {
          that.selectedIds[key] = false;
        });
        that.$refs.collaboratorTable.clearSelection();
        this.collaboratorSelectedList = [];
      }
    },

    handleCheckChange(id, val, data) {
      if (val) {
        const collaboratorSelect = this.collaborators.find((obj) => obj.id === id) || data;
        this.collaboratorSelectedList.push(collaboratorSelect);
      } else {
        const objIndex = this.collaboratorSelectedList.findIndex((obj) => obj.id === id);
        this.collaboratorSelectedList = cloneDeep(this.collaboratorSelectedList).splice(objIndex, 1);
      }

      this.checkAll = this.collaboratorSelectedList.length === this.collaborators.length;
      this.isIndeterminate = this.collaboratorSelectedList.length > 0 && this.collaboratorSelectedList.length < this.collaborators.length;
    },

    /** customize table style method **/
    headerRow({ rowIndex }) {
      if (rowIndex === 0) {
        return 'headerRow';
      }
    },
    /** customize table style method **/

    // someHandler
    handleFormChange() {
      if (!this.isFormChange) {
        this.isFormChange = true;
      }
    },

    // Table
    onClickShowAddPopup() {
      this.collaboratorDialogVisible = true;
      this.formMode = 'add';
    },

    toggleSelection(rows) {
      this.$nextTick(() => {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.collaboratorTable.toggleRowSelection(row);
          });
        }
      });
    },

    async onClickRemove(index) {
      this.$confirm('Do you want to remove collaborator')
        .then(async () => {
          const result = await this.deleteCollaborator([index.row]);
          if (result) {
            this.$notify({
              title: 'Success',
              message: 'Delete collaborator success',
              type: 'success'
            });
          } else {
            this.$notify({
              title: 'Error',
              message: 'Can not remove collaborator',
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },

    onClickDeleteData() {
      if (this.collaboratorSelectedList.length > 0) {
        this.$confirm('Do you want to remove collaborator rows')
          .then(async () => {
            const result = await this.deleteCollaborator(this.collaboratorSelectedList);
            if (result) {
              this.$notify({
                title: 'Success',
                message: 'Delete collaborator rows Success',
                type: 'success'
              });
            } else {
              this.$notify({
                title: 'Error',
                message: 'Can not remove collaborator rows',
                type: 'error'
              });
            }
          })
          .catch(() => {});
      }
    },

    onClickPopupSaveCollaborator() {
      switch (this.formMode) {
        case 'add':
          this.onSaveAddCollaborator();
          break;
        case 'edit':
          this.onSaveEditCollaborator();
          break;
      }
    },

    async onSaveEditCollaborator() {
      this.$refs['formCollaborators'].validate(async (valid) => {
        if (valid) {
          try {
            this.$confirm('Do you want change ?')
              .then(async () => {
                const result = await this.editCollaborator({
                  oldData: this.formCollaboratorsOld,
                  newData: this.formCollaborators
                });
                if (result.status) {
                  this.formCollaboratorsOld = {};
                  this.$notify({
                    title: 'Success',
                    message: 'Edit collaborator Success',
                    type: 'success'
                  });
                  // await this.getAllCollaboratorsForManagement(this.formSearch);
                  this.collaboratorDialogVisible = false;
                } else {
                  this.$notify({
                    title: 'Error',
                    message: result.message,
                    type: 'error'
                  });
                }
              })
              .catch(() => {});
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.error'),
              message: this.$i18n.t('common.notify.message.add.failr', { name: 'row' }),
              type: 'error'
            });
          }
        } else {
          return false;
        }
      });
    },

    async onSaveAddCollaborator() {
      this.$refs['formCollaborators'].validate(async (valid) => {
        if (valid) {
          await this.addCollaborator({ ...this.formCollaborators, saleId: this.getSaleId }).then((result) => {
            if (result.status) {
              this.$notify({
                title: this.$i18n.t('common.notify.title.success'),
                message: this.$i18n.t('common.notify.message.success.add', {
                  item: localStorage.getItem('user-language') === 'vi-VN' ? 'Cộng tác viên' : 'Collaborator'
                }),
                type: 'success'
              });
              this.clearFormCollaborator();
              this.collaboratorDialogVisible = false;
            } else {
              this.$notify({
                title: this.$i18n.t('common.notify.title.error'),
                message: this.$i18n.t('common.notify.message.error.add', {
                  message: result.message
                }),
                type: 'error'
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    clearFormCollaborator() {
      this.formCollaborators = cloneDeep(this.formCollaboratorsDefault);
    },

    async onClickSearch() {
      this.currentPageChange = 1;
      preventSpace(this.formSearch);
      this.isLoadingTable = true;
      await this.getAllCollaborators(this.formSearch);
      this.isLoadingTable = false;
    },

    // handle close button
    handleClose(done) {
      var message = null;
      if (!isEqual(this.formCollaborators, this.formCollaboratorsDefault) && this.isFormChange) {
        message = this.$i18n.t('common.confirm.close.form.out', { name: 'collaborator' });
        this.$confirm(message)
          .then(() => {
            this.clearFormCollaborator();
            this.isFormChange = false;
            this.collaboratorDialogVisible = false;
            this.isCreateNew = false;
            done();
          })
          .catch(() => {});
      } else {
        this.clearFormCollaborator();
        this.isFormChange = false;
        this.collaboratorDialogVisible = false;
        this.isCreateNew = false;
      }
    },

    async onClickSaveCollaborator() {
      try {
        if (this.collaboratorSelectedList.length > 0) {
          const data = this.supplierSaveAddCollaborator(this.collaboratorSelectedList);
          if (data) {
            this.isAddCollaboratorLocal = false;
            this.$notify({
              title: this.$i18n.t('common.notify.success'),
              message: this.$i18n.t('common.notify.message.save.success', {
                name: 'List Collaborator'
              }),
              type: 'success'
            });
            this.handleCheckAllChange(false);
          } else {
            this.$notify({
              title: this.$i18n.t('common.notify.error'),
              message: this.$i18n.t('common.notify.message.save.fail', {
                name: 'List Collaborator'
              }),
              type: 'error'
            });
          }
        } else {
          this.$notify({
            title: this.$i18n.t('common.notify.error'),
            message: this.$i18n.t('common.notify.message.save.fail_null'),
            type: 'error'
          });
        }
      } catch (error) {
        this.$notify({
          title: this.$i18n.t('common.notify.error'),
          message: this.$i18n.t('common.notify.message.error.add', { name: 'row' }),
          type: 'error'
        });
      }
    },

    async handelEditCollaborator(index) {
      try {
        const avatar = index.row.avatar || '';
        const arrAvatar = without(
        avatar.split('|').map(src => {
          return src !== '' ? { filePath: src } : null;
        }),
        null
      );
        this.formMode = 'edit';
        this.formCollaborators = {
          id: index.row.id,
          name: index.row.name,
          phone: index.row.phone,
          avatar: arrAvatar,
          address: index.row.address,
          email: index.row.email,
          refCode: index.row.refCode,
          isActive: Number(index.row.isActive)
        };
        this.formNotificationTypesOld = cloneDeep(index.row);
      } finally {
        this.isFormChange = false;
        this.collaboratorDialogVisible = true;
      }
    }
  }
};
</script>
<style lang="scss">
.tabs-collaborator {
  .tabs .nav-item {
    transform: rotate(-90deg) !important;
    margin-left: -24px !important;
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
}
.tables-basic {
  font-weight: normal !important;
}

.card-header {
  padding: 0rem 0rem !important;
  margin-right: -21px !important;
}
.nav-pills .nav-link {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}
.tab-content.col {
  max-width: 90% !important;
}
</style>
<style src="../collaborator/index.scss" lang="scss" scoped />