import moment from 'moment';

import { readThree } from './helpers';

let filters = {
  formattedDateTime(time) {
    if (time) {
      return moment(time).format('DD/MM/YYYY hh:mm:ss'); // convert data type date time
    }
    return 'No Data';
  },

  formattedDate(time) {
    if (time) {
      return moment(time).format('DD/MM/YYYY'); // convert data type date
    }
    return 'No Data';
  },

  formattedNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  },

  formattedPrice(price) {
    if (price) return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' đ';
    return price;
  },

  formattedPercent(value) {
    if (value) return parseFloat(value).toFixed(2) + ' %';
    return value;
  },

  convertImageSrc(filePath) {
    if (filePath && filePath.indexOf('http://') !== 0 && filePath.indexOf('https://') !== 0) {
      return process.env.VUE_APP_S3_STORE_ENDPOINT + '/' + filePath;
    }
    return filePath;
  },

  truncate(text, length) {
    if (text && text.length > length) {
      return text.substr(0, length - 1) + '…';
    }
    return text;
  },

  convertNumberToWord(number) {
    const moneyUnit = [' triệu tỷ ', ' nghìn tỷ ', ' tỷ ', ' triệu ', ' nghìn', ''];
    let needZeroCount = number.toString().length % 3;
    if (needZeroCount !== 0) needZeroCount = 3 - needZeroCount;
    number = '0'.repeat(needZeroCount) + number;
    const devideNumber = [];
    for (let i = 0; i < number.length / 3; i++) {
      let c = number.substr(i * 3, 3);
      devideNumber.push(c);
    }
    let result = '';
    devideNumber.forEach((item, index) => {
      let c = readThree(item, index);
      if (c !== '') {
        c += moneyUnit[moneyUnit.length - devideNumber.length + index];
      }
      result += c;
    });
    const formatResult = result.trim().replace(/\s+/g, ' ');
    return formatResult.charAt(0).toUpperCase() + formatResult.slice(1);
  }
};

export default {
  filters,

  install(Vue) {
    let keys = Object.keys(filters);
    keys.forEach(name => Vue.filter(name, filters[name]));
  }
};
