import Service from 'core/service';
import * as Type from 'modules/customerDashboard/store/types';
 
const services = new Service()

// *** Customer Dashboard ***
const statisticCustomerTypes = async ({
    commit
}, value) => {
    const {
        startDate = '',
            endDate = ''
    } = value;

    const params = {};
    if (startDate && endDate) {
        params.fromDate = startDate,
            params.toDate = endDate;
    }
    return await services.rest('dashboard/customer/statisticByTypes', params)
        .then(result => {
            commit(Type.STATISTIC_CUSTOMER_TYPE, result);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

const statisticCustomerLevels = async ({
    commit
}, value) => {
    const {
        startDate = '',
        endDate = ''
    } = value;

    const params = {};
    if (startDate && endDate) {
        params.fromDate = startDate,
            params.toDate = endDate;
    }
    return await services.rest('dashboard/customer/statisticByLevels', params)
        .then(result => {
            commit(Type.STATISTIC_CUSTOMER_LEVEL, result);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

const statisticCustomerByChanel = async ({
    commit
}, value) => {
    const {
        startDate = '',
            endDate = ''
    } = value;

    const params = {};
    if (startDate && endDate) {
        params.fromDate = startDate,
            params.toDate = endDate;
    }
    return await services.rest('dashboard/customer/statisticCustomerByChanel', params)
        .then(result => {
            commit(Type.STATISTIC_CUSTOMER_CHANNEL, result);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

const getStatisticCustomerRegistered = async ({
    commit
}, value) => {
    const {
        startDate = '',
    } = value;

    const params = {};
    if (startDate) {
        params.startDate = startDate;
    }
    return await services.rest('dashboard/customer/statisticCustomerRegistered', params)
        .then(result => {
            console.log('🚀 ~ file: actions.js ~ line 92 ~ result', result)
            commit(Type.STATISTIC_CUSTOMER_REGISTERED, result);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

const getStatisticTopCustomer = async ({
    commit
}, value) => {
    const {
        startDate = '',
            endDate = ''
    } = value;

    const params = {};
    if (startDate && endDate) {
        params.fromDate = startDate,
            params.toDate = endDate;
    }
    return await services.rest('dashboard/customer/statisticTopCustomer', params)
        .then(result => {
            commit(Type.STATISTIC_TOP_CUSTOMER, result);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

const getTotalOfCusRegisteredByMonth = async ({
    commit
}, value) => {
    const {
        startDate = '',
    } = value;

    const params = {};
    if (startDate) {
        params.startDate = startDate;
    }
    return await services.rest('dashboard/customer/getTotalOfCusRegisteredByMonth', params)
        .then(result => {
            commit(Type.STATISTIC_CUSTOMER_VERIFIED, result);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

const getTotalOfCusActiveByMonth = async ({
    commit
}, value) => {
    const {
        startDate = '',
    } = value;

    const params = {};
    if (startDate) {
        params.startDate = startDate;
    }
    return await services.rest('dashboard/customer/getTotalOfCusActiveByMonth', params)
        .then(result => {
            commit(Type.STATISTIC_CUSTOMER_ACTIVE, result);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

const getTotalOfCustomerPaidByMonth = async ({
    commit
}, value) => {
    const {
        startDate = '',
            endDate = ''
    } = value;

    const params = {};
    if (startDate && endDate) {
        params.fromDate = startDate,
        params.toDate = endDate;
    }
    return await services.rest('dashboard/customer/getTotalOfCustomerPaidByMonth', params)
        .then(result => {
            commit(Type.STATISTIC_CUSTOMER_PAID, result);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

const statisticCustomerVerified = async ({ commit }, value) => {
    const {
        startDate = '',
        endDate = ''
    } = value;

    const params  = {};
    if (startDate && endDate) {
        params.fromDate = startDate,
        params.toDate = endDate;
      }
    return await services.rest('dashboard/customer/statisticByVerified', params)
    .then(result => {
        commit(Type.STATISTIC_CUSTOMER_VERIFIED, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

const getTotalOfCusRegisteredByDay = async ({
    commit
}, value) => {
    const {
        startDate = '',
        saleId = ''
    } = value;

    const params = {};
    if (startDate) {
        params.startDate = startDate;
    }
    if (saleId) {
        params.saleId = saleId;
    }
    return await services.rest('dashboard/customer/getTotalOfCusRegisteredOfSalesByDay', params)
        .then(result => {
            commit(Type.STATISTIC_CUSTOMER_VERIFIED_BY_DAY, result);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

export {
    statisticCustomerTypes,
    statisticCustomerLevels,
    statisticCustomerVerified,
    statisticCustomerByChanel,
    getStatisticCustomerRegistered,
    getStatisticTopCustomer,
    getTotalOfCusRegisteredByMonth,
    getTotalOfCusActiveByMonth,
    getTotalOfCustomerPaidByMonth,
    getTotalOfCusRegisteredByDay
};