export const SET_ALL_CUSTOMERS = 'SET_ALL_CUSTOMERS';
export const SET_ALL_CUSTOMERS_ADDRESS = 'SET_ALL_CUSTOMERS_ADDRESS';
export const SET_CUSTOMERS_LOCAL = 'SET_CUSTOMERS_LOCAL';
export const SET_CUSTOMERS_ADD = 'SET_CUSTOMERS_ADD';
export const SET_DELETE_CUSTOMERS = 'SET_DELETE_CUSTOMERS';
export const SET_DELETE_CUSTOMERS_LOCAL = 'SET_DELETE_CUSTOMERS_LOCAL';
export const SET_CUSTOMERS_UPDATE_LOCAL = 'SET_CUSTOMERS_UPDATE_LOCAL';
export const SET_CUSTOMERS_UPDATE = 'SET_CUSTOMERS_UPDATE';
export const TOTAL_COUNT_CUSTOMERS = 'TOTAL_COUNT_CUSTOMERS';
export const SET_CUSTOMERS_ADD_WITH_ACTIVE_MAIL = 'SET_CUSTOMERS_ADD_WITH_ACTIVE_MAIL';
export const GET_CUSTOMER_PROFILE = 'GET_CUSTOMER_PROFILE';
export const STATISTIC_CUSTOMER = 'STATISTIC_CUSTOMER';