<template>
  <div class="tables-basic">
    <h2 class="page-title"><span class="fw-semi-bold">Order Status</span></h2>
    <b-row>
      <b-col>
        <Widget>
          <!-- Table partner Data (S) -->
          <el-row :gutter="20">
            <el-table
              ref="partnerTable"
              :data="pagedTableData"
              height="600"
              :header-row-class-name="headerRow"
              stripe
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <!-- Check box (S) -->
              <el-table-column
                prop="id"
                label="Id"
                sortable
                width="180"
                style="text-align: center"
              >
              </el-table-column>
              <el-table-column prop="name" label="Name"></el-table-column>
              <el-table-column prop="createdAt" label="Created At">
                <template slot-scope="scope">
                  {{ formatDateTime(scope.row.createdAt) }}
                </template>
              </el-table-column>
              <el-table-column prop="updatedAt" label="Updated At">
                <template slot-scope="scope">
                  {{ formatDateTime(scope.row.updatedAt) }}
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <!-- Table partner Data (E) -->

          <el-row type="flex" justify="center">
            <el-col
              ><div class="grid-content">
                <el-pagination
                  class="pagination"
                  @current-change="changePage"
                  :current-page="currentPage"
                  :page-size="pageSize"
                  layout="prev, pager, next"
                  :total="totalStatusCnt"
                >
                </el-pagination></div
            ></el-col>
          </el-row>
          <!-- Paging (E) -->
        </Widget>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    return {
      editorConfig: {
        language: 'en'
      },

      // Paging
      currentPage: 1,
      pageSize: 10
    };
  },

  computed: {
    ...mapState('orderStatus', ['listStatus']),

    allStatus() {
      return [...this.listStatus];
    },

    // Paging
    pagedTableData() {
      return this.allStatus.slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },

    totalStatusCnt() {
      return this.allStatus.length || 0;
    }
  },

  async created() {
    await this.getAllStatus();
  },
  methods: {
    ...mapActions('orderStatus', ['getAllStatus']),

    /** common method */
    formatDate(strDate) {
      const date = new Date(strDate);
      return date.getDate() + ' - ' + date.getMonth() + ' - ' + date.getFullYear();
    },

    // Format date time
    formatDateTime(strDate) {
      const date = new Date(strDate);
      const day =
        date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate();
      const month =
        date.getMonth().toString().length === 1
          ? '0' + parseInt(date.getMonth() + 1)
          : date.getMonth();
      const minute =
        date.getMinutes().toString().length === 1
          ? '0' + date.getMinutes()
          : date.getMinutes();
      const second =
        date.getSeconds().toString().length === 1
          ? '0' + date.getSeconds()
          : date.getSeconds();
      return (
        day +
        '/' +
        month +
        '/' +
        date.getFullYear() +
        ' ' +
        date.getHours() +
        ':' +
        minute +
        ':' +
        second
      );
    },

    changePage(val) {
      this.currentPage = val;
    }
  }
};
</script>
<style>
.el-table .headerRow th {
  background: #005792;
  color: white;
}
</style>
<style src="./index.scss" lang="scss" scoped />
