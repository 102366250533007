import Services from 'core/service'
import * as Type from './types'
const service = new Services()

const getByIdCustomer = async ({
  commit
}, value) => {
  const {
    id = ''
  } = value

  const params = {}

  if (id) {
    params.id = id
  }
  const { status, message, data } = await service.rest('customer/getInfo', params)

  if (status) {
    commit(Type.GET_BY_ID, data)
  } else {
    console.log(message)
  }
}

const editCustomer = async ({
  commit
}, value) => {
  return await service
    .rest('customer/setList', {
      items: {
        $update: [value]
      }
    })
    .then(rlt => {
      const {
        items
      } = rlt
      const {
        $rltUpdate
      } = items
      // Commit to news state
      commit(Type.EDIT, $rltUpdate[0])
      return {
        type: true,
        message: 'success'
      }
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
      return {
        type: false,
        message: 'error'
      }
    })
}

export {
  getByIdCustomer,
  editCustomer
}