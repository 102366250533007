export const SET_ALL_ORDER = 'SET_ALL_ORDER';
export const SET_ALL_CUSTOMER_ORDER = 'SET_ALL_CUSTOMER_ORDER';
export const SET_ORDER_BY_ID = 'SET_ORDER_BY_ID';
export const SET_PARTNER_LOCAL = 'SET_PARTNER_LOCAL';
export const SET_ORDER_DETAIL_ADD = 'SET_ORDER_DETAIL_ADD';
export const SET_ORDER_DETAIL_UPDATE = 'SET_ORDER_UPDATE_UPDATE';
export const SET_ORDER_DETAIL_UPDATE_LOCAL = 'SET_ORDER_DETAIL_UPDATE_LOCAL';
export const SET_DELETE_ORDER_DETAIL = 'SET_DELETE_ORDER_DETAIL';
export const SET_DELETE_ORDER_DETAIL_LOCAL = 'SET_DELETE_ORDER_DETAIL_LOCAL';
export const SET_STATUS_LOCAL = 'SET_STATUS_LOCAL';
export const GET_BY_ORDER_ID = 'GET_BY_ORDER_ID';
export const SET_ORDER_DETAIL_LOCAL = 'SET_ORDER_DETAIL_LOCAL';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const DELETE_FILE = 'DELETE_FILE';
export const SEND_MAIL_TEMPLATE = 'SEND_MAIL_TEMPLATE';
export const EXPORT_PO = 'EXPORT_PO';
export const SET_ORDER_UPDATE = 'SET_ORDER_UPDATE';
export const SET_UPDATE_ORDER_DETAIL_SUPPLIER = 'SET_UPDATE_ORDER_DETAIL_SUPPLIER';
export const GET_ORDER_DETAIL_SUPPIER_BY_ORDER_ID = 'GET_ORDER_DETAIL_SUPPIER_BY_ORDER_ID';
export const SET_UPDATE_ORDER_SUPPLIER = 'SET_UPDATE_ORDER_SUPPLIER';


