import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
    orderData: {},
    orderDetails: [],
    orderDocuments: [],
    orderDocumentsAddLocal: [],
    totalOrderDetails: 0,
    totalPrice: 0,
    totalSalePrice: 0,
    vat: 0,
    shippingFee: 0,
    eiCash: 0,
    orderStatus: 0,
    orderSuppliers: [],
    orderDetailSuppliers: []
};

import cloneDeep from 'lodash/cloneDeep';
import { isEqual } from 'lodash';
// import { validateEmail } from 'core/helpers';

const mutations = {

    [Type.SET_ORDER_BY_ID](state, value) {
        state.orderDetailAddLocal = []
        state.orderData = value;
        state.totalPrice = value.totalPrice;
        state.totalSalePrice = value.totalSalePrice;
        state.vat = value.vat;
        state.shippingFee = value.shippingFee;
        state.eiCash = value.eiCash;
        state.orderStatus = value.statusCode;
        state.orderSuppliers = value.orderSuppliers;
    },

    [Type.GET_BY_ORDER_ID](state, value) {
        state.orderDetailAddLocal = []
        state.orderDetails = value.rows;
        state.totalOrderDetails = value.totalRows;
        state.orderDocuments = value.documents;

    },

    [Type.SET_ORDER_DETAIL_LOCAL](state,value) {
        const oldOrderDetails = state.orderDetailAddLocal;
        state.orderDetailAddLocal = [...oldOrderDetails, ...cloneDeep(value)];
    },
    
    [Type.SET_STATUS_LOCAL](state, value) {
        //const oldData = state.orderData;
        state.orderStatus = value;
    },

    [Type.SET_ORDER_DETAIL_ADD](state,value) {
        const listNewOrderDetail = value.rltAdd.map(item => item.orderDetail) || [];
        const listNewOrderDetailSupplier = value.rltAdd.map(item => item.orderDetailSupplier) || [];
        const listNewOrderSupplier = value.newOrderSuppliers;

        const oldOrderDetails = state.orderDetails;
        const oldOrderSuppliers = state.orderSuppliers;
        const oldOrderDetailSuppliers = state.orderDetailSuppliers;
        // add new data
        state.orderDetails = [...cloneDeep(listNewOrderDetail),...oldOrderDetails];
        state.orderSuppliers = [...cloneDeep(listNewOrderSupplier),...oldOrderSuppliers];
        state.orderDetailSuppliers = [...cloneDeep(listNewOrderDetailSupplier),...oldOrderDetailSuppliers];
        
        state.totalRows += 1;
        state.totalPrice = value.price.totalPrice;
        state.vat = value.price.vat;
        state.shippingFee = value.price.shippingFee;
        state.totalSalePrice = value.price.totalSalePrice;
        state.orderData = {
            ...state.orderData,
            totalPrice: value.price.totalPrice,
            vat: value.price.vat,
            shippingFee: value.price.shippingFee,
            totalSalePrice: value.price.totalSalePrice,
            eiCash: value.price.eiCash
        }

        if (state.orderData.customer.eiCash !==  value.price.customerEiCash) state.orderData.customer.eiCash = value.price.customerEiCash
    },

    [Type.SET_ORDER_DETAIL_UPDATE](state,value) {
        // update order detail
        const oldOrderDetails = state.orderDetails;
        const {id} = value.rltUpdate.orderDetail;
        const objIndex = state.orderDetails.findIndex(obj => obj.id === id);
        oldOrderDetails[objIndex] = value.rltUpdate.orderDetail;
        state.orderDetails = oldOrderDetails.slice(0);

        // update order detail Suppluer
        const oldOrderDetailSuppliers = state.orderDetailSuppliers;
        const { id: orderSupplierDetailId } = value.rltUpdate.orderDetailSupplier;
        const objOrderDetailSupplierIndex = state.orderDetailSuppliers.findIndex(obj => obj.id === orderSupplierDetailId);
        oldOrderDetailSuppliers[objOrderDetailSupplierIndex] = value.rltUpdate.orderDetailSupplier;
        state.orderDetailSuppliers = oldOrderDetailSuppliers.slice(0);

        // update orderSupplier
        if (value.updateOrderSupplier) {
            const oldOrderSuppliers = state.orderSuppliers;
            const { supId } = value.updateOrderSupplier;
            const updateIndex = state.orderSuppliers.findIndex((obj) => obj.supId === supId);
            oldOrderSuppliers[updateIndex] = cloneDeep(value.updateOrderSupplier);
            state.orderSuppliers = oldOrderSuppliers.slice(0);
        }

        state.shippingFee = value.price.shippingFee;
        state.totalPrice = value.price.totalPrice;
        state.totalSalePrice = value.price.totalSalePrice;
        state.vat = value.price.vat;
        state.orderData = {
            ...state.orderData,
            totalPrice: value.price.totalPrice,
            vat: value.price.vat,
            shippingFee: value.price.shippingFee,
            totalSalePrice: value.price.totalSalePrice,
            eiCash: value.price.eiCash
        }
        if (state.orderData.customer.eiCash !==  value.price.customerEiCash) state.orderData.customer.eiCash = value.price.customerEiCash
    },

    [Type.SET_ORDER_DETAIL_UPDATE_LOCAL](state,value) {
        const {oldData,newData} = value;
        // File oldData        
        const oldOrderDetailAddLocal = state.orderDetailAddLocal.map(item =>{
            if(isEqual(item, oldData)){
                item = newData
            }
            return item
        })
        state.orderDetailAddLocal = oldOrderDetailAddLocal.slice(0);
    },
    
    [Type.SET_DELETE_ORDER_DETAIL](state, value) {
        // update order detail
        const oldData = cloneDeep(state.orderDetails);
        const { id } = value.rltDelete.orderDetail; 
        const objIndex =  oldData.findIndex((obj) => obj.id === id );
        oldData.splice(objIndex, 1);
        state.orderDetails = oldData.slice(0);

         // update order detail Suppluer
        const oldOrderDetailSuppliers = cloneDeep(state.orderDetailSuppliers);
        const { id: orderSupplierDetailId } = value.rltDelete.orderĐetailSupplier;
        const objOrderDetailSupplierIndex = oldOrderDetailSuppliers.findIndex(obj => obj.id === orderSupplierDetailId);
        oldOrderDetailSuppliers.splice(objOrderDetailSupplierIndex, 1);
        state.orderDetailSuppliers = oldOrderDetailSuppliers.slice(0);
        
        // update orderSupplier
        if (value.updateOrderSupplier && value.updateOrderSupplier.length > 0 ) {
            const oldOrderSuppliers = state.orderSuppliers;
            const { supId } = value.updateOrderSupplier;
            const updateIndex = state.orderSuppliers.findIndex((obj) => obj.supId === supId);
            oldOrderSuppliers[updateIndex] = cloneDeep(value.updateOrderSupplier);
            state.orderSuppliers = oldOrderSuppliers.slice(0);
        }
        
        
        // update order
        state.totalPrice = value.price.totalPrice;
        state.totalSalePrice = value.price.totalSalePrice;
        state.vat = value.price.vat;
        state.shippingFee = value.price.shippingFee;
        state.totalRows -= 1;       
        state.orderData = {
            ...state.orderData,
            totalPrice: value.price.totalPrice,
            vat: value.price.vat,
            shippingFee: value.price.shippingFee,
            totalSalePrice: value.price.totalSalePrice,
            eiCash: value.price.eiCash
        }
        if (state.orderData.customer.eiCash !==  value.price.customerEiCash) state.orderData.customer.eiCash = value.price.customerEiCash
    },

    [Type.SET_DELETE_ORDER_DETAIL_LOCAL](state, value) {
        const oldData = cloneDeep(state.orderDetailAddLocal);
        value.map(item=>{
            const objIndex = oldData.findIndex((obj) => {
                return isEqual(obj,item)
            } );
            oldData.splice(objIndex, 1)
        });
        state.orderDetailAddLocal = oldData.slice(0);
    },

    [Type.UPLOAD_FILE](state, value) {
        const documents = state.orderDocuments;
        let findItem = documents.findIndex(od => od.id === value.typeId)
        const oldItems = documents[findItem].items || [];
        oldItems.push(value)
        documents[findItem] = {...documents[findItem], items: oldItems }
        state.orderDocuments = documents.slice(0);
    },

    [Type.DELETE_FILE](state, value) {
        const oldDocuments = cloneDeep(state.orderDocuments);
        value.rltDelete.map(item=>{
            const { id, typeId } = item
            const index = oldDocuments.findIndex(od => od.id === typeId)
            const oldItems = oldDocuments[index].items || [];
            const objIndex = oldItems.findIndex((obj) => obj.id === id );
            oldItems.splice(objIndex, 1);
            oldDocuments[index] = {...oldDocuments[index], items: oldItems }
        });
        
        state.orderDocuments = oldDocuments.slice(0)       
    },    
    [Type.SEND_MAIL_TEMPLATE]() {},
    [Type.EXPORT_PO]() {},
    [Type.SET_ORDER_UPDATE](state, value) {
        state.totalSalePrice = value.totalSalePrice;
        state.orderData = cloneDeep(value);
    },
    [Type.GET_ORDER_DETAIL_SUPPIER](state, value) {
        state.orderDetailSupplier = value.rows;
    },
    [Type.SET_UPDATE_ORDER_SUPPLIER](state, value) {
        const oldOrderSuppliers = state.orderSuppliers;
        const { id } = value.rltUpdate;
        const objIndex = state.orderSuppliers.findIndex((obj => obj.id === id));
        oldOrderSuppliers[objIndex] = value.rltUpdate;
        state.orderSuppliers = oldOrderSuppliers.slice(0);
        state.totalPrice = value.totalPrice;
    },
    [Type.GET_ORDER_DETAIL_SUPPIER_BY_ORDER_ID](state, value) {
        state.orderDetailSuppliers = value.rows;
    }
};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};