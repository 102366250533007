import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
    orderSupplierStatusHistories: [],
    totalRows: 0
};


const mutations = {
    [Type.SET_ORDER_SUPPLIER_STATUS_HISTORY_BY_SUP_ID](state, value) {
        state.orderSupplierStatusHistories = value.data.items;
        state.totalRows  = value.data.totalRows;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};