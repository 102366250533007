import {
  SET_GLOBAL_READY,
  TOGGLE_SIDEBAR,
  SWITCH_SIDEBAR,
  HANDLE_SWIPE,
  CHANGE_SIDEBAR_ACTIVE,
  TOGGLE_HELPER,
  SET_SIDEBAR_STATIC,
  SET_LOGIN_MODE
} from './types';

import {
  NAVBAR_COLOR_SCHEMES,
  NAVBAR_TYPES,
  SIDEBAR_TYPES,
  SIDEBAR_COLOR_NAME
} from 'core/constants';
import * as getters from './getters';
import * as actions from './actions';

import { isScreen } from 'core/helpers';

const state = {
  globalReady: true,
  sidebarClose: false,
  sidebarStatic: false,
  sidebarColorName: SIDEBAR_COLOR_NAME.DEFAULT,
  navbarColorScheme: NAVBAR_COLOR_SCHEMES.LIGHT,
  navbarType: NAVBAR_TYPES.STATIC,
  sidebarType: SIDEBAR_TYPES.SOLID,
  sidebarActiveElement: null,
  helperOpened: false,
  loginMode: 'admin'
};
const mutations = {
  [SET_GLOBAL_READY](state, flag = true) {
    state.globalReady = flag;
  },

  [SET_LOGIN_MODE](state, mode = 'admin') {
    state.loginMode = mode;
  },

  [SET_SIDEBAR_STATIC](state, flag = false) {
    state.sidebarStatic = flag;
  },

  [TOGGLE_SIDEBAR](state) {
    const nextState = !state.sidebarStatic;

    localStorage.sidebarStatic = nextState;
    state.sidebarStatic = nextState;

    if (!nextState && (isScreen('lg') || isScreen('xl'))) {
      state.sidebarClose = true;
    }
  },
  [SWITCH_SIDEBAR](state, value) {
    if (value) {
      state.sidebarClose = value;
    } else {
      state.sidebarClose = !state.sidebarClose;
    }
  },
  [HANDLE_SWIPE](state, e) {
    if ('ontouchstart' in window) {
      if (e.direction === 4) {
        state.sidebarClose = false;
      }

      if (e.direction === 2 && !state.sidebarClose) {
        state.sidebarClose = true;
      }
    }
  },
  [CHANGE_SIDEBAR_ACTIVE](state, index) {
    state.sidebarActiveElement = index;
  },

  [TOGGLE_HELPER](state, payload) {
    state.helperOpened = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
