<template>
  <div>
    <el-dialog
      v-loading="isLoading"
      title="Lalamove Shipping Fee"
      :visible.sync="dialogLalamoveShippingFeeVisible"
      width="75%"
      :before-close="emitClosePopup"
    >
      <b-row>
        <b-col cols="5" class="pr-5" style="border-right: 1px solid #ebeef5">
          <el-form
            ref="form"
            :model="form"
            label-width="120px"
            size="medium"
            label-position="top"
            :rules="rulesForm"
          >
            <!-- Service Type (S) -->
            <el-form-item
              prop="serviceType"
              :label="$i18n.t('src.components.shipping.title.serviceType')"
            >
              <!-- <b-form-select
                v-model="form.serviceType"
                :options="getServiceType"
                size="sm"
                class="mt-3"
                @change="onchangeMarketAndServiceType"
                :disabled="isDisableInputAndSelectInForm"
              >
                <b-form-select-option value="" disabled
                  >-- Please select an option --</b-form-select-option
                >
              </b-form-select> -->
              
              <el-select
                size="medium"
                v-model="form.serviceType"
                placeholder="Please select service type"
                clearable
                filterable
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="item in getServiceType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- Service Type (E) -->

            <!-- Market Allow By Lalamove (S) -->
            <el-form-item
              prop="market"
              :label="$i18n.t('src.components.shipping.title.market')"
            >
              <!-- <b-form-select
                v-model="form.market"
                :options="getAvailableMarket"
                size="sm"
                class="mt-3"
                @change="onchangeMarketAndServiceType"
                :disabled="isDisableInputAndSelectInForm"
              >
                <b-form-select-option value="" disabled
                  >-- Please select an option --</b-form-select-option
                >
              </b-form-select> -->

              <el-select
                size="medium"
                v-model="form.market"
                placeholder="Please select market"
                clearable
                filterable
                :popper-append-to-body="false"
                @change="onchangeMarketAndServiceType"
                :disabled="isDisableInputAndSelectInForm"
              >
                <el-option
                  v-for="item in getAvailableMarket"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- Market Allow By Lalamove (E) -->

            <!--  Requester name (S) -->
            <el-form-item
              prop="requesterName"
              :label="$i18n.t('src.components.shipping.title.requesterName')"
            >
              <CustomInput
                v-model="form.requesterName" 
                placeholder="Requester name" 
                :disabled="isDisableInputAndSelectInForm"
              >
                <el-button
                  v-if="allowChooseSupplierAddress"
                  slot="append"
                  type="primary"
                  icon="el-icon-location-information"
                  @click="onClickChooseRequester"
                >
                </el-button>
              </CustomInput>
            </el-form-item>
            <!-- Requester name (E) -->

            <!--  Requester phone (S) -->
            <el-form-item
              prop="requesterPhone"
              :label="$i18n.t('src.components.shipping.title.requesterPhone')"
            >
              <el-input
                v-model="form.requesterPhone"
                placeholder="Requester phone"
                :disabled="isDisableInputAndSelectInForm"
              ></el-input>
            </el-form-item>
            <!-- Requester phone (E) -->

            <!-- Delivery Place (S) -->
            <el-form-item
              prop="requesterAddress"
              :label="$i18n.t('src.components.shipping.title.requesterAddress')"
            >
              <CustomInput
                placeholder="Delivery Place"
                v-model="form.requesterAddress"
                class="input-with-select"
                prepend-inner-icon="place"
                :disabled="isDisableInputAndSelectInForm"
              >
              </CustomInput>
            </el-form-item>
            <!-- Delivery Place (E) -->

            <!--  Receiver name (S) -->
            <el-form-item
              prop="receiverName"
              :label="$i18n.t('src.components.shipping.title.receiverName')"
            >
              <CustomInput
                v-model="form.receiverName" 
                placeholder="Receiver Name"
                :disabled="isDisableInputAndSelectInForm"
              >
              </CustomInput>
            </el-form-item>
            <!-- Receiver Name (E) -->

            <!--  Receiver phone (S) -->
            <el-form-item
              prop="receiverPhone"
              :label="$i18n.t('src.components.shipping.title.receiverPhone')"
            >
              <el-input
                v-model="form.receiverPhone"
                placeholder="Receiver phone"
                :disabled="isDisableInputAndSelectInForm"
              ></el-input>
            </el-form-item>
            <!-- Receiver phone (E) -->

            <!-- Receive Place (S) -->
            <el-form-item
              prop="receiverAddress"
              :label="$i18n.t('src.components.shipping.title.receiverAddress')"
            >
              <CustomInput
                v-model="form.receiverAddress"
                placeholder="Receive Place"
                class="input-with-select"
                :disabled="isDisableInputAndSelectInForm"
              >
              </CustomInput>
            </el-form-item>
            <!-- Receive Place (E) -->
          </el-form>
        </b-col>

        <b-col cols="7" class="pl-5">
          <el-row>
            <h1 style="text-align: center; margin-bottom: 15px;">Google Map</h1>
            <div style="position: relative; width: 100%">
              <gmap-autocomplete
                class="introInput"
                style="width: 100%"
                :options="autocompleteOptions"
                @place_changed="setPlace"
              >
                <template v-slot:input="slotProps">
                  <el-input
                    outlined
                    id="inputSearch"
                    @focus="focusInput"
                    prepend-inner-icon="place"
                    placeholder="Type To Find Locations"
                    v-model="autoCompleteInputMap"
                    ref="input"
                    v-on:listeners="slotProps.listeners"
                    v-on:attrs="slotProps.attrs"
                    class="input-with-select"
                  >
                    <el-select 
                      style="width: 110px; border: 1px solid #DCDFE6; border-radius: 5px" 
                      slot="append" 
                      placeholder="Select"
                      @change="onChangeSelectLocationType"
                      v-model="selectedLocationType"
                    >
                      <el-option
                        v-for="item in getLocationTypes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-button
                      slot="prepend"
                      type="success"
                      icon="el-icon-add-location"
                      @click="onClickAddlocation"
                    >
                    </el-button>
                  </el-input>
                </template>
              </gmap-autocomplete>
            </div>
            <br />
            <el-card class="box-card">
              <Gmap-Map
                style="width: 100%; height: 400px;"
                :zoom="mapZoom"
                :center="mapCenterObject"
                ref="map"
              >
                <Gmap-Marker
                  v-for="(marker, index) in markers"
                  :key="index"
                  :position="marker.position"
                  :clickable="true"
                  :draggable="isDraggableFunc(marker.typeMarker)"
                  @dragend="onDragMarkerEnd"
                  :icon="marker.icon"
                >
                </Gmap-Marker>
              </Gmap-Map>
            </el-card>
          </el-row>
          <el-row v-if="shareLink != null">
            <el-card class="box-card">
              <iframe
                style="height: 500px;
                width: 100%;
                border: none;
                box-sizing: border-box;"
                :src="shareLink"
              ></iframe>
            </el-card>
          </el-row>
          <el-row>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Shipping Info</span>
                <el-button
                  v-if="shareLink != null"
                  icon="el-icon-share"
                  style="float: right; padding: 8px 8px"
                  type="primary"
                  @click="onClickShowShipperLocation"
                >
                  Shipper Location
                </el-button>
              </div>
              <el-form label-position="right" label-width="100px" :model="formDetail">
                <el-form-item label="Status">
                  <el-input v-model="formDetail.status" disabled></el-input>
                </el-form-item>
                <el-form-item label="Total Fee">
                  <el-input v-model="formDetail.totalFee" disabled></el-input>
                </el-form-item>
                <el-form-item label="Created At">
                  <el-input v-model="formDetail.createdAt" disabled></el-input>
                </el-form-item>
              </el-form>
            </el-card>
          </el-row>
        </b-col>
      </b-row>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="allowPlaceOrder"
          size="medium"
          type="success"
          @click="onClickPlaceOrder()"
        >
          {{ $i18n.t('src.components.shipping.lalamove.placeOrder') }}
        </el-button>
        <el-button
          v-if="allowRequestQuotation"
          size="medium"
          type="primary"
          @click="requestForQuotation()"
        >
          {{ $i18n.t('src.components.shipping.lalamove.requestForQuotation') }}
        </el-button>
        <el-button
          v-if="allowUpdate"
          size="medium"
          type="info"
          @click="updateShippingInfo()"
        >
          Update
        </el-button>
        <el-button @click="emitClosePopup">{{
          $i18n.t('src.components.modal.selectProduct.tableProduct.close')
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-loading="isLoading"
      :title="$i18n.t('src.components.shipping.lalamove.dialog.title.dialogChooseReceiverAddress')"
      :visible.sync="dialogChooseSupplierAddressVisible"
      width="50%"
      :before-close="emitCloseChooseReceiverAddressPopup"
      :append-to-body="true"
    >
      <el-row>
        <b-form-select
          v-model="valueReceiverAddress"
          :options="getListSupplier"
          size="sm"
          class="mt-3"
          @change="onchangeSupplier"
        >
          <b-form-select-option value="null" disabled >
          -- Please select supplier --
          </b-form-select-option>
        </b-form-select>
      </el-row>
      <el-row>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{$i18n.t('src.components.shipping.lalamove.dialog.card.title')}}</span>
            <!-- <el-button
              v-if="shareLink != null"
              icon="el-icon-share"
              style="float: right; padding: 8px 8px"
              type="primary"
              @click="onClickShowShipperLocation"
            >
              Shipper Location
            </el-button> -->
          </div>
          <el-form label-position="right" label-width="130px" :model="formSupplierAddress" v-loading="isLoadingCardForm">
            <el-form-item :label="$i18n.t('src.components.shipping.lalamove.dialog.card.supplierAddressList')">
              <b-form-select
                v-model="supplierAddressId"
                :options="getListAddressOfSupplier"
                size="sm"
                @change="onchangeSupplierAddress"
              >
                <b-form-select-option value="null" disabled >
                -- Please select address --
                </b-form-select-option>
              </b-form-select>
            </el-form-item>
            <el-form-item :label="$i18n.t('src.components.shipping.title.requesterAddress')" prop="address">
              <el-input v-model="formSupplierAddress.address"></el-input>
            </el-form-item>
            <el-form-item :label="$i18n.t('src.components.shipping.title.receiverName')" prop="receiverName">
              <el-input v-model="formSupplierAddress.receiverName"></el-input>
            </el-form-item>
            <el-form-item :label="$i18n.t('src.components.shipping.title.receiverPhone')" prop="phone">
              <el-input v-model="formSupplierAddress.phone"></el-input>
            </el-form-item>
            
          </el-form>
        </el-card>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="medium"
          type="success"
          @click="onClickAddSupplierAddressToShippingForm()"
        >
          OK
        </el-button>
        <el-button @click="emitCloseDialogSupplierAddress">{{
          $i18n.t('src.components.modal.selectProduct.tableProduct.close')
        }}</el-button>
      </span>
    </el-dialog>
  </div>
  
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  SERVICE_TYPES_LALAMOVE,
  LOCATION_TYPE,
  AVAILABLE_MARKETS,
  LALAMOVE_CODE,
  ORDER_STATUS_LALAMOVE
} from '../../../core/constants';
import axios from 'axios';
import isEqual from 'lodash/isEqual';
// import some from 'lodash/some';
import cloneDeep from 'lodash/cloneDeep';
import { formatDate } from '../../../core/helpers';
// import { gmapApi } from 'vue2-google-maps';
import vnLocal from 'vn-local';
import CustomInput from 'components/CustomInput';

export default {
  name: 'LalaMove',
  components: { CustomInput },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    order_id: {
      type: Number,
      default: null
    },
    form_info:{
      type: Object,
      default: () => ({
        name: '',
        phone: '',
        address: '',
        province: '',
        district: '',
        ward: ''
      })
    },
    supplier_info_arr: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      GEOCODING_API_ENDPOINT: process.env.VUE_APP_GEOCODING_API_ENDPOINT,
      GOOGLE_MAP_API_KEY: process.env.VUE_APP_GOOGLE_MAP_API_KEY,

      form: {
        orderId: this.order_id,
        logisticCode: LALAMOVE_CODE,
        market: '',
        receiverLocation: null,
        receiverAddress: null,
        receiverPhone: '',
        receiverName: '',
        requesterAddress: null,
        requesterLocation: null,
        requesterPhone: '',
        requesterName: '',
        serviceType: ''
      },
      formDefault: {
        orderId: null,
        logisticCode: LALAMOVE_CODE,
        market: '',
        receiverLocation: null,
        receiverAddress: null,
        receiverPhone: '',
        receiverName: '',
        requesterAddress: null,
        requesterLocation: null,
        requesterPhone: '',
        requesterName: '',
        serviceType: ''
      },
      // requesterInfoObj: {
      //   requesterName: null,
      //   requesterLocation: null,
      //   requesterAddress: null,
      //   requesterPhone: null
      // },
      formSupplierAddress:{
        receiverName: null,
        phone: null,
        address: null
      },
      idShippingInfo: null,
      isLoading: true,
      isLoadingCardForm: true,
      valueReceiverAddress: null,
      supplierAddressId: null,
      dialogChooseSupplierAddressVisible: false,
      mapCenterObject: { lat: 0, lng: 0 },
      mapZoom: 8,
      markers: [],
      shareLink: null,
      deliveryLocationIcon: {
        url: require('../../../../public/images/deliveryPlace.png')
      },
      receiveLocationIcon: {
        url: require('../../../../public/images/receiveLocation.png')
      },
      currentPlace: null,
      avaiableMarketArr: [],
      userCurrentPosition: {},
      selectedLocationType: 'deliveryPlace',
      autoCompleteInputMap: null,
      autocompleteOptions: {
        componentRestrictions: {
          country: ['vn']
        }
      },

      rulesForm: {
        serviceType: [
          { required: true, message: 'Please input Service Type', trigger: 'blur' }
        ],
        market: [{ required: true, message: 'Please input Market', trigger: 'blur' }],
        requesterName: [
          { required: true, message: 'Please input Requester Name', trigger: 'blur' }
        ],
        requesterPhone: [
          { required: true, message: 'Please input Requester Phone', trigger: 'blur' },
          {
            required: true,
            pattern: /(84|0[2|3|5|7|8|9])+([0-9]{8,9})\b/,
            message: 'Please enter the correct format',
            trigger: 'blur'
          }
        ],
        requesterAddress: [
          { required: true, message: 'Please input Requester Address', trigger: 'change' }
        ],
        receiverName: [
          { required: true, message: 'Please input Receiver Name', trigger: 'blur' }
        ],
        receiverPhone: [
          { required: true, message: 'Please input Receiver Phone', trigger: 'blur' },
          {
            required: true,
            pattern: /(84|0[2|3|5|7|8|9])+([0-9]{8,9})\b/,
            message: 'Please enter the correct format',
            trigger: 'blur'
          }
        ],
        receiverAddress: [
          { required: true, message: 'Please input Receiver Address', trigger: 'change' }
        ]
      }
    };
  },

  computed: {
    ...mapState('lalaMove', ['lalaMoveInfo']),
    ...mapState('supplierAddress', ['supplierAddress', 'supplierAddressMap']),

    // google: gmapApi,

    formDetail() {
      const { status = '', totalFee = '', createdAt = '' } = cloneDeep(this.lalaMoveInfo);
      const statusTranslateString = status
        ? this.$i18n.t(`src.components.shipping.lalamove.${status}`)
        : 'No Data';

      const dataReturn =
        {
          status: statusTranslateString,
          totalFee: totalFee ? totalFee : 'No Data',
          createdAt: createdAt ? formatDate(createdAt) : 'No Data'
        } || {};
      return dataReturn;
    },

    oldForm() {
      const {
        orderId = this.order_id,
        logisticCode = LALAMOVE_CODE,
        market,
        receiverLocation,
        receiverAddress,
        receiverPhone,
        receiverName,
        requesterAddress,
        requesterLocation,
        requesterPhone,
        requesterName,
        serviceType
      } = cloneDeep(this.lalaMoveInfo);

      const dataReturn = {
        orderId,
        logisticCode,
        market,
        receiverLocation,
        receiverAddress,
        receiverPhone,
        receiverName,
        requesterAddress,
        requesterLocation,
        requesterPhone,
        requesterName,
        serviceType
      };

      return dataReturn;
    },

    dialogLalamoveShippingFeeVisible() {
      return this.visible;
    },

    isShippingOrderNotSuccess(){
      return (
        this.lalaMoveInfo.status === ORDER_STATUS_LALAMOVE.CANCELED.name ||
        this.lalaMoveInfo.status === ORDER_STATUS_LALAMOVE.REJECTED.name ||
        this.lalaMoveInfo.status === ORDER_STATUS_LALAMOVE.EXPIRED.name
      )
    },

    allowUpdate() {
      return (
        this.idShippingInfo &&
        !isEqual(this.oldForm, this.form) &&
        !this.lalaMoveInfo.isPlaceOrder
      );
    },

    allowRequestQuotation(){
      return (
        !this.idShippingInfo || this.isShippingOrderNotSuccess
      ) 
    },

    allowChooseSupplierAddress(){
      return !this.lalaMoveInfo.isPlaceOrder
    },

    isDisableInputAndSelectInForm(){
      return (
        this.lalaMoveInfo.isPlaceOrder 
      )
    },

    allowPlaceOrder() {
      return this.idShippingInfo && !this.lalaMoveInfo.isPlaceOrder;
    },

    getServiceType() {
      const object = SERVICE_TYPES_LALAMOVE || {};
      const serviceTypesArr = Object.keys(object).map(key => {
        const value = object[key].name;
        const label = object[key].description;

        return {
          text: label,
          value: value
        };
      });
      return serviceTypesArr;
    },

    getAvailableMarket() {
      const object = AVAILABLE_MARKETS || {};
      const marketArr = Object.keys(object).map(key => {
        const value = object[key].code;
        const label = object[key].name;

        return {
          label: label,
          value: value
        };
      });

      return marketArr;
    },

    getLocationTypes() {
      return [
        {
          label:
            this.$i18n.t('src.components.shipping.lalamove.requesterAddress') ||
            'Delivery Place',
          value: LOCATION_TYPE.DELIVERY_PLACE
        },
        {
          label:
            this.$i18n.t('src.components.shipping.lalamove.receiverAddress') ||
            'Receive Place',
          value: LOCATION_TYPE.RECEIVE_PLACE
        }
      ];
    },

    getListSupplier(){
      return this.supplier_info_arr.map(obj => {
        const value = obj.id;
        const label = obj.name;

        return {
          text: label,
          value: value
        };
      });
    },

    getListAddressOfSupplier(){
      // this.supplierAddress
      return this.supplierAddress.map(obj =>{

        const { id, address, district, ward } = obj;

        const fullAddress = this.getFullAddressFromCode(address, district, ward) || 'No data';

        return {
          text: fullAddress,
          value: id,
        }
      })
    }
  },

  watch: {
    order_id: async function() {
      await this.getShippingInfoByOrderFunc();
    },

    lalaMoveInfo: function(value){
      const { id } = value;
      this.idShippingInfo = id;
    },

    form_info: async function(value) {
      if(!this.idShippingInfo){
        await this.addOrderInfoToReceiverShippingInfo(value);
      }
    },
  },

  mounted() {
    this.geolocate();
  },

  updated() {
    // run something after dom has changed by vue
    // this.$nextTick(function () {
    // console.log('this.$refs.autone:', this.$refs);
    // })
  },

  async created() {
    await Promise.all([
      this.getShippingInfoByOrderFunc(),
      this.getAllSupplierAddress({supplierId: this.valueReceiverAddress || 0})
    ]).then(() => {
      this.isLoading = false;
      this.isLoadingCardForm = false;
    });
  },

  destroyed() {
    // this.resetAllState();
  },

  methods: {
    ...mapActions('lalaMove', [
      'requestQuotation',
      'getInfoByOrder',
      'placeOrder',
      'updateInfo'
    ]),
    ...mapActions('supplierAddress', [
      'getAllSupplierAddress',
    ]),

    async onchangeSupplier(){
      await this.getAllSupplierAddress({supplierId: this.valueReceiverAddress||0});
      this.formSupplierAddress.receiverName = null;
      this.formSupplierAddress.phone = null;
      this.formSupplierAddress.address = null;
      this.supplierAddressId = null;
      this.isLoadingCardForm = false;
    },

    onchangeSupplierAddress(value){
      const { receiverName = '', phone = '' , address = '', district, ward } = this.supplierAddressMap[value] || {};
      this.formSupplierAddress.receiverName = receiverName;
      this.formSupplierAddress.phone = phone;

      const fullAddress = this.getFullAddressFromCode(address, district, ward) || 'No data';

      this.formSupplierAddress.address = fullAddress;
    },

    onchangeMarketAndServiceType() {
      if (this.form.market && this.form.serviceType) {
        let serviceType = this.form.serviceType;
        let marketArr = SERVICE_TYPES_LALAMOVE?.[serviceType]?.avaiableMarkets || [];
        let serviceTypeName = SERVICE_TYPES_LALAMOVE?.[serviceType]?.description || '';
        let cityName = AVAILABLE_MARKETS?.[this.form.market]?.name || '';

        if (!marketArr.includes(this.form.market)) {
          this.$notify.error({
            title: 'Error',
            message: `This market (${cityName}) does not support the service (${serviceTypeName}) yet`
          });
          this.form.serviceType = this.oldForm?.serviceType || null;
          this.form.market = this.oldForm?.market || null;
        }
      }
    },

    isDraggableFunc(typeMarker) {
      return typeMarker === this.selectedLocationType;
    },

    addOrderInfoToReceiverShippingInfo(value){

      const {address = '', district, name = '', phone = '', ward} = value;

      const fullAddress = this.getFullAddressFromCode(address, district, ward) || '';
      this.form.receiverName = name;
      this.form.receiverAddress = fullAddress;
      this.form.receiverPhone = phone;
      this.form.receiverAddress = fullAddress;

      // this.getLaLongFromAddress()
    },

    getFullAddressFromCode(address, district, ward){

      if(!district || !ward){
        return '';
      };
      
      const districtList = vnLocal.getWardsByDistrictCode(district);
      const provinceName = districtList?.[0]?.provinceName || '';
      const districtName = districtList?.[0]?.districtName || '';
      const wardName = (ward && districtList?.find((d) => d.code === ward)?.name) || '';
        return address + ', ' + wardName + ', ' + districtName + ', ' + provinceName;
      
    },

    async getShippingInfoByOrderFunc() {
      const result = await this.getInfoByOrder({
        orderId: this.order_id,
        logisticCode: LALAMOVE_CODE
      });

      if (result.status) {
        const {
          // createdAt,
          // driverId,
          id,
          logisticCode = LALAMOVE_CODE,
          market,
          orderId = this.order_id,
          receiverAddress,
          receiverLocation,
          receiverName,
          receiverPhone,
          requesterAddress,
          requesterLocation,
          requesterName,
          requesterPhone,
          serviceType,
          shareLink
          // status,
          // totalFee
          // updatedAt,
          // isPlaceOrder
        } = this.lalaMoveInfo;

        this.form = cloneDeep({
          orderId,
          logisticCode,
          market,
          receiverAddress,
          receiverLocation,
          receiverName,
          receiverPhone,
          requesterAddress,
          requesterLocation,
          requesterName,
          requesterPhone,
          serviceType
        });

        this.shareLink = shareLink;

        this.idShippingInfo = id;

        this.autoCompleteInputMap = requesterAddress;

        //add marker to requester place
        this.markers[0] = {
          position: requesterLocation,
          icon: this.deliveryLocationIcon,
          typeMarker: LOCATION_TYPE.DELIVERY_PLACE
        };

        //add marker to receive place
        this.markers[1] = {
          position: receiverLocation,
          icon: this.receiveLocationIcon,
          typeMarker: LOCATION_TYPE.RECEIVE_PLACE
        };
      }

      this.isLoading = false;
      return true;
    },

    onClickChooseRequester(){
      this.dialogChooseSupplierAddressVisible = true;
    },

    async getLaLongFromAddress(address){
      try {
        const response = await axios.get(
          `${this.GEOCODING_API_ENDPOINT}?address=${address}&key=${this.GOOGLE_MAP_API_KEY}`
        );

        console.log('response: ', response);

        if (response.data.error_message) {
          this.$notify.error({
            title: 'Error',
            message: response.data.error_message
          });
          return null;
        } else {
          console.log(
            'response.data.result.formatted_address:',
            response.data.results[0].formatted_address
          );
          console.log('response.data.result.geometry:', response.data.results[0].geometry.location);
          
          let latLongObj = response?.data?.results?.[0]?.geometry?.location || null;
          return latLongObj;
        }
      } catch (error) {
        this.$notify.error({
          title: 'Error',
          message: error.message
        });

        return null
      }
    },

    async getAddressFromLatLong(lat, long) {
      try {
        const response = await axios.get(
          `${this.GEOCODING_API_ENDPOINT}?latlng=${lat},${long}&key=${this.GOOGLE_MAP_API_KEY}`
        );

        if (response.data.error_message) {
          this.$notify.error({
            title: 'Error',
            message: response.data.error_message
          });
          return null;
        } else {
          console.log(
            'response.data.result:',
            response.data.results[0].formatted_address
          );
          let addressString = response.data.results[0].formatted_address;
          return addressString;
        }
      } catch (error) {
        this.$notify.error({
          title: 'Error',
          message: error.message
        });
      }
    },

    setPlace(place) {
      console.log('place: ', place);
      this.currentPlace = place;
      let lat = place.geometry.location.lat() || '';
      let lng = place.geometry.location.lng() || '';
      if (lat && lng) {
        this.showUserLocationOnMap(lat, lng);
      }
    },

    async onClickAddlocation() {
      if (this.currentPlace) {
        try {
          const { formatted_address } = this.currentPlace;

          const marker = {
            lat: this.currentPlace.geometry.location.lat(),
            lng: this.currentPlace.geometry.location.lng()
          };

          this.mapCenterObject = marker;
          this.currentPlace = null;

          //DEPEND ON LOCATION TYPE INSERT LAT LNG ADDRESS TO FORM
          if (this.selectedLocationType === LOCATION_TYPE.DELIVERY_PLACE) {
            this.form.requesterLocation = {
              lat: marker.lat,
              lng: marker.lng
            };
            // this.oldDeliveryPlaceLatLng = {...marker };

            //add marker to requester place
            this.markers[0] = {
              position: marker,
              icon: this.deliveryLocationIcon,
              typeMarker: LOCATION_TYPE.DELIVERY_PLACE
            };

            if (formatted_address) {
              this.form.requesterAddress = formatted_address;
            } else {
              const { lat, lng } = this.form.requesterLocation;

              //get address string
              await this.getAddressFromLatLong(lat, lng).then(addressString => {
                if (addressString) {
                  this.form.requesterAddress = addressString || '';
                }
              });
            }
          }

          if (this.selectedLocationType === LOCATION_TYPE.RECEIVE_PLACE) {
            this.form.receiverLocation = {
              lat: marker.lat,
              lng: marker.lng
            };
            // this.oldReceivePlaceLatLng = {...marker};

            //add marker to receive place
            this.markers[1] = {
              position: marker,
              icon: this.receiveLocationIcon,
              typeMarker: LOCATION_TYPE.RECEIVE_PLACE
            };

            if (formatted_address) {
              this.form.receiverAddress = formatted_address;
            } else {
              const { lat, lng } = this.form.receiverLocation;

              //get address string
              await this.getAddressFromLatLong(lat, lng).then(addressString => {
                if (addressString) {
                  this.form.receiverAddress = addressString || '';
                }
              });
            }
          }

          this.$notify({
            title: this.$i18n.t('common.notify.title.success'),
            message: 'Add success',
            type: 'success'
          });
        } catch (error) {
          console.log('error onClickAddlocation: ', error);
          this.$notify({
            title: 'Error',
            message: error.message,
            type: 'error'
          });
        }
      } else {
        this.$notify({
          title: 'Warning',
          message: 'Nothing to add, please try again !!!',
          type: 'warning'
        });
      }
    },

    geolocate: function() {
      if (navigator.geolocation) {
        //get current position
        navigator.geolocation.getCurrentPosition(
          async position => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            this.userCurrentPosition = { lat, lng };
            await this.getAddressFromLatLong(lat, lng).then(addressString => {
              if (addressString && !this.form.requesterAddress) {
                this.form.requesterAddress = addressString || '';
                this.form.requesterLocation = { lat, lng };
              }
            });

            this.showUserLocationOnMap(lat, lng);
          },
          error => {
            this.$notify.error({
              title: 'Error',
              message: error.message
            });
          }
        );
      } else {
        this.$notify({
          title: 'Warning',
          message: 'Your browser does not support geolocation API',
          type: 'warning'
        });
      }
    },

    focusInput() {
      let inputElement = document.getElementById('inputSearch');

      var pacContainer = document.getElementsByClassName('pac-container')[0];

      inputElement.parentElement.appendChild(pacContainer);
    },

    showUserLocationOnMap(lat, lng) {
      //create map object
      if (lat && lng) {
        this.mapCenterObject = { lat, lng };
        this.mapZoom = 15;

        //add marker
        const marker = {
          lat,
          lng
        };
        //DEPEND ON LOCATION TYPE INSERT LAT LNG ADDRESS TO FORM
        if (this.selectedLocationType === LOCATION_TYPE.DELIVERY_PLACE) {
          this.markers[0] = {
            position: marker,
            icon: this.deliveryLocationIcon,
            typeMarker: LOCATION_TYPE.DELIVERY_PLACE
          };
        }

        if (this.selectedLocationType === LOCATION_TYPE.RECEIVE_PLACE) {
          //add marker to receive place
          this.markers[1] = {
            position: marker,
            icon: this.receiveLocationIcon,
            typeMarker: LOCATION_TYPE.RECEIVE_PLACE
          };
        }
      }
    },

    async onChangeSelectLocationType() {
      if (this.selectedLocationType === LOCATION_TYPE.DELIVERY_PLACE) {
        //get address string
        if (this.form.requesterLocation) {
          const { lat, lng } = this.form.requesterLocation;
          await this.getAddressFromLatLong(lat, lng).then(addressString => {
            if (addressString) {
              this.autoCompleteInputMap = addressString || '';
            }
          });
          this.showUserLocationOnMap(lat, lng);
        }

        this.autoCompleteInputMap = this.form.requesterAddress;

        return true;
      }

      if (this.selectedLocationType === LOCATION_TYPE.RECEIVE_PLACE) {
        //get address string
        if (this.form.receiverLocation) {
          const { lat, lng } = this.form.receiverLocation;
          await this.getAddressFromLatLong(lat, lng).then(addressString => {
            if (addressString) {
              this.autoCompleteInputMap = addressString || '';
            }
          });
          this.showUserLocationOnMap(lat, lng);
        }

        this.autoCompleteInputMap = this.form.receiverAddress;

        return true;
      }

      this.currentPlace = null;
      this.autoCompleteInputMap = null;
      this.mapCenterObject = this.userCurrentPosition;
      this.mapZoom = 8;
    },

    async onDragMarkerEnd(location) {
      const lat = location.latLng.lat();
      const lng = location.latLng.lng();

      if (!lat || !lng) {
        this.$notify({
          title: 'Warning',
          message: 'Please try another location !!!',
          type: 'warning'
        });
      }

      if (this.selectedLocationType === LOCATION_TYPE.DELIVERY_PLACE) {
        await this.getAddressFromLatLong(lat, lng).then(addressString => {
          if (addressString) {
            this.autoCompleteInputMap = addressString || '';
            this.form.requesterAddress = addressString;
            this.form.requesterLocation = { lat: lat, lng: lng };
          }
        });
      }

      if (this.selectedLocationType === LOCATION_TYPE.RECEIVE_PLACE) {
        await this.getAddressFromLatLong(lat, lng).then(addressString => {
          if (addressString) {
            this.autoCompleteInputMap = addressString || '';
            this.form.receiverAddress = addressString;
            this.form.receiverLocation = { lat: lat, lng: lng };
          }
        });
      }
    },

    clearForm() {
      this.form = cloneDeep(this.formDefault);
    },

    emitCloseChooseReceiverAddressPopup(){
      this.dialogChooseSupplierAddressVisible = false;
    },

    emitClosePopup(done) {
      var message = null;
      if (!isEqual(this.form, this.formDefault)) {
        message = this.$i18n.t('common.confirm.close.form.out', { name: 'LalaMove' });
        this.$confirm(message)
          .then(() => {
            this.clearForm();
            this.autoCompleteInputMap = null;
            this.$emit('onCloseLalamoveDialog');
            done();
          })
          .catch(() => {});
      } else {
        this.$emit('onCloseLalamoveDialog');
      }
    },

    emitCloseDialogSupplierAddress(){
      this.dialogChooseSupplierAddressVisible = false;
    },

    requestForQuotation() {
      this.$confirm('Request For Quotation ?')
        .then(async () => {
          this.$refs['form'].validate(async valid => {
            if (valid) {
              try {
                const result = await this.requestQuotation(this.form);
                const { status, message } = result;
                if (status) {
                  this.$notify({
                    title: 'Success',
                    message: 'Request Success',
                    type: 'success'
                  });
                } else {
                  const messageTranslate = 
                    this.$i18n.te(`src.components.shipping.lalamove.requestForQuotation.error.${message}`) ? 
                    this.$i18n.t(`src.components.shipping.lalamove.requestForQuotation.error.${message}`) :
                    message;

                  this.$notify({
                    title: 'Error',
                    message: messageTranslate,
                    type: 'error'
                  });
                }

                return;
              } catch (error) {
                console.log('error requestForQuotation: ', error);
                this.$notify({
                  title: 'Error',
                  message: 'Something wrong !!!',
                  type: 'error'
                });
                return;
              }
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        })
        .catch(() => {});
    },

    updateShippingInfo() {
      this.$confirm('Update ?')
        .then(async () => {
          try {
            const result = await this.updateInfo({
              ...this.form,
              id: this.lalaMoveInfo.id
            });

            const { status, message } = result;
            if (status) {
              this.$notify({
                title: 'Success',
                message: 'Update Success',
                type: 'success'
              });
            } else {
              this.$notify({
                title: 'Error',
                message: message,
                type: 'error'
              });
            }

            return;
          } catch (error) {
            console.log('error requestForQuotation: ', error);
            this.$notify({
              title: 'Error',
              message: 'Something wrong !!!',
              type: 'error'
            });
            return;
          }
        })
        .catch(() => {});
    },

    onClickPlaceOrder() {
      this.$confirm('Place Order ?')
        .then(async () => {
          try {
            const result = await this.placeOrder({
              ...this.form,
              totalFee: this.lalaMoveInfo.totalFee
            });

            const { status, message } = result;
            if (status) {
              this.$notify({
                title: 'Success',
                message: 'Request Success',
                type: 'success'
              });
            } else {
              this.$notify({
                title: 'Error',
                message: message,
                type: 'error'
              });
            }

            return;
          } catch (error) {
            console.log('error requestForQuotation: ', error);
            this.$notify({
              title: 'Error',
              message: 'Something wrong !!!',
              type: 'error'
            });
            return;
          }
        })
        .catch(() => {});
    },

    onClickAddSupplierAddressToShippingForm(){
      this.form.requesterAddress = this.formSupplierAddress.address;
      // this.form.requesterLocation = null,
      this.form.requesterPhone = this.formSupplierAddress.phone;
      this.form.requesterName = this.formSupplierAddress.receiverName;
      this.dialogChooseSupplierAddressVisible = false;
    },

    onClickShowShipperLocation() {
      window.open(this.shareLink);
    }
  }
};
</script>
<style scoped></style>
