<template>
  <div class="tables-basic" v-can="'customer-notification.read'">
    <b-row>
      <b-col>
        <!--  Search (S) -->
        <Widget>
          <el-row :gutter="24">
            <!-- Select Product (S) -->
            <el-col :span="6" :offset="15">
              <div class="grid-content">
                <el-date-picker
                  v-model="formSearch.createdAt"
                  type="daterange"
                  align="right"
                  unlink-panels
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  :default-time="['00:00:00', '23:59:59']"
                  :picker-options="pickerOptions"
                /></div
            ></el-col>
            <!-- Select Product (E) -->
            <el-col :span="3"
              ><div class="grid-content">
                <el-row type="flex" justify="end">
                  <el-button size="medium" type="info" @click="onClickSearch()">
                    <i class="el-icon-search"></i>&nbsp;
                    {{
                      $t('src.modules.customerNotification.index.btnSearch')
                    }}</el-button
                  >
                </el-row>
              </div></el-col
            >
          </el-row>
        </Widget>
        <!--  Search (E) -->
        <!--  Content (S) -->
        <Widget style="margin-bottom: 0px">
          <!-- Button Action (S) -->
          <el-row type="flex" justify="end">
            <el-col>
              <div class="grid-content">
                <el-row type="flex" justify="end">
                  <div class="main-actions">
                    <el-button
                      size="medium"
                      class="float-right"
                      style="margin-left: 5px;"
                      type="danger"
                      :disabled="isDisabledButtonDelete"
                      v-on:click="handleDeleteList()"
                      ><i class="el-icon-delete"></i
                      >{{ $i18n.t('src.modules.customerNotification.index.btnDelete') }}
                    </el-button>
                  </div>
                </el-row>
              </div>
            </el-col>
          </el-row>
          <!-- Button Action (E) -->

          <!-- Table Data (S) -->
          <el-row>
            <!--  Data (S) -->
            <el-table
              :data="pagedTableData"
              height="650"
              style="width: 100%"
              ref="multipleTable"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
              :default-sort="{ prop: 'createdAt', order: 'descending' }"
            >
              <!-- Checkbox (S) -->
              <el-table-column
                fixed="left"
                prop="id"
                type="selection"
                width="50"
                label="#"
              ></el-table-column>
              <!-- Checkbox (E) -->

              <!-- Id (S) -->
              <el-table-column prop="id" width="60" label="#"> </el-table-column>
              <!-- Id (E) -->

              <!-- Notification (S) -->
              <el-table-column
                prop="customerNotification"
                :label="$i18n.t('src.modules.customerNotification.index.col.content')"
                width="450"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    effect="dark"
                    :content="convertContent(scope.row.content)"
                    placeholder="bottom"
                    v-if="convertContent(scope.row.content).length > 50"
                  >
                    <span>{{ convertContent(scope.row.content) | truncate(50) }}</span>
                  </el-tooltip>
                  <span v-else>
                    {{ convertContent(scope.row.content) }}
                  </span>
                </template>
              </el-table-column>
              <!-- Notification (E) -->

              <!-- customer (S) -->
              <el-table-column
                prop="images"
                :label="$i18n.t('src.modules.customerNotification.index.col.images')"
                width="150"
              >
                <template slot-scope="scope">
                  <el-image style="width: 100px; height: 100px" :src="scope.row.images" />
                </template>
              </el-table-column>
              <!-- customer (E) -->

              <!-- reply (S) -->
              <el-table-column
                prop="isRead"
                :label="$i18n.t('src.modules.customerNotification.index.col.isRead')"
                width="150"
              >
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.isRead == 1"
                    type="success"
                    round
                    plain
                    disabled
                  >
                    {{
                      $i18n.t('src.modules.customerNotification.index.col.isRead.read')
                    }}
                  </el-button>
                  <el-button v-else type="info" round plain disabled>
                    {{
                      $i18n.t('src.modules.customerNotification.index.col.isRead.Unread')
                    }}
                  </el-button>
                </template>
              </el-table-column>
              <!-- reply (E) -->

              <!-- customer (S) -->
              <el-table-column
                prop="customerId"
                :label="$i18n.t('src.modules.customerNotification.index.col.customer')"
                sortable
                width="220"
              >
                <template slot-scope="scope">
                  {{ getCustomer(scope.row.customerId) }}
                </template>
              </el-table-column>
              <!-- customer (E) -->

              <!-- Created At (S) -->
              <el-table-column
                prop="createdAt"
                :label="$i18n.t('src.modules.customerNotification.index.col.createdAt')"
                sortable
                width="170"
              >
                <template slot-scope="scope">
                  {{ scope.row.createdAt | formattedDate }}
                </template>
              </el-table-column>
              <!-- Created At (S) -->

              <!-- Actions (S) -->
              <el-table-column
                :label="$i18n.t('src.modules.customerNotification.index.col.action')"
                style="width: 100%"
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    class="btn-red-sm"
                    v-can="'customer-notification.delete'"
                    v-on:click="handleDelete(scope)"
                  >
                    {{ $i18n.t('src.modules.quotation.index.button.delete') }}
                  </el-button>
                </template>
              </el-table-column>
              <!-- Actions (E) -->
            </el-table>
            <!--  Data (E) -->
          </el-row>

          <!--  Pagination (S) -->
          <el-row type="flex" justify="center">
            <el-col
              ><div class="grid-content bg-purple">
                <el-pagination
                  class="pagination"
                  @current-change="handleCurrentChange"
                  :current-page="currentPageChange"
                  :page-size="itemsPerPageChange"
                  layout="prev, pager, next"
                  :total="totalcommentCnt"
                >
                </el-pagination></div
            ></el-col>
          </el-row>
          <!--  Pagination (E) -->
        </Widget>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},
  data() {
    return {
      fileListSelectedForm: [],
      links: [],
      formSearch: {
        createdAt: ''
      },
      pickerOptions: {
        shortcuts: [
          {
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      dialogVisible: false,
      currentPageChange: 1,
      itemsPerPageChange: 10,
      multipleSelection: [],
      detail: {},
      dialogDetail: false
    };
  },
  computed: {
    ...mapState('customerNotification', ['CusNotifications']),
    ...mapState('customer', ['customers']),

    // Paging
    pagedTableData() {
      var arr = this.filterData();

      arr = arr.slice(
        this.itemsPerPageChange * this.currentPageChange - this.itemsPerPageChange,
        this.itemsPerPageChange * this.currentPageChange
      );
      return arr;
    },
    totalcommentCnt() {
      var arr = this.filterData();
      return arr.length || 0;
    },

    isDisabledButtonDelete() {
      return !this.multipleSelection.length > 0;
    }
  },

  created() {
    Promise.all([this.getAllCusNotification(this.formSearch), this.getAllCustomers({})]);
  },
  methods: {
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('customerNotification', [
      'getAllCusNotification',
      'deleteNotification',
      'deleteListNotification'
    ]),
    ...mapActions('customer', ['getAllCustomers']),

    filterData() {
      return this.CusNotifications;
    },

    convertContent(data) {
      if (data) {
        return data;
      } else {
        return 'No Notification';
      }
    },
    getCustomer(data) {
      const id = data;
      return (this.customers[id] && this.customers[id].customerName) || 'No User';
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // handle pagination (S)
    handleCurrentChange(val) {
      this.currentPageChange = val;
      // this.getAllbrands(this.formSearch);
    },
    async onClickSearch() {
      this.setGlobalReady(false);
      await this.getAllCusNotification(this.formSearch);
      this.setGlobalReady(true);
    },

    onClickShowPreview(rowCommnet) {
      this.detail = rowCommnet.row;
      this.dialogDetail = true;
    },
    handleClose() {
      this.detail = {};
      this.dialogDetail = false;
    },
    handleDelete(data) {
      this.$confirm(this.$i18n.t('Do you want to remove rows'))
        .then(async () => {
          const result = await this.deleteNotification(data);
          if (result) {
            this.$notify({
              title: 'Success',
              message: 'Delete Success',
              type: 'success'
            });
          } else {
            this.$notify({
              title: 'Error',
              message: 'Can not remove ',
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },
    async handleDeleteList() {
      this.$confirm('Do you want to remove rows')
        .then(async () => {
          if (this.multipleSelection.length > 0) {
            const result = await this.deleteListNotification(this.multipleSelection);
            if (result) {
              this.$notify({
                title: 'Success',
                message: 'Delete notification rows Success',
                type: 'success'
              });
            } else {
              this.$notify({
                title: 'Error',
                message: 'Can not remove notification rows',
                type: 'error'
              });
            }
          } else {
            this.$notify({
              title: 'Error',
              message: 'Can not remove rows',
              type: 'error'
            });
          }
        })
        .catch(() => {});
    }
    // handle pagination (E)
  }
};
</script>

<style src="./index.scss" lang="scss" />
