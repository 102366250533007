<template>
  <div class="tables-basic">
    <b-row>
      <b-col>
        <Widget>
          <el-row :gutter="24">
            <el-col :span="6">
              <div class="widgetInfo">
                <div class="wiDetail">{{ countStatus(CHO_XAC_NHAN) }}</div>
                <div class="wiName">{{ CHECKOUT_STATUS[CHO_XAC_NHAN] }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="widgetInfo">
                <div class="wiDetail">{{ countStatus(DA_XAC_NHAN) }}</div>
                <div class="wiName">{{ CHECKOUT_STATUS[DA_XAC_NHAN] }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="widgetInfo">
                <div class="wiDetail">{{ countStatus(DANG_GIAO_HANG) }}</div>
                <div class="wiName">{{ CHECKOUT_STATUS[DANG_GIAO_HANG] }}</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="widgetInfo">
                <div class="wiDetail">{{ countStatus(DA_NHAN_HANG) }}</div>
                <div class="wiName">{{ CHECKOUT_STATUS[DA_NHAN_HANG] }}</div>
              </div>
            </el-col>
          </el-row>
        </Widget>
        <Widget>
          <el-tabs type="card" @tab-click="handleClickTabs">
            <el-tab-pane label="Tất cả" value="0"></el-tab-pane>
            <el-tab-pane v-for="item in allStatusCheckoutSearch" :key="item.value" :label="item.text" :value="item.value"></el-tab-pane>
          </el-tabs>
          <el-row :gutter="20">
            <el-col :span="4"
              ><div class="grid-content">
                <el-input
                  class="inline-input"
                  v-model="formSearch.orderCode"
                  :fetch-suggestions="querySearch"
                  :placeholder="$i18n.t('src.modules.order.index.search.orderCode.placeholder')"
                  @input="debouncedHandler"
                  size="medium"
                  style="width: 100%"
                  clearable
                ></el-input></div
            ></el-col>
            <el-col :span="4"
              ><div class="grid-content">
                <el-input
                  class="inline-input"
                  v-model="formSearch.customerName"
                  :fetch-suggestions="querySearch"
                  :placeholder="$i18n.t('src.modules.order.index.search.customerName.placeholder')"
                  @input="debouncedHandler"
                  size="medium"
                  style="width: 100%"
                  clearable
                ></el-input></div
            ></el-col>
            <!-- <el-col :span="4"
              ><div class="grid-content">
                <el-select
                  size="medium"
                  v-model="formSearch.status"
                  :placeholder="
                    $i18n.t('src.modules.order.index.search.status.placeholder')
                  "
                  width="100%"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in allStatusCheckout"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  >
                    <span style="float: left">
                      <div
                        :style="
                          'background-color:' + item.color + 
                          ';width: 10px;height: 10px;display: inline-block;border-radius: 50%;margin-right: 10px;'
                        "
                      ></div>
                      {{ item.text }}</span
                    >
                  </el-option>
                </el-select>
              </div></el-col
            > -->
            <el-col :span="6"
              ><div class="grid-content">
                <el-date-picker
                  v-model="formSearch.createdAt"
                  type="daterange"
                  align="right"
                  :start-placeholder="$i18n.t('src.modules.order.index.startDate')"
                  :end-placeholder="$i18n.t('src.modules.order.index.endDate')"
                  :default-time="['00:00:00', '23:59:59']"
                  format="dd-MM-yyyy"
                  @change="handleChangeFilterDate"
                >
                </el-date-picker></div
            ></el-col>
            <el-col :span="10"
              ><div class="grid-content">
                <el-row type="flex" justify="end">
                  <div class="main-actions">
                    <el-button 
                      size="medium" 
                      type="success" 
                      @click="onClickExportExcelByFilter()"
                      :loading="isLoadingDownloadExcel"
                    >
                      <i class="el-icon-download mr-2" style="color: white"></i>&nbsp;{{ $i18n.t('src.modules.supplier.index.btnDownloadExcel') }}
                    </el-button>

                    <el-button size="medium" type="primary" @click="onClickShowAddPopup()"
                      ><i class="el-icon-plus mr-2"></i>&nbsp;{{ $i18n.t('src.modules.order.index.btnAdd') }}</el-button
                    >
                  </div>
                  <div class="main-actions">
                    <el-button size="medium" type="danger" :disabled="this.orderSelectedList.length === 0" @click="onClickDeleteData()"
                      ><i class="el-icon-delete mr-2"></i>&nbsp;{{ $i18n.t('src.modules.order.index.btnDelete') }}</el-button
                    >
                  </div>
                  <div class="main-actions">
                    <el-button size="medium" type="success" :disabled="this.orderAddLocal.length === 0 ? true : false" @click="onClickSaveMultiOrders()"
                      ><i class="el-icon-check mr-2"></i>&nbsp;{{ $i18n.t('src.modules.product.index.btnSave') }}</el-button
                    >
                  </div>
                </el-row>
                <!-- Button Action (S) -->
              </div></el-col
            >
          </el-row>

          <!-- Table partner Data (S) -->
          <el-row :gutter="20">
            <!-- <el-table
              v-loading="isLoading"
              ref="orderTable"
              :data="
                this.formSearch.orderCode === '' &&
                this.formSearch.customerName === '' &&
                this.formSearch.status === '' &&
                (this.formSearch.createdAt === '' || !this.formSearch.createdAt)
                  ? dataDisplayLocal
                  : pagedTableData
              "
              :default-sort="{ prop: 'updatedAt', order: 'descending' }"
              stripe
              style="width: 100%; min-height: 500px"
              @selection-change="handleSelectionChange"
            > -->
            <el-table
              v-loading="isLoading"
              ref="orderTable"
              :data="
                this.formSearch.orderCode === '' && this.formSearch.customerName === '' && this.formSearch.status === '' && (this.formSearch.createdAt === '' || !this.formSearch.createdAt)
                  ? dataDisplayLocal
                  : pagedTableData
              "
              :default-sort="{ prop: 'updatedAt', order: 'descending' }"
              stripe
              style="width: 100%; min-height: 500px"
              @selection-change="handleSelectionChange"
            >
              <!-- Check box (S) -->
              <el-table-column type="selection" width="50"></el-table-column>
              <!-- Check box (E) -->

              <!-- Id (S) -->
              <el-table-column width="200" :label="$i18n.t('src.modules.order.index.orderCode')" sortable fixed="left">
                <template slot-scope="scope">
                  <span v-if="scope.row.id">{{ scope.row.code }}</span>
                  <el-tag v-else type="danger" size="small" effect="dark">
                    {{ $i18n.t('src.modules.order.index.label.new') }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- Id (E) -->

              <!-- Logo (S) -->
              <el-table-column width="220" sortable :label="$i18n.t('src.modules.order.index.customer_name')" fixed="left">
                <template slot-scope="scope">
                  <el-tooltip effect="dark" :content="scope.row.customer.customerName" placeholder="bottom" v-if="scope.row.name.length > 100">
                    <span>{{ scope.row.customer.customerName | truncate(100) }}</span>
                  </el-tooltip>
                  <span v-else>
                    {{ scope.row.customer.customerName }}
                  </span>
                </template>
              </el-table-column>
              <!-- Logo (S) -->

              <!-- Title (S) -->
              <el-table-column prop="address" :label="$i18n.t('src.modules.order.index.address_checkout')" min-width="200">
                <template slot-scope="scope">
                  <el-tooltip effect="dark" :content="scope.row.address" placeholder="bottom" v-if="scope.row.address.length > 35">
                    <span>{{ scope.row.address | truncate(35) }}</span>
                  </el-tooltip>
                  <span v-else>
                    {{ scope.row.address }}
                  </span>
                </template>
              </el-table-column>
              <!-- Title (E)-->

              <!-- <el-table-column sortable label="Saled IC" width="300">
                <template slot-scope="scope">
                  <div v-if="scope.row.listSales.length > 1 && !isShowMoreSaleArr[scope.$index]" style="text-align: left" class="ml-1">
                    {{ scope.row.listSales[0] }}
                    <span>
                      <el-tag type="info" class="ml-2 mr-2">+ {{ scope.row.listSales.length - 1 }}</el-tag>
                      <i class="el-icon-arrow-down" style="cursor: pointer" @click="handleClickShowMoreSaleBtn(scope.$index)"></i>
                    </span>
                  </div>
                  <div v-if="isShowMoreSaleArr[scope.$index] === true" style="text-align: center;" class="ml-1">
                    <p v-for="(item, idx) in scope.row.listSales" v-bind:key="idx" style="text-align: left;">{{ item }}</p>
                    <i class="el-icon-arrow-up" style="cursor: pointer;" @click="handleClickCollapseSaleBtn(scope.$index)"></i>
                  </div>
                </template>
              </el-table-column> -->

              <el-table-column sortable :label="$i18n.t('src.modules.order.index.totalPrice')" width="170">
                <template slot-scope="scope">
                  {{
                    new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND'
                    }).format(scope.row.totalSalePrice)
                  }}
                </template>
              </el-table-column>
              <el-table-column sortable prop="createdAt" :label="$i18n.t('src.modules.order.index.createdAt/updatedAt')" width="200">
                <template slot-scope="scope">
                  <span>{{ scope.row.createdAt | formattedDateTime }}</span
                  ><br />
                  <span>{{ scope.row.updatedAt | formattedDateTime }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="status" :label="$i18n.t('src.modules.order.index.status')" width="150" fixed="right">
                <template slot-scope="scope">
                  <b-badge pill :class="scope.row.statusColor">{{ scope.row.statusName }}</b-badge>
                </template>
              </el-table-column>

              <el-table-column :label="$i18n.t('src.modules.order.index.accounting')" width="200">
                <template slot-scope="scope">
                  <b-badge pill class="status-blue" v-if="Array.isArray(scope.row.tags) && scope.row.tags.length >= 1 && scope.row.tags[0] === 'AMISExported'">
                    {{ $i18n.t('src.modules.order.index.accounting.pushedAmis') }}
                  </b-badge>
                  <br v-if="scope.row.tags.length > 1" />
                  <b-badge pill class="status-green" v-if="Array.isArray(scope.row.tags) && scope.row.tags.length > 1 && scope.row.tags[1] === 'InvoiceExported'">
                    {{ $i18n.t('src.modules.order.index.accounting.exportInvoice') }}
                  </b-badge>
                </template>
              </el-table-column>

              <el-table-column :label="$i18n.t('src.modules.order.index.action')" width="150" style="text-align: center" fixed="right">
                <template slot-scope="scope">
                  <el-button class="btn-blue-sm" type="primary" size="mini" @click="$router.push(`order/edit/${scope.row.id}`)">
                    <i class="el-icon-edit mr-1" /> {{ $i18n.t('common.label.edit') }}
                  </el-button>
                  <el-button class="btn-black-sm" type="info" size="mini" @click="onShowOrderHistoryPopup(scope)">
                    <i class="el-icon-document mr-1" /> {{ $i18n.t('common.label.orderHistory') }}
                  </el-button>
                  <el-button class="btn-red-sm" type="danger" size="mini" @click="onClickRemove(scope)">
                    <i class="el-icon-delete mr-1" /> {{ $i18n.t('common.label.delete') }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
          <!-- Table partner Data (E) -->

          <!-- Paging (S) -->
          <el-row type="flex" justify="center">
            <el-col
              ><div class="grid-content">
                <el-pagination
                  class="pagination"
                  @current-change="changePage"
                  @size-change="sizeChange"
                  :page-size="pageSize"
                  :page-sizes="[10, 20, 30]"
                  :current-page="currentPage"
                  layout="prev, pager, next"
                  :total="totalOrdersCnt"
                >
                </el-pagination></div
            ></el-col>
          </el-row>
          <!-- Paging (E) -->
        </Widget>
      </b-col>
    </b-row>
    <!-- Popup Add Order (S) -->
    <el-dialog :title="titlePopup" :visible.sync="addOrderDialogVisible" :before-close="handleCloseAddForm" width="25%" :close-on-click-modal="false">
      <!-- Inner Popup - Select Checkout Address -->
      <el-dialog
        width="35%"
        :title="$i18n.t('src.modules.order.index.selectDialogTitle')"
        :visible.sync="selectAddressDialogVisible"
        :before-close="handleCloseAddressForm"
        append-to-body
        custom-class="select-address-dialog"
        :close-on-click-modal="false"
      >
        <el-radio v-for="(value, index) in checkoutAddressList" v-bind:key="index" :label="index" v-model="selectedAddress" class="mb-4 d-block">{{ value }}</el-radio>
        <p class="text-center" v-if="this.checkoutAddressList.length === 0">
          Không có địa chỉ nào &#127773;
        </p>
        <el-radio :label="checkoutAddressList.length" v-bind:key="checkoutAddressList.length" v-model="selectedAddress" class="mb-4">
          {{ this.checkoutAddressList.length === 0 ? $i18n.t('src.modules.order.index.newOrder') : $i18n.t('src.modules.order.index.otherOrder') }}
        </el-radio>
        <!-- <div class="pl-3 pr-3">
          <el-select
            size="small"
            clearable
            v-model="formSearchAddress.province"
            placeholder="Select Province"
            width="100%"
            filterable
            @change="resetLocation('province')"
          >
            <el-option
              v-for="item in provinceList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
        <el-form
          :rules="addressFormRules"
          ref="formSearchAddress"
          :model="formSearchAddress"
          label-width="120px"
          size="small"
          class="pl-3 pr-3 pt-2"
          v-if="selectedAddress === checkoutAddressList.length"
        >
          <el-form-item :label="$i18n.t('src.modules.order.index.form.province.title')" prop="province">
            <el-select size="small" clearable v-model="formSearchAddress.province" width="100%" filterable @change="resetLocation('province')">
              <el-option :label="$i18n.t('src.modules.order.index.form.province.placeholder')" value=""></el-option>
              <el-option v-for="item in provinceList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$i18n.t('src.modules.order.index.form.district.title')" prop="district">
            <el-select size="small" clearable v-model="formSearchAddress.district" width="100%" filterable @change="resetLocation('district')">
              <el-option :label="$i18n.t('src.modules.order.index.form.district.placeholder')" value=""></el-option>
              <el-option v-for="item in districtList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$i18n.t('src.modules.order.index.form.ward.title')" prop="ward">
            <el-select size="small" clearable v-model="formSearchAddress.ward" width="100%" filterable>
              <el-option :label="$i18n.t('src.modules.order.index.form.ward.placeholder')" value=""></el-option>
              <el-option v-for="item in wardList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$i18n.t('src.modules.order.index.form.address.title')" prop="checkoutAddress">
            <el-input v-model="formSearchAddress.checkoutAddress" :placeholder="$i18n.t('src.modules.order.index.form.address.placeholder')"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-row :gutter="24">
            <el-col :span="24">
              <!-- <el-button size="medium" type="primary" @click="onClickAddRow()">
              <i class="el-icon-plus mr-2"></i
              >{{ $i18n.t('src.modules.order.index.btnAdd') }}</el-button
            >
            <el-button size="medium" type="success" @click="onClickSaveOrders()"
              ><i class="el-icon-circle-check mr-2"></i
              >{{ $i18n.t('src.modules.partner.index.btnSave') }}</el-button
            > -->
              <el-button size="medium" type="success" @click="onConfirmSelectAddress"><i class="el-icon-check mr-2" />{{ $i18n.t('src.modules.order.index.form.address.btnSelect') }}</el-button>
            </el-col>
          </el-row>
        </span>
      </el-dialog>
      <!-- Name (S) -->
      <el-select
        size="medium"
        v-model="formAdd.customerId"
        :placeholder="$i18n.t('src.modules.order.index.select.customer')"
        width="100%"
        filterable
        clearable
        style="margin-bottom: 20px"
        @change="handleChangeCustomer"
        @clear="handleClearOption"
      >
        <el-option v-for="item in allCustomer" :key="item.value" :label="item.text" :value="item.value"> </el-option>
      </el-select>
      <el-form ref="formAddOrder" :rules="ruleAddForm" :model="formAdd" style="margin-left: 0px" class="demo-ruleForm">
        <!-- Title (E) -->
        <h5 class="pb-3 border-bottom">
          {{ $i18n.t('src.modules.order.index.form_add.orderInfo') }}
        </h5>
        <!-- Desc (S) -->
        <el-form-item :label="$i18n.t('src.modules.order.index.receiver')" prop="name" style="margin-bottom: 10px">
          <el-input size="medium" placeholder="Please input name" class="input_add" v-model="formAdd.name" clearable />
        </el-form-item>
        <!-- Desc (E) -->
        <!-- Desc (S) -->
        <el-form-item :label="$i18n.t('src.modules.order.index.phone_number')" prop="phone" style="margin-bottom: 10px">
          <el-input size="medium" placeholder="Please input phone" class="input_add" v-model="formAdd.phone" clearable />
        </el-form-item>
        <!-- Desc (E) -->

        <el-form-item :label="$i18n.t('src.modules.order.index.email')" prop="email" style="margin-bottom: 10px">
          <el-input size="medium" placeholder="Please input email" class="input_add" v-model="formAdd.email" clearable />
        </el-form-item>
        <!-- Desc (E) -->

        <el-form-item :label="$i18n.t('src.modules.order.index.delivery_address')" prop="address" style="margin-bottom: 10px">
          <!-- <el-checkbox :v-model="checked" @change="handleChangeAddress">{{formAdd.address}}</el-checkbox> -->
          <!-- <el-input
            size="medium"
            placeholder="Please input address"
            class="input_add"
            v-model="formAdd.address"
            clearable
          /> -->
          <el-button type="warning" size="small" @click="selectAddressDialogVisible = true" :disabled="this.formAdd.customerId === '' ? true : false">{{
            $i18n.t('src.modules.order.index.form.address.btnSelect')
          }}</el-button>
        </el-form-item>
        <p class="ml-2">{{ fullAddress }}</p>
        <!-- <el-form-item
          v-if="checkedAddress === true"
          :label="checkedAddress === false ? $i18n.t('src.modules.order.index.delivery_address')
          : $i18n.t('src.modules.order.index.order_address')"
          prop="address"
          style="margin-bottom: 10px; "
        >
         
        </el-form-item> -->
        <!-- Desc (E) -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-button size="medium" type="primary" @click="onClickAddRow()"> <i class="el-icon-plus mr-2"></i>{{ $i18n.t('src.modules.order.index.btnAdd') }}</el-button>
            <el-button size="medium" type="success" @click="onClickSaveOrders()"><i class="el-icon-circle-check mr-2"></i>{{ $i18n.t('src.modules.partner.index.btnSave') }}</el-button>
          </el-col>
        </el-row>
      </span>
    </el-dialog>
    <el-dialog :title="orderHisoryTitle" :visible.sync="orderHistoryDialogVisable" width="30%" :close-on-click-modal="false">
      <div class="block">
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :icon="activity.icon"
            :type="activity.type"
            :color="activity.color"
            :size="activity.size"
            :timestamp="activity.timestamp"
            placement="top"
          >
            {{ activity.content }}
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>

    <!-- Popup Add partner (E) -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash.debounce';
import { CHECKOUT_STATUS, ORDER_SUPPLIER_COLOR, CHECKOUT_COLOR, CHO_XAC_NHAN, DA_XAC_NHAN, DANG_GIAO_HANG, DA_NHAN_HANG } from 'core/constants';
import vnLocal from 'vn-local';
// import cloneDeep from 'lodash/cloneDeep';
import { convertToSlug } from 'core/helpers';
import XLSX from 'xlsx';

export default {
  data() {
    return {
      editorConfig: {
        language: 'en'
      },
      isLoadingDownloadExcel: false,
      loading: false,
      isLoading: true,
      activities: [],
      //Table partner
      orderSelectedList: [],
      orderHisoryTitle: '',
      // Paging
      currentPage: 1,
      pageSize: 10,
      value2: '',
      isShowMoreSaleArr: [],

      // Rule Form Add, Edit
      ruleAddForm: {
        name: [
          {
            required: true,
            message: this.$i18n.t('src.modules.order.index.rule.name'),
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: this.$i18n.t('src.modules.order.index.rule.phone'),
            trigger: 'blur'
          },
          { validator: this.validatePhone, trigger: 'blur' },
          {
            min: 10,
            max: 11,
            message: 'Phone Number length should be 10 to 11',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: this.$i18n.t('src.modules.order.index.rule.email'),
            trigger: 'blur'
          },
          { validator: this.validateEmail, trigger: 'blur' }
        ],
        address: [
          {
            required: true,
            message: this.$i18n.t('src.modules.order.index.rule.address'),
            trigger: 'blur'
          }
        ]
      },

      // Address Form Rule
      addressFormRules: {
        province: [{ required: true, message: 'Please select province', trigger: 'change' }],
        district: [{ required: true, message: 'Please select district', trigger: 'change' }],
        ward: [{ required: true, message: 'Please select ward', trigger: 'change' }],
        checkoutAddress: [{ required: true, message: 'Please input address', trigger: 'blur' }]
      },

      // Search Form
      formSearch: {
        orderCode: '',
        customerName: '',
        status: '',
        createdAt: ''
      },
      searchLocal: '',
      // Popup Add
      addOrderDialogVisible: false,
      selectAddressDialogVisible: false,
      listCheckoutAddress: [],
      selectedAddress: 0,
      formSearchAddress: {
        province: '',
        district: '',
        ward: '',
        checkoutAddress: ''
      },
      formSearchAddressDefault: {
        province: '',
        district: '',
        ward: '',
        checkoutAddress: ''
      },
      fileListSelectedFormPartner: [],
      //Popup Add Orders
      labelPosition: 'top',
      showDefaultAddress: false,
      checkDefaultAddress: true,
      ortherAddress: '',
      customer: '',
      formAdd: {
        name: '',
        phone: '',
        email: '',
        address: '',
        province: '',
        district: '',
        ward: ''
      },
      formAddDefault: {
        name: '',
        phone: '',
        email: '',
        address: '',
        province: '',
        district: '',
        ward: ''
      },
      formMode: 'add',

      // Popup Upload File
      showUploadFile: false,

      current_page: 1,
      dialogVisibleFile: false,
      disabled: false,
      idEdit: '',
      dialogVisible: false,

      // Popup Order History
      orderHistoryDialogVisable: false,

      // Default Add Order Status
      defaultStatus: 1,

      CHECKOUT_STATUS,
      CHO_XAC_NHAN,
      DA_XAC_NHAN,
      DANG_GIAO_HANG,
      DA_NHAN_HANG
    };
  },

  computed: {
    ...mapState('order', ['orders', 'orderAddLocal', 'totalRows', 'orderDataExportExcel']),
    ...mapState('orderStatus', ['listStatus']),
    ...mapState('partnerCategory', ['partnerCategory', 'partnerCategoryMap']),
    ...mapState('customer', ['customersWithAddress']),
    ...mapState('common', ['listCommons']),
    ...mapState('user', ['usersMap']),
    ...mapState('auth', ['user']),


    allStatusCheckoutSearch() {
      const data = this.listCommons.reduce((objKey, obj) => {
        console.log(obj)
        if (parseInt(obj.code) !== 1) {
          objKey.push({
            value: obj.code,
            text: obj.name,
            color: ORDER_SUPPLIER_COLOR[parseInt(obj.code)]
          });
        }
        return objKey;
      }, []);
      return data.sort((a, b) => (parseInt(a.value) > parseInt(b.value) ? 1 : parseInt(b.value) > parseInt(a.value) ? -1 : 0));
    },

    // get Full Address
    fullAddress() {
      const { address, district, ward } = this.formAdd;
      if (address == '' || district == '' || ward == '') return '';
      else {
        const districtList = vnLocal.getWardsByDistrictCode(district);
        const provinceName = districtList?.[0]?.provinceName || '';
        const districtName = districtList?.[0]?.districtName || '';
        const wardName = (ward && districtList?.find(d => d.code === ward)?.name) || '';
        return address + ', ' + wardName + ', ' + districtName + ', ' + provinceName;
      }
    },

    //get list province
    provinceList() {
      return vnLocal.getProvinces()?.map(item => {
        return {
          value: item.code,
          label: item.name
        };
      });
    },

    districtList() {
      return this.formSearchAddress.province
        ? vnLocal.getDistrictsByProvinceCode(this.formSearchAddress.province)?.map(item => {
            return {
              value: item.code,
              label: item.name
            };
          })
        : [];
    },

    wardList() {
      return this.formSearchAddress.district
        ? vnLocal.getWardsByDistrictCode(this.formSearchAddress.district)?.map(item => {
            return {
              value: item.code,
              label: item.name
            };
          })
        : [];
    },

    checkoutAddressList() {
      return this.listCheckoutAddress.map(item => {
        const district = item.district ? vnLocal.getWardsByDistrictCode(item.district) : '';
        const provinceName = district?.[0]?.provinceName || '';
        const districtName = district?.[0]?.districtName || '';
        const wardName = (item.ward && district?.find(x => x.code === item.ward)?.name) || '';
        return item.address + ', ' + wardName + ', ' + districtName + ', ' + provinceName;
      });
    },

    allOrders() {
      let queryString = [];

      if (this.formSearch.orderCode) {
        queryString.push(
          data =>
            convertToSlug(data.code.toLowerCase()).includes(convertToSlug(this.formSearch.orderCode.toLowerCase())) ||
            data.id
              .toString()
              .toLowerCase()
              .includes(this.formSearch.orderCode.toLowerCase().trim())
        );
        ('');
      }
      // query filter customer Name
      if (this.formSearch.customerName) {
        queryString.push(
          data =>
            convertToSlug(data.name.toLowerCase()).includes(convertToSlug(this.formSearch.customerName.toLowerCase())) ||
            data.id
              .toString()
              .toLowerCase()
              .includes(this.formSearch.customerName.toLowerCase().trim())
        );
      }
      // query filter status
      if (this.formSearch.status) {
        queryString.push(data => data.statusCode === this.formSearch.status);
      }

      // query filter created date
      if (this.formSearch.createdAt) {
        queryString.push(data => {
          const itemDate = new Date(data.createdAt);
          const fromDate = new Date(this.formSearch.createdAt[0]);
          const toDate = new Date(this.formSearch.createdAt[1]);
          if (itemDate > fromDate && itemDate < toDate) return true;
          else return false;
        });
      }
      // filter
      let result = this.orders.filter(data => queryString.reduce((accumulator, queryFilter) => accumulator && queryFilter(data), true));
      return result.map(item => {
        const { address, district, ward } = item;
        let addressString = '';
        if (!address && !district) {
          addressString = 'No Data';
        } else {
          const districtList = vnLocal.getWardsByDistrictCode(district);
          const wardName = (ward && districtList?.find(x => x.code === ward)?.fullName) || '';
          addressString = address + ' ,' + wardName;
        }
        const lstSaleStr = item.saleId || '';
        const withoutFirstAndLastSaletStr = lstSaleStr.slice(1, -1); // remove first and last '|' character in string
        const result1 = withoutFirstAndLastSaletStr.split('|').reduce((rst, saleId) => {
          if (typeof this.usersMap[saleId] !== 'undefined' && Object.keys(this.usersMap[saleId]).length !== 0) {
            if (this.usersMap[saleId].firstName && this.usersMap[saleId].lastName) rst.push(this.usersMap[saleId].firstName + ' ' + this.usersMap[saleId].lastName);
            else if (this.usersMap[saleId].username) rst.push(this.usersMap[saleId].username);
            return rst;
          }
        }, []);
        console.log(result1);
        return {
          ...item,
          statusName: CHECKOUT_STATUS[item.statusCode],
          statusColor: CHECKOUT_COLOR[item.statusCode],
          address: addressString,
          listSales: result1
        };
      });
    },

    allCustomer() {
      return this.customersWithAddress.map(item => {
        return {
          text: item.customerName,
          value: item.id
        };
      });
    },

    allStatusCheckout() {
      const data = this.listCommons.map(item => {
        return {
          value: item.code,
          text: item.name,
          color: CHECKOUT_COLOR[parseInt(item.code)]
        };
      });
      return data;
    },

    titlePopup() {
      switch (this.formMode) {
        case 'add':
          return this.$i18n.t('src.modules.order.index.form_add');
        case 'edit':
          return this.$i18n.t('src.modules.partner.index.form_edit');
        case 'editAdd':
          return this.$i18n.t('src.modules.partner.index.form_edit');
        default:
          return this.$i18n.t('src.modules.partner.index.form_add');
      }
    },

    // Paging
    pagedTableData() {
      // return this.allOrders.slice(
      //   this.pageSize * this.currentPage - this.pageSize,
      //   this.pageSize * this.currentPage
      // );
      return this.orders.map(item => {
        const { address, district, ward } = item;
        let addressString = '';
        if (!address && !district) {
          addressString = 'No Data';
        } else {
          const districtList = vnLocal.getWardsByDistrictCode(district);
          const wardName = (ward && districtList?.find(x => x.code === ward)?.fullName) || '';
          addressString = address + ' ,' + wardName;
        }
        const listAccouting = item.tags ? JSON.parse(item.tags) : '';
        const lstSaleStr = item.saleId || '';
        const withoutFirstAndLastSaletStr = lstSaleStr.slice(1, -1); // remove first and last '|' character in string
        const lstSalesArr =
          withoutFirstAndLastSaletStr !== ''
            ? withoutFirstAndLastSaletStr.split('|').reduce((rst, saleId) => {
                if (typeof this.usersMap[saleId] !== 'undefined' && Object.keys(this.usersMap[saleId]).length !== 0) {
                  if (this.usersMap[saleId].firstName && this.usersMap[saleId].lastName) rst.push(this.usersMap[saleId].firstName + ' ' + this.usersMap[saleId].lastName);
                  else if (this.usersMap[saleId].username) rst.push(this.usersMap[saleId].username);
                  return rst;
                }
              }, [])
            : [];

        return {
          ...item,
          statusName: CHECKOUT_STATUS[item.statusCode],
          statusColor: CHECKOUT_COLOR[item.statusCode],
          address: addressString,
          tags: listAccouting,
          listSales: lstSalesArr,
          isShowMoreSale: false
        };
      });
    },

    dataDisplayLocal() {
      const listOrderLocal = this.orderAddLocal.map(item => {
        const { address, district, ward } = item;
        const districtList = vnLocal.getWardsByDistrictCode(district);
        const wardName = (ward && districtList?.find(x => x.code === ward)?.fullName) || '';
        return {
          ...item,
          address: address + ' ,' + wardName
        };
      });
      return [...this.pagedTableData, ...listOrderLocal];
    },

    totalOrdersCnt() {
      return this.totalRows || 0;
    },

    // Combo Category
    categoryList() {
      return this.partnerCategory.map(item => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },

    // Add Form
    newsImages() {
      return this.formPartner.logo;
    },

    showNewsImages() {
      if (this.newsImages && this.newsImages == '') return false;
      return true;
    }
  },

  watch: {
    partnerAddLocal() {
      const rowsToToggle = this.pagedTableData.filter(row => !row.id);
      this.toggleSelection(rowsToToggle);
    },
    currentPage() {
      this.isLoading = true;
      this.getAllOrders({ ...this.formSearch, page: this.currentPage, pageSize: this.pageSize, saleId: this.user && this.user.id || '' }).then(() => (this.isLoading = false));
    }
  },

  async created() {
    // catch getMe
    if (Object.keys(this.user).length === 0) {
      await this.getMe();
    }
    this.isShowMoreSaleArr = Array(this.pageSize).fill(false);
    this.debouncedHandler = debounce(() => {
      this.isLoading = true;
      this.currentPage = 1;
      this.getAllOrders({ ...this.formSearch, page: this.currentPage, pageSize: this.pageSize, saleId: this.user && this.user.id || ''}).then(() => (this.isLoading = false));
    }, 1000);
    await Promise.all([
      this.getAllCustomersWithAddress({}),
      this.getAllUsers(),
      this.getAllStatus(),
      this.getAllCommons({ name: '', comCode: 'ORDR_STT' }),
      this.getAllOrders({ ...this.formSearch, page: this.currentPage, pageSize: this.pageSize, saleId: this.user && this.user.id || ''})
    ]).then(() => {
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions('order', ['getAllOrders', 'saveAddOrders', 'addOrdersLocal', 'deleteOrder', 'deleteOrderList', 'getAllDataExportExcel']),
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('customer', ['getAllCustomersWithAddress']),
    ...mapActions('orderStatus', ['getAllStatus']),
    ...mapActions('auth', ['getMe']),
    ...mapActions('common', ['getAllCommons']),
    ...mapActions('user', ['getAllUsers']),

    async onClickExportExcelByFilter(){
      this.isLoadingDownloadExcel = true;

      const result = await this.getAllDataExportExcel(this.formSearch);
      const data = this.orderDataExportExcel;

      if(!result){
        this.$notify({
          title: 'Error',
          message: 'Something wrong',
          type: 'error'
        });
      };

      if(result && (result.length <= 0 || data.length <= 0)){
        this.$notify({
          title: 'Warning',
          message: 'Data is empty',
          type: 'Warning'
        });
        this.isLoadingDownloadExcel = false;
        return false
      };

      try {
        //generate sheet
        const ws = XLSX.utils.json_to_sheet(data);
        const setWidth = [
          {width: 20},
          {width: 20},
          {width: 25},
          {width: 20},
          {width: 30},
          {width: 20},
          {width: 20},
          {width: 20},
          {width: 20}
        ];

        ws['!cols'] = setWidth;

        //generate new book
        const wb = XLSX.utils.book_new();
        const fileName = `PO_${Date.now()}.xlsx`;
        XLSX.utils.book_append_sheet(wb, ws, 'Report PO');

        //generate excel file
        XLSX.writeFile(wb, fileName, { bookType: 'xlsx', type: 'base64', bookSST: true });

        this.isLoadingDownloadExcel = false;
        return true;
      } catch (error) {
        console.log('error onClickExportExcelByFilter: ', error);
        return false;
      }
    },

    handleClickTabs(tab) {
      this.currentPage = 1;
      this.formSearch.status = tab.$attrs.value && Number(tab.$attrs.value) > 0 ? tab.$attrs.value : null;
      this.getAllOrders({ ...this.formSearch, page: this.currentPage, pageSize: this.pageSize, saleId: this.user && this.user.id || ''}).then(() => (this.isLoading = false));
    },

    /** Validate method   **/
    validateEmail(rule, value, callback) {
      const validate = /^(([^<>()[\]\\.,;:\s@|"]+(\.[^<>()[\]\\.,;:\s@|"]+)*)|(|".+|"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (validate.test(value) == false) {
        callback('Email incorrect');
      } else {
        callback();
      }
    },

    validatePhone(rule, value, callback) {
      var validate = /(([\\+84|84|0]+(2|3|5|7|8|9|1[2|6|8|9]))+([0-9]{8,11})\b)/g;

      if (validate.test(value) == false) {
        callback('Phone Number incorrect');
      } else {
        callback();
      }
    },
    /** Validate method   **/

    /** common method */
    formatDate(strDate) {
      const date = new Date(strDate);
      return date.getDate() + ' - ' + date.getMonth() + ' - ' + date.getFullYear();
    },

    // Format date time
    formatDateTime(strDate) {
      const date = new Date(strDate);
      const day = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate();
      const month = date.getMonth().toString().length === 1 ? '0' + parseInt(date.getMonth() + 1) : date.getMonth();
      const minute = date.getMinutes().toString().length === 1 ? '0' + date.getMinutes() : date.getMinutes();
      const second = date.getSeconds().toString().length === 1 ? '0' + date.getSeconds() : date.getSeconds();
      return day + '/' + month + '/' + date.getFullYear() + ' ' + date.getHours() + ':' + minute + ':' + second;
    },
    /** common method */

    /** customize table style method **/
    headerRow({ rowIndex }) {
      if (rowIndex === 0) {
        return 'headerRow';
      }
    },
    /** customize table style method **/

    /** main page method */
    handleSelectionChange(val) {
      this.orderSelectedList = val;
    },
    changePage(val) {
      this.currentPage = val;
    },
    sizeChange(val) {
      this.pageSize = val;
    },
    onClickDeleteData() {
      this.$confirm(this.$i18n.t('src.modules.order.index.remove.message'))
        .then(async () => {
          if (this.orderSelectedList.length > 0) {
            const result = await this.deleteOrderList(this.orderSelectedList);
            if (result) {
              this.$notify({
                title: this.$i18n.t('common.notify.title.success'),
                message: this.$i18n.t('common.notify.message.success.remove', {
                  name: 'orders'
                }),
                type: 'success'
              });
            } else {
              this.$notify({
                title: this.$i18n.t('common.notify.title.error'),
                message: this.$$i18n.t('common.notify.message.success.remove2', {
                  name: 'orders'
                }),
                type: 'error'
              });
            }
          } else {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$$i18n.t('common.notify.message.success.remove2', {
                name: 'orders'
              }),
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },
    async onClickAddRow() {
      this.$refs['formAddOrder'].validate(async valid => {
        if (valid) {
          try {
            const data = {
              ...this.formAdd,
              createdAt: Date.now(),
              updatedAt: Date.now(),
              totalPrice: 0,
              status: 1,
              statusName: 'Báo giá'
            };
            await this.addOrdersLocal(data);
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('common.notify.message.add.success', {
                item: 'row'
              }),
              type: 'success'
            });
            this.clearFormOrder();
            this.defaultStatus = 1;
            this.formAdd.customerId = '';
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.add.failr', { name: 'row' }),
              type: 'error'
            });
          }
        } else {
          return false;
        }
      });
    },
    async onClickSaveMultiOrders() {
      if (this.orderAddLocal.length > 0) {
        const data = await this.saveAddOrders(this.orderAddLocal);
        if (data == true) {
          this.$notify({
            title: this.$i18n.t('common.notify.title.success'),
            message: this.$i18n.t('common.notify.message.save.success', {
              name: 'List Post'
            }),
            type: 'success'
          });
          this.currentPage = 1;
          this.getAllOrders({ ...this.formSearch, page: this.currentPage, pageSize: this.pageSize, saleId: this.user && this.user.id || '' }).then(() => (this.isLoading = false));
        } else {
          this.$notify({
            title: this.$i18n.t('common.notify.title.error'),
            message: this.$i18n.t('common.notify.message.save.fail', {
              name: 'List Post'
            }),
            type: 'error'
          });
        }
      } else {
        this.$notify({
          title: this.$i18n.t('common.notify.title.error'),
          message: this.$i18n.t('common.notify.message.save.fail_null'),
          type: 'error'
        });
      }
    },
    async onClickSaveOrders() {
      this.$refs['formAddOrder'].validate(async valid => {
        if (valid) {
          try {
            const newLocalOrder = {
              ...this.formAdd,
              createdAt: Date.now(),
              updatedAt: Date.now(),
              totalPrice: 0,
              status: 1,
              statusName: 'Báo giá',
              saleId: this.user && this.user.id || ''
            };
            const data = await this.saveAddOrders([newLocalOrder]);
            if (data == true) {
              this.$notify({
                title: this.$i18n.t('common.notify.title.success'),
                message: this.$i18n.t('common.notify.message.save.success', {
                  name: 'List Post'
                }),
                type: 'success'
              });
              this.clearFormOrder();
              this.formAdd.customerId = '';
              this.currentPage = 1;
              this.getAllOrders({ ...this.formSearch, page: this.currentPage, pageSize: this.pageSize, saleId: this.user && this.user.id || '' }).then(() => (this.isLoading = false));
            } else {
              this.$notify({
                title: this.$i18n.t('common.notify.title.error'),
                message: this.$i18n.t('common.notify.message.save.fail', {
                  name: 'List Post'
                }),
                type: 'error'
              });
            }
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.add.failr', { name: 'row' }),
              type: 'error'
            });
          }
        } else {
          return false;
        }
      });
    },
    handleCloseAddForm(done) {
      var message = null;
      if (!isEqual(this.formAdd, this.formAddDefault)) {
        message = this.$i18n.t('common.confirm.close.form.out', { name: 'orders' });
        this.$confirm(message)
          .then(() => {
            this.clearFormOrder();
            this.addOrderDialogVisible = false;
            done();
          })
          .catch(() => {});
      } else {
        this.addOrderDialogVisible = false;
      }
    },
    clearFormOrder() {
      this.$refs['formAddOrder'].resetFields(); //Remove the verification result and reset the field value
      this.$refs['formAddOrder'].clearValidate();
      this.customer = '';
    },
    async onClickRemove(index) {
      this.$confirm(`Do you want to remove order ${index.row.id ? '#' + index.row.id : ''} ? `)
        .then(async () => {
          const result = await this.deleteOrder(index.row);
          if (result) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: `Delete order ${index.row.id ? '#' + index.row.id : ''} success`,
              type: 'success'
            });
          } else {
            this.$notify({
              title: 'Error',
              message: `Can not remove order ${index.row.name}-> Error: ${result.message}`,
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },
    /** Add Modal Event **/
    onClickShowAddPopup() {
      this.addOrderDialogVisible = true;
    },

    handleChangeCustomer(val) {
      let customer_info = this.customersWithAddress.find(cus => cus.id === val);
      this.formAdd = {
        ...this.formAdd,
        name: customer_info.customerName,
        phone: customer_info.phone,
        email: customer_info.email,
        address: ''
      };
      this.listCheckoutAddress = customer_info.dataAddress;
      this.$refs['formAddOrder'].clearValidate();
    },
    handleClearOption() {
      this.$refs['formAddOrder'].resetFields(); //Remove the verification result and reset the field value
      this.$refs['formAddOrder'].clearValidate();
    },
    /** Add Modal Event **/

    /* ---  Select Address Modal Event --- */
    onShowSelectAddressPopup() {
      this.selectAddressDialogVisible = true;
    },

    /** Order History Popup Event */
    onShowOrderHistoryPopup(index) {
      this.orderHisoryTitle = `Order #${index.row.id} History Status`;
      this.activities = index.row.orderHistories.map((item, index) => {
        return {
          content: item.orderStatus.name,
          timestamp: this.formatDateTime(item.createdAt),
          color: index === 0 ? '#0bbd87' : '',
          size: index === 0 ? 'large' : 'normal'
        };
      });
      this.orderHistoryDialogVisable = true;
    },
    getCustomerName() {
      return this.customersWithAddress.map(item => {
        return {
          value: item.customerName
        };
      });
    },
    querySearch(queryString, cb) {
      var links = this.getCustomerName();
      // this.orders.filter(item => item.name.toLowerCase().includes(this.formSearch.customerName.toLowerCase()));
      var results = queryString ? links.filter(this.createFilter(queryString)) : links;
      // call callback function to return suggestions
      cb(results);
    },

    createFilter(queryString) {
      return link => {
        return link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },

    clearFormAddress() {
      this.selectedAddress = 0;
      this.$refs['formSearchAddress'].resetFields(); //Remove the verification result and reset the field value
      this.$refs.formSearchAddress.clearValidate(['province', 'district', 'ward']);
    },

    handleCloseAddressForm(done) {
      var message = null;
      if (!isEqual(this.formSearchAddress, this.formSearchAddressDefault) || this.selectedAddress !== '') {
        message = this.$i18n.t('common.confirm.close.form.out', { name: 'orders' });
        this.$confirm(message)
          .then(() => {
            //this.clearFormAddress();
            this.selectAddressDialogVisible = false;
            this.clearFormAddress();
            done();
          })
          .catch(() => {});
      } else {
        this.selectAddressDialogVisible = false;
      }
    },

    resetLocation(name) {
      if (name === 'province') {
        this.formSearchAddress.district = '';
        this.formSearchAddress.ward = '';
        this.$refs['formSearchAddress'].clearValidate(['district', 'ward']);
      }
      if (name === 'district') {
        this.formSearchAddress.ward = '';
      }
    },

    // Confirm select Address
    onConfirmSelectAddress() {
      if (this.selectedAddress !== this.checkoutAddressList.length) {
        const index = this.selectedAddress;
        const { province, district, ward, address } = this.listCheckoutAddress[index];
        this.formAdd = {
          ...this.formAdd,
          province,
          district,
          ward,
          address
        };
        this.selectAddressDialogVisible = false;
        this.$refs['formAddOrder'].clearValidate('address');
      } else {
        this.$refs['formSearchAddress'].validate(valid => {
          if (valid) {
            const { province, district, ward, checkoutAddress } = this.formSearchAddress;
            this.formAdd = {
              ...this.formAdd,
              province,
              district,
              ward,
              address: checkoutAddress
            };
            this.clearFormAddress();
            this.selectAddressDialogVisible = false;
            this.$refs['formAddOrder'].clearValidate('address');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    countStatus(status) {
      let result = this.orders.filter(data => data.statusCode == status);
      return result.length;
    },
    handleChangeFilterDate() {
      this.isLoading = true;
      this.currentPage = 1;
      this.getAllOrders({ ...this.formSearch, page: this.currentPage, pageSize: this.pageSize , saleId: this.user && this.user.id || ''}).then(() => (this.isLoading = false));
    },
    handleClickShowMoreSaleBtn(index) {
      const oldIsShowMoreSaleArr = this.isShowMoreSaleArr;
      oldIsShowMoreSaleArr[index] = true;
      this.isShowMoreSaleArr = oldIsShowMoreSaleArr.slice(0);
    },

    handleClickCollapseSaleBtn(index) {
      const oldIsShowMoreSaleArr = this.isShowMoreSaleArr;
      oldIsShowMoreSaleArr[index] = false;
      this.isShowMoreSaleArr = oldIsShowMoreSaleArr.slice(0);
    }
  }
};
</script>
<style scoped>
.el-table .headerRow th {
  background: #005792;
  color: white;
}
/* .el-timeline-item__timestamp.is-top {
  margin-bottom: 8px;
  padding-top: 4px;
  position: absolute;
  top:0;
  left: 0;
} */
.el-timeline-item__timestamp.is-top {
  position: absolute;
  left: -117px;
  top: 0;
  margin-left: -20px;
}
.el-timeline li:first-child .el-timeline-item__timestamp.is-top {
  font-weight: bold;
}

.el-timeline li:first-child .el-timeline-item__content {
  font-weight: bold;
}
.el-timeline {
  padding-left: 150px;
}
.select-address-dialog .el-dialog__body {
  padding-top: 15px;
  padding-bottom: 15px;
}
.select-address-dialog .el-dialog__header {
  padding-bottom: 20px;
}
.el-form-item__error {
  padding-top: 0px !important;
}
</style>
<style src="./index.scss" lang="scss" scoped />
