import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
import cloneDeep from 'lodash/cloneDeep';
const state = {
  orderMessages:[],
};

// import cloneDeep from 'lodash/cloneDeep'
// import { isEqual } from 'lodash';

const mutations = {
  
  [Type.SET_ALL_MESSAGE](state, value) {
    state.orderMessages = value
  },
  [Type.SET_MESSAGE_ADD](state, value) {
    const oldMessage = state.orderMessages
    // add new data
    state.orderMessages = [...oldMessage, cloneDeep(value)]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};