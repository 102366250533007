import Service from 'core/service';
import { cloneDeep } from 'lodash';
import * as Type from 'modules/quotationDetail/store/types';
import * as QuotationType from 'modules/quotation/store/types';
// import { quotationInfoPDF } from 'modules/quotationDetail/template/quotationInfoPDF';
const service = new Service();

const _convertData = row => {
  const newData = cloneDeep(row);
  return newData;
};

const getQuotationDetailByQuotationId = async ({ commit }, value) => {
  const {
    quotationId = '',
    supplierId = ''
  } = value;
  const params = {};
  if(quotationId !== '') {
    params.quotationId = quotationId;
  }
  if(supplierId !== '') {
    params.supplierId = supplierId;
  }
  return service
    .rest('quotationDetail/getByQuotationId', params)
    .then(res => {
      commit(Type.GET_BY_QUOTATION_ID, res);
      return true;
    })
    .catch(err => {
      console.log(err);
    });
};
const addQuotationDetailLocal = async ({ commit }, newRow) => {
  // Convert data
  const data = _convertData(newRow);
  // Commit to news state
  commit(Type.SET_QUOTATION_DETAIL_LOCAL, data);
  return true;
};

const saveNewQuotationDetails = async ({ commit }, arrAddData) => {
  // Convert data
  const { quotationDetailAddLocal, quotationId } = arrAddData;
  return service
    .rest('quotationDetail/add', {
      quotationDetailArr: quotationDetailAddLocal,
      quotationId
    })
    .then(rlt => {
      const { status, data } = rlt;
      if (!status) {
        return false;
      }

      const { items, quotationUpdated } = data;
      //update to quotation
      commit(
        `quotation/${QuotationType.SET_QUOTATION_UPDATE}`, 
        quotationUpdated, 
        {root: true}
      );

      commit(Type.SAVE_NEW_QUOTATION_DETAIL, { items: items });
      return true;
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
      return false;
    });
};

const saveEditQuotationDetail = async ({ commit }, addData) => {
  const { quotationId, newData } = addData;

  if (!quotationId || quotationId != newData.quotationId) {
    return false;
  }

  return service
    .rest('quotationDetail/update', addData)
    .then(rlt => {
      const { status } = rlt;
      if (!status) {
        return false;
      }
      const { items, quotationUpdated } = rlt.data;
      //update to quotation
      commit(
        `quotation/${QuotationType.SET_QUOTATION_UPDATE}`, 
        quotationUpdated, 
        {root: true}
      );

      // Commit to news state
      commit(Type.UPDATE_QUOTATION_DETAIL, { items, quotationUpdated });
      return true;
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
      return false;
    });
};

const updateQuotationDetailLocal = async ({ commit }, value) => {
  commit(Type.QUOTATION_DETAIL_UPDATE_LOCAL, value);
  return true;
};

const deleteQuotationDetail = async ({ commit }, value) => {
  const { deleteItem } = value;
  const { id } = deleteItem;
  if (id) {
    return service
      .rest('quotationDetail/delete', {
        id
      })
      .then(rlt => {
        const { status } = rlt;
        if (!status) {
          return false;
        }
        const { items, quotationUpdated } = rlt.data;

        //update to quotation
        commit(
          `quotation/${QuotationType.SET_QUOTATION_UPDATE}`, 
          quotationUpdated, 
          {root: true}
        );

        // Commit to news state
        commit(Type.SET_DELETE_QUOTATION_DETAIL, items);
        return true;
      })
      .catch(e => {
        console.log(
          '🚀 ~ file: quotationDetail.actions.js ~ line 113~ awaitservice.rest ~ e',
          e
        );
        return false;
      });
  } else {
    commit(Type.DELETE_QUOTATION_DETAIL_LOCAL, [deleteItem]);
    return true;
  }
};

const createOrderFromQuotation = async ( { commit }, data) => {
  const { quotationId } = data;
  if (quotationId) {
    return service
      .rest('quotation/createOrder', {
        quotationId
      })
      .then(rlt => {
        const { status } = rlt;
        if (!status) {
          return false;
        }
        const { items } = rlt.data;
        console.log('items create order: ', items);
        commit(
          `quotation/${QuotationType.SET_QUOTATION_UPDATE}`, 
          items, 
          {root: true}
        );
        return true;
      })
      .catch(e => {
        console.log(
          '🚀 ~ file: quotationDetail.actions.js ~ line 113~ awaitservice.rest ~ e',
          e
        );
        return false;
      });
  } else {
    return false;
  }
};

const sendMailCustomerQuotationInfo = async (_, data) => {
  const { quotationId, recipientEmail, recipientName } = data;
  if(!quotationId || !recipientEmail || !recipientName){
    return false
  };

  return service
    .rest('quotation/sendMailCustomerQuotationInfo', {
      quotationId,
      recipientEmail,
      recipientName
    })
    .then(rlt => {
      const { status } = rlt;
      if (!status) {
        return false;
      }
      // Commit to news state
      // commit(Type.SET_DELETE_QUOTATION_DETAIL, items);
      return true;
    })
    .catch(e => {
      console.log(
        '🚀 ~ file: quotationDetail.actions.js ~ line 113~ awaitservice.rest ~ e',
        e
      );
      return false;
    });
};

const exportQuotationPDF = async (_, data) => {
  const { quotationId, mailTemplateCode } = data;
  if( !quotationId || !mailTemplateCode ){
    return false
  };

  return service
    .rest('quotation/generateQuotationPDFTemplate', {
      quotationId,
      mailTemplateCode
    })
    .then( rlt => {
      const { status } = rlt;
      if (!status) {
        return {
          status: false,
          message: rlt.errorMess
        };
      }
      // const { 
      //   quotationInfo, 
      //   customerInfo, 
      //   representativeInfo,
      //   personInChargeInfo,
      //   salesInfo,
      //   orderDetailArr
      // } = rlt.data;

      const htmlPDFTemplate = rlt.template;
      // quotationInfoPDF({
      //   quotationInfo, 
      //   customerInfo, 
      //   representativeInfo,
      //   personInChargeInfo,
      //   salesInfo,
      //   orderDetailArr
      // });

      // Commit to news state
      // commit(Type.SET_DELETE_QUOTATION_DETAIL, items);
      return {
        status: true,
        templatePDF: htmlPDFTemplate
      };
    })
    .catch(e => {
      console.log(
        '🚀 ~ file: exportQuotationPDF.actions.js ~ line 113~ awaitservice.rest ~ e',
        e
      );

      return {
        status: false,
        message: e.message
      };
    });
};

const previewMail = async (_, value) => {
  const { listEmail, orderData, mailTemplateCode, note, title, mailTemplateComCode, quotationId } = value;
  const objParam = { listEmail, orderData, mailTemplateCode, note, title, quotationId };
  console.log('🚀 ~ file: actions.js ~ line 280 ~ previewMail ~ listEmail, orderData, mailTemplateCode, note, title', listEmail, orderData, mailTemplateCode, note, title);
  return service
    .rest('email/previewMailTemplate', { mailTemplateComCode, objParam })
    .then(rlt => {
      return rlt;
    })
    .catch(err => {
      console.log('🚀 ~ file: orderDetail.actions.js ~ line 113~ awaitservice.rest ~ e', err);
      return false;
    });
};

const sendMail = async (_, value) => {
  const { listEmail, orderData, mailTemplateCode, note, title, mailTemplateComCode , quotationId } = value;
  const objParam = { listEmail, orderData, mailTemplateCode, note, title, quotationId };
  return service
    .rest('email/sendMailTemplate', { mailTemplateComCode, objParam })
    .then(rlt => {
      // commit(Type.SEND_MAIL_TEMPLATE);
      if (rlt.status) return true;
      else return false;
    })
    .catch(err => {
      console.log('🚀 ~ file: orderDetail.actions.js ~ line 113~ awaitservice.rest ~ e', err);
      return false;
    });
};

export {
  addQuotationDetailLocal,
  saveNewQuotationDetails,
  saveEditQuotationDetail,
  updateQuotationDetailLocal,
  deleteQuotationDetail,
  getQuotationDetailByQuotationId,
  createOrderFromQuotation,
  sendMailCustomerQuotationInfo,
  exportQuotationPDF,
  previewMail,
  sendMail
};
