export const SET_ALL_ORDER = 'SET_ALL_ORDER';
export const SET_ALL_CUSTOMER_ORDER = 'SET_ALL_CUSTOMER_ORDER';
export const SET_ORDER_BY_ID = 'SET_ORDER_BY_ID';
export const SET_PARTNER_LOCAL = 'SET_PARTNER_LOCAL';
export const SET_ORDER_DETAIL_ADD = 'SET_ORDER_DETAIL_ADD';
export const SET_ORDER_DETAIL_UPDATE = 'SET_ORDER_UPDATE_UPDATE';
export const SET_ORDER_DETAIL_UPDATE_LOCAL = 'SET_ORDER_DETAIL_UPDATE_LOCAL';
export const SET_DELETE_ORDER_DETAIL = 'SET_DELETE_ORDER_DETAIL';
export const SET_DELETE_ORDER_DETAIL_LOCAL = 'SET_DELETE_ORDER_DETAIL_LOCAL';
export const SET_STATUS_LOCAL = 'SET_STATUS_LOCAL';
export const GET_BY_ORDER_ID = 'GET_BY_ORDER_ID';
export const SET_ORDER_DETAIL_LOCAL = 'SET_ORDER_DETAIL_LOCAL';


export const GET_BY_QUOTATION_ID = 'GET_BY_QUOTATION_ID';
export const GET_QUOTATION_DETAIL_FROM_QUOTATION = 'GET_QUOTATION_DETAIL_FROM_QUOTATION';
export const SET_QUOTATION_DETAIL_LOCAL = 'SET_QUOTATION_DETAIL_LOCAL';
export const SAVE_NEW_QUOTATION_DETAIL = 'SAVE_NEW_QUOTATION_DETAIL';
export const UPDATE_QUOTATION_DETAIL = 'UPDATE_QUOTATION_DETAIL';
export const QUOTATION_DETAIL_UPDATE_LOCAL = 'QUOTATION_DETAIL_UPDATE_LOCAL';
export const SET_DELETE_QUOTATION_DETAIL = 'SET_DELETE_QUOTATION_DETAIL';
export const DELETE_QUOTATION_DETAIL_LOCAL = 'DELETE_QUOTATION_DETAIL_LOCAL';

