<template>
    <highcharts :options="chartOptions"></highcharts>
</template>
<script>
import { Chart } from 'highcharts-vue'
export default {
    components: {
        'highcharts': Chart
    },
    props: {
        series: {
            type: Array,
            default: () => []
        },
        xAxisCategories: {
            type: Array,
            default: () => []
        },
        yAxisTitle: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        },
        title: {
            type:String,
            default: ''
        },
        enabled: {
            type: Boolean,
            default: true
        },
        colors: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            
        }
    },
    computed: {
        chartOptions() {
            let config = {
                    chart: {
                        zoomType: 'xy'
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    lang: {
                        thousandsSep: ',',
                        numericSymbols: null
                    },
                    xAxis: [{
                        type: 'category',
                        categories: this.xAxisCategories,
                        labels: {
                            rotation: -45,
                            style: {
                                fontSize: '13px'
                            }
                        },
                        crosshairs: true
                    }],
                    yAxis: [{ // Primary yAxis
                        labels: {
                            formatter: function () {
                                return new Intl.NumberFormat('vi-VN', {
                                    style: 'currency',
                                    currency: 'VND'
                                    }).format(this.value)
                            },
                            style: {
                                color: '#0d233a'
                            }
                        },
                        title: {
                            text: 'Giá trung bình',
                            style: {
                                color: '#0d233a'
                            }
                        }
                    }, { // Secondary yAxis
                        title: {
                            text: 'Tổng giá',
                            style: {
                                color: '#2f7ed8'
                            }
                        },
                        labels: {
                            formatter: function () {
                                return new Intl.NumberFormat('vi-VN', {
                                    style: 'currency',
                                    currency: 'VND'
                                    }).format(this.value)
                            },
                            style: {
                                color: '#2f7ed8'
                            }
                        },
                        opposite: true
                    }],
                    tooltip: {
                        headerFormat: '<b>{series.name}</b><br/>',
                        shared: true
                    },
                    legend: {
                        backgroundColor: 'white',
                        borderColor: '#CCC',
                        borderWidth: 1,
                        shadow: false
                    },
                    series: this.series,
                    credits: {
                        enabled: false
                    }
            }
            return config;
        },
    }
}
</script>