import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
    coupons: [],
    listCoupon: [] 
};

const mutations = {
    async [Type.GET_ALL](state, value) {
      state.coupons = value.rows;
    },
    async [Type.GET_ALL_COUPON_SELECT](state, value) {
      state.listCoupon = value.rows;
    },
    async [Type.SAVE](state, value) {
      state.coupons = [ ...value, ...state.coupons]
      Promise.all(value.map( item => {
        state.listCoupon.push(item);
      }));
    },
    async [Type.EDIT](state, value) {
      const oldCoupons = state.coupons
      const index = state.coupons.findIndex((element) => element.id == value.id);
      oldCoupons[index] = value;
      state.coupons = oldCoupons.slice(0);

      // update listCoupon[]
      const indexInListCoupon = state.listCoupon.findIndex((element) => element.id == value.id);
      state.listCoupon[indexInListCoupon].name = value.name
      return state.coupons
    },
    async [Type.DELETE](state, value) {
        await Promise.all(value.map( item => {
          const index = state.coupons.findIndex((element) => element.id == item.id);
          state.coupons.splice(index, 1)
          const indexInListCoupon = state.listCoupon.findIndex((element) => element.id == item.id);
          state.listCoupon.splice(indexInListCoupon, 1)
        }));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};