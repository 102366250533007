<template>
  <div>
    <!-- <vue-element-loading
          :active="!globalReady"
          :is-full-screen="true"
          spiner="line-scale"
        /> -->
    <AppLayout />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import AppLayout from 'components/AppLayout';

export default {
  components: { AppLayout },
  computed: {
    ...mapState('global', ['globalReady'])
  },
  created() {},

  methods: {}
};
</script>

<style src="./index.scss" lang="scss" />
