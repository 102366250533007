import Service from 'core/service';
import {
  cloneDeep
} from 'lodash';
import * as Type from 'modules/customer/store/types';

const service = new Service();

const getAllCustomers = async ({
  commit
}, value) => {
  const {
    customerName = '',
      channelId = '',
      sizeId = '',
      typeId = '',
      rankId = '',
      userInchargeId = '',
      taxCode = '',
      offset = '',
      limit = '',
      customerFilterMethodArr = []
  } = value;

  const params = {};

  if (offset) {
    params.offset = offset
  }
  if (limit) {
    params.limit = limit
  }
  if (customerName) {
    params.customerName = customerName.trim();
  }
  if (channelId) {
    params.channelId = Number(channelId);
  }
  if (sizeId) {
    params.sizeId = Number(sizeId);
  }
  if (typeId) {
    params.typeId = Number(typeId);
  }
  if (rankId) {
    params.rankId = Number(rankId);
  }
  if (userInchargeId) {
    params.userInchargeId = Number(userInchargeId);
  }
  if (rankId) {
    params.rankId = Number(rankId);
  }
  if (taxCode) {
    params.taxCode = taxCode;
  }
  if (customerFilterMethodArr && customerFilterMethodArr.length > 0) {
    params.customerFilterMethodArr = customerFilterMethodArr;
  }

  // Get All News
  return service
    .rest('customer/getListPaging', params)
    .then(res => {
      const {
        status,
        rows,
        totalRows
      } = res;
      if (!status) {
        return false;
      }
      commit(Type.SET_ALL_CUSTOMERS, {
        rows,
        totalRows
      });
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
};

const getAllCustomersWithAddress = async ({
  commit
}, value) => {
  const {
    customerName = '',
      channelId = '',
      sizeId = '',
      typeId = '',
      rankId = '',
      userInchargeId = '',
      taxCode = '',
  } = value;
  const params = {};

  if (customerName) {
    params.customerName = customerName;
  }
  if (channelId) {
    params.channelId = Number(channelId);
  }
  if (sizeId) {
    params.sizeId = Number(sizeId);
  }
  if (typeId) {
    params.typeId = Number(typeId);
  }
  if (rankId) {
    params.rankId = Number(rankId);
  }
  if (userInchargeId) {
    params.userInchargeId = Number(userInchargeId);
  }
  if (rankId) {
    params.rankId = Number(rankId);
  }
  if (taxCode) {
    params.taxCode = taxCode;
  }

  // Get All News
  return service
    .rest('customer/getListPagingWithAddress', params)
    .then(res => {
      commit(Type.SET_ALL_CUSTOMERS_ADDRESS, res.rows);
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
};

const _convertData = (row) => {
  const {
    avatar
  } = row;
  const newData = cloneDeep(row)
  newData.companyId = 1;
  if (avatar && avatar !== '') newData.avatar = avatar.map(item => item.filePath).join('|')
  return newData
}

const saveAddCustomers = async ({
  commit
}, arrAddData) => {
  // Convert data
  const arrAddDataConvert = arrAddData.map(item => _convertData(item))
  return service
    .rest('customer/setList', {
      items: {
        $add: arrAddDataConvert
      }
    })
    .then(rlt => {
      const {
        items
      } = rlt;
      const {
        $rltAdd
      } = items;
      // Commit to news state
      commit(Type.SET_CUSTOMERS_ADD, $rltAdd);
      return true;
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
      return false;
    });
};

const saveAddCustomersWithSendActiveMail = async ({
  commit
}, arrAddData) => {
  const arrAddDataConvert = arrAddData.map(item => _convertData(item));
  return service
    .rest('customer/setAddWithSendActiveMail', {
      items: arrAddDataConvert
    })
    .then(rlt => {
      const {
        items,
        status
      } = rlt;
      if (status) {
        // Commit to news state
        commit(Type.SET_CUSTOMERS_ADD_WITH_ACTIVE_MAIL, {
          rltAdd: items
        });
        return ({
          status: true,
          items
        });
      } else {
        return ({
          status: false,
          items
        });
      }

    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
      return ({
        status: false
      })
    });
};

const deleteCustomers = async ({
  commit
}, value) => {
  const {
    id
  } = value;
  if (id) {
    return service
      .rest('customer/setList', {
        items: {
          $delete: [value]
        }
      })
      .then(rlt => {
        const {
          items
        } = rlt;
        const {
          $rltDelete
        } = items;
        // Commit to news state
        commit(Type.SET_DELETE_CUSTOMERS, $rltDelete);
        return true;
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
        return false;
      });
  }
};

const saveEditCustomers = async ({
  commit
}, addData) => {
  const {
    oldData,
    newData
  } = addData;
  const {
    id
  } = newData;
  const data = _convertData(newData);

  if (id) {
    // Convert data
    return service
      .rest('customer/setList', {
        items: {
          $update: [data]
        }
      })
      .then(rlt => {
        const {
          items
        } = rlt;
        const {
          $rltUpdate
        } = items;
        // Commit to news state
        commit(Type.SET_CUSTOMERS_UPDATE, $rltUpdate[0]);
        return true;
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
        return false;
      });
  } else {
    // Commit to news state
    commit(Type.SET_CUSTOMERS_UPDATE_LOCAL, {
      oldData,
      newData: data
    });
    return true;
  }
};

const deleteCustomersList = async ({
  commit
}, value) => {
  const lstLocal = [];
  const lstDB = [];
  // Filter
  value.map(item => {
    const {
      id
    } = item;
    if (id) {
      lstDB.push(item);
    } else {
      lstLocal.push(item);
    }
  });

  if (lstLocal.length > 0) {
    commit(Type.SET_DELETE_CUSTOMERS_LOCAL, lstLocal);
  }

  if (lstDB.length > 0) {
    return service
      .rest('customer/setList', {
        items: {
          $delete: lstDB
        }
      })
      .then(rlt => {
        const {
          items
        } = rlt;
        const {
          $rltDelete
        } = items;
        // Commit to news state
        commit(Type.SET_DELETE_CUSTOMERS, $rltDelete);
        return true;
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
        return false;
      });
  }
};

const totalCountCustomers = async ({
  commit
}) => {
  return service
    .rest('customer/totalCount', {})
    .then(rlt => {
      const {
        totalRows,
        status
      } = rlt;
      if (!status) {
        return false
      }
      // Commit to news state
      commit(Type.TOTAL_COUNT_CUSTOMERS, totalRows);
      return true;
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
      return false;
    });
}

const getCustomerProfile = async ({
  commit
}, value) => {
  return service
    .rest('customer/getById', {
      id: Number(value)
    })
    .then(res => {
      if (res.status) {
        commit(Type.GET_CUSTOMER_PROFILE, res.data);
        return true;
      }
      return false;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
};

const editCustomerProfile = async ({
  commit
}, value) => {
  const {
    email = '',
    password = ''
  } = value
  const params = {
    id: value.id
  }
  if(email != '') params.email = email
  if(password != '') params.password = password
  return service
    .rest('customer/edit', params)
    .then(res => {
      if (res) {
        commit(Type.GET_CUSTOMER_PROFILE, res);
        return {
          status: true,
          message: 'success'
        };
      }
      return {
        status: false,
        data: 'err'
      };
    })
    .catch(err => {
      console.log(err);
      return {
        status: false,
        data: err.message
      };
    });
};

const statisticCustomer = async ({ commit }, value) => {
  const {
      from, to
  } = value || {};
  
  const params = {};
  
  if (from) params.from = from;
  if (to) params.to = to;

  return service.rest('customer/statisticCustomer', params).then(rlt => {
      const { data } = rlt;
      // Commit to news state
      commit(Type.STATISTIC_CUSTOMER, data);
      return true
  }).catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 154 ~ awaitservice.rest ~ e', e)
      return false
  });
}

export {
  getAllCustomers,
  getAllCustomersWithAddress,
  saveAddCustomersWithSendActiveMail,
  saveAddCustomers,
  deleteCustomers,
  saveEditCustomers,
  deleteCustomersList,
  totalCountCustomers,
  getCustomerProfile,
  editCustomerProfile,
  statisticCustomer
};