import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
import cloneDeep from 'lodash/cloneDeep';
// import groupBy from 'lodash/groupBy';

const state = {
  supplierAddress: [],
  supplierAddressMap: {}
};

const mutations = {
  [Type.SET_ALL_SUPPLIER_ADDRESS](state, value) {
    state.supplierAddress = value;
    console.log('value: ', value);

    state.supplierAddressMap = state.supplierAddress.reduce(
      (cMap, c) => ({
        ...cMap,
        [c.id]: c
      }),
      {}
    );
  },

  [Type.SET_CUSTOMERS_ADDRESS_ADD](state, value){
    const oldCustomerAddress = state.supplierAddress;

    const { customerId } = value[0];
    // if new CustomerAddress default value is true => update all another data default to false
    if (value[0]['isDefault'] === true) {
      oldCustomerAddress.map(item => {
        if (item.isDefault === true && item.customerId === customerId) {
          item.isDefault = false;
        }
        return item;
      });
    }

    //add new data
    state.supplierAddress = [...cloneDeep(value), ...oldCustomerAddress];

  },

  [Type.SET_CUSTOMERS_ADDRESS_UPDATE](state, value) {
    const oldCustomerAddress = state.supplierAddress;
    const { customerId, id } = value;

    // if new CustomerAddress default value is true => update all another data default to false
    if (value['isDefault'] === true) {
      oldCustomerAddress.map(item => {
        if (item.isDefault === true && item.customerId === customerId) {
          item.isDefault = false;
        }
        return item;
      });
    }

    const objIndex = state.supplierAddress.findIndex(obj => obj.id === id);
    oldCustomerAddress[objIndex] = value;
    state.supplierAddress = oldCustomerAddress.slice(0);

  },

  [Type.SET_DELETE_CUSTOMERS_ADDRESS](state, value){
    const oldData = cloneDeep(state.supplierAddress);
    value.map(item => {
      const { id } = item;
      const objIndex = oldData.findIndex(obj => obj.id === id);
      oldData.splice(objIndex, 1);
    });
    state.supplierAddress = oldData.slice(0);

  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};