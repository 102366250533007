import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
    roles: [],
    rolesMap: [],
    listRole: [] 
};

const mutations = {
    async [Type.GET_ALL](state, value) {
      state.roles = value.rows;
      state.rolesMap = state.roles.reduce(
        (cMap, c) => ({
          ...cMap,
          [c.id]: c
        }),
        {}
      )
      state.listRole = value.rows;
    },
    async [Type.SAVE](state, value) {
      state.roles = [ ...value, ...state.roles]
      Promise.all(value.map( item => {
        state.listRole.push(item);
      }));
    },
    async [Type.EDIT](state, value) {
      const oldRoles = state.roles
      const index = state.roles.findIndex((element) => element.id == value.id);
      oldRoles[index] = value;
      state.roles = oldRoles.slice(0);

      // update listRole[]
      const indexInListRole = state.listRole.findIndex((element) => element.id == value.id);
      state.listRole[indexInListRole].name = value.name
    },
    async [Type.DELETE](state, value) {
        await Promise.all(value.map( item => {
          const index = state.roles.findIndex((element) => element.id == item.id);
          state.roles.splice(index, 1)
          const indexInListRole = state.listRole.findIndex((element) => element.id == item.id);
          state.listRole.splice(indexInListRole, 1)
        }));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};