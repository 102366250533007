<template>
        <div v-if="isShow">
            <div class="chat-popup" id="myForm" style="right: 15px">
              <div class="chat-header">
                  <p class="mb-0">{{ this.data.supInfo.name }}</p>
                  <i class="el-icon-close chat-icon-close" @click="handleCloseChatBox"></i>
              </div>
              <div class="chat-body" v-loading="isLoadingMessage">
                  <ul>
                      <li v-for="message in orderMessages" :key="message.id" :class="message.senderType === 'SUP' ? 'sent' : 'replies'">
                          <p>{{message.message}}</p>
                      </li>
                  </ul>
              </div>
              <div class="chat-footer">
                    <input type="text" :placeholder="$i18n.t('src.modules.global.components.chatBox.sendMessage.input.placeholder')" class="input-message" v-model="message" @keyup.enter="handleEnterKeyUp">
                    <button class="send-button" @click="handleSendMessage"><i class="el-icon-s-promotion" ref="sendMessage"></i></button>
              </div>
            </div>
        </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
export default {
    props: {
      data: {
          type: Object,
          default: () => {}
      },
      isShow: {
          type: Boolean,
          default: false
      }
    },
    data: () => {
        return {
            isOpenChatBox: false,
            input: '',
            message: '',
            isLoadingMessage: false
        }
    },
    computed:{
      ...mapState('orderMessage', ['orderMessages'])
    },
    watch:{
      data: function() {
        this.getListMessage({
          orderId: this.$route.params.id,
          type: ['SUP', 'ADM'],
          reciId: this.data.id,
          senderId: 1
        })
      }
    },
    created() {
      this.isLoadingMessage = true;
      this.getListMessage({
        orderId: this.$route.params.id,
        type: ['SUP', 'ADM'],
        reciId: this.data.id,
        senderId: 1
      }).then(() => {
        this.isLoadingMessage = false;
      });
    },
    
    methods: {
      ...mapActions('orderMessage', ['getListMessage', 'addMessage']),
      async handleSendMessage() {
          if (this.message !== '') {
              const postData = {
                  orderId: this.$route.params.id,
                  reciType: 'SUP',
                  reciId: this.data.id,
                  message: this.message
              };
              const result = await this.addMessage([postData]);
              
              if (!result) {
                this.$notify({
                    title: this.$i18n.t('common.notify.title.error'),
                    type: 'error'
                });
              }

              this.message = '';

              const el = this.$el.getElementsByClassName('chat-body')[0];
              if (el) {
                el.scrollTop = el.scrollHeight;
              }
          }
      },
      handleCloseChatBox() {
        this.$emit('onCloseChatBox');
      },
      // enter to add text input
      handleEnterKeyUp() {
        this.$refs.sendMessage.click();
      },
    }
}
</script>
<style>
/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
  background-color: #fff;
  color: #000;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 0px;
  width: 280px;
}

.chat-header {
  background: linear-gradient(to right, #ffac44, #ffac44a9);
  padding: 10px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chat-icon-close {
  cursor: pointer;
}

/* The popup chat - hidden by default */
.chat-popup {
  position: fixed;
  bottom: 0;
  border: 3px solid #f1f1f1;
  z-index: 9;
  min-width: 400px;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}

.chat-message-text {
  background-color: gray;
  padding: 10px;
  display: inline-block;
}

.d-flex-right {
  display: flex;
  justify-content: right;
}

.d-flex-left {
  display: flex;
  justify-content: left;
}

.chat-body {
  height: auto;
  min-height: 400px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: whitesmoke
}

.chat-body::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
.chat-body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

.chat-body ul li {
    display: inline-block;
    clear: both;
    float: left;
    margin: 5px 15px 5px 15px;
    width: calc(100% - 25px);
}

.chat-body ul li img {
    width: 22px;
    border-radius: 50%;
    float: left;
}

.chat-body ul li.sent img {
    margin: 6px 8px 0 0;
}

.chat-body ul li.replies img {
    float: right;
    margin: 6px 0 0 8px;
}

.chat-body ul li p {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 205px;
    line-height: 130%;
}

.chat-body ul li.sent p {
    background: #fff;
    color: #000;
}

.chat-body ul li.replies p {
    background-image:  linear-gradient(to right, #435f7a, #6c7680) !important;
    color: #fff;
    float: right;
}

.input-message {
    float: left;
    border: none;
    width: calc(100% - 90px);
    padding: 11px 32px 10px 8px;
    color: #32465a;
}
.input-message:focus {
  outline: none;
}
.send-button {
    float: right;
    border: none;
    width: 50px;
    padding: 12px 0;
    cursor: pointer;
    background: #32465a;
    color: #f5f5f5;
}
</style>