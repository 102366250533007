import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';

const state = {
  lalaMoveInfo: {}
};

const mutations = {
  [Type.CREATE_QUOTATION](state, value) {
    state.lalaMoveInfo = value
  },

  [Type.GET_INFO_BY_ORDER](state, value) {
    state.lalaMoveInfo = value
  },

  [Type.PLACE_ORDER](state, value) {
    state.lalaMoveInfo = value
  },

  [Type.UPDATE_INFO](state, value) {
    state.lalaMoveInfo = value
  },

  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
