import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  customerRepresentativeArray: [],
  customerRepresentativeMap: {}
};

import cloneDeep from 'lodash/cloneDeep';
// import { isEqual } from 'lodash';

const mutations = {
  [Type.SET_ALL_CUSTOMER_REPRESENTATIVE](state, value) {
    state.customerRepresentativeArray = value;
    state.personInChargeMap = state.customerRepresentativeArray.reduce(
      (cMap, c) => ({
        ...cMap,
        [c.id]: c
      }),
      {}
    );
  },

  [Type.SET_CUSTOMER_REPRESENTATIVE_ADD](state, value) {
    const oldCutomerRepresentative = state.customerRepresentativeArray;
    // if new CutomerRepresentative default value is true => update all another data default to false
    if (value[0]['isDefault'] === true) {
      oldCutomerRepresentative.map(item => {
        if (item.isDefault === true) {
          item.isDefault = false;
        }
        return item;
      });
    }

    //add new data
    state.customerRepresentativeArray = [
      ...cloneDeep(value),
      ...oldCutomerRepresentative
    ];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
