import Service from 'core/service'
import * as Type from 'modules/voucher/store/types'
import { reduce, cloneDeep } from 'lodash'

const services = new Service()

function _convertDataForSaveDB(data) {
  const newData = cloneDeep(data);
  let convertArrToObject = reduce(
    newData,
    (acc, { lang, content }) => ({ ...acc, [lang]: content }),
    {}
  )
  return JSON.stringify(convertArrToObject);
};


const getAllBasicInfos = async ({ commit }) => {
  await services
    .rest('basicInfo/getListPaging')
    .then(res => {
      commit(Type.GET_ALL, res)
    })
    .catch(error => {
      console.log('[ERROR] >>> action >> getAllBasicInfos >', error)
    })
}

const editBasicInfo = async ({ commit }, value) => {
  let arrLogo = value.data.logos
  let logo = await Promise.all(
    arrLogo.map(async element => {
      return element.filePath
    })
  )
  let arrHomeSlider = value.data.homeSliders
  let homeSlider = await Promise.all(
    arrHomeSlider.map(async element => {
      return element.filePath
    })
  )

  let arrBannerImages = value.data.bannerImages
  let bannerImages = await Promise.all(
    arrBannerImages.map(async element => {
      return element.filePath
    })
  )

  value.data.logo = logo.join('|')
  value.data.homeSlider = homeSlider.join('|')
  value.data.socialLinks = JSON.stringify(value.arrSocialLinks)
  value.data.typicalCustomers = JSON.stringify(value.arrTypicalCustomers)
  value.data.typicalBrands = JSON.stringify(value.arrTypicalBrands)
  value.data.bannerImages = bannerImages
  value.data.bannerTitle = _convertDataForSaveDB(value.data.bannerTitle)
  value.data.bannerSubTitle = _convertDataForSaveDB(value.data.bannerSubTitle)
  value.data.bannerBackgroundColor = value.data.bannerBackgroundColor
  value.data.typicalSellers = JSON.stringify(value.arrTypicalSellers)
  return await services
    .rest('basicInfo/setList', {
      items: {
        $update: [value.data]
      }
    })
    .then(rlt => {
      const { items } = rlt
      const { $rltUpdate } = items
      // Commit to news state
      commit(Type.EDIT, $rltUpdate[0])

      return {
        type: true,
        message: 'success'
      }
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js >> ', e)
      return {
        type: false,
        message: 'error'
      }
    })
}

export { editBasicInfo, getAllBasicInfos }
