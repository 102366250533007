import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  quotationStatusArray: [],
  quotationStatusMap: {}
};

// import cloneDeep from 'lodash/cloneDeep'
// import { isEqual } from 'lodash';

const mutations = {
  [Type.SET_ALL_QUOTATION_STATUS](state, value) {
    state.quotationStatusArray = value;
    state.quotationStatusMap = state.quotationStatusArray.reduce(
      (cMap, c) => ({
        ...cMap,
        [c.id]: c
      }),
      {}
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
