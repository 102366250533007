import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  ranks: [],
  ranksMap: {}
};

// import cloneDeep from 'lodash/cloneDeep'
// import { isEqual } from 'lodash';

const mutations = {
  [Type.SET_ALL_RANKS](state, value) {
    state.ranks = value;
    state.ranksMap = state.ranks.reduce(
      (cMap, c) => ({
        ...cMap,
        [c.id]: c
      }),
      {}
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
