import Vue from 'vue';
import Vuex from 'vuex';

import auth from 'modules/auth/store';
import global from 'modules/global/store';
import dashboard from 'modules/dashboard/store';
import order from 'modules/order/store';
import orderDetail from 'modules/orderDetail/store';
import user from 'modules/user/store';
import orderStatus from 'modules/orderStatus/store';
import profile from 'modules/profile/store';
import customerAddress from 'modules/customerAddress/store';
import customerNotification from 'modules/customerNotification/store';
import quotation from 'modules/quotation/store';
import quotationStatus from 'modules/quotationStatus/store';
import personInCharge from 'modules/personInCharge/store';
import customerRepresentative from 'modules/customerRepresentative/store';
import quotationDetail from 'modules/quotationDetail/store';
import customerInvoiceAddress from 'modules/customerInvoiceAddress/store';
import orderMessage from 'modules/orderMessage/store';
import orderSupplierStatusHistory from 'modules/orderSupplierStatusHistory/store';
import customerDetail from 'modules/customerDetail/store';
import customerDashboard from 'modules/customerDashboard/store';
import orderDashboard from 'modules/orderDashboard/store';
import quotationDashboard from 'modules/quotationDashboard/store';
import collaborator from 'modules/collaborator/store';
import fileManagement from 'modules/fileManagement/store';
import role from 'modules/role/store';
import common from 'modules/common/store';
import supplier from 'modules/supplier/store';
import customer from 'modules/customer/store';
import channel from 'modules/channel/store';
import size from 'modules/size/store';
import type from 'modules/type/store';
import rank from 'modules/rank/store';
import industry from 'modules/industry/store';
import industrialPark from 'modules/industrialPark/store';
import mailTemplate from 'modules/mailTemplate/store'
import unit from 'modules/unitOfMeasurement/store'
import voucher from 'modules/voucher/store'
import documentType from 'modules/documentType/store'
import category from 'modules/category/store'
import lalaMove from 'modules/lalaMove/store'
import supplierAddress from 'modules/supplierAddress/store'
import coupon from 'modules/coupon/store'
import supplierUser from 'modules/supplierUser/store'
import basicInfo from 'modules/basicInfo/store'
import product from 'modules/product/store'
import productRepurchase from 'modules/productRepurchase/store'

Vue.use(Vuex);

// Enable dev tools
if (process.env.VUE_APP_DEVTOOLS_ENABLED) {
  Vue.config.devtools = true;
}
export default new Vuex.Store({
  modules: {
    auth,
    global,
    dashboard,
    order,
    orderDetail,
    user,
    orderStatus,
    profile,
    customerAddress,
    customerNotification,
    quotation,
    quotationStatus,
    personInCharge,
    customerRepresentative,
    quotationDetail,
    customerInvoiceAddress,
    orderMessage,
    orderSupplierStatusHistory,
    customerDetail,
    customerDashboard,
    orderDashboard,
    quotationDashboard,
    collaborator,
    fileManagement,
    role,
    common,
    supplier,
    customer,
    channel,
    size,
    type,
    rank,
    industry,
    industrialPark,
    mailTemplate,
    unit,
    voucher,
    documentType,
    category,
    lalaMove,
    supplierAddress,
    coupon,
    supplierUser,
    basicInfo,
    product,
    productRepurchase
  },
  strict: process.env.NODE_ENV !== 'production'
});
