import Service from 'core/service';
import * as Type from 'modules/quotationDashboard/store/types';

const services = new Service()

const getTotalOfQuotationByMonth = async ({
    commit
}, value) => {
    const {
        startDate = '',
    } = value;

    const params = {};
    if (startDate) {
        params.startDate = startDate;
    }
    return await services.rest('dashboard/quotation/getTotalOfQuotationByMonth', params)
        .then(result => {
            commit(Type.GET_QUANTITY_QUOTATION_BY_MONTH, result);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

const getTotalValOfQuotationByMonth = async ({
    commit
}, value) => {
    const {
        startDate = '',
    } = value;

    const params = {};
    if (startDate) {
        params.startDate = startDate;
    }
    return await services.rest('dashboard/quotation/getTotalValOfQuotationByMonth', params)
        .then(result => {
            commit(Type.GET_TOTAL_VAL_QUOTATION_BY_MONTH, result);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

const getSynthesisStatisticsQuotation = async ({
    commit
}, value) => {
    const {
        endDate = ''
    } = value;

    const params = {};
    if (endDate) {
        params.toDate = endDate;
    }
    return await services.rest('dashboard/quotation/getSynthesisStatisticsQuotation', params)
        .then(result => {
            commit(Type.GET_SYNTHESIS_STATISTICS_QUOTATION, result);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

const getTotalValOfQuotatioByDay = async ({
    commit
}, value) => {
    const {
        startDate = '',
        saleId = ''
    } = value;

    const params = {};
    if (startDate) {
        params.startDate = startDate;
    }
    if (saleId) {
        params.saleId = saleId;
    }
    return await services.rest('dashboard/quotation/getTotalValOfQuotationOfSaleByDay', params)
        .then(result => {
            commit(Type.GET_TOTAL_VAL_QUOTATION_BY_DAY, result);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

export {
    getTotalOfQuotationByMonth,
    getTotalValOfQuotationByMonth,
    getSynthesisStatisticsQuotation,
    getTotalValOfQuotatioByDay
};