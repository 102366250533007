<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/' }"
      ><i class="el-icon-s-home"
    /></el-breadcrumb-item>
    <template v-for="(breadcrumb, idx) in breadcrumbList">
      <el-breadcrumb-item :key="idx" v-if="!breadcrumb.link">
        {{ getBreadcrumbName(breadcrumb) }}</el-breadcrumb-item
      >
      <el-breadcrumb-item
        :key="`${{ idx }}`"
        v-else
        :to="{ path: '/app/' + breadcrumb.link }"
      >
        {{ getBreadcrumbName(breadcrumb) }}</el-breadcrumb-item
      >
    </template>
  </el-breadcrumb>
</template>

<script>
export default {
  name: 'Breadcrumb',
  data() {
    return {
      breadcrumbList: []
    };
  },
  watch: {
    $route() {
      this.updateList();
    }
  },
  mounted() {
    this.updateList();
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    getBreadcrumbName(breadcrumb) {
      // console.log(
      //   '🚀 ~ file: index.vue ~ line 51 ~ getBreadcrumbName ~  breadcrumb.link',
      //   breadcrumb.name,
      //   breadcrumb.link
      // );

      if (breadcrumb.routeParams) {
        const params = breadcrumb.routeParams;
        let name = breadcrumb.name;
        params.map(item => {
          const value = this.$route.params[item];
          name = name.replace('${' + item + '}', value);
        });
        return name;
      } else {
        return breadcrumb.name;
      }
    }
  }
};
</script>
