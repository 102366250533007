import store from 'store';
import Cookies from 'js-cookie';
import Vue from 'vue';
import router from 'router';
import NatAcl from 'vue-module-acl';
import { convertPermissions } from 'core/helpers';

export const initAuth = async () => {
  const accessToken = Cookies.get('access_token');
  if (!accessToken) {
    return;
  }
  const authUser = store.getters['auth/user'];
  console.log('🚀 ~ file: auth.js ~ line 21 ~ initAuth ~ authUser', authUser);
  let permission = [];
  if (!authUser.userPermissions) {
    const user = await store.dispatch('auth/getMe');
    permission = user.userPermissions;
  } else {
    permission = authUser.userPermissions;
  }

  const listPermissions = convertPermissions(permission);

  Vue.use(NatAcl, {
    router: router,
    init: listPermissions,
    fail: '/error'
  });
  if (!authUser) {
    return;
  }
  return authUser;
};
