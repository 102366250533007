import Service from 'core/service';
import { cloneDeep } from 'lodash';
import * as Type from 'modules/documentType/store/types';

const service = new Service()

const getAllDocumentTypes = async ({ commit }) => {
    // Get All News
    return service.rest('documentType/getListPaging').then(res => {
        commit(Type.SET_ALL_DOCUMENT_TYPES, res.rows);
        return true
    }).catch(err => {
        console.log(err);
        return false
    })
    // Commit to news state

};

const _convertData = (row) => {
    const newData = cloneDeep(row)
    return newData
}

const addDocumentLocal = async ({ commit }, newRow) => {
    // Convert data
    
    const data = _convertData(newRow)
    // Commit to news state
    commit(Type.SET_DOCUMENT_TYPES_LOCAL, data);
    return true
};

const saveAddDocumentTypes = async ({ commit }, arrAddData) => {
    // Convert data
    const arrAddDataConvert = arrAddData.map(item => _convertData(item))
    return service.rest('documentType/setList', { items: { $add: arrAddDataConvert } }).then(rlt => {
        const { items } = rlt;
        const { $rltAdd } = items;
        // Commit to news state
        commit(Type.SET_DOCUMENT_TYPES_ADD, $rltAdd);
        return true
    }).catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
        return false

    })


}

const saveEditDocumentTypes = async ({ commit }, addData) => {
    const { oldData, newData } = addData
    const { id } = newData;
    const data = _convertData(newData);

    if (id) {
        // Convert data
        return service.rest('documentType/setList', { items: { $update: [data] } }).then(rlt => {
            const { items } = rlt;
            const { $rltUpdate } = items
            // Commit to news state
            commit(Type.SET_DOCUMENT_TYPES_UPDATE, $rltUpdate[0]);
            return true
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
            return false;

        })
    } else {
        // Commit to news state
        commit(Type.SET_DOCUMENT_TYPES_UPDATE_LOCAL, { oldData, newData: data });
        return true
    }
}


const deleteDocumentTypes = async ({ commit }, value) => {
    const { id } = value;
    if (id) {
        return service.rest('documentType/setList', { items: { $delete: [value] } }).then(rlt => {
            const { items } = rlt;
            const { $rltDelete } = items
            // Commit to news state
            commit(Type.SET_DELETE_DOCUMENT_TYPES, $rltDelete);
            return true
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return false

        })
    } else {
        commit(Type.SET_DELETE_DOCUMENT_TYPES_LOCAL, [value]);
        return true
    }

};

const deleteDocumentTypesList = async ({ commit }, value) => {
    const lstLocal = [];
    const lstDB = [];
    // Filter
    value.map(item => {
        const { id } = item
        if (id) {
            lstDB.push(item)
        } else {
            lstLocal.push(item)
        }
    })

    if (lstLocal.length > 0) {
        commit(Type.SET_DELETE_DOCUMENT_TYPES_LOCAL, lstLocal);
    }

    if (lstDB.length > 0) {
        return service.rest('documentType/setList', { items: { $delete: lstDB } }).then(rlt => {
            const { items } = rlt;
            const { $rltDelete } = items
            // Commit to news state
            commit(Type.SET_DELETE_DOCUMENT_TYPES, $rltDelete);
            return true
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return false

        })
    }

};


export {
    getAllDocumentTypes,
    addDocumentLocal,
    saveAddDocumentTypes,
    saveEditDocumentTypes,
    deleteDocumentTypes,
    deleteDocumentTypesList
};