import * as Type from './types'
import * as getters from './getters'
import * as actions from './actions'
import without from 'lodash/without'
import { cloneDeep, isObject } from 'lodash'

const state = {
  basicInfo: {},
  displayLogos: [],
  displayHomeSliders: [],
  displayTypicalCustomers: [],
  displayTypicalBrands: [],
  displayTypicalSellers: [],
  displayBanner: []
};

function _convertDataToUI(data) {
  const obj = JSON.parse(data)
  const array = Object.keys(obj).map(key => ({ lang: key, content: obj[key] }));
  return array;
};

const mutations = {
  async [Type.GET_ALL](state, value) {
    state.basicInfo = cloneDeep(value.rows)
    state.displayLogos = without(
      value.rows.logo.split('|').map(src => {
        return src !== '' ? { src } : null
      }),
      null
    )
    state.displayHomeSliders = without(
      value.rows.homeSlider.split('|').map(src => {
        return src !== '' ? { src } : null
      }),
      null
    )

    state.displayBanner = value.rows.bannerImages
      ? without(
          value.rows.bannerImages.split('|').map(src => {
            return src !== '' ? { src } : null
          }),
          null
        )
      : []

    state.displayTypicalCustomers = JSON.parse(state.basicInfo.typicalCustomers)
    state.displayTypicalBrands = JSON.parse(state.basicInfo.typicalBrands)
    state.displayTypicalSellers = JSON.parse(state.basicInfo.typicalSellers)

    state.basicInfo.bannerTitle =
      state.basicInfo.bannerTitle &&
      isObject(JSON.parse(state.basicInfo.bannerTitle))
        ? _convertDataToUI(state.basicInfo.bannerTitle)
        : [];

    state.basicInfo.bannerSubTitle =
      state.basicInfo.bannerSubTitle &&
      isObject(JSON.parse(state.basicInfo.bannerSubTitle))
        ? _convertDataToUI(state.basicInfo.bannerSubTitle)
        : [];
  },

  async [Type.EDIT](state, value) {
    state.basicInfo = cloneDeep(value)
    state.displayLogos = without(
      value.logo.split('|').map(src => {
        return src !== '' ? { src } : null
      }),
      null
    )
    state.displayHomeSliders = without(
      value.homeSlider.split('|').map(src => {
        return src !== '' ? { src } : null
      }),
      null
    )
    state.displayBanner = without(
      value.bannerImages.split('|').map(src => {
        return src !== '' ? { src } : null
      }),
      null
    )

    state.displayTypicalCustomers = JSON.parse(state.basicInfo.typicalCustomers)
    state.displayTypicalBrands = JSON.parse(state.basicInfo.typicalBrands)
    state.displayTypicalSellers = JSON.parse(state.basicInfo.typicalSellers)

    state.basicInfo.bannerTitle =
      state.basicInfo.bannerTitle &&
      isObject(JSON.parse(state.basicInfo.bannerTitle))
        ? _convertDataToUI(state.basicInfo.bannerTitle)
        : [];

    state.basicInfo.bannerSubTitle =
      state.basicInfo.bannerSubTitle &&
      isObject(JSON.parse(state.basicInfo.bannerSubTitle))
        ? _convertDataToUI(state.basicInfo.bannerSubTitle)
        : [];
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
