import Services from 'core/service';
import * as Type from 'modules/supplier/store/types';


const service = new Services();

const getAllSupplier = async ({ commit },value) => {  
    const { 
        keyword = '',
        status = '',
        supplierIdArray = []
    } = value;
    const params = {}
    if(keyword !== '') params.keyword = keyword; // keyword for search
    if(status !== '') params.isActive = status;
    if(supplierIdArray && supplierIdArray.length > 0){
        params.supplierIdArray = supplierIdArray
    }
   // Get All Supplier
   return service.rest('supplier/getListPaging', params).then(res=> {

    //get supplier with array of id
    if(supplierIdArray && supplierIdArray.length > 0){
        commit(Type.GET_ALL_SUPPLIER_IN_PAGE, res.rows);   
        return true;
    };

    commit(Type.GET_ALL_SUPPLIER, res.rows);   
    return true;

   }).catch(err=> {
    console.log(err);
    return false
   })
};

const getAllSupplierForManagement = async ({ commit },value) => {  
    const { 
        keyword = '',
        status = '',
        isSourcing = '',
        supplierIdArray = []
    } = value;
    const params = {}
    if(keyword !== '') params.keyword = keyword; // keyword for search
    if(status !== '') params.isActive = status;
    if(isSourcing !== '') params.isSourcing = isSourcing;
    if(supplierIdArray && supplierIdArray.length > 0){
        params.supplierIdArray = supplierIdArray
    }
   // Get All Supplier
   return service.rest('supplier/getListPagingForManagement', params).then(res=> {

    //get supplier with array of id
    if(supplierIdArray && supplierIdArray.length > 0){
        commit(Type.GET_ALL_SUPPLIER_IN_PAGE, res.rows);   
        return true;
    };

    commit(Type.GET_ALL_SUPPLIER, res.rows);   
    return true;

   }).catch(err=> {
    console.log(err);
    return false
   })
};

const addSupplier = async ({ commit }, value) => {
    const { arr} = value
    let arrIcon = arr[0].icons;
    let icon = await Promise.all(arrIcon.map(async element => {
        return element.filePath;
    }));
    arr[0].icon = icon.join('|');
    let arrBanner = arr[0].banners;
    let banner = await Promise.all(arrBanner.map(async element => {
        return element.filePath;
    }));
    arr[0].banner = banner.join('|');
    return  service.rest('supplier/setList', { items:{$add:arr}}).then(rlt=>{
        const {items} = rlt;
        const {$rltAdd} = items
        if($rltAdd[0].name === 'SequelizeDatabaseError'){ // determine error on DB
        
        return $rltAdd[0].original.sqlMessage // return error message
        }else{
            const { status, message, data } = $rltAdd[0];
            if(status) {
                commit(Type.ADD_SUPPLIER, data);
                return {
                    status: true
                }
            } else {
                return {
                    status: false,
                    message
                }
            }
        }
        }).catch(e=>{
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return e // return error message
        })
    
}

const editSupplier = async ({ commit }, data) => {
    const {newData} = data
    let arrIcon = newData.icons || [];
    let icon = await Promise.all(arrIcon.map(async element => {
        return element.filePath;
    })) || [];
    newData.icon = icon.join('|')|| [];
    let arrBanner = newData.banners || [];
    let banner = await Promise.all(arrBanner.map(async element => {
        return element.filePath;
    })) || [];
    newData.banner = banner.join('|')|| [];
    console.log('🚀 ~ file: actions.js ~ line 111 ~ editSupplier ~ newData', newData)
    return service.rest('supplier/setList', { items:{$update:[newData]}}).then(rlt =>{
        const {items} = rlt;
        const {$rltUpdate} = items
        if(!$rltUpdate[0].status) return {
            status: false,
            message: $rltUpdate[0].messageContent
        }
        // Commit to  state
        commit(Type.EDIT_SUPPLIER, $rltUpdate[0] );
        return {
            status: true,
        }
    }).catch(e=>{
    return {
        status: false,
        message: e.message
    } // return error message
    })
}

const editSupplierSourcing = async ({ commit }, data) => {
    const {newData} = data
    return service.rest('supplier/setList', { items:{$updateIsSourcing:[newData]}}).then(rlt =>{
        const {items} = rlt;
        const {$rltUpdateIsSourcing} = items
        if(!$rltUpdateIsSourcing[0].status) return {
            status: false,
            message: $rltUpdateIsSourcing[0].messageContent
        }
        // Commit to  state
        commit(Type.EDIT_SUPPLIER, $rltUpdateIsSourcing[0].data );
        return {
            status: true,
        }
    }).catch(e=>{
    return {
        status: false,
        message: e.message
    } // return error message
    })
}

const deleteSupplierList = async ({ commit }, value) => {
    if(value.length > 0){
        return service.rest('supplier/setList', { items:{$delete:value}}).then(rlt =>{
            const {items} = rlt;
            
            const {$rltDelete} = items
            // Commit to  state
            commit(Type.DELETE_SUPPLIER, $rltDelete );
            return true
        }).catch(e=>{
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
             return false
            
        })
    }
    
};

const deleteSupplier = async ({ commit }, value) => {
   
    return service.rest('supplier/setList', { items:{$delete:[value.row]}}).then(rlt =>{
        const {items} = rlt;
        const {$rltDelete} = items
        // Commit to  state
        commit(Type.DELETE_SUPPLIER, $rltDelete );
        return true
    }).catch(e=>{
      console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
      return false 
    })
};

const changeStatus = async ({ commit }, value) => {
    return service.rest('supplier/changeStatus', value.row).then(res =>{
        commit(Type.CHANGE_STATUS_SUPPLIER, res);
        console.log('🚀 ~ file: actions.js ~ line 127 ~ returnservice.rest ~ res', res)
        return true
    }).catch(e=>{
      console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
      return false 
    })
};

const confirm = async ({ commit }, value) => {
    return service.rest('supplier/confirm', value).then(res =>{
        if(!res.status) return res;
        commit(Type.CHANGE_STATUS_SUPPLIER, res.data);
        console.log('🚀 ~ file: actions.js ~ line 139 ~ returnservice.rest ~ res', res)
        return { status: true }
    }).catch(e=>{
      console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
      return { status: true, message: e.message } 
    })
};

const getAllSuppliersSelect = async ({ commit }) => {
    const data = await service.rest('supplier/getListPaging', {})

    if (data) {
        commit(Type.GET_ALL_SUPPLIER_SELECT, data);
    } else {
        console.log('error');
    } 
};

const getSupplierInfo = async ({ commit }, id ) => {
    return service.rest('supplier/getInfo', { id }).then(res =>{
        commit(Type.GET_SUPPLIER_INFO, res);
        return true
    }).catch(e=>{
      console.log('🚀 ~ file: actions.js ~ getSupplierInfo ~ e', e)
      return false 
    })
};

const setVerify = async ({ commit }, id ) => {
    return service.rest('supplier/verify', { id }).then(res =>{
        commit(Type.GET_SUPPLIER_INFO, { data: res });
        return true
    }).catch(e=>{
      console.log('🚀 ~ file: actions.js ~ getSupplierInfo ~ e', e)
      return false 
    })
};

const addSupplierFromOrderDetail = async ({ commit }, value) => {
    return service.rest('supplier/setList', { items: { $addIsSourcing: [value] } } ).then(rlt => {
        const { items } = rlt;
        const { $rltAddIsSourcing } = items;
        const { data, message, status } = $rltAddIsSourcing[0];
        if (status && message === 'success') {
            commit(Type.SET_ADD_SUPPLIER_FROM_ORDER_DETAIL, { rltAdd: [data] });
        }
        return { status, message }
    }).catch(e => {
        console.log('🚀 ~ file: actions.js ~ getSupplierInfo ~ e', e)
        return { status: false, message: e.error }
    })
}
export {
    getAllSupplier,
    getAllSupplierForManagement,
    editSupplier,
    editSupplierSourcing,
    addSupplier,
    deleteSupplier,
    deleteSupplierList,
    changeStatus,
    confirm,
    getAllSuppliersSelect,
    getSupplierInfo,
    setVerify,
    addSupplierFromOrderDetail
};