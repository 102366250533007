import {
  SET_GLOBAL_READY,
  TOGGLE_SIDEBAR,
  SWITCH_SIDEBAR,
  HANDLE_SWIPE,
  CHANGE_SIDEBAR_ACTIVE,
  TOGGLE_HELPER,
  SET_SIDEBAR_STATIC,
  SET_LOGIN_MODE
} from 'modules/global/store/types';
import Service from 'core/service';
import axios from 'axios';
import { convertToSlug } from 'core/helpers'

const service = new Service();

const setGlobalReady = ({ commit }, flag) => {
  commit(SET_GLOBAL_READY, flag);
};

const setLoginMode = ({ commit }, mode) => {
  commit(SET_LOGIN_MODE, mode);
};

const setSidebarStatic = ({ commit }, flag) => {
  commit(SET_SIDEBAR_STATIC, flag);
};

const toggleSidebar = ({ commit }) => {
  commit(TOGGLE_SIDEBAR);
};
const switchSidebar = ({ commit }, value) => {
  commit(SWITCH_SIDEBAR, value);
};
const handleSwipe = ({ commit }, e) => {
  commit(HANDLE_SWIPE, e);
};
const changeSidebarActive = ({ commit }, index) => {
  commit(CHANGE_SIDEBAR_ACTIVE, index);
};

const toggleHelper = ({ commit }, payload) => {
  commit(TOGGLE_HELPER, payload);
};

const uploadFile = async ({ dispatch }, { prefix, companyId = 1, categoryId, file }) => {
  const { name, raw, size } = file;
  const slugName = convertToSlug(name.split('.').slice(0, -1).join('.'))
  const extName = name.split('.').pop()
  const newConvertName = new Date().getTime() + '-' + slugName + '.' + extName;
  const { type } = raw;
  const filePath = prefix + '/' + companyId + '/' + categoryId + '/' + newConvertName
  const { status, message, url, signedRequest } = await service.rest('upload', {
    prefix,
    companyId,
    categoryId,
    name: newConvertName,
    filePath,
    fileType: type
  });

  //duplicate image
  if (status === false) {
    throw new Error(message);
  }

  const options = {
    headers: {
      'Content-Type': type
    }
  };
  await axios.put(signedRequest, raw, options);
  const row = {};
  row.fileName = newConvertName;
  row.url = url;
  row.filePath = filePath;
  row.size = size;
  row.module = prefix;
  row.categoryId = categoryId;

  const data = await dispatch(
    'fileManagement/addFile',
    { row },
    {
      root: true
    }
  );
  return data;
};

export {
  setGlobalReady,
  setLoginMode,
  toggleSidebar,
  switchSidebar,
  handleSwipe,
  changeSidebarActive,
  toggleHelper,
  uploadFile,
  setSidebarStatic
};
