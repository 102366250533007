<template>
  <div class="sidebar-wrapper">
    <AdminChangePassword v-if="loginMode === LOGIN_MODE.ADMIN" />
    <SellerChangePassword v-if="loginMode === LOGIN_MODE.SUPPLIER" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AdminChangePassword from './components/admin.changePassword.vue';
import SellerChangePassword from './components/seller.changePassword.vue';
import { LOGIN_MODE } from 'core/constants';
export default {
  name: 'changePassword',
  components: {
    AdminChangePassword,
    SellerChangePassword
  },
  data() {
    return {
      LOGIN_MODE
    };
  },
  computed: {
    ...mapGetters('global', ['loginMode'])
  }
};
</script>
