import Service from 'core/service';
import * as Type from 'modules/orderSupplierStatusHistory/store/types';
const service = new Service();

const getBySupplierId = async ({ commit }, value) => {
    return service.rest('/orderSupplierStatusHistory/getBySupplierId', value).then(res => {
        commit(Type.SET_ORDER_SUPPLIER_STATUS_HISTORY_BY_SUP_ID, res);
        return true
    }).catch(err => {
        console.log(err);
        return false
    });
}


export {
    getBySupplierId
};