import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
import cloneDeep from 'lodash/cloneDeep'
const state = {
    CusNotifications: [],
};

const mutations = {
    async [Type.GET_ALL](state, value) {
      state.CusNotifications = value;
    },
    [Type.DELETE_NOTI](state, value) {
        const oldData = cloneDeep(state.CusNotifications)
        value.map(item=>{
            const {id} =item
            const objIndex =   oldData.findIndex((obj) => obj.id === id );
            oldData.splice(objIndex, 1)
        })
        state.CusNotifications = oldData.slice(0)       
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};