<template>
  <div class="mainContent">
    <div class="containerHeader p-0 container-fluid">
        <nav class="mainNavbar flexContentBetween navbar navbar navbar-expand-lg navbar-dark bg-dark">
          <a class="navbar-brand" href="/">
              <div class="divLogo"><img src="/images/logo-final-1.png" alt="'EI Industrial'" width="120" height="33" /></div>
          </a>
          <div class="flexContent">
              <a href="https://eiindustrial.com/trung-tam-ban-hang" target="_blank"><span class="btnStyle">Đăng ký</span></a>
          </div>
        </nav>
    </div>
    <h2 class="title">Trung tâm bán hàng</h2>
    <div class="row">
      <div class="col-lg-12">
        <div class="screen">
          <div class="row">
            <div class="col-2 col-lg-4"></div>
            <div class="col-8 col-lg-4">
              <form class="mt" @submit.prevent="login">
                <b-alert class="alert-sm" variant="warning" :show="!!errorMessage">
                  {{ errorMessage }}
                </b-alert>
                <div class=" mb-3 form-group">
                  <input
                    class="form-control"
                    ref="email"
                    required
                    type="email"
                    name="email"
                    placeholder="Email đăng nhập"
                  />
                </div>
                <div class=" mb-3 form-group">
                  <input
                    class="form-control"
                    ref="password"
                    required
                    type="password"
                    name="password"
                    placeholder="Mật khẩu"
                  />
                </div>
                <b-button type="submit" class="btnStyleLogin mb-3 btn btn-primary">{{
                  this.isFetching ? 'Loading...' : 'Đăng nhập'
                }}</b-button>
              </form>
              <p class="text-center"><a class="textLink" href="https://eiindustrial.com/trung-tam-ban-hang" target="_blank">Bạn chưa có tài khoản nhà bán ? <strong>Đăng Ký</strong></a> <br>
              <a class="textLink" href="/forgot-password">Quên mật khẩu?
                <strong>Tìm lại mật khẩu</strong>
              </a>
              </p>
              </div>
              <div class="col-2 col-lg-4"></div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'SupplierLoginPage',
  data() {
    return {
      language: 'vi-VN'
    };
  },
  computed: {
    ...mapState('auth', {
      isFetching: state => state.isFetching,
      errorMessage: state => state.errorMessage
    })
  },
  created() {
    const token = this.$cookies.get('access_token');
    if (token) {
      this.$router.push({ name: 'Dashboard' });
    }
  },
  methods: {
    ...mapActions('auth', ['loginSupplierUser', 'setLanguage']),
    async login() {
      this.setLanguage(this.language);
      const email = this.$refs.email.value;
      const password = this.$refs.password.value;
      if (email.length !== 0 && password.length !== 0) {
        const data = await this.loginSupplierUser({ email, password });
        if (data.type == 'success') {
          //if this is first time user login redirect to changePassword page
          const { changePassword, email } = data?.result?.user;
          if (changePassword == false) {
            this.$router.push({
              name: 'ChangePassword',
              query: { email, isFirstTimeLogin: true }
            });
          } else {
            this.$router.push({ name: 'Dashboard' });
          }
        }
      }
    },
    changeClick() {
      this.setLanguage(this.language);
    },
    googleLogin() {
      this.loginSupplierUser({ social: 'google' });
    },
    microsoftLogin() {
      this.loginSupplierUser({ social: 'microsoft' });
    }
  }
};
</script>
