import Service from 'core/service';
const services = new Service();
import * as Type from 'modules/auth/store/types';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN, AUTH_LANGUAGE, REFRESH_TOKEN } from 'core/constants';
import router from 'router';

const getMe = async ({ commit }) => {
  try {
    if (Cookies.get(ACCESS_TOKEN)) {
      const userProfile = await services.rest('auth/me');
      // set user profile in state
      const { data } = userProfile;
      if (!data) {
        router.push('/');
      }
      const { email } = data;
      localStorage.setItem('user-email', email);
      commit(Type.SET_USER, data);

      // change route to dashboard
      if (router.currentRoute.path === '/login') {
        router.push('/');
      }
      return data;
    }
  } catch (error) {
    return {};
  }
};

const loginUser = async ({ commit }, value) => {
  commit(Type.SET_FETCHING, true);
  const data = await services.post('auth/roles/signIn', {
    email: value.email,
    password: value.password,
    currentTokenFcm: value && value.currentTokenFcm || ''
  });
  if (data) {
    Cookies.set(ACCESS_TOKEN, data.tokens.access.token, '30d');
    Cookies.set(REFRESH_TOKEN, data.tokens.refresh.token, '1y');
    commit(Type.SET_USER, data.user);
    localStorage.setItem('user-email', value.email);
    commit(Type.SET_FETCHING, false);
    return {
      type: 'success',
      result: data
    };
  } else {
    commit(Type.SET_ERROR, 'Incorrect email or password');
    commit(Type.SET_FETCHING, false);
    return {
      type: 'error'
    };
  }
};

const loginSupplierUser = async ({ commit }, value) => {
  commit(Type.SET_FETCHING, true);
  const data = await services.post('auth/supplierSignIn', {
    email: value.email,
    password: value.password
  });
  if (data) {
    Cookies.set(ACCESS_TOKEN, data.tokens.access.token, '30d');
    Cookies.set(REFRESH_TOKEN, data.tokens.refresh.token, '1y');
    console.log(
      '🚀 ~ file: actions.js ~ line 70 ~ loginSupplierUser ~  data.user',
      data.user
    );

    commit(Type.SET_USER, data.user);
    localStorage.setItem('user-email', value.email);
    commit(Type.SET_FETCHING, false);
    return {
      type: 'success',
      result: data
    };
  } else {
    commit(Type.SET_ERROR, 'Incorrect email or password');
    commit(Type.SET_FETCHING, false);
    return {
      type: 'error'
    };
  }
};

const forgetPasswordSupplierUser = async (_, value) => {
  const data = await services.post('/auth/sup/forgotPassword', {
    email: value.email
  });
  if (data) {
    return {
      type: 'success',
      result: data
    };
  } else {
    return {
      type: 'error'
    };
  }
};

const registerUser = async ({ commit }, value) => {
  commit(Type.SET_FETCHING, true);
  const data = await services.post('auth/signUp', {
    email: value.email,
    password: value.password,
    username: value.username,
    supplierId: value.supplierId
  });
  if (data) {
    commit(Type.SET_USER, data.user);
    commit(Type.SET_FETCHING, false);
    return {
      type: 'success',
      result: data
    };
  } else {
    commit(Type.SET_ERROR, 'Email has already existed.');
    commit(Type.SET_FETCHING, false);
    return {
      type: 'error'
    };
  }
};

const logoutUser = async ({ commit }) => {
  const data = await services.post('auth/signOut', {
    refreshToken: Cookies.get('refresh_token')
  });
  if (data) {
    commit(Type.SET_USER, null);
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    localStorage.removeItem('user-email');
    return {
      type: 'success',
      result: data
    };
  } else {
    return {
      type: 'error'
    };
  }
};

const setLanguage = ({ commit }, value) => {
  commit(Type.SET_LANGUAGE, value);
  if (value === 'en-US') {
    localStorage.setItem(AUTH_LANGUAGE, 'en-US');
  }
  if (value === 'vi-VN') {
    localStorage.setItem(AUTH_LANGUAGE, 'vi-VN');
  }
};

const registerError = ({ commit }, value) => {
  commit(Type.SET_ERROR, value);
};

export {
  loginUser,
  registerUser,
  registerError,
  logoutUser,
  setLanguage,
  getMe,
  loginSupplierUser,
  forgetPasswordSupplierUser
};
