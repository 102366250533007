import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';

const state = {
  types: []
};

const mutations = {
  [Type.SET_ALL_TYPES](state, value) {
    state.types = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
