import {
  SET_PRODUCT,
  ADD_PRODUCT_LOCAL,
  PRODUCT_ADD,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_LOCAL,
  DELETE_PRODUCT,
  DELETE_PRODUCT_LOCAL,
  UPDATE_MULTIPLE_PRODUCT,
  SET_PRODUCT_UPDATE_MULTIPLE_PRICE,
  SET_PRODUCT_INPAGE,
  SET_EMPTY_PRODUCT_LIST,
  SAVE_PRODUCT_FROM_ORDER_DETAIL
} from './types';
import * as getters from './getters';
import * as actions from './actions';
import cloneDeep from 'lodash/cloneDeep';
import { isEqual } from 'lodash';

const state = {
  products: [],
  productsAddLocal: [],
  totalRows: 0,
  productInPage: [],
  stockOptions: [
    {
      value: -3,
      label: 'Còn hàng'
    },
    {
      value: -2,
      label: 'Không còn kinh doanh'
    },
    {
      value: -1,
      label: 'Hết hàng ( liên hệ )'
    },
    {
      value: 0,
      label: 'Tạm hết hàng'
    }
  ],
  statusOptions: [
    {
      value: 0,
      label: 'Hide'
    },
    {
      value: 1,
      label: 'Publish'
    }
  ]
};

const mutations = {
  [SET_PRODUCT](state, value) {
    state.products = value.rows;
    state.totalRows = value.totalRows;
  },
  [ADD_PRODUCT_LOCAL](state, value) {
    const oldProducts = state.productsAddLocal;
    state.productsAddLocal = [...oldProducts, ...[cloneDeep(value)]];
  },
  [PRODUCT_ADD](state, value) {
    const oldProduct = state.products;
    // add new data
    state.products = [...cloneDeep(value), ...oldProduct];
    state.productsAddLocal = [];
  },
  [PRODUCT_UPDATE](state, value) {
    const oldProduct = state.products;
    const { id } = value;
    const objIndex = state.products.findIndex(obj => obj.id === id);
    oldProduct[objIndex] = value;
    state.products = oldProduct.slice(0);
  },
  [UPDATE_MULTIPLE_PRODUCT](state, products) {
    const oldProduct = state.products;
    products.map(product => {
      const objIndex = state.products.findIndex(obj => obj.id === product.id);
      oldProduct[objIndex] = { ...oldProduct[objIndex], ...product };
    });
    state.products = oldProduct.slice(0);
  },
  [PRODUCT_UPDATE_LOCAL](state, value) {
    const { oldData, newData } = value;
    // File oldData
    const oldProductAddLocal = state.productsAddLocal.map(item => {
      if (isEqual(item, oldData)) {
        item = newData;
      }
      return item;
    });
    state.productsAddLocal = oldProductAddLocal.slice(0);
  },
  [DELETE_PRODUCT](state, value) {
    const oldData = cloneDeep(state.products);
    value.map(item => {
      const { id } = item;
      const objIndex = oldData.findIndex(obj => obj.id === id);
      oldData.splice(objIndex, 1);
    });
    state.products = oldData.slice(0);
  },
  [DELETE_PRODUCT_LOCAL](state, value) {
    const oldData = cloneDeep(state.productsAddLocal);
    value.map(item => {
      const objIndex = oldData.findIndex(obj => {
        return isEqual(obj, item);
      });
      oldData.splice(objIndex, 1);
    });
    state.productsAddLocal = oldData.slice(0);
  },

  [SET_PRODUCT_UPDATE_MULTIPLE_PRICE](state, value){
    if( value && value.length > 0){
      value.forEach(element => {
        const oldProduct = state.products;
        const { id } = element;
        const objIndex = state.products.findIndex(obj => obj.id === id);
        oldProduct[objIndex] = element;
        state.products = oldProduct.slice(0);
      });
    }
  },

  [SET_PRODUCT_INPAGE](state, value){
    state.productInPage = value
  },

  [SET_EMPTY_PRODUCT_LIST](state) {
    state.products = []
  },

  [SAVE_PRODUCT_FROM_ORDER_DETAIL](state, value) {
    const { rltAdd } = value;
    const oldProduct = state.products;
    // add new data
    state.products = [...cloneDeep(rltAdd), ...oldProduct];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
