import Service from 'core/service';
import * as Type from 'modules/mailTemplate/store/types';

const services = new Service()

const getAllMailTemplates = async ({ commit }, value) => {
    const { title = '', comCode = '' } = value;

    const params = { }

    if (title) {
        params.title = title;
    };

    if(comCode){
        params.comCode = comCode
    };
   
    const data = await services.rest('mailTemplate/getListPaging', params)
      
    if (data) {
        commit(Type.GET_ALL, data);
    } else {
        console.log('error');
    } 
};

const saveMailTemplate = async ({ commit }, value) => {
    const allModule= await getListMailTemplates();
    const checkExist = await allModule.find(element => element.name === value.name);

    if(!checkExist) {
        const data = {
            title: value.title,
            content: value.content,
            name: value.name,
            code: value.code,
            params: value.params.join('|'),
            comCode: value.comCode
        }
        return await services.rest('mailTemplate/setList', { 
            items: {
                $add: [data]
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltAdd} = items
            commit(Type.SAVE, $rltAdd);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
        
    } else {
        return {
            type: false, 
            message: 'Item already exist'
        }
    }
};

const editMailTemplate = async ({ commit }, value) => {
    if (value.oldNameEdit.trim() !== value.data.name.trim()) {
        let allModule= await getListMailTemplates();
        const checkExist = await allModule.find(element => element.name == value.data.name)
        if(checkExist) {
            return {
                type: false, 
                message: 'Item already exist'
            }
        }
    } 

    if (value.id) {
        const data = {
            id: value.id,
            title: value.data.title,
            content: value.data.content,
            name: value.data.name,
            code: value.data.code,
            params: value.data.params.join('|'),
            comCode: value.data.comCode
        }
        return await services.rest('mailTemplate/setList', { 
            items: {
                $update: [data]
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltUpdate} = items
            // Commit to news state
            commit(Type.EDIT, $rltUpdate[0]);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const deleteMailTemplate = async ({ commit }, value) => {
    if (value.row.id) { 
        return await services.rest('mailTemplate/setList', { 
            items: {
                $delete: [value.row]
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltDelete} = items
            // Commit to news state
            commit(Type.DELETE, $rltDelete);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const deleteMailTemplates = async ({ commit }, value) => {
    if (value.length > 0) { 
        return await services.rest('mailTemplate/setList', { 
            items: {
                $delete: value
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltDelete} = items
            // Commit to news state
            commit(Type.DELETE, $rltDelete);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const getListMailTemplates = async () => {    
    const data = await services.rest('mailTemplate/getListPaging', {})
    .then(res=> {
        return res.rows
    }).catch(err=> {
        console.log(err);
    })
    return data
};


export {
    editMailTemplate,
    getAllMailTemplates,
    saveMailTemplate,
    deleteMailTemplate,
    deleteMailTemplates
};