import { render, staticRenderFns } from "./customer.profile.vue?vue&type=template&id=206408e0&"
import script from "./customer.profile.vue?vue&type=script&lang=js&"
export * from "./customer.profile.vue?vue&type=script&lang=js&"
import style0 from "./customer.profile.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports