import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  categories: [],
  fullCategories: [],
  categoriesMap: [],
  categoriesAddLocal: []
};

import cloneDeep from 'lodash/cloneDeep';
import {
  isEqual
} from 'lodash';

const mutations = {

  [Type.SET_ALL_CATEGORY](state, value) {
    state.categories = value
    state.categoriesMap = state.categories.reduce(
      (cMap, c) => ({
        ...cMap,
        [c.id]: c
      }), {}
    );
  },

  [Type.SET_FULL_CATEGORY](state, value) {
    state.fullCategories = value
  },

  [Type.SET_CATEGORY_LOCAL](state, value) {
    const oldCategories = state.categoriesAddLocal
    state.categoriesAddLocal = [...oldCategories, ...[cloneDeep(value)]];
  },

  [Type.SET_CATEGORY_ADD](state, value) {
    const oldCategories = state.categories
    // add new data
    state.categories = [cloneDeep(value), ...oldCategories];
    state.categoriesAddLocal = [];
  },

  [Type.SET_CATEGORY_UPDATE](state, value) {
    const oldCategories = state.categories
    const {
      id
    } = value
    const objIndex = state.categories.findIndex((obj => obj.id === id));
    oldCategories[objIndex] = value;
    state.categories = oldCategories.slice(0);
  },

  [Type.SET_CATEGORY_UPDATE_LOCAL](state, value) {
    const {
      oldData,
      newData
    } = value
    // File oldData        
    const oldCategoriesAddLocal = state.categoriesAddLocal.map(item => {
      if (isEqual(item, oldData)) {
        item = newData
      }
      return item
    })
    state.categoriesAddLocal = oldCategoriesAddLocal.slice(0);
  },

  [Type.SET_DELETE_CATEGORY](state, value) {
    const oldData = cloneDeep(state.categories)
    value.map(item => {
      const {
        id
      } = item
      const objIndex = oldData.findIndex((obj) => obj.id === id);
      oldData.splice(objIndex, 1)
    })
    state.categories = oldData.slice(0)
  },

  [Type.SET_DELETE_CATEGORY_LOCAL](state, value) {

    const oldData = cloneDeep(state.categoriesAddLocal)
    value.map(item => {
      const objIndex = oldData.findIndex((obj) => {
        return isEqual(obj, item)
      });
      oldData.splice(objIndex, 1)
    })
    state.categoriesAddLocal = oldData.slice(0)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};