import Service from 'core/service';
// import { cloneDeep } from 'lodash';
import * as Type from 'modules/user/store/types';

const service = new Service();

const getAllUsers = async ({
    commit
}) => {
    const params = {};
    // Get All News
    return service
        .rest('user/getListPaging', params)
        .then(res => {
            const {
                data
            } = res;
            commit(Type.SET_ALL_USERS, data.rows);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

const getUsersSetting = async ({
    commit
}) => {
    const params = {};
    return service
        .rest('user/getListSetting', params)
        .then(res => {
            const {
                data
            } = res;
            commit(Type.SET_ALL_USERS, data.rows);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

const resetPassword = async (_, value) => {
    return service
        .rest('user/resetPassword', value)
        .then(() => {
            return true;
        })
        .catch(err => {
            console.log('err resetPassword:', err);
            throw new Error('Current password is not match or Password is earlier used');
        });
};

const saveUser = async ({
    commit
}, value) => {
    const allUser = await getListUser();
    const checkUserExist = await allUser.find(element => element.email === value.email);

    let arrAvatar = value.avatars;
    let avatar = await Promise.all(arrAvatar.map(async element => {
        return element.filePath;
    }));
    value.avatar = avatar.join('|');

    if (!checkUserExist) {
        return await service.rest('user/setList', {
            items: {
                $add: [value]
            }
        }).then(rlt => {
            const {
                data
            } = rlt;
            const {
                items
            } = data;
            const {
                $rltAdd
            } = items
            commit(Type.SAVE, $rltAdd);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })

    } else {
        return {
            type: false,
            message: 'Item already exist'
        }
    }
};

const editUser = async ({
    commit
}, value) => {
    if (value.oldEmailEdit.trim() !== value.data.email.trim()) {
        let allUser = await getListUser();
        const checkUserExist = await allUser.find(element => element.email == value.data.email)
        if (checkUserExist) {
            return {
                type: false,
                message: 'Item already exist'
            }
        }
    }

    if (value.id) {
        let arrAvatar = value.data.avatars;
        let avatar = await Promise.all(arrAvatar.map(async element => {
            return element.filePath;
        }));
        value.data.avatar = avatar.join('|');
        value.data.id = value.id;
        return await service.rest('user/setList', {
            items: {
                $update: [value.data]
            }
        }).then(rlt => {
            const {
                data
            } = rlt;
            const {
                items
            } = data;
            const {
                $rltUpdate
            } = items
            // Commit to news state
            commit(Type.EDIT, $rltUpdate[0]);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const deleteUser = async ({
    commit
}, value) => {
    if (value.row.id) {
        return await service.rest('user/setList', {
            items: {
                $delete: [value.row]
            }
        }).then(rlt => {
            const {
                data
            } = rlt;
            const {
                items
            } = data;
            const {
                $rltDelete
            } = items
            // Commit to news state
            commit(Type.DELETE, $rltDelete);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const deleteUsers = async ({
    commit
}, value) => {
    if (value.length > 0) {
        return await service.rest('user/setList', {
            items: {
                $delete: value
            }
        }).then(rlt => {
            const {
                data
            } = rlt;
            const {
                items
            } = data;
            const {
                $rltDelete
            } = items
            // Commit to news state
            commit(Type.DELETE, $rltDelete);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const getListUser = async () => {
    const params = {};
    // Get All News
    const data = await service
        .rest('user/getListPaging', params)
        .then(res => {
            const {
                data
            } = res;
            return data.rows
        })
        .catch(err => {
            console.log(err);
            return null;
        });
    return data
};

export {
    getAllUsers,
    getUsersSetting,
    resetPassword,
    editUser,
    saveUser,
    deleteUser,
    deleteUsers
};