import Service from 'core/service';
import * as Type from 'modules/collaborator/store/types';

const services = new Service()

const getAllCollaborators = async ({
    commit
}, value) => {
    const {
        name = '',
        refCode = '',
        currentPage = ''
    } = value;

    const params = {}

    if (name != '') {
        params.name = name;
    };

    if (refCode != '') {
        params.refCode = refCode;
    };

    if (currentPage != '') {
        params.currentPage = currentPage;
    };

    const result = await services.rest('collaborator/getListPaging', params)

    if (result.status) {
        const {
            items
        } = result;
        commit(Type.GET_ALL, items.data);
    } else {
        console.log('error');
    }
};

const addCollaborator = async ({
    commit
}, value) => {
    const {
        name,
        phone,
        avatar,
        address,
        email,
        saleId,
        isActive,
    } = value;
    const data = {
        name,
        phone,
        avatar,
        address,
        email,
        saleId,
        isActive,
    }
    return await services.rest('collaborator/setList', {
        items: {
            $add: [data]
        }
    }).then(rlt => {
        const {
            items
        } = rlt;
        const {
            $rltAdd
        } = items
        commit(Type.SAVE, $rltAdd);
        return {
            status: true,
            message: 'success'
        }
    }).catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
        return {
            status: false,
            message: 'error'
        }
    })
};

const editCollaborator = async ({
    commit
}, value) => {
    if (value.newData.id) {
        const {
            id,
            name,
            phone,
            avatar,
            address,
            email,
            isActive,
        } = value.newData;
        const data = {
            id,
            name,
            phone,
            avatar,
            address,
            email,
            isActive,
        }

        return await services.rest('collaborator/setList', {
            items: {
                $update: [data]
            }
        }).then(rlt => {
            const {
                items
            } = rlt;
            const {
                $rltUpdate
            } = items;
            // Commit to news state
            commit(Type.EDIT, $rltUpdate[0]);
            return {
                status: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                status: false,
                message: 'error'
            }
        })
    }
};

const deleteCollaborator = async ({
    commit
}, value) => {
    if (value.length > 0) {
        return await services.rest('collaborator/setList', {
            items: {
                $delete: value
            }
        }).then(rlt => {
            const {
                items
            } = rlt;
            const {
                $rltDelete
            } = items
            // Commit to news state
            commit(Type.DELETE, $rltDelete);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

export {
    editCollaborator,
    getAllCollaborators,
    addCollaborator,
    deleteCollaborator
};