<template>
  <div class="tables-basic">
    <b-row>
      <b-col style="">
        <!-- Status and quotation code (S) -->
        <el-row type="flex" class="row-quotation-status">
          <div >
            <el-tag style="" type="success" effect="dark">
              {{ `#${formQuotation.code}` }}
            </el-tag>
          </div>
          <div>
            <!-- <el-tag
              :type="getTypeColorQuotationStatus(formQuotation.quotationStatusId)"
              effect="dark"
            >
              {{ getNameQuotationStatus(formQuotation.quotationStatusId) }}
            </el-tag> -->

            <el-select
              size="medium"
              :value="formQuotation.quotationStatusId"
              width="100%"
              filterable
              class="select_quotation_status"
              @change="handleChangeQuotationStatus($event)"
              :disabled="isConvertToOrder"
            >
              <template slot="prefix">
                <el-tag
                  :disable-transitions="true"
                  :key="formQuotation.quotationStatusId"
                  :type="getTypeColorQuotationStatus(formQuotation.quotationStatusId)"
                  effect="dark"
                  style="padding-right: 10px"
                >
                  {{ getNameQuotationStatus(formQuotation.quotationStatusId) }}
                </el-tag>
              </template>
              <el-option
                v-can="'quotation.edit'"
                v-for="item in quotationStatusList"
                :key="item.value"
                :value="item.value"
              >
                <span>
                  <el-tag
                    :disable-transitions="true"
                    :key="item.label"
                    :type="item.color"
                    effect="dark"
                  >
                    {{ item.label }}
                  </el-tag>
                </span>
              </el-option>
            </el-select>
          </div>
          <div>
            <el-switch v-model="formQuotation.isImportant"> </el-switch>
          </div>
          <div v-if="isShowCancelReason">
            <el-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 2}"
              placeholder="Cancel Reason"
              v-model="getCancelReason"
            >
            </el-input>
          </div>
        </el-row>
        <!-- Status and quotation code (E) -->
        <Widget>
          <el-form
            ref="formQuotation"
            :rules="rulesFormQuotation"
            :model="formQuotation"
            label-width="200px"
          >
            <el-row>
              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <!-- Customer (S) -->
                <el-form-item
                  :label="$i18n.t('src.modules.customer.index.customerName')"
                  prop="customerId"
                >
                  <template>
                    <div class="el-autocomplete">
                      <div class="el-input el-input-group el-input-group--append">
                        <el-select
                          size="medium"
                          disabled
                          placeholder="Select Customer"
                          width="100%"
                          v-model="formQuotation.customerId"
                        >
                          <el-option
                            v-for="item in customersList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                        <div v-if="allowShowButtonShowCustomerInfo" class="el-input-group__append">
                          <el-tooltip content="Show Customer Info" placement="top">
                            <el-button
                              type="primary"
                              icon="el-icon-s-custom"
                              @click="onClickShowCustomerInfo"
                              class="btn-blue-lg"
                            >
                            </el-button>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-form-item>
                <!-- Customer (E) -->
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$i18n.t('src.modules.quotation.index.customerAddress')"
                  prop="customerAddressId"
                >
                  <el-select
                    size="medium"
                    clearable
                    placeholder="Select Customer Address"
                    width="100%"
                    filterable
                    v-model="formQuotation.customerAddressId"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="item in customerAddressList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <!-- Sales (S) -->
                <!-- <el-form-item
                  :label="$i18n.t('src.modules.quotation.index.sales')"
                  prop="salesInChargeIdList"
                >
                  <template>
                    <el-select
                      size="medium"
                      clearable
                      multiple
                      collapse-tags
                      placeholder="Select Sales Incharge"
                      width="100%"
                      filterable
                      v-model="formQuotation.salesInChargeIdList"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="item in salesList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-form-item> -->
                <!-- Sales (E) -->
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <!-- Delivery  (S) -->
                <el-form-item :label="$i18n.t('src.modules.quotation.index.estimateDeliveryDate')" prop="estimateDeliveryDate">
                  <el-date-picker
                    v-model="formQuotation.estimateDeliveryDate"
                    type="date"
                    format="dd/MM/yyyy"
                    placeholder="Pick a date">
                  </el-date-picker>
                </el-form-item>
                <!-- BD (S) -->
                <!-- <el-form-item
                  :label="$i18n.t('src.modules.quotation.index.BD')"
                  prop="bdInChargeIdList"
                >
                  <template>
                    <el-select
                      size="medium"
                      clearable
                      multiple
                      collapse-tags
                      placeholder="Select BD Incharge"
                      width="100%"
                      filterable
                      v-model="formQuotation.bdInChargeIdList"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="item in BDList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </template>
                  
                </el-form-item> -->
                <!-- BD (E) -->
              </el-col>
            </el-row>
            <el-row>
              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                
              </el-col>

              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$i18n.t('src.modules.quotation.index.personIncharge')"
                  prop="personInChargeId"
                >
                  <el-select
                    size="medium"
                    clearable
                    placeholder="Select Person Incharge"
                    width="100%"
                    filterable
                    v-model="formQuotation.personInChargeId"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="item in personInChargeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <!-- Note (S) -->
                <el-form-item :label="$i18n.t('src.modules.quotation.note')" prop="note">
                  <el-input
                    size="medium"
                    type="textarea"
                    :rows="2"
                    placeholder="Note"
                    v-model="formQuotation.note"
                  />
                </el-form-item>
                <!-- Note (E) -->
                
              </el-col>
            </el-row>
            <!-- estimateDeliveryDate (S) -->
            <el-row>
              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                
              </el-col>
            </el-row>
            <!-- estimateDeliveryDate (E) -->
            <el-row type="flex" justify="end">
              <el-tooltip
                class="item"
                effect="dark"
                content="Update Quotation"
                placement="top-start"
              >
                <el-button
                  v-can="'quotation-detail.edit'"
                  type="primary"
                  icon="el-icon-edit"
                  @click="onClickUpdateQuotation()"
                  v-if="!isConvertToOrder || isUpdateQuotation !== true"
                  class="btn-blue-lg"
                >
                  {{ $i18n.t('src.modules.quotationDetail.index.btn.updateQuotation') }}
                </el-button>
              </el-tooltip>

              <el-tooltip
                class="item"
                effect="dark"
                content="Reset the form"
                placement="top-start"
              >
                <el-button
                  v-can="'quotation-detail.edit'"
                  type="info"
                  icon="el-icon-refresh-left"
                  @click="onClickResetQuotationForm()"
                  :disabled="isUpdateQuotation"
                  class="btn-black-lg"
                >
                  Reset
                </el-button>
              </el-tooltip>
            </el-row>
          </el-form>
        </Widget>
        <Widget style="margin-bottom: 0px">
          <SelectProduct
            :visible="selectProductVisible"
            :selected-items="multipleSelectProduct"
            :customer-id="customerInfo.id"
            @onChangeSelect="handleSelectionChange"
            @onCloseAddProduct="handleCloseAddProduct"
            @onClickAddMultiRow="handleAddMultiRow"
            @onClickAddSingleRow="onClickAddRow"
            @onSaveAddOrderDetail="handleAddMultiRow"
          />
          <!-- Button Action (S) -->
          <el-row type="flex" justify="start">
            <el-col>
              <div class="grid-content">
                <el-row type="flex" justify="end">
                  <el-button
                    v-can="'quotation-detail.edit'"
                    size="medium"
                    type="primary"
                    @click="selectProductVisible = true"
                    v-if="!isConvertToOrder"
                  >
                    <i class="el-icon-circle-plus-outline"></i>
                    {{ $i18n.t('src.modules.quotationDetail.index.btnAddProduct') }}
                  </el-button>

                  <el-button
                    v-can="'quotation-detail.edit'"
                    size="medium"
                    type="success"
                    @click="handleSaveQuotationDetail"
                    :disabled="this.quotationDetailAddLocal.length === 0"
                  >
                    <i class="el-icon-check"></i>&nbsp;
                    {{ $i18n.t('src.modules.quotationDetail.index.btn.saveRecord') }}
                    <span v-if="this.quotationDetailAddLocal.length > 0">
                     {{ $i18n.t('src.modules.quotationDetail.index.btn.record', { record: this.quotationDetailAddLocal.length }) }}
                    </span>
                  </el-button>

                  <el-button
                    icon="el-icon-s-promotion"
                    v-can="'quotation-detail.read'"
                    size="medium"
                    type="warning"
                    @click="onShowSendMailDialog"
                  >
                    {{ this.$i18n.t('src.modules.order.detail.sendMail.btnSendMail') }}
                  </el-button>

                  <el-button
                    icon="el-icon-download"
                    v-can="'quotation-detail.read'"
                    size="medium"
                    type="info"
                    :loading="isButtonDownloadPDFLoading"
                    @click="printDownload()"
                  >
                    {{ this.$i18n.t('src.modules.quotationDetail.index.btnDownloadPDF') }}
                  </el-button>
                </el-row>
              </div>
            </el-col>
          </el-row>
          <!-- Button Action (E) -->

          <!-- Table Quotations Data (S) -->
          <el-row>
            <el-table
              v-loading="isLoading"
              :data="pagedTableData"
              height="400"
              :default-sort="{ prop: 'updatedAt', order: 'descending' }"
              style="width: 100%"
            >
              <!-- Id (S) -->
              <el-table-column prop="id" label="#" width="80">
                <template slot-scope="scope">
                  <span v-if="scope.row.id">{{ scope.row.id }}</span>
                  <el-tag v-else type="danger">New</el-tag>
                </template>
              </el-table-column>
              <!-- Id (E) -->

              <!-- Image Product (S) -->
              <el-table-column
                :label="this.$i18n.t('src.modules.quotationDetail.index.image')"
                :width="100"
              >
                <template slot-scope="scope">
                  <el-image 
                    style="width: 50px; height: 50px" 
                    :src="S3_STORE_ENDPOINT + '/' + getProductImage(scope.row.images)">
                  </el-image>
                </template>
              </el-table-column>
              <!-- Image Product (E) -->

              <!-- Product Name (S) -->
              <el-table-column
                :label="this.$i18n.t('src.modules.quotationDetail.index.productName')"
                :width="300"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="scope.row.productName"
                    placement="top"
                    v-if="scope.row.productName && scope.row.productName.length > 50"
                  >
                    <span>{{ scope.row.productName.slice(0, 50) + '...' }}</span>
                  </el-tooltip>
                  <span v-else>
                    {{ scope.row.productName || 'No Data' }}
                  </span>
                </template>
              </el-table-column>
              <!-- Product Name (E) -->

              <!-- Supplier Name (S) -->
              <el-table-column
                prop="supplierName"
                :label="$i18n.t('src.modules.quotationDetail.index.supplierName')"
                width="200"
                sortable
              >
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="scope.row.supplierName"
                    placement="top"
                    v-if="scope.row.supplierName && scope.row.supplierName.length > 30"
                  >
                    <span>{{ scope.row.supplierName.slice(0, 30) + '...' }}</span>
                  </el-tooltip>
                  <span v-else>
                    {{ scope.row.supplierName || 'No Data' }}
                  </span>
                </template>
              </el-table-column>
              <!-- Supplier Name (E)-->

              <!-- Note (S) -->
              <el-table-column
                prop="note"
                :label="$i18n.t('src.modules.quotation.note')"
                width="200"
                sortable
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="editRow != scope.$index">
                    {{ scope.row.note || 'No Data' }}
                  </span>
                  <el-input
                    v-else
                    type="textarea" 
                    :rows="4" 
                    placeholder="Please input" 
                    v-model="scope.row.note">
                  </el-input>
                </template>
              </el-table-column>
              <!-- Note (E)-->

              <!-- Quantity (S) -->
              <el-table-column
                prop="quanity"
                :label="this.$i18n.t('src.modules.quotationDetail.index.quantity')"
                width="150"
                style="text-align: center"
              >
                <template slot-scope="scope">
                  <span v-if="editRow != scope.$index">{{ scope.row.quantity }}</span>
                  <el-input-number
                    v-else
                    v-model="scope.row.quantity"
                    size="mini"
                    :min="1"
                    style="width: 100%"
                  ></el-input-number>
                </template>
              </el-table-column>
              <!-- Quantity (E)-->

              <!-- Unit (S) -->
              <el-table-column
                prop="unitName"
                :label="this.$i18n.t('src.modules.quotationDetail.index.unit')"
                width="130"
                style="text-align: center"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="scope.row.unitName"
                    placement="top"
                    v-if="scope.row.unitName && scope.row.unitName.length > 30"
                  >
                    <span>{{ scope.row.unitName.slice(0, 30) + '...' }}</span>
                  </el-tooltip>
                  <span v-else>
                    {{ scope.row.unitName || 'No Data' }}
                  </span>
                </template>
              </el-table-column>
              <!-- Unit (E)-->

              <!-- SupplierPrice (S) -->
              <el-table-column
                prop="supplierPrice"
                :label="this.$i18n.t('src.modules.product.index.supplierPrice')"
                width="140"
              >
                <template slot-scope="scope">
                  <span>
                    {{
                      new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND'
                      }).format(scope.row.supplierPrice)
                    }}
                  </span>
                </template>
              </el-table-column>
              <!-- Supplier Price (E) -->

              <!-- Unit Price (S) -->
              <el-table-column
                :label="this.$i18n.t('src.modules.quotationDetail.index.unitPrice')"
                width="150"
              >
                <template slot-scope="scope">
                  <span v-if="editRow != scope.$index"
                    >{{
                      new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND'
                      }).format(scope.row.unitPrice)
                    }}
                  </span>
                  <el-input
                    v-else
                    placeholder="Please type unit price"
                    size="small"
                    @blur="isInputActive = false"
                    @focus="isInputActive = true"
                    v-model="displayUnitPriceValue"
                  ></el-input>
                </template>
              </el-table-column>
              <!-- Unit Price (E) -->

              <!-- Tax Percent (S) -->
              <el-table-column
                prop="vatPercent"
                :label="this.$i18n.t('src.modules.quotationDetail.index.tax')"
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="editRow != scope.$index"
                    >
                    {{
                      new Intl.NumberFormat('en-US', {
                        style: 'percent',
                        maximumFractionDigits:2
                      }).format( scope.row.vatPercent / 100 )
                    }}
                  </span>
                  <el-input
                    v-else
                    placeholder="Please type tax"
                    size="small"
                    @blur="isInputActive = false"
                    @focus="isInputActive = true"
                    v-model="scope.row.vatPercent"
                  ></el-input>
                </template>
              </el-table-column>
              <!-- Tax Percent (E) -->

              <!-- Total (S) -->
              <el-table-column
                prop="total"
                :label="this.$i18n.t('src.modules.quotationDetail.index.total')"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$i18n.t('src.modules.quotationDetail.index.tooltip.totalWithTax', {
                      taxPercent: 
                        scope.row.vatPercent && 
                        scope.row.vatPercent >= 0 ? 
                        scope.row.vatPercent : 10
                    })"
                    placement="top"
                  >
                  <span>
                    {{
                      new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND'
                      }).format(scope.row.total)
                    }}
                  </span>
                  </el-tooltip>
                  
                </template>
              </el-table-column>
              <!-- Total (E) -->

              <!-- Actions (S) -->
              <el-table-column width="140" fixed="right">
                <template slot-scope="scope">
                  <el-button
                    v-can="'quotation-detail.edit'"
                    v-if="scope.row.isSourcing && scope.row.id"
                    type="primary"
                    icon="el-icon-goods"
                    size="mini"                    
                    @click="onClickEditSourcingProduct(scope)"
                    class="btn-blue-sm"
                  >
                    {{ $i18n.t('src.modules.quotation.index.button.editProduct') }}
                  </el-button>
                  <el-button
                    v-can="'quotation-detail.edit'"
                    v-if="editRow != scope.$index && scope.row.id"
                    type="primary"
                    icon="el-icon-edit"
                    size="mini"                    
                    @click="enableEditing(scope.$index)"
                    class="btn-blue-sm"
                  >
                    {{ $i18n.t('src.modules.quotation.index.button.edit') }}
                  </el-button>
                  <el-button
                    v-can="'quotation-detail.edit'"
                    v-else-if="scope.row.id"
                    type="warning"
                    icon="el-icon-document-checked"
                    size="mini"                    
                    @click="onSaveEditQuotationDetail(scope.$index)"
                    class="btn-orange-sm"
                  >
                    {{ $i18n.t('src.modules.quotation.index.button.saveChange') }}
                  </el-button>
                  <el-button
                    v-can="'quotation-detail.delete'"
                    v-if="editRow != scope.$index"
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"                    
                    @click="onClickRemove(scope)"
                    class="btn-red-sm"
                  >
                    {{ $i18n.t('src.modules.quotation.index.button.delete') }}
                  </el-button>
                  <el-button
                    v-can="'quotation-detail.edit'"
                    v-else
                    type="danger"
                    icon="el-icon-close"
                    size="mini"                    
                    @click="onCloseEditing(scope.$index)"
                    class="btn-blue-sm"
                  >
                    {{ $i18n.t('src.modules.quotation.index.button.cancelChange') }}
                  </el-button>
                </template>
              </el-table-column>
              <!-- Actions (E) -->
            </el-table>
          </el-row>
          <!-- Table Customers Data (E) -->

          <!-- Paging (S) -->
          <el-row type="flex" justify="center">
            <el-col>
              <div class="grid-content bg-purple">
                <el-pagination
                  class="pagination"
                  @current-change="changePage"
                  :page-size="pageSize"
                  :current-page="currentPage"
                  layout="prev, pager, next"
                  :total="totalQuotationDetailCnt"
                >
                </el-pagination>
              </div>
            </el-col>
          </el-row>
          <!-- Paging (E) -->

          <!-- Statistical (S) -->
          <el-row type="flex" justify="end">
            <el-col :span="6" :offset="6">
              <h5 class="font-weight-bold pb-3 mb-2" style="border-bottom: 1px solid rgba(0, 0, 0, 0.05)">
                {{ this.$i18n.t('src.modules.quotationDetail.index.quotationInfo') }}
              </h5>
              <div class="d-flex justify-content-between mb-3">
                <div style="color: #696969">
                  <span>
                    {{ this.$i18n.t('src.modules.quotationDetail.index.quotationInfo.totalWithSupPrice') }}
                  </span>
                </div>
                <div class="font-weight-bold">
                  <span
                    >{{
                      new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND'
                      }).format(this.quotationInfo.totalBySupPrice)
                    }}
                  </span>
                </div>
              </div>
              <div class="d-flex justify-content-between mb-3">
                <div style="color: #696969">
                  <span>
                    {{ this.$i18n.t('src.modules.quotationDetail.index.quotationInfo.totalWithTaxFee') }}
                  </span>
                </div>
                <div class="font-weight-bold">
                  <span
                    >{{
                      new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND'
                      }).format(this.quotationInfo.subTotal)
                    }}
                  </span>
                </div>
              </div>

              <div class="d-flex justify-content-between mb-3">
                <div style="color: #696969">
                  <span>{{$i18n.t('src.modules.order.detail.shippingFee')}}</span>
                </div>
                <div class="font-weight-bold">
                  <div class="d-flex justify-content-between" v-if="!isEnableEditShippingFee">
                    <span class="mr-2"
                      >+
                      {{
                        new Intl.NumberFormat('vi-VN', {
                          style: 'currency',
                          currency: 'VND'
                        }).format(this.quotationInfo.shippingFee)
                      }}
                    </span>
                    <button 
                      v-can="'order-detail.edit'" 
                      class="btn-edit" 
                      @click="enableEditShippingFee()"
                    >
                      <i class="el-icon-edit" />
                    </button>
                  </div>
                  <div class="d-flex justify-content-between" v-else>
                    <el-input 
                      placeholder="Please input" 
                      v-model="shippingFeeWithComma" 
                      @keyup.enter.native="handleEnterShippingFeeKeyUp" 
                      size="small"
                    ></el-input>
                    <button 
                      v-can="'order-detail.edit'" 
                      class="btn-save" 
                      @click.prevent="closeEditShippingFee()" 
                      ref="btnSaveShippingFee"
                    >
                      <i class="el-icon-document-checked" />
                    </button>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between align-items-center pt-3 mt-3" style="border-top: 3px solid black; font-size: 16px; font-weight: bold">
                <div style="color: #696969; font-size: 18px">
                  <span>{{ this.$i18n.t('src.modules.order.detail.total') }}</span>
                  <el-button
                    style="margin-left: 5px"
                    v-can="'quotation.edit'"
                    size="small"
                    type="primary"
                    icon="el-icon-refresh"
                    @click="handleRecalculateQuotation"
                  >
                  </el-button>
                </div>
                <div class="font-weight-bold">
                  <span style="font-size: 18px">
                    {{
                      new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND'
                      }).format(this.quotationInfo.total)
                    }}
                  </span>
                </div>
              </div>

            </el-col>
          </el-row>
          <!-- Statistical (E) -->

          <!-- Button Function (S) -->
          <el-row type="flex" justify="end">
            <el-col>
              <div class="grid-content">
                <el-row type="flex" justify="end">
                  <!-- <el-button
                    v-can="'quotation-detail.read'"
                    loading: true
                    size="medium"
                    type="primary"
                    @click="onClickGenerateExcel()"
                  >
                    <i class="el-icon-download"></i>
                    {{ 'Excel File' }}
                  </el-button> -->

                  <el-button
                    v-can="'quotation-detail.edit'"
                    v-if="isDisabledButtonCreateOrder"
                    loading: true
                    size="medium"
                    type="danger"
                    @click="onClickCreateOrderFromQuotation()"
                  >
                    <i class="el-icon-document-copy"></i>
                    Convert To Order
                  </el-button>
                </el-row>
              </div>
            </el-col>
          </el-row>
          <!-- Button Function (E) -->
        </Widget>
      </b-col>
    </b-row>
    <el-dialog
      :title="$i18n.t('src.modules.quotationDetail.index.dialog.title')"
      :visible.sync="dialogCustomerVisible"
      width="35%"
    >
      <el-form label-position="right" label-width="130px" :model="customerInfo">
        <el-form-item>
          <el-image
            style="width: 100px; height: 100px; border-radius: 50%"
            :src="S3_STORE_ENDPOINT + '/' + getCustomerAvatar(customerInfo.avatar)"
            fit="cover"
          >
          </el-image>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.modules.customer.index.customerCode')" prop="customerCode">
          <el-input v-model="customerInfo.code"></el-input>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.modules.customer.index.customerName')" prop="customerName">
          <el-input v-model="customerInfo.customerName"></el-input>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.modules.customer.index.phone')" prop="phone">
          <el-input v-model="customerInfo.phone"></el-input>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.modules.customer.index.email')" prop="email">
          <el-input v-model="customerInfo.email"></el-input>
        </el-form-item>
        <el-form-item :label="$i18n.t('src.modules.customer.index.taxCode')" prop="taxCode">
          <el-input v-model="customerInfo.taxCode"></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- Send Mail Popup -->
    <el-dialog
      :title="$i18n.t('src.modules.order.detail.sendMail.title')"
      :visible.sync="isShowSendMailDialog"
      width="60%"
      :before-close="handleCloseSendMailDialog"
      custom-class="select-address-dialog"
    >
      <b-row v-loading="isLoadingSendMail">
        <b-col cols="5" style="border-right: 1px solid #f0f0f0">
          <div class="sub-title mb-3">
            {{ this.$i18n.t('src.modules.order.detail.sendMail.select.receiveMail.title') }}
          </div>
          <el-cascader
            :options="roleObj"
            :props="props"
            :show-all-levels="false"
            filterable
            clearable
            style="width: 90%"
            class="mb-3"
            @change="handleChangeListMail"
          ></el-cascader>
          <el-checkbox v-model="checkedCustomerMail" class="mb-3"
            >{{ this.$i18n.t('src.modules.order.detail.sendMail.checkbox.title') }}: {{ this.customerInfo.email }}
          </el-checkbox>
          <div class="sub-title mb-3">
            {{ this.$i18n.t('src.modules.order.detail.sendMail.inputAdd.title') }}
          </div>
          <b-row class="d-flex justify-content-between mb-3">
            <b-col cols="8">
              <el-input
                :placeholder="$i18n.t('src.modules.order.detail.sendMail.inputAdd.placeholder')"
                v-model="addedMail"
                size="medium"
                @keyup.enter.native="handleEnterKeyUp"
                clearable
                filterable
              ></el-input>
            </b-col>
            <b-col cols="4">
              <el-button type="primary" icon="el-icon-plus" size="medium" @click="handleAddMail" ref="addMail">
                {{ this.$i18n.t('src.modules.order.detail.sendMail.btnAdd') }}
              </el-button>
            </b-col>
          </b-row>
          <el-tag v-for="mail in listAddedMail" :key="mail" closable type="info" class="mr-2 mb-3" @close="handleRemoveMail(mail)">
            {{ mail }}
          </el-tag>
        </b-col>
        <b-col cols="7">
          <el-form ref="formMail" :rules="ruleFormMail" :model="formMail" label-width="150px">
            <el-form-item :label="$i18n.t('src.modules.order.detail.sendMail.select.template.label')" prop="template">
              <el-select v-model="formMail.template" :placeholder="$i18n.t('src.modules.order.detail.sendMail.select.template.placeholder')" width="100%" clearable filterable>
                <el-option v-for="mt in listMailTemplates" :key="mt.value" :label="mt.label" :value="mt.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$i18n.t('src.modules.order.detail.sendMail.input.title.title')" width="100%" prop="note">
              <el-input type="text" v-model="formMail.title" :placeholder="$i18n.t('src.modules.order.detail.sendMail.input.title.placeholder')"> </el-input>
            </el-form-item>
            <el-form-item :label="$i18n.t('src.modules.order.detail.sendMail.textarea.note.title')" width="100%" prop="note">
              <el-input type="textarea" v-model="formMail.note" :rows="4" :placeholder="$i18n.t('src.modules.order.detail.sendMail.textarea.note.placeholder')"></el-input>
            </el-form-item>
          </el-form>
        </b-col>
      </b-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowSendMailDialog = false" icon="el-"> Cancel </el-button>
        <el-button type="warning" @click="handlePreviewMail">
          {{ this.$i18n.t('src.modules.order.detail.sendMail.btnPreviewMail') }}
        </el-button>
        <el-button type="warning" @click="handleSendMail">
          {{ this.$i18n.t('src.modules.order.detail.sendMail.btnSendMail') }}
        </el-button>
      </span>
    </el-dialog>
    <!-- Send Mail Popup -->

    <!-- Print Quotation HTML (S) -->
    <VueHtml2pdf 
      :manual-pagination="true" 
      :filename="formQuotation.code + '-' + Date.now()" 
      :enable-download="true"
      :paginate-elements-by-height="1100"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="1000px" 
      ref="DownloadComp"
    >
      <section slot="pdf-content">
        <span v-html="pdfQuotationContent"></span>
      </section>
    </VueHtml2pdf>
    <!-- Print Quotation HTML (E) -->

    <!--  Show Mail Content (S) -->
    <el-dialog title="Mail Preview" :visible.sync="mailPreviewShow" width="70%">
      <span v-html="this.mailPreviewContent"></span>
      <!-- <span v-if="isMailSupplier" slot="footer" class="dialog-footer">
        <el-button @click="mailPreviewShow = false"> Cancel </el-button>
        <el-button type="warning" @click="handleSendMailToSupplier(mailSupplierContent)">
          {{ this.$i18n.t('src.modules.order.detail.sendMail.btnSendMail') }}
        </el-button>
      </span> -->
    </el-dialog>
    <!--  Show Mail Content (E) -->

    <!--  Edit Product sourcing (S) -->
    <el-dialog
      width="40%"
      :title="$i18n.t('src.modules.quotationDetail.index.modal.editProductSourcing.title')"
      :visible.sync="editProductSourcingDialogVisible"
      :before-close="handleCloseEditProductSourcingForm"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form :model="formEditProductSourcing" ref="formEditProductSourcing" :rules="ruleFormEditProductSourcing" label-width="150px" class="demo-ruleForm">
          <el-form-item :label="$i18n.t('src.components.modal.selectProduct.form.newProduct.name.title')" prop="productName">
            <el-input v-model="formEditProductSourcing.productName" :placeholder="$i18n.t('src.components.modal.selectProduct.form.newProduct.name.placeholder')"></el-input>
          </el-form-item>
          <el-form-item :label="$i18n.t('src.components.modal.selectProduct.form.newProduct.supplierName.title')" prop="supplierName">
            <el-select
              v-model="formEditProductSourcing.supplierId"
              :placeholder="$i18n.t('src.components.modal.selectProduct.placaholder.supplier')"
              clearable
              filterable
            >
              <el-option v-for="item in allSuppliers" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$i18n.t('src.components.modal.selectProduct.form.newProduct.price.title')" prop="unitPrice">
            <el-input v-model.number="formEditProductSourcing.unitPrice" :placeholder="$i18n.t('src.components.modal.selectProduct.form.newProduct.price.placeholder')"></el-input>
          </el-form-item>
          <el-form-item :label="$i18n.t('src.components.modal.selectProduct.form.newProduct.supplierPrice.title')" prop="supplierPrice">
            <el-input v-model.number="formEditProductSourcing.supplierPrice" :placeholder="$i18n.t('src.components.modal.selectProduct.form.newProduct.price.placeholder')"></el-input>
          </el-form-item>
          <el-form-item :label="$i18n.t('src.modules.quotationDetail.index.tax')" prop="tax">
            <el-input 
              v-model.number="formEditProductSourcing.tax" 
              placeholder="Tax">
            </el-input>
          </el-form-item>
          <el-form-item :label="$i18n.t('src.modules.product.index.unit')" prop="unitCode">
            <el-select size="medium" v-model="formEditProductSourcing.unitCode" placeholder="Select Unit" width="100%" filterable>
              <el-option v-for="item in unitOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$i18n.t('src.modules.product.index.description')" prop="description">
            <!-- <ckeditor v-model="formEditProductSourcing.description" class="nat-cke-content" :config="editorConfig"></ckeditor> -->
            <el-input type="textarea" :rows="4" placeholder="Please input" v-model="formEditProductSourcing.description"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="float-right mr-2" type="primary" @click="handleSaveEditProductSourcing">{{ $i18n.t('common.label.save') }}</el-button>
          </el-form-item>
      </el-form>
    </el-dialog>
    <!--  Edit Product sourcing (E) -->

    <!-- Popup Confirm Cancel Quotation (S) -->
    <el-dialog
      v-loading="isLoading"
      :title="$i18n.t('src.modules.quotation.index.dialog.title.dialogConfirmCancelQuotation')"
      :visible.sync="dialogConfirmCancelQuotationVisible"
      width="50%"
      :before-close="emitCloseConfirmCancelQuotationPopup"
      :append-to-body="true"
    >
      <el-row>
        <el-select
          size="medium"
          clearable
          placeholder="Select Reason"
          v-model="formQuotation.quotationCancelReasonCode"
          width="100%"
          filterable
          @change="onchangeQuotationCancelStatus"
        >
          <el-option
            v-for="item in getListQuotationCancelStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-row>
      <el-row v-if="isShowCancelReasonText">
        <span>{{$i18n.t('src.modules.quotation.index.cancelStatus.title')}}</span>
        <el-input
          size="medium"
          type="textarea"
          :rows="3"
          placeholder="Note"
          v-model="formQuotation.cancelReason"
        />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="medium"
          type="success"
          @click="onClickSaveCancelQuotationStatus()"
        >
          OK
        </el-button>
        <el-button @click="emitCloseConfirmCancelQuotationPopup">{{
          $i18n.t('src.components.modal.selectProduct.tableProduct.close')
        }}
        </el-button>
      </span>
    </el-dialog>
    <!-- Popup Confirm Cancel Quotation (E) -->

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Widget from 'components/Widget/Widget';
import SelectProduct from 'components/Modal/SelectProduct';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import vnLocal from 'vn-local';
import VueHtml2pdf from 'vue-html2pdf';
import { 
  QUOTATION_STATUS, 
  QUOTATION_STATUS_COLOR, 
  QUOTATION_DA_XAC_NHAN, 
  TYPE_TEMPLATE_MAIL_CODE, 
  DEFAULT_UNIT_NAME,
  TYPE_TEMPLATE_FILE_QUOTATION_CODE,
  QUOTATION_DA_HUY,
  QUOTATION_CANCEL_REASON_CODE 
} from 'core/constants';
import { validateStringPreventDuplicateWhiteSpace } from 'core/helpers';
export default {
  components: { Widget, SelectProduct, VueHtml2pdf },

  data() {
    return {
      S3_STORE_ENDPOINT: process.env.VUE_APP_S3_STORE_ENDPOINT,
      currentPage: 1,
      pageSize: 10,
      isLoading: true,
      editRow: null,
      quotationDetailOld: {},
      unitPrice: '',
      shippingCost: '',
      quotationDetailEditing: {},
      isInputActive: false,
      selectProductVisible: false,
      isButtonDownloadPDFLoading: false,
      isEnableEditShippingFee: false,
      isShowCancelReasonText: false,
      dialogConfirmCancelQuotationVisible: false,
      pdfQuotationContent: '',
      multipleSelectProduct: [],
      selectedListUser: [],
      searchSupplier: {
        keyword: '' // search input
      },
      dialogCustomerVisible: false,
      mailPreviewShow: false,
      mailPreviewContent: '',
      listAddedMail: [],
      addedMail: '',
      // Send Mail Dialog
      isShowSendMailDialog: false,
      isLoadingSendMail: false,
      props: { multiple: true },
      editorConfig: {
        language: 'en',
        allowedContent: true
      },
      checkedCustomerMail: false,
      editProductSourcingDialogVisible: false,
      formEditProductSourcingOld:{},
      formEditProductSourcing: {
        isEditProductSourcing: true,
        id: '',
        productId: '',
        productName: '',
        supplierId: '',
        unitPrice: '',
        supplierPrice: '',
        unitCode: 'PC',
        description: '',
        quotationId: '',
        quantity: '',
        tax: ''
      },
      lang: '',
      formMail: {
        template: '',
        note: '',
        title: ''
      },
      formMailDefault: {
        template: '',
        note: '',
        title: ''
      },
      quotationInfo: {
        total: 0,
        shippingFee: 0,
        subTotal: 0,
        totalBySupPrice: 0
      },
      ruleFormMail: {
        template: [
          {
            required: true,
            message: 'Please Choose One Mail Template',
            trigger: 'change'
          }
        ]
      },
      ruleFormEditProductSourcing:{},
      customerInfo: {},

      formQuotation: {
        customerId: null,
        bdInChargeIdList: [],
        salesInChargeIdList: [],
        customerAddressId: null,
        note: null,
        personInChargeId: null,
        quotationStatus: null,
        quotationStatusId: null,
        total: null,
        isImportant: null,
        code: null,
        orderId: null,
        quotationCancelReasonCode: null,
        cancelReason: null,
        estimateDeliveryDate: null
      },

      oldQuotationData: {},
      rulesFormQuotation: {},
      rulesSendMailForm: {
        recipientEmail: [
          {
            required: true,
            type: 'email',
            message: 'Please enter the correct email',
            trigger: 'change'
          }
        ],
        recipientName: [
          {
            required: true,
            validator: validateStringPreventDuplicateWhiteSpace,
            message: 'Please type',
            trigger: 'change'
          }
        ],
      }
    };
  },
  computed: {
    ...mapState('quotationDetail', [
      'quotationDetails',
      'quotationDetailAddLocal',
    ]),
    ...mapState('user', ['users', 'usersMap']),
    ...mapState('customerAddress', ['customerAddress', 'customerAddressMap']),
    ...mapState('personInCharge', ['personInChargeArray']),
    ...mapState('quotation', ['quotations']),
    ...mapState('supplier', ['suppliers', 'suppliersMap']),
    ...mapState('auth', ['user']),
    ...mapState('quotationStatus', ['quotationStatusArray', 'quotationStatusMap']),
    ...mapState('mailTemplate', ['mailTemplates']),
    ...mapState('role', ['roles']),
    ...mapState('unit', ['units']),
    ...mapState('common', ['listCommons', 'quoCancelReasonMap']),

    shippingFeeWithComma: {
      get: function () {
        return this.quotationInfo.shippingFee
          .toString()
          .replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      set: function (newValue) {
        this.quotationInfo.shippingFee = newValue !== '' ? newValue.replace(/,/g, '') : '';
      }
    },

    getCancelReason:{
      get: function(){
        const quotationCancelCode = this.formQuotation.quotationCancelReasonCode;
        const quotationCancelReasonText = this.formQuotation.cancelReason && this.formQuotation.cancelReason.trim() || '';

        if(
          QUOTATION_CANCEL_REASON_CODE.OTHER_REASON == quotationCancelCode
          &&
          ( quotationCancelReasonText || quotationCancelReasonText.length > 0 )
        ){
          return quotationCancelReasonText;
        };

        const cancelReasonObj = this.quoCancelReasonMap[quotationCancelCode];
        const { name = '{}' } = cancelReasonObj;
        const nameParse = JSON.parse(name);
        const messageReturn = this.lang === 'vi-VN' && nameParse.vi_VN ? nameParse.vi_VN : nameParse.en_US || 'No Data';

        return messageReturn
      },
      set: function () {
      }
    },

    isShowCancelReason(){

      if(
        this.formQuotation.quotationCancelReasonCode 
      ){
        return true;
      };

      return false;
    },

    getListQuotationCancelStatus(){
      return this.listCommons.map(item => {
        const { name, code } = item;
        const nameParse = JSON.parse(name);
        const label = this.lang === 'vi-VN' && nameParse ? nameParse.vi_VN : nameParse.en_US || 'No Data';

        return {
          value: code,
          label,
          key: code
        };
      });
    },

    unitOptions() {
      return this.units.map((item) => {
        return {
          value: item.code,
          label: item.vi_VN
        };
      });
    },

    quotationStatusList(){
      return this.quotationStatusArray.map(item => {
        return {
          value: item.id,
          label: QUOTATION_STATUS[item.id],
          color: QUOTATION_STATUS_COLOR[item.id]
        };
      });
    },

    // all mail selected
    allMailSelect() {
      const allMails = [...this.selectedListUser, ...this.listAddedMail];
      if (this.checkedCustomerMail && this.customerInfo.email) allMails.push(this.customerInfo.email);
      return allMails;
    },

    roleObj() {
      return this.roles.reduce((acc, ele) => {
        if (ele.id !== 1 && typeof this.groupUserByRoleId[ele.id] !== 'undefined') {
          acc.push({
            value: ele.name,
            label: ele.name,
            children: this.groupUserByRoleId[ele.id]
          });
        }
        return acc;
      }, []);
    },

    // group user by roleId
    groupUserByRoleId() {
      return this.users.reduce((objectsByKeyValue, obj) => {
        const value = obj['roleId'];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat({
          value: obj.email,
          label: obj.email,
          disabled: this.listAddedMail.some(m => m === obj.email)
        });
        return objectsByKeyValue;
      }, {});
    },

    // list mail template
    listMailTemplates() {
      return this.mailTemplates.map(mt => ({
        label: mt.name,
        value: mt.code
      }));
    },

    allowShowButtonShowCustomerInfo(){
      return !!this.customerInfo.id
    },

    isUpdateQuotation() {
      return isEqual(this.formQuotation, this.oldQuotationData);
    },

    isDisabledButtonCreateOrder(){
      return (this.formQuotation.quotationStatusId === QUOTATION_DA_XAC_NHAN && !this.formQuotation.orderId);
    },

    isConvertToOrder(){
      // console.log('isConvertToOrder:', this.formQuotation.orderId && this.formQuotation.orderId >= 0);
      return (this.formQuotation.orderId && this.formQuotation.orderId >= 0);
    },

    displayUnitPriceValue: {
      get: function() {
        if (this.isInputActive) {
          return this.quotationDetailEditing.unitPrice.toString();
        } else {
          return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND'
          }).format(this.quotationDetailEditing.unitPrice);
        }
      },
      set: function(modifiedValue) {
        this.quotationDetailEditing.unitPrice = parseFloat(
          modifiedValue.replace(/,/g, '')
        );
        if (isNaN(this.quotationDetailEditing.unitPrice)) {
          this.quotationDetailEditing.unitPrice = 0;
        }
      }
    },

    displayShippingCostValue: {
      get: function() {
        if (this.isInputActive) {
          return this.quotationDetailEditing.shippingCost.toString();
        } else {
          return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND'
          }).format(this.quotationDetailEditing.shippingCost);
        }
      },
      set: function(modifiedValue) {
        this.quotationDetailEditing.shippingCost = parseFloat(
          modifiedValue.replace(/,/g, '')
        );
        if (isNaN(this.quotationDetailEditing.shippingCost)) {
          this.quotationDetailEditing.shippingCost = 0;
        }
      }
    },

    salesList() {
      return this.users.map(item => {
        return {
          value: item.id,
          label: item.username
        };
      });
    },

    BDList() {
      return this.users.map(item => {
        return {
          value: item.id,
          label: item.username
        };
      });
    },

    personInChargeList() {
      return this.personInChargeArray.map(item => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },

    // list supplier
    allSuppliers() {
      return this.suppliers.map(item => {
        return {
          label: item.name,
          value: item.id
        };
      });
    },

    customerAddressList() {
      return this.customerAddress.map(item => {
        let address = item?.address || '';
        if (item?.district) {
          const district = vnLocal.getWardsByDistrictCode(item.district);

          let districtName = district?.[0]?.districtName || '';
          let provinceName = district?.[0]?.provinceName || '';
          let wardName =
            (item.ward && district?.find(x => x.code === item.ward)?.name) || '';
          address = `${address ? address : ''}${wardName ? ', ' + wardName : ''}${
            districtName ? ', ' + districtName : ''
          }${provinceName ? ', ' + provinceName : ''}`;
        }
        item.deliveryAddress = address || 'No Data';

        return {
          value: item.id,
          label: item.deliveryAddress
        };
      });
    },

    allQuotationDetail() {
      return cloneDeep([...this.quotationDetailAddLocal, ...this.quotationDetails]);
    },

    //list all quotation detail
    pagedTableData() {
      return this.allQuotationDetail
        .slice(
          this.pageSize * this.currentPage - this.pageSize,
          this.pageSize * this.currentPage
        )
        .map(item => {
          const itemReturn = {};
          const {
            id,
            product,
            quotationId,
            productId,
            quantity,
            weight,
            shippingCost,
            total,
            unitPrice,
            note,
            tax

          } = item;

          itemReturn.quotationId = quotationId;
          itemReturn.productId = productId;
          itemReturn.quantity = quantity || 0;
          itemReturn.weight = weight;
          itemReturn.shippingCost = shippingCost || 0;
          itemReturn.total = total || 0;
          itemReturn.unitName = this.lang === 'vi-VN' ? DEFAULT_UNIT_NAME.vi_VN : DEFAULT_UNIT_NAME.en_US;
          itemReturn.note = note;

          // data from database
          if (id && product) {
            const { images, name, supplierId, supplierPrice , isSourcing, description , unitCode, unit = {} } = product;
            itemReturn.images = images || 'No Data';
            itemReturn.productName = name || 'No Data';
            itemReturn.supplierName = (this.suppliersMap[supplierId] && this.suppliersMap[supplierId].name) || 'No Data';
            itemReturn.supplierId = supplierId || 0;
            itemReturn.id = id;
            itemReturn.supplierPrice = supplierPrice || 0;
            itemReturn.description = description || '' ;
            itemReturn.unitCode = unitCode || '' ;
            itemReturn.unitPrice = unitPrice || 0;

            const unitNameParse = unit?.name ? JSON.parse(unit.name) : null;
            if(unitNameParse && unitNameParse.en_US && unitNameParse.vi_VN){
              itemReturn.unitName = this.lang === 'vi-VN' ? unitNameParse.vi_VN : unitNameParse.en_US;
            };
            itemReturn.isSourcing = isSourcing;
            itemReturn.vatPercent = tax;
            
          }else{
            // data from add local
            const { images, productName, supplierId, supplierPrice , isSourcing, description, unitCode, unit = {}, vatPercent } = item;
            itemReturn.images = images || 'No Data';
            itemReturn.productName = productName || 'No Data';
            itemReturn.supplierName = (this.suppliersMap[supplierId] && this.suppliersMap[supplierId].name) || 'No Data';
            itemReturn.supplierId = supplierId || 0;
            itemReturn.supplierPrice = supplierPrice || 0;
            itemReturn.isSourcing = isSourcing || '';
            itemReturn.description = description || '';
            itemReturn.unitCode = unitCode || '';
            itemReturn.unitPrice = unitPrice || 0;
            itemReturn.vatPercent = vatPercent;

            const unitNameParse = unit?.name ? JSON.parse(unit.name) : null;
            if(unitNameParse && unitNameParse.en_US && unitNameParse.vi_VN){
              itemReturn.unitName = this.lang === 'vi-VN' ? unitNameParse.vi_VN : unitNameParse.en_US;
            };
          }
          return itemReturn;
        });
    },

    customersList() {
      const customer = [this.customerInfo];
      return customer.map(item => {
        return {
          value: item.id,
          label: item.customerName
        };
      });
    },

    totalQuotationDetailCnt(){
      return this.allQuotationDetail.length || 0;
    }
  },
  watch: {
    quotationDetailEditing: {
      handler(newValue) {
        const { quantity, unitPrice, shippingCost } = newValue;
        let total = +quantity * +unitPrice;
        const vatP = newValue?.vatPercent || 10;
        const taxFee = total * ( +vatP / 100 );
        newValue.total = total + taxFee + +shippingCost;
      },
      deep: true
    },

    quotations: {
      handler() {
        this.signQuotationData()
      },
      deep: true
    },

    quotationDetails: {
      handler() {
        this.signQuotationsDetailData()
      },
      deep: true
    }
  },

  async created() {
    // promise
    const promise = [
      this.getAllUsers(),
      this.getAllQuotationStatus(),
      this.getAllRoles({}),
      this.getAllMailTemplates({ comCode: TYPE_TEMPLATE_MAIL_CODE.QUO_TMPLT_ML }),
      this.getAllCommons({ comCode : 'QTTN_CC_STT_RS' })
    ];
    // catch getMe
    if (Object.keys(this.user).length === 0) {
      await this.getMe();
    }
    // catch supplier
    // if (this.user && this.user.supplierId > 0) {
    //   this.isSupplier = true;
    //   promise.push(this.getAllQuotations({ id: this.$route.params.id, supplierId: this.user.supplierId }))
    // } else {
    promise.push(this.getAllSupplier(this.searchSupplier));
    promise.push(this.getAllQuotations({ id: this.$route.params.id }));
    // }
    await Promise.all(promise).then(async () => {

      const {id, customerName, code: customerCode, avatar, email, phone, taxCode } = this.quotations[0]?.customer;
      this.customerInfo = { id, customerName, code: customerCode, avatar, email, phone, taxCode };
      this.oldQuotationData = cloneDeep(this.formQuotation);
      await Promise.all([
        this.getAllCustomerAddress({ customerId: this.formQuotation.customerId || '' }),
        this.getAllPersonInCharge({ customerId: this.formQuotation.customerId || '' })
      ]).then(() => {
        this.isLoading = false;
      });
    });

    this.lang = localStorage.getItem('user-language');
  },
  methods: {
    ...mapActions('quotationDetail', [
      'getQuotationDetailByQuotationId',
      'addQuotationDetailLocal',
      'saveNewQuotationDetails',
      'saveEditQuotationDetail',
      'updateQuotationDetailLocal',
      'deleteQuotationDetail',
      'createOrderFromQuotation',
      'exportQuotationPDF',
      'previewMail',
      'sendMail'
    ]),
    ...mapActions('customerAddress', ['getAllCustomerAddress']),
    ...mapActions('personInCharge', ['getAllPersonInCharge']),
    ...mapActions('user', ['getAllUsers']),
    ...mapActions('quotation', ['getAllQuotations', 'saveEditQuotation', 'changeQuotationStatus', 'recalculateQuotation']),
    ...mapActions('supplier', ['getAllSupplier']),
    ...mapActions('auth', ['getMe']),
    ...mapActions('quotationStatus', ['getAllQuotationStatus']),
    ...mapActions('role', ['getAllRoles']),
    ...mapActions('mailTemplate', ['getAllMailTemplates']),
    ...mapActions('unit', ['getAllUnits']),
    ...mapActions('product', ['saveEditProductIsSourcing']),
    ...mapActions('common', ['getAllCommons']),
    

    signQuotationsDetailData(){
      const quotationDetailArr = this.quotationDetails || [];
      
      let totalBySupPrice = 0;
      quotationDetailArr.forEach((item)=>{
        const { product, quantity, tax = 10 } = item;
        const { supplierPrice } = product || {};

        if(quantity >= 0 && supplierPrice >= 0){
          const total = +quantity * +supplierPrice;
          totalBySupPrice += total + (total * (tax / 100));
        };
      });
      this.quotationInfo.totalBySupPrice = totalBySupPrice;
    },

    signQuotationData(){
      const {
        customerId,
        bdInChargeIdList,
        salesInChargeIdList,
        customerAddressId,
        note,
        personInChargeId,
        quotationStatus,
        quotationStatusId,
        total,
        isImportant,
        code,
        orderId,
        shippingFee,
        quotationCancelReasonCode,
        cancelReason,
        estimateDeliveryDate
      } = this.quotations[0];

      const convertBDListToArray = 
        bdInChargeIdList && bdInChargeIdList.split('|').map(Number).filter(id => id > 0) || [];
      const convertSalesListToArray = 
        salesInChargeIdList && salesInChargeIdList.split('|').map(Number).filter(id => id > 0) || [];

      this.formQuotation = {
        customerId,
        bdInChargeIdList : convertBDListToArray,
        salesInChargeIdList : convertSalesListToArray,
        customerAddressId : customerAddressId || null,
        note,
        personInChargeId : personInChargeId || null,
        quotationStatus,
        quotationStatusId,
        total,
        isImportant,
        code,
        orderId,
        quotationCancelReasonCode,
        cancelReason,
        estimateDeliveryDate
      };

      const subTotalQuotation = +total - (+shippingFee || 0);

      this.quotationInfo.subTotal = subTotalQuotation;
      this.quotationInfo.shippingFee = shippingFee;
      this.quotationInfo.total = total;
    },

    onClickEditSourcingProduct(index){
      const { 
        id, 
        productId, 
        productName, 
        supplierId, 
        supplierPrice, 
        unitPrice, 
        description, 
        unitCode = 'PC',
        quantity,
        vatPercent
      } = index.row;

      this.formEditProductSourcing = {
        isEditProductSourcing: true,
        id,
        productId,
        productName,
        supplierId,
        unitPrice,
        supplierPrice,
        unitCode,
        description,
        quantity,
        quotationId: this.$route.params.id,
        tax: vatPercent
      };

      this.formEditProductSourcingOld = cloneDeep(this.formEditProductSourcing);

      this.editProductSourcingDialogVisible = true;
    },

    handleCloseEditProductSourcingForm(done){
      if(!isEqual( this.formEditProductSourcing, this.formEditProductSourcingOld)){
        this.$confirm(this.$i18n.t('common.confirm.close.form.out'))
          .then(() => {
            this.formEditProductSourcing = {};
            this.formEditProductSourcingOld = {};
            done();
          })
          .catch(() => {});
      }else{
        this.formEditProductSourcing = {};
        this.formEditProductSourcingOld = {};
        done()
        // this.editProductSourcingDialogVisible = false;
      };
    },

    // enter to add text input
    handleEnterKeyUp() {
      this.$refs.addMail.$el.click();
    },

    onShowSendMailDialog() {
      this.isShowSendMailDialog = true;
    },

    handleChangeListMail(value) {
      this.selectedListUser = value.map(v => v[1]);
    },

    clearFormMail() {
      this.formMail = cloneDeep(this.formMailDefault);
      this.$refs['formMail'].resetFields(); //Remove the verification result and reset the field value
      this.$refs['formMail'].clearValidate(['template']);
      this.selectedListUser = [];
      this.listAddedMail = [];
      this.addedMail = [];
    },

    handleAddMail() {
      const regex = /^(([^<>()[\]\\.,;:\s@|"]+(\.[^<>()[\]\\.,;:\s@|"]+)*)|(|".+|"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      // check email is empty or not
      if (this.addedMail === '') {
        this.$message({
          showClose: true,
          message: this.$i18n.t('src.modules.order.detail.sendMail.validate.mail.error.empty'),
          type: 'error'
        });

        // check mail input is correct format or not
      } else if (!regex.test(this.addedMail)) {
        this.$message({
          showClose: true,
          message: this.$i18n.t('src.modules.order.detail.sendMail.validate.mail.error.format'),
          type: 'error'
        });
        this.addedMail = '';

        // check email already exists or not
      } else if (this.allMailSelect.some(obj => (Array.isArray(obj) ? obj[1] === this.addedMail : obj === this.addedMail))) {
        this.$message({
          showClose: true,
          message: this.$i18n.t('src.modules.order.detail.sendMail.validate.mail.error.exist'),
          type: 'error'
        });
        this.addedMail = '';
      } else {
        this.listAddedMail.push(this.addedMail);
        this.$message({
          message: this.$i18n.t('src.modules.order.detail.sendMail.addMail.success'),
          type: 'success'
        });
        this.addedMail = '';
      }
    },

    async handleSendMail() {
      this.$refs['formMail'].validate(async valid => {
        if (valid) {
          const listEmail = this.allMailSelect;
          this.isLoadingSendMail = true;
          await this.sendMail({
            listEmail,
            mailTemplateCode: this.formMail.template,
            note: this.formMail.note,
            title: this.formMail.title,
            mailTemplateComCode: TYPE_TEMPLATE_MAIL_CODE.QUO_TMPLT_ML,
            quotationId: this.$route.params.id
          }).then(result => {
            this.isLoadingSendMail = false;
            if (result) {
              this.$notify({
                title: this.$i18n.t('common.notify.title.success'),
                message: this.$i18n.t('src.modules.order.detail.sendMail.success'),
                type: 'success'
              });
            } else {
              this.$notify({
                title: this.$i18n.t('common.notify.title.error'),
                message: this.$i18n.t('src.modules.order.detail.sendMail.error', {
                  message: result.message
                }),
                type: 'error'
              });
            }
          });
        }
      });
    },

    async handlePreviewMail() {
      this.$refs['formMail'].validate(async valid => {
        if (valid) {
          const listEmail = this.allMailSelect;
    
          const { message } = await this.previewMail({
            listEmail,
            mailTemplateCode: this.formMail.template,
            note: this.formMail.note,
            title: this.formMail.title,
            mailTemplateComCode: TYPE_TEMPLATE_MAIL_CODE.QUO_TMPLT_ML,
            quotationId: this.$route.params.id
          });

          this.mailPreviewContent = message;
          this.mailPreviewShow = true;
        }
      });
    },

    handleCloseSendMailDialog(done) {
      var message = null;
      if (!isEqual(this.formMail, this.formMailDefault) || this.selectedListUser.length > 0 || this.listAddedMail.length > 0) {
        message = this.$i18n.t('common.confirm.close.form.out', { name: 'orders' });
        this.$confirm(message)
          .then(() => {
            this.clearFormMail();
            //this.isShowSendMailDialog = false;
            done();
          })
          .catch(() => {});
      } else {
        this.isShowSendMailDialog = false;
      }
    },

    //Paging
    changePage(val) {
      this.currentPage = val;
    },

    async printDownload() {
      this.isButtonDownloadPDFLoading = true;
      await this.exportQuotationPDF({
        quotationId: this.$route.params.id,
        mailTemplateCode: TYPE_TEMPLATE_FILE_QUOTATION_CODE.QUO_PDF_TEMP
      }).then(res => {
        const { status, templatePDF, message } = res; 
        if(!status){
          this.$notify({
            title: this.$i18n.t('common.notify.title.error'),
            message: message,
            type: 'error'
          });
        }else{
          this.pdfQuotationContent = templatePDF;
          this.$refs.DownloadComp.generatePdf();
        };
        this.isButtonDownloadPDFLoading = false;
      });
    },

    onchangeQuotationCancelStatus(code){
      //handle when change quotation status to cancel
      if(QUOTATION_CANCEL_REASON_CODE.OTHER_REASON == code){
        this.isShowCancelReasonText = true;
        return
      };

      this.isShowCancelReasonText = false;
      return

    },

    async onClickSaveCancelQuotationStatus(){
      const quotationCancelCode = this.formQuotation.quotationCancelReasonCode;
      const quotationCancelReasonText = this.formQuotation.cancelReason && this.formQuotation.cancelReason.trim() || '';

      //warning when not add cancel reason when save
      if(
        QUOTATION_CANCEL_REASON_CODE.OTHER_REASON == quotationCancelCode &&
        ( !quotationCancelReasonText || quotationCancelReasonText.length <= 0 )
      ){
        this.$notify({
          title: 'Warning',
          message: this.$i18n.t('src.modules.quotation.index.notAddCancelReasonText'),
          type: 'warning'
        });
        return
      };

      const result = await this.changeQuotationStatus({
        quotationStatusId: QUOTATION_DA_HUY,
        id: this.$route.params.id,
        cancelReason: quotationCancelReasonText,
        quotationCancelReasonCode: quotationCancelCode
      });
      const { status } = result;
      if (status) {
        this.$notify({
          title: 'Success',
          message: 'Change Quotation Status Success',
          type: 'success'
        });

        this.isShowCancelReasonText = false;
        this.dialogConfirmCancelQuotationVisible = false;

      } else {
        const { messageCode, messageContent } = result;

        const messageTranslate = 
          this.$i18n.te(`src.modules.quotation.index.changeStatus.error.${messageCode}`) ? 
          this.$i18n.t(`src.modules.quotation.index.changeStatus.error.${messageCode}`) :
          messageContent;
          
        this.$notify({
          title: 'Error',
          message: messageTranslate,
          type: 'error'
        });
      };
    },

    emitCloseConfirmCancelQuotationPopup(){
      this.dialogConfirmCancelQuotationVisible = false;
      this.formQuotation.quotationCancelReasonCode = null;
      this.formQuotation.cancelReason = '';
      this.isShowCancelReasonText = false;
    },

    handleChangeQuotationStatus($event){

      //handle when change quotation status to cancel
      if($event === QUOTATION_DA_HUY){
        this.dialogConfirmCancelQuotationVisible = true;

        return;
      };  

      this.$confirm('Do you want change ?')
        .then(async () => {
          try {
            const result = await this.changeQuotationStatus({
              quotationStatusId: $event,
              id: this.$route.params.id
            });
            const { status } = result;
            if (status) {
              this.$notify({
                title: 'Success',
                message: 'Change Quotation Status Success',
                type: 'success'
              });
            } else {
              const { messageCode, messageContent } = result;

              const messageTranslate = 
                this.$i18n.te(`src.modules.quotation.index.changeStatus.error.${messageCode}`) ? 
                this.$i18n.t(`src.modules.quotation.index.changeStatus.error.${messageCode}`) :
                messageContent;
                
              this.$notify({
                title: 'Error',
                message: messageTranslate,
                type: 'error'
              });
            }
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.edit.fail', {
                name: 'row'
              }),
              type: 'error'
            });
          }
        })
        .catch(error => {
          console.log('error: ', error);
        });
    },

    getCustomerAvatar(listAvatar) {
      const images = listAvatar ? listAvatar.split('|') : [];
      return images[0];
    },

    getProductImage(listImage){
      const images = listImage ? listImage.split('|') : [];
      return images[0];
    },

    onClickShowCustomerInfo(){
      this.dialogCustomerVisible = true;
    },

    // Add Product Selected To Quotation Detail
    async handleAddMultiRow() {
      let existProduct = [];
      let newQuotationDetails = [];
      this.multipleSelectProduct.map(addData => {
        if (
          this.quotationDetailAddLocal.some(item => item.productId === addData.id) ||
          this.quotationDetails.some(item => item.productId === addData.id)
        ) {
          existProduct.push(addData.name);
        } else {
          const quotationId = this.$route.params.id;
          const totalWithTax = addData.price + (addData.price * 0.1);
          newQuotationDetails.push({
            quotationId,
            productId: addData.id,
            quantity: 1,
            weight: 0,
            shippingCost: 0,
            unitPrice: addData.price,
            total: totalWithTax,
            images: addData.images,
            productName: addData.name,
            supplierName: addData.supplierName,
            supplierId: addData.supplierId,
            supplierPrice: addData.supplierPrice,
            unit: addData.unit,
            isSourcing: addData.isSourcing,
            description: addData.description,
            unitCode: addData.unitCode,
            vatPercent: addData.taxRate
          });
        }
      });
      if (existProduct.length > 0) {
        this.$notify({
          title: this.$i18n.t('common.notify.title.error'),
          message: this.$i18n.t('src.modules.quotationDetail.index.notify.message.error.addDuplicate', {
            message: `${existProduct.join(',') || ''}`
          }),
          type: 'error'
        });
      }
      if (newQuotationDetails.length > 0) {
        await this.addQuotationDetailLocal(newQuotationDetails);
        this.$notify({
          title: this.$i18n.t('common.notify.title.success'),
          message: this.$i18n.t('common.notify.message.success.add', {
            item: 'products'
          }),
          type: 'success'
        });
      };

      this.multipleSelectProduct = [];
    },

    async handleRecalculateQuotation(){
      this.$confirm(this.$i18n.t('src.modules.quotationDetail.index.confirm.recalculateQuotation'))
        .then(async () => {
          try {
            const result = await this.recalculateQuotation({
              quotationId: this.$route.params.id
            });
            if (result) {
              this.$notify({
                title: 'Success',
                message: 'Update Quotation Value Success',
                type: 'success'
              });
            } else {
              this.$notify({
                title: 'Error',
                message: 'Update Quotation Value Fail',
                type: 'error'
              });
            }
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.edit.fail', {
                name: 'row'
              }),
              type: 'error'
            });
          }
        })
        .catch(error => {
          console.log('error: ', error);
        });
    },

    async handleSaveEditProductSourcing(){
      
       if (!isEqual(this.formEditProductSourcingOld, this.formEditProductSourcing)) {
         try {
          // const oldData = this.formEditProductSourcingOld;
          const newData = this.formEditProductSourcing;

          const result = await this.saveEditQuotationDetail({
            quotationId: this.$route.params.id,
            newData
          });
          if (result) {
            this.formEditProductSourcingOld = {};
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message:  this.$i18n.t('common.notify.message.save.success'),
              type: 'success'
            });
            this.editProductSourcingDialogVisible = false;
          }else{
            this.$notify({
              title:  this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.edit.fail'),
              type: 'error'
            });
          };

         } catch (error) {
           console.log('error handleSaveEditProductSourcing: ', error);
          this.$notify({
            title:  this.$i18n.t('common.notify.title.error'),
            message: error.message,
            type: 'error'
          });
         }

       }else{
        this.formEditProductSourcingOld = {};
        this.editProductSourcingDialogVisible = false;
       }
    },

    async handleSaveQuotationDetail() {
      if (this.quotationDetailAddLocal.length > 0) {
        const data = await this.saveNewQuotationDetails({
          quotationDetailAddLocal: this.quotationDetailAddLocal,
          quotationId: this.$route.params.id
        });
        if (data == true) {
          this.$notify({
            title: this.$i18n.t('common.notify.title.success'),
            message: this.$i18n.t('common.notify.message.success.add', {
              item: 'Order Details'
            }),
            type: 'success'
          });
        } else {
          this.$notify({
            title: this.$i18n.t('common.notify.title.error'),
            message: this.$i18n.t('common.notify.message.save.fail', {
              name: 'Order Detail'
            }),
            type: 'error'
          });
        }
      }
    },

    // Add One Product to Order Detail
    async onClickAddRow(index) {
      const addData = index.row;
      if (
        this.quotationDetailAddLocal.some(item => item.productId === addData.id) ||
        this.quotationDetails.some(item => item.productId === addData.id)
      ) {
        this.$notify({
          title: this.$i18n.t('common.notify.title.error'),
          message: this.$i18n.t('src.modules.quotationDetail.index.notify.message.error.addDuplicate', {
            message: `${addData.name || ''}`
          }),
          type: 'error'
        });
      } else {
        const quotationId = this.$route.params.id;
        const newQuotationDetail = {
          quotationId,
          productId: addData.id,
          quantity: 1,
          weight: 0,
          shippingCost: 0,
          unitPrice: addData.price,
          total: addData.price,
          images: addData.images,
          productName: addData.name,
          supplierName: addData.supplierName,
          supplierId: addData.supplierId,
          vatPercent: addData.taxRate
        };
        await this.addQuotationDetailLocal([newQuotationDetail]);
        this.$notify({
          title: this.$i18n.t('common.notify.title.success'),
          message: this.$i18n.t('common.notify.message.success.add', {
            item: `product ${addData.name}`
          }),
          type: 'success'
        });
      }
    },

    // edit order detail
    enableEditing(id) {

      //avoid edit quotation detail when converted to order
      if(this.isConvertToOrder){
        this.$notify({
          title: 'Warning',
          message: this.$i18n.t('src.modules.quotationDetail.index.edit.avoidEditQuotationDetailWhenConvertedOrder'),
          type: 'warning'
        });
        return true;
      };

      if( this.editRow === null || isEqual( this.quotationDetailOld, this.quotationDetailEditing) ){
        this.editRow = this.editRow !== id ? id : null;
        this.quotationDetailOld = cloneDeep(this.pagedTableData[id]);
        this.quotationDetailEditing = this.pagedTableData[id];
      }else{
        this.$notify({
          title: 'Warning',
          message: this.$i18n.t('src.modules.quotationDetail.index.notify.warning.notSaveBeforeLeave'),
          type: 'warning'
        });
      }
    },

    onCloseEditing(index) {
      this.pagedTableData[index] = cloneDeep(this.quotationDetailOld);
      console.log('this.pagedTableData[index]: ', this.pagedTableData[index]);
      this.editRow = null;
      this.quotationDetailEditing = {};
    },

    enableEditShippingFee() {
      this.isEnableEditShippingFee = true;
    },

    async closeEditShippingFee() {
      await this.handleEditShippingFee();
      this.isEnableEditShippingFee = false;
    },

    handleEditShippingFee(){
      const quotationId = this.$route.params.id;
      this.$confirm(this.$i18n.t('common.confirm.save.editForm'))
        .then(async () => {
          try {
            const result = await this.saveEditQuotation({
              id: quotationId,
              shippingFee: this.quotationInfo.shippingFee
            });
            if (result) {
              this.$notify({
                title: this.$i18n.t('common.notify.title.success'),
                message: this.$i18n.t('common.notify.title.success'),
                type: 'success'
              });
            } else {
              this.$notify({
                title: this.$i18n.t('common.notify.title.error'),
                message: this.$i18n.t('common.notify.message.edit.fail'),
                type: 'error'
              });
            }
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.edit.fail'),
              type: 'error'
            });
          }
        })
        .catch(error => {
          console.log('error: ', error);
        });
    },

    handleEnterShippingFeeKeyUp() {
      this.$refs.btnSaveShippingFee.click();
    },

    async onSaveEditQuotationDetail(index) {
      if (!isEqual(this.quotationDetailOld, this.pagedTableData[index])) {
        
        const {
          id,
          quantity,
          unitPrice,
          shippingCost,
          total,
          quotationId,
          productId,
          isSourcing,
          note,
          vatPercent

        } = this.pagedTableData[index];

        if (
          isNaN(quantity) ||
          isNaN(unitPrice) ||
          isNaN(shippingCost) ||
          !productId
        ) {
          this.$notify({
            title: 'Input invalid',
            message: 'Check input again please',
            type: 'error'
          });

          return false;
        }

        if (id) {
          // when update data in database
          this.$confirm(
            this.$i18n.t('common.notify.edit.comfirm', { name: 'quotation detail' })
          )
            .then(async () => {
              const objectQT = {
                id,
                productId,
                quantity,
                quotationId,
                shippingCost,
                total,
                unitPrice,
                note,
                tax: vatPercent
              };

              // edit in table product sourcing       
              if(isSourcing){
                objectQT.isEditProductSourcing = true
              };

              const result = await this.saveEditQuotationDetail({
                quotationId: this.$route.params.id,
                newData: objectQT
              });

              if (result) {
                this.quotationDetailOld = {};
                this.editRow = null;
                this.quotationDetailEditing = {};
                this.$notify({
                  title: this.$i18n.t('common.notify.title.success'),
                  message: this.$i18n.t('common.notify.message.success.edit', {
                    name: 'order detail'
                  }),
                  type: 'success'
                });
              } else {
                this.$notify({
                  title: this.$i18n.t('common.notify.title.error'),
                  message: this.$i18n.t('common.notify.message.error.edit', {
                    name: 'order detail',
                    message: result.message
                  }),
                  type: 'error'
                });
              }
            })
            .catch(error => {
              console.log('error: ', error);
              return false;
            });
        } else {
          //edit local quotation detail
          const oldData = this.quotationDetailOld;
          const newData = this.pagedTableData[index];

          await this.updateQuotationDetailLocal({
            oldData,
            newData
          });

          this.quotationDetailOld = {};
          this.editRow = null;
          this.quotationDetailEditing = {};

          this.$notify({
            title: this.$i18n.t('common.notify.title.success'),
            message: this.$i18n.t('common.notify.message.success.edit', {
              name: 'order detail'
            }),
            type: 'success'
          });
        }
      }else{
        this.quotationDetailEditing = {};
        this.quotationDetailOld = {};
        this.editRow = null;
      }
    },

    async onClickRemove(index) {

      //avoid edit quotation detail when converted to order
      if(this.isConvertToOrder){
        this.$notify({
          title: 'Warning',
          message: this.$i18n.t('src.modules.quotationDetail.index.remove.avoidRemoveQuotationDetailWhenConvertedOrder'),
          type: 'warning'
        });
        return true;
      };

      this.$confirm(
        this.$i18n.t('src.modules.quotationDetail.index.remove.comfirm', {
          productName: index.row.productName ? index.row.productName : ''
        })
      )
        .then(async () => {
          const result = await this.deleteQuotationDetail({
            deleteItem: index.row,
          });
          if (result) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('src.modules.quotationDetail.remove.success', {
                productName: index.row.productName ? index.row.productName : ''
              }),
              type: 'success'
            });
          } else {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('src.modules.quotationDetail.remove.error', {
                productName: index.row.productName ? index.row.productName : '',
              }),
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },

    getNameQuotationStatus(statusId) {
      return QUOTATION_STATUS[statusId];
    },

    getTypeColorQuotationStatus(statusId) {
      return QUOTATION_STATUS_COLOR[statusId];
    },

    onClickResetQuotationForm() {
      this.formQuotation = cloneDeep(this.oldQuotationData);
    },

    onClickUpdateQuotation() {
      const quotationId = this.$route.params.id;
      this.$confirm('Do you want change ?')
        .then(async () => {
          try {
            const result = await this.saveEditQuotation({
              id: quotationId,
              ...this.formQuotation
            });
            if (result) {
              this.$notify({
                title: 'Success',
                message: 'Update Quotation Success',
                type: 'success'
              });
            } else {
              this.$notify({
                title: 'Error',
                message: `Can not update quotation, Error: ${result.message}`,
                type: 'error'
              });
            }
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.edit.fail', {
                name: 'row'
              }),
              type: 'error'
            });
          }
        })
        .catch(error => {
          console.log('error: ', error);
        });
    },

    onClickCreateOrderFromQuotation(){
      const quotationId = this.$route.params.id;
      this.$confirm('Do you want create order from this quotation ?')
        .then(async () => {
          try {
            const result = await this.createOrderFromQuotation({
              quotationId,
            });
            if (result) {
              this.$notify({
                title: 'Success',
                message: 'Create Order Success',
                type: 'success'
              });
            } else {
              this.$notify({
                title: 'Error',
                message: 'Can not create order',
                type: 'error'
              });
            }
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.edit.fail', {
                name: 'row'
              }),
              type: 'error'
            });
          }
        })
        .catch(error => {
          console.log('error: ', error);
        });
    },

    handleCloseAddProduct() {
      this.selectProductVisible = false;
    },

    handleSelectionChange(val) {
      this.multipleSelectProduct = val;
    }
  },

  beforeRouteLeave (to, from , next) {
    if(this.quotationDetailAddLocal.length > 0){
      this.$confirm(this.$i18n.t('src.modules.quotationDetail.index.confirm.leaveWhenNotSaveNewData'))
        .then(async () => {
          next();
        })
        .catch(error => {
          next(false)
          console.log('error: ', error);
        });
    }else{
      next()
    }
  }
};
</script>

<style>
.row-quotation-status > div {
  margin: 0px 10px;
}
.el-input-group__append {
  border: none !important;
}
</style>
