import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  customerInvoiceAddressArr: [],
};

import cloneDeep from 'lodash/cloneDeep';
// import { isEqual } from 'lodash';

const mutations = {
  [Type.SET_ALL_CUSTOMERS_INVOICE_ADDRESS](state, value) {
    console.log('value: ', value);
    state.customerInvoiceAddressArr = value;
  },

  [Type.SET_ADD_CUSTOMERS_INVOICE_ADDRESS](state, value) {
    const oldNews = state.customerInvoiceAddressArr;
    // add new data
    state.customerInvoiceAddressArr = [...cloneDeep(value), ...oldNews];
  },

  [Type.SET_DELETE_CUSTOMERS_INVOICE_ADDRESS](state, value){
    const oldData = cloneDeep(state.customerInvoiceAddressArr);
    value.map(item => {
      const { id } = item;
      const objIndex = oldData.findIndex(obj => obj.id === id);
      oldData.splice(objIndex, 1);
    });
    state.customerInvoiceAddressArr = oldData.slice(0);
  },
  
  [Type.SET_CUSTOMERS_INVOICE_ADDRESS_UPDATE](state, value) {
    const oldCustomerInvoiceAddress = state.customerInvoiceAddressArr;
    const { id } = value;
    const objIndex = state.customerInvoiceAddressArr.findIndex(obj => obj.id === id);
    oldCustomerInvoiceAddress[objIndex] = value;
    state.customerInvoiceAddressArr = oldCustomerInvoiceAddress.slice(0);
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
