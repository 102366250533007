<template>
  <div class="tables-basic" v-can="'customer-address.read'">
    <b-row>
      <b-col>
        <Widget style="margin-bottom: 0px">
          <!-- Button Action (S) -->
          <el-row type="flex" justify="end">
            <el-col>
              <div class="grid-content">
                <el-row type="flex" justify="end">
                  <el-button
                    v-can="'customer-address.add'"
                    size="medium"
                    type="primary"
                    @click="onClickShowAddPopup()"
                    ><i class="el-icon-circle-plus-outline"></i
                    >{{ $t('src.modules.news.index.btnAdd') }}</el-button
                  >
                  <el-button
                    v-can="'customer-address.delete'"
                    size="medium"
                    type="danger"
                    @click="onClickDeleteData()"
                    :disabled="isDisabledButtonDelete"
                    ><i class="el-icon-delete"></i
                    >{{ $t('src.modules.news.index.btnDelete') }}</el-button
                  >
                </el-row>
              </div>
            </el-col>
          </el-row>
          <!-- Button Action (E) -->

          <!-- Table Customers Data (S) -->
          <el-row>
            <el-table
              v-loading="isLoading"
              ref="newsTable"
              :data="pagedTableData"
              height="auto"
              :default-sort="{ prop: 'updatedAt', order: 'descending' }"
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <!-- Check box (S) -->
              <el-table-column type="selection" width="50"></el-table-column>
              <!-- Check box (E) -->

              <!-- Id (S) -->
              <!-- <el-table-column prop="id" label="#" width="50"></el-table-column> -->
              <!-- Id (E) -->

              <!-- customerName (S) -->
              <el-table-column
                prop="customerName"
                :label="$i18n.t('src.modules.customerAddress.index.customerName')"
                width="150"
                sortable
                show-overflow-tooltip
              >
              </el-table-column>
              <!-- customerName (E)-->

              <!-- companyName (S) -->
              <el-table-column
                prop="companyName"
                :label="$i18n.t('src.modules.customerAddress.index.companyName')"
                width="200"
                sortable
                show-overflow-tooltip
              >
              </el-table-column>
              <!-- companyName (E)-->

              <!-- receiverName (S) -->
              <el-table-column
                prop="receiverName"
                :label="$i18n.t('src.modules.customerAddress.index.receiverName')"
                width="200"
                sortable
                show-overflow-tooltip
              >
              </el-table-column>
              <!-- receiverName (E)-->

              <!-- email (S) -->
              <el-table-column
                prop="email"
                :label="$i18n.t('src.modules.customer.index.email')"
                width="220"
                sortable
                show-overflow-tooltip
              >
              </el-table-column>
              <!-- email (E)-->

              <!-- phone (S) -->
              <el-table-column
                prop="phone"
                :label="$i18n.t('src.modules.customerAddress.index.phone')"
                width="220"
                sortable
                show-overflow-tooltip
              >
              </el-table-column>
              <!-- phone (E)-->

              <!-- address (S) -->
              <el-table-column
                prop="address"
                :label="$i18n.t('src.modules.customerAddress.index.address')"
                width="150"
                sortable
                show-overflow-tooltip
              >
              </el-table-column>
              <!-- address (E)-->

              <!-- ward (S) -->
              <el-table-column
                prop="ward"
                :label="$i18n.t('src.modules.customerAddress.index.ward')"
                width="180"
                sortable
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.wardName }}
                </template>
              </el-table-column>
              <!-- ward (E)-->

              <!-- district (S) -->
              <el-table-column
                prop="district"
                :label="$i18n.t('src.modules.customerAddress.index.district')"
                width="150"
                sortable
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.districtName }}
                </template>
              </el-table-column>
              <!-- district (E) -->

              <!-- province (S) -->
              <el-table-column
                prop="province"
                :label="$i18n.t('src.modules.customerAddress.index.province')"
                width="150"
                sortable
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.provinceName }}
                </template>
              </el-table-column>
              <!-- province (E)-->

              <!-- Status (S) -->
              <el-table-column
                prop="isDefault"
                :label="$i18n.t('src.modules.customerAddress.index.isDefault')"
                width="110"
              >
                <template slot-scope="scope">
                  <el-tag
                    :key="scope.row.isDefault === true ? 'ACTIVE' : ''"
                    :type="scope.row.isDefault === true ? 'danger' : 'info'"
                    effect="dark"
                  >
                    {{
                      scope.row.isDefault === true
                        ? $i18n.t('src.modules.customerAddress.index.active')
                        : $i18n.t('src.modules.customerAddress.index.inActive')
                    }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- Status (E) -->

              <!-- Created At (S) -->
              <el-table-column
                prop="createdAt"
                :label="$i18n.t('src.modules.customer.index.createdAt')"
                width="130"
                sortable
              >
                <template slot-scope="scope">
                  {{ scope.row.createdAt | formattedDate }}
                </template>
              </el-table-column>
              <!-- Created At (E) -->

              <!-- Actions (S) -->
              <el-table-column
                :label="$i18n.t('src.modules.customer.index.table.col.action')"
                width="155"
                fixed="right"
              >
                <template slot-scope="scope">
                    <el-button
                      v-can="'customer-address.edit'"
                      type="primary"
                      icon="el-icon-edit"
                      circle
                      size="mini"
                      @click="onClickEditAddress(scope)"
                      class="btn-blue-sm"
                    >
                      {{ $i18n.t('src.modules.quotation.index.button.edit') }}
                    </el-button>

                    <el-button
                      v-can="'customer-address.delete'"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      size="mini"
                      @click="onClickRemove(scope)"
                      class="btn-red-sm"
                    >
                      {{ $i18n.t('src.modules.quotation.index.button.delete') }}
                    </el-button>
                </template>
              </el-table-column>
              <!-- Actions (E) -->
            </el-table>
          </el-row>
          <!-- Table Customers Data (E) -->

          <!-- Paging (S) -->
          <el-row type="flex" justify="center">
            <el-col>
              <div class="grid-content bg-purple">
                <el-pagination
                  class="pagination"
                  @current-change="changePage"
                  :page-size="pageSize"
                  :current-page="currentPage"
                  layout="prev, pager, next"
                  :total="totalCustomerAddressCnt"
                >
                </el-pagination>
              </div>
            </el-col>
          </el-row>
          <!-- Paging (E) -->
        </Widget>
        <!-- Popup Add Customer (S) -->
        <el-dialog
          :title="titlePopup"
          :visible.sync="customerAddressDialogVisible"
          :before-close="handleCloseAddForm"
          width="45%"
          :modal-append-to-body="false"
          :append-to-body="true"
          :close-on-click-modal="false"
        >
          <el-form
            ref="formCustomerAddress"
            :rules="rulesForm"
            :model="formCustomerAddress"
            label-width="200px"
          >
            <!-- isDefault (S) -->
            <el-form-item prop="isDefault">
              <el-switch
                v-model="formCustomerAddress.isDefault"
                active-text="Active"
                inactive-text="Inactive"
              >
              </el-switch>
            </el-form-item>
            <!-- isDefault (E) -->

            <!-- companyName (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.customerAddress.index.companyName')"
              prop="companyName"
            >
              <el-input
                size="medium"
                type="text"
                class="input_add"
                v-model="formCustomerAddress.companyName"
              />
            </el-form-item>
            <!-- companyName (E) -->

            <!-- receiverName (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.customerAddress.index.receiverName')"
              prop="receiverName"
            >
              <el-input
                size="medium"
                type="text"
                class="input_add"
                v-model="formCustomerAddress.receiverName"
              />
            </el-form-item>
            <!-- receiverName (E) -->

            <!-- email (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.customer.index.email')"
              prop="email"
            >
              <el-input
                size="medium"
                type="email"
                class="input_add"
                v-model="formCustomerAddress.email"
              />
            </el-form-item>
            <!-- email (E) -->

            <!-- phone (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.customer.index.phone')"
              prop="phone"
            >
              <el-input
                size="medium"
                type="text"
                class="input_add"
                v-model="formCustomerAddress.phone"
              />
            </el-form-item>
            <!-- phone (E) -->

            <!-- address (S) -->
            <el-form-item
              :label="$i18n.t('src.modules.customerAddress.index.address')"
              prop="address"
            >
              <el-input
                size="medium"
                class="input_add"
                v-model="formCustomerAddress.address"
              />
            </el-form-item>
            <!-- address (E) -->

            <!-- province (S)  -->
            <el-form-item
              :label="$i18n.t('src.modules.customerAddress.index.province')"
              prop="province"
            >
              <el-select
                size="medium"
                clearable
                placeholder="Select Province"
                v-model="formCustomerAddress.province"
                width="100%"
                filterable
                @change="resetLocationForm('province')"
              >
                <el-option
                  v-for="item in provinceList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- province (E)  -->

            <!-- district (S)  -->
            <el-form-item
              :label="$i18n.t('src.modules.customerAddress.index.district')"
              prop="district"
            >
              <el-select
                size="medium"
                clearable
                placeholder="Select District"
                v-model="formCustomerAddress.district"
                width="100%"
                filterable
                @change="resetLocationForm('district')"
              >
                <el-option
                  v-for="item in districtListInForm"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- district (E)  -->

            <!-- ward (S)  -->
            <el-form-item
              :label="$i18n.t('src.modules.customerAddress.index.ward')"
              prop="ward"
            >
              <el-select
                size="medium"
                clearable
                placeholder="Select Ward"
                v-model="formCustomerAddress.ward"
                width="100%"
                filterable
              >
                <el-option
                  v-for="item in wardListInForm"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- ward (E)  -->
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-row :gutter="24">
              <el-col :span="4">
                <el-button size="medium" @click="handleCloseAddForm">{{
                  $i18n.t('src.modules.news.index.close')
                }}</el-button>
              </el-col>
              <el-col l :span="20">
                <el-button
                  size="medium"
                  v-if="formMode === 'add'"
                  type="primary"
                  @click="onClickAddRow()"
                  >{{ $i18n.t('src.modules.news.index.btnAdd') }}</el-button
                >
                <el-button
                  size="medium"
                  v-if="formMode === 'edit'"
                  type="success"
                  @click="onSaveEditCustomerAddress()"
                  >{{ $i18n.t('src.modules.news.index.update') }}</el-button
                >
              </el-col>
            </el-row>
          </span>
        </el-dialog>
      </b-col>
    </b-row>
  </div>
</template>

<script>
//import
import { mapState, mapActions } from 'vuex';
import vnLocal from 'vn-local';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import {
  validatePhone,
  validateStringPreventDuplicateWhiteSpace,
} from 'core/helpers';

export default {
  //component
  props: {
    customer_id: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      isLoading: true,

      addressSelectedList: [],

      // Paging
      currentPage: 1,
      pageSize: 10,

      formMode: 'add',

      formCustomerAddressOld: {},

      // Popup Add
      customerAddressDialogVisible: false,

      formCustomerAddressDefault: {
        customerId: this.customer_id,
        companyName: '',
        receiverName: '',
        email: '',
        phone: '',
        province: null,
        district: null,
        ward: null,
        address: '',
        isDefault: false
      },

      formCustomerAddress: {
        customerId: this.customer_id,
        companyName: '',
        receiverName: '',
        email: '',
        phone: '',
        province: null,
        district: null,
        ward: null,
        address: '',
        isDefault: false
      },

      rulesForm: {
        companyName: [
          {
            required: true,
            message: 'Please enter company name',
            trigger: 'change',
            validator: validateStringPreventDuplicateWhiteSpace
          }
        ],
        receiverName: [
          {
            required: true,
            message: 'Please enter receiver name',
            trigger: 'change',
            validator: validateStringPreventDuplicateWhiteSpace
          }
        ],
        email: [
          {
            required: true,
            type: 'email',
            message: 'Please enter the correct email',
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            message: 'Please enter the correct phone format',
            trigger: 'change'
          }
        ],
        address: [
          {
            required: true,
            message: 'Please enter address',
            trigger: 'change',
            validator: validateStringPreventDuplicateWhiteSpace
          }
        ],
        district: [
          {
            required: true,
            message: 'Please enter district',
            trigger: 'change'
          }
        ],
        ward: [
          {
            required: true,
            message: 'Please enter ward',
            trigger: 'change'
          }
        ],
        province: [
          {
            required: true,
            message: 'Please choose province',
            trigger: 'change'
          }
        ]
      }
    };
  },
  computed: {
    ...mapState('customerAddress', ['customerAddress']),
    ...mapState('customer', ['customers', 'customersAddLocal', 'customerMap']),
    //get list province
    provinceList() {
      return vnLocal.getProvinces()?.map(item => {
        return {
          value: item.code,
          label: item.name
        };
      });
    },

    titlePopup() {
      switch (this.formMode) {
        case 'add':
          return this.$i18n.t('src.modules.news.index.form_add');
        case 'edit':
          return this.$i18n.t('src.modules.news.index.form_edit');
        case 'editAdd':
          return this.$i18n.t('src.modules.news.index.form_edit');
        default:
          return this.$i18n.t('src.modules.news.index.form_add');
      }
    },

    isDisabledButtonDelete() {
      return !this.addressSelectedList.length > 0;
    },

    districtListInForm() {
      return this.formCustomerAddress.province
        ? vnLocal
            .getDistrictsByProvinceCode(this.formCustomerAddress.province)
            ?.map(item => {
              return {
                value: item.code,
                label: item.name
              };
            })
        : [];
    },

    wardListInForm() {
      return this.formCustomerAddress.district
        ? vnLocal.getWardsByDistrictCode(this.formCustomerAddress.district)?.map(item => {
            return {
              value: item.code,
              label: item.name
            };
          })
        : [];
    },

    allCustomerAddress() {
      const data = this.customerAddress.filter(
        item => item.customerId === this.customer_id
      );
      return data;
    },

    // Paging
    pagedTableData() {
      let dataPaging = this.allCustomerAddress.slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );

      dataPaging.map(item => {
        if (item.district) {
          const district = vnLocal.getWardsByDistrictCode(item.district);
          item.districtName = district?.[0]?.districtName || 'No Data';
          item.provinceName = district?.[0]?.provinceName || 'No Data';
          item.wardName =
            (item.ward && district?.find(x => x.code === item.ward)?.name) || 'No Data';
        }
        item.customerName = item?.customer?.customerName || 'No Data';
        //get customer name after add new address
        if (item.customerId) {
          const id = item.customerId;
          item.customerName =
            (this.customerMap[id] && this.customerMap[id].customerName) || 'No Data';
        }
        return item;
      });
      return dataPaging;
    },

    totalCustomerAddressCnt() {
      return this.allCustomerAddress.length || 0;
    }
  },
  watch: {
    customer_id: async function() {
      this.formCustomerAddress.customerId = this.customer_id;
      this.formCustomerAddressDefault.customerId = this.customer_id;
    }
  },
  async created() {
    await Promise.all([
      this.getAllCustomerAddressFunc(),
      // this.getAllCustomers({})
    ]).then(() => {
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('customerAddress', [
      'getAllCustomerAddress',
      'saveAddCustomerAddress',
      'saveEditCustomerAddress',
      'deleteCustomerAddressList'
    ]),
    ...mapActions('customer', ['getAllCustomers']),

    async getAllCustomerAddressFunc(){
      await this.getAllCustomerAddress({ customerId: this.customer_id })
      this.isLoading = false;
      return true;
    },

    createFilter(queryString) {
      return link => {
        return link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSelectionChange(val) {
      this.addressSelectedList = val;
    },

    // Paging
    changePage(val) {
      this.currentPage = val;
    },

    // Form customerAddress
    clearFormCustomerAddress() {
      this.formCustomerAddress = cloneDeep(this.formCustomerAddressDefault);
    },

    resetLocationForm(name) {
      if (name === 'province') {
        this.formCustomerAddress.district = '';
        this.formCustomerAddress.ward = '';
      }
      if (name === 'district') {
        this.formCustomerAddress.ward = '';
      }
    },

    // Action Button
    onClickShowAddPopup() {
      this.customerAddressDialogVisible = true;
      this.formMode = 'add';
      this.clearFormCustomerAddress();
    },

    handleCloseAddForm(done) {
      var message = null;
      if (!isEqual(this.formCustomerAddress, this.formCustomerAddressDefault)) {
        message = this.$i18n.t('common.confirm.close.form.out', {
          name: 'customer address'
        });
        this.$confirm(message)
          .then(() => {
            this.clearFormCustomerAddress();
            this.customerAddressDialogVisible = false;
            done();
          })
          .catch(() => {});
      } else {
        this.customerAddressDialogVisible = false;
      }
    },

    onClickEditAddress(index) {
      const { id } = index.row;
      if (id) {
        this.formMode = 'edit';
      }

      (this.formCustomerAddress = {
        addressId: index.row.id,
        // customerName: index.row.customerName,
        email: index.row.email,
        phone: index.row.phone,
        address: index.row.address,
        companyName: index.row.companyName,
        province: index.row.province,
        ward: index.row.ward,
        district: index.row.district,
        receiverName: index.row.receiverName,
        customerId: index.row.customerId,
        isDefault: index.row.isDefault
      }),
        (this.formCustomerAddressOld = cloneDeep(index.row));
      this.customerAddressDialogVisible = true;
    },

    async onClickAddRow() {
      this.$refs['formCustomerAddress'].validate(async valid => {
        if (valid) {
          try {
            await this.saveAddCustomerAddress(this.formCustomerAddress);
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('common.notify.message.add.success', {
                item: 'row'
              }),
              type: 'success'
            });
            this.clearFormCustomerAddress();
            this.customerAddressDialogVisible = false;
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.add.failr', { name: 'row' }),
              type: 'error'
            });
          }
        } else {
          return false;
        }
      });
    },

    onSaveEditCustomerAddress() {
      this.$confirm('Do you want change ?')
        .then(async () => {
          this.$refs['formCustomerAddress'].validate(async valid => {
            if (valid) {
              try {
                const result = await this.saveEditCustomerAddress(
                  this.formCustomerAddress
                );
                if (result) {
                  this.formCustomerAddressOld = {};
                  this.$notify({
                    title: 'Success',
                    message: 'Edit Customers Address Success',
                    type: 'success'
                  });
                } else {
                  this.$notify({
                    title: 'Error',
                    message: `Can not edit Customers Address, Error: ${result.message}`,
                    type: 'error'
                  });
                }
                this.customerAddressDialogVisible = false;
              } catch (error) {
                this.$notify({
                  title: this.$i18n.t('common.notify.title.error'),
                  message: this.$i18n.t('common.notify.message.edit.fail', {
                    name: 'row'
                  }),
                  type: 'error'
                });
              }
            } else {
              return false;
            }
          });
        })
        .catch(error => {
          console.log('error: ', error);
        });
    },

    onClickDeleteData() {
      this.$confirm('Do you want to remove Customers Address rows ?')
        .then(async () => {
          if (this.addressSelectedList.length > 0) {
            const CustomerAddressIdArr = this.addressSelectedList.map(item => item.id);
            const result = await this.deleteCustomerAddressList(CustomerAddressIdArr);
            if (result) {
              this.$notify({
                title: 'Success',
                message: 'Delete customers address rows Success',
                type: 'success'
              });
              this.addressSelectedList = [];
            } else {
              this.$notify({
                title: 'Error',
                message: 'Can not remove customers address rows',
                type: 'error'
              });
            }
          } else {
            this.$notify({
              title: 'Error',
              message: 'Can not remove customers address rows',
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },

    async onClickRemove(index) {
      this.$confirm(`Do you want to remove ${index.row.customerName} ?`)
        .then(async () => {
          const result = await this.deleteCustomerAddressList([index.row.id]);
          if (result) {
            this.$notify({
              title: 'Success',
              message: 'Delete Success',
              type: 'success'
            });
          } else {
            this.$notify({
              title: 'Error',
              message: 'Cannot remove customer address',
              type: 'error'
            });
          }
        })
        .catch(() => {});
    }
  }
};
</script>
