import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  personInChargeArray: [],
  personInChargeMap: {}
};

import cloneDeep from 'lodash/cloneDeep';
// import { isEqual } from 'lodash';

const mutations = {
  [Type.SET_ALL_PERSON_IN_CHARGE](state, value) {
    state.personInChargeArray = value;
    state.personInChargeMap = state.personInChargeArray.reduce(
      (cMap, c) => ({
        ...cMap,
        [c.id]: c
      }),
      {}
    );
  },

  [Type.SET_PERSON_IN_CHARGE_ADD](state, value) {
    const oldPersonInCharge = state.personInChargeArray;
    // if new personIncharge default value is true => update all another data default to false
    if (value[0]['isDefault'] === true) {
      oldPersonInCharge.map(item => {
        if (item.isDefault === true) {
          item.isDefault = false;
        }
        return item;
      });
    }

    //add new data
    state.personInChargeArray = [...cloneDeep(value), ...oldPersonInCharge];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
