import Service from 'core/service';
import {
    cloneDeep
} from 'lodash';
import * as Type from 'modules/orderDetail/store/types';
const service = new Service();
import axios from 'axios';
import {
    formattedNumber,
    convertNumberToWord,
    formatDate
} from 'core/helpers';

const _convertData = row => {
    const newData = cloneDeep(row);
    const { saleId = [], bdId = [] } = newData || {};

    if( Array.isArray(saleId) && saleId.length > 0){
        newData.saleId = `|${saleId.join('|')}|`;
    }else{
        newData.saleId = '';
    };

    if( Array.isArray(bdId) && bdId.length > 0){
        newData.bdId = `|${bdId.join('|')}|`;
    }else{
        newData.bdId = '';
    };
    return newData;
};

const updateOrderTags = async (_, {
    tags,
    orderId
}) => {
    return service
        .rest('order-detail/updateOrderTags', {
            tags,
            orderId
        })
        .then(rlt => {
            return rlt.status;
        })
        .catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
            return false;
        });
};

const getByOrderId = async ({
    commit
}, value) => {
    const {
        id = ''
    } = value;
    const params = {};
    if (id !== '') {
        params.id = id;
    }
    return service
        .rest('order-detail/getByCheckoutId', params)
        .then(res => {
            commit(Type.GET_BY_ORDER_ID, res);
            return true;
        })
        .catch(err => {
            console.log(err);
        });
};

const getOrderDetailSupplierByOrderId = async ({
    commit
}, value) => {
    const {
        id = '', supplierId = ''
    } = value;
    const params = {};
    if (id !== '') {
        params.id = id;
    }
    if (supplierId !== '') {
        params.supplierId = supplierId;
    }
    return service
        .rest('orderDetailSupplier/getByCheckoutId', params)
        .then(res => {
            commit(Type.GET_ORDER_DETAIL_SUPPIER_BY_ORDER_ID, res);
            return true;
        })
        .catch(err => {
            console.log(err);
        });
};

const addOrderDetailLocal = async ({
    commit
}, newRow) => {
    // Convert data
    const data = _convertData(newRow);
    // Commit to news state
    commit(Type.SET_ORDER_DETAIL_LOCAL, data);
    return true;
};

const saveAddOrderDetails = async ({ commit }, arrAddData) => {
  // Convert data
  const { checkoutId, newOrderDetails, discountAmount, eiCash, customerId} = arrAddData;
  const arrAddDataConvert = newOrderDetails.map(item => _convertData(item));
  return service
    .rest('order-detail/setList', {
      items: { $add: arrAddDataConvert },
      checkoutId,
      discountAmount,
      eiCash,
      customerId
    })
    .then(rlt => {
      const { items, price, newOrderSuppliers } = rlt;
      const { $rltAdd } = items;
      // Commit to Partner state
      commit(Type.SET_ORDER_DETAIL_ADD, { rltAdd: $rltAdd, price, newOrderSuppliers });
      return true;
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
      return false;
    });
};

const saveEditOrderDetail = async ({ commit }, addData) => {
    const { oldData, newData, checkoutId, discountAmount, eiCash, customerId} = addData;
    const { id } = newData;
    const data = _convertData(newData);
    const oldDataConvert = _convertData(oldData);
    const postData = {
      items: { $update: [data] },
      checkoutId,
      $oldData: [oldDataConvert],
      discountAmount,
      eiCash,
      customerId
    };
    if (id) {
      // Convert data
      return service
        .rest('order-detail/setList', postData)
        .then(rlt => {
          const { items, price, newOrderSuppliers } = rlt;
          const { $rltUpdate } = items;
          // Commit to news state
          commit(Type.SET_ORDER_DETAIL_UPDATE, { rltUpdate: $rltUpdate[0], price, updateOrderSupplier: newOrderSuppliers[0] });
          return true;
        })
        .catch(e => {
          console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
          return false;
        });
    } else {
      // Commit to news state
      commit(Type.SET_ORDER_DETAIL_UPDATE_LOCAL, { oldData, newData: data });
      return true;
    }
};


const changeStatus = async ({
    commit
}, newStatus) => {
    commit(Type.SET_STATUS_LOCAL, newStatus);
    return true;
};


const deleteOrderDetail = async ({ commit }, value) => {
    const { deleteItem, checkoutId, discountAmount, eiCash, customerId } = value;
    const { id } = deleteItem;
    if (id) {
      return service
        .rest('order-detail/setList', {
          items: { $delete: [deleteItem] },
          checkoutId,
          discountAmount,
          eiCash,
          customerId
        })
        .then(rlt => {
          const { items, price, newOrderSuppliers } = rlt;
          const { $rltDelete } = items;
          // Commit to news state
          commit(Type.SET_DELETE_ORDER_DETAIL, { rltDelete: $rltDelete[0], price, updateOrderSupplier: newOrderSuppliers[0] || null });
          return $rltDelete;
        })
        .catch(e => {
          console.log('🚀 ~ file: orderDetail.actions.js ~ line 113~ awaitservice.rest ~ e', e);
          return false;
        });
    } else {
      commit(Type.SET_DELETE_ORDER_DETAIL_LOCAL, [value.deleteItem]);
      return true;
    }
  };


const deletePartnerList = async ({
    commit
}, value) => {
    const lstLocal = [];
    const lstDB = [];
    // Filter
    value.map(item => {
        const {
            id
        } = item;
        if (id) {
            lstDB.push(item);
        } else {
            lstLocal.push(item);
        }
    });

    if (lstLocal.length > 0) {
        commit(Type.SET_DELETE_PARTNER_LOCAL, lstLocal);
    }

    if (lstDB.length > 0) {
        return service
            .rest('partner/setList', {
                items: {
                    $delete: lstDB
                }
            })
            .then(rlt => {
                const {
                    items
                } = rlt;
                const {
                    $rltDelete
                } = items;
                // Commit to news state
                commit(Type.SET_DELETE_PARTNER, $rltDelete);
                return true;
            })
            .catch(e => {
                console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
                return false;
            });
    }
};

const getOrderById = ({
    commit
}, value) => {
    const {
        id,
        supplierId = ''
    } = value;
    const params = {};
    if (supplierId !== '') {
        params.supplierId = supplierId;
    }
    return service
        .rest(`order/${id}`, params)
        .then(res => {
            const {
                status,
                data
            } = res;
            if (status) {
                commit(Type.SET_ORDER_BY_ID, data);
                return true;
            }
            return false;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
};

const uploadFile = async ({
    commit
}, {
    prefix,
    companyId = 1,
    orderId,
    typeId,
    file
}) => {
    const {
        name,
        raw,
        size
    } = file;
    const {
        type
    } = raw;
    const filePath = prefix + '/' + companyId + '/' + orderId + '/' + name;
    const {
        status,
        message,
        signedRequest
    } = await service.rest('uploadDocument', {
        prefix,
        companyId,
        orderId,
        typeId,
        name,
        filePath,
        fileType: type
    });

    //duplicate image
    if (status === false) {
        // throw new Error(message);
        return {
            status,
            message
        };
    }

    const options = {
        headers: {
            'Content-Type': type
        }
    };
    await axios.put(signedRequest, raw, options);
    const row = {};
    row.fileName = name;
    row.filePath = filePath;
    row.size = size;
    row.orderId = orderId;
    row.typeId = typeId;

    return service
        .rest('documentManagement/setList', {
            items: {
                $add: [row]
            }
        })
        .then(rlt => {
            const {
                items
            } = rlt;
            const {
                $rltAdd
            } = items;
            // Commit to news state
            commit(Type.UPLOAD_FILE, $rltAdd[0]);
            const result = {
                status: true,
                message: 'success'
            };
            return result;
        })
        .catch(error => {
            // eslint-disable-next-line
            console.log('[EI Debug] [ERROR] >>> action >> addFile > error ', error);
            const result = {
                status: false,
                message: error.message
            };
            return result;
        });
};

const deleteFile = async ({
    commit
}, value) => {
    return service
        .rest('documentManagement/setList', {
            items: {
                $delete: [value]
            }
        })
        .then(rlt => {
            const {
                items
            } = rlt;
            const {
                $rltDelete
            } = items;
            // Commit to news state
            commit(Type.DELETE_FILE, {
                rltDelete: $rltDelete
            });
            return $rltDelete;
        })
        .catch(e => {
            console.log('🚀 ~ file: orderDetail.actions.js ~ line 113~ awaitservice.rest ~ e', e);
            return false;
        });
};

const sendMail = async ({
    commit
}, value) => {
    const {
        listEmail,
        orderData,
        mailTemplateCode,
        note,
        title
    } = value;
    const mailTemplateComCode = 'ORDR_TMPLT_ML';
    const objParam = {
        listEmail,
        orderData,
        mailTemplateCode,
        note,
        title
    };
    return service
        .rest('email/sendMailTemplate', {
            mailTemplateComCode,
            objParam
        })
        .then(rlt => {
            commit(Type.SEND_MAIL_TEMPLATE);
            if (rlt.status) return true;
            else return false;
        })
        .catch(err => {
            console.log('🚀 ~ file: orderDetail.actions.js ~ line 113~ awaitservice.rest ~ e', err);
            return false;
        });
};

const sendMailToSupplier = async ({
    commit
}, value) => {
    const {
        listEmail,
        orderData,
        mailTemplateCode,
        note,
        title
    } = value;
    const mailTemplateComCode = 'ORDR_TMPLT_ML';
    const objParam = {
        listEmail,
        orderData,
        mailTemplateCode,
        note,
        title
    };
    return service
        .rest('email/sendMailTemplate', {
            mailTemplateComCode,
            objParam
        })
        .then(rlt => {
            commit(Type.SEND_MAIL_TEMPLATE);
            if (rlt.status) return true;
            else return false;
        })
        .catch(err => {
            console.log('🚀 ~ file: orderDetail.actions.js ~ line 113~ awaitservice.rest ~ e', err);
            return false;
        });
};

const previewMail = async (_, value) => {
    const {
        listEmail,
        orderData,
        mailTemplateCode,
        note,
        title
    } = value;
    const mailTemplateComCode = 'ORDR_TMPLT_ML';
    const objParam = {
        listEmail,
        orderData,
        mailTemplateCode,
        note,
        title
    };
    console.log('🚀 ~ file: actions.js ~ line 280 ~ previewMail ~ listEmail, orderData, mailTemplateCode, note, title', listEmail, orderData, mailTemplateCode, note, title);
    return service
        .rest('email/previewMailTemplate', {
            mailTemplateComCode,
            objParam
        })
        .then(rlt => {
            return rlt;
        })
        .catch(err => {
            console.log('🚀 ~ file: orderDetail.actions.js ~ line 113~ awaitservice.rest ~ e', err);
            return false;
        });
};

const exportPO = async ({
    commit
}, value) => {
    const {
        orderData,
        orderDetails
    } = value;

    commit(Type.EXPORT_PO);

    const renderOrderDetails = orderDetails
        .map((od, index) => {
            return ` <tr>
                  <td>${index + 1}</td>
                  <td style="word-wrap:break-word">${od.products.eic}</td>
                  <td style="word-wrap:break-word">
                      ${od.productName}<br/>
                      ${od.variants && od.variants.length > 0 ? od.variants.map(v => `<span class="productVarName">${v.groupName}: ${v.name}</span>`).join(', ') : ''}
                  </td>
                  <td>${od.productUnit}</td>
                  <td>${od.quantity}</td>
                  <td>
                    ${formattedNumber(Math.round(od.price))} đ
                  </td>
                  <td>
                    ${od.taxRate} %
                  </td>
                  <td class="text-right">
                    ${formattedNumber(Math.round(od.price * od.quantity))} đ
                  </td>
              </tr> `;
        })
        .join('');
    const html = `
  <html>
    <head>
    <style>
          .exportPO .quoteManagement {
                padding-bottom: 3.5rem !important;
                background: #eee;
            }
    
            .exportPO .logo {
                height: 50px;
            }
    
            .exportPO .center {
                display: flex;
                justify-content: center;
            }
    
            .exportPO .content {
                min-width: 896px;
                max-width: 896px;
                margin-left: 10px;
                padding: 0
            }
    
            .exportPO .box {
                border-radius: 25px;
                background: #fff;
                padding: 20px;
                margin-bottom: 20px;
            }
    
            .exportPO .width50 {
                width: 50%;
                display: inline-block;
            }
    
            .exportPO .borderBottom {
                border-bottom: 1px solid #e7eaf3;
                margin-bottom: 1rem;
            }
    
            .exportPO .borderBottomBold {
                border-bottom: 5px solid #777;
                margin-bottom: 1rem;
            }
    
            .exportPO .tableCustomQuotation tr td {
                font-size: 13px;
                border-top: none;
                min-width: 170px;
                padding: 0rem 0.75rem 0.75rem 0rem;
            }
    
            .exportPO .tableCustomQuotation2 {
                margin-bottom: 0px;

            }
    
            .exportPO .tableCustomQuotation2 tr th {
                font-size: 13px;
                padding: 10px 5px;
                font-weight: normal;
                border-bottom: 1px solid #dee2e6;
            }
    
            .exportPO .tableCustomQuotation2 tr td {
                font-size: 13px;
                border-top: none;
                padding: 10px 5px;
                max-width: 200px;
            }
    
            .exportPO .borderTop {
                border-top: 1px solid #e7eaf3 !important;
                margin-top: 1rem;
            }
    
            .exportPO .width200 {
                width: 200px;
            }
    
            .exportPO .pdCustomAddress {
                padding: 0px 5px !important;
            }
    
            .exportPO .pdCustomAddressFirst {
                padding: 10px 5px 0 5px !important;
            }
    
            .exportPO .pdCustomAddressLast {
                padding: 0 5px 10px 5px !important;
            }
    
            .exportPO .boxCheck {
                display: inline-block;
                width: 15px;
                height: 15px;
                border: 1px solid #000;
                margin-top: 3px;
            }
    
            .exportPO .rulesStyle {
                list-style: none;
                padding-left: 0;
            }
    
            .exportPO .rulesStyle li {
                font-size: 14px;
                padding: 2px 0px 2px 0px;
            }
    
            .exportPO  .borderTopNone {
                border-top: 1px solid transparent !important;
            }
    
            .exportPO .mb100 {
                margin-bottom: 100px;
            }
        </style>
    </head>
  <body>
  <div class="container-fluid exportPO">
    <div class="row">
        <div class="col-md-12 pt-3">
            <img  src='/images/logo-final-3.png'
                      class="logo"
                      alt="logo"
                  />
            <div class="row">
            <div class="col-md-12">
                <h2 class="font-weight-bold text-center" style="font-size: 21px">ĐƠN ĐẶT HÀNG -
                PURCHASE ORDER
                </h2>
            </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <p class="mb-0" style="font-size: 14px;"><strong>Đơn đặt hàng số/ PO number:
                </strong> ${orderData.code}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 width50">
            <div class="borderBottomBold"></div>
            <div class="responsive">
                <table class="table tableCustomQuotation">
                    <tbody>
                        <tr>
                            <td>
                                <strong> BÊN BÁN/SELLER</strong>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <strong> Tên doanh nghiệp </strong> <br />
                                <i>Company Name</i>
                            </td>
                            <td><strong>CÔNG TY CỔ PHẦN EI INDUSTRIAL</strong></td>
                        </tr>
                        <tr>
                            <td>
                                <strong> Địa chỉ </strong> <br />
                                <i>Address</i>
                            </td>
                            <td>37 Nguyễn Văn Hưởng, Thảo Điền, Thành phố Thủ Đức, Thành phố
                                Hồ Chí Minh,
                                Việt Nam</td>
                        </tr>
                        <tr>
                            <td>
                                <strong> Mã số thuế </strong> <br />
                                <i>Tax Identification Number </i>
                            </td>
                            <td>0314878148 </td>
                        </tr>
                        <tr>
                            <td>
                                <strong> Người đại diện </strong> <br />
                                <i>Legal Representative </i>
                            </td>
                            <td>Mr. Hồ Phi Ân</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-6 width50">
            <div class="borderBottomBold"></div>
            <div class="responsive">
                <table class="table tableCustomQuotation">
                    <tbody>
                        <tr>
                            <td>
                                <strong> BÊN MUA/BUYER </strong>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <strong> Tên doanh nghiệp </strong> <br />
                                <i>Company Name</i>
                            </td>
                            <td><strong>${orderData && orderData.customerName }</strong></td>
                        </tr>
                        <tr>
                            <td>
                                <strong> Địa chỉ </strong> <br />
                                <i>Address</i>
                            </td>
                            <td>${orderData.fullAddress}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong> Mã số thuế </strong> <br />
                                <i>Tax Identification Number </i>
                            </td>
                            <td>${orderData && orderData.customer.taxCode ? orderData.customer.taxCode : ''}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong> Người đại diện </strong> <br />
                                <i>Legal Representative </i>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <strong> Email nhận hoá đơn điện tử </strong> <br />
                                <i>Email receive e_invoice</i>
                            </td>
                            <td>${orderData && orderData.email}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-12" style="font-size: 14px;">
            <strong>Đồng ý mua/</strong> Agree to buy: <br />
            <strong>Thông tin sản phẩm/</strong> Good details:
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table tableCustomQuotation2">
                <thead>
                    <tr>
                        <th><strong>STT</strong><br /> <i>No.</i></th>
                        <th><strong>Mã sản phẩm</strong><br /> <i>Product code</i></th>
                        <th><strong>Tên sản phẩm</strong><br /> <i>Product name</i></th>
                        <th><strong>Đơn vị tính</strong><br /> <i>Unit</i></th>
                        <th><strong>Số lượng</strong><br /> <i>Quantity</i></th>
                        <th><strong>Đơn giá</strong><br /> <i>Unit price</i></th>
                        
                        <th><strong>Thuế suất</strong><br />
                            <i>Tax Rate</i>
                        </th>
                        <th class="text-right"><strong>Thành tiền</strong><br />
                            <i>Total</i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                ${renderOrderDetails}
                </tbody>
            </table>
            <table class="table tableCustomQuotation2">
                <tbody>
                    <tr>
                        <td class="borderTop width200" colspan="8">
                            <strong>Cộng tiền hàng/ Sub total</strong><br />
                            ${orderData.shippingFee && orderData.shippingFee > 0 ? '<strong>Cộng tiền phí vận chuyển/ Shipping fee</strong><br />' : ''}
                            <strong>Cộng tiền thuế GTGT/ VAT amount</strong><br />
                            ${orderData.discountAmount && orderData.discountAmount > 0 ? '<strong>Cộng tiền giảm giá/ Discount amount</strong><br />' : ''}
                            ${orderData.eiCash && orderData.eiCash > 0 ? '<strong>Sử dụng EI Cash/ Use EI cash</strong><br />' : ''}
                            <strong>Tổng cộng tiền thanh toán/ Total amount</strong><br />
                        </td>
                        <td class="borderTop text-right" colspan="1">
                            <span>
                                ${formattedNumber(Math.round(orderData.totalPrice))} đ
                            </span><br />
                            ${ orderData.shippingFee && orderData.shippingFee ? '<span>' + formattedNumber(Math.round(orderData.shippingFee)) + ' đ</span><br/>' : ''}
                            <span>${formattedNumber(Math.round(orderData.vat))} đ</span><br />
                            ${ orderData.discountAmount && orderData.discountAmount > 0 ? '<span>-' + formattedNumber(Math.round(orderData.discountAmount)) + ' đ</span><br/>' : ''}
                            ${ orderData.eiCash && orderData.eiCash > 0 ? '<span>-' + formattedNumber(Math.round(orderData.eiCash)) + ' đ</span><br/>' : ''}
                            <span><strong>
                            ${formattedNumber(Math.round(orderData.totalSalePrice))} đ
                                </strong></span><br />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="borderTop width200">
                            <strong>Bằng chữ</strong><br /> <i>Total in words</i>
                        </td>
                        <td colspan="8" class="borderTop">${convertNumberToWord(Math.round(orderData.totalSalePrice))} việt nam đồng</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="borderTop width200 pdCustomAddressFirst">
                            <strong>Nơi nhận hàng</strong><br />
                            <i>Ship to</i>
                        </td>
                        <td colspan="8" class="borderTop">${orderData && orderData.fullAddress}</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="pdCustomAddress width200">
                            <strong>Địa chỉ </strong><br /> <i>Address</i>
                        </td>
                        <td colspan="8" class="pdCustomAddress">${orderData && orderData.fullAddress}</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="pdCustomAddress width200">
                            <strong>Ngày nhận hàng </strong><br /> <i>Received date</i>
                        </td>
                        <td colSpan="8" class="pdCustomAddress">
                          ${orderData && orderData.estimatedDeliveryDate && formatDate(orderData.estimatedDeliveryDate)}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="pdCustomAddressLast width200">
                            <strong>Phương tiện vận chuyển </strong><br />
                            <i>Transportation</i>
                        </td>
                        <td colspan="8" class="pdCustomAddress">Xe tải <br />By truck
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="borderTop width200 pdCustomAddressFirst">
                            <strong>Thanh toán</strong><br />
                            <i>Payment</i>
                        </td>
                        <td colspan="8" class="borderTop pdCustomAddressFirst">Thanh toán 100% trước khi giao hàng theo thông tin dưới đây
                            <br /><i>100% payment before delivery as follow information </i>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="pdCustomAddress width200">
                            <strong>Tên tài khoản </strong><br /> <i>Benrficiary</i>
                        </td>
                        <td colspan="8" class="pdCustomAddress">CÔNG TY CỔ PHẦN EI INDUSTRIAL</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="pdCustomAddress width200">
                            <strong>Số tài khoản </strong><br /> <i>Benrficiary account</i>
                        </td>
                        <td colspan="8" class="pdCustomAddress">9991115678999</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="pdCustomAddress width200">
                            <strong>Ngân hàng </strong><br /> <i>Bank</i>
                        </td>
                        <td colspan="8" class="pdCustomAddress">Quân đội (MBBANK)</td>
                    </tr>
                    <tr>
                        <td colSpan="1" class="pdCustomAddressLast width200">
                            <strong>Chi nhánh </strong><br /> <i>Branch</i>
                        </td>
                        <td colspan="8" class="pdCustomAddress">Tân Cảng</td>
                    </tr>
                    <tr>
                        <td colSpan="1" class="pdCustomAddressFirst borderTop width200">
                            <strong>Bảo hành/</strong> <i>Warranty</i>
                        </td>
                        <td colspan="8" class="pdCustomAddressFirst borderTop"><span
                                class="boxCheck"></span></td>
                    </tr>
                    <tr>
                        <td colspan="7"><strong>Thời hạn bảo
                                hành:</strong>Theo chính sách của nhà cung cấp<br /> <i>Warranty period</i></td>
                        <td colspan="1"><strong>Kích
                                thước:</strong><br /> <i>Dimention</i></td>
                        <td colspan="1"><strong>Cân
                                nặng:</strong><br /> <i>Weight</i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="borderBottom"></div>
            <ul class="rulesStyle"> 
                <li style="margin-bottom: 10px;"><strong style="font-size: 14px;">ĐIỀU KHOẢN/ TERM</strong></li>
                <li style="margin-bottom: 10px;"> - Hóa đơn GTGT sẽ được gởi vào địa chỉ email đăng ký sau khi đơn hàng đã giao thành công./ <i>The VAT invoice will be sent to the registered email after the Purchase Order has been successfully delivered.</i></li>
                <li style="margin-bottom: 10px;"> - Nếu sản phẩm có dấu hiệu hư hỏng/ bể vỡ hoặc không đúng với thông tin trên website, bạn vui lòng liên hệ với EI trong vòng 24 giờ 
                kể từ thời điểm nhận hàng để được hỗ trợ. Vui lòng giữ nguyên vẹn biên nhận bán hàng được dán trên thùng hàng, hóa đơn (nếu có) và hộp sản phẩm để đổi 
                trả hàng hoặc bảo hành khi cần./ <i>If the product shows signs of damage / breakage or does not match the information on the website, please contact 
                EI within 24 hours from the time of receiving the goods for support. Please keep the sales receipt affixed on the box, invoice (if any) and product box 
                intact for exchange or warranty when needed.</i>
                <li> - Bên Mua đồng ý mua hàng hóa với thông tin chi tiết ở trên và đồng ý thực hiện, tuân thủ các quyền và nghĩa vụ được quy định trong Đơn Đặt Hàng này 
                và Các Chính Sách và Điều Khoản niêm yết tại website https://eiindustrial.com/ (sau đây được gọi chung là “Các Chính Sách và Điều Khoản”). Bằng việc ký 
                kết vào Đơn Đặt Hàng này, Bên Mua tại đây xác nhận và hiểu rõ toàn bộ Các Chính Sách và Điều Khoản và hoàn toàn đồng ý với mỗi và mọi điều khoản và điều 
                kiện được quy định tại Các Chính Sách và Điều Khoản và các phụ lục điều chỉnh (nếu có)./ <i>The Buyer agrees to purchase the goods with the details above 
                and agrees to perform and comply with the rights and obligations set forth in this Order and the Policies and Terms listed on the website 
                https://eiindustrial.com/ (collectively, the “Policies and Terms”). By signing this Purchase Order, Buyer hereby acknowledges and understands all of the 
                Policies and Terms and fully agrees to each and all terms and conditions specified in the Policies and Terms and the amending Appendices (if any).</i>
                </li>
            </ul>
            <table class="table">
                <tbody>
                    <tr>
                        <td class="width50 borderTopNone mb100">
                            <strong style="font-size: 14px;">BÊN MUA/ BUYER:</strong>
                        </td>
                        <td class="width50 borderTopNone mb100">
                            <strong style="font-size: 14p;">BÊN BÁN/ SELLER: <br />CÔNG TY
                                CỔ
                                PHẦN EI INDUSTRIAL </strong>
                        </td>
                    </tr>
                    <tr>
                        <td class="width50 borderTopNone">
                            <i style="font-size: 14px;">Ngày, tháng/ Date</i>
                        </td>
                        <td class="width50 borderTopNone">
                            <i class="font-size: 14px;">Ngày, tháng/ Date</i>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>

            </div>
        </div>
    </div>
        </div>
    </div>
  </div>
  </body>
  </html>

  `;
    return html;
};

const exportDeliveryRecord = async ({ commit }, value) => {
    const { orderData, orderDetails , basicInfo } = value;
    commit(Type.EXPORT_PO);
    const renderOrderDetails = orderDetails
      .map((od, index) => {
        return ` <tr>
                    <td>${index + 1}</td>
                    <td>${typeof od.products.eic !== 'undefined' && od.products.eic ? od.products.eic : '' }</td>
                    <td>
                        ${od.productName}<br/>
                        ${od.variants && od.variants.length > 0 ? od.variants.map(v => `<span class="productVarName">${v.groupName}: ${v.name}</span>`).join(', ') : ''}
                    </td>
                    <td>${od.productUnit}</td>
                    <td style="">${od.quantity}</td>
                </tr> `;
      })
      .join('');
    const html = `
    <html>
      <head>
      <style>
            .exportPO .quoteManagement {
                  padding-bottom: 3.5rem !important;
                  background: #eee;
              }
      
              .exportPO .logo {
                  height: 50px;
              }
      
              .exportPO .center {
                  display: flex;
                  justify-content: center;
              }
      
              .exportPO .content {
                  min-width: 896px;
                  max-width: 896px;
                  margin-left: 10px;
                  padding: 0
              }
      
              .exportPO .box {
                  border-radius: 25px;
                  background: #fff;
                  padding: 20px;
                  margin-bottom: 20px;
              }
      
              .exportPO .width50 {
                  width: 50%;
                  display: inline-block;
              }
      
              .exportPO .borderBottom {
                  border-bottom: 1px solid #e7eaf3;
                  margin-bottom: 1rem;
              }
      
              .exportPO .borderBottomBold {
                  border-bottom: 5px solid #777;
                  margin-bottom: 1rem;
              }
      
              .exportPO .tableCustomQuotation tr td {
                  font-size: 13px;
                  border-top: none;
                  min-width: 170px;
                  padding: 0rem 0.75rem 0.75rem 0rem;
              }
      
              .exportPO .tableCustomQuotation2 {
                  margin-bottom: 0px;
  
              }
      
              .exportPO .tableCustomQuotation2 tr th {
                  font-size: 13px;
                  padding: 10px 5px;
                  font-weight: normal;
                  border-bottom: 1px solid #dee2e6;
              }
      
              .exportPO .tableCustomQuotation2 tr td {
                  font-size: 13px;
                  border-top: none;
                  padding: 10px 5px;
                  max-width: 200px;
              }
      
              .exportPO .borderTop {
                  border-top: 1px solid #e7eaf3 !important;
                  margin-top: 1rem;
              }
      
              .exportPO .width200 {
                  width: 200px;
              }
      
              .exportPO .pdCustomAddress {
                  padding: 0px 5px !important;
              }
      
              .exportPO .pdCustomAddressFirst {
                  padding: 10px 5px 0 5px !important;
              }
      
              .exportPO .pdCustomAddressLast {
                  padding: 0 5px 10px 5px !important;
              }
      
              .exportPO .boxCheck {
                  display: inline-block;
                  width: 15px;
                  height: 15px;
                  border: 1px solid #000;
                  margin-top: 3px;
              }
      
              .exportPO .rulesStyle {
                  list-style: none;
                  padding-left: 0;
              }
      
              .exportPO .rulesStyle li {
                  font-size: 14px;
                  padding: 2px 0px 2px 0px;
              }
      
              .exportPO  .borderTopNone {
                  border-top: 1px solid transparent !important;
              }
      
              .exportPO .mb100 {
                  margin-bottom: 100px;
              }
          </style>
      </head>
    <body>
    <div class="container-fluid exportPO">
      <div class="row">
          <div class="col-md-12 pt-3">
              <img  src='/images/logo-final-3.png'
                        class="logo"
                        alt="logo"
                    />
              <div class="row">
              <div class="col-md-12">
                  <h2 class="font-weight-bold text-center" style="font-size: 21px">BIÊN BẢN GIAO HÀNG -
                  DELIVERY RECORD
                  </h2>
              </div>
      </div>
      <div class="row">
          <div class="col-md-6 width50">
              <div class="borderBottomBold"></div>
              <div class="responsive">
                  <table class="table tableCustomQuotation">
                      <tbody>
                          <tr>
                              <td>
                                  <strong> BÊN BÁN/SELLER</strong>
                              </td>
                              <td></td>
                          </tr>
                          <tr>
                              <td>
                                  <strong> Tên doanh nghiệp </strong> <br />
                                  <i>Company Name</i>
                              </td>
                              <td><strong>CÔNG TY CỔ PHẦN EI INDUSTRIAL</strong></td>
                          </tr>
                          <tr>
                              <td>
                                  <strong> Địa chỉ </strong> <br />
                                  <i>Address</i>
                              </td>
                              <td>37 Nguyễn Văn Hưởng, Thảo Điền, Thành phố Thủ Đức, Thành phố
                                  Hồ Chí Minh,
                                  Việt Nam</td>
                          </tr>
                          <tr>
                              <td>
                                  <strong> Người liên hệ </strong> <br />
                                  <i>Contact Name</i>
                              </td>
                              <td>${orderData.saleName}</td>
                          </tr>
                          <tr>
                              <td>
                                  <strong> Số điện thoại </strong> <br />
                                  <i>Contact Number</i>
                              </td>
                              <td>${basicInfo.landlinePhone}</td>
                          </tr>
                          <tr>
                              <td>
                                  <strong> Số đơn hàng </strong> <br />
                                  <i>Order Number</i>
                              </td>
                              <td>${orderData.code}</td>
                          </tr>
                          <tr>
                              <td>
                                  <strong> Ngày giao hàng </strong> <br />
                                  <i>Delivery Date</i>
                              </td>
                              <td>${orderData.estimatedDeliveryDate}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
          <div class="col-md-6 width50">
              <div class="borderBottomBold"></div>
              <div class="responsive">
                  <table class="table tableCustomQuotation">
                      <tbody>
                          <tr>
                              <td>
                                  <strong> BÊN MUA/BUYER </strong>
                              </td>
                              <td></td>
                          </tr>
                          <tr>
                              <td>
                                  <strong> Tên doanh nghiệp </strong> <br />
                                  <i>Company Name</i>
                              </td>
                              <td><strong>${orderData && orderData.customer?.customerName }</strong></td>
                          </tr>
                          <tr>
                              <td>
                                  <strong> Địa chỉ </strong> <br />
                                  <i>Address</i>
                              </td>
                              <td>${orderData.fullAddress}</td>
                          </tr>
                          <tr>
                              <td>
                                  <strong> Người liên hệ </strong> <br />
                                  <i>Contact Name </i>
                              </td>
                              <td>No Data</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
    
      <div class="row">
          <div class="col-md-12">
              <table class="table tableCustomQuotation2">
                <tbody>
                    <tr>
                        <td colspan="1" class="borderTop width200">
                            <strong>Đối tác giao hàng</strong><br /> <i>Shipping Partner</i>
                        </td>
                        <td colspan="6" class="borderTop">No Data</td>
                    </tr>
                    <tr>
                        <td colspan="7" class="borderTop borderBottom">
                            <strong>Thông tin sản phẩm / </strong> Good details 
                        </td>
                    </tr>
                </tbody>
             </table>
              <table class="table tableCustomQuotation2">
                  <thead>
                      <tr>
                          <th><strong>STT</strong><br /> <i>No.</i></th>
                          <th><strong>Mã sản phẩm</strong><br /> <i>Product code</i></th>
                          <th><strong>Tên sản phẩm</strong><br /> <i>Product name</i></th>
                          <th><strong>Đơn vị tính</strong><br /> <i>Unit</i></th>
                          <th><strong>Số lượng</strong><br /> <i>Quantity</i></th>
                      </tr>
                  </thead>
                  <tbody>
                  ${renderOrderDetails}
                  </tbody>
              </table>
          </div>
      </div>
      <div class="row">
          <div class="col-md-12">
              <div class="borderBottom"></div>
              <table class="table">
                  <tbody>
                      <tr>
                          <td class="width50 borderTopNone mb100">
                              <strong style="font-size: 14px;">BÊN MUA/ BUYER:</strong>
                          </td>
                          <td class="width50 borderTopNone mb100">
                              <strong style="font-size: 14p;">BÊN BÁN/ SELLER: <br />CÔNG TY
                                  CỔ
                                  PHẦN EI INDUSTRIAL </strong>
                          </td>
                      </tr>
                      <tr>
                          <td class="width50 borderTopNone">
                              <i style="font-size: 14px;">Ngày, tháng/ Date</i>
                          </td>
                          <td class="width50 borderTopNone">
                              <i class="font-size: 14px;">Ngày, tháng/ Date</i>
                          </td>
                      </tr>
                  </tbody>
              </table>
              </div>
  
              </div>
          </div>
      </div>
          </div>
      </div>
    </div>
    </body>
    </html>
  
    `;
    return html;
  };

const saveEditOrders = async ({ commit }, addData) => {
    const { oldData, newData } = addData;
    const { id } = newData;
    const data = _convertData(newData);
    if (id) {
      // Convert data
      return service
        .rest('order/setList', { items: { $update: [data] } })
        .then(rlt => {
          const { items } = rlt;
          const { $rltUpdate } = items;
          // Commit to news state
          commit(Type.SET_ORDER_UPDATE, $rltUpdate[0]);
          return true;
        })
        .catch(e => {
          console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
          return false;
        });
    } else {
      // Commit to news state
      commit(Type.SET_PARTNER_UPDATE_LOCAL, { oldData, newData: data });
      return true;
    }
};

const updateOrderStatusSupplier = async ({
    commit
}, data) => {
    const updateData = _convertData(data);
    return service
        .rest('orderSupplier/setList', {
            items: {
                $update: [updateData]
            }
        })
        .then(rlt => {
            const {
                items
            } = rlt;
            const {
                $rltUpdate
            } = items;
            commit(Type.SET_UPDATE_ORDER_SUPPLIER, {
                rltUpdate: $rltUpdate[0]
            });
            return true;
        })
        .catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
            return false;
        });
};;

const exportSupplierPO = async (_, value) => {
    const {
        orderData,
        supplierInfo
    } = value;
    const defaultSupplierBank = supplierInfo.supInfo.supBanks.find(sup => sup.isDefault === true);
    let totalPrice = 0;
    const vatAmount = supplierInfo.orderDetailSuppliers.reduce((acc, ele) => acc + ele.price * ele.quantity * (ele.taxRate / 100), 0);
    const renderOrderDetails = supplierInfo.orderDetailSuppliers
        .map((od, index) => {
            totalPrice = totalPrice + od.price * od.quantity;
            return ` <tr>
                  <td>${index + 1}</td>
                  <td style="word-wrap:break-word">${od.productModel}</td>
                  <td style="word-wrap:break-word">
                      ${od.productName}<br/>
                      ${od.variants && od.variants.length > 0 ? od.variants.map(v => `<span class="productVarName">${v.groupName}: ${v.name}</span>`).join(', ') : ''}
                  </td>
                  <td>${od.productUnit}</td>
                  <td>${od.quantity}</td>
                  <td>
                    ${formattedNumber(Math.round(od.price))} đ
                  </td>
                  <td class="text-center">
                    ${od.taxRate} %
                  </td>
                  <td class="text-right">
                    ${formattedNumber(Math.round(od.price * od.quantity))} đ
                  </td>
              </tr> `;
        })
        .join('');

    const html = `
  <html>
    <head>
    <style>
          .exportPO .quoteManagement {
                padding-bottom: 3.5rem !important;
                background: #eee;
            }
    
            .exportPO .logo {
                height: 50px;
            }
    
            .exportPO .center {
                display: flex;
                justify-content: center;
            }
    
            .exportPO .content {
                min-width: 1000px;
                max-width: 1000px;
                margin-left: 10px;
                padding: 0
            }
    
            .exportPO .box {
                border-radius: 25px;
                background: #fff;
                padding: 20px;
                margin-bottom: 20px;
            }
    
            .exportPO .width50 {
                width: 50%;
                display: inline-block;
            }
    
            .exportPO .borderBottom {
                border-bottom: 1px solid #e7eaf3;
                margin-bottom: 1rem;
            }
    
            .exportPO .borderBottomBold {
                border-bottom: 5px solid #777;
                margin-bottom: 1rem;
            }
    
            .exportPO .tableCustomQuotation tr td {
                font-size: 13px;
                border-top: none;
                min-width: 170px;
                padding: 0rem 0.75rem 0.75rem 0rem;
            }
    
            .exportPO .tableCustomQuotation2 {
                margin-bottom: 0px;
            }
    
            .exportPO .tableCustomQuotation2 tr th {
                font-size: 13px;
                padding: 10px 5px;
                font-weight: normal;
                border-bottom: 1px solid #dee2e6;
            }
    
            .exportPO .tableCustomQuotation2 tr td {
                font-size: 13px;
                border-top: none;
                padding: 10px 5px;
                max-width: 200px;
            }
    
            .exportPO .borderTop {
                border-top: 1px solid #e7eaf3 !important;
                margin-top: 1rem;
            }
    
            .exportPO .width200 {
                width: 200px;
            }
    
            .exportPO .pdCustomAddress {
                padding: 0px 5px !important;
            }
    
            .exportPO .pdCustomAddressFirst {
                padding: 10px 5px 0 5px !important;
            }
    
            .exportPO .pdCustomAddressLast {
                padding: 0 5px 10px 5px !important;
            }
    
            .exportPO .boxCheck {
                display: inline-block;
                width: 15px;
                height: 15px;
                border: 1px solid #000;
                margin-top: 3px;
            }
    
            .exportPO .rulesStyle {
                list-style: none;
                padding-left: 0;
            }
    
            .exportPO .rulesStyle li {
                font-size: 14px;
                padding: 2px 0px 2px 0px;
            }
    
            .exportPO  .borderTopNone {
                border-top: 1px solid transparent !important;
            }
    
            .exportPO .mb100 {
                margin-bottom: 100px;
            }
        </style>
    </head>
  <body>
  <div class="container-fluid exportPO">
    <div class="row">
        <div class="col-md-12 pt-3">
        <img  src='/images/logo-final-3.png'
                      class="logo"
                      alt="logo"
                  />
        <div class="row">
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <p class="mb-0" style="font-size: 14px;"><strong>Đơn đặt hàng số/ PO number:
                </strong> ${orderData.code}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 width50">
            <div class="borderBottomBold"></div>
            <div class="responsive">
                <table class="table tableCustomQuotation">
                    <tbody>
                        <tr>
                            <td>
                            <strong> BÊN BÁN/SELLER </strong>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                            <strong> Tên doanh nghiệp </strong> <br />
                            <i>Company Name</i>
                            </td>
                            <td><strong>CÔNG TY CỔ PHẦN EI INDUSTRIAL</strong></td>
                        </tr>
                        <tr>
                            <td>
                            <strong> Địa chỉ </strong> <br />
                            <i>Address</i>
                            </td>
                            <td>
                            37 Nguyễn Văn Hưởng, Thảo Điền, Thành phố Thủ Đức, Thành
                            phố Hồ Chí Minh, Việt Nam
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <strong> Mã số thuế </strong> <br />
                            <i>Tax Identification Number </i>
                            </td>
                            <td>0314878148</td>
                        </tr>
                        <tr>
                            <td>
                            <strong> Người đại diện </strong> <br />
                            <i>Legal Representative </i>
                            </td>
                            <td>Mr. Hồ Phi Ân</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-6 width50">
            <div class="borderBottomBold"></div>
            <div class="responsive">
            <table class="table tableCustomQuotation">
            <tbody>
                <tr>
                    <td>
                        <strong> BÊN BÁN/SELLER</strong>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <strong> Tên doanh nghiệp </strong> <br />
                        <i>Company Name</i>
                    </td>
                    <td><strong>${(supplierInfo && supplierInfo.supInfo.name) || 'No Data'}</strong></td>
                </tr>
                <tr>
                    <td>
                        <strong> Địa chỉ </strong> <br />
                        <i>Address</i>
                    </td>
                    <td>${(supplierInfo && supplierInfo.supInfo.address) || 'No Data'}</td>
                </tr>
                <tr>
                    <td>
                        <strong> Mã số thuế </strong> <br />
                        <i>Tax Identification Number </i>
                    </td>
                    <td>${(supplierInfo && supplierInfo.supInfo.taxCode) || 'No Data'} </td>
                </tr>
                <tr>
                    <td>
                        <strong> Người đại diện </strong> <br />
                        <i>Legal Representative </i>
                    </td>
                    <td>No Data</td>
                </tr>
                <tr>
                    <td>
                        <strong> Email nhận hoá đơn điện tử </strong> <br />
                        <i>Email receive e_invoice</i>
                        </td>
                        <td>${(supplierInfo && supplierInfo.supInfo.email) || 'No Data'}</td>
                    </tr>
            </tbody>
        </table>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-12" style="font-size: 14px;">
            <strong>Đồng ý mua/</strong> Agree to buy: <br />
            <strong>Thông tin sản phẩm/</strong> Good details:
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table tableCustomQuotation2">
                <thead>
                    <tr>
                        <th><strong>STT</strong><br /> <i>No.</i></th>
                        <th><strong>Mã sản phẩm</strong><br /> <i>Product code</i></th>
                        <th><strong>Tên sản phẩm</strong><br /> <i>Product name</i></th>
                        <th><strong>Đơn vị tính</strong><br /> <i>Unit</i></th>
                        <th><strong>Số lượng</strong><br /> <i>Quantity</i></th>
                        <th><strong>Đơn giá</strong><br /> <i>Unit price</i></th>
                        <th class="text-right"><strong>Thuế suất</strong><br />
                            <i>Tax Rate</i>
                        </th>
                        <th class="text-right"><strong>Thành tiền</strong><br />
                            <i>Total Amount</i>
                        </th> 
                    </tr>
                </thead>
                <tbody>
                ${renderOrderDetails}
                </tbody>
            </table>
            <table class="table tableCustomQuotation2">
                <tbody>
                    <tr>
                        <td class="borderTop width200" colspan="6">
                            <strong>Cộng tiền hàng/ Sub total</strong><br />
                            <strong>Cộng tiền thuế GTGT/ VAT amount</strong><br />
                            <strong>Tỏng cộng tiền thanh toán/ Total amount</strong><br />
                        </td>
                        <td class="borderTop text-right">
                            <span>
                                ${formattedNumber(Math.round(totalPrice))} đ
                            </span><br />
                            <span>${formattedNumber(Math.round(vatAmount))} đ</span><br />
                            <span><strong>
                            ${formattedNumber(Math.round(totalPrice + vatAmount))} đ
                                </strong></span><br />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="borderTop width200">
                            <strong>Bằng chữ</strong><br /> <i>Total in words</i>
                        </td>
                        <td colspan="6" class="borderTop">${convertNumberToWord(Math.round(totalPrice + vatAmount))} việt nam đồng</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="borderTop pdCustomAddressFirst width200">
                            <strong>Địa chỉ </strong><br /> <i>Address</i>
                        </td>
                        <td colspan="6" class="borderTop pdCustomAddressFirst">${orderData && orderData.fullAddress}</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="pdCustomAddressLast width200">
                            <strong>Ngày giao hàng </strong><br /> <i>Delivery date</i>
                        </td>
                        <td colSpan="6" class="pdCustomAddressLast">
                          ${orderData && orderData.estimatedDeliveryDate && formatDate(orderData.estimatedDeliveryDate)}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="borderTop width200 pdCustomAddressFirst">
                            <strong>Thanh toán</strong><br />
                            <i>Payment</i>
                        </td>
                        <td colspan="6" class="borderTop pdCustomAddressFirst">Chuyển khoản
                            theo thông tin dưới đây
                            <br /><i>Wire transfer as follow information </i>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" class="pdCustomAddress width200">
                            <strong>Tên tài khoản </strong><br /> <i>Benrficiary</i>
                        </td>
                        <td colspan="3" class="pdCustomAddress">${(defaultSupplierBank && defaultSupplierBank.accountName) || 'No Data'}</td>
                    </tr>
                    <tr>
                        <td colspan="4" class="pdCustomAddress width200">
                            <strong>Số tài khoản </strong><br /> <i>Benrficiary account</i>
                        </td>
                        <td colspan="3" class="pdCustomAddress">${(defaultSupplierBank && defaultSupplierBank.accountNumber) || 'No Data'}</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="pdCustomAddress width200">
                            <strong>Tên ngân hàng </strong><br /> <i>Bank</i>
                        </td>
                        <td colspan="6" class="pdCustomAddress">${(defaultSupplierBank && defaultSupplierBank.bankName) || 'No Data'}</td>
                    </tr>
                    <tr>
                        <td colSpan="1" class="pdCustomAddressLast width200">
                            <strong>Chi nhánh </strong><br /> <i>Branch</i>
                        </td>
                        <td colspan="6" class="pdCustomAddress">${(defaultSupplierBank && defaultSupplierBank.bankBranch) || 'No Data'}</td>
                    </tr>
                    <tr>
                        <td colSpan="1" class="pdCustomAddressFirst borderTop width200">
                            <strong>Bảo hành/</strong> <i>Warranty</i>
                        </td>
                        <td colspan="6" class="pdCustomAddressFirst borderTop"><span
                                class="boxCheck"></span></td>
                    </tr>
                    <tr>
                        <td colspan="5" style="padding-top: 10px"><strong>Thời hạn bảo
                                hành:</strong>Theo chính sách của nhà cung cấp<br /> <i>Warranty period</i></td>
                        <td colspan="1" style="padding-top: 10px"><strong>Kích
                                thước:</strong><br /> <i>Dimention</i></td>
                        <td colspan="1" style="padding-top: 10px"><strong>Cân
                                nặng:</strong><br /> <i>Weight</i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="borderBottom"></div>
            <ul class="rulesStyle">
                <li style="margin-bottom: 10px;"><strong style="font-size: 14px;">ĐIỀU KHOẢN/ TERM</strong></li>
                <li style="margin-bottom: 10px;"> - Hóa đơn GTGT sẽ được gởi vào địa chỉ email đăng ký sau khi đơn hàng đã giao thành công./ <i>The VAT invoice will be sent to the registered email after the Purchase Order has been successfully delivered.</i></li>
                <li style="margin-bottom: 10px;"> - Nếu sản phẩm có dấu hiệu hư hỏng/ bể vỡ hoặc không đúng với thông tin trên website, bạn vui lòng liên hệ với EI trong vòng 24 giờ 
                kể từ thời điểm nhận hàng để được hỗ trợ. Vui lòng giữ nguyên vẹn biên nhận bán hàng được dán trên thùng hàng, hóa đơn (nếu có) và hộp sản phẩm để đổi 
                trả hàng hoặc bảo hành khi cần./ <i>If the product shows signs of damage / breakage or does not match the information on the website, please contact 
                EI within 24 hours from the time of receiving the goods for support. Please keep the sales receipt affixed on the box, invoice (if any) and product box 
                intact for exchange or warranty when needed.</i>
                <li> - Bên Mua đồng ý mua hàng hóa với thông tin chi tiết ở trên và đồng ý thực hiện, tuân thủ các quyền và nghĩa vụ được quy định trong Đơn Đặt Hàng này 
                và Các Chính Sách và Điều Khoản niêm yết tại website https://eiindustrial.com/ (sau đây được gọi chung là “Các Chính Sách và Điều Khoản”). Bằng việc ký 
                kết vào Đơn Đặt Hàng này, Bên Mua tại đây xác nhận và hiểu rõ toàn bộ Các Chính Sách và Điều Khoản và hoàn toàn đồng ý với mỗi và mọi điều khoản và điều 
                kiện được quy định tại Các Chính Sách và Điều Khoản và các phụ lục điều chỉnh (nếu có)./ <i>The Buyer agrees to purchase the goods with the details above 
                and agrees to perform and comply with the rights and obligations set forth in this Order and the Policies and Terms listed on the website 
                https://eiindustrial.com/ (collectively, the “Policies and Terms”). By signing this Purchase Order, Buyer hereby acknowledges and understands all of the 
                Policies and Terms and fully agrees to each and all terms and conditions specified in the Policies and Terms and the amending Appendices (if any).</i>
                </li>
            </ul>
            <table class="table">
                <tbody>
                    <tr>
                        <td class="width50 borderTopNone mb100">
                            <strong style="font-size: 14px;">BÊN MUA/ BUYER:</strong>
                        </td>
                        <td class="width50 borderTopNone mb100">
                            <strong style="font-size: 14p;">BÊN BÁN/ SELLER: <br />CÔNG TY
                                CỔ
                                PHẦN EI INDUSTRIAL </strong>
                        </td>
                    </tr>
                    <tr>
                        <td class="width50 borderTopNone">
                            <i style="font-size: 14px;">Ngày, tháng/ Date</i>
                        </td>
                        <td class="width50 borderTopNone">
                            <i class="font-size: 14px;">Ngày, tháng/ Date</i>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>

            </div>
        </div>
    </div>
        </div>
    </div>
  </div>
  </body>
  </html>

  `;
    return html;
};

const sendMailConfirmToSupplier = async ({
    commit
}, value) => {
    console.log(value);
    const {
        listEmail,
        orderData,
        supplierInfo,
        mailTemplateCode,
        note = '',
        title
    } = value;
    let defaultSupplierBank = supplierInfo.supInfo.supBanks && supplierInfo.supInfo.supBanks.length > 0 ? supplierInfo.supInfo.supBanks[0] : null;
    if (supplierInfo.supInfo.supBanks && supplierInfo.supInfo.supBanks.length > 0 && supplierInfo.supInfo.supBanks.find(sup => sup.isDefault === true)) 
            defaultSupplierBank = supplierInfo.supInfo.supBanks.find(sup => sup.isDefault === true);
    const addressWarehouseSupplier = supplierInfo.supInfo && supplierInfo.supInfo.supplierAddress || '';
    let totalPricePO = 0;
    let vatPrice = 0;
    const renderOrderDetails = supplierInfo.orderDetailSuppliers
        .map((od, index) => {
            totalPricePO += od.price * od.quantity;
            vatPrice += od.price * od.quantity * (od.taxRate / 100);
            return ` <tr>
                    <td class="text-center">${index + 1}</td>
                    <td class="text-center">${od.products.eic}</td>
                    <td class="text-center"> 
                        ${od.productName}<br/>
                        ${od.variants && od.variants.length > 0 ? od.variants.map(v => `<span class="productVarName">${v.groupName}: ${v.name}</span>`).join(', ') : ''}
                    </td>
                    <td class="text-center">
                        ${od.productUnit}
                    </td>
                    <td class="text-center">${od.quantity}</td>
                    <td class="text-center">
                        ${formattedNumber(Math.round(od.price))} đ
                    </td>
                    <td class="text-center">
                        ${od.taxRate} %
                    </td>
                    <td class="text-right text-center">
                        ${formattedNumber(Math.round(od.price * od.quantity))} đ
                    </td>
                </tr> `;
        })
        .join('');
    
    const renderOrderDetailsInDeliveryRecord = supplierInfo.orderDetailSuppliers
        .map((od, index) => {
            return ` <tr>
                    <td class="text-center">${index + 1}</td>
                    <td class="text-center">${typeof od.products.eic !== 'undefined' && od.products.eic ? od.products.eic : ''}</td>
                    <td class="text-center"> 
                        ${od.productName}<br/>
                        ${od.variants && od.variants.length > 0 ? od.variants.map(v => `<span class="productVarName">${v.groupName}: ${v.name}</span>`).join(', ') : ''}
                    </td>
                    <td class="text-center">
                        ${od.productUnit}
                    </td>
                    <td class="text-center">${od.quantity}</td>
                </tr> `;
        })
        .join('');

    const html = `
    <html>
        <head>
        <style>
            .exportPO .quoteManagement {
                    padding-bottom: 3.5rem !important;
                    background: #eee;
                }
        
                .exportPO .logo {
                    height: 50px;
                }
        
                .exportPO .center {
                    display: flex;
                    justify-content: center;
                }
        
                .exportPO .content {
                    min-width: 1000px;
                    max-width: 1000px;
                    margin-left: 10px;
                    padding: 0
                }
        
                .exportPO .box {
                    border-radius: 25px;
                    background: #fff;
                    padding: 20px;
                    margin-bottom: 20px;
                }
        
                .exportPO .width50 {
                    width: 50%;
                    display: inline-block;
                }

                .exportPO .width49 {
                    width: 49%;
                    display: inline-block;
                }
        
                .exportPO .borderBottom {
                    border-bottom: 1px solid #e7eaf3;
                    margin-bottom: 1rem;
                }
        
                .exportPO .borderBottomBold {
                    border-bottom: 5px solid #777;
                    margin-bottom: 1rem;
                }
        
                .exportPO .tableCustomQuotation tr td {
                    font-size: 13px;
                    border-top: none;
                    min-width: 170px;
                    padding: 0rem 0.75rem 0.75rem 0rem;
                }
        
                .exportPO .tableCustomQuotation2 {
                    margin-bottom: 0px;
                    width: 100%;
                }
        
                .exportPO .tableCustomQuotation2 tr th {
                    font-size: 13px;
                    padding: 10px 5px;
                    font-weight: normal;
                    border-bottom: 1px solid #dee2e6;
                }
        
                .exportPO .tableCustomQuotation2 tr td {
                    font-size: 13px;
                    border-top: none;
                    padding: 10px 5px;
                }
        
                .exportPO .borderTop {
                    border-top: 1px solid #e7eaf3 !important;
                    margin-top: 1rem;
                }
        
                .exportPO .width200 {
                    width: 200px;
                }
        
                .exportPO .pdCustomAddress {
                    padding: 0px 5px !important;
                }
        
                .exportPO .pdCustomAddressFirst {
                    padding: 10px 5px 0 5px !important;
                }
        
                .exportPO .pdCustomAddressLast {
                    padding: 0 5px 10px 5px !important;
                }
        
                .exportPO .boxCheck {
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    border: 1px solid #000;
                    margin-top: 3px;
                }
        
                .exportPO .rulesStyle {
                    list-style: none;
                    padding-left: 0;
                }
        
                .exportPO .rulesStyle li {
                    font-size: 14px;
                    padding: 2px 0px 2px 0px;
                }
        
                .exportPO  .borderTopNone {
                    border-top: 1px solid transparent !important;
                }
        
                .exportPO .mb100 {
                    margin-bottom: 100px;
                }
                .exportPO .col-md-12 {
                    width: 100%;
                }
                .exportPO .row {
                    width: 100%;
                }
                .exportPO .col-md-6 {
                    width: 45%;
                }
                .table {
                    width: 100%
                }
                .exportPO .text-center {
                    text-align: center;
                }
            </style>
        </head>
    <body>
    <div class="container-fluid exportPO">
        <div class="row">
            <div style="width: 100%; margin-top: 20px">
                <img  src='https://ei-dev-store.s3.amazonaws.com/Global/1/1/logo-final-3.png'
                        class="logo"
                        alt="logo"
                    />
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h2 class="font-weight-bold text-center" style="font-size: 21px">ĐƠN ĐẶT HÀNG -
                PURCHASE ORDER
                </h2>
            </div>
        </div>
        <div style="margin-top: 20px">
            <div style="width: 100%">
                <p style="font-size: 14px;"><strong>Đơn đặt hàng số/ PO number:
                    </strong> ${orderData.code}</p>
            </div>
        </div>
        <div style="display: flex;  justify-content: space-between">
        <div class="col-md-6">
            <div class="borderBottomBold"></div>
            <div class="responsive">
                <table class="table tableCustomQuotation">
                    <tbody>
                        <tr>
                            <td>
                            <strong> BÊN MUA/BUYER </strong>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                            <strong> Tên doanh nghiệp </strong> <br />
                            <i>Company Name</i>
                            </td>
                            <td><strong>CÔNG TY CỔ PHẦN EI INDUSTRIAL</strong></td>
                        </tr>
                        <tr>
                            <td>
                            <strong> Địa chỉ </strong> <br />
                            <i>Address</i>
                            </td>
                            <td>
                            37 Nguyễn Văn Hưởng, Thảo Điền, Thành phố Thủ Đức, Thành
                            phố Hồ Chí Minh, Việt Nam
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <strong> Mã số thuế </strong> <br />
                            <i>Tax Identification Number </i>
                            </td>
                            <td>0314878148</td>
                        </tr>
                        <tr>
                            <td>
                            <strong> Người đại diện </strong> <br />
                            <i>Legal Representative </i>
                            </td>
                            <td>Mr. Hồ Phi Ân</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-6">
            <div class="borderBottomBold"></div>
            <div class="responsive">
            <table class="table tableCustomQuotation">
            <tbody>
                <tr>
                    <td>
                        <strong> BÊN BÁN/SELLER</strong>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <strong> Tên doanh nghiệp </strong> <br />
                        <i>Company Name</i>
                    </td>
                    <td><strong>${(supplierInfo && supplierInfo.supInfo.name) || 'No Data'}</strong></td>
                </tr>
                <tr>
                    <td>
                        <strong> Địa chỉ </strong> <br />
                        <i>Address</i>
                    </td>
                    <td>${(supplierInfo && supplierInfo.supInfo.address) || 'No Data'}</td>
                </tr>
                <tr>
                    <td>
                        <strong> Mã số thuế </strong> <br />
                        <i>Tax Identification Number </i>
                    </td>
                    <td>${(supplierInfo && supplierInfo.supInfo.taxCode) || 'No Data'} </td>
                </tr>
                <tr>
                    <td>
                        <strong> Người đại diện </strong> <br />
                        <i>Legal Representative </i>
                    </td>
                    <td>No Data</td>
                </tr>
                <tr>
                    <td>
                        <strong> Email nhận hoá đơn điện tử </strong> <br />
                        <i>Email receive e_invoice</i>
                        </td>
                        <td>${(supplierInfo && supplierInfo.supInfo.email) || 'No Data'}</td>
                    </tr>
            </tbody>
        </table>
            </div>
        </div>
    </div>
        <div class="row mb-3">
            <div class="col-md-12" style="font-size: 14px;">
                <strong>Đồng ý mua/</strong> Agree to buy: <br />
                <strong>Thông tin sản phẩm/</strong> Good details:
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <table class="table tableCustomQuotation2">
                    <thead>
                        <tr>
                            <th><strong>STT</strong><br /> <i>No.</i></th>
                            <th><strong>Mã sản phẩm</strong><br /> <i>Product code</i></th>
                            <th><strong>Tên sản phẩm</strong><br /> <i>Product name</i></th>
                            <th><strong>Đơn vị tính</strong><br /> <i>Unit</i></th>
                            <th><strong>Số lượng</strong><br /> <i>Quantity</i></th>
                            <th><strong>Đơn giá</strong><br /> <i>Unit price</i></th>
                            <th><strong>Thuế suất</strong><br /> <i>Tax rate</i></th>
                            <th class="text-right"><strong>Thành tiền (VND)</strong><br />
                                <i>Total amount</i>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    ${renderOrderDetails}
                    </tbody>
                </table>
                <table class="table tableCustomQuotation2">
                    <tbody>
                        <tr>
                            <td class="borderTop" colspan="6">
                                <strong>Tạm tính/ Sub total</strong><br />
                                <strong>Thuế GTGT/ VAT amount</strong><br />
                                <strong>Tổng cộng/ Total amount</strong><br />
                            </td>
                            <td class="borderTop text-right">
                                <span>
                                    ${formattedNumber(Math.round(totalPricePO))} đ
                                </span><br />
                                <span>${formattedNumber(Math.round(vatPrice))} đ</span><br />
                                <span><strong>
                                ${formattedNumber(Math.round(totalPricePO + vatPrice))} đ
                                    </strong></span><br />
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="borderTop width200">
                                <strong>Bằng chữ</strong><br /> <i>Total in words</i>
                            </td>
                            <td colspan="6" class="borderTop">${convertNumberToWord(Math.round(totalPricePO + vatPrice))} việt nam đồng</td>
                        </tr>
                        <tr>
                            <td colspan="1" class="borderTop pdCustomAddressFirst width200">
                                <strong>Nhận hàng tại </strong><br /> <i>Address</i>
                            </td>
                            <td colspan="6" class="borderTop pdCustomAddressFirst">
                            ${'Kho hàng của ' + (supplierInfo && supplierInfo.supInfo && supplierInfo.supInfo.name) || 'nhà bán'}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="borderTop pdCustomAddressFirst width200">
                                <strong>Địa chỉ </strong><br /> <i>Address</i>
                            </td>
                            <td colspan="6" class="borderTop pdCustomAddressFirst">${addressWarehouseSupplier}</td>
                        </tr>
                        <tr>
                            <td colspan="1" class="borderTop pdCustomAddressFirst width200">
                                <strong>Ngày giao hàng dự kiến cho EI</strong><br /> <i>Address</i>
                            </td>
                            <td colspan="6" class="borderTop pdCustomAddressFirst">
                            ${supplierInfo && supplierInfo.supInfo.estimatedDeliveryDate && formatDate(supplierInfo.supInfo.estimatedDeliveryDate) || 'No Data'}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="borderTop pdCustomAddressLast width200">
                                <strong>Ngày EI giao hàng cho khách</strong><br /> <i>Delivery date</i>
                            </td>
                            <td colSpan="6" class="borderTop pdCustomAddressLast">
                            ${supplierInfo && supplierInfo.supInfo.deliveryDateCustomer && formatDate(supplierInfo.supInfo.deliveryDateCustomer) || 'No Data'}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" class="borderTop width200 pdCustomAddressFirst">
                                <strong>Thanh toán</strong><br />
                                <i>Payment</i>
                            </td>
                            <td colspan="6" class="borderTop pdCustomAddressFirst">Chuyển khoản
                                theo thông tin dưới đây
                                <br /><i>Wire transfer as follow information </i>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" class="pdCustomAddress width200">
                                <strong>Tên tài khoản </strong><br /> <i>Benrficiary</i>
                            </td>
                            <td colspan="3" class="pdCustomAddress">${(defaultSupplierBank && defaultSupplierBank.accountName) || 'No Data'}</td>
                        </tr>
                        <tr>
                            <td colspan="4" class="pdCustomAddress width200">
                                <strong>Số tài khoản </strong><br /> <i>Benrficiary account</i>
                            </td>
                            <td colspan="3" class="pdCustomAddress">${(defaultSupplierBank && defaultSupplierBank.accountNumber) || 'No Data'}</td>
                        </tr>
                        <tr>
                            <td colspan="1" class="pdCustomAddress width200">
                                <strong>Tên ngân hàng </strong><br /> <i>Bank</i>
                            </td>
                            <td colspan="6" class="pdCustomAddress">${(defaultSupplierBank && defaultSupplierBank.bankName) || 'No Data'}</td>
                        </tr>
                        <tr>
                            <td colSpan="1" class="pdCustomAddressLast width200">
                                <strong>Chi nhánh </strong><br /> <i>Branch</i>
                            </td>
                            <td colspan="6" class="pdCustomAddress">${(defaultSupplierBank && defaultSupplierBank.bankBranch) || 'No Data'}</td>
                        </tr>
                        <tr>
                            <td colSpan="1" class="pdCustomAddressFirst borderTop width200">
                                <strong>Bảo hành/</strong> <i>Warranty</i>
                            </td>
                            <td colspan="6" class="pdCustomAddressFirst borderTop"><span
                                    class="boxCheck"></span></td>
                        </tr>
                        <tr>
                            <td colspan="5" style="padding-top: 10px"><strong>Thời hạn bảo
                                    hành:</strong>Theo chính sách của nhà cung cấp<br /> <i>Warranty period</i></td>
                            <td colspan="1" style="padding-top: 10px"><strong>Kích
                                    thước:</strong><br /> <i>Dimention</i></td>
                            <td colspan="1" style="padding-top: 10px"><strong>Cân
                                    nặng:</strong><br /> <i>Weight</i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="borderBottom"></div>
                <ul class="rulesStyle">
                    <li style="margin-bottom: 10px;"><strong style="font-size: 14px;">ĐIỀU KHOẢN/ TERM</strong></li>
                    <li style="margin-bottom: 10px;"> - Hóa đơn GTGT sẽ được gởi vào địa chỉ email đăng ký sau khi đơn hàng đã giao thành công./ <i>The VAT invoice will be sent to the registered email after the Purchase Order has been successfully delivered.</i></li>
                    <li style="margin-bottom: 10px;"> - Nếu sản phẩm có dấu hiệu hư hỏng/ bể vỡ hoặc không đúng với thông tin trên website, bạn vui lòng liên hệ với EI trong vòng 24 giờ 
                    kể từ thời điểm nhận hàng để được hỗ trợ. Vui lòng giữ nguyên vẹn biên nhận bán hàng được dán trên thùng hàng, hóa đơn (nếu có) và hộp sản phẩm để đổi 
                    trả hàng hoặc bảo hành khi cần./ <i>If the product shows signs of damage / breakage or does not match the information on the website, please contact 
                    EI within 24 hours from the time of receiving the goods for support. Please keep the sales receipt affixed on the box, invoice (if any) and product box 
                    intact for exchange or warranty when needed.</i>
                    <li> - Bên Mua đồng ý mua hàng hóa với thông tin chi tiết ở trên và đồng ý thực hiện, tuân thủ các quyền và nghĩa vụ được quy định trong Đơn Đặt Hàng này 
                    và Các Chính Sách và Điều Khoản niêm yết tại website https://eiindustrial.com/ (sau đây được gọi chung là “Các Chính Sách và Điều Khoản”). Bằng việc ký 
                    kết vào Đơn Đặt Hàng này, Bên Mua tại đây xác nhận và hiểu rõ toàn bộ Các Chính Sách và Điều Khoản và hoàn toàn đồng ý với mỗi và mọi điều khoản và điều 
                    kiện được quy định tại Các Chính Sách và Điều Khoản và các phụ lục điều chỉnh (nếu có)./ <i>The Buyer agrees to purchase the goods with the details above 
                    and agrees to perform and comply with the rights and obligations set forth in this Order and the Policies and Terms listed on the website 
                    https://eiindustrial.com/ (collectively, the “Policies and Terms”). By signing this Purchase Order, Buyer hereby acknowledges and understands all of the 
                    Policies and Terms and fully agrees to each and all terms and conditions specified in the Policies and Terms and the amending Appendices (if any).</i>
                    </li>
                </ul>
                <table class="table">
                    <tbody>
                        <tr>
                            <td class="width49 borderTopNone mb100">
                                <strong style="font-size: 14px;">BÊN MUA/ BUYER:</strong>
                            </td>
                            <td class="width49 borderTopNone mb100">
                                <strong style="font-size: 14p;">BÊN BÁN/ SELLER: <br />CÔNG TY
                                    CỔ
                                    PHẦN EI INDUSTRIAL </strong>
                            </td>
                        </tr>
                        <tr>
                            <td class="width49 borderTopNone">
                                <i style="font-size: 14px;">Ngày, tháng/ Date</i>
                            </td>
                            <td class="width49 borderTopNone">
                                <i class="font-size: 14px;">Ngày, tháng/ Date</i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>

                </div>
            </div>
        </div>
            </div>
        </div>
    </div>
    </body>
    </html>

    `;

    const deliveryOrderHtml = `
    <html>
        <head>
        <style>
            .exportPO .quoteManagement {
                    padding-bottom: 3.5rem !important;
                    background: #eee;
                }
        
                .exportPO .logo {
                    height: 50px;
                }
        
                .exportPO .center {
                    display: flex;
                    justify-content: center;
                }
        
                .exportPO .content {
                    min-width: 1000px;
                    max-width: 1000px;
                    margin-left: 10px;
                    padding: 0
                }
        
                .exportPO .box {
                    border-radius: 25px;
                    background: #fff;
                    padding: 20px;
                    margin-bottom: 20px;
                }
        
                .exportPO .width50 {
                    width: 50%;
                    display: inline-block;
                }
        
                .exportPO .borderBottom {
                    border-bottom: 1px solid #e7eaf3;
                    margin-bottom: 1rem;
                }
        
                .exportPO .borderBottomBold {
                    border-bottom: 5px solid #777;
                    margin-bottom: 1rem;
                }
        
                .exportPO .tableCustomQuotation tr td {
                    font-size: 13px;
                    border-top: none;
                    min-width: 170px;
                    padding: 0rem 0.75rem 0.75rem 0rem;
                }
        
                .exportPO .tableCustomQuotation2 {
                    margin-bottom: 0px;
                    width: 100%;
                }
        
                .exportPO .tableCustomQuotation2 tr th {
                    font-size: 13px;
                    padding: 10px 5px;
                    font-weight: normal;
                    border-bottom: 1px solid #dee2e6;
                }
        
                .exportPO .tableCustomQuotation2 tr td {
                    font-size: 13px;
                    border-top: none;
                    padding: 10px 5px;
                }
        
                .exportPO .borderTop {
                    border-top: 1px solid #e7eaf3 !important;
                    margin-top: 1rem;
                }
        
                .exportPO .width200 {
                    width: 200px;
                }
        
                .exportPO .pdCustomAddress {
                    padding: 0px 5px !important;
                }
        
                .exportPO .pdCustomAddressFirst {
                    padding: 10px 5px 0 5px !important;
                }
        
                .exportPO .pdCustomAddressLast {
                    padding: 0 5px 10px 5px !important;
                }
        
                .exportPO .boxCheck {
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    border: 1px solid #000;
                    margin-top: 3px;
                }
        
                .exportPO .rulesStyle {
                    list-style: none;
                    padding-left: 0;
                }
        
                .exportPO .rulesStyle li {
                    font-size: 14px;
                    padding: 2px 0px 2px 0px;
                }
        
                .exportPO  .borderTopNone {
                    border-top: 1px solid transparent !important;
                }
        
                .exportPO .mb100 {
                    margin-bottom: 100px;
                }
                .exportPO .col-md-12 {
                    width: 100%;
                }
                .exportPO .row {
                    width: 100%;
                }
                .exportPO .col-md-6 {
                    width: 45%;
                }
                .table {
                    width: 100%
                }
                .exportPO .text-center {
                    text-align: center;
                }
                .exportPO .width49 {
                    width: 49%;
                    display: inline-block;
                }
            </style>
        </head>
    <body>
    <div class="container-fluid exportPO">
        <div class="row">
            <div style="width: 100%; margin-top: 20px">
            <img  src='https://ei-dev-store.s3.amazonaws.com/Global/1/1/logo-final-3.png'
                        class="logo"
                        alt="logo"
                    />
        </div>
        <div class="row">
            <div class="col-md-12">
                <h2 class="font-weight-bold text-center" style="font-size: 21px">BIÊN BẢN GIAO HÀNG - DELIVERY RECORD
                </h2>
            </div>
        </div>
        <div style="margin-top: 20px">
            <div style="width: 100%">
                <p style="font-size: 14px;"><strong>Đơn đặt hàng số/ PO number:
                    </strong> ${orderData.code}</p>
            </div>
        </div>
        <div style="display: flex;  justify-content: space-between">
        <div class="col-md-6">
            <div class="borderBottomBold"></div>
            <div class="responsive">
                <table class="table tableCustomQuotation">
                    <tbody>
                        <tr>
                            <td>
                            <strong> BÊN BÁN/SELLER </strong>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                            <strong> Tên doanh nghiệp </strong> <br />
                            <i>Company Name</i>
                            </td>
                            <td><strong>CÔNG TY CỔ PHẦN EI INDUSTRIAL</strong></td>
                        </tr>
                        <tr>
                            <td>
                            <strong> Địa chỉ </strong> <br />
                            <i>Address</i>
                            </td>
                            <td>
                            37 Nguyễn Văn Hưởng, Thảo Điền, Thành phố Thủ Đức, Thành
                            phố Hồ Chí Minh, Việt Nam
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <strong> Người liên hệ </strong> <br />
                            <i> Contact Name </i>
                            </td>
                            <td>Yên Nguyễn</td>
                        </tr>
                        <tr>
                            <td>
                            <strong> Số điện thoại </strong> <br />
                            <i> Phone Number </i>
                            </td>
                            <td>0909123456</td>
                        </tr>
                        <tr>
                            <td>
                            <strong> Số đơn hàng </strong> <br />
                            <i>Order Number </i>
                            </td>
                            <td>${orderData.code}</td>
                        </tr>
                        <tr>
                            <td>
                            <strong> Ngày giao hàng </strong> <br />
                            <i>Delivery Date </i>
                            </td>
                            <td>${orderData.estimatedDeliveryDate}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-6">
            <div class="borderBottomBold"></div>
            <div class="responsive">
            <table class="table tableCustomQuotation">
            <tbody>
                <tr>
                    <td>
                        <strong> BÊN BÁN/SELLER</strong>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <strong> Tên doanh nghiệp </strong> <br />
                        <i>Company Name</i>
                    </td>
                    <td><strong>${(supplierInfo && supplierInfo.supInfo.name) || 'No Data'}</strong></td>
                </tr>
                <tr>
                    <td>
                        <strong> Địa chỉ </strong> <br />
                        <i>Address</i>
                    </td>
                    <td>${ addressWarehouseSupplier !== '' ? addressWarehouseSupplier : (supplierInfo && supplierInfo.supInfo.address) || 'No Data'}</td>
                </tr>
                <tr>
                    <td>
                        <strong> Người liên hệ </strong> <br />
                        <i>Contact Name </i>
                    </td>
                    <td>${(supplierInfo && supplierInfo.supInfo.companyName) || 'No Data'}</td>
                </tr>
                <tr>
                    <td>
                        <strong> Email nhận hoá đơn điện tử </strong> <br />
                        <i>Email receive e_invoice</i>
                        </td>
                        <td>${(supplierInfo && supplierInfo.supInfo.email) || 'No Data'}</td>
                    </tr>
            </tbody>
        </table>
            </div>
        </div>
    </div>
        <div class="row mb-3">
            <div class="col-md-12" style="font-size: 14px;">
                <strong>Đối tác vận chuyển/</strong>Shipping Partner: <br />
                <strong>Thông tin sản phẩm/</strong> Good details:
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <table class="table tableCustomQuotation2">
                    <thead>
                        <tr>
                            <th><strong>STT</strong><br /> <i>No.</i></th>
                            <th><strong>Mã sản phẩm</strong><br /> <i>Product code</i></th>
                            <th><strong>Tên sản phẩm</strong><br /> <i>Product name</i></th>
                            <th><strong>Đơn vị tính</strong><br /> <i>Unit</i></th>
                            <th><strong>Số lượng</strong><br /> <i>Quantity</i></th>
                        </tr>
                    </thead>
                    <tbody>
                    ${renderOrderDetailsInDeliveryRecord}
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="borderBottom"></div>
                <table class="table">
                    <tbody>
                        <tr>
                            <td class="width49 borderTopNone mb100">
                                <strong style="font-size: 14px;">BÊN MUA/ BUYER:</strong>
                            </td>
                            <td class="width49 borderTopNone mb100">
                                <strong style="font-size: 14p;">BÊN BÁN/ SELLER: <br />CÔNG TY
                                    CỔ
                                    PHẦN EI INDUSTRIAL </strong>
                            </td>
                        </tr>
                        <tr>
                            <td class="width49 borderTopNone">
                                <i style="font-size: 14px;">Ngày, tháng/ Date</i>
                            </td>
                            <td class="width49 borderTopNone">
                                <i class="font-size: 14px;">Ngày, tháng/ Date</i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>

                </div>
            </div>
        </div>
            </div>
        </div>
    </div>
    </body>
    </html>

    `;
    //const { data } = await service.rest('/generatePdf/createPdfBuffer', { htmlContent: html });

    // data Buffer [123, 123, 123, 123, ]
    // console.log(data.toString('base64'));
    // let html1 = `<h1>Hello world</h1>`;
    var listAttachments = [{
            name: 'hondon.pdf',
            type: 'application/pdf',
            content: html
        },
        {
            name: 'bienbangiaohang.pdf',
            type: 'application/pdf',
            content: deliveryOrderHtml
        }
    ]
    const mailTemplateComCode = 'ORDR_TMPLT_ML';
    const objParam = {
        listEmail,
        orderData,
        mailTemplateCode,
        note,
        title,
        attachments: listAttachments
    };
    return service
        .rest('email/sendMailTemplate', {
            objParam,
            mailTemplateComCode
        })
        .then(rlt => {
            commit(Type.SEND_MAIL_TEMPLATE);
            if (rlt.status) return true;
            else return false;
        })
        .catch(err => {
            console.log('🚀 ~ file: orderDetail.actions.js ~ line 113~ awaitservice.rest ~ e', err);
            return false;
        });
}

export {
  updateOrderTags,
  addOrderDetailLocal,
  saveAddOrderDetails,
  saveEditOrderDetail,
  deleteOrderDetail,
  deletePartnerList,
  getOrderById,
  changeStatus,
  getByOrderId,
  getOrderDetailSupplierByOrderId,
  uploadFile,
  deleteFile,
  sendMail,
  sendMailToSupplier,
  previewMail,
  exportPO,
  exportDeliveryRecord,
  saveEditOrders,
  updateOrderStatusSupplier,
  exportSupplierPO,
  sendMailConfirmToSupplier
};