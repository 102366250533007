import Service from 'core/service';
// import { cloneDeep } from 'lodash';
import * as Type from 'modules/supplierAddress/store/types';

const service = new Service();

const getAllSupplierAddress = async ({ commit }, value) => {
  const {
    supplierName = '',
    phone = '',
    province = '',
    district = '',
    ward = '',
    supplierId = '',
    supplierIdArr = []
  } = value;
  const params = {};

  if(supplierIdArr.length > 0){
    params.supplierIdArr = supplierIdArr
  }

  if (supplierName) {
    params.supplierName = supplierName;
  }

  if (supplierId) {
    params.supplierId = supplierId;
  }

  if (phone) {
    params.phone = phone;
  }

  if (province) {
    params.province = province;
  }

  if (district) {
    params.district = district;
  }

  if (ward) {
    params.ward = ward;
  }

  // Get getAll Customer Address
  return service
    .rest('supplierAddress/admin/getList', params)
    .then(res => {
      const { status, data } = res;
      if(!status){
        return false;
      }
      commit(Type.SET_ALL_SUPPLIER_ADDRESS, data.items);
      return true;
    })
    .catch(err => {
      console.log('err: ', err);
      return false;
    });
};

const saveAddSupplierAddress = async ({ commit }, arrAddData) => {
  try {
    Object.keys(arrAddData).forEach(
      k => !arrAddData[k] && arrAddData[k] !== undefined && delete arrAddData[k]
    );
    return service
      .rest('supplierAddress/admin/add', arrAddData)
      .then(result => {
        const { data, status } = result;
        if (status !== true) {
          return { status: false, message: 'Không thể thêm địa chỉ' };
        }
        const { items } = data;
        // Commit to state
        commit(Type.SET_CUSTOMERS_ADDRESS_ADD, [items]);
        return {
          status: true,
          message: 'success',
          data: items
        };
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ saveAddCustomerAddress ~ e', e);
        return { status: false, message: e.message };
      });
  } catch (error) {
    console.log('🚀 ~ file: actions.js ~ line 86 ~ saveAddSupplierAddress ~ error', error.message);
    return { status: false, message: error.message };
  }
};

const saveEditSupplierAddress = async ({ commit }, newData) => {
  const { addressId } = newData;

  if (!addressId) {
    return false;
  }
  // Convert data
  return service
    .rest('supplierAddress/admin/update', newData)
    .then(result => {
      const { data, status } = result;
      if (status !== true) {
        return false;
      }
      const { items } = data;
      // Commit to news state
      commit(Type.SET_CUSTOMERS_ADDRESS_UPDATE, items);
      return true;
    })
    .catch(e => {
      console.log(
        '🚀 ~ file: actions.js ~ line 66 ~ saveEditSupplierAddress.rest ~ e',
        e
      );
      return false;
    });
};

const deleteSupplierAddressList = async ({ commit }, value) => {
  if (value.length <= 0) {
    return false;
  }

  return service
    .rest('supplierAddress/admin/deleteList', value)
    .then(result => {
      const { data, status } = result;
      if (status !== true) {
        return result;
      }
      const { items } = data;
      // Commit to news state
      commit(Type.SET_DELETE_CUSTOMERS_ADDRESS, items);
      return result;
    })
    .catch(e => {
      console.log(
        '🚀 ~ file: actions.js ~ line 120 ~ deleteSupplierAddressList.rest ~ e',
        e
      );
      return {
        status: false,
        messageContent: e.message
      };
    });
};

export {
  getAllSupplierAddress,
  saveAddSupplierAddress,
  saveEditSupplierAddress,
  deleteSupplierAddressList,
};
