import Service from 'core/service';
import * as Type from 'modules/lalaMove/store/types';
import { cloneDeep, isEmpty } from 'lodash';

const service = new Service();

function _convertData(data) {
  const newData = cloneDeep(data);
  const { receiverLocation = null, requesterLocation = null, totalFee } = newData;

  newData.totalFee = totalFee+'';

  if(receiverLocation){
    Object.keys(receiverLocation).forEach(item =>{
      receiverLocation[item] = receiverLocation[item] + '';
      return receiverLocation
    });
  };

  if(requesterLocation){
    Object.keys(requesterLocation).forEach(item =>{
      requesterLocation[item] = requesterLocation[item] + '';
      return requesterLocation
    });
  };

  return newData;
};

function _convertDataToState(data) {
  const newData = cloneDeep(data);

  if(isEmpty(data)){
    return {};
  }

  const { receiverLocation= null, requesterLocation = null } = newData;

  if(receiverLocation && requesterLocation){
    newData.receiverLocation = JSON.parse(receiverLocation);
    newData.requesterLocation = JSON.parse(requesterLocation);

    Object.keys(newData.receiverLocation).forEach(item =>{
      newData.receiverLocation[item] = +newData.receiverLocation[item];
      return newData.receiverLocation
    });

    Object.keys(newData.requesterLocation).forEach(item =>{
      newData.requesterLocation[item] = +newData.requesterLocation[item];
      return newData.requesterLocation
    });
  }

  return newData;
}

const requestQuotation = async ({ commit }, value) => {
  try {
    const newData = _convertData(value);
    delete newData.totalFee;
    return service
    .rest('shipping/lalamove/requestQuotation', newData)
    .then(res => {
      // console.log('res requestQuotation: ', res);
      const {status, data, message = ''} = res;
      if(!status){
        return { status, message };
      };

      const  newData = _convertDataToState(data);

      commit(Type.CREATE_QUOTATION, newData);
      return { status };
    })
    .catch(err => {
      console.log(err);
      return { status: false, message: err.message };
    });

  } catch (err) {
    return { status: false, message: err.message };
  }
};

const getInfoByOrder = async ({ commit }, value) => {
  try {
    
    return service
    .rest('shipping/lalamove/getInfoByOrder', value)
    .then(res => {
      // console.log('res getInfoByOrder: ', res);
      const {status, data = {}, message = ''} = res;
      if(!status){
        return { status, message };
      };

      const  newData = _convertDataToState(data);

      commit(Type.GET_INFO_BY_ORDER, newData);
      return { status };
    })
    .catch(err => {
      console.log(err);
      return { status: false, message: err.message };
    });

  } catch (err) {
    return { status: false, message: err.message };
  }
};

const placeOrder = async ({ commit }, value) => {
  try {
    const newData = _convertData(value);
    return service
    .rest('shipping/lalamove/placeOrder', newData)
    .then(res => {
      // console.log('res placeOrder: ', res);
      const {status, data, message = ''} = res;
      if(!status){
        return { status, message };
      }
      commit(Type.PLACE_ORDER, data);
      return { status };
    })
    .catch(err => {
      console.log(err);
      return { status: false, message: err.message };
    });

  } catch (err) {
    return { status: false, message: err.message };
  }
};

const updateInfo = async ({ commit }, value) => {
  try {
    const newData = _convertData(value);
    delete newData.totalFee;
    return service
    .rest('shipping/lalamove/updateInfo', newData)
    .then(res => {
      // console.log('res updateInfo: ', res);
      const {status, data, message = ''} = res;
      if(!status){
        return { status, message };
      }
      
      const  newData = _convertDataToState(data);

      commit(Type.UPDATE_INFO, newData);
      return { status };
    })
    .catch(err => {
      console.log(err);
      return { status: false, message: err.message };
    });

  } catch (err) {
    return { status: false, message: err.message };
  }

}

export {
  requestQuotation,
  getInfoByOrder,
  placeOrder,
  updateInfo
};
