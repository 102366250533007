import Service from 'core/service';
import * as Type from 'modules/unitOfMeasurement/store/types';

const services = new Service()

const getAllUnits = async ({ commit }, value) => {
    const { name = '' } = value;

    const params = { }

    if (name) {
        params.name = name;
    };
   
    const data = await services.rest('unit/getListPaging', params)
      
    if (data) {
        commit(Type.GET_ALL, data);
    } else {
        console.log('error');
    } 
};

const saveUnit = async ({ commit }, value) => {
    const allUnit = await getListUnit();
    const checkUnitExist = await allUnit.find(element => element.name === value.name);

    if(!checkUnitExist) {
        return await services.rest('unit/setList', { 
            items: {
                $add: [value]
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltAdd} = items
            commit(Type.SAVE, $rltAdd);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
        
    } else {
        return {
            type: false, 
            message: 'Item already exist'
        }
    }
};

const editUnit = async ({ commit }, value) => {
    // if (value.oldNameEdit.trim() !== value.data.name.trim()) {
    //     let allUnit = await getListUnit();
    //     const checkUnitExist = await allUnit.find(element => element.en_US == value.data.en_US)
    //     if(checkUnitExist) {
    //         return {
    //             type: false, 
    //             message: 'Item already exist'
    //         }
    //     }
    // } 
    const { id } = value;
    if (id) {
        return await services.rest('unit/setList', { 
            items: {
                $update: [value]
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltUpdate} = items
            // Commit to news state
            commit(Type.EDIT, $rltUpdate[0]);
            return {
                type: true,
                message: 'success'
            }
        }).catch(err => {
            return {
                type: false,
                message: err.message
            }
        })
    }
};

const deleteUnit = async ({ commit }, value) => {
    if (value.row.id) { 
        return await services.rest('unit/setList', { 
            items: {
                $delete: [value.row]
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltDelete} = items
            // Commit to news state
            commit(Type.DELETE, $rltDelete);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const deleteUnits = async ({ commit }, value) => {
    if (value.length > 0) { 
        return await services.rest('unit/setList', { 
            items: {
                $delete: value
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltDelete} = items
            // Commit to news state
            commit(Type.DELETE, $rltDelete);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const getAllUnitsSelect = async ({ commit }) => {
    const data = await services.rest('unit/getListPaging', {})
    if (data) {
        commit(Type.GET_ALL_UNIT_SELECT, data);
    } else {
        console.log('error');
    } 
};

const getListUnit = async () => {    
    const data = await services.rest('unit/getListPaging', {})
    .then(res=> {
        return res.rows
    }).catch(err=> {
        console.log(err);
    })
    return data
};


export {
    editUnit,
    getAllUnits,
    saveUnit,
    deleteUnit,
    deleteUnits,
    getAllUnitsSelect
};