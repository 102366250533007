import Service from 'core/service';
import * as Type from 'modules/productRepurchase/store/types';

const services = new Service()

const getAllProductRepurchases = async ({ commit }, value) => {
    const { customerId = '', productId = '', currentPage = '' } = value;

    const params = { }

    if (customerId != '') {
        params.customerId = customerId;
    };

    if (productId != '') {
        params.productId = productId;
    };

    if (currentPage != '') {
        params.currentPage = currentPage;
    };
   
    const result = await services.rest('productRepurchase/getListPaging', params)
      
    if (result.status) {
        commit(Type.GET_ALL, result.data);
    } else {
        console.log('error');
    } 
};

const addProductRepurchase = async ({ commit }, value) => {
    const {
        orderId,
        customerId,
        productId,
        salePrice,
        supplierPrice,
        quantity
    } = value;
    const data = {
        orderId,
        customerId,
        productId,
        salePrice,
        supplierPrice,
        quantity
    }
    return await services.rest('productRepurchase/setList', { 
        items: {
            $add: [data]
        }
    }).then(rlt => {
        const {items} = rlt;
        const {$rltAdd} = items
        commit(Type.SAVE, $rltAdd);
        return {
            status: true,
            message: 'success'
        }
    }).catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
        return {
            status: false,
            message: 'error'
        }
    })
};

const editProductRepurchase = async ({ commit }, value) => {
    if (value.newData.id) {
        const {
            id,
            orderId,
            customerId,
            productId,
            salePrice,
            supplierPrice,
            quantity
        } = value.newData;
        const data = {
            id,
            orderId,
            salePrice,
            supplierPrice,
            quantity
        }
        // catch customerId and productId change
        if(Number(customerId) > 0) data.customerId = customerId;
        if(Number(productId) > 0) data.productId = productId;

        return await services.rest('productRepurchase/setList', { 
            items: {
                $update: [data]
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltUpdate} = items
            console.log('🚀 ~ file: actions.js ~ line 95 ~ editProductRepurchase ~ $rltUpdate', $rltUpdate)
            // Commit to news state
            commit(Type.EDIT, $rltUpdate[0]);
            return {
                status: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                status: false,
                message: 'error'
            }
        })
    }
};

const deleteProductRepurchase = async ({ commit }, value) => {
    if (value.length > 0) { 
        return await services.rest('productRepurchase/setList', { 
            items: {
                $delete: value
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltDelete} = items
            // Commit to news state
            commit(Type.DELETE, $rltDelete);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

export {
    editProductRepurchase,
    getAllProductRepurchases,
    addProductRepurchase,
    deleteProductRepurchase
};