<template>
  <div>
    <b-row>
      <b-col>
        <div>
          <Widget>
            <div class="grid-content">
              <el-form label-position="top" ref="formUser" :model="formUser" label-width="100px" size="small" :rules="rulesForm" class="form-user">
                <!--email (S) -->
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="this.$i18n.t('src.modules.customer.index.email')" prop="email" style="margin-bottom: 0px">
                      <el-input size="medium" type="text" v-model="formUser.email"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- email (E) -->
                <!-- password (S) -->
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="this.$i18n.t('src.modules.customer.index.password')" prop="password" style="margin-bottom: 0px">
                      <el-input :disabled="!isChangePassword" size="medium" type="password" placeholder="********" v-model="formUser.password"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- password (E) -->
                <!-- password (S) -->
                <el-row v-show="isChangePassword">
                  <el-col :span="24">
                    <el-form-item :label="this.$i18n.t('src.modules.customer.index.rePassword')" prop="rePassword" style="margin-bottom: 0px">
                      <el-input :disabled="!isChangePassword" size="medium" type="password" placeholder="********" v-model="formUser.rePassword"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- password (E) -->
              </el-form>
              <!-- Actions (S) -->
              <span slot="footer" class="dialog-footer">
                <el-row style="margin-top: 30px">
                  <el-col :span="12" v-show="!isChangePassword">
                    <!-- Change Password (S) -->
                    <el-button type="danger" @click="handleChangePass">
                      {{ this.$i18n.t('src.modules.profile.index.btnChangePassword') }}
                    </el-button>
                    <!-- Change Password (S) -->
                  </el-col>
                  <el-col :span="isChangePassword ? 24 : 12">
                    <!-- Change Read to edit (S) -->
                    <el-button size="medium" type="success" @click="Save()" style="float: right">
                      {{ this.$i18n.t('src.modules.profile.index.btnSave') }}
                    </el-button>
                    <!-- Change Read to edit (S) -->
                  </el-col>
                </el-row>
              </span>
              <!-- Actions (E) -->
            </div>
          </Widget>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validateEmail, validatePassword, checkDuplicate } from 'core/helpers';
import cloneDeep from 'lodash/cloneDeep';

export default {
  components: {},
  props: {
    customer_id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      S3_STORE_ENDPOINT: process.env.VUE_APP_S3_STORE_ENDPOINT,
      formUser: {
        email: '',
        password: '',
        rePassword: ''
      },
      isChangePassword: false,
      // Rule Form Add, Edit
      rulesForm: {
        email: [
          {
            required: true,
            message: this.$i18n.t('src.modules.supplier.index.rule', { name: 'Email' }),
            trigger: 'blur'
          },
          { validator: validateEmail, trigger: 'blur' },
          { validator: this.checkEmailDuplicate, trigger: 'blur' }
        ],
        password: [],
        rePassword: []
      },
      rulePassword: [
        {
          required: true,
          message: this.$i18n.t('src.modules.customer.index.rule.password'),
          trigger: 'blur'
        },
        { validator: this.validateNewPass, trigger: 'blur' },
        {
          message: this.$i18n.t('src.modules.customer.index.rule.password'),
          trigger: 'change',
          validator: validatePassword
        }
      ],
      ruleRePassword: [
        {
          required: true,
          message: this.$i18n.t('src.modules.customer.index.rule.password'),
          trigger: 'blur'
        },
        { validator: this.validateConfirmPass, trigger: 'blur' },
        {
          message: this.$i18n.t('src.modules.customer.index.rule.password'),
          trigger: 'change',
          validator: validatePassword
        }
      ],
      formUserOld: {},
      // ckeditor
      editorConfig: {
        language: 'en'
      }
    };
  },

  computed: {
    ...mapState('customerDetail', ['customerDetail']),
    ...mapState('common', ['commonMap']),
    ...mapState('customer', ['customers'])
  },

  watch: {},

  async created() {
    await this.getByIdCustomer({ id: this.customer_id });
    this.loadDataUser();
    this.getAllCustomers({});
  },
  methods: {
    ...mapActions('customer', ['getAllCustomers']),
    ...mapActions('customer', ['editCustomerProfile']),
    ...mapActions('customerDetail', ['getByIdCustomer', 'editCustomer']),
    ...mapActions('global', ['setGlobalReady']),


    checkEmailDuplicate(rule, value, callback) {
      // check email already used
      if (checkDuplicate(this.customers, { email: value }, this.formUserOld.email)) {
        callback('Email already used')
      } else {
        callback()
      }
    },

    validateNewPass(rule, value, callback) {
      if (value === '') {
        callback(this.$i18n.t('src.modules.global.components.changePassword.rule.newPassword'));
      } else {
        if (this.formUser.rePassword !== '') {
          this.$refs.formUser.validateField('confirmNewPassword');
        }
        callback();
      }
    },

    validateConfirmPass(rule, value, callback) {
      if (value === '') {
        callback(this.$i18n.t('src.modules.global.components.changePassword.rule.confirmNewPassword'));
      } else if (value !== this.formUser.password) {
        callback(this.$i18n.t('src.modules.global.components.changePassword.rule.compareConfirmAndNewPassword'));
      } else {
        callback();
      }
    },

    handleChangePass() {
      this.isChangePassword = true;
      this.rulesForm.password = this.rulePassword;
      this.rulesForm.rePassword = this.ruleRePassword;
    },
    async loadDataUser() {
      this.formUser = {
        id: this.customerDetail.id,
        email: this.customerDetail.email
      };
      this.formUserOld = cloneDeep(this.formUser)
    },

    async Save() {
      this.$refs['formUser'].validate(async (valid) => {
        if (valid) {
          this.$confirm('Do you want change ?')
            .then(async () => {
              try {
                const result = await this.editCustomerProfile(this.formUser);
                if (result && result.status) {
                  this.$notify({
                    title: 'Success',
                    message: 'Edit profile success',
                    type: 'success'
                  });
                  this.isChangePassword = false;
                } else {
                  this.$notify({
                    title: 'Error',
                    message: result.message,
                    type: 'error'
                  });
                }
              } catch (error) {
                console.log('🚀 ~ file: customer.profile.vue ~ line 190 ~ this.$refs[\'formUser\'].validate ~ error', error.message);
              }
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style>
.my-account-form .tables-basic {
  font-weight: normal !important;
  height: 100% !important;
}
.my-account-form .tabs .nav-item {
  transform: rotate(-90deg) !important;
  margin-left: -25px !important;
  margin-top: 14% !important;
  margin-bottom: 15% !important;
  height: 150px;
  width: 200px;
}
.my-account-form .card-header {
  padding: 0rem 0rem !important;
  margin-right: -140px !important;
}
.my-account-form .nav-pills .nav-link {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}
.my-account-form .nav-link {
  display: block !important;
  padding: 0.5rem 1rem !important;
  text-align: center !important;
}
.my-account-form .tab-content.col {
  max-width: 100% !important;
}

button.el-button.permission-tags {
  height: 30px;
  margin-inline: 10px;
  margin-bottom: 10px;
}

.form-user {
  margin-top: 10px;
  overflow: auto;
  min-height: auto !important;
}

.permission-table {
  margin-top: 10px;
  overflow: auto;
  max-height: 900px;
  min-height: 900px;
}
</style>
