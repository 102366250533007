import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN } from 'core/constants';
const state = {
  isAuthenticated: !!Cookies.get(ACCESS_TOKEN),
  isFetching: false,
  errorMessage: null,
  user: {},
  lang: false
};

const mutations = {
  [Type.SET_USER](state, value) {
    state.isAuthenticated = value ? true : false;
    state.user = value;
  },
  [Type.SET_LANGUAGE](state, value) {
    state.lang = value;
  },
  [Type.SET_ERROR](state, value) {
    state.errorMessage = value;
    setTimeout(() => {
      state.errorMessage = null;
    }, 3 * 1000);
  },
  [Type.SET_FETCHING](state, value) {
    state.isFetching = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
