<template>
  <div class="tables-basic">
    <b-row>
      <b-col>
        <!-- Search (S) -->
        <Widget style="">
          <el-row :gutter="24">
            <el-col :span="4">
              <div class="grid-content">
                <el-input clearable class="input" v-model="formSearch.customerName" placeholder="Input Name or id, email"> </el-input>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content">
                <el-select size="medium" clearable v-model="formSearch.channelId" placeholder="Select Channel" width="100%" filterable @input="changePageOne" @change="changePageOne">
                  <el-option v-for="item in channelList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content">
                <el-select size="medium" clearable v-model="formSearch.sizeId" placeholder="Select Size" width="100%" filterable @input="changePageOne" @change="changePageOne">
                  <el-option v-for="item in sizeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content">
                <el-select size="medium" clearable v-model="formSearch.typeId" placeholder="Select Type" width="100%" filterable @input="changePageOne" @change="changePageOne">
                  <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content">
                <el-select size="medium" clearable v-model="formSearch.rankId" placeholder="Select Rank" width="100%" filterable @input="changePageOne" @change="changePageOne">
                  <el-option v-for="item in rankList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </Widget>
        <!-- Search (E)  -->
        <Widget style="margin-bottom: 0px">
          <!-- Button Action (S) -->
          <el-row type="flex" justify="end">
            <el-col>
              <div class="grid-content">
                <el-row type="flex" justify="end">
                  <div class="main-actions">
                    <el-button size="medium" type="info" @click="onClickSearchCustomers()"><i class="el-icon-search mr-2"></i>{{ $t('src.modules.news.index.btnSearch') }} </el-button>
                  </div>
                </el-row>
              </div>
            </el-col>
          </el-row>
          <!-- Button Action (E) -->

          <!-- Table Customers Data (S) -->
          <el-row>
            <el-table
              v-loading="isLoading"
              ref="newsTable"
              :data="pagedTableData"
              height="600"
              :default-sort="{ prop: 'updatedAt', order: 'descending' }"
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <!-- Check box (S) -->
              <el-table-column type="selection" width="50"></el-table-column>
              <!-- Check box (E) -->

              <!-- Id (S) -->
              <el-table-column prop="id" label="#" width="50"></el-table-column>
              <!-- Id (E) -->

              <!-- customer code (S) -->
              <el-table-column prop="code" :label="$i18n.t('src.modules.customer.index.customerCode')" width="150" sortable show-overflow-tooltip> </el-table-column>
              <!-- customer code (E)-->

              <!-- Avatar (S) -->
              <el-table-column prop="avatar" label="Avatar" width="140">
                <template slot-scope="scope">
                  <el-image style="width: 100px; height: 100px" :src="S3_STORE_ENDPOINT + '/' + getCustomerAvatar(scope.row.avatar)" fit="cover"> </el-image>
                </template>
              </el-table-column>
              <!-- Avatar (E) -->

              <!-- customerName (S) -->
              <el-table-column prop="customerName" :label="$i18n.t('src.modules.customer.index.customerName')" width="200" sortable show-overflow-tooltip> </el-table-column>
              <!-- customerName (E)-->

              <!-- email (S) -->
              <el-table-column prop="email" :label="$i18n.t('src.modules.customer.index.email')" width="220" sortable show-overflow-tooltip> </el-table-column>
              <!-- email (E)-->

              <!-- address (S) -->
              <el-table-column prop="address" :label="$i18n.t('src.modules.customer.index.address')" width="220" sortable show-overflow-tooltip> </el-table-column>
              <!-- address (E)-->

              <!-- Industry (S) -->
              <el-table-column prop="industryId" :label="$i18n.t('src.modules.customer.index.industry')" width="180" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ getIndustryName(scope.row.industryId) }}
                </template>
              </el-table-column>
              <!-- Industry (E)-->

              <!-- Rank (S) -->
              <el-table-column prop="rankId" :label="$i18n.t('src.modules.customer.index.rank')" width="150" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ getRankName(scope.row.rankId) }}
                </template>
              </el-table-column>
              <!-- Rank (E) -->

              <!-- Tax Code (S) -->
              <el-table-column prop="taxCode" :label="$i18n.t('src.modules.customer.index.taxCode')" width="150" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.taxCode }}
                </template>
              </el-table-column>
              <!-- Tax Code (E) -->

              <!-- Created At (S) -->
              <el-table-column prop="createdAt" :label="$i18n.t('src.modules.customer.index.createdAt')" width="160" sortable>
                <template slot-scope="scope">
                  {{ scope.row.createdAt | formattedDate }}
                </template>
              </el-table-column>
              <!-- Created At (E) -->

              <!-- Status (S) -->
              <el-table-column prop="active" :label="$i18n.t('src.modules.customer.index.activeStatus')" width="120" fixed="right">
                <template slot-scope="scope">
                  <el-tag :disable-transitions="true" :key="scope.row.status" :type="scope.row.statusColor" effect="dark">
                    {{ scope.row.status }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- Status (E) -->
            </el-table>
          </el-row>
          <!-- Table Customers Data (E) -->

          <!-- Paging (S) -->
          <el-row type="flex" justify="center">
            <el-col>
              <div class="grid-content bg-purple">
                <el-pagination class="pagination" @current-change="changePage" :page-size="pageSize" :current-page="currentPage" layout="prev, pager, next" :total="totalCustomerCnt"> </el-pagination>
              </div>
            </el-col>
          </el-row>
          <!-- Paging (E) -->
        </Widget>

        <!-- Popup Upload File (S) -->
        <UploadFile
          :show="showUploadFile"
          @onCloseUploadFile="handleOnCloseUploadFile"
          @onSelectedFiles="handleOnSelectedFiles"
          :module-options="moduleOptions"
          :category-options="categoryOptions"
          :accept="'image/*'"
        />
        <!-- Popup Upload File (E) -->

        <!-- Popup Add Customer (S) -->
        <el-dialog :title="titlePopup" :visible.sync="customerDialogVisible" :before-close="handleCloseAddForm" width="52%" :close-on-click-modal="false">
          <el-form ref="formNews" :rules="rulesForm" :model="formNews" label-width="200px">
            <!-- Upload Image (S) -->
            <el-form-item :label="$t('src.modules.customer.index.avatar')" prop="avatar">
              <el-row>
                <el-col>
                  <el-button type="primary" icon="el-icon-circle-plus-outline" size="mini" @click="handleOpenPopupUploadFile"></el-button>
                  <el-button v-if="showCustomerImages" type="danger" icon="el-icon-delete" size="mini" @click="handleDeleteImage"></el-button>
                </el-col>
              </el-row>
              <el-row v-if="showCustomerImages">
                <el-col>
                  <vue-select-image :h="'200px'" :w="'200px'" ref="imageListAddForm" :data-images="customerImages" :is-multiple="true" @onselectmultipleimage="onSelectMultipleImageAddForm">
                  </vue-select-image>
                </el-col>
              </el-row>
            </el-form-item>
            <!-- Upload Image (E) -->
            <!-- customerName (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.customerName')" prop="customerName">
              <CustomInput
                size="medium" class="input_add" v-model="formNews.customerName"
              >
              </CustomInput>
            </el-form-item>
            <!-- customerName (E) -->

            <!-- password (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.password')" prop="password" v-if="formMode === 'edit'">
              <el-input size="medium" class="input_add" v-model="formNews.password" show-password />
            </el-form-item>
            <!-- password (E) -->

            <!-- email (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.email')" prop="email">
              <el-input size="medium" type="email" class="input_add" v-model="formNews.email" />
            </el-form-item>
            <!-- email (E) -->

            <!-- phone (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.phone')" prop="phone">
              <el-input size="medium" type="text" class="input_add" v-model="formNews.phone" />
            </el-form-item>
            <!-- phone (E) -->

            <!-- dateOfIncorporationCompany (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.dateOfIncorporationCompany')" prop="dateOfIncorporationCompany">
              <el-date-picker v-model="formNews.dateOfIncorporationCompany" type="date" size="small" value-format="yyyy-MM-dd" style="width: 99.9%"> </el-date-picker>
            </el-form-item>
            <!-- dateOfIncorporationCompany (E) -->

            <!-- taxCode (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.taxCode')" prop="taxCode">
              <el-input size="medium" class="input_add" v-model="formNews.taxCode" />
            </el-form-item>
            <!-- taxCode (E) -->

            <!-- type (S)  -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.typeId')" prop="typeId">
              <el-select size="medium" clearable placeholder="Select Type" v-model="formNews.typeId" width="100%" filterable>
                <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <!-- type (E)  -->

            <!-- Size (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.size')" prop="sizeId">
              <el-select size="medium" clearable v-model="formNews.sizeId" placeholder="Select Size" width="100%" filterable>
                <el-option v-for="item in sizeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <!-- Size (S) -->

            <!-- Industry (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.industry')" prop="industryId">
              <el-select size="medium" clearable v-model="formNews.industryId" placeholder="Select Industry" width="100%" filterable>
                <el-option v-for="item in industryList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <!-- Industry (S) -->

            <!-- taxCode (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.website')" prop="taxCode">
              <el-input size="medium" class="input_add" v-model="formNews.website" />
            </el-form-item>
            <!-- taxCode (E) -->

            <!-- Rank (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.rank')" prop="rankId">
              <el-select size="medium" clearable v-model="formNews.rankId" placeholder="Select Rank" width="100%" filterable>
                <el-option v-for="item in rankList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <!-- Rank (S) -->

            <!-- Channel (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.channel')" prop="channelId">
              <el-select size="medium" clearable v-model="formNews.channelId" placeholder="Select Channel" width="100%" filterable>
                <el-option v-for="item in channelList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <!-- Channel (S) -->

            <!-- Industrial Park (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.industrialPark')" prop="industrialParkId">
              <el-select size="medium" clearable v-model="formNews.industrialParkId" placeholder="Select Industrial Park" width="100%" filterable>
                <el-option v-for="item in industrialParkList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <!-- Industrial Park (E) -->
            <!-- User Incharge (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.userIncharge')" prop="userInchargeId">
              <el-select size="medium" clearable v-model="formNews.userInchargeId" placeholder="Select User Incharge" width="100%" filterable>
                <el-option v-for="item in userList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <!-- User Incharge (E) -->
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-row :gutter="24">
              <el-col :span="4">
                <el-button size="medium" @click="handleCloseAddForm">{{ $i18n.t('src.modules.news.index.close') }}</el-button>
              </el-col>
              <el-col l :span="20">
                <el-button size="medium" v-if="formMode === 'add'" type="primary" @click="onClickAddRow()">{{ $i18n.t('src.modules.customer.index.btn.addNew') }}</el-button>
                <el-button size="medium" v-if="formMode === 'edit'" type="success" @click="onClickPopupSaveNews()">{{ $i18n.t('src.modules.news.index.update') }}</el-button>
              </el-col>
            </el-row>
          </span>
        </el-dialog>
        <!-- Popup Add Customer (E) -->

        <!-- Popup Add Customer Quickly (S) -->
        <el-dialog :title="titlePopup" :visible.sync="createCustomerQuicklyDialogVisible" :before-close="handleCloseAddShortForm" width="30%" :close-on-click-modal="false">
          <el-form ref="formShortNews" :rules="rulesShortForm" :model="formShortNews" label-width="150px">
            <!-- customerName (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.customerName')" prop="customerName">
              <CustomInput
                size="medium" class="input_add" v-model="formShortNews.customerName"
              >
              </CustomInput>
            </el-form-item>
            <!-- customerName (E) -->

            <!-- email (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.email')" prop="email">
              <el-input size="medium" type="email" class="input_add" v-model="formShortNews.email" />
            </el-form-item>
            <!-- email (E) -->

            <!-- phone (S) -->
            <el-form-item :label="$i18n.t('src.modules.customer.index.phone')" prop="phone">
              <el-input size="medium" type="text" class="input_add" v-model="formShortNews.phone" />
            </el-form-item>
            <!-- phone (E) -->
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-row :gutter="24">
              <el-col :span="4">
                <el-button size="medium" @click="handleCloseAddShortForm">{{ $i18n.t('src.modules.news.index.close') }}</el-button>
              </el-col>
              <el-col l :span="20">
                <el-button size="medium" v-if="formMode === 'add'" type="primary" @click="onClickAddQuickCustomer()">{{ $i18n.t('src.modules.customer.index.btn.addNew') }}</el-button>
              </el-col>
            </el-row>
          </span>
        </el-dialog>
        <!-- Popup Add Customer Quickly(E) -->

        <!-- Popup Upload Excel File (S) -->
        <el-dialog title="Upload Excel File" :visible.sync="uploadExcelFileDialogVisible" :before-close="handleCloseUploadForm" width="50%" :close-on-click-modal="false">
          <el-row>
            <el-col :span="8">
              <el-upload ref="uploadFiles" action="" :limit="1" :auto-upload="false" :on-remove="handleRemoveFileUpload" :on-exceed="handleExceed" :on-change="handleFileUpload" class="mb-3">
                <div slot="tip" class="el-upload__tip">Please select excel file (.xlsx)</div>
                <el-button size="medium" type="warning"
                  >{{ this.$i18n.t('src.modules.order.detail.btnUpload') }}
                  <i class="el-icon-upload2 ml-2"></i>
                </el-button>
              </el-upload>
            </el-col>
            <el-col :span="16">
              <h6>Result:</h6>
              <div style="max-height: 100px; overflow-y: scroll">
                <p style="color: #67c23a" v-if="successEmailCount > 0"><i class="el-icon-check mr-2"></i>{{ successEmailCount }} email thêm thành công</p>
                <el-row v-for="(item, index) in listMailError" :key="index">
                  <el-col :span="12">
                    <span style="color: #f56c6c"><i class="el-icon-close mr-2"></i>{{ item.email }} </span>
                  </el-col>
                  <el-col :span="12">
                    <span style="color: #f56c6c">{{ item.message }}</span>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>

          <el-table :data="listCustomerUpload" v-loading="loadingAddMultiCustomer" style="width: 100%" height="400">
            <el-table-column label="Index" width="100">
              <template slot-scope="scope" style="text-align: center">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column prop="customerName" label="Tên công ty"> </el-table-column>
            <el-table-column prop="email" label="Email" width="350"> </el-table-column>
            <el-table-column prop="phone" label="SĐT" width="200"> </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-row :gutter="24">
              <el-col :span="4">
                <el-button size="medium" @click="handleCloseAddForm">{{ $i18n.t('src.modules.news.index.close') }}</el-button>
              </el-col>
              <el-col :span="6">
                <vue-excel-xlsx class="el-button el-button--success el-button--medium" :data="sampleData" :columns="columns" :filename="'sampleFile'">
                  <i class="el-icon-download" style="color: white"></i> Download sample file
                </vue-excel-xlsx>
              </el-col>
              <el-col :span="14">
                <el-button size="medium" type="primary" @click="onClickAddMultiCustomer">{{ $i18n.t('src.modules.customer.index.btn.addNew') }} </el-button>
              </el-col>
            </el-row>
          </span>
        </el-dialog>
        <!-- Popup Upload Excel File (E) -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UploadFile from 'components/Upload';
import CustomInput from 'components/CustomInput';
import Widget from 'components/Widget/Widget';
import remove from 'lodash/remove';
import isEqual from 'lodash/isEqual';
import without from 'lodash/without';
import cloneDeep from 'lodash/cloneDeep';
import { validatePhone, validatePassword } from 'core/helpers';
import { UPLOAD_FILE_MODULE } from 'core/constants';
// import CustomerInvoiceAddress from 'modules/customerInvoiceAddress/index.vue';
// import CustomerAddress from 'modules/customerAddress/index.vue';
import vnLocal from 'vn-local';
import readXlsxFile from 'read-excel-file';
import { CUSTOMER_STATUS_COLOR, CUSTOMER_STATUS } from 'core/constants';
import VueExcelXlsx from 'vue-excel-xlsx';
import Vue from 'vue';

Vue.use(VueExcelXlsx);
export default {
  // name: 'Customer',
  components: { Widget, UploadFile, CustomInput },
  data() {
    return {
      S3_STORE_ENDPOINT: process.env.VUE_APP_S3_STORE_ENDPOINT,
      isLoading: true,

      customerId: null,

      fileListSelectedFormNews: [],
      // customerInfoDialogVisible: false,
      uploadExcelFileDialogVisible: false,
      createCustomerQuicklyDialogVisible: false,

      customerIdListInPage: [],

      // Search Form
      formSearch: {
        customerName: '',
        channelId: '',
        sizeId: '',
        typeId: '',
        rankId: '',
        limit: 10,
        offset: 0
      },
      // Paging
      currentPage: 1,
      pageSize: 10,

      formMode: 'add',

      //Table News
      newsSelectedList: [],

      // Popup Add
      customerDialogVisible: false,
      formCustomerDefault: {
        customerName: '',
        email: '',
        phone: '',
        avatar: [],
        taxCode: '',
        typeId: null,
        sizeId: null,
        industryId: null,
        website: '',
        rankId: null,
        industrialParkId: null,
        channelId: null,
        userInchargeId: null,
        password: null,
        dateOfIncorporationCompany: null
      },
      formShortCustomerDefault: {
        customerName: '',
        email: '',
        phone: ''
      },
      formNews: {
        customerName: '',
        email: '',
        phone: '',
        avatar: '',
        taxCode: '',
        typeId: null,
        sizeId: null,
        industryId: null,
        website: '',
        rankId: null,
        industrialParkId: null,
        channelId: null,
        userInchargeId: null,
        password: null,
        dateOfIncorporationCompany: null
      },
      formShortNews: {
        customerName: '',
        email: '',
        phone: ''
      },
      formNewsOld: {},

      // Rule Form Add, Edit
      rulesForm: {
        customerName: [
          {
            required: true,
            message: this.$i18n.t('src.modules.customer.index.rule.customer_name'),
            trigger: 'blur',
          }
        ],
        email: [
          {
            required: true, //Is required
            message: this.$i18n.t('src.modules.customer.index.rule.email'), //Error message
            trigger: 'blur' //Inspection method (blur means clicking other places with the mouse)
          },
          {
            type: 'email', //The type to be checked (number, email, date, etc.)
            message: this.$i18n.t('src.modules.customer.index.rule.checkType_email'),
            trigger: ['blur', 'change'] //change is when the tested character changes)
          }
        ],
        phone: [
          {
            required: true,
            message: this.$i18n.t('src.modules.customer.index.rule.phone'),
            trigger: 'blur',
            validator: validatePhone
          }
        ],
        avatar: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.avatar'),
            trigger: 'change'
          }
        ],
        taxCode: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.taxCode'),
            trigger: 'blur'
          }
        ],
        typeId: [
          {
            required: true,
            message: this.$i18n.t('src.modules.customer.index.rule.typeId'),
            trigger: 'blur'
          }
        ],
        sizeId: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.sizeId'),
            trigger: 'change'
          }
        ],
        industryId: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.industryId'),
            trigger: 'change'
          }
        ],
        website: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.website'),
            trigger: 'blur'
          }
        ],
        rankId: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.rankId'),
            trigger: 'change'
          }
        ],
        industrialParkId: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.industrialParkId'),
            trigger: 'change'
          }
        ],
        channelId: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.channelId'),
            trigger: 'change'
          }
        ],
        password: [
          {
            message: this.$i18n.t('src.modules.customer.index.rule.password'),
            trigger: 'change',
            validator: validatePassword
          }
        ]
      },

      rulesShortForm: {
        customerName: [
          {
            required: true,
            message: this.$i18n.t('src.modules.customer.index.rule.customer_name'),
            trigger: 'blur',
          }
        ],
        email: [
          {
            required: true, //Is required
            message: this.$i18n.t('src.modules.customer.index.rule.email'), //Error message
            trigger: 'blur' //Inspection method (blur means clicking other places with the mouse)
          },
          {
            type: 'email', //The type to be checked (number, email, date, etc.)
            message: this.$i18n.t('src.modules.customer.index.rule.checkType_email'),
            trigger: ['blur', 'change'] //change is when the tested character changes)
          }
        ],
        phone: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.phone'),
            trigger: 'blur',
            validator: validatePhone
          }
        ]
      },
      // Popup Upload File
      showUploadFile: false,

      moduleOptions: [{ value: UPLOAD_FILE_MODULE.CUSTOMER, label: UPLOAD_FILE_MODULE.CUSTOMER }],
      categoryOptions: [{ value: 1, label: 'No Data' }],
      listCustomerUpload: [],
      listMailError: [],
      successEmailCount: 0,
      loadingAddMultiCustomer: false,
      columns: [
        {
          label: 'Tên công ty',
          field: 'customerName'
        },
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'SĐT',
          field: 'phone'
        }
      ],
      sampleData: [
        {
          customerName: 'Nguyen Van A',
          email: 'nguyenvana@gmail.com',
          phone: '0987556558'
        },
        {
          customerName: 'Nguyen Thi B',
          email: 'nguyenthib@gmail.com',
          phone: '0987556558'
        }
      ]
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('customer', ['customers', 'totalCount']),
    ...mapState('type', ['types']),
    ...mapState('size', ['sizes']),
    ...mapState('industry', ['industries', 'industriesMap']),
    ...mapState('rank', ['ranks', 'ranksMap']),
    ...mapState('channel', ['channels']),
    ...mapState('industrialPark', ['industrialParks']),
    ...mapState('user', ['users', 'usersMap']),
    ...mapState('customerAddress', ['customerAddress', 'customerAddressMapWithCustomer']),

    getSaleId() {
      return this.user.id || '';
    },

    isDisabledButtonDelete() {
      return !this.newsSelectedList.length > 0;
    },

    titlePopup() {
      switch (this.formMode) {
        case 'add':
          return this.$i18n.t('src.modules.news.index.form_add');
        case 'edit':
          return this.$i18n.t('src.modules.news.index.form_edit');
        case 'editAdd':
          return this.$i18n.t('src.modules.news.index.form_edit');
        default:
          return this.$i18n.t('src.modules.news.index.form_add');
      }
    },

    quotationStatusList() {
      const customerStatusArr = ['true', 'false'];
      return customerStatusArr.map((item) => {
        return {
          value: item.toString(),
          label: CUSTOMER_STATUS[item],
          color: CUSTOMER_STATUS_COLOR[item]
        };
      });
    },

    // typeList
    typeList() {
      return this.types.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },
    // sizeList
    sizeList() {
      return this.sizes.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },

    // industryList
    industryList() {
      return this.industries.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },

    // rankList
    rankList() {
      return this.ranks.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },

    // userList
    userList() {
      return this.users.map((item) => {
        return {
          value: item.id,
          label: item.username
        };
      });
    },

    // rankList
    channelList() {
      return this.channels.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },

    // industrialParkList
    industrialParkList() {
      return this.industrialParks.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },

    allCustomers() {
      return cloneDeep([...this.customers]);
    },

    // Paging
    pagedTableData() {
      const dataPaging = this.allCustomers;
      const customerAddressMap = this.customerAddressMapWithCustomer || {};
      this.resetCustomerIdListInPage();

      dataPaging.map((item) => {
        this.customerIdListInPage.push(item.id);
        const { id, active } = item;

        if (customerAddressMap[id]) {
          let hasDefaultAddress = false;
          const customerAddressArr = customerAddressMap[id];

          customerAddressArr.forEach((element) => {
            const { district, ward, isDefault, address } = element;

            if (isDefault === true && district) {
              hasDefaultAddress = true;

              const districtInfo = vnLocal.getWardsByDistrictCode(district);
              const districtName = districtInfo?.[0]?.districtName || '';
              const provinceName = districtInfo?.[0]?.provinceName || '';
              const wardName = (ward && districtInfo?.find((x) => x.code === ward)?.name) || '';

              item.address = `${address ? address + ', ' : ''}${wardName ? wardName + ', ' : ''}${districtName ? districtName + ', ' : ''}${provinceName ? provinceName : ''}`;
            }

            if (hasDefaultAddress === false && (!district || isDefault === false)) {
              item.address = 'No Data';
            }
          });
        } else {
          item.address = 'No Data';
        }

        //customer status
        item.status = CUSTOMER_STATUS[active];
        item.statusColor = CUSTOMER_STATUS_COLOR[active];

        return item;
      });

      return dataPaging;
    },

    totalCustomerCnt() {
      return this.totalCount || 0;
    },

    // Add Form
    customerImages() {
      return this.formNews.avatar;
    },

    showCustomerImages() {
      if ((this.customerImages.length === 1 && this.customerImages[0] === '') || this.customerImages.length === 0) return false;
      return true;
    }
  },

  async created() {
    this.formSearch.userInchargeId = this.getSaleId;
    await Promise.all([
      this.getAllTypes(),
      this.getAllSizes(),
      this.getAllIndustries(),
      this.getAllRanks(),
      this.getAllIndustrialParks(),
      this.getAllChannels(),
      this.getAllUsers(),
      this.getAllCustomers(this.formSearch)
    ]).then(async () => {
      await this.getAllCustomerAddressInPageCustomer({
        customerIdArr: this.customerIdListInPage
      });
      this.isLoading = false;
    });
  },

  methods: {
    ...mapActions('customer', ['getAllCustomers', 'saveAddCustomers', 'deleteCustomers', 'saveEditCustomers', 'deleteCustomersList', 'saveAddCustomersWithSendActiveMail']),
    ...mapActions('type', ['getAllTypes']),
    ...mapActions('size', ['getAllSizes']),
    ...mapActions('industry', ['getAllIndustries']),
    ...mapActions('rank', ['getAllRanks']),
    ...mapActions('industrialPark', ['getAllIndustrialParks']),
    ...mapActions('channel', ['getAllChannels']),
    ...mapActions('user', ['getAllUsers']),
    ...mapActions('global', ['setGlobalReady', 'uploadFile']),
    ...mapActions('customerAddress', ['getAllCustomerAddressInPageCustomer']),

    handleChangeCustomerStatus(customerId, value) {
      this.$confirm('Do you want change ?')
        .then(async () => {
          try {
            const result = await this.saveEditCustomers({
              oldData: {},
              newData: { id: customerId, active: value }
            });
            if (result) {
              this.$notify({
                title: 'Success',
                message: 'Change Customer Status Success',
                type: 'success'
              });
            } else {
              this.$notify({
                title: 'Error',
                message: `Cannot change customer status, Error: ${result.message}`,
                type: 'error'
              });
            }
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.edit.fail', {
                name: 'row'
              }),
              type: 'error'
            });
          }
        })
        .catch((error) => {
          console.log('error: ', error);
        });
    },

    resetCustomerIdListInPage() {
      this.customerIdListInPage = [];
    },

    loadAllCustomer() {
      return this.customers.map((item) => {
        return {
          value: item.customerName
        };
      });
    },

    getCustomerAvatar(listAvatar) {
      const images = listAvatar ? listAvatar.split('|') : [];
      return images[0];
    },

    // Upload File
    handleOpenPopupUploadFile() {
      this.showUploadFile = true;
    },

    handleOnCloseUploadFile() {
      this.showUploadFile = false;
    },

    handleOnSelectedFiles(arrObj) {
      this.formNews.avatar = arrObj.map((item) => {
        item.src = this.S3_STORE_ENDPOINT + '/' + item.filePath;
        return item;
      });
      this.showUploadFile = false;
    },

    async onClickSearchCustomers() {
      this.setGlobalReady(false);
      await this.getAllCustomers(this.formSearch);
      this.setGlobalReady(true);
    },

    async onClickAddRow() {
      this.$refs['formNews'].validate(async (valid) => {
        if (valid) {
          try {
            await this.saveAddCustomers([this.formNews]);
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('common.notify.message.add.success', {
                item: 'row'
              }),
              type: 'success'
            });
            this.clearFormNews();
            this.customerDialogVisible = false;
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.add.failr', { name: 'row' }),
              type: 'error'
            });
          }
        } else {
          return false;
        }
      });
    },

    async onClickAddQuickCustomer() {
      this.$refs['formShortNews'].validate(async (valid) => {
        if (valid) {
          try {
            this.listCustomerUpload.push(this.formShortNews);
            const result = await this.saveAddCustomersWithSendActiveMail(this.listCustomerUpload);
            if (result.status) {
              if (result.items.length > 0 && result.items[0].items) {
                this.$notify({
                  title: this.$i18n.t('common.notify.title.success'),
                  message: this.$i18n.t('common.notify.message.add.success', {
                    item: 'row'
                  }),
                  type: 'success'
                });
              } else {
                this.$notify({
                  title: this.$i18n.t('common.notify.title.error'),
                  message: this.$i18n.t('common.notify.message.add.failr.content', {
                    error: result.items[0].message
                  }),
                  type: 'error'
                });
              }
            } else {
              this.$notify({
                title: this.$i18n.t('common.notify.title.error'),
                message: this.$i18n.t('common.notify.message.add.failr.content', {
                  error: result.message
                }),
                type: 'error'
              });
            }
            this.clearShortFormNews();
            this.listCustomerUpload = [];
            this.createCustomerQuicklyDialogVisible = false;
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.add.failr', { name: 'row' }),
              type: 'error'
            });
            this.listCustomerUpload = [];
          }
        } else {
          return false;
        }
      });
    },

    async onClickAddMultiCustomer() {
      this.loadingAddMultiCustomer = true;
      const result = await this.saveAddCustomersWithSendActiveMail(this.listCustomerUpload);
      this.loadingAddMultiCustomer = false;
      if (result.status) {
        if (result.items.every((item) => item.items !== null)) {
          this.$notify({
            title: 'Success',
            message: 'Add customers rows Success',
            type: 'success'
          });
        } else {
          const filterEmailSucces = result.items.filter((item) => item.items !== null);
          const filterEmailError = result.items.filter((item) => item.items === null);
          this.listMailError = filterEmailError;
          this.successEmailCount = filterEmailSucces.length;
          //const listError = filterEmailError.map(item => `Email: ${item.email} - ${item.message}`).join('');
          //  this.$notify({
          //   title: 'Error',
          //   message: listError,
          //   type: 'error'
          // });
        }
      } else {
        this.$notify({
          title: 'Error',
          message: 'Can not remove customers rows',
          type: 'error'
        });
        this.listCustomerUpload = [];
      }
    },

    onClickDeleteData() {
      this.$confirm('Do you want to remove Customers rows')
        .then(async () => {
          if (this.newsSelectedList.length > 0) {
            const result = await this.deleteCustomersList(this.newsSelectedList);
            if (result) {
              this.$notify({
                title: 'Success',
                message: 'Delete customers rows Success',
                type: 'success'
              });
              this.newsSelectedList = [];
            } else {
              this.$notify({
                title: 'Error',
                message: 'Can not remove customers rows',
                type: 'error'
              });
            }
          } else {
            this.$notify({
              title: 'Error',
              message: 'Can not remove customers rows',
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },

    handleSelectionChange(val) {
      this.newsSelectedList = val;
    },

    getIndustryName(data) {
      const id = data;
      return (this.industriesMap[id] && this.industriesMap[id].name) || 'No Data';
    },

    getRankName(data) {
      const id = data;
      return (this.ranksMap[id] && this.ranksMap[id].name) || 'No Data';
    },

    getUserName(data) {
      const id = data;
      return (this.usersMap[id] && this.usersMap[id].username) || 'No Data';
    },

    async onClickRemove(index) {
      this.$confirm(`Do you want to remove ${index.row.customerName} ?`)
        .then(async () => {
          const result = await this.deleteCustomers(index.row);
          if (result) {
            this.$notify({
              title: 'Success',
              message: `Delete customer ${result.result.title} Success`,
              type: 'success'
            });
          } else {
            this.$notify({
              title: 'Error',
              message: `Can not remove customer ${index.row.id}-> Error: ${result.message}`,
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },

    onClickEditCustomer(index) {
      const { id } = index.row;

      if (id) {
        this.formMode = 'edit';
      } else {
        this.formMode = 'editAdd';
      }

      const avatar = without(
        index.row.avatar?.split('|').map((src) => {
          return src !== '' ? { src: this.S3_STORE_ENDPOINT + '/' + src, filePath: src } : null;
        }),
        null
      );

      (this.formNews = {
        id: index.row.id,
        customerName: index.row.customerName,
        email: index.row.email,
        phone: index.row.phone,
        avatar,
        taxCode: index.row.taxCode,
        typeId: index.row.typeId,
        sizeId: index.row.sizeId,
        industryId: index.row.industryId,
        website: index.row.website,
        rankId: index.row.rankId,
        industrialParkId: index.row.industrialParkId,
        channelId: index.row.channelId,
        userInchargeId: index.row.userInchargeId,
        dateOfIncorporationCompany: index.row.dateOfIncorporationCompany
      }),
        (this.formNewsOld = cloneDeep(index.row));
      this.customerDialogVisible = true;
    },
    // Paging
    async changePage(val) {
      this.currentPage = val;

      //call api get data in next page
      const offset = (+this.currentPage - 1) * this.pageSize;
      this.formSearch.offset = offset;
      this.formSearch.pageSize = this.pageSize;
      this.formSearch.userInchargeId = this.getSaleId;
      await this.getAllCustomers(this.formSearch);
      await this.getAllCustomerAddressInPageCustomer({
        customerIdArr: this.customerIdListInPage
      });
    },
    changePageOne() {
      this.currentPage = 1;
    },

    // Form News (S)
    clearFormNews() {
      this.formNews = cloneDeep(this.formCustomerDefault);
    },

    onClickShowUploadExcelFile() {
      this.uploadExcelFileDialogVisible = true;
    },

    clearShortFormNews() {
      this.formShortNews = cloneDeep(this.formShortCustomerDefault);
    },
    // Action Button
    onClickShowAddPopup() {
      this.customerDialogVisible = true;
      this.formMode = 'add';
      this.clearFormNews();
    },

    onClickShowAddQuicklyPopup() {
      this.createCustomerQuicklyDialogVisible = true;
      this.formMode = 'add';
      this.clearFormNews();
    },

    // onClickShowInfoCustomer(scope) {
    //   this.customerInfoDialogVisible = true;
    //   this.customerId = scope.row.id;
    // },

    onClickPopupSaveNews() {
      switch (this.formMode) {
        case 'add':
          this.onSaveAddNews();
          break;
        case 'editAdd':
          this.onSaveEditCustomers();
          break;
        case 'edit':
          this.onSaveEditCustomers();
          break;
      }
    },

    async onSaveEditCustomers() {
      this.$confirm('Do you want change ?')
        .then(async () => {
          this.$refs['formNews'].validate(async (valid) => {
            if (valid) {
              try {
                const result = await this.saveEditCustomers({
                  oldData: this.formNewsOld,
                  newData: this.formNews
                });
                if (result) {
                  this.formNewsOld = {};
                  this.$notify({
                    title: 'Success',
                    message: 'Edit Customers Success',
                    type: 'success'
                  });
                } else {
                  this.$notify({
                    title: 'Error',
                    message: `Can not edit Customers Error: ${result.message}`,
                    type: 'error'
                  });
                }
                this.customerDialogVisible = false;
              } catch (error) {
                this.$notify({
                  title: this.$i18n.t('common.notify.title.error'),
                  message: this.$i18n.t('common.notify.message.edit.fail', {
                    name: 'row'
                  }),
                  type: 'error'
                });
              }
            } else {
              return false;
            }
          });
        })
        .catch((error) => {
          console.log('error: ', error);
        });
    },

    async onSaveAddNews() {
      const data = await this.saveAddCustomers([this.formNews]);

      if (data == true) {
        this.$notify({
          title: this.$i18n.t('common.notify.title.success'),
          message: this.$i18n.t('common.notify.message.save.success', {
            name: 'List Post'
          }),
          type: 'success'
        });
        this.clearFormNews();
        this.customerDialogVisible = false;
      } else {
        this.$notify({
          title: this.$i18n.t('common.notify.title.error'),
          message: this.$i18n.t('common.notify.message.save.fail', {
            name: 'List Post'
          }),
          type: 'error'
        });
      }
    },

    handleCloseAddForm(done) {
      var message = null;
      if (!isEqual(this.formNews, this.formCustomerDefault)) {
        message = this.$i18n.t('common.confirm.close.form.out', { name: 'customer' });
        this.$confirm(message)
          .then(() => {
            this.clearFormNews();
            this.customerDialogVisible = false;
            done();
          })
          .catch(() => {});
      } else {
        this.customerDialogVisible = false;
      }
    },

    handleCloseAddShortForm(done) {
      var message = null;
      if (!isEqual(this.formShortNews, this.formShortCustomerDefault)) {
        message = this.$i18n.t('common.confirm.close.form.out', { name: 'customer' });
        this.$confirm(message)
          .then(() => {
            this.clearShortFormNews();
            this.createCustomerQuicklyDialogVisible = false;
            done();
          })
          .catch(() => {});
      } else {
        this.createCustomerQuicklyDialogVisible = false;
      }
    },

    onSelectMultipleImageAddForm(value) {
      this.fileListSelectedFormNews = value;
    },

    handleDeleteImage() {
      this.fileListSelectedFormNews.map((item) => {
        remove(this.formNews.avatar, function (n) {
          return isEqual(n, item);
        });
      });
      this.formNews.avatar = this.formNews.avatar.slice();
      this.$refs.imageListAddForm.removeFromMultipleSelected();
    },

    async handleFileUpload(file) {
      this.listMailError = [];
      this.successEmailCount = 0;
      const { raw, name: fileName } = file;
      const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
      if (fileExtension !== 'xlsx') {
        this.$message.error('File không hợp lệ. Vui lòng chọn file excel có các cột: Tên công ty, Email, SĐT');
      } else {
        let excelFile = raw;
        const schema = {
          'Tên công ty': {
            prop: 'customerName',
            type: String,
            required: true
          },
          Email: {
            prop: 'email',
            type: String,
            required: true
          },
          SĐT: {
            prop: 'phone',
            type: String
          }
        };
        readXlsxFile(excelFile, { schema }).then(({ rows, errors }) => {
          if (errors.length === 0) {
            this.listCustomerUpload = rows;
            this.$message({
              message: 'Tải file thành công',
              type: 'success'
            });
          } else {
            this.$message.error('File không hợp lệ. File phải có các cột: Tên công ty, Email, SĐT');
          }
        });
      }
    },
    handleRemoveFileUpload() {
      this.listCustomerUpload = [];
      this.successEmailCount = 0;
      this.listMailError = [];
    },
    handleExceed() {
      this.$message.warning('Chỉ được tải lên 1 file');
    },
    handleCloseUploadForm(done) {
      var message = this.$i18n.t('common.confirm.close.form.out', { name: 'upload excel' });
      this.$confirm(message)
        .then(() => {
          this.clearFormNews();
          this.customerDialogVisible = false;
          this.successEmailCount = 0;
          this.listMailError = [];
          done();
        })
        .catch(() => {});
    }
  }
};
</script>

<style src="./index.scss" lang="scss" scoped />
