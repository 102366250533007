import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  users:[],
  usersMap:{},
  listUser: []
};

// import cloneDeep from 'lodash/cloneDeep'
// import { isEqual } from 'lodash';

const mutations = {
  
  [Type.SET_ALL_USERS](state, value) {
    state.users = value;
    state.usersMap = state.users.reduce(
      (cMap, c) => ({
        ...cMap,
        [c.id]: c
      }),
      {}
    )
    state.listUser = value;
  },

  async [Type.SAVE](state, value) {
    state.users = [ ...value, ...state.users]
    Promise.all(value.map( item => {
      state.listUser.push(item);
    }));
  },
  async [Type.EDIT](state, value) {
    const oldUsers = state.users
    const index = state.users.findIndex((element) => element.id == value.id);
    oldUsers[index] = value;
    state.users = oldUsers.slice(0);

    // update listUser[]
    const indexInListUser = state.listUser.findIndex((element) => element.id == value.id);
    state.listUser[indexInListUser].email = value.email
    return state.users
  },
  async [Type.DELETE](state, value) {
      await Promise.all(value.map( item => {
        const index = state.users.findIndex((element) => element.id == item.id);
        state.users.splice(index, 1)
        const indexInListUser = state.listUser.findIndex((element) => element.id == item.id);
        state.listUser.splice(indexInListUser, 1)
      }));
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};