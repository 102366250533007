<template>
  <div style="padding-top:30px" class="change-password-page">
    <b-container>
      <Widget class="widget-auth mx-auto" custom-header>
        <h5 style="text-align: center; margin-bottom: 15px; margin-top: 20px">
          <i class="el-icon-bell text-danger"></i>&nbsp;
          {{ $t('src.modules.global.components.changePassword.h3.heading') }}
          <i class="el-icon-bell text-danger"></i>&nbsp;
        </h5>
        <hr />
        <el-form :rules="rulesForm" ref="passForm" :model="passForm" label-position="top">
          <!-- Email (S) -->
          <el-form-item label="Email" prop="email">
            <el-input
              size="medium"
              v-model="passForm.email"
              type="email"
              placeholder="me@example.com"
            />
          </el-form-item>
          <!-- Email (E) -->
          <!-- Old Password (S) -->
          <el-form-item
            :label="$t('src.modules.global.components.changePassword.oldPassword')"
            prop="oldPassword"
          >
            <el-input
              size="medium"
              v-model="passForm.oldPassword"
              :type="passForm.show.old ? 'text' : 'password'"
            >
              <i
                slot="prefix"
                class="fa"
                :class="[passForm.show.old ? 'fa-eye' : 'fa-eye-slash']"
                @click="passForm.show.old = !passForm.show.old"
                style="cursor:pointer ;margin: 10px 0;"
              ></i
              >&nbsp;
            </el-input>
          </el-form-item>
          <!-- Old Password (E) -->
          <!-- New Password (S) -->
          <el-form-item
            :label="$t('src.modules.global.components.changePassword.newPassword')"
            prop="newPassword"
          >
            <el-input
              size="medium"
              v-model="passForm.newPassword"
              :type="passForm.show.new ? 'text' : 'password'"
            >
              <i
                slot="prefix"
                class="fa"
                :class="[passForm.show.new ? 'fa-eye' : 'fa-eye-slash']"
                @click="passForm.show.new = !passForm.show.new"
                style="cursor:pointer; margin: 10px 0;"
              ></i
              >&nbsp;
            </el-input>
          </el-form-item>
          <!-- New Password (E) -->
          <!-- Confirm New Password (S) -->
          <el-form-item
            :label="$t('src.modules.global.components.changePassword.confirmNewPassword')"
            prop="confirmNewPassword"
          >
            <el-input
              size="medium"
              v-model="passForm.confirmNewPassword"
              :type="passForm.show.confirm ? 'text' : 'password'"
            >
              <i
                slot="prefix"
                class="fa"
                :class="[passForm.show.confirm ? 'fa-eye' : 'fa-eye-slash']"
                @click="passForm.show.confirm = !passForm.show.confirm"
                style="cursor:pointer; margin: 10px 0;"
              ></i
              >&nbsp;
            </el-input>
          </el-form-item>
          <!-- New Password (E) -->
          <el-form-item>
            <el-button size="medium" type="primary" @click="onClickChangePassword"
              >Submit</el-button
            >
            <el-button
              size="medium"
              v-bind:style="{ display: cancelButtonDisplayStyle }"
              @click="$router.push('app/my-account')"
              type="info"
              >Cancel</el-button
            >
          </el-form-item>
        </el-form>
      </Widget>
    </b-container>
  </div>
</template>

<script>
import Widget from 'components/Widget/Widget';
import cloneDeep from 'lodash/cloneDeep';
import { mapActions } from 'vuex';

export default {
  components: { Widget },
  data() {
    let validateNewPass = (rule, value, callback) => {
      if (value === '') {
        callback(
          new Error(
            this.$i18n.t('src.modules.global.components.changePassword.rule.newPassword')
          )
        );
      } else {
        if (this.passForm.confirmNewPassword !== '') {
          this.$refs.passForm.validateField('confirmNewPassword');
        }
        callback();
      }
    };

    let validateConfirmPass = (rule, value, callback) => {
      if (value === '') {
        callback(
          new Error(
            this.$i18n.t(
              'src.modules.global.components.changePassword.rule.confirmNewPassword'
            )
          )
        );
      } else if (value !== this.passForm.newPassword) {
        callback(
          new Error(
            this.$i18n.t(
              'src.modules.global.components.changePassword.rule.compareConfirmAndNewPassword'
            )
          )
        );
      } else {
        callback();
      }
    };

    return {
      passForm: {
        email: '',
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        show: {
          old: false,
          new: false,
          confirm: false
        }
      },
      passFormDefault: {
        email: '',
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        show: {
          old: false,
          new: false,
          confirm: false
        }
      },

      isFirstTimeLogin: false,

      cancelButtonDisplayStyle: '',
      // Rules Form
      rulesForm: {
        email: [
          {
            required: true,
            message: this.$i18n.t(
              'src.modules.global.components.changePassword.rule.email'
            ),
            trigger: 'blur'
          },
          {
            type: 'email', //The type to be checked (number, email, date, etc.)
            message: this.$i18n.t(
              'src.modules.global.components.changePassword.rule.checkType_email'
            ),
            trigger: ['blur', 'change'] //change is when the tested character changes)
          }
        ],
        oldPassword: [
          {
            required: true,
            message: this.$i18n.t(
              'src.modules.global.components.changePassword.rule.oldPassword'
            ),
            trigger: 'blur'
          }
        ],
        newPassword: [
          { validator: validateNewPass, trigger: 'blur' },
          {
            required: true,
            message: this.$i18n.t(
              'src.modules.global.components.changePassword.rule.newPassword'
            )
          },
          {
            min: 8,
            message: this.$i18n.t(
              'src.modules.global.components.changePassword.rule.newPasswordLenght'
            )
          }
        ],
        confirmNewPassword: [
          { validator: validateConfirmPass, trigger: 'blur' },
          {
            required: true,
            message: this.$i18n.t(
              'src.modules.global.components.changePassword.rule.confirmNewPassword'
            )
          }
        ]
      }
    };
  },

  computed: {},

  watch: {},

  async created() {
    this.getQueryParams();
  },
  methods: {
    ...mapActions('supplierUser', ['resetPassword']),
    ...mapActions('auth', ['logoutUser']),
    ...mapActions('global', ['setGlobalReady']),

    getQueryParams() {
      this.passForm.email = this.$route.query.email || '';
      this.passFormDefault.email = this.$route.query.email || '';
      if (this.$route.query.isFirstTimeLogin == true) {
        this.$notify({
          title: this.$i18n.t('common.notify.title.login.success'),
          message: this.$i18n.t('common.notify.title.login.success'),
          type: 'success'
        });

        setTimeout(() => {
          this.$notify({
            title: 'Warning',
            message: this.$i18n.t('common.notify.warning.login.changePassword'),
            type: 'warning'
          });
        }, 5);

        this.isFirstTimeLogin = true;
        this.cancelButtonDisplayStyle = 'none';
      }
    },

    clearPassForm() {
      this.passForm = cloneDeep(this.passFormDefault);
    },

    onClickChangePassword() {
      this.$refs['passForm'].validate(async (valid) => {
        if (valid) {
          try {
            this.setGlobalReady(false);
            const { oldPassword, newPassword } = this.passForm
            const result = await this.resetPassword({ oldPassword, newPassword })
            if (result.status) {
              this.$notify({
                title: this.$i18n.t('common.notify.title.success'),
                message: this.$i18n.t('common.notify.title.success', {
                  item: 'row'
                }),
                type: 'success'
              })
              this.clearPassForm()
              //if this is a first time login change password and logout
              if (this.isFirstTimeLogin == true) {
                await this.logoutUser()
                window.location.href = '/login'
                // this.$router.push({ name: 'Login' });
              } else {
                window.location.href = '/app/dashboard'
              }
            } else {
              this.$notify({
                title: this.$i18n.t('common.notify.title.error'),
                message: result.message,
                type: 'error'
              })
              this.clearPassForm()
            }
            this.setGlobalReady(true);
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t(error.message),
              type: 'error'
            })
            this.clearPassForm()
            this.setGlobalReady(true);
          }
        } else {
          return false
        }
      })
    }
  }
};
</script>
