<template>
  <div class="dashboard-page">
    <div class="search">
      <!--  Search (S) -->
      <b-row>
        <b-col style="padding: 10px;">
          <div class="h-100">
            <Widget style="margin-bottom:0px">
              <el-row type="flex" justify="end" :gutter="24">
                <!-- Input Datepicker (S) -->
                <el-col :span="24">
                  <div class="grid-content">
                    <el-row type="flex" justify="end">
                      <el-date-picker v-model="formSearch.date" type="month" placeholder="Pick a month" :picker-options="pickerOptions" @change="handleDateChangeSynthesisStatistic" :clearable="false">
                      </el-date-picker>
                      <el-button class="btn-black-sm" @click="handleDateChangeSynthesisStatistic(formSearch.date)" type="info" size="mini"> <i class="el-icon-refresh "/></el-button>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </Widget>
          </div>
        </b-col>
      </b-row>
      <!--  Search (E) -->
    </div>
    <div
      class="sidesWrapper"
      style="
        overflow:auto; 
        max-height:900px;
        min-height:900px
      "
    >
      <div class="analyticsSide">
        <b-row>
          <b-col lg="12" sm="6" xs="12" style="padding: 10px;">
            <div class="h-100">
              <Widget>
                <el-row :gutter="24">
                  <el-col :span="12">
                    <div class="widgetInfo">
                      <div class="wiName">{{ this.$i18n.t('src.modules.dashboard.index.chart.totalNewCustomer') }}</div>
                      <div class="wiDetail">{{ totalNewCustomer }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="widgetInfo">
                      <div class="wiName">{{ this.$i18n.t('src.modules.dashboard.index.chart.totalNewCustomerActive') }}</div>
                      <div class="wiDetail">{{ totalNewCustomerActive }}</div>
                    </div>
                  </el-col>
                </el-row>
              </Widget>
              <Widget style="margin-bottom:0px">
                <el-row>
                  <el-col :span="24">
                    <h5 class="mt-0">{{ this.$i18n.t('src.modules.dashboard.customer.index.totalCustomerVerified') }}</h5>
                  </el-col>
                </el-row>
                <StackedColumnChart :series="cusVerifyMonthlySeries" :x-axis="cusVerifiedMonthlyXAxisCategory" y-axis-title="Số lượng khách hàng" title="" height="300" :enabled="false" />
              </Widget>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" sm="6" xs="12" style="padding: 10px;">
            <div class="h-100">
              <Widget>
                <el-row :gutter="24">
                  <el-col :span="6">
                    <div class="widgetInfo">
                      <div class="wiName">{{ this.$i18n.t('src.modules.dashboard.index.chart.totalOrder') }}</div>
                      <div class="wiDetail">{{ totalOrder }}</div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="widgetInfo">
                      <div class="wiName">{{ this.$i18n.t('src.modules.dashboard.index.chart.totalValOrder') }}</div>
                      <div class="wiDetail">
                        {{
                          new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND'
                          }).format(totalValOrder) || 0
                        }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="widgetInfo">
                      <div class="wiName">{{ this.$i18n.t('src.modules.dashboard.index.chart.totalOrderCompleted') }}</div>
                      <div class="wiDetail">{{ totalOrderData.totalOrderCompleted || 0 }}</div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="widgetInfo">
                      <div class="wiName">{{ this.$i18n.t('src.modules.dashboard.index.chart.totalValOrderCompleted') }}</div>
                      <div class="wiDetail">
                        {{
                          new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND'
                          }).format(totalOrderData.totalValOrderCompleted || 0) || 0
                        }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </Widget>
              <Widget style="margin-bottom:0px">
                <el-row>
                  <el-col :span="24">
                    <h5 class="mt-0">{{ this.$i18n.t('src.modules.dashboard.index.chart.totalValOrder') }}</h5>
                  </el-col>
                </el-row>
                <CombineChart
                  :series="orderTotalPriceStatisticByMonthSeries"
                  :x-axis-categories="orderTotalPriceStatisticByMonthCategories"
                  y-axis-title="Giá trung bình"
                  title=""
                  height="300"
                  :enabled="false"
                />
              </Widget>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" sm="6" xs="12" style="padding: 10px;">
            <div class="h-100">
              <Widget>
                <el-row :gutter="24">
                  <el-col :span="8">
                    <div class="widgetInfo">
                      <div class="wiName">{{ this.$i18n.t('src.modules.dashboard.index.chart.totalQuotationConfirmed') }}</div>
                      <div class="wiDetail">{{ (totalQuotationData.quotationConfirmed && totalQuotationData.quotationConfirmed.total) || 0 }}</div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="widgetInfo">
                      <div class="wiName">{{ this.$i18n.t('src.modules.dashboard.index.chart.totalQuotationUnConfirmed') }}</div>
                      <div class="wiDetail">{{ (totalQuotationData.quotationUnConfirmed && totalQuotationData.quotationUnConfirmed.total) || 0 }}</div>
                    </div>
                  </el-col>

                  <el-col :span="8">
                    <div class="widgetInfo">
                      <div class="wiName">{{ this.$i18n.t('src.modules.dashboard.index.chart.totalQuotationConverted') }}</div>
                      <div class="wiDetail">{{ (totalQuotationData.quotationlConverted && totalQuotationData.quotationlConverted.total) || 0 }}</div>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="8">
                    <div class="widgetInfo">
                      <div class="wiName">{{ this.$i18n.t('src.modules.dashboard.index.chart.valQuotationConfirmed') }}</div>
                      <div class="wiDetail">
                        {{
                          new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND'
                          }).format((totalQuotationData.quotationConfirmed && totalQuotationData.quotationConfirmed.value) || 0) || 0
                        }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="widgetInfo">
                      <div class="wiName">{{ this.$i18n.t('src.modules.dashboard.index.chart.valQuotationUnConfirmed') }}</div>
                      <div class="wiDetail">
                        {{
                          new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND'
                          }).format((totalQuotationData.quotationUnConfirmed && totalQuotationData.quotationUnConfirmed.value) || 0) || 0
                        }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="widgetInfo">
                      <div class="wiName">{{ this.$i18n.t('src.modules.dashboard.index.chart.valQuotationConverted') }}</div>
                      <div class="wiDetail">
                        {{
                          new Intl.NumberFormat('vi-VN', {
                            style: 'currency',
                            currency: 'VND'
                          }).format((totalQuotationData.quotationlConverted && totalQuotationData.quotationlConverted.value) || 0) || 0
                        }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </Widget>
              <Widget style="margin-bottom:0px">
                <el-row>
                  <el-col :span="24">
                    <h5 class="mt-0">{{ this.$i18n.t('src.modules.dashboard.quotation.index.totalValQuotation') }}</h5>
                  </el-col>
                </el-row>
                <StackedColumnChart :series="totalQuotationMonthlySeries" :x-axis="valQuotationMonthlyXAxisCategory" y-axis-title="Giá trị báo giá" title="" height="300" :enabled="false" />
              </Widget>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StackedColumnChart from 'components/Chart/StackedColumnChart.vue';
import CombineChart from 'components/Chart/CombineChart.vue';
import moment from 'moment';
import Widget from 'components/Widget/Widget.vue';

export default {
  name: 'AdminDashboard',
  components: { StackedColumnChart, CombineChart, Widget },
  data() {
    return {
      colors: {
        info: '#1A86D0',
        primary: '#005792',
        danger: '#FD5F00',
        success: '#21AE8C',
        secondary: '#798892',
        warning: '#FDA700',
        inverse: '#002B49',
        textColor: '#495057',
        gray: '#D7DFE6',
        pink: '#cc6699'
      },
      formSearch: {
        date: moment(new Date())
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('customerDashboard', ['totalCustomerVerifiedCountByDay']),
    ...mapState('orderDashboard', ['averagePriceOrderStatisticByDay', 'totalOrderData']),
    ...mapState('quotationDashboard', ['quantityValQuotationByDay', 'totalQuotationData']),

    getSaleId() {
      return this.user.id || '';
    },

    totalNewCustomer() {
      const arrData = this.totalCustomerVerifiedCountByDay && this.totalCustomerVerifiedCountByDay.length > 0 ? this.totalCustomerVerifiedCountByDay : [];
      return arrData.reduce((acc, ele) => acc + Number(ele.total[0] || 0) + Number(ele.total[1] || 0), 0);
    },

    totalNewCustomerActive() {
      const arrData = this.totalCustomerVerifiedCountByDay && this.totalCustomerVerifiedCountByDay.length > 0 ? this.totalCustomerVerifiedCountByDay : [];
      return arrData.reduce((acc, ele) => acc + Number(ele.total[0] || 0), 0);
    },

    totalOrder() {
      const arrData = this.averagePriceOrderStatisticByDay && this.averagePriceOrderStatisticByDay.length > 0 ? this.averagePriceOrderStatisticByDay : [];
      return arrData.reduce((acc, ele) => acc + Number(ele.totalQty || 0), 0);
    },

    totalValOrder() {
      const arrData = this.averagePriceOrderStatisticByDay && this.averagePriceOrderStatisticByDay.length > 0 ? this.averagePriceOrderStatisticByDay : [];
      return arrData.reduce((acc, ele) => acc + Number(ele.totalPrice || 0), 0);
    },

    cusVerifyMonthlySeries() {
      const arrData = this.totalCustomerVerifiedCountByDay && this.totalCustomerVerifiedCountByDay.length > 0 ? this.totalCustomerVerifiedCountByDay : [];
      const charDataVerified = arrData.map(obj => obj.total[0] || null);
      const charDataUnVerified = arrData.map(obj => obj.total[1] || null);
      const charDataPercent = arrData.map(obj => parseInt((obj.total[0] / (obj.total[0] + obj.total[1])) * 100) || 0);
      return [
        {
          type: 'column',
          name: this.$i18n.t('src.modules.dashboard.seller.index.verified'),
          data: charDataVerified
        },
        {
          type: 'column',
          name: this.$i18n.t('src.modules.dashboard.seller.index.unVerified'),
          data: charDataUnVerified
        },
        {
          type: 'spline',
          name: this.$i18n.t('src.modules.dashboard.customer.index.percent'),
          data: charDataPercent,
          marker: {
            lineWidth: 2,
            lineColor: 'black',
            fillColor: 'white'
          },
          yAxis: 1,
          tooltip: {
            valueSuffix: '%'
          }
        }
      ];
    },

    cusVerifiedMonthlyXAxisCategory() {
      return this.totalCustomerVerifiedCountByDay && this.totalCustomerVerifiedCountByDay.length > 0 ? this.totalCustomerVerifiedCountByDay.map(obj => obj.period) : [];
    },

    orderTotalPriceStatisticByMonthSeries() {
      // const chartData = this.averagePriceOrderStatisticByDay.map(obj => [obj.period, obj.averagePrice]);
      // return [{
      //   name: 'Average price',
      //   data: chartData
      // }]
      const chartTotalPriceData = this.averagePriceOrderStatisticByDay.map(obj => obj.totalPrice);
      // const chartAverageData = this.averagePriceOrderStatisticByDay.map(obj => obj.averagePrice);
      const series = [
        {
          name: this.$i18n.t('src.modules.dashboard.order.index.totalValue'),
          type: 'column',
          data: chartTotalPriceData,
          tooltip: {
            formatter: function() {
              return this.y + ' đ2';
            }
          }
        }
        // {
        //   name: 'Average price',
        //   type: 'spline',
        //   data: chartAverageData,
        //   tooltip: {
        //     valueSuffix: ' đ'
        //   }
        // }
      ];
      return series;
    },

    orderTotalPriceStatisticByMonthCategories() {
      return this.averagePriceOrderStatisticByDay.map(obj => obj.period);
    },

    totalQuotationMonthlySeries() {
      const arrData = this.quantityValQuotationByDay && this.quantityValQuotationByDay.length > 0 ? this.quantityValQuotationByDay : [];
      const charData = arrData.map(obj => obj.total[0] || null);
      const charDataConv = arrData.map(obj => obj.total[1] || null);
      const charDataPercent = arrData.map(obj => parseInt((obj.total[0] / (obj.total[0] + obj.total[1])) * 100) || 0);
      return [
        {
          type: 'spline',
          name: this.$i18n.t('src.modules.dashboard.quotation.index.percent'),
          data: charDataPercent,
          yAxis: 1,
          tooltip: {
            valueSuffix: '%'
          },
          marker: {
            lineWidth: 2,
            lineColor: 'black',
            fillColor: 'white'
          }
        },
        {
          type: 'column',
          name: this.$i18n.t('src.modules.dashboard.quotation.index.valUnConvert'),
          data: charData
        },
        {
          type: 'column',
          name: this.$i18n.t('src.modules.dashboard.quotation.index.valConvert'),
          data: charDataConv
        }
      ];
    },

    valQuotationMonthlyXAxisCategory() {
      return this.quantityValQuotationByDay && this.quantityValQuotationByDay.length > 0 ? this.quantityValQuotationByDay.map(obj => obj.period) : [];
    }
  },
  async created() {
    this.setGlobalReady(false);
    await Promise.all([
      this.getTotalValOfQuotatioByDay({
        startDate:
          moment(Date.now())
            .endOf('year')
            .unix() *
            1000 >
          Date.now()
            ? Date.now()
            : moment(Date.now())
                .endOf('year')
                .unix() * 1000,
        saleId: this.getSaleId
      }),
      this.getTotalOfCusRegisteredByDay({
        startDate:
          moment(Date.now())
            .endOf('year')
            .unix() *
            1000 >
          Date.now()
            ? Date.now()
            : moment(Date.now())
                .endOf('year')
                .unix() * 1000,
          saleId: this.getSaleId
      }),
      this.getAveragePriceStatisticByDay({
        startDate:
          moment(Date.now())
            .endOf('year')
            .unix() *
            1000 >
          Date.now()
            ? Date.now()
            : moment(Date.now())
                .endOf('year')
                .unix() * 1000,
        saleId: this.getSaleId
      })
    ]);
    this.setGlobalReady(true);
  },
  methods: {
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('quotationDashboard', ['getTotalValOfQuotatioByDay']),
    ...mapActions('customerDashboard', ['getTotalOfCusRegisteredByDay']),
    ...mapActions('orderDashboard', ['getAveragePriceStatisticByDay']),

    async handleDateChangeSynthesisStatistic(value) {
      this.setGlobalReady(false);
      await Promise.all([
        this.getTotalValOfQuotatioByDay({
          startDate: moment(value).unix() * 1000,
          saleId: this.getSaleId
        }),
        this.getTotalOfCusRegisteredByDay({
          startDate: moment(value).unix() * 1000,
          saleId: this.getSaleId
        }),
        this.getAveragePriceStatisticByDay({
          startDate: moment(value).unix() * 1000,
          saleId: this.getSaleId
        })
      ]);
      this.setGlobalReady(true);
    }
  }
};
</script>
<style src="../index.scss" lang="scss" scoped />
