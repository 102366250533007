<template>
  <highcharts :options="chartOptions"></highcharts>
</template>
<script>
import { Chart } from 'highcharts-vue';
export default {
  components: {
    highcharts: Chart
  },
  props: {
    series: {
      type: Array,
      default: () => []
    },
    xAxis: {
      type: Array,
      default: () => []
    },
    yAxisTitle: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    enabled: {
      type: Boolean,
      default: true
    },
    colors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    chartOptions() {
      let config = {
        chart: {
          type: 'column',
          height: this.height,
          zoomType: 'xy'
        },
        title: {
          text: this.title
        },
        xAxis: [
          {
            categories: this.xAxis,
            crosshair: true
          }
        ],
        yAxis: [
          {
            // min: 0,
            // text: this.yAxisTitle,
            // stackLabels: {
            //   enabled: true,
            //   style: {
            //     fontWeight: 'bold',
            //     color:
            //       // theme
            //       'gray'
            //   }
            // }
            // Primary yAxis
            labels: {
              style: {
                color: 'gray'
              }
            },
            title: {
              text: this.yAxisTitle,
              style: {
                color: 'gray'
              }
            }
          },
          {
            min: 0,
            max: 100,
            labels: {
              format: '{value} %',
              style: {
                color: 'gray'
              }
            },
            title: {
              text: 'Phần trăm',
              style: {
                color: 'gray'
              }
            },
            opposite: true
          }
        ],
        legend: {
          backgroundColor: 'white',
          borderColor: '#CCC',
          borderWidth: 1,
          shadow: false
        },
        tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true
            }
          }
        },
        series: this.series,
        credits: {
          enabled: false
        }
      };
      console.log('🚀 ~ file: StackedColumnChart.vue ~ line 95 ~ chartOptions ~ config', config);
      return config;
    }
  }
};
</script>