export const GET_ALL_SUPPLIER = 'GET_ALL_SUPPLIER';
export const ADD_SUPPLIER = 'ADD_SUPPLIER';
export const EDIT_SUPPLIER = 'EDIT_SUPPLIER';
export const DELETE_SUPPLIER = 'DELETE_SUPPLIER';
export const CHANGE_STATUS_SUPPLIER = 'ACTIVE_SUPPLIER';
export const GET_ALL_SUPPLIER_SELECT = 'GET_ALL_SUPPLIER_SELECT';
export const GET_SUPPLIER_INFO = 'GET_SUPPLIER_INFO';
export const GET_ALL_SUPPLIER_IN_PAGE = 'GET_ALL_SUPPLIER_IN_PAGE';
export const SET_ADD_SUPPLIER_FROM_ORDER_DETAIL = 'SET_ADD_SUPPLIER_FROM_ORDER_DETAIL';

