<template>
  <div class="auth-page">
    <b-container>
      <h5 class="auth-logo"><i class="fa fa-circle text-gray"></i>&nbsp; EI Industrial <i class="fa fa-circle text-warning"></i>&nbsp;</h5>
      <Widget class="widget-auth mx-auto" title="<h3 class='mt-0'>Login to EI</h3>" custom-header>
        <p class="widget-auth-info">Use your email to sign in.</p>
        <!-- <b-alert class="alert-sm text-center mt-2" variant="secondary" show>
          This is a real app with Node.js backend - use
          <br/>
          <span class="font-weight-bold">"admin@flatlogic.com / password"</span>
          <br/>
          to login!
        </b-alert> -->
        <form class="mt" @submit.prevent="login">
          <b-alert class="alert-sm" variant="warning" :show="!!errorMessage">
            {{ errorMessage }}
          </b-alert>
          <div class="form-group">
            <input class="form-control no-border" ref="email" required type="email" name="email" placeholder="Email" />
          </div>
          <div class="form-group">
            <input class="form-control no-border" ref="password" required type="password" name="password" placeholder="Password" />
          </div>
          <div class="form-group">
            <el-row type="flex" justify="end">
              <el-select v-model="language" @change="changeClick" size="mini" style="width: 120px">
                <el-option :selected="language" v-for="item in languageOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-row>
          </div>
          <b-button type="submit" size="sm" class="auth-btn mb-3" variant="dark">{{ this.isFetching ? 'Loading...' : 'Login' }}</b-button>
          <!-- <p class="widget-auth-info">or sign in with</p>
          <div class="social-buttons">
            <b-button @click="googleLogin" variant="primary" class="social-button mb-2">
              <i class="social-icon social-google"></i>&nbsp;
              <p class="social-text">GOOGLE</p>
            </b-button>
            <b-button @click="microsoftLogin" variant="success" class="social-button">
              <i class="social-icon social-microsoft"></i>&nbsp;
              <p class="social-text">MICROSOFT</p>
            </b-button>
          </div> -->
        </form>

        <p class="widget-auth-info">Don't have an account? Sign up now!</p>
        <router-link class="d-block text-center" to="register">Create an Account</router-link>
      </Widget>
    </b-container>
    <footer class="auth-footer">
      <!-- 2019 &copy; Sing App Vue Admin Dashboard Template. -->
    </footer>
  </div>
</template>

<script>
import Widget from 'components/Widget/Widget';
import { mapState, mapActions } from 'vuex';
import { AUTH_LANGUAGE } from 'core/constants';
// import { getMessaging, getToken } from 'firebase/messaging';
// import { initializeApp } from 'firebase/app';
const lang = localStorage.getItem(AUTH_LANGUAGE);
export default {
  name: 'AdminLoginPage',
  components: { Widget },
  data() {
    return {
      language: lang ? lang : 'vi-VN',
      languageOptions: [
        { value: 'en-US', label: 'English' },
        { value: 'vi-VN', label: 'Vietnamese' }
      ],
      firebaseConfig: {
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID,
        measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
      }
    };
  },
  computed: {
    ...mapState('auth', {
      isFetching: (state) => state.isFetching,
      errorMessage: (state) => state.errorMessage
    })
  },
  created() {
    const token = this.$cookies.get('access_token');
    if (token) {
      this.$router.push({ name: 'Dashboard' });
    }
  },
  methods: {
    ...mapActions('auth', ['loginUser', 'setLanguage', 'receiveToken', 'receiveLogin']),
    async login() {
      let currentTokenFcm = '';
      // const fapp = initializeApp(this.firebaseConfig);
      // const messaging = getMessaging(fapp);
      // await getToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_VAPI_KEY })
      //   .then((currentToken) => {
      //     if (currentToken) {
      //       currentTokenFcm = currentToken;
      //     } else {
      //       // Show permission request UI
      //       console.log('No registration token available. Request permission to generate one.');
      //       // ...
      //     }
      //   })
      //   .catch((err) => {
      //     console.log('An error occurred while retrieving token. ', err);
      //     // ...
      //   });
      const email = this.$refs.email.value;
      const password = this.$refs.password.value;
      if (email.length !== 0 && password.length !== 0) {
        const data = await this.loginUser({ email, password, currentTokenFcm });
        if (data.type == 'success') {
          //if this is first time user login redirect to changePassword page
          const { changePassword, email } = data?.result?.user;
          if (changePassword == false) {
            this.$router.push({
              name: 'ChangePassword',
              query: { email, isFirstTimeLogin: true }
            });
          } else {
            this.$router.push({ name: 'Dashboard' });
          }
        }
      }
    },
    changeClick() {
      this.setLanguage(this.language);
    },
    googleLogin() {
      this.loginUser({ social: 'google' });
    },
    microsoftLogin() {
      this.loginUser({ social: 'microsoft' });
    }
  }
};
</script>
