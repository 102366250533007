import Service from 'core/service';
// import { cloneDeep } from 'lodash';
import * as Type from 'modules/industry/store/types';

const service = new Service();

const getAllIndustries = async ({ commit }) => {
  const params = {};
  // Get All News
  return service
    .rest('customer_industry/getListPaging', params)
    .then(res => {
      console.log('res customer_industry/getListPaging:', res);
      commit(Type.SET_ALL_INDUSTRIES, res.rows);
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
};

export { getAllIndustries };
