import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
    orders: [],
    totalRows: 0,
    orderAddLocal: [],
    countOrderByStatus: null,
    gmv: 0,
    supplier: null,
    statisticOrder: {},
    orderDataExportExcel: []
};

import cloneDeep from 'lodash/cloneDeep';
import { isEqual } from 'lodash';

const mutations = {

    [Type.SET_ALL_ORDER](state, value) {
        state.orders = value.rows;
        state.totalRows = value.totalRows;
    },

    [Type.SET_ALL_ORDER_SUPPLIER](state, value) {
        state.orders = value.rows;
        state.totalRows = value.totalRows;
        state.supplier = value.supplier;
    },

    [Type.SET_ORDER_LOCAL](state, value) {
        console.log(value);
        const oldOrderLocal = state.orderAddLocal;
        state.orderAddLocal = [...oldOrderLocal, ...[cloneDeep(value)]];
    },

    [Type.SET_ORDER_ADD](state, value) {
        const oldOrders = state.orders;
        // add new data
        state.orders = [...cloneDeep(value), ...oldOrders];
        state.orderAddLocal = [];
    },

    [Type.SET_ORDER_UPDATE](state, value) {
        const oldOrder = state.orders;
        const { id } = value;
        const objIndex = state.orders.findIndex((obj => obj.id === id));
        oldOrder[objIndex] = value;
        state.orders = oldOrder.slice(0);
    },

    [Type.SET_PARTNER_UPDATE_LOCAL](state, value) {
        const { oldData, newData } = value
        // File oldData        
        const oldPartnerAddLocal = state.partnerAddLocal.map(item => {
            if (isEqual(item, oldData)) {
                item = newData
            }
            return item
        })
        state.partnerAddLocal = oldPartnerAddLocal.slice(0);
    },

    [Type.SET_DELETE_ORDER](state, value) {
        const oldData = cloneDeep(state.orders);
        value.map(item => {
            const { id } = item
            const objIndex = oldData.findIndex((obj) => obj.id === id);
            oldData.splice(objIndex, 1)
        });
        state.orders = oldData.slice(0)
    },

    [Type.SET_DELETE_ORDER_LOCAL](state, value) {
        const oldData = cloneDeep(state.orderAddLocal);
        value.map(item => {
            const objIndex = oldData.findIndex((obj) => {
                return isEqual(obj, item)
            });
            oldData.splice(objIndex, 1)
        })
        state.ord0erAddLocal = oldData.slice(0);
    },

    [Type.SET_ALL_ORDER_DATA_EXPORT_EXCEL](state, value) {
        state.orderDataExportExcel = value;
    }
};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};