import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  files: []
};

import cloneDeep from 'lodash/cloneDeep';

const mutations = {
  [Type.SET_ALL_FILE](state, files) {
    state.files = files;
  },

  [Type.ADD_FILE](state, value) {
    state.files.push(value);
  },

  [Type.DELETE_FILE](state, value) {

    const { id, fileName } = value[0];

    const found = state.files.findIndex(
      element => element.id == id || element.fileName == fileName
    );
    return state.files.splice(found, 1);
  },

  [Type.DELETE_FILES](state, value) {
    const oldData = cloneDeep(state.files);
    value.map(item => {
      const { id } = item;
      const objIndex = oldData.findIndex(obj => obj.id === id);
      oldData.splice(objIndex, 1);
    });
    state.files = oldData.slice(0);
  },

  [Type.CELAR_ALL_FILE](state) {
    state.files = [];
  }

  // async [Type.REMOVE_FILE](state, value) {
  //     const found = state.filePage.findIndex((element) => element.id == value || element.fileName == value);
  //     return state.filePage.splice(found, 1)
  // },
  // async [Type.CLEAR_FILE](state) {
  //     console.log(state);
  //     // const found = state.filePage.findIndex((element) => element.id == value || element.fileName == value);
  //     // return state.filePage.splice(found, 1)
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
