<template>
  <el-dialog title="Upload Image" :visible="dialogAddProductVisible" destroy-on-close class="nat-upload-image" @close="emitCloseUploadFile()" :append-to-body="true">
    <Widget>
      <el-row :gutter="20">
        <el-col :span="6" v-if="companyOptions.length > 0"
          ><div class="grid-content">
            <el-select size="medium" v-model="formSearch.companyId" placeholder="Select Company" default-first-option>
              <el-option v-for="item in companyOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select></div
        ></el-col>

        <el-col :span="6" v-if="moduleOptions.length > 0"
          ><div class="grid-content">
            <el-select default-first-option filterable size="medium" v-model="formSearch.module" placeholder="Select Module">
              <el-option v-for="item in moduleOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select></div
        ></el-col>

        <el-col :span="6" v-if="categoryOptions.length > 0"
          ><div class="grid-content">
            <el-select default-first-option size="medium" filterable v-model="formSearch.categoryId" placeholder="Select Category" @change="onChangeSelectedCategory">
              <el-option v-for="item in categoryOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select></div
        ></el-col>
      </el-row>
    </Widget>

    <Widget v-if="isMultiple">
      <el-row class="nat-select-image-widget">
        <vue-element-loading :active="loadingFile" spiner="line-scale" />
        <vue-select-image
          ref="imageListUploadFile"
          :h="'50px'"
          :w="'50px'"
          :data-images="dataImages"
          :is-multiple="isMultiple"
          :selected-images="initialSelected"
          @onselectmultipleimage="onSelectMultipleImage"
        >
        </vue-select-image>
      </el-row>
    </Widget>

    <Widget v-if="!isMultiple">
      <el-row class="nat-select-image-widget">
        <vue-element-loading :active="loadingFile" spiner="line-scale" />
        <vue-select-image ref="imageListUploadFile" :h="'50px'" :w="'50px'" :data-images="dataImages" @onselectimage="onSelectImage"> </vue-select-image>
      </el-row>
    </Widget>

    <span slot="footer" class="dialog-footer">
      <el-row :gutter="24">
        <el-col :span="12"
          ><div class="grid-content">
            <el-row type="flex">
              <!-- Upload (S) -->
              <el-upload ref="uploadFiles" action="" multiple :auto-upload="false" :show-file-list="false" :on-change="handleFileUpload">
                <el-button size="small" type="primary" :loading="loadingUploadLocal" :disabled="loadingUploadLocal"><i class="el-icon-upload2"></i></el-button>
              </el-upload>
              <!-- Upload (E) -->
            </el-row>
          </div></el-col
        >
        <el-col :span="12"
          ><div class="grid-content">
            <el-row type="flex" justify="end">
              <el-button size="small" @click="emitCloseUploadFile">Close</el-button>
              <el-button size="small" type="primary" @click="emitChooseUploadFile">Choose</el-button>
            </el-row>
          </div></el-col
        >
      </el-row>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { asyncImgChecked } from 'core/helpers';

function defaultState() {
  return {
    formLabelWidth: '120px',
    fileList: [],

    formSearch: {
      companyId: null,
      categoryId: null,
      module: null
    },
    initialSelected: null,
    loadingUploadLocal: false,
    loadingFile: false
  };
}

export default {
  name: 'UploadFile',

  props: {
    show: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: '*'
    },
    companyOptions: {
      type: Array,
      default: () => []
    },
    categoryOptions: {
      type: Array,
      default: () => []
    },
    moduleOptions: {
      type: Array,
      default: () => []
    },
    isMultiple: {
      type: Boolean,
      default: true
    }
  },

  data: defaultState,

  computed: {
    ...mapState('fileManagement', ['files']),
    ...mapState('auth', ['user']),

    dataImages() {
      return this.files.map(item => {
        item.src = process.env.VUE_APP_S3_STORE_ENDPOINT + '/' + item.filePath;
        return item;
      });
    },

    dialogAddProductVisible() {
      return this.show;
    }
  },

  watch: {
    async categoryOptions() {
      if (this.categoryOptions.length > 0) {
        this.formSearch.categoryId = this.categoryOptions[0].value;
      }
    },
    async moduleOptions() {
      if (this.moduleOptions.length > 0) {
        this.formSearch.module = this.moduleOptions[0].value;
      }
    },
    async show() {
      if (this.show === true && this.files.length === 0) {
        if (this.moduleOptions.length > 0) {
          this.formSearch.module = this.moduleOptions[0].value;
        }
        if (this.categoryOptions.length > 0) {
          this.formSearch.categoryId = this.categoryOptions[0].value;
        }
        if (this.formSearch.categoryId && this.formSearch.module) {
          await this.getAllFileUpload(this.formSearch.module, this.formSearch.categoryId);
        }
      }
    }
  },

  destroyed() {
    this.resetAllState();
  },

  methods: {
    ...mapActions('global', ['uploadFile']),
    ...mapActions('fileManagement', ['getAllFile', 'clearAllFile']),

    async getAllFileUpload(module, categoryId) {
      this.loadingFile = true;
      await this.getAllFile({
        module,
        categoryId
      });
      this.loadingFile = false;
    },

    onChangeSelectedCategory() {
      this.getAllFileUpload(this.formSearch.module, this.formSearch.categoryId);
    },

    resetAllState() {
      this.clearAllFile();

      //reset all state but keep formSearch option
      Object.assign(this.$data, defaultState(), { formSearch: this.formSearch });
    },

    // Action Button (S)
    emitCloseUploadFile() {
      this.resetAllState();
      this.$emit('onCloseUploadFile');
    },

    emitChooseUploadFile() {
      this.$refs.imageListUploadFile.removeFromMultipleSelected();
      this.$emit('onSelectedFiles', this.fileList);
    },

    async handleFileUpload(file) {
      asyncImgChecked(file)
        .then(async () => {
          const { companyId } = this.user;
          this.loadingUploadLocal = true;
          await this.uploadFile({
            companyId,
            prefix: this.formSearch.module,
            categoryId: this.formSearch.categoryId,
            file
          });
          this.loadingUploadLocal = false;
        })
        .catch(error => {
          console.log('error catch: ', error);
          this.loadingUploadLocal = false;
          this.$message.error(`${error.message}`);
          return false;
        });
    },
    // Action Button (E)

    // this.$refs.upload.clearFiles()
    onSelectMultipleImage(value) {
      this.fileList = value;
    },
    onSelectImage(value) {
      this.fileList = value;
    }
  }
};
</script>
<style lang="scss" scoped>
.nat-upload-image {
  /deep/ .vue-select-image__item {
    margin: 0 6px 6px 0;
  }
}
.nat-select-image-widget {
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
}
</style>
