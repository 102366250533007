<template>
  <div class="customer-detail">
    <div class="wrapper-header">
      <span>{{ $i18n.t('src.components.AppLeftMenu.index.customer_management') }}</span> <i class="fa fa-angle-right pl-2 pr-3"></i
      ><span class="customer-name">{{ (customerDetail && customerDetail.customerName) || textNotFound }}</span>
    </div>
    <div class="wrapper-content">
      <div class="cus-leftsidebar">
        <div class="cus-leftside-top">
          <img :src="S3_STORE_ENDPOINT + '/' + getCustomerAvatar(customerDetail && customerDetail.avatar)" alt="" class="cus-leftside-logo" />
          <div class="cus-leftside-name">{{ (customerDetail && customerDetail.customerName) || textNotFound }}</div>
          <div v-show="false" class="cus-leftside-desc">Diamon CTY sản xuất</div>
        </div>
        <div class="cus-leftside-detail">
          <div class="detail-item">
            <div class="detail-item-icon">
              <i class="fa fa-phone"></i>
            </div>
            <div class="detail-item-main">
              <div class="detail-item-desc">{{ $i18n.t('src.modules.customerDetail.index.phone') }}</div>
              <div class="detail-item-content">{{ (customerDetail && customerDetail.phone) || textNotFound }}</div>
            </div>
          </div>
        </div>
        <div class="cus-leftside-detail">
          <div class="detail-item">
            <div class="detail-item-icon">
              <i class="fa fa-envelope"></i>
            </div>
            <div class="detail-item-main">
              <div class="detail-item-desc">{{ $i18n.t('src.modules.customerDetail.index.email') }}</div>
              <div class="detail-item-content">{{ (customerDetail && customerDetail.email) || textNotFound }}</div>
            </div>
          </div>
        </div>
        <div class="cus-leftside-detail">
          <div class="detail-item">
            <div class="detail-item-icon">
              <i class="fa fa-map-marker"></i>
            </div>
            <div class="detail-item-main">
              <div class="detail-item-desc">{{ $i18n.t('src.modules.customerDetail.index.address') }}</div>
              <div class="detail-item-content">{{ (customerDetail && customerDetail.address) || textNotFound }}</div>
            </div>
          </div>
        </div>
        <div class="cus-leftside-detail">
          <div class="detail-item">
            <div class="detail-item-icon">
              <i class="fa fa-file-text"></i>
            </div>
            <div class="detail-item-main">
              <div class="detail-item-desc">{{ $i18n.t('src.modules.customerDetail.index.taxCode') }}</div>
              <div class="detail-item-content">{{ (customerDetail && customerDetail.taxCode) || textNotFound }}</div>
            </div>
          </div>
        </div>
        <div class="cus-leftside-detail">
          <div class="detail-item">
            <div class="detail-item-icon">
              <i class="fa fa-building"></i>
            </div>
            <div class="detail-item-main">
              <div class="detail-item-desc">{{ $i18n.t('src.modules.customerDetail.index.industrialPark') }}</div>
              <div class="detail-item-content">{{ (customerDetail && customerDetail.industrialPark && customerDetail.industrialPark.name) || textNotFound }}</div>
            </div>
          </div>
        </div>
        <div class="cus-leftside-detail">
          <div class="detail-item">
            <div class="detail-item-icon">
              <i class="fa fa-calendar"></i>
            </div>
            <div class="detail-item-main">
              <div class="detail-item-desc">{{ $i18n.t('src.modules.customerDetail.index.industry') }}</div>
              <div class="detail-item-content">{{ (customerDetail && customerDetail.industry && customerDetail.industry.name) || textNotFound }}</div>
            </div>
          </div>
        </div>
        <div class="cus-leftside-detail">
          <div class="detail-item">
            <div class="detail-item-icon">
              <i class="fa fa-birthday-cake"></i>
            </div>
            <div class="detail-item-main">
              <div class="detail-item-desc">{{ $i18n.t('src.modules.customerDetail.index.dateOfIncorporationCompany') }}</div>
              <div class="detail-item-content">{{ customerDetail && customerDetail.dateOfIncorporationCompany | formattedDate }}</div>
            </div>
          </div>
        </div>
        <div class="cus-leftside-detail">
          <div class="detail-item">
            <div class="detail-item-icon">
              <i class="fa fa-calendar"></i>
            </div>
            <div class="detail-item-main">
              <div class="detail-item-desc">{{ $i18n.t('src.modules.customerDetail.index.createdAt') }}</div>
              <div class="detail-item-content">{{ customerDetail && customerDetail.createdAt | formattedDate }}</div>
            </div>
          </div>
        </div>
        <div class="text-center wrapper-btn">
          <el-button type="info" class="btn-black-gradient" @click="onClickEditCustomer()"><i class="fa fa-edit"></i> {{ $i18n.t('common.label.edit') }}</el-button>
        </div>
      </div>
      <div class="cus-maincontent">
        <b-tabs nav-class="bg-transparent" class="tabs-content">
          <b-tab :title="$i18n.t('src.modules.customerDetail.index.debtsAndEICash')" active>
            <div class="tb-content">
              <div class="tb-thead">
                <span>{{ $i18n.t('src.modules.customerDetail.index.debts') }}</span>
              </div>
              <div class="tb-tbody">
                <table>
                  <tr>
                    <td class="w-20p">
                      <span class="body-title">{{ $i18n.t('src.modules.customerDetail.index.debtsLimit') }}</span>
                      <span class="body-main"><strong>0 VNĐ</strong></span>
                    </td>
                    <td class="w-20p">
                      <span class="body-title">{{ $i18n.t('src.modules.customerDetail.index.overdueDebt') }}</span>
                      <span class="body-main"><strong>0 VNĐ</strong></span>
                    </td>
                  </tr>
                  <tr>
                    <td class="w-20p">
                      <span class="body-title">{{ $i18n.t('src.modules.customerDetail.index.totalLiabilities') }}</span>
                      <span class="body-main"><strong>0 VNĐ</strong></span>
                    </td>
                    <td class="w-20p">
                      <span class="body-title">{{ $i18n.t('src.modules.customerDetail.index.paymentDueDate') }}</span>
                      <span class="body-main"><strong>--/--/---</strong></span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="tb-content">
              <div class="tb-thead">
                <span>EI Cash</span>
              </div>
              <div class="tb-tbody">
                <table>
                  <tr>
                    <td class="w-20p">
                      <span class="body-title">EI Cash</span>
                      <span class="body-main"
                        ><strong>{{ customerDetail && customerDetail.eiCash | formattedPrice }}</strong></span
                      >
                    </td>
                    <td class="w-20p">
                      <span class="body-title">{{ $i18n.t('src.modules.customerDetail.index.expiryDate') }}</span>
                      <span class="body-main"><strong>--/--/----</strong></span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$i18n.t('src.modules.comment.index.col.order')">
            <div class="tb-content">
              <div class="tb-thead expired">
                <span>{{ $i18n.t('src.modules.customerDetail.index.ordersAreDueForPayment') }}</span> <i class="fa fa-bars"></i>
              </div>
              <div class="tb-tbody">
                <table>
                  <tr>
                    <td class="">
                      <span class="body-title">{{ $i18n.t('src.modules.order.index.orderCode') }}</span>
                      <span class="body-main">{{ textNotFound }}</span>
                    </td>
                    <td class="">
                      <span class="body-title">{{ $i18n.t('src.modules.customerDetail.index.debts') }}</span>
                      <span class="body-main">0 VNĐ</span>
                    </td>
                    <td class="">
                      <span class="body-title">{{ $i18n.t('src.modules.customerDetail.index.paymentDueDate') }}</span>
                      <span class="body-main">--/--/---</span>
                    </td>
                    <td class="td-action">
                      <a href="#"><i class="fa fa-eye"></i></a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="tb-content">
              <div class="tb-thead">
                <span>{{ $i18n.t('src.modules.customerDetail.index.allOrder') }}</span> <i class="fa fa-bars"></i>
              </div>
              <div class="tb-tbody">
                <table v-loading="isLoadingOrderTable">
                  <tr v-for="order in orders" :key="order.id">
                    <td class="">
                      <span class="body-title">{{ $i18n.t('src.modules.order.index.orderCode') }}</span>
                      <span class="body-main">{{ order.code || textNotFound }}</span>
                    </td>
                    <td class="">
                      <span class="body-title">{{ $i18n.t('src.modules.order.index.totalPrice') }}</span>
                      <span class="body-main">{{ order.totalSalePrice | formattedPrice }}</span>
                    </td>
                    <td class="">
                      <span class="body-title">{{ $i18n.t('src.modules.order.index.createdAt') }}</span>
                      <span class="body-main">{{ order.createdAt | formattedDate }}</span>
                    </td>
                    <td class="td-action">
                      <a @click="onClickShowOrderDetail(order)"><i class="fa fa-eye"></i></a>
                    </td>
                  </tr>
                </table>
                <!--  Pagination (S) -->
                <vue-paging-crazy
                  v-model="currentPageOrder"
                  :page-count="pageCountOrder"
                  :page-range="pageSizeOrder"
                  :data-count="totalRows"
                  :label-options="{
                    showing: $i18n.t('common.paging.label.showing'),
                    to: $i18n.t('common.paging.label.to'),
                    of: $i18n.t('common.paging.label.of'),
                    entries: $i18n.t('common.paging.label.entries'),
                    prevText: $i18n.t('common.paging.label.prevText'),
                    nextText: $i18n.t('common.paging.label.nextText')
                  }"
                  class="pagination"
                />
                <!--  Pagination (E) -->
              </div>
            </div>
          </b-tab>
          <b-tab :title="$i18n.t('src.modules.customerDetail.index.quotation')">
            <div class="tb-content">
              <div class="tb-thead">
                <span>{{ $i18n.t('src.modules.customerDetail.index.allQuotation') }}</span> <i class="fa fa-bars"></i>
              </div>
              <div class="tb-tbody">
                <table v-loading="isLoadingQuotationTable">
                  <tr v-for="quotation in quotationsPaging" :key="quotation.id">
                    <td class="">
                      <span class="body-title">{{ $i18n.t('src.modules.quotation.index.quotationCode') }}</span>
                      <span class="body-main">{{ quotation.code || textNotFound }}</span>
                    </td>
                    <td class="">
                      <span class="body-title">{{ $i18n.t('src.modules.quotation.index.total') }}</span>
                      <span class="body-main">{{ quotation.total | formattedPrice }}</span>
                    </td>
                    <td class="">
                      <span class="body-title">{{ $i18n.t('src.modules.order.index.createdAt') }}</span>
                      <span class="body-main">{{ quotation.createdAt | formattedDate }}</span>
                    </td>
                    <td class="td-action">
                      <a @click="onClickShowQuotationDetail(quotation)"><i class="fa fa-eye"></i></a>
                    </td>
                  </tr>
                </table>
                <!--  Pagination (S) -->
                <vue-paging-crazy
                  v-model="currentPageQuotation"
                  :page-count="pageCountQuotation"
                  :page-range="pageSizeQuotation"
                  :data-count="totalRowsQuotation"
                  :label-options="{
                    showing: $i18n.t('common.paging.label.showing'),
                    to: $i18n.t('common.paging.label.to'),
                    of: $i18n.t('common.paging.label.of'),
                    entries: $i18n.t('common.paging.label.entries'),
                    prevText: $i18n.t('common.paging.label.prevText'),
                    nextText: $i18n.t('common.paging.label.nextText')
                  }"
                  class="pagination"
                />
                <!--  Pagination (E) -->
              </div>
            </div>
          </b-tab>
          <b-tab :title="$i18n.t('src.modules.customerDetail.index.staffInCharge')">
            <div class="tb-content">
              <div class="tb-thead">
                <span>{{ $i18n.t('src.modules.customerDetail.index.staffInCharge') }}</span> <i class="fa fa-bars"></i>
              </div>
              <div class="tb-tbody">
                <table>
                  <tr v-show="false">
                    <td class="w-20p">
                      <span class="body-title">{{ $i18n.t('src.modules.role.index.name') }}</span>
                      <span class="body-main"> {{ textNotFound }} <Br></Br></span>
                    </td>
                    <td class="w-20p">
                      <span class="body-title">{{ $i18n.t('src.modules.role.index.title') }}</span>
                      <span class="body-main">{{ textNotFound }}</span>
                    </td>
                    <td class="w-20p">
                      <span class="body-title">{{ $i18n.t('src.modules.profile.index.phone') }}</span>
                      <span class="body-main">{{ textNotFound }}</span>
                    </td>
                    <td class="">
                      <span class="body-title">Email</span>
                      <span class="body-main">{{ textNotFound }}</span>
                    </td>
                    <td class="td-action">
                      <a href="#"><i class="fa fa-pencil"></i></a>
                    </td>
                  </tr>
                </table>
                <br />
                <div class="btn-add-person">
                  <button>+ {{ $i18n.t('src.modules.customerDetail.index.addAStaffInCharge') }}</button>
                </div>
              </div>
            </div>
            <div class="tb-content">
              <div class="tb-thead">
                <span>{{ $i18n.t('src.modules.customerDetail.index.eiStaffInCharge') }}</span> <i class="fa fa-bars"></i>
              </div>
              <div class="tb-tbody">
                <table>
                  <tr v-show="customerDetail && customerDetail.userIncharge">
                    <td class="w-20p">
                      <span class="body-title">{{ $i18n.t('src.modules.role.index.name') }}</span>
                      <span class="body-main">{{
                        customerDetail && customerDetail.userIncharge ? customerDetail.userIncharge.firstName + ' ' + customerDetail.userIncharge.lastName : textNotFound
                      }}</span>
                    </td>
                    <td class="w-20p">
                      <span class="body-title">{{ $i18n.t('src.modules.role.index.title') }}</span>
                      <span class="body-main">{{ (customerDetail && customerDetail.userIncharge && customerDetail.userIncharge.role && customerDetail.userIncharge.role.name) || textNotFound }}</span>
                    </td>
                    <td class="w-20p">
                      <span class="body-title">{{ $i18n.t('src.modules.profile.index.phone') }}</span>
                      <span class="body-main">{{ (customerDetail && customerDetail.userIncharge && customerDetail.userIncharge.phone) || textNotFound }}</span>
                    </td>
                    <td class="">
                      <span class="body-title">Email</span>
                      <span class="body-main">{{ (customerDetail && customerDetail.userIncharge && customerDetail.userIncharge.email) || textNotFound }}</span>
                    </td>
                    <td class="td-action">
                      <a href="#"><i class="fa fa-pencil"></i></a>
                    </td>
                  </tr>
                </table>
                <br v-show="customerDetail && !customerDetail.userIncharge" />
                <div class="btn-add-person">
                  <button>+ {{ $i18n.t('src.modules.customerDetail.index.addAStaffInCharge') }}</button>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$i18n.t('src.modules.customerDetail.index.activityHistory')">
            <div class="tb-content">
              <div class="tb-thead">
                <span>{{ $i18n.t('src.modules.customerDetail.index.activityHistory') }}</span>
              </div>
              <div class="tb-tbody"></div>
            </div>
          </b-tab>
          <b-tab :title="$i18n.t('src.modules.customerDetail.index.account')">
            <b-row>
              <b-col cols="6" style="border-right: 1px solid #ebeef5">
                <div class="tb-content">
                  <div class="tb-thead">
                    <span>{{ $i18n.t('src.modules.order.detail.supplierAddress.dialog.title') }}</span>
                  </div>
                  <div class="tb-tbody">
                    <CustomerAddress :customer_id="this.customerId" />
                  </div>
                </div>
                <div class="tb-content">
                  <div class="tb-thead">
                    <span>{{ $i18n.t('src.modules.order.index.invoiceInfo') }}</span>
                  </div>
                  <div class="tb-tbody">
                    <CustomerInvoiceAddress :customer_id="this.customerId" />
                  </div>
                </div>
              </b-col>
              <b-col cols="4" class="pr-5">
                <div class="tb-content">
                  <div class="tb-thead">
                    <span>{{ $i18n.t('src.modules.customerDetail.index.accountInformation') }}</span>
                  </div>
                  <div class="tb-tbody">
                    <CustomerProfile :customer_id="this.customerId"/>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <!-- Popup Upload File (S) -->
    <UploadFile
      :show="showUploadFile"
      @onCloseUploadFile="handleOnCloseUploadFile"
      @onSelectedFiles="handleOnSelectedFiles"
      :module-options="moduleOptions"
      :category-options="categoryOptions"
      :accept="'image/*'"
    />
    <!-- Popup Upload File (E) -->

    <!-- Popup Add Customer (S) -->
    <el-dialog :title="$i18n.t('common.label.edit')" :visible.sync="customerDialogVisible" :before-close="handleCloseForm" width="52%" :close-on-click-modal="false">
      <el-form ref="formCustomer" :rules="rulesForm" :model="formCustomer" label-width="200px">
        <!-- Upload Image (S) -->
        <el-form-item :label="$t('src.modules.customer.index.avatar')" prop="avatar">
          <el-row>
            <el-col>
              <el-button type="primary" icon="el-icon-circle-plus-outline" size="mini" @click="handleOpenPopupUploadFile"></el-button>
              <el-button v-if="showCustomerImages" type="danger" icon="el-icon-delete" size="mini" @click="handleDeleteImage"></el-button>
            </el-col>
          </el-row>
          <el-row v-if="showCustomerImages">
            <el-col>
              <vue-select-image :h="'200px'" :w="'200px'" ref="imageListAddForm" :data-images="customerImages" :is-multiple="true" @onselectmultipleimage="onSelectMultipleImageAddForm">
              </vue-select-image>
            </el-col>
          </el-row>
        </el-form-item>
        <!-- Upload Image (E) -->
        <!-- customerName (S) -->
        <el-form-item :label="$i18n.t('src.modules.customer.index.customerName')" prop="customerName">
          <el-input size="medium" class="input_add" v-model="formCustomer.customerName" />
        </el-form-item>
        <!-- customerName (E) -->

        <!-- email (S) -->
        <el-form-item :label="$i18n.t('src.modules.customer.index.email')" prop="email">
          <el-input size="medium" type="email" class="input_add" v-model="formCustomer.email" />
        </el-form-item>
        <!-- email (E) -->

        <!-- phone (S) -->
        <el-form-item :label="$i18n.t('src.modules.customer.index.phone')" prop="phone">
          <el-input size="medium" type="text" class="input_add" v-model="formCustomer.phone" />
        </el-form-item>
        <!-- phone (E) -->

        <!-- dateOfIncorporationCompany (S) -->
        <el-form-item :label="$i18n.t('src.modules.customer.index.dateOfIncorporationCompany')" prop="dateOfIncorporationCompany">
          <el-date-picker v-model="formCustomer.dateOfIncorporationCompany" type="date" size="small" value-format="yyyy-MM-dd" style="width: 99.9%"> </el-date-picker>
        </el-form-item>
        <!-- dateOfIncorporationCompany (E) -->

        <!-- taxCode (S) -->
        <el-form-item :label="$i18n.t('src.modules.customer.index.taxCode')" prop="taxCode">
          <el-input size="medium" class="input_add" v-model="formCustomer.taxCode" />
        </el-form-item>
        <!-- taxCode (E) -->

        <!-- type (S)  -->
        <el-form-item :label="$i18n.t('src.modules.customer.index.typeId')" prop="typeId">
          <el-select size="medium" clearable placeholder="Select Type" v-model="formCustomer.typeId" width="100%" filterable>
            <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <!-- type (E)  -->

        <!-- Size (S) -->
        <el-form-item :label="$i18n.t('src.modules.customer.index.size')" prop="sizeId">
          <el-select size="medium" clearable v-model="formCustomer.sizeId" placeholder="Select Size" width="100%" filterable>
            <el-option v-for="item in sizeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <!-- Size (S) -->

        <!-- Industry (S) -->
        <el-form-item :label="$i18n.t('src.modules.customer.index.industry')" prop="industryId">
          <el-select size="medium" clearable v-model="formCustomer.industryId" placeholder="Select Industry" width="100%" filterable>
            <el-option v-for="item in industryList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <!-- Industry (S) -->

        <!-- taxCode (S) -->
        <el-form-item :label="$i18n.t('src.modules.customer.index.website')" prop="taxCode">
          <el-input size="medium" class="input_add" v-model="formCustomer.website" />
        </el-form-item>
        <!-- taxCode (E) -->

        <!-- Rank (S) -->
        <el-form-item :label="$i18n.t('src.modules.customer.index.rank')" prop="rankId">
          <el-select size="medium" clearable v-model="formCustomer.rankId" placeholder="Select Rank" width="100%" filterable>
            <el-option v-for="item in rankList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <!-- Rank (S) -->

        <!-- Channel (S) -->
        <el-form-item :label="$i18n.t('src.modules.customer.index.channel')" prop="channelId">
          <el-select size="medium" clearable v-model="formCustomer.channelId" placeholder="Select Channel" width="100%" filterable>
            <el-option v-for="item in channelList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <!-- Channel (S) -->

        <!-- Industrial Park (S) -->
        <el-form-item :label="$i18n.t('src.modules.customer.index.industrialPark')" prop="industrialParkId">
          <el-select size="medium" clearable v-model="formCustomer.industrialParkId" placeholder="Select Industrial Park" width="100%" filterable>
            <el-option v-for="item in industrialParkList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <!-- Industrial Park (E) -->
        <!-- User Incharge (S) -->
        <el-form-item :label="$i18n.t('src.modules.customer.index.userIncharge')" prop="userInchargeId">
          <el-select size="medium" clearable v-model="formCustomer.userInchargeId" placeholder="Select User Incharge" width="100%" filterable>
            <el-option v-for="item in userList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <!-- User Incharge (E) -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-row :gutter="24">
          <el-col :span="4">
            <el-button size="medium" @click="handleCloseForm()">{{ $i18n.t('src.modules.news.index.close') }}</el-button>
          </el-col>
          <el-col l :span="20">
            <el-button size="medium" type="success" @click="onSaveEditCustomers()">{{ $i18n.t('src.modules.news.index.update') }}</el-button>
          </el-col>
        </el-row>
      </span>
    </el-dialog>
    <!-- Popup Add Customer (E) -->
    <!-- Popup Show Order (S) -->
    <ShowOrderDetail :visible="orderDialogVisible" :order-id="showOrderId" @onCloseShowOrderDetail="onCloseShowOrderDetail" />
    <!-- Popup Show Order (E) -->

    <!-- Popup Show Quotation (S) -->
    <ShowQuotationDetail :visible="quotationDialogVisible" :quotation-id="showQuotationId" @onCloseShowQuotationDetail="onCloseShowQuotationDetail" />
    <!-- Popup Show Quotation (E) -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UploadFile from 'components/Upload';
import { TEXT_NOT_FOUND } from 'core/constants';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import without from 'lodash/without';
import { UPLOAD_FILE_MODULE } from 'core/constants';
import ShowOrderDetail from 'components/Modal/ShowOrderDetail';
import ShowQuotationDetail from 'components/Modal/ShowQuotationDetail';
import CustomerInvoiceAddress from 'modules/customerInvoiceAddress/index.vue';
import CustomerAddress from 'modules/customerAddress/index.vue';
import CustomerProfile from 'modules/profile/components/customer.profile.vue';
import { validatePhone, validatePassword, validateStringPreventDuplicateWhiteSpace } from 'core/helpers';

export default {
  components: { UploadFile, ShowOrderDetail, ShowQuotationDetail, CustomerInvoiceAddress, CustomerAddress, CustomerProfile },
  data() {
    return {
      customerId: 0,
      S3_STORE_ENDPOINT: process.env.VUE_APP_S3_STORE_ENDPOINT,
      showOrderId: 0,
      showQuotationId: 0,
      customerDialogVisible: false,
      orderDialogVisible: false,
      quotationDialogVisible: false,
      currentPageOrder: 1,
      pageSizeOrder: 5,
      currentPageQuotation: 1,
      pageSizeQuotation: 5,
      isLoadingOrderTable: true,
      isLoadingQuotationTable: true,
      // Popup Upload File
      showUploadFile: false,
      formCustomerDefault: {
        customerName: '',
        email: '',
        phone: '',
        avatar: [],
        taxCode: '',
        typeId: null,
        sizeId: null,
        industryId: null,
        website: '',
        rankId: null,
        industrialParkId: null,
        channelId: null,
        userInchargeId: null,
        dateOfIncorporationCompany: ''
      },
      formCustomer: {
        customerName: '',
        email: '',
        phone: '',
        avatar: '',
        taxCode: '',
        typeId: null,
        sizeId: null,
        industryId: null,
        website: '',
        rankId: null,
        industrialParkId: null,
        channelId: null,
        userInchargeId: null,
        dateOfIncorporationCompany: ''
      },
      formCustomerOld: {},
      categoryOptions: [{ value: 1, label: 'No Data' }],
      moduleOptions: [{ value: UPLOAD_FILE_MODULE.CUSTOMER, label: UPLOAD_FILE_MODULE.CUSTOMER }],
      rulesForm: {
        customerName: [
          {
            required: true,
            message: this.$i18n.t('src.modules.customer.index.rule.customer_name'),
            trigger: 'blur',
            validator: validateStringPreventDuplicateWhiteSpace
          }
        ],
        email: [
          {
            required: true, //Is required
            message: this.$i18n.t('src.modules.customer.index.rule.email'), //Error message
            trigger: 'blur' //Inspection method (blur means clicking other places with the mouse)
          },
          {
            type: 'email', //The type to be checked (number, email, date, etc.)
            message: this.$i18n.t('src.modules.customer.index.rule.checkType_email'),
            trigger: ['blur', 'change'] //change is when the tested character changes)
          }
        ],
        phone: [
          {
            required: true,
            message: this.$i18n.t('src.modules.customer.index.rule.phone'),
            trigger: 'blur',
            validator: validatePhone
          }
        ],
        avatar: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.avatar'),
            trigger: 'change'
          }
        ],
        taxCode: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.taxCode'),
            trigger: 'blur'
          }
        ],
        typeId: [
          {
            required: true,
            message: this.$i18n.t('src.modules.customer.index.rule.typeId'),
            trigger: 'blur'
          }
        ],
        sizeId: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.sizeId'),
            trigger: 'change'
          }
        ],
        industryId: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.industryId'),
            trigger: 'change'
          }
        ],
        website: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.website'),
            trigger: 'blur'
          }
        ],
        rankId: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.rankId'),
            trigger: 'change'
          }
        ],
        industrialParkId: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.industrialParkId'),
            trigger: 'change'
          }
        ],
        channelId: [
          {
            required: false,
            message: this.$i18n.t('src.modules.customer.index.rule.channelId'),
            trigger: 'change'
          }
        ],
        password: [
          {
            message: this.$i18n.t('src.modules.customer.index.rule.password'),
            trigger: 'change',
            validator: validatePassword
          }
        ]
      }
    };
  },
  computed: {
    ...mapState('customerDetail', ['customerDetail']),
    ...mapState('type', ['types']),
    ...mapState('size', ['sizes']),
    ...mapState('industry', ['industries', 'industriesMap']),
    ...mapState('rank', ['ranks', 'ranksMap']),
    ...mapState('channel', ['channels']),
    ...mapState('industrialPark', ['industrialParks']),
    ...mapState('user', ['users', 'usersMap']),
    ...mapState('order', ['orders', 'totalRows']),
    ...mapState('quotation', ['quotationsPaging', 'totalRowsQuotation']),

    pageCountOrder() {
      return Math.ceil(this.totalRows / this.pageSizeOrder);
    },

    pageCountQuotation() {
      return Math.ceil(this.totalRowsQuotation / this.pageSizeQuotation);
    },

    // typeList
    typeList() {
      return this.types.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },
    // sizeList
    sizeList() {
      return this.sizes.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },

    // industryList
    industryList() {
      return this.industries.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },

    // rankList
    rankList() {
      return this.ranks.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },

    // userList
    userList() {
      return this.users.map((item) => {
        return {
          value: item.id,
          label: item.username
        };
      });
    },

    // rankList
    channelList() {
      return this.channels.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },

    // industrialParkList
    industrialParkList() {
      return this.industrialParks.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },

    textNotFound() {
      return localStorage.getItem('user-language') === 'vi-VN' ? TEXT_NOT_FOUND.vi_VN : TEXT_NOT_FOUND.en_US;
    },

    customerImages() {
      return this.formCustomer.avatar;
    },

    showCustomerImages() {
      if ((this.customerImages.length === 1 && this.customerImages[0] === '') || this.customerImages.length === 0) return false;
      return true;
    }
  },

  watch: {
    currentPageOrder: async function () {
      this.isLoadingOrderTable = true;
      await this.getAllOrders({ customerId: this.$route.params.id, page: this.currentPageOrder, pageSize: this.pageSizeOrder });
      this.isLoadingOrderTable = false;
    },
    currentPageQuotation: async function () {
      this.isLoadingQuotationTable = true;
      await this.getPagingQuotations({ customerId: this.$route.params.id, page: this.currentPageQuotation, limit: this.pageSizeQuotation });
      this.isLoadingQuotationTable = false;
    }
  },

  async created() {
    this.customerId = Number(this.$route.params.id);
    await this.getByIdCustomer({ id: this.$route.params.id });
    this.getAllTypes();
    this.getAllSizes();
    this.getAllIndustries();
    this.getAllRanks();
    this.getAllIndustrialParks();
    this.getAllChannels();
    this.getAllUsers();
    this.getAllOrders({ customerId: this.customerId, page: this.currentPageOrder, pageSize: this.pageSizeOrder }).then(() => (this.isLoadingOrderTable = false));
    await this.getPagingQuotations({ customerId: this.customerId, page: this.currentPageQuotation, limit: this.pageSizeQuotation }).then(() => (this.isLoadingQuotationTable = false));
  },

  methods: {
    ...mapActions('customerDetail', ['getByIdCustomer', 'editCustomer']),
    ...mapActions('customer', ['saveEditCustomers']),
    ...mapActions('type', ['getAllTypes']),
    ...mapActions('size', ['getAllSizes']),
    ...mapActions('industry', ['getAllIndustries']),
    ...mapActions('rank', ['getAllRanks']),
    ...mapActions('industrialPark', ['getAllIndustrialParks']),
    ...mapActions('channel', ['getAllChannels']),
    ...mapActions('user', ['getAllUsers']),
    ...mapActions('global', ['setGlobalReady', 'uploadFile']),
    ...mapActions('order', ['getAllOrders']),
    ...mapActions('quotation', ['getPagingQuotations']),

    onClickShowOrderDetail(order) {
      this.showOrderId = order.id;
      this.orderDialogVisible = true;
    },

    onClickShowQuotationDetail(quotation) {
      this.showQuotationId = quotation.id;
      this.quotationDialogVisible = true;
    },

    onCloseShowOrderDetail() {
      this.orderDialogVisible = false;
    },

    onCloseShowQuotationDetail() {
      this.quotationDialogVisible = false;
    },

    // Upload File
    handleOpenPopupUploadFile() {
      this.showUploadFile = true;
    },

    handleOnCloseUploadFile() {
      this.showUploadFile = false;
    },

    handleOnSelectedFiles(arrObj) {
      this.formCustomer.avatar = arrObj.map((item) => {
        item.src = this.S3_STORE_ENDPOINT + '/' + item.filePath;
        return item;
      });
      this.showUploadFile = false;
    },

    async onSaveEditCustomers() {
      this.$confirm('Do you want change ?')
        .then(async () => {
          this.$refs['formCustomer'].validate(async (valid) => {
            if (valid) {
              try {
                const result = await this.saveEditCustomers({
                  oldData: this.formCustomerOld,
                  newData: this.formCustomer
                });
                if (result) {
                  this.formCustomerOld = {};
                  this.$notify({
                    title: 'Success',
                    message: 'Edit Customers Success',
                    type: 'success'
                  });
                } else {
                  this.$notify({
                    title: 'Error',
                    message: `Can not edit Customers Error: ${result.message}`,
                    type: 'error'
                  });
                }
                this.customerDialogVisible = false;
              } catch (error) {
                this.$notify({
                  title: this.$i18n.t('common.notify.title.error'),
                  message: this.$i18n.t('common.notify.message.edit.fail', {
                    name: 'row'
                  }),
                  type: 'error'
                });
              }
            } else {
              return false;
            }
          });
        })
        .catch((error) => {
          console.log('error: ', error);
        });
    },

    getCustomerAvatar(listAvatar) {
      const images = listAvatar ? listAvatar.split('|') : [];
      return images[0];
    },

    clearFormCustomer() {
      this.formCustomer = cloneDeep(this.formCustomerDefault);
    },

    handleCloseForm(done) {
      var message = null;
      if (!isEqual(this.formCustomer, this.formCustomerDefault)) {
        message = this.$i18n.t('common.confirm.close.form.out', { name: 'customer' });
        this.$confirm(message)
          .then(() => {
            this.clearFormCustomer();
            this.customerDialogVisible = false;
            done();
          })
          .catch(() => {});
      } else {
        this.customerDialogVisible = false;
      }
    },

    onClickEditCustomer() {
      const avatar = without(
        this.customerDetail.avatar?.split('|').map((src) => {
          return src !== '' ? { src: this.S3_STORE_ENDPOINT + '/' + src, filePath: src } : null;
        }),
        null
      );
      (this.formCustomer = {
        id: this.customerDetail.id,
        customerName: this.customerDetail.customerName,
        email: this.customerDetail.email,
        phone: this.customerDetail.phone,
        avatar,
        taxCode: this.customerDetail.taxCode,
        typeId: this.customerDetail.typeId,
        sizeId: this.customerDetail.sizeId,
        industryId: this.customerDetail.industryId,
        website: this.customerDetail.website,
        rankId: this.customerDetail.rankId,
        industrialParkId: this.customerDetail.industrialParkId,
        channelId: this.customerDetail.channelId,
        userInchargeId: this.customerDetail.userInchargeId,
        dateOfIncorporationCompany: this.customerDetail.dateOfIncorporationCompany
      }),
        (this.formCustomerOld = cloneDeep(this.customerDetail));
      this.customerDialogVisible = true;
    }
  }
};
</script>
<style src="../index.scss" lang="scss" />