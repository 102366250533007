import Services from 'core/service';
import * as Type from 'modules/customerNotification/store/types';


const service = new Services();

const getAllCusNotification = async ({ commit },value) => {  
    const { 
        createdAt = '',
    } = value;
    const params = {}
   
    if(createdAt){
        params.createdAt = createdAt; // keyword for seach
    }
   // Get All Brand
   return service.rest('customerNotification/getAllNotification', params).then(res=> {
    
    commit(Type.GET_ALL, res.rows);   
    return true
   }).catch(err=> {
    console.log(err);
    return false
   })
   
  
};

const deleteListNotification = async ({ commit }, value) => {
    if(value.length > 0){
        return service.rest('customerNotification/AdminDeleteNotification', { items:{$delete:value}}).then(rlt =>{
            const {items} = rlt;
            
            const {$rltDelete} = items
            // Commit to  state
            commit(Type.DELETE_NOTI, $rltDelete );
            return true
        }).catch(e=>{
            console.log('🚀 ~ file: actions.js ~ line 31 ~ awaitservice.rest ~ e', e)
             return false
            
        })
    }
};

const deleteNotification = async ({ commit }, value) => {
    return service.rest('customerNotification/AdminDeleteNotification', { items:{$delete:[value.row]}}).then(rlt =>{
        const {items} = rlt;
        const {$rltDelete} = items
        // Commit to  state
        commit(Type.DELETE_NOTI, $rltDelete);
        return true
    }).catch(e=>{
      console.log('🚀 ~ file: actions.js ~ line 47 ~ awaitservice.rest ~ e', e)
      return false 
    })
};

export {
    getAllCusNotification,
    deleteNotification,
    deleteListNotification,
};