import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
import cloneDeep from 'lodash/cloneDeep';

const state = {
  productRepurchases: [],
  listProductRepurchases: [],
  totalRowsProductRepurchases: 0
};

const mutations = {
  async [Type.GET_ALL](state, value) {
    state.productRepurchases = value.rows;
    state.totalRowsProductRepurchases = value.totalRows;
    state.listProductRepurchases = value.rows;
  },
  async [Type.SAVE](state, value) {
    const oldProductRepurchases = state.productRepurchases
    // add new data
    state.productRepurchases = [...cloneDeep(value), ...oldProductRepurchases];
  },
  async [Type.EDIT](state, value) {
    const oldProductRepurchases = state.productRepurchases
    const {
      id
    } = value
    const objIndex = state.productRepurchases.findIndex((obj => obj.id === id));
    oldProductRepurchases[objIndex] = value;
    state.productRepurchases = oldProductRepurchases.slice(0);
  },
  [Type.DELETE](state, value) {
    const oldData = cloneDeep(state.productRepurchases)
    value.map(item => {
      const {
        id
      } = item
      const objIndex = oldData.findIndex((obj) => obj.id === id);
      oldData.splice(objIndex, 1)
    })
    state.productRepurchases = oldData.slice(0)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};