<template>
  <div class="sidebar-wrapper">
    <AdminForgotPasswordPage v-if="loginMode === LOGIN_MODE.ADMIN" />
    <SellerForgotPasswordPage v-if="loginMode === LOGIN_MODE.SUPPLIER" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AdminForgotPasswordPage from './components/admin.forgotPassword.vue';
import SellerForgotPasswordPage from './components/seller.forgotPassword.vue';
import { LOGIN_MODE } from 'core/constants';

export default {
  name: 'LoginPage',
  components: {
    AdminForgotPasswordPage,
    SellerForgotPasswordPage
  },
  data() {
    return {
      LOGIN_MODE
    };
  },

  computed: {
    ...mapGetters('global', ['loginMode'])
  },

  created() {
    console.log('🚀 ~ file: index.vue ~ line 26 ~ created ~ loginMode', this.loginMode);
  }
};
</script>
