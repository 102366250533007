<template>
  <div class="sidebar-wrapper">
    <AdminCustomerDetail
      v-if="!isSupplier"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AdminCustomerDetail from './components/admin.customerDetail.vue';


export default {
  name: 'customerDetail',
  components: {
    AdminCustomerDetail
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('auth', ['user']),

    isSupplier() {
      return this.user.supplierId == null ? false : true;
    }
  },
  created() {
    this.getMe();
  },
  methods: {
    ...mapActions('auth', ['getMe']),

  },
};
</script>
<style lang="scss">
// Xinat NDP Comment -> ảnh hưởng đến giao diện khác
// .el-input__inner {
//   border: none;
// }
</style>
