import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
    documentTypes:[],
    documentTypesAddLocal:[],
    posts: [],
    listPost: [],
};

import cloneDeep from 'lodash/cloneDeep'
import { isEqual } from 'lodash';

const mutations = {

    [Type.SET_ALL_DOCUMENT_TYPES](state, value) {
        state.documentTypes = value
    },

    [Type.SET_DOCUMENT_TYPES_LOCAL](state,value) {
        const oldDocumentTypes = state.documentTypesAddLocal
        state.documentTypesAddLocal = [...oldDocumentTypes,...[cloneDeep(value)]];
    },

    [Type.SET_DOCUMENT_TYPES_ADD](state,value) {
        const oldDocumentTypes = state.documentTypes
        // add new data
        state.documentTypes = [...cloneDeep(value),...oldDocumentTypes];
        state.documentTypesAddLocal = [];
    },

    [Type.SET_DOCUMENT_TYPES_UPDATE](state,value) {
        const oldDocumentTypes = state.documentTypes
        const {id} = value
        const objIndex = state.documentTypes.findIndex((obj => obj.id === id));
        oldDocumentTypes[objIndex] = value;
        state.documentTypes = oldDocumentTypes.slice(0);
    },

    [Type.SET_DOCUMENT_TYPES_UPDATE_LOCAL](state,value) {
        const {oldData,newData} = value
        // File oldData        
        const oldDocumentTypesAddLocal = state.documentTypesAddLocal.map(item =>{
            if(isEqual(item,oldData)){
                item = newData
            }
            return item
        })
        state.documentTypesAddLocal = oldDocumentTypesAddLocal.slice(0);
    },
    
    [Type.SET_DELETE_DOCUMENT_TYPES](state, value) {
        const oldData = cloneDeep(state.documentTypes)
        value.map(item=>{
            const {id} =item
            const objIndex =   oldData.findIndex((obj) => obj.id === id );
            oldData.splice(objIndex, 1)
        })
        state.documentTypes = oldData.slice(0)       
    },

    [Type.SET_DELETE_DOCUMENT_TYPES_LOCAL](state, value) {
    console.log('🚀 ~ file: index.js ~ line 63 ~ value', value)

        const oldData = cloneDeep( state.documentTypesAddLocal)
        value.map(item=>{
            const objIndex = oldData.findIndex((obj) => {
                return isEqual(obj,item)
            } );
            oldData.splice(objIndex, 1)
        })
        state.documentTypesAddLocal = oldData.slice(0)     

     
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};