import Service from 'core/service';
import { cloneDeep } from 'lodash';
import * as Type from 'modules/order/store/types';
const service = new Service();

const getAllOrders = async ({ commit }, value) => {
    const {
        saleId,
        page,
        pageSize,
        orderCode,
        customerName = '',
        customerId = '',
        status = '',
        createdAt = [],
    } = value || {};

    const params = {}
    
    if (saleId) {
        params.saleId = saleId
    }

    if (orderCode) {
        params.orderCode = orderCode
    }

    if (customerId) {
        params.customerId = customerId
    }

    if (customerName) {
        params.customerName = customerName
    }

    if (status) {
        params.status = Number(status)
    }
    if (createdAt && createdAt.length > 0) {
        params.createdAt = createdAt
    }

    if (page && page > 0) {
        params.page = page
    }
    if (pageSize && pageSize > 0) {
        params.itemsPerPage  = pageSize
    }

    return service.rest('/order/sales/getListPaging', params).then(res => {
        console.log(res)
        commit(Type.SET_ALL_ORDER, res);
        return true
    }).catch(err => {
        console.log(err);
        return false
    })
    // Commit to news state
}

const getAllOrderSuppliers = async ({ commit }, value) => {
    const { customerName = '',
        status = '',
        createdAt = [],
    } = value || {};

    const params = {}

    if (customerName) {
        params.customerName = customerName
    }
    if (status) {
        params.status = Number(status)
    }
    if (createdAt && createdAt.length > 0) {
        params.createdAt = createdAt
    }

    return service.rest('/order/getAllBySupplier', params).then(res => {
        commit(Type.SET_ALL_ORDER_SUPPLIER, res);
        return true
    }).catch(err => {
        console.log(err);
        return false
    })
    // Commit to news state
}

const _convertData = (row) => {
    const newData = cloneDeep(row)
    return newData
}

const addOrdersLocal = async ({ commit }, newRow) => {
    console.log(newRow);
    // Convert data
    const data = _convertData(newRow)
    // Commit to news state
    commit(Type.SET_ORDER_LOCAL, data);
    return true;
};

// const saveStatusOrder = async ({ commit }, value) => {
//     return service.rest('order/updateStatus', value).then(rlt => {
//         console.log(rlt);
//     });
// }
const saveAddOrders = async ({ commit }, arrAddData) => {
    // Convert data
    const arrAddDataConvert = arrAddData.map(item => _convertData(item));
    // const arrAddDataConvert = arrAddData.map(item => {
    //     item.logo = item.logo[0].src;
    //     return _convertData(item)
    // })
    return service.rest('order/sales/setList', { items: { $add: arrAddDataConvert } }).then(rlt => {
        console.log('result : ', rlt);
        const { items } = rlt;
        const { $rltAdd } = items;
        // Commit to Partner state
        commit(Type.SET_ORDER_ADD, $rltAdd);
        return true
    }).catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
        return false
    });
}

const saveEditOrders = async ({ commit }, addData) => {
    const { oldData, newData } = addData
    const { id } = newData;
    const data = _convertData(newData);
    console.log('🚀 ~ file: index.vue ~ line 627 ~ .then ~ result', newData)
    if (id) {
        // Convert data
        return service.rest('order/setList', { items: { $update: [data] } }).then(rlt => {
            const { items } = rlt;
            const { $rltUpdate } = items
            // Commit to news state
            commit(Type.SET_ORDER_UPDATE, $rltUpdate[0]);
            return true
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return false

        })
    } else {
        // Commit to news state
        commit(Type.SET_PARTNER_UPDATE_LOCAL, { oldData, newData: data });
        return true
    }
}


const deleteOrder = async ({ commit }, value) => {
    const { id } = value;
    if (id) {
        return service.rest('order/setList', { items: { $delete: [value] } }).then(rlt => {
            const { items } = rlt;
            const { $rltDelete } = items;
            // Commit to news state
            commit(Type.SET_DELETE_ORDER, $rltDelete);
            return $rltDelete;
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return false;
        })
    } else {
        commit(Type.SET_DELETE_ORDER_LOCAL, [value]);
        return true
    }

};

const deleteOrderList = async ({ commit }, value) => {
    const lstLocal = [];
    const lstDB = [];
    // Filter
    value.map(item => {
        const { id } = item;
        if (id) {
            lstDB.push(item)
        } else {
            lstLocal.push(item)
        }
    })

    if (lstLocal.length > 0) {
        commit(Type.SET_DELETE_ORDER_LOCAL, lstLocal);
        return true;
    }

    if (lstDB.length > 0) {
        return service.rest('order/setList', { items: { $delete: lstDB } }).then(rlt => {
            const { items } = rlt;
            const { $rltDelete } = items
            // Commit to news state
            commit(Type.SET_DELETE_ORDER, $rltDelete);
            return true
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 154 ~ awaitservice.rest ~ e', e)
            return false
        })
    }

};

const getAllDataExportExcel = async ({ commit }, value) => {
    const { 
        orderCode,
        customerName = '',
        customerId = '',
        status = '',
        createdAt = [],
    } = value || {};

    const params = {}

    if (orderCode) {
        params.orderCode = orderCode
    }

    if (customerId) {
        params.customerId = customerId
    }

    if (customerName) {
        params.customerName = customerName
    }

    if (status) {
        params.status = Number(status)
    }
    if (createdAt && createdAt.length > 0) {
        params.createdAt = createdAt
    }

    return service.rest('order/getOrderForExcelFile', params).then(res => {
        const { status, totalRows, rows } = res;
        if(!status){
            return false;
        };
        if(totalRows <= 0){
            return [];
        };

        commit(Type.SET_ALL_ORDER_DATA_EXPORT_EXCEL, rows);
        return true;

    }).catch(err => {
        console.log(err);
        return false
    })
};

export {
    getAllOrders,
    getAllOrderSuppliers,
    addOrdersLocal,
    saveAddOrders,
    saveEditOrders,
    deleteOrder,
    deleteOrderList,
    getAllDataExportExcel
};