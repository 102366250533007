<template>
  <el-dialog v-can="'quotation.read'" :title="$i18n.t('common.label.showQuotationDetail')" :visible.sync="dialogVisible" :before-close="handleClose" :close-on-click-modal="false" width="80%">
    <b-row>
      <b-col cols="12" class="pr-5">
        <!-- Table Quotations Data (S) -->
        <el-row>
          <el-table v-loading="isLoading" :data="pagedTableData" height="450" :default-sort="{ prop: 'updatedAt', order: 'descending' }" style="width: 100%">
            <!-- Id (S) -->
            <el-table-column prop="id" label="#" width="80">
              <template slot-scope="scope">
                <span v-if="scope.row.id">{{ scope.row.id }}</span>
                <el-tag v-else type="danger">New</el-tag>
              </template>
            </el-table-column>
            <!-- Id (E) -->

            <!-- Image Product (S) -->
            <el-table-column :label="this.$i18n.t('src.modules.quotationDetail.index.image')" width="100">
              <template slot-scope="scope">
                <el-image style="width: 50px; height: 50px" :src="S3_STORE_ENDPOINT + '/' + getProductImage(scope.row.images)"> </el-image>
              </template>
            </el-table-column>
            <!-- Image Product (E) -->

            <!-- Product Name (S) -->
            <el-table-column :label="this.$i18n.t('src.modules.quotationDetail.index.productName')" width="300">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="scope.row.productName" placement="top" v-if="scope.row.productName && scope.row.productName.length > 50">
                  <span>{{ scope.row.productName.slice(0, 50) + '...' }}</span>
                </el-tooltip>
                <span v-else>
                  {{ scope.row.productName || 'No Data' }}
                </span>
              </template>
            </el-table-column>
            <!-- Product Name (E) -->

            <!-- Supplier Name (S) -->
            <el-table-column prop="supplierName" :label="$i18n.t('src.modules.quotationDetail.index.supplierName')" width="200" sortable>
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="scope.row.supplierName" placement="top" v-if="scope.row.supplierName && scope.row.supplierName.length > 30">
                  <span>{{ scope.row.supplierName.slice(0, 30) + '...' }}</span>
                </el-tooltip>
                <span v-else>
                  {{ scope.row.supplierName || 'No Data' }}
                </span>
              </template>
            </el-table-column>
            <!-- Supplier Name (E)-->

            <!-- Note (S) -->
            <el-table-column prop="note" :label="$i18n.t('src.modules.quotation.note')" width="200" sortable show-overflow-tooltip>
              <template slot-scope="scope">
                <span>
                  {{ scope.row.note || 'No Data' }}
                </span>
              </template>
            </el-table-column>
            <!-- Note (E)-->

            <!-- Quantity (S) -->
            <el-table-column prop="quanity" :label="this.$i18n.t('src.modules.quotationDetail.index.quantity')" width="150" style="text-align: center">
              <template slot-scope="scope">
                <span >{{ scope.row.quantity }}</span>
              </template>
            </el-table-column>
            <!-- Quantity (E)-->

            <!-- Unit (S) -->
            <el-table-column prop="unitName" :label="this.$i18n.t('src.modules.quotationDetail.index.unit')" width="130" style="text-align: center">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="scope.row.unitName" placement="top" v-if="scope.row.unitName && scope.row.unitName.length > 30">
                  <span>{{ scope.row.unitName.slice(0, 30) + '...' }}</span>
                </el-tooltip>
                <span v-else>
                  {{ scope.row.unitName || 'No Data' }}
                </span>
              </template>
            </el-table-column>
            <!-- Unit (E)-->

            <!-- SupplierPrice (S) -->
            <el-table-column prop="supplierPrice" :label="this.$i18n.t('src.modules.product.index.supplierPrice')" width="140">
              <template slot-scope="scope">
                <span>
                  {{
                    new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND'
                    }).format(scope.row.supplierPrice)
                  }}
                </span>
              </template>
            </el-table-column>
            <!-- Supplier Price (E) -->

            <!-- Unit Price (S) -->
            <el-table-column :label="this.$i18n.t('src.modules.quotationDetail.index.unitPrice')" width="150">
              <template slot-scope="scope">
                <span
                  >{{
                    new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND'
                    }).format(scope.row.unitPrice)
                  }}
                </span>
              </template>
            </el-table-column>
            <!-- Unit Price (E) -->

            <!-- Shipping Cost (S) -->
            <el-table-column prop="shippingCost" :label="this.$i18n.t('src.modules.quotationDetail.index.shippingCost')" width="150">
              <template slot-scope="scope">
                <span
                  >{{
                    new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND'
                    }).format(scope.row.shippingCost)
                  }}
                </span>
              </template>
            </el-table-column>
            <!-- Shipping Cost (E) -->

            <!-- Total (S) -->
            <el-table-column prop="total" :label="this.$i18n.t('src.modules.quotationDetail.index.total')" width="150">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="$i18n.t('src.modules.quotationDetail.index.tooltip.totalWithTax')" placement="top">
                  <span>
                    {{
                      new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND'
                      }).format(scope.row.total)
                    }}
                  </span>
                </el-tooltip>
              </template>
            </el-table-column>
            <!-- Total (E) -->
          </el-table>
        </el-row>
        <!-- Table Customers Data (E) -->

        <!-- Paging (S) -->
        <el-row type="flex" justify="center">
          <el-col>
            <div class="grid-content bg-purple">
              <el-pagination class="pagination" @current-change="changePage" :page-size="pageSize" :current-page="currentPage" layout="prev, pager, next" :total="totalQuotationDetailCnt">
              </el-pagination>
            </div>
          </el-col>
        </el-row>
        <!-- Paging (E) -->
      </b-col>
    </b-row>
    <span slot="footer" class="dialog-footer">
      <el-row :gutter="24">
        <el-col :span="4">
          <el-button class="float-left" @click="handleClose">{{ $i18n.t('src.components.modal.selectProduct.tableProduct.close') }}</el-button>
        </el-col>
        <el-col :span="20">
          <el-button v-can="'quotation.edit'" class="float-right btn-blue-sm" type="primary" size="medium" @click="$router.push(`/quotation/${quotationId}`)">{{
            $i18n.t('common.label.editQuotation')
          }}</el-button>
        </el-col>
      </el-row>
    </span>
  </el-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex';
// import { filterByKey } from 'core/helpers';
// import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { DEFAULT_UNIT_NAME } from 'core/constants';

export default {
  components: {},

  props: {
    quotationId: {
      type: Number,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      S3_STORE_ENDPOINT: process.env.VUE_APP_S3_STORE_ENDPOINT,
      EI_ENDPOINT: process.env.VUE_APP_EI_ENDPOINT,
      isLoading: false,
      currentPage: 1,
      pageSize: 10,
      lang: '',
      editorConfig: {
        language: 'en'
      },
      supplierInfoArr: [],
      productTableData: []
    };
  },
  computed: {
    ...mapState('supplier', ['suppliers', 'suppliersMap']),
    ...mapState('quotationDetail', ['quotationDetails']),

    allQuotationDetail() {
      return cloneDeep([...this.quotationDetails]);
    },

    //list all quotation detail
    pagedTableData() {
      return this.allQuotationDetail.slice(this.pageSize * this.currentPage - this.pageSize, this.pageSize * this.currentPage).map((item) => {
        const itemReturn = {};
        const { id, product, quotationId, productId, quantity, weight, shippingCost, total, unitPrice, note } = item;

        itemReturn.quotationId = quotationId;
        itemReturn.productId = productId;
        itemReturn.quantity = quantity || 0;
        itemReturn.weight = weight;
        itemReturn.shippingCost = shippingCost || 0;
        itemReturn.total = total || 0;
        itemReturn.unitName = this.lang === 'vi-VN' ? DEFAULT_UNIT_NAME.vi_VN : DEFAULT_UNIT_NAME.en_US;
        itemReturn.note = note;

        // data from database
        if (id && product) {
          const { images, supplierName, name, supplierId, supplierPrice, isSourcing, description, unitCode, unit = {} } = product;
          itemReturn.images = this.getProductImage(images) || 'No Data';
          itemReturn.productName = name || 'No Data';
          itemReturn.supplierName = supplierName || (this.suppliersMap[supplierId] && this.suppliersMap[supplierId].name) || 'No Data';
          itemReturn.supplierId = supplierId || 0;
          itemReturn.id = id;
          itemReturn.supplierPrice = supplierPrice || 0;
          itemReturn.description = description || '';
          itemReturn.unitCode = unitCode || '';
          itemReturn.unitPrice = unitPrice || 0;

          const unitNameParse = unit?.name ? JSON.parse(unit.name) : null;
          if (unitNameParse && unitNameParse.en_US && unitNameParse.vi_VN) {
            itemReturn.unitName = this.lang === 'vi-VN' ? unitNameParse.vi_VN : unitNameParse.en_US;
          }
          itemReturn.isSourcing = isSourcing;
        } else {
          // data from add local
          const { images, productName, supplierName, supplierId, supplierPrice, isSourcing, description, unitCode, unit = {} } = item;
          itemReturn.images = this.getProductImage(images) || 'No Data';
          itemReturn.productName = productName || 'No Data';
          itemReturn.supplierName = supplierName || (this.suppliersMap[supplierId] && this.suppliersMap[supplierId].name) || 'No Data';
          itemReturn.supplierId = supplierId || 0;
          itemReturn.supplierPrice = supplierPrice || 0;
          itemReturn.isSourcing = isSourcing || '';
          itemReturn.description = description || '';
          itemReturn.unitCode = unitCode || '';
          itemReturn.unitPrice = unitPrice || 0;

          const unitNameParse = unit?.name ? JSON.parse(unit.name) : null;
          if (unitNameParse && unitNameParse.en_US && unitNameParse.vi_VN) {
            itemReturn.unitName = this.lang === 'vi-VN' ? unitNameParse.vi_VN : unitNameParse.en_US;
          }
        }
        return itemReturn;
      });
    },

    totalQuotationDetailCnt(){
      return this.allQuotationDetail.length || 0;
    },

    dialogVisible() {
      return this.visible;
    }
  },

  watch: {
    quotationId: async function () {
      const listPromise = [this.getAllQuotations({ id: this.quotationId })];
      this.isLoading = true;
      await Promise.all(listPromise);
      this.isLoading = false;
    }
  },

  async created() {
    this.lang = localStorage.getItem('user-language');
    await this.getAllQuotations({ id: this.quotationId });
    await this.getAllSupplier({});
  },
  methods: {
    ...mapActions('supplier', ['getAllSupplier']),

    ...mapActions('global', ['setGlobalReady']),

    ...mapActions('quotation', ['getAllQuotations']),

    //Paging
    changePage(val) {
      this.currentPage = val;
    },

    getProductImage(listImage) {
      const images = listImage ? listImage.split('|') : [];
      return images[0];
    },
    handleClose() {
      this.$emit('onCloseShowQuotationDetail');
    }
  }
};
</script>
<style>
.list-product-row .el-table__fixed-right-patch {
  background-color: #333 !important;
  height: 56px !important;
}
.supplier-status-select {
  background-color: white !important;
}
.supplier-status-select input {
  width: 100%;
  border: none;
  background-color: white !important;
}
.text-order-info {
  padding-bottom: 5px !important;
}
</style>
