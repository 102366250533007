import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';

const state = {
    customerTypesCountByDate: [],
    customerLevelsCountByDate: [],
    customerVerifiedCountByDate: [],
    customerChanelCountByDate: [],
    statisticCustomerRegistered: [],
    statisticTopCustomer: [],
    totalOfCusRegisteredByMonth: [],
    totalOfCusActiveByMonth: [],
    quantityCustomerPaidByMonth: {},
    totalCustomerVerifiedCountByDay: []
};

const mutations = {
  async [Type.STATISTIC_CUSTOMER_TYPE](state, value) {
    state.customerTypesCountByDate = value.data;
  },

  async [Type.STATISTIC_CUSTOMER_LEVEL](state, value) {
    state.customerLevelsCountByDate = value.data;
  },

  async [Type.STATISTIC_CUSTOMER_VERIFIED](state, value) {
    state.customerVerifiedCountByDate = value.data;
  },

  async [Type.STATISTIC_CUSTOMER_VERIFIED_BY_DAY](state, value) {
    state.totalCustomerVerifiedCountByDay = value.data;
  },
  
  async [Type.STATISTIC_CUSTOMER_CHANNEL](state, value) {
    state.customerChanelCountByDate = value.data;
  },

  async [Type.STATISTIC_CUSTOMER_REGISTERED](state, value) {
    state.statisticCustomerRegistered = value.data;
  },

  async [Type.STATISTIC_TOP_CUSTOMER](state, value) {
    state.statisticTopCustomer = value.data;
  },

  async [Type.STATISTIC_CUSTOMER_VERIFIED](state, value) {
    state.totalOfCusRegisteredByMonth = value.data;
  },

  async [Type.STATISTIC_CUSTOMER_ACTIVE](state, value) {
    state.totalOfCusActiveByMonth = value.data;
  },

  async [Type.STATISTIC_CUSTOMER_PAID](state, value) {
    state.quantityCustomerPaidByMonth = value.data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
