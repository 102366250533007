import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
    mailTemplates: [],
    listMailTemplates: [] 
};

const mutations = {
    async [Type.GET_ALL](state, value) {
      state.mailTemplates = value.rows;
      state.listMailTemplates = value.rows;
    },
    async [Type.SAVE](state, value) {
      state.mailTemplates = [ ...value, ...state.mailTemplates]
      Promise.all(value.map( item => {
        state.listMailTemplates.push(item);
      }));
    },
    async [Type.EDIT](state, value) {
      const oldMailTemplates = state.mailTemplates
      const index = state.mailTemplates.findIndex((element) => element.id == value.id);
      oldMailTemplates[index] = value;
      state.mailTemplates = oldMailTemplates.slice(0);

      // update listMailTemplatesActions[]
      const indexInListMailTemplates = state.listMailTemplates.findIndex((element) => element.id == value.id);
      state.listMailTemplates[indexInListMailTemplates].name = value.name
    },
    async [Type.DELETE](state, value) {
        await Promise.all(value.map( item => {
          const index = state.mailTemplates.findIndex((element) => element.id == item.id);
          state.mailTemplates.splice(index, 1)
          const indexInListMailTemplates = state.listMailTemplates.findIndex((element) => element.id == item.id);
          state.listMailTemplates.splice(indexInListMailTemplates, 1)
        }));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};