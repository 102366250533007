import Service from 'core/service';
import { cloneDeep } from 'lodash';
import * as Type from 'modules/quotation/store/types';
import * as QuotationDetailType from 'modules/quotationDetail/store/types';

const service = new Service();

const _convertData = row => {
  const newData = cloneDeep(row);

  const { salesInChargeIdList = [], bdInChargeIdList = [] } = newData || {};

  if( Array.isArray(salesInChargeIdList) && salesInChargeIdList.length > 0){
    newData.salesInChargeIdList = `|${salesInChargeIdList.join('|')}|`;
  }else{
    newData.salesInChargeIdList = '';
  };

  if( Array.isArray(bdInChargeIdList) && bdInChargeIdList.length > 0){
    newData.bdInChargeIdList = `|${bdInChargeIdList.join('|')}|`;
  }else{
    newData.bdInChargeIdList = '';
  };

  return newData;
};

const getAllQuotations = async ({ commit }, value) => {
  const {
    id = '',
    customerId = '',
    salesInChargeId = '',
    bdInChargeId = '',
    createdAt = [],
    quotationStatusId = '',
    supplierId = '',
    quotationCode = ''
  } = value;

  const params = {};

  if (id) {
    params.id = id;
  }

  if (customerId) {
    params.customerId = customerId;
  }

  if (supplierId !== '') {
    params.supplierId = supplierId;
  }

  if (salesInChargeId) {
    params.salesInChargeId = salesInChargeId;
  }

  if (bdInChargeId) {
    params.bdInChargeId = bdInChargeId;
  }

  if (createdAt && createdAt.length > 0) {
    params.createdAt = createdAt;
  }

  if (quotationStatusId) {
    params.quotationStatusId = quotationStatusId;
  }

  if (quotationCode) {
    params.quotationCode = quotationCode;
  }

  // Get getAll Customer Address
  return service
    .rest('quotation/sales/getAllPaging', params)
    .then(res => {
      commit(Type.SET_ALL_QUOTATION, res.rows);

      if (id) {
        const { quotationDetails = [] } = res.rows[0];
        commit(
          `quotationDetail/${QuotationDetailType.GET_QUOTATION_DETAIL_FROM_QUOTATION}`,
          quotationDetails,
          { root: true }
        );
      }
      return true;
    })
    .catch(err => {
      console.log('err: ', err);
      return false;
    });
};

const getPagingQuotations = async ({ commit }, value) => {
  const {
    customerId = '',
    limit = '',
    page = ''
  } = value;

  const params = {};

  if (customerId) {
    params.customerId = customerId;
  }

  if (limit !== '') {
    params.limit = limit;
  }

  if (page !== '') {
    params.page = page;
  }

  // Get getAll quotation paging
  return service
    .rest('quotation/getListQuotation', params)
    .then(res => {
      commit(Type.SET_PAGING_QUOTATION, res.data);
      return true;
    })
    .catch(err => {
      console.log('err: ', err);
      return false;
    });
};

const getAllQuotationsBySupplier = async ({ commit }, value) => {
  const {
    id = '',
    createdAt = [],
    quotationStatusId = '',
    supplierId = ''
  } = value;
  console.log(value)

  const params = {};

  if (id) {
    params.id = id;
  }

  if (supplierId !== '') {
    params.supplierId = supplierId;
  }

  if (createdAt && createdAt.length > 0) {
    params.createdAt = createdAt;
  }

  if (quotationStatusId) {
    params.quotationStatusId = quotationStatusId;
  }

  // Get getAll Customer Address
  return service
    .rest('quotation/getAllPagingBySupplier', params)
    .then(res => {
      commit(Type.SET_ALL_QUOTATION, res.rows);

      if (id) {
        const { quotationDetails = [] } = res.rows[0];
        commit(
          `quotationDetail/${QuotationDetailType.GET_QUOTATION_DETAIL_FROM_QUOTATION}`,
          quotationDetails,
          { root: true }
        );
      }
      return true;
    })
    .catch(err => {
      console.log('err: ', err);
      return false;
    });
};
// get all quotations sales

const saveEditQuotation = async ({ commit }, newData) => {
  const {
    quotationStatusId,
    id,
    bdInChargeIdList,
    salesInChargeIdList,
    customerAddressId,
    note,
    personInChargeId,
    shippingFee,
    isImportant,
    estimateDeliveryDate

  } = newData;

  const dataUpdate = {
    quotationStatusId,
    id,
    bdInChargeIdList,
    salesInChargeIdList,
    customerAddressId,
    note,
    personInChargeId,
    shippingFee,
    isImportant,
    estimateDeliveryDate
  };

  Object.keys(dataUpdate).forEach(
    k =>
      !dataUpdate[k] &&
      dataUpdate[k] !== undefined &&
      k !== 'note' &&
      delete dataUpdate[k]
  );

  const dataAfterConvert = _convertData(dataUpdate);

  if (!id) {
    return false;
  }
  // Convert data
  return service
    .rest('quotation/update', dataAfterConvert)
    .then(result => {
      const { data, status } = result;
      if (status !== true) {
        return false;
      }
      const { items } = data;
      // Commit to news state
      commit(Type.SET_QUOTATION_UPDATE, items);
      return true;
    })
    .catch(e => {
      console.log(
        '🚀 ~ file: actions.js ~ line 66 ~ saveEditCustomerAddress.rest ~ e',
        e
      );
      return false;
    });
};

const addNewQuotation = async ({ commit }, arrAddData) => {
  Object.keys(arrAddData).forEach(
    k => !arrAddData[k] && arrAddData[k] !== undefined && delete arrAddData[k]
  );

  const dataAfterConvert = _convertData(arrAddData);

  return service
    .rest('quotation/add', dataAfterConvert)
    .then(result => {
      const { data, status } = result;
      if (status !== true) {
        return false;
      }
      const { items } = data;
      // Commit to state
      commit(Type.SET_QUOTATION_ADD, [items]);
      return true;
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 66 ~ addNewQuotation ~ e', e);
      return false;
    });
};

const deleteQuotationList = async ({ commit }, value) => {
  if (value.length <= 0) {
    return false;
  }

  return service
    .rest('quotation/deleteList', { listQuotation: value })
    .then(result => {
      const { data, status } = result;
      if (status !== true) {
        return false;
      }
      const { items } = data;
      // Commit to news state
      commit(Type.SET_DELETE_QUOTATION, items);
      return true;
    })
    .catch(e => {
      console.log(
        '🚀 ~ file: actions.js ~ line 120 ~ deleteCustomerAddressList.rest ~ e',
        e
      );
      return false;
    });
};

const changeQuotationStatus = async ({ commit }, newData) => {
  const {
    quotationStatusId,
    id,
    cancelReason,
    quotationCancelReasonCode
  } = newData;

  const dataUpdate = {
    quotationStatusId,
    id,
    cancelReason,
    quotationCancelReasonCode
  };

  Object.keys(dataUpdate).forEach(
    k =>
      !dataUpdate[k] &&
      dataUpdate[k] !== undefined &&
      k !== 'note' &&
      delete dataUpdate[k]
  );

  if (!id) {
    return false;
  }
  // Convert data
  return service
    .rest('quotation/changeQuotationStatus', dataUpdate)
    .then(result => {
      const { data, status } = result;
      if (!status) {
        return {
          status: false,
          messageCode: result.messageCode,
          messageContent: result.messageContent
        };
      }
      const { items } = data;
      // Commit to news state
      commit(Type.SET_QUOTATION_UPDATE, items);
      return {
        status: true
      };
    })
    .catch(e => {
      console.log(
        '🚀 ~ file: actions.js ~ line 66 ~ saveEditCustomerAddress.rest ~ e',
        e
      );
      return {
        status: false,
        messageCode: '',
        messageContent: e.message
      };
    });
};

const getAllQuotationsByCode = async ({ commit }, value) => {
  const {
    quotationCode = '',
    page,
    limit    
  } = value;

  const params = {};

  if (quotationCode) {
    params.quotationCode = quotationCode;
  };

  if (page) {
    params.page = page;
  };

  if (limit) {
    params.limit = limit;
  };

  // Get getAll Customer Address
  return service
    .rest('quotation/getAllPaging', params)
    .then(res => {
      const { rows } = res;
      if(!rows || rows <= 0){
        return false;
      };

      commit(Type.SET_ALL_QUOTATION_BY_QUOTATION_CODE, rows);

      return true;
    })
    .catch(err => {
      console.log('err: ', err);
      return false;
    });
};

const recalculateQuotation = async ({ commit }, value) => {
  const {
    quotationId = '',
  } = value;

  const params = {};

  if (quotationId) {
    params.quotationId = quotationId;
  };

  // Get getAll Customer Address
  return service
    .rest('quotation/recalculate', params)
    .then(result => {
      const { data, status } = result;
      if (status !== true) {
        return false;
      }
      const { items } = data;
      // Commit to news state
      commit(Type.SET_QUOTATION_UPDATE, items);
      return true;
    })
    .catch(e => {
      console.log(
        '🚀 ~ file: actions.js ~ line 66 ~ saveEditCustomerAddress.rest ~ e',
        e
      );
      return false;
    });
};

const getAllQuotationDataByFilterForExportExcel = async ({ commit }, value) => {
  const {
    customerId = '',
    salesInChargeId = '',
    bdInChargeId = '',
    createdAt = [],
    quotationStatusId = '',
    supplierId = '',
    quotationCode = ''
  } = value;

  const params = {};

  if (customerId) {
    params.customerId = customerId;
  }

  if (supplierId !== '') {
    params.supplierId = supplierId;
  }

  if (salesInChargeId) {
    params.salesInChargeId = salesInChargeId;
  }

  if (bdInChargeId) {
    params.bdInChargeId = bdInChargeId;
  }

  if (createdAt && createdAt.length > 0) {
    params.createdAt = createdAt;
  }

  if (quotationStatusId) {
    params.quotationStatusId = quotationStatusId;
  }

  if (quotationCode) {
    params.quotationCode = quotationCode;
  }

  // Get getAll Customer Address
  return service
    .rest('quotation/getAllDataExportExcel', params)
    .then(res => {
      const { status, totalRows, rows } = res;
      if(!status){
        return false;
      };
      if(totalRows <= 0){
        return [];
      };

      commit(Type.GET_QUOTATION_FOR_EXPORT_EXCEL, rows);

      return true;
    })
    .catch(err => {
      console.log('err: ', err);
      return false;
    });
};

export { 
  getAllQuotations,
  getPagingQuotations, 
  getAllQuotationsBySupplier, 
  addNewQuotation, 
  deleteQuotationList, 
  saveEditQuotation, 
  changeQuotationStatus,
  getAllQuotationsByCode,
  recalculateQuotation,
  getAllQuotationDataByFilterForExportExcel 
};
