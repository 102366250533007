import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
    vouchers: [],
    listVoucher: [] 
};

const mutations = {
    async [Type.GET_ALL](state, value) {
      state.vouchers = value.rows;
    },
    async [Type.GET_ALL_VOUCHER_SELECT](state, value) {
      state.listVoucher = value.rows;
    },
    async [Type.SAVE](state, value) {
      state.vouchers = [ ...value, ...state.vouchers]
      Promise.all(value.map( item => {
        state.listVoucher.push(item);
      }));
    },
    async [Type.EDIT](state, value) {
      const oldVouchers = state.vouchers
      const index = state.vouchers.findIndex((element) => element.id == value.id);
      oldVouchers[index] = value;
      state.vouchers = oldVouchers.slice(0);

      // update listVoucher[]
      const indexInListVoucher = state.listVoucher.findIndex((element) => element.id == value.id);
      state.listVoucher[indexInListVoucher].name = value.name
      return state.vouchers
    },
    async [Type.DELETE](state, value) {
        await Promise.all(value.map( item => {
          const index = state.vouchers.findIndex((element) => element.id == item.id);
          state.vouchers.splice(index, 1)
          const indexInListVoucher = state.listVoucher.findIndex((element) => element.id == item.id);
          state.listVoucher.splice(indexInListVoucher, 1)
        }));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};