import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';

const state = {
    quantityOrderStatisticByMonth: [],
    averagePriceOrderStatisticByMonth: [],
    totalOrderType: {},
    statisticOrder: {},
    averagePriceOrderStatisticByDay: [],
    totalOrderData: {}
};

const mutations = {
  async [Type.GET_QUANTITY_ORDER_BY_MONTH](state, value) {
    state.quantityOrderStatisticByMonth = value.data;
  },

  async [Type.GET_AVERAGE_PRICE_ORDER_BY_MONTH](state, value) {
    state.averagePriceOrderStatisticByMonth = value.data;
  },

  async [Type.GET_AVERAGE_PRICE_ORDER_BY_DAY](state, value) {
    state.averagePriceOrderStatisticByDay = value.data.arrData;
    state.totalOrderData = value.data.totalData;
  },

  async [Type.GET_TOTAL_ORDER_TYPE](state, value) {
    state.totalOrderType = value.data;
  },

  async [Type.SET_STATISTIC_BY_STATUS](state, value) {
    state.statisticOrder = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
