import Service from 'core/service';
import * as Type from 'modules/fileManagement/store/types';

const service = new Service();

const getAllFile = async ({ commit }, data) => {
  const files = await service
    .rest('fileManagement/getListPaging', data)
    .then(res => {
      return res.items;
    })
    .catch(error => {
      console.log('[EI Debug] [ERROR] >>> action >> getAllNewsCategory > error ', error);
    });
  console.log('🚀 ~ file: actions.js ~ line 15 ~ getAllFile ~ files', files);
  commit(Type.SET_ALL_FILE, files);
};

/**
 *  fileName,
    url,
    size,
    module,
    categoryId
 */
const addFile = async ({ commit }, { row }) => {
  return service
    .rest('fileManagement/setList', { items: { $add: [row] } })
    .then(rlt => {
      const { items } = rlt;
      const { $rltAdd } = items;
      // Commit to news state
      commit(Type.ADD_FILE, $rltAdd[0]);
      return $rltAdd[0];
    })
    .catch(error => {
      // eslint-disable-next-line
      console.log('[EI Debug] [ERROR] >>> action >> addFile > error ', error);
      return false;
    });
};

const deleteFile = async ({ commit }, value) => {
  const { id } = value;
  if (id) {
    return service
      .rest('fileManagement/setList', { items: { $delete: [value] } })
      .then(rlt => {
        const { items } = rlt;
        const { $rltDelete } = items;
        // Commit to news state
        commit(Type.DELETE_FILE, $rltDelete);
        return true;
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
        return false;
      });
  }
};

const deleteFilesList = async ({ commit }, value) => {
  const lstDB = [];
  // Filter
  value.map(item => {
    const { id } = item;
    if (id) {
      lstDB.push(item);
    }
  });

  if (lstDB.length > 0) {
    return service
      .rest('fileManagement/setList', { items: { $delete: lstDB } })
      .then(rlt => {
        const { items } = rlt;
        const { $rltDelete } = items;
        // Commit to news state
        commit(Type.DELETE_FILES, $rltDelete);
        return true;
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
        return false;
      });
  }
};

const clearAllFile = async ({ commit }) => {
  commit(Type.CELAR_ALL_FILE);
};

export { getAllFile, addFile, deleteFile, deleteFilesList, clearAllFile };
