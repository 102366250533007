// Admin Dashboard
export const GET_CUSTOMER_CHART = 'GET_CUSTOMER_CHART';
export const GET_CUSTOMERS_SIGN_UP = 'GET_CUSTOMERS_SIGN_UP';
export const GET_SUPPLIERS_SIGN_UP = 'GET_SUPPLIERS_SIGN_UP';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ACTIVE_CUSTOMERS = 'GET_ACTIVE_CUSTOMERS';
export const GET_ORDERS_CHART = 'GET_ORDERS_CHART';
export const GET_TOP_CUSTOMERS = 'GET_TOP_CUSTOMERS';
export const GET_TOP_SUPPLIERS = 'GET_TOP_SUPPLIERS';
export const GET_REVENUE_OF_CATEGORY = 'GET_REVENUE_OF_CATEGORY';
export const GET_PROFIT = 'GET_PROFIT';

// Supplier Dashboard
export const GET_SUPPLIER_ORDERS = 'GET_SUPPLIER_ORDERS';
export const GET_SUPPLIER_ORDERS_CHART = 'GET_SUPPLIER_ORDERS_CHART';
export const GET_SUPPLIER_PRODUCTS_SALES = 'GET_SUPPLIER_PRODUCTS_SALES';

// Customer Dashboard
export const GET_ACTIVE_CUSTOMER_MONTHLY = 'GET_ACTIVE_CUSTOMER_MONTHLY';
