import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';

const state = {
  customerAddress: [],
  customerAddressCustomerPage: [],
  customerAddressMapWithCustomer: {}
};

const mutations = {
  [Type.SET_ALL_CUSTOMER_ADDRESS](state, value) {
    state.customerAddress = cloneDeep(value);

    //check if not in customerAddressMapWithCustomer add new value
    if(Array.isArray(value)){
      value.forEach(element => {
        const { customerId } = element;
        if(customerId){
          if(!state.customerAddressMapWithCustomer[customerId]){
            state.customerAddressMapWithCustomer[customerId] = value;
          }else{
            state.customerAddressMapWithCustomer[customerId].push(element);
          };
        } 
      })
    };
  },

  [Type.SET_ALL_CUSTOMER_ADDRESS_IN_PAGE](state, value) {
    state.customerAddress = value;
    state.customerAddressMapWithCustomer = 
    groupBy(value, function(objectAddress) {
      return objectAddress.customerId;
    });
  },

  [Type.SET_CUSTOMERS_ADDRESS_ADD](state, value){
    const oldCustomerAddress = state.customerAddress;

    const { customerId } = value[0];
    // if new CustomerAddress default value is true => update all another data default to false
    if (value[0]['isDefault'] === true) {
      oldCustomerAddress.map(item => {
        if (item.isDefault === true && item.customerId === customerId) {
          item.isDefault = false;
        }
        return item;
      });
    }

    //add new data
    state.customerAddress = [...cloneDeep(value), ...oldCustomerAddress];

    //recalculate the addressMap
    state.customerAddressMapWithCustomer = 
    groupBy(state.customerAddress, function(objectAddress) {
      return objectAddress.customerId;
    });
  },

  [Type.SET_CUSTOMERS_ADDRESS_UPDATE](state, value) {
    const oldCustomerAddress = state.customerAddress;
    const { customerId, id } = value;

    // if new CustomerAddress default value is true => update all another data default to false
    if (value['isDefault'] === true) {
      oldCustomerAddress.map(item => {
        if (item.isDefault === true && item.customerId === customerId) {
          item.isDefault = false;
        }
        return item;
      });
    }

    const objIndex = state.customerAddress.findIndex(obj => obj.id === id);
    oldCustomerAddress[objIndex] = value;
    state.customerAddress = oldCustomerAddress.slice(0);

    //recalculate the addressMap
    state.customerAddressMapWithCustomer = 
    groupBy(state.customerAddress, function(objectAddress) {
      return objectAddress.customerId;
    });
  },

  [Type.SET_DELETE_CUSTOMERS_ADDRESS](state, value){
    const oldData = cloneDeep(state.customerAddress);
    value.map(item => {
      const { id } = item;
      const objIndex = oldData.findIndex(obj => obj.id === id);
      oldData.splice(objIndex, 1);
    });
    state.customerAddress = oldData.slice(0);

    //recalculate the addressMap
    state.customerAddressMapWithCustomer = 
    groupBy(state.customerAddress, function(objectAddress) {
      return objectAddress.customerId;
    });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};