import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  customerProfile: {},
  customers: [],
  customersWithAddress: [],
  customersAddLocal: [],
  customerMap: {},
  totalCustomer: 0,
  totalActiveCustomer: 0,
  totalCount: 0
};

import cloneDeep from 'lodash/cloneDeep';
import {
  isEqual
} from 'lodash';

const mutations = {
  [Type.GET_CUSTOMER_PROFILE](state, value) {
    state.customerProfile = value
  },

  [Type.SET_ALL_CUSTOMERS](state, value) {
    const {
      rows,
      totalRows
    } = value;
    state.customers = rows;
    state.totalCount = totalRows;
    state.customerMap = state.customers.reduce(
      (cMap, c) => ({
        ...cMap,
        [c.id]: c
      }), {}
    );
  },

  [Type.SET_ALL_CUSTOMERS_ADDRESS](state, value) {
    state.customersWithAddress = value;
  },

  [Type.SET_CUSTOMERS_LOCAL](state, value) {
    const oldCustomers = state.customersAddLocal;
    state.customersAddLocal = [...oldCustomers, ...[cloneDeep(value)]];
  },

  [Type.SET_CUSTOMERS_ADD](state, value) {
    const oldNews = state.customers;
    // add new data
    state.customers = [...cloneDeep(value), ...oldNews];
    state.customersAddLocal = [];
  },

  [Type.SET_CUSTOMERS_ADD_WITH_ACTIVE_MAIL](state, value) {
    const listCustomers = value.rltAdd;
    const oldCustomers = state.customers;
    const listItemsSuccess = listCustomers.filter(cus => cus.status === true && cus.items !== null).map(item => item.items);
    // add new data
    state.customers = [...cloneDeep(listItemsSuccess), ...oldCustomers];
    state.customersAddLocal = [];
  },

  [Type.SET_DELETE_CUSTOMERS](state, value) {
    const oldData = cloneDeep(state.customers);
    value.map(item => {
      const {
        id
      } = item;
      const objIndex = oldData.findIndex(obj => obj.id === id);
      oldData.splice(objIndex, 1);
    });
    state.customers = oldData.slice(0);
  },

  [Type.SET_CUSTOMERS_UPDATE](state, value) {
    const oldCustomers = state.customers;
    const {
      id
    } = value;
    const objIndex = state.customers.findIndex(obj => obj.id === id);
    oldCustomers[objIndex] = value;
    state.customers = oldCustomers.slice(0);
  },

  [Type.SET_CUSTOMERS_UPDATE_LOCAL](state, value) {
    const {
      oldData,
      newData
    } = value;
    // File oldData
    const oldCustomersAddLocal = state.customersAddLocal.map(item => {
      if (isEqual(item, oldData)) {
        item = newData;
      }
      return item;
    });
    state.customersAddLocal = oldCustomersAddLocal.slice(0);
  },

  [Type.SET_DELETE_CUSTOMERS_LOCAL](state, value) {
    const oldData = cloneDeep(state.customersAddLocal);
    value.map(item => {
      const objIndex = oldData.findIndex(obj => {
        return isEqual(obj, item);
      });
      oldData.splice(objIndex, 1);
    });
    state.customersAddLocal = oldData.slice(0);
  },

  [Type.TOTAL_COUNT_CUSTOMERS](state, value) {
    state.totalCount = value
  },

  [Type.STATISTIC_CUSTOMER](state, value) {
    state.totalCustomer = value.totalCustomers;
    state.totalActiveCustomer = value.totalActiveCustomers;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};