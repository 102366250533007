import Service from 'core/service';
// import { cloneDeep } from 'lodash';
import * as Type from 'modules/customerRepresentative/store/types';

const service = new Service();

const getAllCustomerRepresentative = async ({ commit }, value) => {
  const { customerId = '' } = value;
  const params = {};

  if (customerId) {
    params.customerId = customerId;
  }
  // Get All
  return service
    .rest('customerRepresentative/getListPaging', params)
    .then(res => {
      commit(Type.SET_ALL_CUSTOMER_REPRESENTATIVE, res.rows);
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
};

const saveAddCustomerRepresentative = async ({ commit }, arrAddData) => {
  Object.keys(arrAddData).forEach(
    k => !arrAddData[k] && arrAddData[k] !== undefined && delete arrAddData[k]
  );
  return service
    .rest('customerRepresentative/add', arrAddData)
    .then(result => {
      const { data, status } = result;
      if (status !== true) {
        return false;
      }
      const { items } = data;
      // Commit to state
      commit(Type.SET_CUSTOMER_REPRESENTATIVE_ADD, [items]);
      return true;
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 66 ~ saveAddCustomerRepresentative ~ e', e);
      return false;
    });
};

export { getAllCustomerRepresentative, saveAddCustomerRepresentative };
