<template>
  <div class="auth-page">
    <b-container>
      <h5 class="auth-logo">
        <i class="fa fa-circle text-gray"></i>&nbsp; EI Industrial
        <i class="fa fa-circle text-warning"></i>&nbsp;
      </h5>
      <Widget
        class="widget-auth mx-auto"
        title="<h3 class='mt-0'>Create an account</h3>"
        custom-header
      >
        <p class="widget-auth-info">Please fill all fields below</p>
        <form class="mt" @submit.prevent="register">
          <b-alert class="alert-sm" variant="warning" :show="!!errorMessage">
            {{ errorMessage }}
          </b-alert>
          <div class="form-group">
            <input
              class="form-control no-border"
              ref="email"
              type="email"
              required
              name="email"
              placeholder="Email"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control no-border"
              ref="username"
              type="text"
              required
              name="username"
              placeholder="Username"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control no-border"
              ref="password"
              type="password"
              required
              name="password"
              placeholder="Password"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control no-border"
              ref="confirmPassword"
              @blur="checkPassword"
              type="password"
              required
              name="confirmPassword"
              placeholder="Confirm"
            />
          </div>
          <div class="form-group">
            <el-select
              size="medium"
              clearable
              filterable
              v-model="supplierId"
              placeholder="Select Supplier"
            >
              <el-option
                v-for="item in supplierOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <b-button type="submit" variant="dark" class="auth-btn mb-3" size="sm">{{
            this.isFetching ? 'Loading...' : 'Register'
          }}</b-button>
          <!-- <p class="widget-auth-info">or sign up with</p>
          <div class="social-buttons">
            <b-button @click="googleLogin" variant="primary" class="social-button mb-2">
              <i class="social-icon social-google"></i>&nbsp;
              <p class="social-text">GOOGLE</p>
            </b-button>
            <b-button @click="microsoftLogin" variant="success" class="social-button">
              <i class="social-icon social-microsoft"></i>&nbsp;
              <p class="social-text">MICROSOFT</p>
            </b-button>
          </div> -->
        </form>
        <p class="widget-auth-info">Already have the account? Login now!</p>
        <router-link class="d-block text-center" to="login"
          >Enter the account</router-link
        >
      </Widget>
    </b-container>
    <footer class="auth-footer">
      <!-- 2019 &copy; Sing App - React Admin Dashboard Template. -->
    </footer>
  </div>
</template>
<script>
import Widget from 'components/Widget/Widget';
import { mapActions, mapState } from 'vuex';
import Service from 'core/service';

export default {
  name: 'RegisterPage',
  components: { Widget },
  data() {
    return { suppliers: [], supplierId: 0 };
  },
  computed: {
    ...mapState('auth', {
      isFetching: state => state.isFetching,
      errorMessage: state => state.errorMessage
    }),

    supplierOptions() {
      const noSupplier = {
        value: 0,
        label: 'Không phải supplier'
      };
      const listSupplier = this.suppliers.map(item => {
        return {
          value: item.id,
          label: item.name
        };
      });
      return [noSupplier, ...listSupplier];
    }
  },

  async created() {
    this.getListSupplier();
  },

  methods: {
    ...mapActions('auth', ['registerUser', 'registerError']),
    async getListSupplier() {
      const service = new Service();
      service
        .rest('supplier/ei/getList')
        .then(data => {
          this.suppliers = data.rows;
        })
        .catch(e => {
          console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e);
        });
    },
    async register() {
      const email = this.$refs.email.value;
      const password = this.$refs.password.value;
      const username = this.$refs.username.value;
      const supplierId = this.supplierId;

      if (!this.isPasswordValid()) {
        this.checkPassword();
      } else {
        // this.registerUser({creds: {email, password}, $toasted: this.$toasted});
        const data = await this.registerUser({
          email,
          password,
          username,
          supplierId
        });
        if (data.type !== 'error') {
          this.$notify({
            title: this.$i18n.t('common.notify.title.success'),
            message: this.$i18n.t('common.notify.message.success.register'),
            type: 'success'
          });
          // this.$cookies.set('access_token', data.result.tokens.access.token, '30d');
          // this.$cookies.set('refresh_token', data.result.tokens.refresh.token, '1y');
          window.location.href = '/login';
          // this.$router.push({ name: 'Login' });
        }
      }
    },
    // googleLogin() {
    //   this.loginUser({ social: 'google' });
    // },
    // microsoftLogin() {
    //   this.loginUser({ social: 'microsoft' });
    // },
    checkPassword() {
      if (!this.isPasswordValid()) {
        if (!this.$refs.password.value) {
          this.registerError('Password field is empty');
        } else {
          this.registerError('Passwords are not equal');
        }
      }
    },
    isPasswordValid() {
      return (
        this.$refs.password.value &&
        this.$refs.password.value === this.$refs.confirmPassword.value
      );
    }
  }
};
</script>
