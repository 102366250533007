import Service from 'core/service'
import { cloneDeep } from 'lodash'
import {
  SET_PRODUCT,
  ADD_PRODUCT_LOCAL,
  PRODUCT_ADD,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_LOCAL,
  DELETE_PRODUCT,
  DELETE_PRODUCT_LOCAL,
  UPDATE_MULTIPLE_PRODUCT,
  SET_PRODUCT_UPDATE_MULTIPLE_PRICE,
  SET_PRODUCT_INPAGE,
  SET_EMPTY_PRODUCT_LIST,
  SAVE_PRODUCT_FROM_ORDER_DETAIL
} from './types'

const service = new Service()

// For Admin

const getAllProducts = async ({ commit }, value) => {
  const {
    categoryId = '',
    supplierId = '',
    name = '',
    tags = [],
    model = '',
    currentPage = 0,
    isGetAll = ''
  } = value
  const params = {}
  if (name && name !== '') {
    params.name = name
  }
  if (categoryId && categoryId !== '') {
    params.categoryId = categoryId
  }
  if (tags && tags.length > 0) {
    params.tags = tags
  }
  if (supplierId && supplierId !== '') {
    params.supplierId = Number(supplierId)
  }
  if (model && model !== '') {
    params.eic = model
  }
  if (currentPage && currentPage > 0) {
    params.currentPage = currentPage
  }
  if (isGetAll !== '') {
    params.isGetAll = isGetAll
  }
  // Get All Product
  return service
    .rest('/product/getListPaging', params)
    .then(res => {
      commit(SET_PRODUCT, res)
      return true
    })
    .catch(err => {
      console.log(err)
      return false
    })
}

const getAllProductsForManagement = async ({ commit }, value) => {
  const {
    categoryId = '',
    supplierId = '',
    name = '',
    tags = [],
    model = '',
    isSourcing = '',
    isDemo = '',
    currentPage = 0,
    isGetAll = ''
  } = value
  const params = {}
  if (name && name !== '') {
    params.name = name
  }
  if (isDemo && isDemo !== '') {
    params.isDemo = isDemo
  }
  if (categoryId && categoryId !== '') {
    params.categoryId = categoryId
  }
  if (tags && tags.length > 0) {
    params.tags = tags
  }
  if (supplierId && supplierId !== '') {
    params.supplierId = Number(supplierId)
  }
  if (model && model !== '') {
    params.eic = model
  }
  if (isSourcing && isSourcing !== '') {
    params.isSourcing = isSourcing
  }
  if (currentPage && currentPage > 0) {
    params.currentPage = currentPage
  }
  if (isGetAll !== '') {
    params.isGetAll = isGetAll
  }
  // Get All Product
  return service
    .rest('/product/getListPagingForManagement', params)
    .then(res => {
      commit(SET_PRODUCT, res)
      return true
    })
    .catch(err => {
      console.log(err)
      return false
    })
}

const addProductLocal = async ({ commit }, product) => {
  commit(ADD_PRODUCT_LOCAL, product)
}

const _convertData = row => {
  const newData = cloneDeep(row)
  return newData
}

const saveAddProduct = async ({ commit }, arrAddData) => {
  // Convert data
  const arrAddDataConvert = arrAddData.map(item => {
    return _convertData(item)
  })
  return service
    .rest('product/setList', {
      items: {
        $add: arrAddDataConvert
      }
    })
    .then(rlt => {
      const { items } = rlt
      const { $rltAdd } = items
      // Check complete
      if ($rltAdd && $rltAdd.length > 0) {
        // Commit to product state
        let status = true
        let message = ''
        for (let i = 0; i < $rltAdd.length; i++) {
          if ($rltAdd[i].actionStatus == 'complete') {
            commit(PRODUCT_ADD, [$rltAdd[i]])
            return {
              status: true
            }
          } else {
            status = false
            message =
              message == ''
                ? message + $rltAdd[i].messageContent
                : message + ' ,' + $rltAdd[i].messageContent
          }
        }
        if (status) {
          return { status }
        }
        return { status, message }
      } else {
        return {
          status: false,
          message: 'Cant call API'
        }
      }
    })
    .catch(e => {
      return {
        status: false,
        message: e.message
      }
    })
}

const saveEditProduct = async ({ commit }, addData) => {
  const { oldData, newData } = addData
  const { id } = newData
  const data = _convertData(newData)
  if (id) {
    // Convert data
    return service
      .rest('product/setList', {
        items: {
          $update: [data]
        }
      })
      .then(rlt => {
        const { items } = rlt
        const { $rltUpdate } = items
        if ($rltUpdate && $rltUpdate.length > 0) {
          // Commit to news state
          if ($rltUpdate[0].actionStatus == 'complete') {
            commit(PRODUCT_UPDATE, $rltUpdate[0])
            return {
              status: true
            }
          } else {
            return {
              status: false,
              message: $rltUpdate[0].messageContent
            }
          }
        } else {
          return {
            status: false,
            message: 'Cant call API'
          }
        }
      })
      .catch(e => {
        return {
          status: false,
          message: e.message
        }
      })
  } else {
    // Commit to news state
    commit(PRODUCT_UPDATE_LOCAL, {
      oldData,
      newData: data
    })
    return {
      status: true
    }
  }
}

const saveEditProductIsSourcing = async ({ commit }, addData) => {
  const { oldData, newData } = addData
  const { id } = newData
  const data = _convertData(newData)
  if (id) {
    // Convert data
    return service
      .rest('product/setList', {
        items: {
          $updateIsSourcing: [data]
        }
      })
      .then(rlt => {
        const { items } = rlt
        const { $rltUpdateIsSourcing } = items
        if ($rltUpdateIsSourcing && $rltUpdateIsSourcing.length > 0) {
          // Commit to news state
          if ($rltUpdateIsSourcing[0].actionStatus == 'complete') {
            commit(PRODUCT_UPDATE, $rltUpdateIsSourcing[0])
            return {
              status: true
            }
          } else {
            return {
              status: false,
              message: $rltUpdateIsSourcing[0].messageContent
            }
          }
        } else {
          return {
            status: false,
            message: 'Cant call API'
          }
        }
      })
      .catch(e => {
        return {
          status: false,
          message: e.message
        }
      })
  } else {
    // Commit to news state
    commit(PRODUCT_UPDATE_LOCAL, {
      oldData,
      newData: data
    })
    return {
      status: true
    }
  }
}

const deleteProduct = async ({ commit }, value) => {
  const { id } = value
  if (id) {
    return service
      .rest('product/setList', {
        items: {
          $delete: [value]
        }
      })
      .then(rlt => {
        const { items } = rlt
        const { $rltDelete } = items
        // Commit to news state
        commit(DELETE_PRODUCT, $rltDelete)
        return value
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
        return false
      })
  } else {
    commit(DELETE_PRODUCT_LOCAL, [value])
    return true
  }
}

const deleteProductList = async ({ commit }, value) => {
  const lstLocal = []
  const lstDB = []
  // Filter
  value.map(item => {
    const { id } = item
    if (id) {
      lstDB.push(item)
    } else {
      lstLocal.push(item)
    }
  })

  if (lstLocal.length > 0) {
    commit(DELETE_PRODUCT_LOCAL, lstLocal)
  }

  if (lstDB.length > 0) {
    return service
      .rest('product/setList', {
        items: {
          $delete: lstDB
        }
      })
      .then(rlt => {
        const { items } = rlt
        const { $rltDelete } = items
        // Commit to news state
        commit(DELETE_PRODUCT, $rltDelete)
        return true
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
        return false
      })
  }
}

const updateProductList = async ({ commit }, value) => {
  const lstLocal = []
  const lstDB = []
  const params = {}
  const {
    categoryId = '',
    supplierId = '',
    price = '',
    discountRate = '',
    ratioPerPrice = '',
    markup = '',
    stock = '',
    isUse = '',
    brandId = '',
    moq = '',
    unitCode = '',
    items
  } = value

  // Filter
  if (categoryId !== '' && categoryId > 0) {
    params.categoryId = categoryId
  }
  if (unitCode !== '') {
    params.unitCode = unitCode
  }
  if (supplierId !== '' && supplierId > 0) {
    params.supplierId = supplierId
  }
  if (price !== '' && price > 0) {
    params.price = price
  }
  if (ratioPerPrice !== '' && ratioPerPrice > 0) {
    params.ratioPerPrice = ratioPerPrice
  }
  if (moq !== '' && moq > 0) {
    params.moq = moq
  }
  if (discountRate !== '' && discountRate >= 0) {
    params.discountRate = discountRate
  }
  if (markup !== '' && markup >= 0) {
    params.markup = markup
  }
  if (stock !== '') {
    params.stock = stock
  }
  if (isUse !== '') {
    params.isUse = isUse
  }
  if (brandId !== '' && brandId > 0) {
    params.brandId = brandId
  }
  items.map(item => {
    const { id } = item
    if (id) {
      lstDB.push(item)
    } else {
      lstLocal.push(item)
    }
  })

  const listProductId = lstDB.map(item => item.id)

  if (lstLocal.length > 0) {
    commit(UPDATE_MULTIPLE_PRODUCT, { listProductId, params })
  }

  if (lstDB.length > 0) {
    return service
      .rest('product/setList', {
        items: {
          $updateMultiple: {
            listProduct: listProductId,
            params
          }
        }
      })
      .then(rlt => {
        const { items } = rlt
        const { $rltUpdateMultiple } = items
        const { status } = $rltUpdateMultiple[0]
        if (status) {
          // Commit to news state
          commit(UPDATE_MULTIPLE_PRODUCT, $rltUpdateMultiple[0].data)
        }
        return $rltUpdateMultiple[0]
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
        return false
      })
  }
}

// For Supplier

const supplierGetAllProducts = async ({ commit }, value) => {
  const {
    categoryId = '',
    supplierId = '',
    name = '',
    tags = [],
    currentPage = 0,
    isGetAll = '',
    isUse = ''
  } = value
  const params = {}
  if (name && name !== '') {
    params.name = name
  }
  if (categoryId && categoryId !== '') {
    params.categoryId = categoryId
  }
  if (tags && tags.length > 0) {
    params.tags = tags
  }
  if (supplierId && supplierId !== '') {
    params.supplierId = Number(supplierId)
  }
  if (currentPage && currentPage > 0) {
    params.currentPage = currentPage
  }
  if (isGetAll !== '') {
    params.isGetAll = isGetAll
  }
  if (isUse !== '') {
    params.isUse = isUse
  }
  // Get All Product
  return service
    .rest('/product/supplier/getListPaging', params)
    .then(res => {
      commit(SET_PRODUCT, res)
      return true
    })
    .catch(err => {
      console.log(err)
      return false
    })
}

const supplierSaveAddProduct = async ({ commit }, arrAddData) => {
  // Convert data
  const arrAddDataConvert = arrAddData.map(item => {
    return _convertData(item)
  })
  return service
    .rest('product/supplier/setList', {
      items: {
        $add: arrAddDataConvert
      }
    })
    .then(rlt => {
      const { items } = rlt
      const { $rltAdd } = items
      // Check complete
      if ($rltAdd && $rltAdd.length > 0) {
        // Commit to product state
        let status = true
        let message = ''
        for (let i = 0; i < $rltAdd.length; i++) {
          if ($rltAdd[i].actionStatus == 'complete') {
            commit(PRODUCT_ADD, [$rltAdd[i]])
            return {
              status: true
            }
          } else {
            status = false
            message =
              message == ''
                ? message + $rltAdd[i].messageContent
                : message + ' ,' + $rltAdd[i].messageContent
          }
        }
        if (status) {
          return { status }
        }
        return { status, message }
      } else {
        return {
          status: false,
          message: 'Cant call API'
        }
      }
    })
    .catch(e => {
      return {
        status: false,
        message: e.message
      }
    })
}

const supplierSaveEditProduct = async ({ commit }, addData) => {
  const { oldData, newData } = addData
  const { id } = newData
  const data = _convertData(newData)
  if (id) {
    // Convert data
    return service
      .rest('product/supplier/setList', {
        items: {
          $update: [data]
        }
      })
      .then(rlt => {
        const { items } = rlt
        const { $rltUpdate } = items
        if ($rltUpdate && $rltUpdate.length > 0) {
          // Commit to news state
          if ($rltUpdate[0].actionStatus === 'complete') {
            commit(PRODUCT_UPDATE, $rltUpdate[0])
            return {
              status: true
            }
          } else {
            return {
              status: false,
              message: $rltUpdate[0].messageContent
            }
          }
        } else {
          return {
            status: false,
            message: 'Cant call API'
          }
        }
      })
      .catch(e => {
        return {
          status: false,
          message: e.message
        }
      })
  } else {
    // Commit to news state
    commit(PRODUCT_UPDATE_LOCAL, {
      oldData,
      newData: data
    })
    return {
      status: true
    }
  }
}

const supplierDeleteProduct = async ({ commit }, value) => {
  const { id } = value
  if (id) {
    return service
      .rest('product/supplier/setList', {
        items: {
          $delete: [value]
        }
      })
      .then(rlt => {
        const { items } = rlt
        const { $rltDelete } = items
        // Commit to news state
        commit(DELETE_PRODUCT, $rltDelete)
        return value
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
        return false
      })
  } else {
    commit(DELETE_PRODUCT_LOCAL, [value])
    return true
  }
}

const supplierDeleteProductList = async ({ commit }, value) => {
  const lstLocal = []
  const lstDB = []
  // Filter
  value.map(item => {
    const { id } = item
    if (id) {
      lstDB.push(item)
    } else {
      lstLocal.push(item)
    }
  })

  if (lstLocal.length > 0) {
    commit(DELETE_PRODUCT_LOCAL, lstLocal)
  }

  if (lstDB.length > 0) {
    return service
      .rest('product/supplier/setList', {
        items: {
          $delete: lstDB
        }
      })
      .then(rlt => {
        const { items } = rlt
        const { $rltDelete } = items
        // Commit to news state
        commit(DELETE_PRODUCT, $rltDelete)
        return true
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
        return false
      })
  }
}

const supplierUpdateProductList = async ({ commit }, value) => {
  const lstLocal = []
  const lstDB = []
  const params = {}
  const {
    discountRate = '',
    stock = '',
    isUse = '',
    brandId = '',
    moq = '',
    unitCode = '',
    items
  } = value

  // Filter
  if (unitCode !== '') {
    params.unitCode = unitCode
  }
  if (moq !== '' && moq > 0) {
    params.moq = moq
  }
  if (discountRate !== '' && discountRate > 0) {
    params.discountRate = discountRate
  }
  if (stock !== '') {
    params.stock = stock
  }
  if (isUse !== '') {
    params.isUse = isUse
  }
  if (brandId !== '' && brandId > 0) {
    params.brandId = brandId
  }
  items.map(item => {
    const { id } = item
    if (id) {
      lstDB.push(item)
    } else {
      lstLocal.push(item)
    }
  })

  const listProductId = lstDB.map(item => item.id)

  if (lstLocal.length > 0) {
    commit(UPDATE_MULTIPLE_PRODUCT, { listProductId, params })
  }

  if (lstDB.length > 0) {
    return service
      .rest('product/supplier/setList', {
        items: {
          $updateMultiple: {
            listProduct: listProductId,
            params
          }
        }
      })
      .then(rlt => {
        const { items } = rlt
        const { $rltUpdateMultiple } = items
        const { status } = $rltUpdateMultiple[0]
        if (status) {
          // Commit to news state
          commit(UPDATE_MULTIPLE_PRODUCT, $rltUpdateMultiple[0].data)
        }
        return $rltUpdateMultiple[0]
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
        return false
      })
  }
}

const updateOnTable = async ({ commit }, value) => {
  const { isMarketPlace, productInfoArr } = value
  return service
    .rest('product/updateOnTable', { isMarketPlace, productInfoArr })
    .then(rlt => {
      const { data, status } = rlt
      if (!status) {
        return false
      }
      // Commit to news state
      commit(SET_PRODUCT_UPDATE_MULTIPLE_PRICE, data)
      return true
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
      return false
    })
}

const setProductInPage = async ({ commit }, value) => {
  // Commit to news state
  commit(SET_PRODUCT_INPAGE, value)
  return true
}

const setEmptyProductList = async ({commit}) =>{
  commit(SET_EMPTY_PRODUCT_LIST)
  return true;
}
 
const saveAddProductFromOrderDetail = async ({ commit }, value) => {
  const productArr = [];
  productArr.push(value);
  return service.rest('product/setList', {
      items: {
        $addIsSourcing: productArr
      }
    })
    .then(rlt => {
      const { items, status, message = ''} = rlt
      const { $rltAddIsSourcing } = items
      commit(SAVE_PRODUCT_FROM_ORDER_DETAIL, { rltAdd: $rltAddIsSourcing });
      return { status, message }
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ getSupplierInfo ~ e', e)
      return { status: false, message: e.message}
    })
}
export {
  getAllProducts,
  getAllProductsForManagement,
  addProductLocal,
  saveAddProduct,
  saveEditProduct,
  saveEditProductIsSourcing,
  deleteProduct,
  updateProductList,
  deleteProductList,
  supplierGetAllProducts,
  supplierSaveAddProduct,
  supplierSaveEditProduct,
  supplierDeleteProduct,
  supplierDeleteProductList,
  supplierUpdateProductList,
  updateOnTable,
  setProductInPage,
  setEmptyProductList,
  saveAddProductFromOrderDetail
}
