import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
import cloneDeep from 'lodash/cloneDeep';

const state = {
  collaborators: [],
  listCollaborators: [],
  totalRowsCollaborators: 0
};

const mutations = {
  async [Type.GET_ALL](state, value) {
    state.collaborators = value.rows;
    state.totalRowsCollaborators = value.totalRows;
    state.listCollaborators = value.rows;
  },
  async [Type.SAVE](state, value) {
    const oldCollaborators = state.collaborators
    // add new data
    state.collaborators = [...cloneDeep(value), ...oldCollaborators];
  },
  async [Type.EDIT](state, value) {
    const oldCollaborators = state.collaborators
    const {
      id
    } = value
    const objIndex = state.collaborators.findIndex((obj => obj.id === id));
    oldCollaborators[objIndex] = value;
    state.collaborators = oldCollaborators.slice(0);
  },
  [Type.DELETE](state, value) {
    const oldData = cloneDeep(state.collaborators)
    value.map(item => {
      const {
        id
      } = item
      const objIndex = oldData.findIndex((obj) => obj.id === id);
      oldData.splice(objIndex, 1)
    })
    state.collaborators = oldData.slice(0)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};