export const SET_PRODUCT = 'SET_PRODUCT';
export const ADD_PRODUCT_LOCAL = 'ADD_PRODUCT_LOCAL';
export const PRODUCT_ADD = 'SET_PARTNER_ADD';
export const PRODUCT_UPDATE = 'PRODUCT_ADD';
export const PRODUCT_UPDATE_LOCAL = 'PRODUCT_UPDATE_LOCAL';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_LOCAL = 'DELETE_PRODUCT_LOCAL';
export const UPDATE_MULTIPLE_PRODUCT = 'UPDATE_MULTIPLE_PRODUCT';
export const SET_PRODUCT_UPDATE_MULTIPLE_PRICE = 'SET_PRODUCT_UPDATE_MULTIPLE_PRICE';
export const SET_PRODUCT_INPAGE = 'SET_PRODUCT_INPAGE';
export const SET_EMPTY_PRODUCT_LIST = 'SET_EMPTY_PRODUCT_LIST';
export const SAVE_PRODUCT_FROM_ORDER_DETAIL = 'SAVE_PRODUCT_FROM_ORDER_DETAIL';