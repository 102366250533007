<template>
  <div class="visits-page">
    <!--  Search (S) -->
    <Widget>
      <el-row :gutter="24">
        <!-- Input Email (S) -->
        <el-col :span="4">
          <div class="grid-content">
            <el-input
              clearable
              size="medium"
              class="input"
              v-model="formSearch.email"
              :fetch-suggestions="querySearchAsync"
              placeholder="Input Email, Name or ID"
              style="width: 100%"
              @input="addEvent"
              @change="addEvent"
            >
            </el-input>
          </div>
        </el-col>
        <!-- Input Email (E) -->
        <!-- Input Role (S) -->
        <el-col :span="4">
          <div class="grid-content">
            <el-select size="medium" clearable filterable class="input" v-model="formSearch.roleId" placeholder="Select Role" style="width: 100%" @input="addEvent" @change="addEvent">
              <el-option v-for="item in roleOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
        </el-col>
        <!-- Input Role (E) -->
      </el-row>
    </Widget>
    <!--  Search (E) -->

    <!--  Content (S) -->
    <Widget>
      <!--  Data (S) -->
      <el-table
        :data="pagedTableData"
        max-height="650"
        style="width: 100%"
        ref="multipleTable"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        :default-sort="{ prop: 'updatedAt', order: 'descending' }"
      >
        <!-- Checkbox (S) -->
        <el-table-column fixed="left" prop="id" type="selection" width="45" label="#"></el-table-column>
        <!-- Checkbox (E) -->

        <!-- Id (S) -->
        <el-table-column prop="id" width="60" label="#"> </el-table-column>
        <!-- Id (E) -->

        <!-- avatar (S) -->
        <el-table-column prop="avatar" :label="$i18n.t('src.modules.user.index.avatar')" width="140">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="scope.row.avatar | convertImageSrc" fit="cover"> </el-image>
          </template>
        </el-table-column>
        <!-- avatar (E) -->

        <!-- Email (S) -->
        <el-table-column prop="email" sortable :label="$i18n.t('src.modules.user.index.email')" width="">
          <template slot-scope="scope">
            <el-tooltip effect="dark" :content="scope.row.email" placeholder="bottom" v-if="scope.row.email !== null && scope.row.email.length > 30">
              <span>{{ scope.row.email | truncate(30) }}</span>
            </el-tooltip>
            <span v-else>
              {{ scope.row.email }}
            </span>
          </template>
        </el-table-column>
        <!-- Email (E) -->

        <!-- Name (S) -->
        <el-table-column prop="username" sortable :label="$i18n.t('src.modules.user.index.userName')" width=""> </el-table-column>
        <!-- Name (E) -->

        <!-- Role (S) -->
        <el-table-column sortable prop="roleId" :label="$i18n.t('src.modules.user.index.role')" width="">
          <template slot-scope="scope">
            <el-tag type="success" effect="dark" style="font-weight: bold">
              {{ roleTableData(scope.row.roleId) }}
            </el-tag>
          </template>
        </el-table-column>
        <!-- Role (E) -->

        <!-- Phone (S) -->
        <el-table-column prop="phone" :label="$i18n.t('src.modules.user.index.phone')" width=""> </el-table-column>
        <!-- Phone (S) -->

        <!-- address (S) -->
        <el-table-column prop="address" :label="$i18n.t('src.modules.user.index.address')" width="">
          <template slot-scope="scope">
            <el-tooltip effect="dark" :content="scope.row.address" placeholder="bottom" v-if="scope.row.address !== null && scope.row.address.length > 30">
              <span>{{ scope.row.address | truncate(30) }}</span>
            </el-tooltip>
            <span v-else>
              {{ scope.row.address }}
            </span>
          </template>
        </el-table-column>
        <!-- address (S) -->

        <!-- Updated At (S) -->
        <el-table-column prop="updatedAt" :label="$i18n.t('src.modules.voucher.index.updatedAt')" sortable width="">
          <template slot-scope="scope">
            {{ scope.row.updatedAt | formattedDate }}
          </template>
        </el-table-column>
        <!-- Updated At (S) -->
      </el-table>
      <!--  Data (E) -->

      <!--  Pagination (S) -->
      <el-row type="flex" justify="center">
        <el-col
          ><div class="grid-content">
            <el-pagination
              class="pagination"
              @current-change="handleCurrentChange"
              :current-page="currentPageChange"
              :page-size="itemsPerPageChange"
              :pager-count="5"
              layout="prev, pager, next"
              :total="totalNewsCnt"
            >
            </el-pagination></div
        ></el-col>
      </el-row>
      <!--  Pagination (E) -->
    </Widget>
    <!--  Content (E) -->

    <!-- Popup Upload File Avatar (S) -->
    <UploadFile
      :show="showUploadFile"
      @onCloseUploadFile="handleOnCloseUploadFile"
      @onSelectedFiles="handleOnSelectedFiles"
      :module-options="moduleOptions"
      :category-options="categoryOptions"
      :accept="'image/*'"
    />
    <!-- Popup Upload File Avatar (E) -->

    <!--  Add Form (S) -->
    <el-dialog :title="titlePopup" :visible.sync="dialogVisible" width="60%" :before-close="handleClose" :close-on-click-modal="false">
      <b-card no-body class="my-account-form">
        <b-tabs pills card vertical>
          <b-tab :title="this.$i18n.t('src.modules.profile.index.userInfo')" active>
            <div class="grid-content">
              <el-form label-position="top" ref="form" :model="form" label-width="100px" :rules="rulesForm">
                <!-- avatar (S) -->
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="$i18n.t('src.modules.user.index.avatar')" prop="avatar" style="margin-bottom: 0px">
                      <!-- Upload Image (S) -->
                      <el-row>
                        <el-col>
                          <el-button type="primary" icon="el-icon-circle-plus-outline" size="mini" @click="handleOpenPopupUploadFile"></el-button>
                          <el-button v-if="showImages" type="danger" icon="el-icon-delete" size="mini" @click="handleDeleteImage"></el-button>
                        </el-col>
                      </el-row>
                      <el-row v-if="showImages">
                        <el-col>
                          <vue-select-image :h="'200px'" :w="'200px'" ref="imageListAddForm" :data-images="avatars" :is-multiple="true" @onselectmultipleimage="onSelectMultipleImageAddForm">
                          </vue-select-image>
                        </el-col>
                      </el-row>
                      <!-- Upload Image (E) -->
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- avatar (E) -->
                <!-- role (S) -->
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="$i18n.t('src.modules.user.index.role')" prop="roleId" style="margin-bottom: 0px">
                      <el-select size="medium" class="role-select" v-model="form.roleId" placeholder="Select Role" filterable style="width: 100%">
                        <el-option v-for="item in roleOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- role (E) -->
                <!-- email (S) -->
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="$i18n.t('src.modules.user.index.email')" prop="email" style="margin-bottom: 0px">
                      <el-input size="medium" type="text" v-model="form.email"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- email (E) -->
                <!-- password (S) -->
                <el-row>
                  <el-col :span="24">
                    <!--   Button Change Password(S) -->
                    <el-button size="mini" :type="btnChangePasswordType" v-on:click="ChangePassword()" class="float-right">
                      {{ btnChangePasswordTitle }}
                    </el-button>
                    <!--   Button Change Password(E) -->
                  </el-col>
                  <el-col :span="12">
                    <el-form-item :label="$i18n.t('src.modules.user.index.password')" prop="password" style="margin-bottom: 0px" v-show="showPasswordEntry">
                      <el-input :disabled="blockPasswordEntry" size="medium" :type="passwordFieldType" v-model="form.password">
                        <template slot="append">
                          <i class="fa" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'" aria-hidden="true" @click="switchVisibility"> </i>
                        </template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- password (E) -->
                <!-- username (S) -->
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="$i18n.t('src.modules.user.index.userName')" prop="username" style="margin-bottom: 0px">
                      <el-input size="medium" v-model="form.username"> </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- username (E) -->
                <!-- First & Last Name (S) -->
                <el-row>
                  <el-col :span="12">
                    <el-form-item :label="$i18n.t('src.modules.user.index.firstName')" prop="firstName" style="margin-bottom: 0px; padding-right: 10px">
                      <el-input size="medium" v-model="form.firstName"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item :label="$i18n.t('src.modules.user.index.lastName')" prop="lastName" style="margin-bottom: 0px">
                      <el-input size="medium" v-model="form.lastName"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- First & Last Name (E) -->
                <!-- phone (S) -->
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="$i18n.t('src.modules.user.index.phone')" prop="phone" style="margin-bottom: 0px">
                      <el-input size="medium" v-model="form.phone" @input="handelValidatePhone"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- phone (E) -->
                <!-- address (S) -->
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="$i18n.t('src.modules.user.index.address')" prop="address" style="margin-bottom: 0px">
                      <el-input size="medium" type="textarea" v-model="form.address"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- address (E) -->
              </el-form>
            </div>
          </b-tab>
          <b-tab :title="this.$i18n.t('src.modules.profile.index.controlNotifications')">
            <div class="grid-content">
              <el-form label-position="top" ref="form" :model="form" label-width="100px" size="small" :rules="rulesForm" class="form-user">
                <!-- <el-row>
                  <el-col :span="24">
                    <el-form-item :label="this.$i18n.t('src.modules.profile.index.isAllowNotification')" prop="isAllowNotification" style="margin-bottom: 0px">
                      <el-switch v-model="form.isAllowNotification"></el-switch>
                    </el-form-item>
                  </el-col>
                </el-row> -->
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="this.$i18n.t('src.modules.profile.index.receiveEmail')" prop="receiveEmail" style="margin-bottom: 0px">
                      <el-checkbox-group v-model="form.receiveEmail" size="mini">
                        <el-checkbox class="mb-12" v-for="item in listSendMailAction" :key="item.value" :label="item.value" border> {{ item.label }} </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
      <!-- Actions (S) -->
      <span slot="footer" class="dialog-footer">
        <!--   Button Cancel(S) -->
        <el-button size="medium" v-on:click="handleExit()">
          {{ $i18n.t('src.modules.user.index.cancel') }}
        </el-button>
        <!--   Button Cancel(E) -->
        <!--   Button Save(S) -->
        <el-button size="medium" v-if="formMode === 'add'" type="success" v-on:click="Submit()">
          {{ $i18n.t('src.modules.user.index.btnSave') }}
        </el-button>
        <!--   Button Save(E) -->
        <!--  Button Edit Save (S) -->
        <el-button size="medium" v-if="formMode === 'edit'" type="primary" v-on:click="SubmitSave()"> <i class="el-icon-circle-check"></i>{{ $i18n.t('src.modules.user.index.btnSave') }} </el-button>
        <!--  Button Edit Save (E) -->
      </span>
      <!-- Actions (E) -->
    </el-dialog>
    <!--  Add Form (E) -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { validateEmail, validatePhone, preventSpace } from 'core/helpers';
import UploadFile from 'components/Upload';
import { UPLOAD_FILE_MODULE } from 'core/constants';
import isEqual from 'lodash/isEqual';
import remove from 'lodash/remove';
import without from 'lodash/without';
import { convertToSlug } from 'core/helpers';

export default {
  components: {
    UploadFile
  },
  data() {
    return {
      S3_STORE_ENDPOINT: process.env.VUE_APP_S3_STORE_ENDPOINT,
      passwordFieldType: 'password',
      showPassword: false,
      blockPasswordEntry: true,
      showPasswordEntry: true,
      btnChangePasswordType: 'success',
      btnChangePasswordTitle: this.$i18n.t('src.modules.user.index.changePassword'),
      links: [],
      timeout: null,
      formSearch: {
        name: '',
        roleId: ''
      },
      form: {
        email: '',
        password: '',
        username: '',
        roleId: '',
        phone: '',
        address: '',
        firstName: '',
        lastName: '',
        avatars: [],
        receiveEmail: [],
        // isAllowNotification: ''
      },
      formDefault: {
        email: '',
        password: '',
        username: '',
        roleId: '',
        phone: '',
        address: '',
        firstName: '',
        lastName: '',
        avatars: [],
        receiveEmail: [],
        // isAllowNotification: ''
      },
      idEdit: '',
      oldEmailEdit: '',
      dialogVisible: false,
      currentPageChange: 1,
      itemsPerPageChange: 10,
      // rulesForm: {},
      rulesForm: {
        roleId: [
          {
            required: true,
            message: this.$i18n.t('src.modules.user.index.rule', {
              name: this.$i18n.t('src.modules.user.index.role')
            }),
            trigger: 'change'
          }
        ],
        email: [
          {
            required: true,
            message: this.$i18n.t('src.modules.user.index.rule', {
              name: this.$i18n.t('src.modules.user.index.email')
            }),
            trigger: 'blur'
          },
          { validator: validateEmail, trigger: 'blur' }
        ],
        password: [],
        phone: []
      },
      phone: [
        { validator: validatePhone, trigger: 'blur' },
        {
          min: 10,
          max: 11,
          message: 'Phone Number Length should be 10 to 11',
          trigger: 'blur'
        }
      ],
      rulePassword: [
        {
          required: true,
          message: this.$i18n.t('src.modules.user.index.rule', { name: 'Password' }),
          trigger: 'blur'
        },
        {
          min: 8,
          message: 'Password length must of at least 8 characters',
          trigger: 'blur'
        }
      ],
      multipleSelection: [],
      formMode: '',
      flagClearForm: 0,

      // Upload image
      showUploadFile: false,
      fileListSelectedFormNews: [],
      moduleOptions: [
        {
          value: UPLOAD_FILE_MODULE.USER,
          label: UPLOAD_FILE_MODULE.USER
        }
      ],
      categoryOptions: [{ value: 1, label: 'No Data' }]
    };
  },
  computed: {
    ...mapState('user', ['users', 'listUser']),
    ...mapState('role', ['listRole']),
    ...mapState('common', ['commonMap']),

    listSendMailAction() {
      const actions = this.commonMap['SED_MAL_AC'] || [];
      return actions.reduce((objKey, obj) => {
        objKey.push({
          value: obj.code,
          label: this.lang == 'en-US' ? JSON.parse(obj.name)['en_US'] : JSON.parse(obj.name)['vi_VN']
        });
        return objKey;
      }, []);
    },

    // Show avatars
    avatars() {
      return this.form.avatars.map((item) => {
        if (item.src.indexOf('http://') !== 0 && item.src.indexOf('https://') !== 0) {
          item.src = this.S3_STORE_ENDPOINT + '/' + item.src;
        }
        return item;
      });
    },

    showImages() {
      if ((this.avatars.length === 1 && this.avatars[0] === '') || this.avatars.length === 0) return false;
      return true;
    },

    titlePopup() {
      switch (this.formMode) {
        case 'add':
          return this.$i18n.t('src.modules.news.index.form_add');
        case 'edit':
          return this.$i18n.t('src.modules.news.index.form_edit');
        default:
          return this.$i18n.t('src.modules.news.index.form_add');
      }
    },

    // filter local
    filterTableData() {
      let queryString = [];
      // query filter Name
      if (this.formSearch.email) {
        queryString.push(
          (data) =>
            data.email.toLowerCase().includes(this.formSearch.email.toLowerCase().trim()) ||
            convertToSlug(data.username.toLowerCase()).includes(convertToSlug(this.formSearch.email.toLowerCase())) ||
            data.id.toString().toLowerCase().includes(this.formSearch.email.toLowerCase().trim())
        );
      }
      // query filter RoleId
      if (String(this.formSearch.roleId)) {
        queryString.push((data) => data.roleId === this.formSearch.roleId);
      }
      // filter
      let result = this.users.filter((data) => queryString.reduce((accumulator, queryFilter) => accumulator && queryFilter(data), true));
      // this.addEvent();
      return result;
    },
    // Paging
    pagedTableData() {
      return this.filterTableData.slice(this.itemsPerPageChange * this.currentPageChange - this.itemsPerPageChange, this.itemsPerPageChange * this.currentPageChange);
    },

    totalNewsCnt() {
      return this.filterTableData.length || 0;
    },

    isDisabledButtonDelete() {
      return !this.multipleSelection.length > 0;
    },

    roleOptions() {
      //get only user's role
      return this.listRole
        .filter((item) => {
          if (item.isBelongToSupplier === false) {
            return item;
          }
        })
        .map((item) => {
          return {
            value: item.id,
            label: item.name
          };
        });
    }
  },

  created() {
    Promise.all([this.getAllUsers(this.formSearch), this.getAllRoles({}), this.actGetCommonByCode('SED_MAL_AC')]);
  },
  methods: {
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('user', ['getAllUsers', 'getAllUsersSelect', 'addUser', 'saveUser', 'editUser', 'deleteUser', 'deleteUsers']),
    ...mapActions('role', ['getAllRoles']),
    ...mapActions('common', ['actGetCommonByCode']),

    handelValidatePhone(value) {
      if (value.trim()) {
        this.rulesForm.phone = this.phone;
      } else {
        this.rulesForm.phone = [];
      }
    },

    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      this.showPassword = this.passwordFieldType === 'password' ? false : true;
    },

    ChangePassword() {
      if (this.showPasswordEntry === false) {
        this.rulesForm.password = this.rulePassword;
        this.blockPasswordEntry = false;
        this.showPasswordEntry = true;
        this.btnChangePasswordType = 'danger';
        this.btnChangePasswordTitle = this.$i18n.t('src.modules.user.index.cancel');
      } else {
        this.rulesForm.password = [];
        this.blockPasswordEntry = true;
        this.showPasswordEntry = false;
        this.btnChangePasswordType = 'success';
        this.btnChangePasswordTitle = this.$i18n.t('src.modules.user.index.changePassword');
      }
      this.formMode === 'edit' ? (this.form.password = '') : (this.form.password = '12345678');
    },

    // Upload File
    // Avatar
    handleOpenPopupUploadFile() {
      this.showUploadFile = true;
    },

    handleOnCloseUploadFile() {
      this.showUploadFile = false;
    },

    handleOnSelectedFiles(arrObj) {
      this.form.avatars = arrObj;
      this.showUploadFile = false;
    },

    onSelectMultipleImageAddForm(value) {
      this.fileListSelectedFormNews = value;
    },

    handleDeleteImage() {
      this.fileListSelectedFormNews.map((item) => {
        remove(this.form.avatars, function (n) {
          return isEqual(n, item);
        });
      });
      this.form.avatars = this.form.avatars.slice();
      this.$refs.imageListAddForm.removeFromMultipleSelected();
    },

    // change page to page_1 for input search
    addEvent() {
      this.currentPageChange = 1;
    },

    // handle input name (S)
    loadAllUser() {
      return this.listUser.map((item) => {
        return {
          value: item.email
        };
      });
    },
    querySearchAsync(queryString, cb) {
      var links = this.loadAllUser();
      var results = queryString ? links.filter(this.createFilter(queryString)) : links;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 200 * Math.random());
    },
    createFilter(queryString) {
      return (link) => {
        return !queryString || link.value.toLowerCase().includes(queryString.toLowerCase());
      };
    },
    // handleSelect(item) {
    //     console.log(item);
    // },
    // handle input name (E)

    // clear form
    clearAddForm() {
      this.form = cloneDeep(this.formDefault);
    },

    // handle add button
    async handleAdd() {
      // this.blockPasswordEntry = true;
      this.showPasswordEntry = true;
      this.ChangePassword();
      this.formMode = 'add';
      if (this.flagClearForm !== 0) {
        this.clearAddForm();
        this.$refs['form'].clearValidate();
      }
      this.dialogVisible = true;
      this.flagClearForm = 1;
      this.form.password = '12345678';
    },

    // handle close button
    handleClose(done) {
      if (this.form.email !== '') {
        this.$confirm(this.$i18n.t('src.modules.user.index.close'))
          .then(() => {
            this.$refs['form'].clearValidate();
            done();
          })
          .catch(() => {});
      } else {
        this.dialogVisible = false;
        this.$refs['form'].clearValidate();
      }
    },

    // handle exit button
    handleExit() {
      if (this.form.email !== '') {
        this.$confirm(this.$i18n.t('src.modules.user.index.close'))
          .then(() => {
            this.dialogVisible = false;
            this.$refs['form'].clearValidate();
            this.clearAddForm();
          })
          .catch(() => {});
      } else {
        this.$refs['form'].clearValidate();
        this.dialogVisible = false;
      }
    },

    // handle save button
    async Submit() {
      await preventSpace(this.form);
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const data = await this.saveUser(this.form);
          if (data.type !== false) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('src.modules.user.index.saveSuccess'),
              type: 'success'
            });
            this.clearAddForm();
            this.$refs['form'].clearValidate();
            this.form.password = '12345678';
            this.dialogVisible = true;
            this.currentPageChange = 1;
          } else {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: `${data.message}`,
              type: 'error'
            });
          }
        } else {
          return false;
        }
      });
    },

    // handle remove button
    handleRemove(data) {
      this.$confirm(this.$i18n.t('src.modules.user.index.delete') + `"${data.row.email}"`)
        .then(async () => {
          const resultDeleted = await this.deleteUser(data);
          if (resultDeleted.type != false) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.success'),
              message: this.$i18n.t('common.notify.message.delete.success'),
              type: 'success'
            });
          } else {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              // message: this.$i18n.t('common.notify.message.error.delete'),
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },

    // handle edit button
    handleEdit(data) {
      if (this.flagClearForm !== 0) {
        this.$refs['form'].clearValidate();
      }
      this.oldEmailEdit = data.row.email;
      this.idEdit = data.row.id;
      // this.blockPasswordEntry = true;
      this.showPasswordEntry = true;
      this.ChangePassword();

      const arrAvatar = without(
        data.row.avatar.split('|').map((src) => {
          return src !== '' ? { src, filePath: src } : null;
        }),
        null
      );

      const settingJson = (data.row.setting && JSON.parse(data.row.setting)) || {};
      const receiveEmailArray = settingJson.receiveEmail || [];
      const receiveEmail = receiveEmailArray.map((item) => item.actionCode);

      this.form = {
        password: '',
        email: data.row.email,
        username: data.row.username,
        roleId: data.row.roleId,
        phone: data.row.phone,
        address: data.row.address,
        firstName: data.row.firstName,
        lastName: data.row.lastName,
        avatars: arrAvatar,
        receiveEmail,
        // isAllowNotification: !!Number(data.row.isAllowNotification)
      };

      this.formMode = 'edit';
      this.dialogVisible = true;
    },

    // handle save edit form
    SubmitSave() {
      preventSpace(this.form);
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.$confirm(this.$i18n.t('src.modules.user.index.edit'))
            .then(async () => {
              const resultEdited = await this.editUser({
                id: this.idEdit,
                oldEmailEdit: this.oldEmailEdit,
                data: this.form
              });
              if (resultEdited.type != false) {
                this.$notify({
                  title: this.$i18n.t('common.notify.title.success'),
                  // message: this.$i18n.t('common.notify.message.success.editItem'),
                  type: 'success'
                });
                this.dialogVisible = false;
                this.ChangePassword();
                this.$refs['form'].clearValidate();
                this.clearAddForm();
              } else {
                this.$notify({
                  title: this.$i18n.t('common.notify.title.error'),
                  message: `${resultEdited.message}`,
                  type: 'error'
                });
              }
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },

    // handle check box (S)
    filterDataTable(rows) {
      this.$refs.multipleTable.clearSelection();
      rows.forEach(async (row) => {
        if (row.id) {
          // console.log(row.id);
        } else {
          await this.$refs.multipleTable.toggleRowSelection(row);
        }
      });
    },

    // selectable(row) {
    //     if (row.id) {
    //         return false;
    //     }else {
    //         return true;
    //     }
    // },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // handle check box (E)

    // handle pagination (S)
    handleCurrentChange(val) {
      this.currentPageChange = val;
    },
    // handle pagination (E)

    // handle delete button
    async handleDelete() {
      this.$confirm('Do you want to remove rows')
        .then(async () => {
          if (this.multipleSelection.length > 0) {
            const result = await this.deleteUsers(this.multipleSelection);
            if (result) {
              this.$notify({
                title: 'Success',
                message: 'Delete rows Success',
                type: 'success'
              });
            } else {
              this.$notify({
                title: 'Error',
                message: 'Can not remove rows',
                type: 'error'
              });
            }
          } else {
            this.$notify({
              title: 'Error',
              message: 'Can not remove rows',
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },
    // handle display role data
    roleTableData(roleId) {
      let name;
      this.listRole.map((item) => {
        if (parseInt(roleId) === item.id) {
          name = item.name || 'No Data';
        }
      });
      return name;
    }
  }
};
</script>

<style src="./index.scss" lang="scss" />
