import Service from 'core/service';
import * as Type from 'modules/voucher/store/types';
import {
    cloneDeep
} from 'lodash';

const service = new Service()

const _convertData = (row) => {
    const newData = cloneDeep(row)
    return newData
}

const getAllVouchers = async ({ commit }, value) => {
    const { name = '' } = value;

    const params = { }

    if (name) {
        params.name = name;
    };
   
    const data = await service.rest('voucher/getListPaging', params)
      
    if (data) {
        commit(Type.GET_ALL, data);
    } else {
        console.log('error');
    } 
};

const addVoucher = async ({ commit }, arrAddData) => {
    // Convert data
    const addDataConvert = _convertData(arrAddData);
    return service.rest('voucher/setList', {
        items: {
            $add: [addDataConvert]
        }
    }).then(rlt => {
        const {
            items
        } = rlt;
        const {
            $rltAdd
        } = items
        // Commit to voucher state
        if ($rltAdd && $rltAdd.length > 0) {
            // Commit to voucher state
            commit(Type.SAVE, $rltAdd);
            return {
                status: true,
                message: 'success'
            }
        } else {
            throw 'exit';
        }
    }).catch(e => {
        return {
            status: false,
            message: e.message
        }
    })
};

const editVoucher = async ({ commit }, editData) => {
    // Convert data
    const data = _convertData(editData);
    return service.rest('voucher/setList', {
        items: {
            $update: [data]
        }
    }).then(rlt => {
        const {
            items
        } = rlt;
        const {
            $rltUpdate
        } = items
        if ($rltUpdate && $rltUpdate.length > 0) {
            // Commit to voucher state
            commit(Type.EDIT, $rltUpdate[0]);
            return {
                status: true,
                message: 'success'
            }
        } else {
            return {
                status: false,
                message: 'Cant update voucher'
            }
        }
    }).catch(e => {
        return {
            status: false,
            message: e.message
        }
    })
};

const deleteVoucher = async ({ commit }, value) => {
    if (value.row.id) { 
        return await service.rest('voucher/setList', { 
            items: {
                $delete: [value.row]
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltDelete} = items
            // Commit to news state
            commit(Type.DELETE, $rltDelete);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const deleteVouchers = async ({ commit }, value) => {
    if (value.length > 0) { 
        return await service.rest('voucher/setList', { 
            items: {
                $delete: value
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltDelete} = items
            // Commit to news state
            commit(Type.DELETE, $rltDelete);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const getAllVouchersSelect = async ({ commit }) => {
    const data = await service.rest('voucher/getListPaging', {})
    if (data) {
        commit(Type.GET_ALL_VOUCHER_SELECT, data);
    } else {
        console.log('error');
    } 
};

// const getListVoucher = async () => {    
//     const data = await service.rest('voucher/getListPaging', {})
//     .then(res=> {
//         return res.rows
//     }).catch(err=> {
//         console.log(err);
//     })
//     return data
// };


export {
    editVoucher,
    getAllVouchers,
    addVoucher,
    deleteVoucher,
    deleteVouchers,
    getAllVouchersSelect
};