import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
import cloneDeep from 'lodash/cloneDeep'

const state = {
    suppliers: [],
    suppliersMap: {},
    listSupplier: [],
    supplierInfo: {},
    suppliersInPage: []
};

const mutations = {
    [Type.GET_ALL_SUPPLIER](state, value) {
        state.suppliers = value;
        state.suppliersMap = state.suppliers.reduce(
            (cMap, c) => ({
              ...cMap,
              [c.id]: c
            }),
            {}
          );
    },

    [Type.GET_ALL_SUPPLIER_IN_PAGE](state, value) {
        state.suppliersInPage = value;
    },
    
    [Type.ADD_SUPPLIER](state,value) {
        const oldSuppliers = state.suppliers
        // add new data
        state.suppliers = [...cloneDeep(value),...oldSuppliers];
        state.suppliersMap = state.suppliers.reduce(
            (cMap, c) => ({
              ...cMap,
              [c.id]: c
            }),
            {}
          );
    },

    [Type.EDIT_SUPPLIER](state,value) {
        const oldSuppliers = state.suppliers
        const {id} = value
        const objIndex = state.suppliers.findIndex((obj => obj.id === id));
        oldSuppliers[objIndex] = value;
        state.suppliers = oldSuppliers.slice(0);
        state.supplierInfo = value;
    },

    [Type.CHANGE_STATUS_SUPPLIER](state,value) {
        const oldSuppliers = state.suppliers
        const {id} = value
        const objIndex = state.suppliers.findIndex((obj => obj.id === id));
        oldSuppliers[objIndex] = value;
        state.suppliers = oldSuppliers.slice(0);
    },


    [Type.DELETE_SUPPLIER](state, value) {
        const oldData = cloneDeep( state.suppliers)
        value.map(item=>{
            const {id} =item
            const objIndex =   oldData.findIndex((obj) => obj.id === id );
            oldData.splice(objIndex, 1)
        })
        state.suppliers = oldData.slice(0)       
    },
    async [Type.GET_ALL_SUPPLIER_SELECT](state, value) {
        state.listSupplier = value.rows;
    },
    async [Type.GET_SUPPLIER_INFO](state, value) {
        state.supplierInfo = value.data;
    },
    [Type.SET_ADD_SUPPLIER_FROM_ORDER_DETAIL](state, value) {
        const { rltAdd } = value;
        const oldSuppliers = state.suppliers
        // add new data
        state.suppliers = [...cloneDeep(rltAdd),...oldSuppliers];
        state.suppliersMap = state.suppliers.reduce(
            (cMap, c) => ({
              ...cMap,
              [c.id]: c
            }),
            {}
          );
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};