import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  channels:[],
};

// import cloneDeep from 'lodash/cloneDeep'
// import { isEqual } from 'lodash';

const mutations = {
  
  [Type.SET_ALL_CHANNELS](state, value) {
    state.channels = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};