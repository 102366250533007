<template>
  <div>
    <b-row>
      <b-col lg="12" sm="12" xs="12" style="padding: 10px">
        <div class="h-100">
          <Widget style="margin-bottom: 0px">
            <b-card no-body class="my-account-form">
              <b-tabs pills card vertical>
                <b-tab :title="this.$i18n.t('src.modules.profile.index.userInfo')" active>
                  <div class="d-flex justify-content-between align-items-center mb-lg-0">
                    <h5 class="fw-semi-bold">
                      {{ this.$i18n.t('src.modules.profile.index.profile') }}
                    </h5>
                    <span class="el-icon-user text-primary rotate-200" style="font-size: 50px" />
                  </div>
                  <div class="grid-content">
                    <el-row type="flex">
                      <el-col
                        ><div class="grid-content">
                          <el-row type="flex">
                            <el-tooltip class="item" effect="dark" :content="this.$i18n.t('src.modules.profile.index.avatar')" placement="top">
                              <el-image style="width: 100px; height: 100px; border-radius: 100px" :src="formUser.avatar | convertImageSrc" fit="cover" @click="changeAvata" />
                            </el-tooltip>
                            <div class="h-100" style="padding: 10px">
                              <el-row
                                ><h6 class="fw-semi-bold">
                                  {{ formUser.username }}
                                </h6></el-row
                              >
                              <el-row
                                ><h6 class="fw-semi-bold">{{ formUser.role }}</h6></el-row
                              >
                              <el-row
                                ><h6 class="fw-semi-bold">
                                  {{ formUser.company }}
                                </h6></el-row
                              >
                            </div>
                          </el-row>
                        </div></el-col
                      >
                    </el-row>

                    <el-divider></el-divider>

                    <el-form label-position="top" ref="formUser" :model="formUser" label-width="100px" size="small" :rules="rulesForm" class="form-user">
                      <!--email (S) -->
                      <el-row>
                        <el-col :span="24">
                          <el-form-item :label="this.$i18n.t('src.modules.profile.index.email')" prop="email" style="margin-bottom: 0px">
                            <el-input size="medium" type="text" v-model="formUser.email"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <!-- email (E) -->
                      <!-- First Name (S) -->
                      <el-row>
                        <el-col :span="24">
                          <el-form-item :label="this.$i18n.t('src.modules.profile.index.firstName')" prop="firstName" style="margin-bottom: 0px">
                            <el-input size="medium" type="text" v-model="formUser.firstName"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <!-- First Name (E) -->
                      <!-- Last Name (S) -->
                      <el-row>
                        <el-col :span="24">
                          <el-form-item :label="this.$i18n.t('src.modules.profile.index.lastName')" prop="lastName" style="margin-bottom: 0px">
                            <el-input size="medium" v-model="formUser.lastName"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <!-- Last Name (E) -->
                      <!-- Last Name (S) -->
                      <el-row>
                        <el-col :span="24">
                          <el-form-item :label="this.$i18n.t('src.modules.profile.index.phone')" prop="phone" style="margin-bottom: 0px">
                            <el-input size="medium" v-model="formUser.phone"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <!-- Last Name (E) -->
                      <!-- Address (S) -->
                      <el-row>
                        <el-col :span="24">
                          <el-form-item :label="this.$i18n.t('src.modules.profile.index.address')" prop="address" style="margin-bottom: 0px">
                            <el-input size="medium" type="textarea" v-model="formUser.address"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <!-- Address (E) -->
                    </el-form>
                  </div>
                </b-tab>
                <!-- <b-tab :title="this.$i18n.t('src.modules.profile.index.controlNotifications')">
                  <div class="grid-content">
                    <el-form label-position="top" ref="formUser" :model="formUser" label-width="100px" size="small" :rules="rulesForm" class="form-user">
                      <el-row>
                        <el-col :span="24">
                          <el-form-item :label="this.$i18n.t('src.modules.profile.index.isAllowNotification')" prop="isAllowNotification" style="margin-bottom: 0px">
                            <el-switch v-model="formUser.isAllowNotification"></el-switch>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="24">
                          <el-form-item :label="this.$i18n.t('src.modules.profile.index.receiveEmail')" prop="receiveEmail" style="margin-bottom: 0px">
                            <el-checkbox-group v-model="formUser.receiveEmail" size="mini">
                              <el-checkbox class="mb-12" v-for="item in listSendMailAction" :key="item.value" :label="item.value" border> {{ item.label }} </el-checkbox>
                            </el-checkbox-group>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form>
                  </div>
                </b-tab> -->
              </b-tabs>
              <!-- Actions (S) -->
              <span slot="footer" class="dialog-footer">
                <el-row>
                  <el-col :span="12">
                    <!-- Change Password (S) -->
                    <el-button type="danger" @click="handleRedirectChangePassPage">
                      {{ this.$i18n.t('src.modules.profile.index.btnChangePassword') }}
                    </el-button>
                    <!-- Change Password (S) -->
                  </el-col>
                  <el-col :span="12">
                    <!-- Change Read to edit (S) -->
                    <el-button size="medium" type="success" @click="Save()" style="float: right">
                      {{ this.$i18n.t('src.modules.profile.index.btnSave') }}
                    </el-button>
                    <!-- Change Read to edit (S) -->
                  </el-col>
                </el-row>
              </span>
              <!-- Actions (E) -->
            </b-card>
          </Widget>
        </div>
      </b-col>
    </b-row>

    <UploadFile :show="showUploadFile" @onCloseUploadFile="handleOnCloseUploadFile" @onSelectedFiles="handleOnSelectedFiles" :module-options="moduleOptions" :accept="'/user/avatar/'" />

    <!-- popup -->

    <!-- Popup Upload File Icon (S) -->
    <UploadFile
      v-if="isSupplier"
      :show="showUploadFileSupplier"
      @onCloseUploadFile="handleOnCloseUploadFileSupplier"
      @onSelectedFiles="handleOnSelectedFilesSupplier"
      :module-options="moduleOptionsSupplier"
      :category-options="categoryOptionsSupplier"
      :accept="'image/*'"
    />
    <!-- Popup Upload File Icon (E) -->
    <!-- Popup Upload File Banner (S) -->
    <UploadFile
      v-if="isSupplier"
      :show="showUploadFileBanner"
      @onCloseUploadFile="handleOnCloseUploadFileBanner"
      @onSelectedFiles="handleOnSelectedFilesBanner"
      :module-options="moduleOptions"
      :category-options="categoryOptionsSupplier"
      :accept="'image/*'"
    />
    <!-- Popup Upload File Banner (E) -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UploadFile from 'components/Upload';
import remove from 'lodash/remove';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { UPLOAD_FILE_MODULE } from 'core/constants';
import { validateEmail, validatePhone, validateTaxCode } from 'core/helpers';
import without from 'lodash/without';

export default {
  components: { UploadFile },
  data() {
    return {
      S3_STORE_ENDPOINT: process.env.VUE_APP_S3_STORE_ENDPOINT,
      formUser: {
        firstName: '',
        lastName: '',
        username: '',
        avatar: '',
        phone: '',
        role: '',
        address: '',
        email: '',
        company: '',
        receiveEmail: [],
        isAllowNotification: ''
      },
      modules: [],
      checkSwitch: true,
      // Rule Form Add, Edit
      rulesForm: {
        phone: [
          { validator: validatePhone, trigger: 'blur' },
          {
            min: 10,
            max: 11,
            message: 'Phone Number Length should be 10 to 11',
            trigger: 'blur'
          }
        ],
        email: [{ validator: validateEmail, trigger: 'blur' }]
      },

      fileListSelectedFormNews: [],

      formUserOld: {},

      // Popup Upload File
      showUploadFile: false,

      dialogVisibleFormPassword: false,
      dialogVisible: false,
      moduleOptions: [{ value: UPLOAD_FILE_MODULE.USER, label: UPLOAD_FILE_MODULE.USER }],

      formSupplier: {
        // supplier suppliers input
        name: '',
        address: '',
        phone: '',
        email: '',
        company: '',
        taxCode: '',
        code: '',
        shortDesc: '',
        desc: '',
        type: '',
        icons: [],
        banners: [],
        content: '',
        receiveEmail: []
      },
      formSupplierOld: {
        name: '',
        address: '',
        phone: '',
        email: '',
        company: '',
        taxCode: '',
        code: '',
        shortDesc: '',
        desc: '',
        type: '',
        receiveEmail: []
      },
      rulesSupplierForm: {
        name: [
          {
            required: true,
            message: this.$i18n.t('src.modules.supplier.index.rule', { name: 'Name' }),
            trigger: 'blur'
          },
          { validator: this.checkNameDuplicate, trigger: 'blur' }
        ],
        address: [
          {
            required: true,
            message: this.$i18n.t('src.modules.supplier.index.rule', { name: 'Address' }),
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: this.$i18n.t('src.modules.supplier.index.rule', { name: 'Phone' }),
            trigger: 'blur'
          },
          { validator: validatePhone, trigger: 'blur' },
          {
            min: 10,
            max: 11,
            message: 'Phone Number Length should be 10 to 11',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: this.$i18n.t('src.modules.supplier.index.rule', { name: 'Email' }),
            trigger: 'blur'
          },
          { validator: validateEmail, trigger: 'blur' },
          { validator: this.checkEmailDuplicate, trigger: 'blur' }
        ],
        company: [
          {
            required: true,
            message: this.$i18n.t('src.modules.supplier.index.rule', {
              name: 'Company Name'
            }),
            trigger: 'blur'
          }
        ],
        taxCode: [
          {
            required: true,
            message: this.$i18n.t('src.modules.supplier.index.rule', {
              name: 'Tax Code'
            }),
            trigger: 'blur'
          },
          { validator: validateTaxCode, trigger: 'blur' },
          { validator: this.checkTaxCodeDuplicate, trigger: 'blur' }
        ]
      },
      // ckeditor
      editorConfig: {
        language: 'en'
      },
      // Upload image
      showUploadFileSupplier: false,
      fileListSelectedFormSupplier: [],
      moduleOptionsSupplier: [{ value: UPLOAD_FILE_MODULE.SUPPLIER, label: UPLOAD_FILE_MODULE.SUPPLIER }],
      categoryOptionsSupplier: [{ value: 1, label: 'No Data' }],
      // Banners
      showUploadFileBanner: false,
      fileListSelectedFormBanner: []
    };
  },

  computed: {
    ...mapState('profile', ['userProfile']),
    ...mapState('supplier', ['supplierInfo']),
    ...mapState('common', ['commonMap']),

    listSendMailAction() {
      const actions = this.commonMap['SED_MAL_AC'] || [];
      return actions.reduce((objKey, obj) => {
        objKey.push({
          value: obj.code,
          label: this.lang == 'en-US' ? JSON.parse(obj.name)['en_US'] : JSON.parse(obj.name)['vi_VN']
        });
        return objKey;
      }, []);
    },

    isSupplier() {
      return this.userProfile.supplierId == null ? false : true;
    },

    userAvater() {
      return this.formUser.avatar.map((item) => {
        if (item.src.indexOf('http://') !== 0 && item.src.indexOf('https://') !== 0) {
          item.src = this.S3_STORE_ENDPOINT + '/' + item.src;
        }
        return item;
      });
    },

    showuserAvater() {
      if ((this.userAvater.length === 1 && this.userAvater[0] === '') || this.userAvater.length === 0) return false;
      return true;
    },

    // Show Icon
    icons() {
      return this.formSupplier.icons.map((item) => {
        if (item.src.indexOf('http://') !== 0 && item.src.indexOf('https://') !== 0) {
          item.src = this.S3_STORE_ENDPOINT + '/' + item.src;
        }
        return item;
      });
    },

    showImages() {
      if ((this.icons.length === 1 && this.icons[0] === '') || this.icons.length === 0) return false;
      return true;
    },
    // Show Banner
    banners() {
      return this.formSupplier.banners.map((item) => {
        if (item.src.indexOf('http://') !== 0 && item.src.indexOf('https://') !== 0) {
          item.src = this.S3_STORE_ENDPOINT + '/' + item.src;
        }
        return item;
      });
    },

    showImagesBanner() {
      if ((this.banners.length === 1 && this.banners[0] === '') || this.banners.length === 0) return false;
      return true;
    }
  },

  watch: {},

  async created() {
    await this.getUserProfile();
    this.loadDataUser();
    if (this.userProfile && this.userProfile.supplierId) {
      await this.getSupplierInfo(this.userProfile.supplierId);
      this.loadSupplierData();
    }

    this.actGetCommonByCode('SED_MAL_AC');
  },
  methods: {
    ...mapActions('profile', ['getUserProfile', 'saveEditProfile', 'getRoleUser', 'getCompanyUser']),
    ...mapActions('supplier', ['getSupplierInfo', 'editSupplier']),
    ...mapActions('global', ['setGlobalReady']),
    ...mapActions('common', ['actGetCommonByCode']),

    colorButton(action) {
      // define color for col Action
      switch (action) {
        case 'add':
          return 'success';
        case 'edit':
          return 'primary';
        case 'delete':
          return 'danger';
        default:
          return 'info';
      }
    },
    changeAvata() {
      //open popup upload file
      this.showUploadFile = true;
      this.formUserOld = cloneDeep(this.userProfile);
    },

    async loadDataUser() {
      const settingJson = (this.userProfile.setting && JSON.parse(this.userProfile.setting)) || {};
      const receiveEmailArray = settingJson.receiveEmail || [];
      const receiveEmail = receiveEmailArray.map((item) => item.actionCode);
      this.formUser = {
        firstName: this.userProfile.firstName,
        lastName: this.userProfile.lastName,
        username: this.userProfile.username,
        avatar: this.userProfile.avatar,
        phone: this.userProfile.phone,
        role: await this.getRoleUser(),
        address: this.userProfile.address,
        email: this.userProfile.email,
        company: this.userProfile.companyId,
        isAllowNotification: !!Number(this.userProfile.isAllowNotification),
        receiveEmail
      };
    },

    handleDeleteImage() {
      this.fileListSelectedFormNews.map((item) => {
        remove(this.formUser.images, function (n) {
          return isEqual(n, item);
        });
      });
      this.formUser.images = this.formUser.images.slice();
      this.$refs.imageListAddForm.removeFromMultipleSelected();
    },

    async Save() {
      this.$confirm('Do you want change ?')
        .then(async () => {
          const result = await this.saveEditProfile(this.formUser);
          if (result && result.status) {
            this.loadDataUser();
            this.$notify({
              title: 'Success',
              message: 'Edit profile success',
              type: 'success'
            });
          } else {
            this.$notify({
              title: 'Error',
              message: result.message,
              type: 'error'
            });
          }
        })
        .catch(() => {});
    },

    // Upload File
    handleOpenPopupUploadFile() {
      this.showUploadFile = true;
    },

    handleRedirectChangePassPage() {
      // this.dialogVisibleFormPassword = true
      this.$router.push({
        name: 'ChangePassword',
        query: { email: this.formUser.email }
      });
    },

    handleOnCloseUploadFile() {
      this.showUploadFile = false;
    },

    handleOnSelectedFiles(arrObj) {
      this.formUser.avatar = arrObj[0].filePath;
      this.showUploadFile = false;
    },

    // supplier info
    // Upload File
    // Icon
    handleOpenPopupUploadFileSupplier() {
      this.showUploadFileSupplier = true;
    },

    handleOnCloseUploadFileSupplier() {
      this.showUploadFileSupplier = false;
    },

    handleOnSelectedFilesSupplier(arrObj) {
      this.formSupplier.icons = arrObj;
      this.showUploadFileSupplier = false;
    },

    onSelectMultipleImageAddFormSupplier(value) {
      this.fileListSelectedFormSupplier = value;
    },

    handleDeleteImageSupplier() {
      this.fileListSelectedFormSupplier.map((item) => {
        remove(this.formSupplier.icons, function (n) {
          return isEqual(n, item);
        });
      });
      this.formSupplier.icons = this.formSupplier.icons.slice();
      this.$refs.imageListAddFormSupplier.removeFromMultipleSelected();
    },
    // Banner
    handleOpenPopupUploadFileBanner() {
      this.showUploadFileBanner = true;
    },

    handleOnCloseUploadFileBanner() {
      this.showUploadFileBanner = false;
    },

    handleOnSelectedFilesBanner(arrObj) {
      this.formSupplier.banners = arrObj;
      this.showUploadFileBanner = false;
    },

    onSelectMultipleImageAddFormBanner(value) {
      this.fileListSelectedFormBanner = value;
    },

    handleDeleteImageBanner() {
      this.fileListSelectedFormBanner.map((item) => {
        remove(this.formSupplier.banners, function (n) {
          return isEqual(n, item);
        });
      });
      this.formSupplier.banners = this.formSupplier.banners.slice();
      this.$refs.imageListAddFormBanner.removeFromMultipleSelected();
    },

    async loadSupplierData() {
      const arrIcon = without(
        this.supplierInfo.icon.split('|').map((src) => {
          return src !== '' ? { src, filePath: src } : null;
        }),
        null
      );
      const arrBanner = without(
        this.supplierInfo.banner.split('|').map((src) => {
          return src !== '' ? { src, filePath: src } : null;
        }),
        null
      );
      this.formSupplier = {
        id: this.supplierInfo.id,
        name: this.supplierInfo.name,
        address: this.supplierInfo.address,
        phone: this.supplierInfo.phone,
        email: this.supplierInfo.email,
        company: this.supplierInfo.company,
        taxCode: this.supplierInfo.taxCode,
        code: this.supplierInfo.code,
        shortDesc: this.supplierInfo.shortDesc,
        desc: this.supplierInfo.desc,
        type: this.supplierInfo.type,
        icons: arrIcon,
        banners: arrBanner,
        content: this.supplierInfo.content
      };
    },
    // save
    onSaveEditSupplier() {
      this.$refs['formSupplier'].validate(async (valid) => {
        if (valid) {
          try {
            this.$confirm('Do you want change ?')
              .then(async () => {
                const result = await this.editSupplier({
                  oldData: this.formSupplierOld,
                  newData: this.formSupplier
                });
                if (result.status) {
                  this.formSupplierOld = {};
                  this.$notify({
                    title: 'Success',
                    message: 'Edit supplier success',
                    type: 'success'
                  });
                } else {
                  this.$notify({
                    title: 'Error',
                    message: `Can not edit supplier Error: ${result.message}`,
                    type: 'error'
                  });
                }
                this.supplierDialogVisible = false;
                this.clearFormSupplier();
              })
              .catch(() => {});
          } catch (error) {
            this.$notify({
              title: this.$i18n.t('common.notify.title.error'),
              message: this.$i18n.t('common.notify.message.add.failr', { name: 'row' }),
              type: 'error'
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style>
.my-account-form .tables-basic {
  font-weight: normal !important;
  height: 100% !important;
}
.my-account-form .tabs .nav-item {
  transform: rotate(-90deg) !important;
  margin-left: -25px !important;
  margin-top: 14% !important;
  margin-bottom: 15% !important;
  height: 150px;
  width: 200px;
}
.my-account-form .card-header {
  padding: 0rem 0rem !important;
  margin-right: -140px !important;
}
.my-account-form .nav-pills .nav-link {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}
.my-account-form .nav-link {
  display: block !important;
  padding: 0.5rem 1rem !important;
  text-align: center !important;
}
.my-account-form .tab-content.col {
  max-width: 100% !important;
}

button.el-button.permission-tags {
  height: 30px;
  margin-inline: 10px;
  margin-bottom: 10px;
}

.form-user {
  margin-top: 10px;
  overflow: auto;
  max-height: 607px;
  min-height: 607px;
}

.permission-table {
  margin-top: 10px;
  overflow: auto;
  max-height: 900px;
  min-height: 900px;
}
</style>
