</<template>
<div>
  <Widget style="margin-bottom: 0px">
    <el-row type="flex" justify="end">
      <el-col>
        <div class="grid-content">
          <el-row type="flex" justify="end">
            <el-button
              v-can="'customer-invoice-address.add'"
              size="medium" 
              type="primary" 
              @click="onClickShowAddPopup()"
            >
              <i class="el-icon-circle-plus-outline"></i>
              {{ $t('src.modules.news.index.btnAdd') }}
            </el-button>
            <el-button
              v-can="'customer-invoice-address.delete'"
              size="medium"
              type="danger"
              @click="onClickDeleteData()"
              :disabled="isDisabledButtonDelete"
            >
              <i class="el-icon-delete"></i>
              {{ $t('src.modules.news.index.btnDelete') }}
            </el-button>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-table
        v-loading="isLoading"
        ref="newsTable"
        :data="pagedTableData"
        :default-sort="{ prop: 'updatedAt', order: 'descending' }"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >

        <!-- Check box (S) -->
        <el-table-column type="selection" width="50"></el-table-column>
        <!-- Check box (E) -->

        <!-- Id (S) -->
        <!-- <el-table-column prop="id" label="#" width="50"></el-table-column> -->
        <!-- Id (E) -->

        <!-- companyName (S) -->
        <el-table-column
          prop="companyName"
          :label="$i18n.t('src.modules.customer.index.btn.showInfoCustomer.table.companyName')"
          width="200"
          sortable
        >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.companyName"
            placement="left"
            v-if="scope.row.companyName && scope.row.companyName.length > 50"
          >
            <span>{{ scope.row.companyName.slice(0, 50) + '...' }}</span>
          </el-tooltip>
          <span v-else>
            {{ scope.row.companyName }}
          </span>
        </template>
        </el-table-column>
        <!-- companyName (E)-->

        <!-- taxCode (S) -->
        <el-table-column
          prop="taxCode"
          :label="$i18n.t('src.modules.customer.index.btn.showInfoCustomer.table.taxCode')"
          width="200"
          sortable
        >
        </el-table-column>
        <!-- taxCode (E)-->

        <!-- email (S) -->
        <el-table-column
          prop="email"
          label="Email"
          width="200"
          sortable
        >
        </el-table-column>
        <!-- email (E)-->

        <!-- phone (S) -->
        <el-table-column
          prop="phone"
          :label="$i18n.t('src.modules.customer.index.btn.showInfoCustomer.table.phone')"
          width="200"
          sortable
        >
        </el-table-column>
        <!-- phone (E)-->

        <!-- address (S) -->
        <el-table-column
          prop="fullAddressString"
          :label="$i18n.t('src.modules.customer.index.btn.showInfoCustomer.table.address')"
          width="200"
          sortable
        >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.fullAddressString"
            placement="left"
            v-if="scope.row.fullAddressString && scope.row.fullAddressString.length > 50"
          >
            <span>{{ scope.row.fullAddressString.slice(0, 50) + '...' }}</span>
          </el-tooltip>
          <span v-else>
            {{ scope.row.fullAddressString }}
          </span>
        </template>
        </el-table-column>
        <!-- address (E)-->

        <!-- Status (S) -->
        <el-table-column
          prop="isDefault"
          :label="$i18n.t('src.modules.customerAddress.index.isDefault')"
          width="150"
          sortable
        >
          <template slot-scope="scope">
            <el-tag
              style="width: 100%;text-align: center;"
              :key="scope.row.isDefault === true ? 'ACTIVE' : ''"
              :type="scope.row.isDefault === true ? 'danger' : 'info'"
              effect="dark"
            >
              {{
                scope.row.isDefault === true
                  ? $i18n.t('src.modules.customerAddress.index.active')
                  : $i18n.t('src.modules.customerAddress.index.inActive')
              }}
            </el-tag>
          </template>
        </el-table-column>
        <!-- Status (E) -->
        <!-- Actions (S) -->
        <el-table-column
          :label="$i18n.t('src.modules.customer.index.table.col.action')"
          width="155"
          fixed="right"
        >
          <template slot-scope="scope">
              <el-button
                v-can="'customer-invoice-address.edit'"
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="onClickEditInvoiceAddress(scope)"
                class="btn-blue-sm"
              >
                {{ $i18n.t('src.modules.quotation.index.button.edit') }}
              </el-button>

              <el-button
                v-can="'customer-invoice-address.delete'"
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="onClickRemove(scope)"
                class="btn-red-sm"
              >
                {{ $i18n.t('src.modules.quotation.index.button.delete') }}
              </el-button>
          </template>
        </el-table-column>
        <!-- Actions (E) -->
      </el-table>
  </el-row>
  <!-- Paging (S) -->
  <el-row type="flex" justify="center">
    <el-col>
      <div class="grid-content bg-purple">
        <el-pagination
          class="pagination"
          @current-change="changePage"
          :page-size="pageSize"
          :current-page="currentPage"
          layout="prev, pager, next"
          :total="totalCnt"
        >
        </el-pagination>
      </div>
    </el-col>
  </el-row>
  <!-- Paging (E) -->
  
  </Widget>
  <!-- Dialog Customer Invoice Address (S) -->
  <el-dialog
    title="Add New"
    :visible.sync="addCustomerInvoiceAddressVisible"
    :before-close="handleCloseAddCustomerInvoiceAddressForm"
    :modal-append-to-body="false"
    :append-to-body="true"
    width="45%"
    :close-on-click-modal="false"
  >
    <el-form
      ref="formCustomerInvoiceAddress"
      :rules="rulesForm"
      :model="formCustomerInvoiceAddress"
      label-width="200px"
    >
      <!-- isDefault (S) -->
      <el-form-item prop="isDefault">
        <el-switch
          v-model="formCustomerInvoiceAddress.isDefault"
          active-text="Default"
          inactive-text="Normal"
        >
        </el-switch>
      </el-form-item>
      <!-- isDefault (E) -->

      <!-- companyName (S) -->
      <el-form-item
        :label="$i18n.t('src.modules.customerAddress.index.companyName')"
        prop="companyName"
      >
        <el-input
          size="medium"
          type="text"
          class="input_add"
          v-model="formCustomerInvoiceAddress.companyName"
        />
      </el-form-item>
      <!-- companyName (E) -->

      <!-- taxCode (S) -->
      <el-form-item
        :label="$i18n.t('src.modules.customerInvoiceAddress.index.taxCode')"
        prop="taxCode"
      >
        <el-input
          size="medium"
          type="text"
          class="input_add"
          v-model="formCustomerInvoiceAddress.taxCode"
        />
      </el-form-item>
      <!-- taxCode (E) -->

      <!-- email (S) -->
      <el-form-item
        :label="$i18n.t('src.modules.customer.index.email')"
        prop="email"
      >
        <el-input
          size="medium"
          type="email"
          class="input_add"
          v-model="formCustomerInvoiceAddress.email"
        />
      </el-form-item>
      <!-- email (E) -->

      <!-- phone (S) -->
      <el-form-item
        :label="$i18n.t('src.modules.customer.index.phone')"
        prop="phone"
      >
        <el-input
          size="medium"
          type="text"
          class="input_add"
          v-model="formCustomerInvoiceAddress.phone"
        />
      </el-form-item>
      <!-- phone (E) -->

      <!-- address (S) -->
      <el-form-item
        :label="$i18n.t('src.modules.customerAddress.index.address')"
        prop="address"
      >
        <el-input
          size="medium"
          class="input_add"
          v-model="formCustomerInvoiceAddress.address"
        />
      </el-form-item>
      <!-- address (E) -->

      <!-- province (S)  -->
      <el-form-item
        :label="$i18n.t('src.modules.customerAddress.index.province')"
        prop="province"
      >
        <el-select
          size="medium"
          clearable
          placeholder="Select Province"
          v-model="formCustomerInvoiceAddress.province"
          width="100%"
          filterable
          @change="resetLocationForm('province')"
        >
          <el-option
            v-for="item in provinceList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- province (E)  -->

      <!-- district (S)  -->
      <el-form-item
        :label="$i18n.t('src.modules.customerAddress.index.district')"
        prop="district"
      >
        <el-select
          size="medium"
          clearable
          placeholder="Select District"
          v-model="formCustomerInvoiceAddress.district"
          width="100%"
          filterable
          @change="resetLocationForm('district')"
        >
          <el-option
            v-for="item in districtListInForm"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- district (E)  -->

      <!-- ward (S)  -->
      <el-form-item
        :label="$i18n.t('src.modules.customerAddress.index.ward')"
        prop="ward"
      >
        <el-select
          size="medium"
          clearable
          placeholder="Select Ward"
          v-model="formCustomerInvoiceAddress.ward"
          width="100%"
          filterable
        >
          <el-option
            v-for="item in wardListInForm"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- ward (E)  -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-row :gutter="24">
        <el-col :span="4">
          <el-button size="medium" @click="handleCloseAddForm">{{
            $i18n.t('src.modules.news.index.close')
          }}</el-button>
        </el-col>
        <el-col l :span="20">
          <el-button
            size="medium"
            v-if="formMode === 'add'"
            type="primary"
            @click="onClickAddCustomerInvoiceAddress()"
            >{{ $i18n.t('src.modules.news.index.btnAdd') }}
            </el-button>
            <el-button
              size="medium"
              v-if="formMode === 'edit'"
              type="success"
              @click="onSaveEditCustomerInvoiceAddress()"
              >{{ $i18n.t('src.modules.news.index.update') }}
              </el-button>
        </el-col>
      </el-row>
    </span>
  </el-dialog>
  <!-- Dialog Customer Invoice Address (E) -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Widget from 'components/Widget/Widget';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import vnLocal from 'vn-local';
import { validatePhone, validateStringPreventDuplicateWhiteSpace, validateTaxCode } from 'core/helpers';

  export default {
    components: { Widget },
      props: {
      // getting: {
      //   type: String,
      //   default: 'Hello'
      // },
      // visible: {
      //   type: Boolean,
      //   default: false
      // },
      
      selectedItems: {
        type: Array,
        default: () => []
      },

      customer_id: {
        type: Number,
        default: null
      },

      add_form_visible: {
        type: Boolean,
        default: false
      }
    },
    data: function (){
      return {
        // Paging
        currentPage: 1,
        pageSize: 10,
        isLoading: true,
        customerInvoiceAddressVisible: false,

        formCustomerInvoiceAddressOld: {},

        addCustomerInvoiceAddressVisible: false,

        formCustomerInvoiceAddressDefault: {
          customerId: null,
          companyName: '',
          taxCode: '',
          email: '',
          phone: '',
          province: null,
          district: null,
          ward: null,
          address: '',
          isDefault: false
        },

        formCustomerInvoiceAddress: {
          customerId: null,
          companyName: '',
          taxCode: '',
          email: '',
          phone: '',
          province: null,
          district: null,
          ward: null,
          address: '',
          isDefault: false
        },

        multipleSelection: [],
        formMode: 'add',
        rulesForm: {
          companyName: [
            {
              required: true,
              message: 'Please enter company name',
              trigger: 'change',
              validator: validateStringPreventDuplicateWhiteSpace
            }
          ],
          taxCode: [
            {
              required: true,
              message: 'Please enter tax code',
              trigger: 'change',
              validator: validateTaxCode
            }
          ],
          email: [
            {
              required: true,
              type: 'email',
              message: 'Please enter the correct email',
              trigger: 'change'
            }
          ],
          phone: [
            {
              required: true,
              validator: validatePhone,
              message: 'Please enter the correct phone format',
              trigger: 'change'
            }
          ],
          address: [
            {
              required: true,
              message: 'Please enter address',
              trigger: 'change',
              validator: validateStringPreventDuplicateWhiteSpace
            }
          ],
          district: [
            {
              required: true,
              message: 'Please enter district',
              trigger: 'change',
            }
          ],
          ward: [
            {
              required: true,
              message: 'Please enter ward',
              trigger: 'change',
            }
          ],
          province: [
            {
              required: true,
              message: 'Please choose province',
              trigger: 'change',
            }
          ],
        }
      }
    },

    computed: {
      ...mapState('customerInvoiceAddress', ['customerInvoiceAddressArr']),

      isDisabledButtonDelete() {
        return !this.multipleSelection.length > 0;
      },

      provinceList() {
        return vnLocal.getProvinces()?.map(item => {
          return {
            value: item.code,
            label: item.name
          };
        });
      },

      districtListInForm() {
        return this.formCustomerInvoiceAddress.province
          ? vnLocal
              .getDistrictsByProvinceCode(this.formCustomerInvoiceAddress.province)
              ?.map(item => {
                return {
                  value: item.code,
                  label: item.name
                };
              })
          : [];
      },

      wardListInForm() {
        return this.formCustomerInvoiceAddress.district
          ? vnLocal
              .getWardsByDistrictCode(this.formCustomerInvoiceAddress.district)
              ?.map(item => {
                return {
                  value: item.code,
                  label: item.name
                };
              })
          : [];
      },

      allCustomerInvoiceAddress(){
        return cloneDeep(this.customerInvoiceAddressArr) || [];
      },

      dialogVisible(){
        return this.add_form_visible;
      },

      // Paging
      pagedTableData() {
        return this.allCustomerInvoiceAddress.slice(
          this.pageSize * this.currentPage - this.pageSize,
          this.pageSize * this.currentPage
        ).map(item => {
          
          function generateAddress(addressString = '', districtCode) {
            const district = vnLocal.getWardsByDistrictCode(districtCode);
            let districtName = district?.[0]?.districtName || '';
            let provinceName = district?.[0]?.provinceName || '';
            let wardName =
              (item.ward && district?.find(x => x.code === item.ward).name) || '';
            return `${addressString ? addressString + ', ' : ''}${
              wardName ? wardName + ', ' : ''
            }${districtName ? districtName + ', ' : ''}${
              provinceName ? provinceName : ''
            }`;
          }

          //generate address when load from database
          const addressString = item?.address || '';
          const districtCode = item?.district || '';
          item.fullAddressString = 'No Data';
          if (districtCode) {
            item.fullAddressString =
              generateAddress(addressString, districtCode) || 'No Data';
          }

          return item;
        });
      },

      totalCnt(){
        return this.allCustomerInvoiceAddress.length || 0
      }

    },

    watch: {
      customer_id: async function () {
        await this.getAllCustomerIncvoiceAddressFunc();
      },
    },

    async created() {
      await Promise.all([
        this.getAllCustomerIncvoiceAddressFunc()
      ]).then(() => {
        this.isLoading = false;
      });
    },

    methods: {
      ...mapActions('customerInvoiceAddress', [
        'getAllCustomerIncvoiceAddress',
        'saveAddCustomerInvoiceAddress',
        'deleteListCustomerInvoiceAddress',
        'saveEditCustomerInvoiceAddress'
      ]),

      onClickDeleteData(){
        this.$confirm('Do you want to remove ?')
        .then(async () => {
          if (this.multipleSelection.length > 0) {
            const CustomerInvoiceAddressIdArr = this.multipleSelection.map(item => item.id);
            const result = await this.deleteListCustomerInvoiceAddress(CustomerInvoiceAddressIdArr);
            if (result) {
              this.$notify({
                title: 'Success',
                message: 'Delete customers rows Success',
                type: 'success'
              });
              this.multipleSelection = [];
            } else {
              this.$notify({
                title: 'Error',
                message: 'Can not remove customers rows',
                type: 'error'
              });
            }
          } else {
            this.$notify({
              title: 'Error',
              message: 'Can not remove customers rows',
              type: 'error'
            });
          }
        })
        .catch(() => {});
      },

      onClickRemove(index){
        this.$confirm(`Do you want to remove ${index.row.companyName || ''} ?`)
        .then(async () => {
          if (this.multipleSelection.length > 0) {
            const result = await this.deleteListCustomerInvoiceAddress([index.row.id]);
            if (result) {
              this.$notify({
                title: 'Success',
                message: 'Delete customers rows Success',
                type: 'success'
              });
              this.multipleSelection = [];
            } else {
              this.$notify({
                title: 'Error',
                message: 'Can not remove customers rows',
                type: 'error'
              });
            }
          } else {
            this.$notify({
              title: 'Error',
              message: 'Can not remove customers rows',
              type: 'error'
            });
          }
        })
        .catch(() => {});
      },

      handleSelectionChange(val) {
        this.multipleSelection = val;
      },

      resetLocationForm(name) {
        if (name === 'province') {
          this.formCustomerInvoiceAddress.district = '';
          this.formCustomerInvoiceAddress.ward = '';
        }
        if (name === 'district') {
          this.formCustomerInvoiceAddress.ward = '';
        }
      },

      clearFormCustomerInvoice(){
        this.formCustomerInvoiceAddress = cloneDeep(this.formCustomerInvoiceAddressDefault);
      },

      async getAllCustomerIncvoiceAddressFunc(){
        await this.getAllCustomerIncvoiceAddress({ customerId :this.customer_id})
        this.isLoading = false;
        return true;
      },

      handleCloseAddCustomerInvoiceAddressForm() {
        this.addCustomerInvoiceAddressVisible = false;
      },

      onClickEditInvoiceAddress(index) {
        const { id } = index.row;
        if (id) {
          this.formMode = 'edit';
        }

        (this.formCustomerInvoiceAddress = {
          addressId: index.row.id,
          email: index.row.email,
          phone: index.row.phone,
          customerId: index.row.customerId,
          taxCode: index.row.taxCode,
          address: index.row.address,
          companyName: index.row.companyName,
          province: index.row.province,
          ward: index.row.ward,
          district: index.row.district,
          isDefault: index.row.isDefault
        }),
          (this.formCustomerInvoiceAddressOld = cloneDeep(index.row));
        this.addCustomerInvoiceAddressVisible = true;
      },

      onSaveEditCustomerInvoiceAddress(){
        this.$confirm('Do you want change ?')
        .then(async () => {
          this.$refs['formCustomerInvoiceAddress'].validate(async valid => {
            if (valid) {
              try {
                const result = await this.saveEditCustomerInvoiceAddress(
                  this.formCustomerInvoiceAddress
                );
                if (result) {
                  this.formCustomerInvoiceAddressOld = {};
                  this.$notify({
                    title: 'Success',
                    message: 'Edit Customers Address Success',
                    type: 'success'
                  });
                } else {
                  this.$notify({
                    title: 'Error',
                    message: `Can not edit Customers Address, Error: ${result.message}`,
                    type: 'error'
                  });
                }
                this.addCustomerInvoiceAddressVisible = false;
              } catch (error) {
                this.$notify({
                  title: this.$i18n.t('common.notify.title.error'),
                  message: this.$i18n.t('common.notify.message.edit.fail', {
                    name: 'row'
                  }),
                  type: 'error'
                });
              }
            } else {
              return false;
            }
          });
        })
        .catch(error => {
          console.log('error: ', error);
        });
      },

      onClickAddCustomerInvoiceAddress() {
        this.$refs['formCustomerInvoiceAddress'].validate(async valid => {
          if (valid) {
            try {
              await this.saveAddCustomerInvoiceAddress({...this.formCustomerInvoiceAddress, customerId: this.customer_id});
              this.$notify({
                title: this.$i18n.t('common.notify.title.success'),
                message: this.$i18n.t('common.notify.message.add.success', {
                  item: 'row'
                }),
                type: 'success'
              });
              this.clearFormCustomerInvoice();
              this.addCustomerInvoiceAddressVisible = false;
            } catch (error) {
              this.$notify({
                title: this.$i18n.t('common.notify.title.error'),
                message: this.$i18n.t('common.notify.message.add.failr', { name: 'row' }),
                type: 'error'
              });
            }
          } else {
            return false;
          }
        });
      },

      // Form customer invoice Address
      clearForm() {
        this.formCustomerInvoiceAddress = cloneDeep(this.formCustomerInvoiceAddressDefault);
      },

      handleCloseAddForm(done) {
        var message = null;
        if (!isEqual(this.formCustomerInvoiceAddress, this.formCustomerInvoiceAddressDefault)) {
          message = this.$i18n.t('common.confirm.close.form.out', {
            name: 'customer invoice address'
          });
          this.$confirm(message)
            .then(() => {
              this.clearForm();
              this.addCustomerInvoiceAddressVisible = false;
              done();
            })
            .catch(() => {});
        } else {
          this.addCustomerInvoiceAddressVisible = false;
        }
      },

      // Action Button
      onClickShowAddPopup() {
        // this.$emit('clickShowFormAddCustomerInvoiceAddress');
        this.addCustomerInvoiceAddressVisible = true;
        this.formMode = 'add';
        this.clearForm();
      },

      changePage(val) {
        this.currentPage = val;
      },
    },
  }
</script>