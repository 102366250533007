import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
  quotationDetails: [],
  totalOrderDetails: 0,
  quotationDetailAddLocal: [],
  totalPrice: 0
  // posts: [],
  // listPost: [],
};

import cloneDeep from 'lodash/cloneDeep';
import { isEqual } from 'lodash';

const mutations = {
  [Type.GET_QUOTATION_DETAIL_FROM_QUOTATION](state, quotationDetailArr) {
    state.quotationDetailAddLocal = [];
    state.quotationDetails = quotationDetailArr;
  },

  [Type.GET_BY_QUOTATION_ID](state, value) {
    state.quotationDetailAddLocal = [];
    const { quotationDetails = [] } = value.rows;
    state.quotationDetails = quotationDetails;
    // state.totalOrderDetails = value.totalRows;
  },

  [Type.SET_QUOTATION_DETAIL_LOCAL](state, value) {
    const oldQuotationDetails = state.quotationDetailAddLocal;
    state.quotationDetailAddLocal = [...oldQuotationDetails, ...cloneDeep(value)];
  },

  //SAVE QT TO DATABASE
  [Type.SAVE_NEW_QUOTATION_DETAIL](state, value) {
    // const oldQuotationDetails = cloneDeep(state.quotationDetails);
    const { items } = value;

    if(Array.isArray(items) && items.length > 0){
      items.forEach((item)=>{
        state.quotationDetails.unshift(cloneDeep(item));
      })
    };

    //ADD NEW DATA AFTER CREATE SUCCESS
    // state.quotationDetails = [...cloneDeep(items), ...oldQuotationDetails];

    state.quotationDetailAddLocal = [];
  },

  [Type.UPDATE_QUOTATION_DETAIL](state, value) {
    console.log('value UPDATE_QUOTATION_DETAIL: ', value);
    const oldQuotationDetails = state.quotationDetails;
    const { items } = value;
    // quotationUpdated
    const { id } = items;
    const objIndex = state.quotationDetails.findIndex(obj => obj.id === id);

    // get info supplier and product images from old data
    const newQT = items;
    // newQT.product = oldQuotationDetails[objIndex].product;
    oldQuotationDetails[objIndex] = newQT;
    state.quotationDetails = oldQuotationDetails.slice(0);
  },

  [Type.QUOTATION_DETAIL_UPDATE_LOCAL](state, value) {
    const { oldData, newData } = value;

    const oldQuotationDetailAddLocal = state.quotationDetailAddLocal.map(item => {
      if (isEqual(item, oldData)) {
        item = newData;
      }
      return item;
    });
    state.quotationDetailAddLocal = oldQuotationDetailAddLocal.slice(0);
  },

  [Type.SET_DELETE_QUOTATION_DETAIL](state, value) {
    const oldData = cloneDeep(state.quotationDetails);
    const { id } = value;

    const objIndex = oldData.findIndex(obj => obj.id === id);
    oldData.splice(objIndex, 1);
    state.quotationDetails = oldData.slice(0);
  },

  [Type.DELETE_QUOTATION_DETAIL_LOCAL](state, value) {
    const oldData = cloneDeep(state.quotationDetailAddLocal);
    const objIndex = oldData.findIndex(obj => {
      return isEqual(obj, value);
    });
    oldData.splice(objIndex, 1);
    state.quotationDetailAddLocal = oldData.slice(0);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
