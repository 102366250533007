import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';

const state = {
    quantityQuotationByMonth: [],
    quantityValQuotationByMonth: [],
    synthesisStatisticsQuotation: {},
    quantityValQuotationByDay: [],
    totalQuotationData: {}
};

const mutations = {
  async [Type.GET_QUANTITY_QUOTATION_BY_MONTH](state, value) {
    state.quantityQuotationByMonth = value.data;
  },
  async [Type.GET_TOTAL_VAL_QUOTATION_BY_MONTH](state, value) {
    state.quantityValQuotationByMonth = value.data;
  },
  async [Type.GET_TOTAL_VAL_QUOTATION_BY_DAY](state, value) {
    state.quantityValQuotationByDay = value.data.arrData;
    state.totalQuotationData = value.data.totalData;
  },
  async [Type.GET_SYNTHESIS_STATISTICS_QUOTATION](state, value) {
    state.synthesisStatisticsQuotation = value.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
