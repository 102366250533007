import Service from 'core/service';
import * as Type from 'modules/profile/store/types';
import axios from 'axios';
const service = new Service();

const uploadFile = async (
  _, {
    prefix,
    companyId = 2,
    typeCode,
    file,
    categoryId,
    supplierId
  }
) => {
  const {
    name,
    raw
  } = file;
  const {
    type
  } = raw;
  const filePath = prefix + '/' + companyId + '/' + name;
  const {
    status,
    message,
    signedRequest
  } = await service.rest('upload', {
    prefix,
    companyId,
    name,
    filePath,
    categoryId,
    fileType: type
  });

  //duplicate image
  if (status === false) {
    // throw new Error(message);
    return {
      status,
      message
    };
  }

  const options = {
    headers: {
      'Content-Type': type
    }
  };
  await axios.put(signedRequest, raw, options);

  return service
    .rest('supplier/updateUploadFile', {
      id: supplierId,
      file: {
        name,
        url: filePath,
        type: typeCode
      }
    })
    .then(rlt => {
      const result = {
        status: true,
        message: 'success',
        data: rlt
      };
      return result;
    })
    .catch(error => {
      // eslint-disable-next-line
      console.log('[EI Debug] [ERROR] >>> action >> addFile > error ', error);
      const result = {
        status: false,
        message: error.message
      };
      return result;
    });
};

const removeFile = async (_, {
  supplierId,
  file
}) => {
  return service
    .rest('supplier/removeUploadFile', {
      id: supplierId,
      file
    })
    .then(rlt => {
      const result = {
        status: true,
        message: 'success',
        data: rlt
      };
      return result;
    })
    .catch(e => {
      console.log(
        '🚀 ~ file: orderDetail.actions.js ~ line 113~ awaitservice.rest ~ e',
        e
      );
      return false;
    });
};

const getUserProfile = async ({
  commit
}) => {
  const params = {};
  return service
    .rest('auth/me', params)
    .then(res => {
      commit(Type.GET_USER_PROFILE, res.data);
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
};

const saveEditProfile = async ({
  commit
}, newInfo) => {
  return service
    .rest('user/update', newInfo)
    .then(res => {
      const {
        status,
        data
      } = res;
      if (status) commit(Type.UPDATE_USER_PROFILE, data);
      return res;
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 21 ~ awaitservice.rest ~ e', e);
      return {
        status: false,
        message: 'Cant update profile!'
      };
    });
};

const getSupplierProfile = async ({
  commit
}, value) => {
  return service
    .rest('supplierUser/me', {
      id: value
    })
    .then(res => {
      commit(Type.GET_SUPPLIER_PROFILE, res.data);
      return true;
    })
    .catch(err => {
      console.log(err);
      return false;
    });
};

const saveEditSupplierProfile = async ({
  commit
}, newInfo) => {
  return service
    .rest('supplierUser/update', newInfo)
    .then(res => {
      const {
        status,
        data
      } = res;
      if (status) commit(Type.UPDATE_SUPPLIER_PROFILE, data);
      return res;
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 21 ~ awaitservice.rest ~ e', e);
      return {
        status: false,
        message: 'Cant update profile!'
      };
    });
};

function getRoleUser(prop) {
  const roleId = prop.state.userProfile.roleId;
  return service
    .rest('role/getInfo', {
      id: roleId
    })
    .then(res => {
      return res.data.name;
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 32 ~ awaitservice.rest ~ e', e);
      return roleId;
    });
}

function getCompanyUser(prop) {
  const companyId = prop.state.userProfile.roleId;
  return service
    .rest('role/getInfo', {
      id: companyId
    })
    .then(res => {
      return res.data.name;
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 43 ~ awaitservice.rest ~ e', e);
      return companyId;
    });
}

async function getPermission(prop) {
  const roleId = prop.state.userProfile.roleId;
  const result = [];
  const permissionId = await getRolePermisson(roleId);
  permissionId.forEach(element => {
    return service
      .rest('permission/getInfo', {
        id: element.permissionId
      })
      .then(res => {
        const permission = res.data;
        permission.action = filterAction(permission.action);
        return result.push(permission);
      })
      .catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 57 ~ awaitservice.rest ~ e', e);
      });
  });

  return result;
}

function filterAction(actionTxt) {
  const arrAction = actionTxt.split('|');
  const resultAction = [];
  arrAction.forEach(element => {
    return resultAction.push({
      name: element
    });
  });
  return resultAction;
}

function getRolePermisson(rolePermissionId) {
  return service
    .rest('rolePermission/getListPaging', {
      roleId: rolePermissionId
    })
    .then(res => {
      return res.rows;
    })
    .catch(e => {
      console.log('🚀 ~ file: actions.js ~ line 80 ~ awaitservice.rest ~ e', e);
      return false;
    });
}
export {
  getUserProfile,
  getSupplierProfile,
  saveEditProfile,
  saveEditSupplierProfile,
  getRoleUser,
  getPermission,
  getCompanyUser,
  uploadFile,
  removeFile
};