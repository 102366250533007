import Service from 'core/service';
import * as Type from 'modules/orderDashboard/store/types';

const services = new Service()

const getQuantityStatisticByMonth = async ({ commit }, value) => {
    const {
        startDate = '',
    } = value;

    const params  = {};
    if (startDate) {
        params.startDate = startDate;
    }
    return await services.rest('dashboard/order/getQuantityStatisticByMonth', params)
    .then(result => {
        commit(Type.GET_QUANTITY_ORDER_BY_MONTH, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

const getAveragePriceStatisticByMonth = async ({ commit }, value) => {
    const {
        startDate = '',
    } = value;

    const params  = {};
    if (startDate) {
        params.startDate = startDate;
    }
    return await services.rest('dashboard/order/getAveragePriceStatisticByMonth', params)
    .then(result => {
        commit(Type.GET_AVERAGE_PRICE_ORDER_BY_MONTH, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

const getTotalOfOrderType = async ({ commit }, value) => {
    const {
        startDate = '',
    } = value;

    const params  = {};
    if (startDate) {
        params.startDate = startDate;
    }
    return await services.rest('dashboard/order/getTotalOfOrderType', params)
    .then(result => {
        commit(Type.GET_TOTAL_ORDER_TYPE, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

const statisticOrderByStatus = async ({ commit }, value) => {
    const {
        date
    } = value || {};
    
    const params = {};
    
    if (date) params.date = date;

    return await services.rest('dashboard/order/statisticByStatus', params).then(rlt => {
        const { data } = rlt;
        // Commit to news state
        commit(Type.SET_STATISTIC_BY_STATUS, data);
        return true
    }).catch(e => {
        console.log('🚀 ~ file: actions.js ~ line 154 ~ awaitservice.rest ~ e', e)
        return false
    });
}


const getAveragePriceStatisticByDay = async ({ commit }, value) => {
    const {
        startDate = '',
        saleId = ''
    } = value;

    const params  = {};
    if (startDate) {
        params.startDate = startDate;
    }
    if (saleId) {
        params.saleId = saleId;
    }
    return await services.rest('dashboard/order/getAveragePriceStatisticOfSalesByDay', params)
    .then(result => {
        commit(Type.GET_AVERAGE_PRICE_ORDER_BY_DAY, result);
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    });
};

export {
    getQuantityStatisticByMonth,
    getAveragePriceStatisticByMonth,
    getTotalOfOrderType,
    statisticOrderByStatus,
    getAveragePriceStatisticByDay
};