import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
    userProfile:{},
    supplierProfile: {}
};

const mutations = {

    [Type.GET_USER_PROFILE](state, value) {
        state.userProfile = value
    },

    [Type.UPDATE_USER_PROFILE](state,value) {
        state.userProfile = value
    },

    [Type.GET_SUPPLIER_PROFILE](state, value) {
        state.supplierProfile = value
    },

    [Type.UPDATE_SUPPLIER_PROFILE](state,value) {
        state.supplierProfile = value
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};