import Service from 'core/service';
import * as Type from 'modules/coupon/store/types';

const services = new Service()

const getAllCoupons = async ({ commit }, value) => {
    const { name = '' } = value;

    const params = { }

    if (name) {
        params.name = name;
    };
   
    const data = await services.rest('coupon/getListPaging', params)
      
    if (data) {
        commit(Type.GET_ALL, data);
    } else {
        console.log('error');
    } 
};

const saveCoupon= async ({ commit }, value) => {
    const allCoupon= await getListCoupon();
    const checkCouponExist = await allCoupon.find(element => element.name === value.name);

    if(!checkCouponExist) {
        return await services.rest('coupon/setList', { 
            items: {
                $add: [value]
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltAdd} = items
            commit(Type.SAVE, $rltAdd);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
        
    } else {
        return {
            type: false, 
            message: 'Item already exist'
        }
    }
};

const editCoupon = async ({ commit }, value) => {
    if (value.oldNameEdit.trim() !== value.data.name.trim()) {
        let allCoupon= await getListCoupon();
        const checkCouponExist = await allCoupon.find(element => element.name == value.data.name)
        if(checkCouponExist) {
            return {
                type: false, 
                message: 'Item already exist'
            }
        }
    } 

    if (value.id) {
        value.data.id = value.id;
        return await services.rest('coupon/setList', { 
            items: {
                $update: [value.data]
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltUpdate} = items
            // Commit to news state
            commit(Type.EDIT, $rltUpdate[0]);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const deleteCoupon= async ({ commit }, value) => {
    if (value.row.id) { 
        return await services.rest('coupon/setList', { 
            items: {
                $delete: [value.row]
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltDelete} = items
            // Commit to news state
            commit(Type.DELETE, $rltDelete);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const deleteCoupons = async ({ commit }, value) => {
    if (value.length > 0) { 
        return await services.rest('coupon/setList', { 
            items: {
                $delete: value
            }
        }).then(rlt => {
            const {items} = rlt;
            const {$rltDelete} = items
            // Commit to news state
            commit(Type.DELETE, $rltDelete);
            return {
                type: true,
                message: 'success'
            }
        }).catch(e => {
            console.log('🚀 ~ file: actions.js ~ line 66 ~ awaitservice.rest ~ e', e)
            return {
                type: false,
                message: 'error'
            }
        })
    }
};

const getAllCouponsSelect = async ({ commit }) => {
    const data = await services.rest('coupon/getListPaging', {})
    if (data) {
        commit(Type.GET_ALL_COUPON_SELECT, data);
    } else {
        console.log('error');
    } 
};

const getListCoupon = async () => {    
    const data = await services.rest('coupon/getListPaging', {})
    .then(res=> {
        return res.rows
    }).catch(err=> {
        console.log(err);
    })
    return data
};


export {
    editCoupon,
    getAllCoupons,
    saveCoupon,
    deleteCoupon,
    deleteCoupons,
    getAllCouponsSelect
};