import * as Type from './types';
import * as getters from './getters';
import * as actions from './actions';
const state = {
    listStatus: []
};


const mutations = {
    [Type.SET_ALL_STATUS](state, value) {
        state.listStatus = value.rows;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};