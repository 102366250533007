<template>
  <div class="sidebar-wrapper">
    <AdminLoginPage v-if="loginMode === LOGIN_MODE.ADMIN" />
    <SupplierLoginPage v-if="loginMode === LOGIN_MODE.SUPPLIER" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AdminLoginPage from './Login';
import SupplierLoginPage from './SupplierLogin';
import { LOGIN_MODE } from 'core/constants';

export default {
  name: 'LoginPage',
  components: {
    AdminLoginPage,
    SupplierLoginPage
  },
  data() {
    return {
      LOGIN_MODE
    };
  },

  computed: {
    ...mapGetters('global', ['loginMode'])
  },

  created() {
    console.log('🚀 ~ file: index.vue ~ line 26 ~ created ~ loginMode', this.loginMode);
  }
};
</script>
