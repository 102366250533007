import axios from 'axios';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import BootstrapVue from 'bootstrap-vue';
import Widget from 'components/Widget/Widget';
import VueQuillS3 from '@natpkg/vue-quill-s3';
import VuePagingCrazy from '@natpkg/vue-paging-crazy';
import App from 'core/App';
import {
  initAuth
} from 'core/auth';
import filters from 'core/filters';
import updateData from 'core/mixins/updateData';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';
import {
  i18n
} from 'locales';
import router from 'router';
import store from 'store';
import {
  ValidationObserver,
  ValidationProvider
} from 'vee-validate';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueElementLoading from 'vue-element-loading';
import VueSelectImage from 'vue-select-image';
import VueTouch from 'vue-touch';
import * as VueGoogleMaps from 'gmap-vue';
require('vue-select-image/dist/vue-select-image.css');
import CKEditor from 'ckeditor4-vue';
import vSelect from 'vue-select';
import VueExcelXlsx from 'vue-excel-xlsx';

const requireComponentVue = require.context(
  'components/EIComponents',
  false,
  /Ei[A-Z]\w+\.(vue)$/
);

requireComponentVue.keys().forEach(fileName => {
  const componentConfig = requireComponentVue(fileName);
  const componentName = upperFirst(camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1')));
  Vue.component(componentName, componentConfig.default || componentConfig);
});


Vue.use(VueCookies);

// Vue use
Vue.use(VueTouch);
Vue.use(VuePagingCrazy)
Vue.use(VueSelectImage);
Vue.use(CKEditor);
Vue.use(VueExcelXlsx);

Vue.component('VueQuillS3', VueQuillS3);
Vue.component('Widget', Widget);
Vue.component('v-select', vSelect);

Vue.config.productionTip = false;
// eslint-disable-next-line
Vue.prototype.$log = console.log.bind(console);

/**
 * Enable dev tools
 */
if (process.env.VUE_APP_DEVTOOLS_ENABLED) {
  Vue.config.devtools = true;
}

Vue.use(filters);
Vue.use(ElementUI, {
  locale
});
Vue.use(BootstrapVue);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
    libraries: 'places' //necessary for places input
  }
});

Vue.mixin(updateData);
Vue.component('VueElementLoading', VueElementLoading);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('../firebase-messaging-sw.js')
//     .then(function (registration) {
//       console.log('Registration successful, scope is:', registration.scope);
//     }).catch(function (err) {
//       console.log('Service worker registration failed, error:', err);
//     });
// }

/**
 *  Import css
 */
axios.defaults.headers.post['Content-Type'] = 'application/json';

/**
 * Sync login and logout on multi tab
 */
window.loggedInUserEmail = localStorage.getItem('user-email');
window.addEventListener('storage', () => {
  const email = localStorage.getItem('user-email');
  if (email != window.loggedInUserEmail) {
    if (!email) {
      window.location.href = '/login';
    } else {
      window.loggedInUserEmail = email;
      window.location.reload(true);
    }
  }
});

// new Vue({
//   el: '#app',
//   components: {
//     App
//   },
//   router,
//   store,
//   i18n,
//   render: h => h('app')
// });

const createApp = () => {
  new Vue({
    el: '#app',
    components: {
      App
    },
    router,
    store,
    i18n,
    render: h => h('app')
  });
};

initAuth().then(createApp);